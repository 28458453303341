import JoyBreadcrumbs from '@mui/joy/Breadcrumbs';
import type { BreadcrumbsProps as JoyBreadcrumbsProps } from '@mui/joy/Breadcrumbs';
import { ForwardedRef, forwardRef } from 'react';

export type BreadcrumbsProps = JoyBreadcrumbsProps;

const Breadcrumbs = (
  props: BreadcrumbsProps,
  ref: ForwardedRef<HTMLElement>,
) => {
  return <JoyBreadcrumbs {...props} ref={ref} />;
};

export default forwardRef<HTMLElement, BreadcrumbsProps>(Breadcrumbs);

import CancelIcon from '@mui/icons-material/Cancel';
import {
  Button,
  Callout,
  Card,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

import {
  CreateDocumentIssuanceBalanceReturnData,
  CreateDocumentIssuanceBody,
} from 'apis/document-issuance/apis';
import CommonCode from 'components/CommonCode';
import { commaizeNumber } from 'utils/format';

export interface DocumentIssuanceBalanceModalProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'onSubmit'> {
  data: CreateDocumentIssuanceBalanceReturnData & {
    unionRegisters: CreateDocumentIssuanceBody['unionRegisters'];
  };
  isLoading?: boolean;
  isSubmitLoading?: boolean;
  onClose?: () => void;
  onSubmit?: (data: { totalAddress: number; totalCount: number }) => void;
}

const INITIAL_DATA = {
  unionRegisters: [],
  items: [],
};

const DocumentIssuanceBalanceModal = (
  props: DocumentIssuanceBalanceModalProps,
) => {
  const {
    data = INITIAL_DATA,
    isLoading = false,
    isSubmitLoading = false,
    onClose,
    onSubmit,
    ...rest
  } = props;
  const { items, unionRegisters } = data;

  const totalAddress = new Set(
    unionRegisters.flatMap(
      ({ registrationAddressSeqs }) => registrationAddressSeqs,
    ),
  ).size;
  const totalCount = items.reduce(
    (total, { issuanceCount }) => (total += issuanceCount),
    0,
  );
  const totalCost = items.reduce(
    (total, { issuanceCost, issuanceCount }) =>
      (total += issuanceCost * issuanceCount),
    0,
  );

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleClose: ModalProps['onClose'] = () => {
    onClose?.();
  };

  const handleSubmit = () => {
    onSubmit?.({ totalAddress, totalCount });
  };

  return (
    <Modal onClose={handleClose} {...rest}>
      <ModalOverflow>
        <ModalDialog>
          <DialogTitle>자료발급</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack gap={3}>
              <Callout
                variant="outlined"
                color="danger"
                startDecorator={<CancelIcon />}
              >
                <Typography textColor="danger.700">발급 유의사항</Typography>
                부동산 구분에 맞지 않는 공부는 발급 정보에 표시되지 않으며 발급
                신청이 되지 않습니다. <br />
                발급 완료 이후 취소 및 환불이 불가능하니 발급 설정과 수량을 다시
                확인해주세요. <br />
                발급 실패된 건은 비용이 차감되지 않습니다. <br />
                행정기관의 공식적인 서버점검 또는 과부하로 인한 장애시 열람이
                지연될 수 있습니다.
              </Callout>

              <Stack gap={2}>
                <Card>
                  {isLoading ? (
                    <Stack
                      height={200}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress />
                    </Stack>
                  ) : (
                    <Stack gap={1.25}>
                      <Stack direction="row" gap={1.25}>
                        <Typography level="body-sm" flex="0 0 92px">
                          선택주소
                        </Typography>

                        <Typography level="body-sm">
                          {commaizeNumber(totalAddress)} 개
                        </Typography>
                      </Stack>

                      <Stack direction="row" gap={1.25}>
                        <Typography level="body-sm" flexBasis="92px">
                          발급내용
                        </Typography>

                        <Stack flex={1} gap={1.25}>
                          {items.map(
                            ({
                              documentName,
                              issuanceType,
                              issuanceCount,
                              issuanceCost,
                            }) => (
                              <Stack
                                key={`issue_${documentName}_${issuanceType}`}
                                direction="row"
                                gap={1.25}
                              >
                                <Stack
                                  direction="row"
                                  flex="1 0 200px"
                                  gap={0.75}
                                >
                                  <Typography level="body-sm">
                                    [{issuanceType}]
                                  </Typography>
                                  <Typography level="body-sm">
                                    <CommonCode
                                      groupCode="DOCUMENT_CODE"
                                      code={documentName}
                                    />
                                  </Typography>
                                </Stack>

                                <Typography
                                  level="body-sm"
                                  textAlign="right"
                                  flexBasis="60px"
                                >
                                  {commaizeNumber(issuanceCount)} 통
                                </Typography>
                                <Typography
                                  level="body-sm"
                                  textAlign="right"
                                  flexBasis="123px"
                                >
                                  {commaizeNumber(issuanceCost * issuanceCount)}
                                  원
                                </Typography>
                              </Stack>
                            ),
                          )}
                        </Stack>
                      </Stack>

                      <Divider />

                      <Stack direction="row" gap={1.25}>
                        <Typography level="body-sm" flexBasis="92px">
                          합계
                        </Typography>

                        <Typography
                          level="body-sm"
                          textAlign="right"
                          flex="1 0 270px"
                        >
                          {commaizeNumber(totalCount)} 통
                        </Typography>
                        <Typography
                          level="body-sm"
                          textAlign="right"
                          flexBasis="123px"
                        >
                          {commaizeNumber(totalCost)}원
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </Card>

                <Stack alignItems="center" gap={2}>
                  <Typography>
                    발급 정보에 오류가 없는지 다시 한번 확인해주세요.
                  </Typography>
                  <Checkbox
                    label="위 사항을 모두 확인하였습니다."
                    checked={isChecked}
                    onChange={handleChecked}
                    disabled={isSubmitLoading}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!isChecked}
              loading={isSubmitLoading}
              onClick={handleSubmit}
            >
              자료발급
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onClose?.()}
              disabled={isSubmitLoading}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default DocumentIssuanceBalanceModal;

import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { queryClient } from 'apis/ReactQueryProvider';
import { ApiError, ApiResponseData } from 'types/api';

// interface Transaction {}

// 충전 내역 파라미터 타입 정의
export interface TransactionListParams {
  page?: number; // 페이지 번호
  pageSize?: number; // 페이지 크기
  unionSeq: number; // 연합 일련번호
  searchFrom?: string; // 요청 날짜
  searchTo?: string; // 완료 날짜
}

// 충전 내역 타입 정의
export interface TransactionList {
  transactionSeq: number; // 거래 일련번호
  serviceType: string; // 서비스 유형
  content: string; // 내용 (금액)
  totalChargeAmount: number; // 총 충전 금액
  totalUsageAmount: number; // 총 사용 금액
  totalRefundAmount: number; // 총 환불 금액
  totalBalance: number; // 총 잔액
  requestedAt?: string; // 요청 시각
  completedAt?: string; // 완료 시각
}
export interface TransactionDetailReturnData {
  depositChargeSeq: number;
  depositorName: string;
  depositorPhoneNo: string;
  requestAmount: number;
  requesterName: string;
  taxInvoiceStatus: string;
  taxInvoiceRequestAt: string;
}

// 충전 내역 반환 데이터 타입 정의
export type TransactionListReturnData = TransactionList[];

export type TransactionDetailParams = {
  unionSeq: number;
  depositChargeSeq: number;
};

export type TransactionPatchStatusRequest = {
  unionSeq: number;
  depositChargeSeq: number;
  status: string;
};

export interface ChargeBody {
  requestAmount: number;
  depositorName: string;
  depositorPhoneNo: string;
}
export interface ChargeDepositRequest extends ChargeBody {
  unionSeq: number;
}

export interface ApplyTaxBody {
  email: string;
  managerPhoneNo: string;
  managerName: string;
  depositAt: string;
  requestAt: string;
}
export interface ApplyTaxRequest extends ApplyTaxBody {
  unionSeq: number;
  depositChargeSeq: number;
}

export const useChargeListQuery = (
  params: TransactionListParams,
): UseQueryResult<
  ApiResponseData<TransactionListReturnData, true>,
  ApiError
> => {
  const { unionSeq, ...restParams } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/transactions`, restParams],
  });
};

export const useTransactionDetailQuery = (
  params: TransactionDetailParams,
): UseQueryResult<
  ApiResponseData<TransactionDetailReturnData, true>,
  ApiError
> => {
  const { unionSeq, depositChargeSeq } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/deposit-charges/${depositChargeSeq}`],
    enabled: !!depositChargeSeq,
  });
};

export const usePatchTransactionStatusMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  TransactionPatchStatusRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, depositChargeSeq, status } = request;
      const { data } = await axiosInstance.patch(
        `/unions/${unionSeq}/deposit-charges/${depositChargeSeq}/status`,
        { status },
      );
      return data;
    },
  });

export interface ChargeDepositReturnData {
  seq: number;
}

export const useChargeDeposit = (): UseMutationResult<
  ApiResponseData<ChargeDepositReturnData>,
  ApiError,
  ChargeDepositRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      // const { unionSeq, depositChargeSeq, status } = request;
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/deposit-charges`,
        body,
      );
      return data;
    },
  });

export const useTaxInvoiceRequestMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ApplyTaxRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, depositChargeSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/deposit-charges/${depositChargeSeq}/tax-invoices`,
        body,
      );

      queryClient.invalidateQueries({
        queryKey: [`/unions/${unionSeq}/deposit-charges/${depositChargeSeq}`],
      });

      return data;
    },
  });

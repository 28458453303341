import { Snackbar, SnackbarProps } from '@wooriga/design-system';
import { useState } from 'react';

export interface FeedbackSnackbarProps
  extends Omit<SnackbarProps, 'open' | 'onClose'> {
  disableClickAwayClose?: boolean;
  onClose?: () => void;
}

const FeedbackSnackbar = (props: FeedbackSnackbarProps) => {
  const { disableClickAwayClose, onClose, ...rest } = props;

  const [open, setOpen] = useState(true);

  const handleClose: SnackbarProps['onClose'] = (_, reason) => {
    if (reason === 'clickaway' && disableClickAwayClose) return;

    onClose?.();
    setOpen(false);
  };

  return <Snackbar {...rest} open={open} onClose={handleClose} />;
};

export default FeedbackSnackbar;

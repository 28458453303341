import { Typography } from '@wooriga/design-system';

import { MeetStatus } from 'apis/types/meet';

const MeetStatusContent = ({ meetStatus }: { meetStatus: MeetStatus }) => {
  switch (meetStatus) {
    case 'BEFORE':
      return (
        <>
          <Typography level="body-md">총회 진행 전 입니다.</Typography>
          <Typography level="body-md">
            {' '}
            총회가 시작되면 총회 관리 시스템에서 입력된 정보가 실시간으로 하단에
            표시됩니다.
          </Typography>
        </>
      );
    case 'PROCESS':
      return (
        <>
          <Typography level="body-md">총회가 진행 중입니다.</Typography>
          <Typography level="body-md">
            총회 관리 시스템에서 입력된 정보가 실시간으로 하단에 표시됩니다.
          </Typography>
        </>
      );
    case 'COUNTING':
      return (
        <>
          <Typography level="body-md">투표 결과를 집계 중 입니다.</Typography>
          <Typography level="body-md">
            총회 종료 직전까지의 정보가 하단에 표시됩니다.
          </Typography>
        </>
      );

    case 'COMPLETE':
      return (
        <>
          <Typography level="body-md">총회가 종료되었습니다.</Typography>
          <Typography level="body-md">
            총회 마감 전까지의 정보가 하단에 표시됩니다.
          </Typography>
        </>
      );

    default:
      return <Typography level="body-md">취소</Typography>;
  }
};

export default MeetStatusContent;

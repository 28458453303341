import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { queryClient } from 'apis/ReactQueryProvider';
import {
  Post,
  PostBalance,
  PostDocument,
  PostRecipient,
} from 'apis/types/post';
import { ApiError, ApiResponseData } from 'types/api';

/**
 * GET
 */

// 우편 내역 목록 조회
export type PostsReturnData = Post[];
export interface PostsParams {
  unionSeq: number;
  requestedAtFrom: string;
  requestedAtTo: string;
  sentAtFrom: string;
  sentAtTo: string;
  deliveredAtFrom: string;
  deliveredAtTo: string;
}
type UsePostsQueryResult = UseQueryResult<
  ApiResponseData<PostsReturnData>,
  ApiError
>;

export const usePostsQuery = (params: PostsParams): UsePostsQueryResult => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/posts`, restParams],
  });
};

// 우편 내역 상세 조회
export type PostReturnData = Post;
export interface PostParams {
  unionSeq: number;
  postSeq: number;
}
type UsePostQueryResult = UseQueryResult<
  ApiResponseData<PostReturnData>,
  ApiError
>;

export const usePostQuery = (params: PostParams): UsePostQueryResult => {
  const { unionSeq, postSeq } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/posts/${postSeq}`],
  });
};

// 우편 수취인 목록 조회
export type PostRecipientReturnData = PostRecipient[];
export interface PostRecipientParams {
  unionSeq: number;
  postSeq: number;
  unionRegisterName: string;
  postAddress: string;
  searchFrom: string;
  searchTo: string;
}
type UsePostRecipientsQueryResult = UseQueryResult<
  ApiResponseData<PostRecipientReturnData>,
  ApiError
>;

export const usePostRecipientsQuery = (
  params: PostRecipientParams,
): UsePostRecipientsQueryResult => {
  const { unionSeq, postSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/posts/${postSeq}/recipients`, restParams],
  });
};

// 우편 문서 서식 목록 조회
export type PostDocumentsReturnData = PostDocument[];
export interface PostDocumentsQueryOptions {
  isModalOpen: boolean;
}
export interface PostDocumentsParams {
  unionSeq: number;
}
type UsePostDocumentsQueryResult = UseQueryResult<
  ApiResponseData<PostDocumentsReturnData>,
  ApiError
>;

export const usePostDocumentsQuery = (
  params: PostDocumentsParams,
): UsePostDocumentsQueryResult => {
  const { unionSeq } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/post-forms`],
    enabled: !!unionSeq,
  });
};

/**
 * POST
 */

// 우편 발송
export type CreatePostSendRequest = CreatePostSendParams & CreatePostSendBody;
export interface CreatePostSendParams {
  unionSeq: number;
}
export interface CreatePostSendBody {
  address: string;
  detailAddress: string;
  zipNo: string;
  name: string;
  phoneNo: string;
  postType: string;
  sendQuantity: number;
  fileSeq: number;
  colorType: string;
  flexType: string;
  isStapler: boolean;
  recipients: {
    unionRegisterSeq?: number;
    address: string;
    detailAddress: string;
    zipNo: string;
    name: string;
    phoneNo: string;
  }[];
}
type UseCreatePostSendMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreatePostSendRequest
>;

export const useCreatePostSendMutation = (): UseCreatePostSendMutationResult =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/posts`,
        body,
      );

      return data;
    },
  });

// 우편 재발송
export type CreatePostResendRequest = CreatePostResendParams &
  CreatePostResendBody;
export interface CreatePostResendParams {
  unionSeq: number;
  postSeq: number;
}
export interface CreatePostResendBody {
  colorType: string;
  postType: string;
  recipients: {
    unionRegisterSeq?: number;
    address: string;
    detailAddress: string;
    zipNo: string;
    name: string;
    phoneNo: string;
  }[];
}
type UseCreatePostResendMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreatePostResendRequest
>;

export const useCreatePostResendMutation =
  (): UseCreatePostResendMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, postSeq, ...body } = request;
        const { data } = await axiosInstance.post(
          `/unions/${unionSeq}/posts/${postSeq}/resend`,
          body,
        );

        return data;
      },
    });

// 우편 문서 서식 등록
export type CreatePostDocumentRequest = CreatePostDocumentParams &
  CreatePostDocumentBody;
export interface CreatePostDocumentParams {
  unionSeq: number;
}
export interface CreatePostDocumentBody {
  fileSeq: number;
  formName: string;
}
type UseCreatePostDocumentMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreatePostDocumentRequest
>;

export const useCreatePostDocumentMutation =
  (): UseCreatePostDocumentMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, ...body } = request;
        const { data } = await axiosInstance.post(
          `/unions/${unionSeq}/post-forms`,
          body,
        );

        queryClient.invalidateQueries({
          queryKey: [`/unions/${unionSeq}/post-forms`],
        });

        return data;
      },
    });

//   우편 발송 비용 조회
export type CreatePostBalanceReturnData = PostBalance;
export type CreatePostBalanceRequest = CreatePostBalanceParams &
  CreatePostSendBody;
export interface CreatePostBalanceParams {
  unionSeq: number;
}
type UseCreatePostBalanceMutationResult = UseMutationResult<
  ApiResponseData<CreatePostBalanceReturnData>,
  ApiError,
  CreatePostBalanceRequest
>;

export const useCreatePostBalanceMutation =
  (): UseCreatePostBalanceMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, ...body } = request;
        const { data } = await axiosInstance.post(
          `/unions/${unionSeq}/posts/cost/calculate`,
          body,
        );

        return data;
      },
    });

/**
 * DELETE
 */

// 우편 문서 서식 삭제
export type DeletePostDocumentRequest = DeletePostDocumentParams &
  DeletePostDocumentBody;
export interface DeletePostDocumentParams {
  unionSeq: number;
}
export interface DeletePostDocumentBody {
  postFormSeqs: number[];
}
type UseDeletePostDocumentMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  DeletePostDocumentRequest
>;

export const useDeletePostDocumentMutation =
  (): UseDeletePostDocumentMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, ...body } = request;
        const { data } = await axiosInstance.delete(
          `/unions/${unionSeq}/post-forms`,
          { data: body },
        );

        queryClient.invalidateQueries({
          queryKey: [`/unions/${unionSeq}/post-forms`],
        });

        return data;
      },
    });

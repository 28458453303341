import JoyCardOverflow from '@mui/joy/CardOverflow';
import type { CardOverflowProps as JoyCardOverflowProps } from '@mui/joy/CardOverflow';
import { ForwardedRef, forwardRef } from 'react';

export type CardOverflowProps = JoyCardOverflowProps;

const CardOverflow = (
  props: CardOverflowProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyCardOverflow {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, CardOverflowProps>(CardOverflow);

import { SxProps } from '@mui/joy/styles/types';
import useControlled from '@mui/utils/useControlled';
import type {
  DateValidationError,
  PickerChangeHandlerContext,
  DatePickerProps as XDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import Typography from 'design-system/components/dataDisplay/Typography';
import FormLabel from 'design-system/components/inputs/FormLabel';
import { GridProps } from 'design-system/components/layout';
import Grid from 'design-system/components/layout/Grid';
import Stack from 'design-system/components/layout/Stack';
import { DateFieldProps } from 'design-system/components/pickers/date/DateField';
import DatePicker from 'design-system/components/pickers/date/DatePicker';
import {
  maxDateTime,
  minDateTime,
} from 'design-system/components/pickers/utils';

export type DateRangePickerSlot = 'root' | 'label' | 'input' | 'helpText';

export interface DateRangePickerProps
  extends Omit<
    XDatePickerProps<Date, false>,
    'value' | 'defaultValue' | 'onChange' | 'sx' | 'minDate' | 'maxDate'
  > {
  variant?: DateFieldProps['variant'];
  color?: DateFieldProps['color'];
  size?: DateFieldProps['size'];
  label?: string;
  helperText?: string;
  minDate?: string | number | null;
  maxDate?: string | number | null;
  value?: (string | number | null)[];
  defaultValue?: (string | number | null)[];
  onChange?: (
    value: (string | number | null)[],
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => void;
  sx?: SxProps & { field?: GridProps };
}

const DateRangePicker = (
  props: DateRangePickerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    label,
    helperText,
    value: valueProp,
    defaultValue,
    onChange,
    minDate: minDateProp,
    maxDate: maxDateProp,
    sx,
    ...other
  } = props;

  const { field, ...otherSx } = sx || {};

  const [value, setValue] = useControlled<
    (string | number | null)[] | undefined
  >({
    controlled: valueProp,
    default: defaultValue,
    name: 'DateRangePicker',
    state: 'value',
  });

  const startPickerRange = useMemo(() => {
    const minDate = minDateProp;
    const maxDate = minDateTime(value?.[1], maxDateProp);

    return { minDate, maxDate };
  }, [maxDateProp, minDateProp, value]);

  const endPickerRange = useMemo(() => {
    const minDate = maxDateTime(value?.[0], minDateProp);
    const maxDate = maxDateProp;

    return { minDate, maxDate };
  }, [maxDateProp, minDateProp, value]);

  const handleChange = useCallback(
    (
      values: (string | number | null)[],
      context: PickerChangeHandlerContext<DateValidationError>,
    ) => {
      const dates = [values?.[0] || null, values?.[1] || null];

      setValue(dates);
      return onChange?.(dates, context);
    },
    [onChange, setValue],
  );

  return (
    <Stack sx={otherSx}>
      {label && (
        <FormLabel sx={{ margin: '0 0 0.375rem 0' }}>{label}</FormLabel>
      )}

      <Grid container flex={1} flexDirection="row" flexWrap="wrap" gap={2}>
        <Grid {...field}>
          <DatePicker
            ref={ref}
            value={value?.[0] || null}
            onChange={(startDateValue, context) =>
              handleChange(
                [
                  startDateValue ? `${startDateValue}T00:00:00` : null,
                  value?.[1] || null,
                ],
                context,
              )
            }
            minDate={startPickerRange.minDate}
            maxDate={startPickerRange.maxDate}
            {...other}
          />
        </Grid>
        <Grid {...field}>
          <DatePicker
            ref={ref}
            value={value?.[1] || null}
            onChange={(endDateValue, context) =>
              handleChange(
                [
                  value?.[0] || null,
                  endDateValue ? `${endDateValue}T23:59:59` : null,
                ],
                context,
              )
            }
            minDate={endPickerRange.minDate}
            maxDate={endPickerRange.maxDate}
            {...other}
          />
        </Grid>
      </Grid>

      {helperText && <Typography>{helperText}</Typography>}
    </Stack>
  );
};

export default forwardRef<HTMLDivElement, DateRangePickerProps>(
  DateRangePicker,
);

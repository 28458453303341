import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Callout,
  FileDragDrop,
  FileList,
  FormControl,
  FormHelperText,
  Input,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useFileUploadMutation } from 'apis/common/apis';
import { useDeletePostDocumentMutation } from 'apis/post/apis';
import { POST_STAPLE_OPTIONS } from 'apis/post/constants';
import { FileInfo } from 'apis/types/common';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import AddressModalTrigger, {
  AddressModalTriggerProps,
} from 'components/pages/posts/AddressModalTrigger';
import PostDocumentSelectionModal from 'components/pages/posts/modal/PostDocumentSelectionModal';
import useFeedback from 'hooks/useFeedback';
import useStepperDialog from 'hooks/useStepperDialog';

const schema = yup.object({
  name: yup.string().required('발송자를 입력하세요.'),
  phoneNo: yup.string().required('발송자 연락처를 입력하세요.'),
  address: yup.string().required('발송자 주소를 검색해 주세요.'),
  detailAddress: yup.string().optional().default(''),
  zipNo: yup.string().required(),
  fileSeq: yup.number().required(),
  postType: yup.string().required('발송방식을 선택해 주세요.'),
  colorType: yup.string().required('출력색상을 선택해 주세요.'),
  flexType: yup.string().required('출력형태을 선택해 주세요.'),
  isStapler: yup.boolean().required('스테이플러 여부를 선택해 주세요.'),
});

type PostFormSchema = yup.InferType<typeof schema>;
type PostSenderStepValues = PostFormSchema & { files: File[] };
export interface PostSenderStepProps {
  unionSeq: number;
}

const PostSenderStep = (props: PostSenderStepProps) => {
  const { unionSeq } = props;

  const [isDocumentSelectionModalOpen, setIsDocumentSelectionModalOpen] =
    useState(false);

  const { mutate: deletePostDocument } = useDeletePostDocumentMutation();
  const { mutateAsync: uploadFiles, isPending: isFileUploading } =
    useFileUploadMutation();

  const { values, updateValues, PreviousButton, stepNext } =
    useStepperDialog<PostSenderStepValues>();
  const {
    control,
    setValue,
    handleSubmit: validateSubmit,
    formState: { errors },
    reset,
  } = useForm<PostFormSchema>({ resolver: yupResolver(schema), values });

  const { snackbar } = useFeedback();
  const { getGroupCode } = useCommonCodes();

  const [files, setFiles] = useState<(File | FileInfo)[]>(values.files ?? []);

  const { postTypeCodeGroup, colorTypeCodeGroup, flexTypeCodeGroup } = useMemo(
    () => ({
      postTypeCodeGroup: getGroupCode('POST_SEND_DIVIDE'),
      colorTypeCodeGroup: getGroupCode('POST_COLOR_DIVIDE'),
      flexTypeCodeGroup: getGroupCode('POST_FLEX_DIVIDE'),
    }),
    [getGroupCode],
  );

  const handleChangeAddress: AddressModalTriggerProps['onComplete'] = (
    result,
  ) => {
    const { userSelectedType, roadAddress, jibunAddress, zonecode } = result;

    const address = userSelectedType === 'R' ? roadAddress : jibunAddress;
    const zipNo = zonecode;

    setValue('address', address);
    setValue('zipNo', zipNo);
  };

  const handleSelectFiles = (upcomingFiles: FileList | null) => {
    if (!upcomingFiles) {
      return;
    }

    const isFileNameDuplicate = Array.from(upcomingFiles).some(({ name }) =>
      files.some((file) => file.name === name),
    );

    if (isFileNameDuplicate) {
      snackbar('파일 이름이 중복되어 첨부하실 수 없습니다.', {
        color: 'danger',
      });
      return;
    }

    setFiles([...files, ...Array.from(upcomingFiles)]);
  };

  const handleSelectFileDelete = (file: File | FileInfo) => {
    const filteredFiles = files.filter((value) => value.name !== file.name);

    setFiles(filteredFiles);
  };

  const handleUploadFiles = async () => {
    const fileList = new DataTransfer();

    files.forEach((file) => {
      if (file instanceof File) {
        fileList.items.add(file);
      }
    });

    return await uploadFiles(
      { divisionCode: 'POST', files: fileList.files },
      {
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleDeletePostDocument = (postFormSeqs: number[]) => {
    const request = {
      unionSeq,
      postFormSeqs,
    };

    deletePostDocument(request, {
      onSuccess: () => {
        snackbar('서식 삭제에 성공하였습니다.', {
          color: 'success',
        });
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleSubmit = validateSubmit(async (data) => {
    if (files.length === 0) {
      snackbar('서식을 선택하거나, 보낼 문서를 업로드 해주세요.', {
        color: 'danger',
      });
      return;
    }

    const { data: file = [] } = await handleUploadFiles();

    updateValues({ ...data, fileSeq: file[0].fileSeq, files });
    stepNext();
  });

  const handleSubmitPostDocumentSelection = (upcomingFiles: File[]) => {
    const isFileNameDuplicate = Array.from(upcomingFiles).some(({ name }) =>
      files.some((file) => file.name === name),
    );

    if (isFileNameDuplicate) {
      snackbar('파일 이름이 중복되어 첨부하실 수 없습니다.', {
        color: 'danger',
      });
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...upcomingFiles]);
  };

  useEffect(() => {
    reset({
      ...values,
      postType: values.postType || postTypeCodeGroup?.items[0].code,
      colorType: values.colorType || colorTypeCodeGroup?.items[0].code,
      flexType: values.flexType || flexTypeCodeGroup?.items[0].code,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTypeCodeGroup, colorTypeCodeGroup, flexTypeCodeGroup, reset]);

  return (
    <>
      <Stack gap={2} maxWidth={1080}>
        <Callout color="warning" startDecorator={<InfoIcon />}>
          일반우편으로 발송하시면 배달조회를 할 수 없으므로 배달조회를 하시려면
          준등기 / 일반등기 / 익일특급을 이용해주세요.
          <br />
          준등기: 최대 30매까지 발송 가능합니다.
          <br />
          익일특급: 신청일(11시 이전 접수 건) 다음날 배달이 되는 서비스입니다.
          2000건 미만에 한하여 익일특급이 가능하며, 2000건 이상에 한하여 당일
          최대한 발송 및 익일 전량발송 됩니다.
        </Callout>

        <Stack direction="row" flexWrap="wrap" gap={3}>
          <Stack flex="1 1 40%" gap={2}>
            <Stack gap={1}>
              <Stack direction="row" alignItems="flex-end" gap={1}>
                <Controller
                  control={control}
                  name="address"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      variant="soft"
                      label="발송자 주소"
                      placeholder="주소"
                      startDecorator={<SearchIcon />}
                      fullWidth
                      readOnly
                      error={fieldState.invalid}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="zipNo"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="soft"
                      placeholder="우편번호"
                      readOnly
                      error={fieldState.invalid}
                      sx={{ width: '120px' }}
                    />
                  )}
                />

                <AddressModalTrigger onComplete={handleChangeAddress}>
                  <Button
                    variant="outlined"
                    color="neutral"
                    sx={{ width: '80px' }}
                  >
                    검색
                  </Button>
                </AddressModalTrigger>
              </Stack>

              <Controller
                control={control}
                name="detailAddress"
                render={({ field }) => (
                  <FormControl error={!!errors.address || !!errors.zipNo}>
                    <Input placeholder="상세주소 입력" {...field} />
                    <FormHelperText>
                      {errors.address?.message ?? errors.zipNo?.message ?? ''}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Stack>

            <Stack direction="row" gap={1}>
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="발송자 명"
                    placeholder="발송자 명 입력"
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    validateOptions={{
                      maxLength: 30,
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="phoneNo"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="발송자 연락처"
                    placeholder="숫자만 입력"
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    validateOptions={{ maxLength: 11, regex: /^[0-9\s]*$/ }}
                  />
                )}
              />
            </Stack>

            <Stack gap={1}>
              <Controller
                control={control}
                name="postType"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    size="md"
                    orientation="horizontal"
                    label="발송방식"
                  >
                    {postTypeCodeGroup?.items.map(({ code, name }) => (
                      <Radio
                        key={`post_method_${code}_radio`}
                        label={name}
                        value={code}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Stack>

            <Stack direction="row" gap={8}>
              <Controller
                control={control}
                name="colorType"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    size="md"
                    orientation="horizontal"
                    label="출력색상"
                  >
                    {colorTypeCodeGroup?.items.map(({ code, name }) => (
                      <Radio
                        key={`post_color_${code}_radio`}
                        label={name}
                        value={code}
                      />
                    ))}
                  </RadioGroup>
                )}
              />

              <Controller
                control={control}
                name="flexType"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    size="md"
                    orientation="horizontal"
                    label="출력형태"
                  >
                    {flexTypeCodeGroup?.items.map(({ code, name }) => (
                      <Radio
                        key={`post_flex_${code}_radio`}
                        label={name}
                        value={code}
                      />
                    ))}
                  </RadioGroup>
                )}
              />

              <Controller
                control={control}
                name="isStapler"
                render={({ field }) => (
                  <RadioGroup
                    size="md"
                    orientation="horizontal"
                    label="스테이플러"
                  >
                    {POST_STAPLE_OPTIONS.map(({ label, value }) => (
                      <Radio
                        key={`post_flex_${value}_radio`}
                        label={label}
                        value={value}
                        checked={field.value === value}
                        onChange={() => field.onChange(value)}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Stack>
          </Stack>

          <Stack flex="1 1 40%" gap={2}>
            <Stack gap={0.75}>
              <Typography level="body-sm" textColor="common.black">
                첨부파일 올리기
              </Typography>

              <FileDragDrop
                variant="outlined"
                color="neutral"
                accept="image/png, application/pdf"
                fullWidth
                onSelectFiles={handleSelectFiles}
              />
            </Stack>

            <Stack gap={0.75}>
              <Typography level="body-sm" textColor="common.black">
                첨부파일 목록{' '}
                <Typography color="primary">{files.length}</Typography>
              </Typography>

              <FileList
                variant="outlined"
                color="neutral"
                value={files}
                labelGetter={(value) => value.name}
                onDelete={handleSelectFileDelete}
                fullWidth
                sx={{ height: '160px', overflowY: 'auto' }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setIsDocumentSelectionModalOpen(true)}
          >
            문서서식
          </Button>

          <Stack direction="row" gap={1}>
            <PreviousButton />
            <Button onClick={handleSubmit} loading={isFileUploading}>
              다음
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {isDocumentSelectionModalOpen && (
        <PostDocumentSelectionModal
          unionSeq={unionSeq}
          open={isDocumentSelectionModalOpen}
          onClose={setIsDocumentSelectionModalOpen}
          onDelete={handleDeletePostDocument}
          onSubmit={handleSubmitPostDocumentSelection}
        />
      )}
    </>
  );
};

export default PostSenderStep;

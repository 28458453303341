import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/joy';
import {
  Button,
  Chip,
  IconButton,
  Input,
  Stack,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useRef, useState } from 'react';

import { useFileUploadMutation } from 'apis/common/apis';
import useFeedback from 'hooks/useFeedback';
import { CreateMeetBody } from 'lim/meetOpen/apis';

export type AgendaFormDataProps = Omit<
  CreateMeetBody['agendas'][number]['candidates'][number],
  'no'
>;
interface CandidateProps extends AgendaFormDataProps {
  isOnly: boolean;
  includeElectronic: boolean;
  index: number;
  onChange: (formData: AgendaFormDataProps) => void;
  onDelete: () => void;
  onAdd: () => void;
}

const ACCEPT_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];

const Candidate = ({
  isOnly,
  includeElectronic,
  index,
  name,
  description,
  attachFileSeq,
  onChange,
  onAdd,
  onDelete,
}: CandidateProps) => {
  const fileRefs = useRef<(HTMLDivElement | null)[]>([]);

  const theme = useTheme();
  const { snackbar } = useFeedback();

  const { mutate } = useFileUploadMutation();

  const [fileName, setFileName] = useState('');

  const [formData, setFormData] = useState<AgendaFormDataProps>({
    name,
    description,
    attachFileSeq,
  });

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    const newValue = {
      ...formData,
      [name]: value,
    };
    setFormData(newValue);
    onChange(newValue);
  };

  const handleChangeFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { files: originalFiles } = event.currentTarget;
    const files = Array.from(originalFiles ?? []);

    const isAcceptFiles = files.every((file) =>
      ACCEPT_FILE_TYPES.includes(file.type),
    );

    if (!isAcceptFiles) {
      return;
    }

    if (originalFiles)
      mutate(
        { divisionCode: 'MEET', files: originalFiles },
        {
          onSuccess: ({ data }) => {
            if (data) {
              setFileName(originalFiles?.[0].name || '');

              const newValue = {
                ...formData,
                attachFileSeq: data[0].fileSeq,
              };

              setFormData(newValue);
              onChange(newValue);
            }
          },
          onError: (e) => {
            snackbar(e.message, {
              color: 'danger',
            });
          },
        },
      );
  };

  const handleClickOpenFileFinder = () => {
    if (fileRefs.current[0]) {
      fileRefs.current[0].click();
    }
  };

  return (
    <Stack
      border={`1px ${theme.palette.neutral[300]} solid`}
      padding={2}
      borderRadius={5}
      bgcolor={theme.palette.neutral[100]}
    >
      <Stack flexDirection="row" gap={2}>
        <Chip sx={{ height: '28px' }} variant="outlined" color="neutral">
          후보 {index + 1}
        </Chip>
        <Stack gap={2} width="100%">
          <Input
            placeholder="후보 명 입력"
            name="name"
            value={formData.name}
            onChange={handleOnChange}
          />

          <Textarea
            placeholder="후보에 대한 설명 입력"
            name="description"
            value={formData.description}
            onChange={handleOnChange}
            sx={{ height: '100%' }}
          />
        </Stack>
        <Stack gap={2} width={240}>
          {!isOnly && (
            <Stack flexDirection="row" justifyContent="end" gap={2}>
              <Stack borderRadius={5} bgcolor={theme.palette.neutral[50]}>
                <IconButton
                  variant="outlined"
                  color="neutral"
                  onClick={onDelete}
                >
                  <RemoveIcon />
                </IconButton>
              </Stack>
              <Stack borderRadius={5} bgcolor={theme.palette.neutral[50]}>
                <IconButton variant="outlined" color="neutral" onClick={onAdd}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </Stack>
          )}
          {includeElectronic && (
            <Stack
              border={`1px ${theme.palette.neutral[300]} solid`}
              padding={2}
              borderRadius={5}
              bgcolor={theme.palette.neutral[50]}
            >
              <Input
                type="file"
                name={name}
                sx={{ display: 'none' }}
                slotProps={{
                  input: {
                    ref: (element) => (fileRefs.current[0] = element),
                  },
                }}
                onChange={handleChangeFileInput}
              />
              <Typography
                level="body-md"
                textColor={theme.palette.neutral[400]}
                sx={{ flexDirection: 'column' }}
                endDecorator={
                  <Button
                    variant="outlined"
                    color="neutral"
                    onClick={handleClickOpenFileFinder}
                    sx={{ mt: 1.5 }}
                  >
                    내 PC에서 올리기
                  </Button>
                }
              >
                <Typography sx={{ textAlign: 'center' }}>
                  {fileName ? (
                    fileName
                  ) : (
                    <>
                      PNG, JPG, JPEG
                      <br />
                      파일 1개만
                      <br />
                      등록 가능합니다.
                    </>
                  )}
                </Typography>
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Candidate;

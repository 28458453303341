import { GridColDef } from '@mui/x-data-grid';

import { RegistrationAddress } from 'apis/types/common';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

export const REGISTRATION_GRID_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    {
      field: 'registrationNo',
      headerName: '고유번호',
      width: 150,
    },
    {
      field: 'registrationType',
      headerName: '부동산구분',
      width: 78,
      valueGetter: (value) => getCommonCode('REGISTRATION_TYPE', value)?.name,
    },
    {
      field: 'registrationAddress',
      headerName: '상세주소',
      width: 230,
    },
  ] as GridColDef<RegistrationAddress>[];

import {
  Descriptions,
  DescriptionsItem,
  DescriptionsProps,
} from '@wooriga/design-system';
import { isNumber } from 'lodash';

import { DataProps } from 'components/DescriptionsRenderer/type';

interface DescriptionsRendererProps extends DescriptionsProps {
  json: DataProps[];
}

const DescriptionsRenderer = ({
  json,
  ...props
}: DescriptionsRendererProps) => {
  return (
    <Descriptions
      color="neutral"
      columns={3}
      size="md"
      variant="outlined"
      {...props}
    >
      {json.map((desc) => {
        const { label, content, span } = desc;
        return (
          <DescriptionsItem
            key={`${label}-${content}`}
            label={label}
            span={span || 1}
          >
            {isNumber(content) ? Number(content) : content}
          </DescriptionsItem>
        );
      })}
    </Descriptions>
  );
};

export default DescriptionsRenderer;

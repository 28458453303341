import {
  MutateAgenda,
  MutateCandidate,
  VoteResultBody,
} from 'lim/generalMeetingHistoryDetail/apis';

import { DataGridValueKeys, DataGridValues } from '../../common/ResultGrid';

export type TransformAgenda = Omit<MutateAgenda, 'order' | 'candidates'> & {
  candidates: Partial<MutateCandidate>[];
};

const ensureKeys = (result: DataGridValueKeys) => {
  if (Object.keys(result).length === 0) {
    return {
      yesCount: 0,
      noCount: 0,
      abstentionCount: 0,
      totalCount: 0,
    };
  }

  const totalCount = Object.values(result)?.reduce((acc, cur) => {
    return (acc = Number(acc) + Number(cur));
  });

  return {
    yesCount: result.yesCount || 0,
    noCount: result.noCount || 0,
    abstentionCount: result.abstentionCount || 0,
    totalCount,
  };
};

export const combineResults = (
  onsite: DataGridValues,
  written: DataGridValues,
): VoteResultBody['agendas'] => {
  const agendasMap = new Map();

  const keys = new Set([...Object.keys(onsite), ...Object.keys(written)]);

  keys.forEach((key) => {
    const [agendaSeq, no] = key.split('-').map(Number);

    let agenda = agendasMap.get(agendaSeq);
    if (!agenda) {
      agenda = { agendaSeq, candidates: [] };
      agendasMap.set(agendaSeq, agenda);
    }

    const candidate = {
      no,
      writtenSubmissionResult: ensureKeys(written[key] || {}),
      onsiteResult: ensureKeys(onsite[key] || {}),
    };

    agenda.candidates.push(candidate);
  });

  return Array.from(agendasMap.values());
};

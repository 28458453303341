import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

import useFeedback from 'hooks/useFeedback';

interface ReVoteModalProps {
  open: boolean;
  onClose: (open: false) => void;
  onSubmit: (password: string) => void;
}

const ReVoteModal = ({
  open,

  onClose,
  onSubmit,
}: ReVoteModalProps) => {
  const { alertDialog } = useFeedback();
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState('');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleOnSubmit = () => {
    if (!isChecked) {
      return alertDialog('주의사항을 확인하시고 내용에 동의해 주세요.');
    }

    if (!password) {
      return alertDialog('비밀번호를 입력해주세요.');
    }

    onSubmit(password);
  };

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
    setPassword('');
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <Typography
            alignSelf="center"
            color="danger"
            level="h2"
            startDecorator={<InfoIcon />}
          >
            개표 결과 재입력 주의 사항
          </Typography>
          <DialogContent>
            <Stack gap={3}>
              <Callout color="danger" variant="soft">
                <Typography level="title-lg">
                  확인을 선택하면 총회 진행상태가 진행중으로 변경됩니다.
                </Typography>
                <Typography level="body-md">
                  진행중으로 변경되면 선거관리시스템(VMS)에서 입력, 수정, 삭제가
                  가능합니다. 이미 생성된 총회 참석자 연명부가 삭제됩니다.
                  선거관리시스템(VMS)에서 추가 작업을 완료한 뒤 개표 결과 입력
                  완료와 연명부 다운로드를 진행해 주세요.
                  <br />
                  <br />
                  집계된 개표 결과가 초기화되며, 개표 결과를 수정하거나 다시
                  입력하실 수 있습니다.
                </Typography>
              </Callout>

              <Checkbox
                color="primary"
                label="확인하였습니다."
                onChange={(e) => setIsChecked(e.target.checked)}
              />

              <Input
                type="password"
                name="password"
                placeholder="총회 비밀번호를 입력하세요."
                onChange={handleOnChange}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth type="submit" onClick={handleOnSubmit}>
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={handleClose}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default ReVoteModal;

import JoyAccordionDetails from '@mui/joy/AccordionDetails';
import type { AccordionDetailsProps as JoyAccordionDetailsProps } from '@mui/joy/AccordionDetails';
import { ForwardedRef, forwardRef } from 'react';

export type AccordionDetailsProps = JoyAccordionDetailsProps;

const AccordionDetails = (
  props: AccordionDetailsProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyAccordionDetails {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, AccordionDetailsProps>(
  AccordionDetails,
);

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ForwardedRef, forwardRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { RegisterManagerRequest } from 'kwon/union-info/apis';

const schema = yup.object().shape({
  name: yup.string().required('담당자 이름을 입력해주세요.'),
  phoneNo: yup
    .string()
    .matches(/^\d{10,11}$/, '유효한 연락처를 입력해주세요 (10-11자리 숫자).')
    .required('담당자 연락처를 입력해주세요.'),
  email: yup.string().email('유효한 이메일을 입력해주세요.'),
});

export interface AddUnionManagerModalProps {
  open: boolean;
  unionSeq: number;
  onClose: () => void;
  onSubmit: (data: RegisterManagerRequest) => void;
}

const AddUnionManagerModal = (
  props: AddUnionManagerModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { open, onClose, onSubmit } = props;

  const {
    handleSubmit: validateSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<RegisterManagerRequest>({
    resolver: yupResolver(schema),
  });
  const handleSubmit = (data: RegisterManagerRequest) => {
    data.unionSeq = props.unionSeq;
    onSubmit(data);
    reset();
  };

  return (
    <>
      <Modal
        open={open}
        ref={ref}
        component="form"
        onSubmit={validateSubmit(handleSubmit)}
      >
        <ModalDialog
          sx={{
            width: '400px',
          }}
        >
          <DialogTitle>조합 관리자 등록</DialogTitle>

          <Typography level="body-md">
            조합 관리자를 신규로 등록하시겠습니까?
          </Typography>
          <DialogContent>
            <Stack gap={2}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="조합 관리자 명"
                    placeholder="조합 관리자 명 입력"
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ''}
                    {...field}
                    validateOptions={{
                      maxLength: 30,
                    }}
                  />
                )}
              />
              <Controller
                name="phoneNo"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="조합 관리자 연락처"
                    placeholder="숫자만 입력"
                    type="text"
                    value={field.value || ''}
                    onChange={(e) => {
                      const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                      if (onlyNums.length <= 11) {
                        field.onChange(onlyNums);
                      }
                    }}
                    error={!!errors.phoneNo}
                    helperText={errors.phoneNo ? errors.phoneNo.message : ''}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="조합 관리자 이메일"
                    placeholder="이메일 입력"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    {...field}
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button size="md" variant="solid" color="primary" type="submit">
              등록
            </Button>
            <Button
              size="md"
              variant="outlined"
              color="neutral"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default forwardRef(AddUnionManagerModal);

import { ForwardedRef, forwardRef } from 'react';
import { PatternFormat, type PatternFormatProps } from 'react-number-format';

export interface PatternFormatAdapterProps extends PatternFormatProps {}

const PatternFormatAdapter = (
  props: PatternFormatAdapterProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  return <PatternFormat {...props} {...ref} />;
};

export default forwardRef<HTMLInputElement, PatternFormatAdapterProps>(
  PatternFormatAdapter,
);

import {
  AlertDailogResultType,
  AlertDialog,
  AlertDialogProps,
  Modal,
  ModalProps,
  SnackbarClose,
  SnackbarProps,
} from '@wooriga/design-system';
import { createElement, ReactNode } from 'react';

import FeedbackSnackbar from 'components/Feedback/FeedbackSnackbar';

export type SnackbarDuration = 'DEFAULT' | 'SHORT' | 'LONG';

export type SnackbarOptions = {
  duration?: SnackbarDuration;
  color?: SnackbarProps['color'];
  variant?: SnackbarProps['variant'];
  anchorOrigin?: SnackbarProps['anchorOrigin'];
  disableClickAwayClose?: boolean;
  disableAutoHideDuration?: boolean;
  disableCloseButton?: boolean;
};

export interface AlertOptions
  extends Pick<
    AlertDialogProps,
    'variant' | 'color' | 'size' | 'message' | 'type'
  > {
  enableBackdropClose?: boolean;
  onSubmit?: () => void;
}

export interface ConfirmOptions
  extends Pick<
    AlertDialogProps,
    'variant' | 'color' | 'size' | 'message' | 'type'
  > {
  enableBackdropClose?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const SnackbarDuration = {
  SHORT: 1000,
  DEFAULT: undefined, // 디자인시스템 Snackbar 기본 값
  LONG: 5000,
} as const;

export const createFeedbackSnackbarElement = (
  message: string | ReactNode,
  id: string,
  options?: SnackbarOptions,
  onUnmount?: () => void,
) =>
  createElement(
    FeedbackSnackbar,
    {
      key: id,
      variant: options?.variant ?? 'solid',
      color: options?.color ?? 'primary',
      anchorOrigin: options?.anchorOrigin ?? {
        vertical: 'top',
        horizontal: 'center',
      },
      disableClickAwayClose: options?.disableClickAwayClose,
      autoHideDuration: !options?.disableAutoHideDuration
        ? SnackbarDuration[options?.duration ?? 'DEFAULT']
        : null,
      endDecorator: !options?.disableCloseButton
        ? createElement(SnackbarClose)
        : null,
      onUnmount,
    },
    message,
  );

export const createFeedbackModalElement = (
  options: AlertOptions | ConfirmOptions,
  children: Parameters<typeof createElement>[2],
  onClose?: () => void,
) =>
  createElement(
    Modal,
    {
      open: true,
      onClose: (_: unknown, reason: string) => {
        if (reason === 'backdropClick' && !options.enableBackdropClose) return;
        onClose && onClose();
      },
    } as unknown as ModalProps,
    children,
  );

export const createFeedbackDialogElement = (
  detail: ReactNode,
  options:
    | Omit<AlertOptions, 'message'>
    | Omit<ConfirmOptions, 'message' | 'onCancel' | 'onConfirm'>,
  message?: ReactNode,
  onSubmit?: (result: AlertDailogResultType) => void,
) =>
  createElement(AlertDialog, {
    ...options,
    detail,
    message: message ?? '알림',
    onSubmit,
  });

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import styled from '@mui/joy/styles/styled';
import { ForwardedRef, forwardRef, useMemo } from 'react';

import IconButton, {
  IconButtonProps,
} from 'design-system/components/inputs/IconButton';
import { UsePaginationItem } from 'design-system/components/navigation/pagination/usePagination';

export type PaginationItemProps = Omit<IconButtonProps, 'type'> &
  UsePaginationItem;

const PaginationItem = (
  { type, page, ...rest }: PaginationItemProps,
  ref: ForwardedRef<HTMLElement>,
) => {
  const itemProps = useMemo(() => {
    switch (type) {
      case 'first':
        return {
          component: IconButton,
          'aria-label': 'Go to first page',
          children: <FirstPageRoundedIcon />,
        };
      case 'previous':
        return {
          component: IconButton,
          'aria-label': 'Go to previous page',
          children: <ChevronLeftRoundedIcon />,
        };
      case 'next':
        return {
          component: IconButton,
          'aria-label': 'Go to next page',
          children: <ChevronRightRoundedIcon />,
        };
      case 'last':
        return {
          component: IconButton,
          'aria-label': 'Go to last page',
          children: <LastPageRoundedIcon />,
        };
      default:
        return {
          component: IconButton,
          'aria-label': `Go to page ${page}`,
          children: page,
        };
    }
  }, [page, type]);

  if (type === 'start-ellipsis' || type === 'end-ellipsis')
    return (
      <PaginationItemRootText ref={ref as ForwardedRef<HTMLDivElement>}>
        ...
      </PaginationItemRootText>
    );

  return (
    <PaginationItemRoot
      {...rest}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      {...itemProps}
    />
  );
};

const PaginationItemRoot = styled(IconButton, {
  name: 'JoyPaginationItem',
  slot: 'root',
})``;

const PaginationItemRootText = styled('div', {
  name: 'JoyPaginationItem',
  slot: 'root',
})``;

export default forwardRef<HTMLElement, PaginationItemProps>(PaginationItem);

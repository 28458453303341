import {
  PropsWithChildren,
  cloneElement,
  isValidElement,
  useEffect,
} from 'react';

export interface StepperDialogStepProps extends PropsWithChildren {
  readonly name: string;
  onEnter?: () => void;
}

const StepperDialogStep = (props: StepperDialogStepProps) => {
  const { name, children, onEnter } = props;

  useEffect(() => {
    onEnter?.();
  }, [onEnter]);

  if (!isValidElement<StepperDialogStepProps>(children)) {
    return children;
  }

  return cloneElement(children, { name });
};

export default StepperDialogStep;

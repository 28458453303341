import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
} from '@wooriga/design-system';
import { useCallback, useEffect, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';
import { CreatePostDocumentBody } from 'apis/post/apis';
import { FileInfo } from 'apis/types/common';
import PostDocumentNameModal from 'components/pages/posts/modal/PostDocumentNameModal';
import usePrevious from 'hooks/usePrevious';

export interface PostDocumentPreviewModalProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'onSubmit'> {
  fileInfo: FileInfo;
  loading?: boolean;
  onSubmit?: (values: CreatePostDocumentBody) => void;
  onClose: (value: boolean) => void;
}

const PostDocumentPreviewModal = (props: PostDocumentPreviewModalProps) => {
  const { fileInfo, loading, onSubmit, onClose, ...rest } = props;
  const { fileSeq } = fileInfo;

  const [src, setSrc] = useState('');
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);

  const { mutate: downloadFile, isPending: isFileDownloading } =
    useFileDownloadMutation();

  const previousFileSeq = usePrevious(fileSeq);

  const handleDocumentNameSubmit = (formName: string) => {
    onSubmit?.({ fileSeq, formName });
    setIsNameModalOpen(false);
    onClose(false);
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') return;

    onClose(false);
  };

  const getPostDocumentUrl = useCallback(() => {
    if (!fileSeq || previousFileSeq === fileSeq) {
      return;
    }

    downloadFile(
      { fileSeq },
      {
        onSuccess: (data) => {
          if (!data) {
            return;
          }

          const url = URL.createObjectURL(
            new Blob([data], { type: 'application/pdf' }),
          );
          setSrc(url);
        },
      },
    );
  }, [fileSeq, previousFileSeq, downloadFile]);

  useEffect(() => {
    getPostDocumentUrl();
  }, [getPostDocumentUrl]);

  return (
    <>
      <Modal {...rest} onClose={handleClose}>
        <ModalOverflow>
          <ModalDialog minWidth={1080}>
            <DialogTitle>우편발송 미리보기</DialogTitle>

            <DialogContent>
              <Stack
                width="100%"
                minHeight={580}
                justifyContent="center"
                alignItems="center"
              >
                {loading || isFileDownloading ? (
                  <CircularProgress />
                ) : (
                  <iframe
                    title="우편발송 미리보기"
                    src={src}
                    style={{ width: '100%', height: '100%', flex: 1 }}
                  />
                )}
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setIsNameModalOpen(true)}>서식저장</Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => onClose(false)}
              >
                취소
              </Button>
            </DialogActions>
          </ModalDialog>
        </ModalOverflow>
      </Modal>

      {isNameModalOpen && (
        <PostDocumentNameModal
          open={isNameModalOpen}
          onClose={setIsNameModalOpen}
          onSubmit={handleDocumentNameSubmit}
        />
      )}
    </>
  );
};

export default PostDocumentPreviewModal;

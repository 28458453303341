import { GridValidRowModel } from '@mui/x-data-grid';
import {
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { DataGrid } from '@wooriga/design-system';

import { TypeGuardCol } from 'lim/_fixtures/type';
import { MeetsAgendasTableColumnsProps } from 'lim/generalMeetingHistoryDetail/fixtures';

export type DataGridValueKeys = Pick<
  MeetsAgendasTableColumnsProps,
  'yesCount' | 'noCount' | 'abstentionCount'
>;

type GroupingModelKeys = keyof MeetsAgendasTableColumnsProps;

export type DataGridValues = Record<string, DataGridValueKeys>;

interface ResultGridProps<RowType, ColumnType extends GridValidRowModel> {
  rows: RowType[];
  columns: TypeGuardCol<ColumnType>[];
  defaultGroupingExpansionDepth?: number;
  groupingModels?: GroupingModelKeys[];
}

const ResultGrid = <RowType, ColumnType extends GridValidRowModel>({
  rows,
  columns,
  defaultGroupingExpansionDepth = 2,
  groupingModels,
}: ResultGridProps<RowType, ColumnType>) => {
  const defaultModels = ['order', 'no'];
  const model = groupingModels
    ? [...defaultModels, ...groupingModels]
    : defaultModels;
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {
        columnVisibilityModel: {},
      },
      rowGrouping: {
        model,
      },
    },
  });

  return (
    <DataGrid
      apiRef={apiRef}
      disableRowSelectionOnClick
      initialState={initialState}
      defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
      columns={columns}
      rows={rows}
    />
  );
};

export default ResultGrid;

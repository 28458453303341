import JoyOption from '@mui/joy/Option';
import type { OptionProps as JoyOptionProps } from '@mui/joy/Option';
import { ForwardedRef, forwardRef } from 'react';

export type OptionProps = JoyOptionProps;

const Option = (props: OptionProps, ref: ForwardedRef<HTMLLIElement>) => {
  return <JoyOption {...props} ref={ref} />;
};

export default forwardRef<HTMLLIElement, OptionProps>(Option);

import { DataGrid, Stack, Typography } from '@wooriga/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CreatePostSendBody } from 'apis/post/apis';
import {
  POST_RESEND_COLUMNS,
  PostResenderColumnProps,
} from 'apis/post/fixtures';
import { PostRecipient } from 'apis/types/post';
import useStepperDialog from 'hooks/useStepperDialog';
import { commaizeNumber } from 'utils/format';

export interface PostResendStepProps {
  defaultRows?: PostRecipient[];
}
const PostResendStep = (props: PostResendStepProps) => {
  const { defaultRows = [] } = props;

  const [rows, setRows] = useState<PostRecipient[]>(defaultRows);

  const { values, updateValues, PreviousButton, NextButton } =
    useStepperDialog<CreatePostSendBody>();

  const spreadRecipient = (rows: PostRecipient[]) => {
    return rows.map(({ address, name, phoneNo }) => ({
      ...address,
      name,
      phoneNo,
    }));
  };

  const handleChangeAddress: PostResenderColumnProps['onClickAddress'] =
    useCallback(
      (result, seq) => {
        const { userSelectedType, roadAddress, jibunAddress, zonecode } =
          result;
        const address = userSelectedType === 'R' ? roadAddress : jibunAddress;

        const newRows = rows.map((row) => {
          if (row.postRecipientSeq === seq) {
            return {
              ...row,
              address: { ...row.address, address, zipNo: zonecode },
            };
          }
          return row;
        });

        setRows(newRows);
      },
      [rows],
    );

  const handleProcessRowUpdate = (
    row: PostRecipient & { detailAddress?: string },
  ) => {
    let newRow = row;
    const isAddressChange = 'detailAddress' in newRow;

    if (isAddressChange) {
      const changeAddress = newRow['detailAddress'];
      delete newRow['detailAddress'];

      newRow = {
        ...newRow,
        address: { ...newRow.address, detailAddress: changeAddress ?? '' },
      };
    }
    const newRows = rows.map((row) =>
      newRow.postRecipientSeq === row.postRecipientSeq ? newRow : row,
    );

    setRows(newRows);
    return newRow;
  };

  const handleNext = () => {
    updateValues({ ...values, recipients: spreadRecipient(rows) });
  };

  const columns = useMemo(
    () =>
      POST_RESEND_COLUMNS({
        onClickAddress: handleChangeAddress,
      }),
    [handleChangeAddress],
  );

  useEffect(() => {
    updateValues({
      ...values,
      recipients: rows.map(({ address, unionRegisterSeq, name, phoneNo }) => ({
        ...address,
        ...(unionRegisterSeq && { unionRegisterSeq }),
        name,
        phoneNo,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, updateValues]);

  return (
    <Stack gap={2.5} minWidth={1158}>
      <Typography level="body-md" color="neutral">
        처음 발송했을 때의 설정과 서식 그대로 발송됩니다.
        <br />
        신규 발송하시려면 ‘우편발송’ 메뉴로 이동하여 진행해주시길 바랍니다.
      </Typography>

      <Stack gap={1}>
        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          전체{' '}
          <Typography color="primary">{commaizeNumber(rows.length)}</Typography>
        </Typography>

        <Stack height={442}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            getRowId={(row) => row.postRecipientSeq}
            processRowUpdate={handleProcessRowUpdate}
          />
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" gap={1}>
        <PreviousButton />
        <NextButton onClick={handleNext} />
      </Stack>
    </Stack>
  );
};

export default PostResendStep;

import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  Card,
  Chip,
  FileDragDrop,
  FileThumbnailList,
  Stack,
  TextField,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, ReactNode, useMemo } from 'react';

import useFeedback from 'hooks/useFeedback';
import { eucKrBytes } from 'utils/byte';

const MESSAGE_TEXTAREA_PLACEHOLDER = `내용 입력* \n
• SMS: 문자길이 최대 90byte (영문 90자, 한글 45자)
• LMS: 문자길이 최대 2,000byte(영문 2,000자, 한글 1,000자)
• MMS: 문자길이 최대 2,000byte 또는 이미지 첨부
• 제목 입력 시 LMS(장문메세지)로 전송됩니다.
• 문자 길이가 90byte를 넘어 갈 경우, LMS로 전송됩니다.`;

const MAX_LENGTH_OF_SMS = 90;
const MAX_LENGTH_OF_MESSAGE = 2000;
const MAX_LENGTH_OF_IMAGES = 3;

const DEFAULT_VALUES = {
  title: '',
  message: '',
  files: [],
};
export interface MessageFormValues {
  title: string;
  message: string;
  files: File[];
}

export interface MessageFormProps {
  value?: MessageFormValues;
  actions?: ReactNode;
  onChange?: (data: MessageFormValues) => void;
}

const MessageForm = (props: MessageFormProps) => {
  const { value = DEFAULT_VALUES, actions, onChange } = props;

  const formValues = useMemo(
    () => ({
      title: value.title || '',
      message: value.message || '',
      files: value.files || [],
    }),
    [value],
  );
  const { title, message, files } = formValues;

  const hasTitle = useMemo(() => (title?.length || 0) > 0, [title]);
  const contentCount = useMemo(() => eucKrBytes(message ?? ''), [message]);
  const messageType = useMemo(() => {
    const isLMS = hasTitle || contentCount > MAX_LENGTH_OF_SMS;
    const isMMS = (files ?? []).length > 0;

    return isMMS ? 'MMS' : isLMS ? 'LMS' : 'SMS';
  }, [files, hasTitle, contentCount]);

  const { snackbar, alertDialog } = useFeedback();

  const handleResetMessageForm = () => {
    onChange?.(DEFAULT_VALUES);
  };

  const handleChangeValues = <T extends HTMLInputElement | HTMLTextAreaElement>(
    event: ChangeEvent<T>,
  ) => {
    const { name, value: inputValue } = event.target;

    if (name === 'message' && eucKrBytes(inputValue) > MAX_LENGTH_OF_MESSAGE) {
      alertDialog('2000Bytes이상 입력할 수 없습니다.', {
        message: '입력 초과',
      });
      return;
    }

    const newValue = {
      ...formValues,
      [name]: inputValue,
    };

    onChange?.(newValue);
  };

  const handleSelectFiles = (upcomingFiles: FileList | null) => {
    if (!upcomingFiles) {
      return;
    }

    if (upcomingFiles.length + formValues.files.length > MAX_LENGTH_OF_IMAGES) {
      snackbar('이미지는 최대 3장까지 첨부 가능합니다.', { color: 'danger' });
      return;
    }

    const newValue = {
      ...formValues,
      files: [...formValues.files, ...Array.from(upcomingFiles)],
    };

    onChange?.(newValue);
  };

  const handleChangeFiles = (files: File[]) => {
    const newValue = { ...formValues, files };
    onChange?.(newValue);
  };

  return (
    <Card variant="soft" color="neutral">
      <Stack gap={2}>
        <TextField
          placeholder="제목 입력"
          name="title"
          value={title ?? ''}
          onChange={handleChangeValues}
          helperText={
            <>
              <InfoIcon /> 제목을 입력하실 경우 LMS (장문메세지)로 전송됩니다
            </>
          }
        />

        <Textarea
          variant="outlined"
          name="message"
          placeholder={MESSAGE_TEXTAREA_PLACEHOLDER}
          minRows={12}
          maxRows={12}
          value={message ?? ''}
          onChange={handleChangeValues}
          endDecorator={
            <Stack width="100%" direction="row">
              <Stack direction="row" alignItems="center" gap={1}>
                <Chip variant="solid" color="primary">
                  {messageType}
                </Chip>
                <Typography>{contentCount} Byte</Typography>
              </Stack>

              <Button
                variant="outlined"
                color="neutral"
                size="sm"
                startDecorator={<RefreshIcon />}
                onClick={handleResetMessageForm}
                sx={{ ml: 'auto' }}
              >
                초기화
              </Button>
            </Stack>
          }
        />

        <Stack gap={1}>
          <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
            첨부파일
          </Typography>

          <FileDragDrop
            variant="outlined"
            color="neutral"
            accept="image/png, image/jpg, image/jpeg"
            fullWidth
            onSelectFiles={handleSelectFiles}
          />

          <FileThumbnailList
            value={files}
            onChange={handleChangeFiles}
            labelGetter={(file) => file.name}
            thumnailGetter={(file) => URL.createObjectURL(file)}
          />
        </Stack>

        {actions && actions}
      </Stack>
    </Card>
  );
};

export default MessageForm;

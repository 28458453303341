import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import useFeedback from 'hooks/useFeedback';

interface TallyResultEnterModalProps {
  open: boolean;

  onClose: (open: false) => void;
  onSubmit: (password: string) => void;
}

const TallyResultEnterModal = ({
  open,

  onClose,
  onSubmit,
}: TallyResultEnterModalProps) => {
  const { alertDialog } = useFeedback();
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState('');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleOnSubmit = () => {
    if (!isChecked) {
      return alertDialog('주의사항을 확인하시고 내용에 동의해 주세요.');
    }

    if (!password) {
      return alertDialog('비밀번호를 입력해주세요.');
    }

    onSubmit(password);
  };

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
    setPassword('');
  };

  useEffect(() => {
    setIsChecked(false);
    setPassword('');
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <Typography
            alignSelf="center"
            color="danger"
            level="h2"
            startDecorator={<InfoIcon />}
          >
            개표 결과 입력 주의 사항
          </Typography>
          <DialogContent>
            <Stack gap={3}>
              <Callout color="danger" variant="soft">
                <Typography level="title-lg">
                  다음으로 넘어가면 총회 진행 상태가 집계 중으로 변경됩니다.
                </Typography>
                <Typography level="body-md">
                  중복 투표자가 있는 경우 개표 결과를 입력할 수 없습니다. 집계
                  중으로 변경되면 총회관리 시스템(VMS)은 읽기 권한만 주어집니다.
                  결과 입력 전, 수정이 필요하시면 취소 버튼을 눌러 총회관리
                  시스템(VMS)에서 추가 작업을 완료한 뒤 개표 결과를 입력해
                  주세요.
                  <br />
                  <br />
                  개표 결과 입력을 완료한 뒤에 재입력을 통해 다시 진행 중 상태로
                  돌아올 수 있습니다.
                  <br />
                  <br />
                  유효한 전자투표 결과는 자동으로 집계되고, 개표 결과는 총회
                  종료 전까지 수정할 수 있습니다. 입력이 완료되면 안건의 의결
                  결과를 선택하여 총회를 종료할 수 있습니다.
                </Typography>
              </Callout>

              <Checkbox
                color="primary"
                label="확인하였습니다."
                onChange={(e) => setIsChecked(e.target.checked)}
              />

              <Input
                type="password"
                name="password"
                placeholder="총회 비밀번호를 입력하세요."
                onChange={handleOnChange}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth type="submit" onClick={handleOnSubmit}>
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={handleClose}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default TallyResultEnterModal;

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typography } from '@wooriga/design-system';

export const compareObjects = (obj1: any, obj2: any) => {
  if (!obj2) return {};

  const diff: Record<string, any> = {};

  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  allKeys.forEach((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (
      typeof val1 === 'object' &&
      val1 !== null &&
      typeof val2 === 'object' &&
      val2 !== null
    ) {
      const nestedDiff = compareObjects(val1, val2);
      if (Object.keys(nestedDiff).length > 0) {
        diff[key] = nestedDiff;
      }
    } else if (val1 !== val2) {
      diff[key] = val2;
    }
  });

  return diff;
};

export const combineValues = (targetObj: any, compareObj: any) => {
  const newValues: Record<string, any> = {};

  (function loop(
    targetObject: any = targetObj,
    compareObject: any = compareObj,
    values = {},
  ) {
    Object.keys(compareObject).forEach((key) => {
      const value = targetObject[key];
      if (!value) return;

      if (typeof value === 'string') {
        Object.assign(values, {
          [key]: (
            <span>
              <Typography
                sx={{ textDecoration: 'line-through' }}
                level="body-sm"
              >
                {compareObject[key]}
              </Typography>

              <Typography level="body-sm" color="danger">
                → {value}
              </Typography>
            </span>
          ),
        });

        Object.assign(newValues, values);
      }
      if (typeof value === 'object') {
        loop(targetObject[key], compareObject[key], value);
      }
    });
  })();

  return newValues;
};

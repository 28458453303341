import JoyDrawer from '@mui/joy/Drawer';
import type { DrawerProps as JoyDrawerProps } from '@mui/joy/Drawer';
import { ForwardedRef, forwardRef } from 'react';

export type DrawerProps = JoyDrawerProps;

const Drawer = (props: DrawerProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyDrawer {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, DrawerProps>(Drawer);

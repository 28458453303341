export type ValidationOptions<T> = {
  minLength?: number;
  maxLength?: number;
  regex?: RegExp;
  equals?: T;
};

export const validateInput = <T = string>(
  value: T,
  options: ValidationOptions<T> = {},
): boolean => {
  const { minLength, maxLength, regex, equals } = options;

  if (typeof value === 'string') {
    if (minLength !== undefined && value.length < minLength) {
      return false;
    }

    if (maxLength !== undefined && value.length > maxLength) {
      return false;
    }

    if (regex !== undefined && !regex.test(value)) {
      return false;
    }
  }

  if (equals !== undefined && value !== equals) {
    return false;
  }

  return true;
};

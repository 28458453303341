import styled from '@mui/joy/styles/styled';
import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  Fragment,
  ReactNode,
  Ref,
} from 'react';

import FormControl from 'design-system/components/inputs/FormControl';
import FormHelperText from 'design-system/components/inputs/FormHelperText';
import FormLabel from 'design-system/components/inputs/FormLabel';
import Input, { InputProps } from 'design-system/components/inputs/Input';

export interface JoyFieldProps extends Omit<InputProps, 'placeholder'> {
  label?: ReactNode;
  helperText?: ReactNode;
  inputRef?: Ref<HTMLInputElement>;
  enableAccessibleFieldDOMStructure?: boolean;
  InputProps?: {
    ref?: Ref<HTMLDivElement>;
    endAdornment?: ReactNode;
    startAdornment?: ReactNode;
  };
  formControlSx?: InputProps['sx'];
}

interface JoyFieldComponent extends ForwardRefExoticComponent<JoyFieldProps> {
  propTypes?: never;
}

const JoyField = forwardRef(
  (props: JoyFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      enableAccessibleFieldDOMStructure,

      disabled,
      id,
      label,
      helperText,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      formControlSx,
      startDecorator: startDecoratorProp,
      endDecorator: endDecoratorProp,
      slotProps,
      inputRef,
      ...other
    } = props;

    const startDecorator = (startAdornment || startDecoratorProp) && (
      <Fragment>
        {startAdornment}
        {startDecoratorProp}
      </Fragment>
    );

    const endDecorator = (endAdornment || endDecoratorProp) && (
      <Fragment>
        {endAdornment}
        {endDecoratorProp}
      </Fragment>
    );

    return (
      <JoyFieldRoot
        disabled={disabled}
        id={id}
        sx={[
          ...(Array.isArray(formControlSx) ? formControlSx : [formControlSx]),
        ]}
        ref={ref}
      >
        {label && <JoyFieldLabel>{label}</JoyFieldLabel>}
        <JoyFieldInput
          ref={ref}
          disabled={disabled}
          startDecorator={startDecorator}
          endDecorator={endDecorator}
          slotProps={{
            ...slotProps,
            root: { ...slotProps?.root, ref: containerRef },
            input: { ...slotProps?.input, ref: inputRef },
          }}
          {...other}
        />
        {helperText && <JoyFieldHelpText>{helperText}</JoyFieldHelpText>}
      </JoyFieldRoot>
    );
  },
) as JoyFieldComponent;

JoyField.displayName = 'JoyField';

const JoyFieldRoot = styled(FormControl, {
  name: 'JoyField',
  slot: 'root',
})``;

const JoyFieldLabel = styled(FormLabel, {
  name: 'JoyField',
  slot: 'label',
})``;

const JoyFieldInput = styled(Input, {
  name: 'JoyField',
  slot: 'input',
})``;

const JoyFieldHelpText = styled(FormHelperText, {
  name: 'JoyField',
  slot: 'helpText',
})``;

export default JoyField;

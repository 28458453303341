export const utf8Bytes = (text: string) => {
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(text);

  return byteArray.length;
};

export const eucKrBytes = (text: string) => {
  let byteLength = 0;

  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    if (charCode <= 0x007f) {
      byteLength += 1; // ASCII 문자
    } else if (charCode <= 0x07ff) {
      byteLength += 2; // 라틴 문자 등
    } else if (charCode <= 0xffff) {
      byteLength += 2; // 한글 등 BMP 문자
    } else {
      byteLength += 3; // 나머지 문자 (잘 사용되지 않음)
    }
  }
  return byteLength;
};

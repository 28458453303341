import { useMutation, UseMutationResult } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { UnionRegisterInfo } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

export interface UseHasrepresentativeMutationParams {
  unionSeq: number;
  unionRegisterNoMain: number;
  unionRegisterSeq?: number;
}

export const useHasRepresentativeMutation = (): UseMutationResult<
  ApiResponseData<boolean>,
  ApiError,
  UseHasrepresentativeMutationParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, unionRegisterNoMain } = request;

      const { data } = await axiosInstance.get(
        `/unions/${unionSeq}/registers`,
        {
          params: { unionRegisterNoMain },
        },
      );

      const result =
        data?.data?.filter(
          ({ isRepresentative, unionRegisterSeq }: UnionRegisterInfo) =>
            isRepresentative && unionRegisterSeq !== request.unionRegisterSeq,
        ).length === 0;

      return { ...data, data: result };
    },
  });

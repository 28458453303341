import JoyLink from '@mui/joy/Link';
import type { LinkProps as JoyLinkProps } from '@mui/joy/Link';
import { ForwardedRef, forwardRef, useCallback, MouseEvent } from 'react';
import { LinkProps as RouteDomLinkProps } from 'react-router-dom';

export interface LinkProps extends Omit<JoyLinkProps, 'href'> {
  href?: RouteDomLinkProps['to'] & JoyLinkProps['href'];
}

const Link = (props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const { href, onClick, ...other } = props;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (!href) event.preventDefault();

      onClick?.(event);
    },
    [href, onClick],
  );

  return <JoyLink {...other} href={href} onClick={handleClick} ref={ref} />;
};

export default forwardRef<HTMLAnchorElement, LinkProps>(Link);

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Callout,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Link,
  List,
  ListItem,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useFileDownloadMutation } from 'apis/common/apis';

const ACCEPT_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];
const FILE_INPUTS = [
  {
    name: 'communicationFile',
    label: '통신서비스이용증명원',
    required: true,
  },
  {
    name: 'attorneyFile',
    label: '위임장',
    required: true,
  },
  {
    name: 'businessRegistrationFile',
    label: '조합 사업자등록증',
    required: false,
  },
  {
    name: 'employmentCertificateFile',
    label: '재직증명서',
    required: false,
  },
  {
    name: 'identityFile',
    label: '신분증 사본(뒷자리 제거)',
    required: false,
  },
];

const DEFAULT_FORM_VALUES = {
  outgoingPhoneNo: '',
  name: '',
  phoneNo: '',
  communicationFile: undefined,
  attorneyFile: undefined,
  businessRegistrationFile: undefined,
  employmentCertificateFile: undefined,
  identityFile: undefined,
};

export interface CallerAdditionValues {
  outgoingPhoneNo: string;
  name: string;
  phoneNo: string;
  communicationFile: File;
  attorneyFile: File;
  businessRegistrationFile: File | undefined;
  employmentCertificateFile: File | undefined;
  identityFile: File | undefined;
}

const schema = yup.object({
  outgoingPhoneNo: yup.string().required('발신번호를 입력해 주세요'),
  name: yup.string().required('소유자명을 입력해 주세요.'),
  phoneNo: yup.string().required('비상연락처를 입력해 주세요.'),
  communicationFile: yup
    .mixed()
    .required('통신서비스이용증명원을 첨부해 주세요'),
  attorneyFile: yup.mixed().required('위임장을 첨부해 주세요'),
  businessRegistrationFile: yup.mixed().nullable(),
  employmentCertificateFile: yup.mixed().nullable(),
  identityFile: yup.mixed().nullable(),
});

type CallerAdditionFormValues = yup.InferType<typeof schema>;

export interface MessageCallerAdditionModalProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'component' | 'onSubmit'> {
  isSubmitLoading?: boolean;
  onSubmit?: (data: CallerAdditionValues) => void;
  onClose: (value: boolean) => void;
}

const MessageCallerAdditionModal = (props: MessageCallerAdditionModalProps) => {
  const { isSubmitLoading = false, onSubmit, onClose, ...rest } = props;

  const fileRefs = useRef<(HTMLDivElement | null)[]>([]);

  const {
    control,
    handleSubmit: validateSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm<CallerAdditionFormValues>({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const { mutate: downloadFile } = useFileDownloadMutation();

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    reset();
    onClose(false);
  };

  const handleClickOpenFileFinder = (index: number) => () => {
    if (fileRefs.current[index]) {
      fileRefs.current[index].click();
    }
  };

  const handleClickDownloadAttorneyFile = () => {
    downloadFile(
      { fileCode: 'SMS_ATTORNEY' },
      {
        onSuccess: (response) => {
          const blob = new Blob([response]);
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = '위임장.hwp';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(url);
        },
      },
    );
  };

  const handleChangeFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, files: originalFiles } = event.currentTarget;

    const files = Array.from(originalFiles ?? []);

    const isAcceptFiles = files.every((file) =>
      ACCEPT_FILE_TYPES.includes(file.type),
    );

    if (!isAcceptFiles) {
      return;
    }

    setValue(name as keyof CallerAdditionValues, files[0]);
    trigger(name as keyof CallerAdditionValues);
  };

  const handleSubmit = (data: CallerAdditionFormValues) => {
    onSubmit?.(data as CallerAdditionValues);
  };

  return (
    <Modal
      {...rest}
      component="form"
      onClose={handleClose}
      onSubmit={validateSubmit(handleSubmit)}
    >
      <ModalOverflow>
        <ModalDialog>
          <DialogTitle>발신번호 추가</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack gap={2}>
              <Stack direction="row" justifyContent="space-between" gap={2}>
                <Box sx={{ flex: 1 }}>
                  <Controller
                    control={control}
                    name="outgoingPhoneNo"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="발신번호"
                        placeholder="숫자만 입력"
                        sx={{ flex: 1 }}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        validateOptions={{
                          maxLength: 11,
                          regex: /^[0-9]*$/,
                        }}
                      />
                    )}
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="발신번호 소유자명"
                        placeholder="소유자명 입력"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Controller
                    control={control}
                    name="phoneNo"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="비상연락처"
                        placeholder="비상연락처 입력"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        validateOptions={{
                          maxLength: 11,
                          regex: /^[0-9]*$/,
                        }}
                      />
                    )}
                  />
                </Box>
              </Stack>

              <Stack>
                <Typography level="body-sm">필요서류 안내</Typography>

                <Stack direction="row" justifyContent="space-evenly" gap={2}>
                  <Card variant="soft" color="primary" sx={{ flex: 1 }}>
                    <Stack gap={1.5}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textAlign="center"
                      >
                        조합명의 번호 등록
                      </Typography>

                      <List component="ol" marker="disc">
                        <ListItem>통신서비스이용증명원</ListItem>
                        <ListItem>
                          <Link onClick={handleClickDownloadAttorneyFile}>
                            위임장
                          </Link>
                        </ListItem>
                        <ListItem>사업자 등록증</ListItem>
                      </List>
                    </Stack>
                  </Card>

                  <Card variant="soft" color="primary" sx={{ flex: 1 }}>
                    <Stack gap={1.5}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textAlign="center"
                      >
                        조합장, 운영진, 직원번호 등록
                      </Typography>

                      <List component="ol" marker="disc">
                        <ListItem>사업자 등록증</ListItem>
                        <ListItem>통신서비스 이용증명원</ListItem>
                        <ListItem>위임장</ListItem>
                        <ListItem>재직증명서</ListItem>
                        <ListItem>신분증사본 (뒷자리제거)</ListItem>
                      </List>
                    </Stack>
                  </Card>
                </Stack>
              </Stack>

              <Callout variant="outlined" color="warning">
                통신서비스 이용 증명원은 가려진 부분이 없고, 3개월 이내 발급된
                서류만 가능합니다.
                <br />
                통신서비스 이용 증명원은 가입하신 통신사에서 발급받으실 수
                있습니다. <br />
                (KT : 원부 증명서, SKT : 이용계약 등록사항 증명서, LG : 서비스
                가입 확인서)
                <br />
                재직증명서와 신분증 사본(뒷자리 제거)을 하나로 합쳐서 등록해
                주세요. <br />
                파일 첨부 시 이미지 파일 (pdf, jpeg, jpg, png)로 첨부해 주세요.
              </Callout>

              <Stack gap={2}>
                {FILE_INPUTS.map(({ name, label, required }, index) => {
                  const placeholder = `[${
                    required ? '필수' : '선택'
                  }] 파일을 등록하세요`;
                  const file = watch(
                    name as keyof CallerAdditionValues,
                  ) as File;

                  return (
                    <Box key={`file_input_${name}`} sx={{ flex: 1 }}>
                      <Input
                        type="file"
                        defaultValue=""
                        name={name}
                        sx={{ display: 'none' }}
                        slotProps={{
                          input: {
                            ref: (element) =>
                              (fileRefs.current[index] = element),
                            accept: ACCEPT_FILE_TYPES.join(','),
                          },
                        }}
                        onChange={handleChangeFileInput}
                      />
                      <TextField
                        label={label}
                        value={file?.name ?? ''}
                        required={required}
                        placeholder={placeholder}
                        readOnly
                        error={!!errors[name as keyof CallerAdditionFormValues]}
                        helperText={
                          errors[name as keyof CallerAdditionFormValues]
                            ?.message
                        }
                        endDecorator={
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={handleClickOpenFileFinder(index)}
                          >
                            파일첨부
                          </Button>
                        }
                      />
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button type="submit" loading={isSubmitLoading}>
              추가
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              disabled={isSubmitLoading}
              onClick={() => {
                reset();
                onClose(false);
              }}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default MessageCallerAdditionModal;

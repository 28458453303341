import JoyAccordion from '@mui/joy/Accordion';
import type { AccordionProps as JoyAccordionProps } from '@mui/joy/Accordion';
import {
  Children,
  ForwardedRef,
  cloneElement,
  forwardRef,
  isValidElement,
} from 'react';

import { AccordionDetailsProps } from 'design-system/components/surfaces/accordion/AccordionDetails';
import { AccordionSummaryProps } from 'design-system/components/surfaces/accordion/AccordionSummary';

export type AccordionProps = JoyAccordionProps;

const Accordion = (
  { children, color, variant, ...rest }: AccordionProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <JoyAccordion {...rest} ref={ref}>
      {Children.map(children, (child) => {
        if (
          isValidElement<AccordionSummaryProps | AccordionDetailsProps>(
            child,
          ) &&
          variant === 'solid'
        ) {
          return cloneElement(child, {
            color,
            variant,
          });
        }

        return child;
      })}
    </JoyAccordion>
  );
};

export default forwardRef<HTMLDivElement, AccordionProps>(Accordion);

import {
  AspectRatio,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
} from '@wooriga/design-system';
import { useEffect, useMemo } from 'react';

export interface FormPreviewModalProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  data:
    | {
        title: string;
        blob: Blob | null;
      }
    | undefined;
  loading?: boolean;
  title?: string;
  onClose?: () => void;
}

const INITIAL_DATA = {
  title: '',
  blob: new Blob(),
};

const FormPreviewModal = (props: FormPreviewModalProps) => {
  const { data = INITIAL_DATA, loading = false, onClose, ...rest } = props;
  const { title, blob } = data;

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    onClose?.();
  };

  const src = useMemo(() => {
    if (!blob) {
      return '';
    }

    return URL.createObjectURL(blob);
  }, [blob]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(src);
    };
  }, [src]);

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog minWidth="650px">
          <DialogTitle>{title} 미리보기</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <AspectRatio ratio="1/1.4" objectFit="fill">
              {loading ? (
                <CircularProgress />
              ) : (
                <img src={src ?? ''} alt={`${title} 미리보기`} />
              )}
            </AspectRatio>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>닫기</Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default FormPreviewModal;

import * as _ from 'lodash-es';
import { ReactNode } from 'react';
import { StateCreator } from 'zustand';

import {
  AlertOptions,
  ConfirmOptions,
  createFeedbackModalElement,
  createFeedbackSnackbarElement,
  SnackbarOptions,
  createFeedbackDialogElement,
} from 'components/Feedback/elements';

const DEFAULT_SNACKBAR_STACK_LIMIT = 2;

export type FeedbackSlice = {
  limitSnackbars: number;
  snackbars: Map<string, ReactNode>;
  setLimitSnackbars: (limit: number) => void;
  snackbar: (message: string | ReactNode, options?: SnackbarOptions) => void;
  unmountSnackbar: (id: string) => void;
  clearSnackbars: () => void;

  feedbackDialogs: Map<string, ReactNode>;
  alertDialog: (detail: ReactNode, options?: AlertOptions) => void;
  confirmDialog: (detail: ReactNode, options?: ConfirmOptions) => void;
  unmountFeedbackDialog: (id: string) => void;
  unmountLatestFeedbackDialog: () => void;
  clearFeedbackDialogs: () => void;

  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const createFeedbackSlice: StateCreator<
  FeedbackSlice,
  [],
  [],
  FeedbackSlice
> = (set, get) => ({
  limitSnackbars: DEFAULT_SNACKBAR_STACK_LIMIT,
  snackbars: new Map(),

  // SnackBar 노출 개수 제한 (default: 1)
  setLimitSnackbars: (limit) =>
    set((state) => ({ ...state, limitSnackbars: limit })),

  // SnackBar 관련 Control 함수들
  snackbar: (message: string | ReactNode, options?: SnackbarOptions) =>
    set(() => {
      const previousStack = [...get().snackbars];
      if (previousStack.length + 1 > get().limitSnackbars) {
        previousStack.shift();
      }

      const id = _.uniqueId();
      const snackbarId = `snackbar-${id}`;

      const clonedStack = new Map(previousStack);
      const element = createFeedbackSnackbarElement(
        message,
        snackbarId,
        options,
        () => get().unmountSnackbar(snackbarId),
      );

      clonedStack.set(snackbarId, element);

      return { snackbars: clonedStack };
    }),
  unmountSnackbar: (id) =>
    set(() => {
      const previousStack = get().snackbars;
      const clonedStack = new Map(previousStack);

      clonedStack.delete(id);

      return { snackbars: clonedStack };
    }),
  clearSnackbars: () => set(() => ({ snackbars: new Map() })),

  // Dialog 관련 Control 함수들
  feedbackDialogs: new Map(),
  alertDialog: (detail: ReactNode, options: AlertOptions = {}) =>
    set(() => {
      const previousStack = get().feedbackDialogs;
      const clonedStack = new Map(previousStack);

      const { message, ...restOptions } = options;

      const id = _.uniqueId();
      const alertDialogId = `alert-dialog-${id}`;

      const element = createFeedbackModalElement(
        options,
        createFeedbackDialogElement(detail, restOptions, message, () => {
          options?.onSubmit?.();
          get().unmountFeedbackDialog(alertDialogId);
        }),
        () => {
          get().unmountFeedbackDialog(alertDialogId);
        },
      );

      clonedStack.set(alertDialogId, element);

      return { feedbackDialogs: clonedStack };
    }),

  // ConfirmDialog 관련 Control 함수들
  confirmDialog: (detail: ReactNode, options: ConfirmOptions = {}) =>
    set(() => {
      const previousStack = get().feedbackDialogs;
      const clonedStack = new Map(previousStack);

      const { message, onCancel, onConfirm, ...restOptions } = options;

      const id = _.uniqueId();
      const confirmId = `confirm-${id}`;

      const element = createFeedbackModalElement(
        options,
        createFeedbackDialogElement(
          detail,
          { ...restOptions, type: 'OKCancel', variant: 'outlined' },
          message,
          (result) => {
            result === 'Cancel' && onCancel?.();
            result === 'OK' && onConfirm?.();

            get().unmountFeedbackDialog(confirmId);
          },
        ),
        () => get().unmountFeedbackDialog(confirmId),
      );

      clonedStack.set(confirmId, element);

      return { feedbackDialogs: clonedStack };
    }),

  unmountFeedbackDialog: (id: string) =>
    set(() => {
      const previousStack = get().feedbackDialogs;
      const clonedStack = new Map(previousStack);

      clonedStack.delete(id);

      return { feedbackDialogs: clonedStack };
    }),
  unmountLatestFeedbackDialog: () =>
    set(() => {
      const previousStack = [...get().feedbackDialogs];
      previousStack.pop();

      const clonedStack = new Map(previousStack);

      return { feedbackDialogs: clonedStack };
    }),
  clearFeedbackDialogs: () =>
    set(() => {
      return { feedbackDialogs: new Map() };
    }),

  loading: false,
  setLoading: (loading: boolean) => set((state) => ({ ...state, loading })),
});

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Snackbar,
  SnackbarClose,
  DataGrid,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import {
  UNION_REGISTER_TABLE_COLUMNS,
  UnionRegistersTableProps,
} from 'apis/meet/fixtures';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import { UnionRegistersQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { VoterManagementTableColumns } from 'lim/generalMeetingHistoryDetail/fixtures';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

interface VoterAddModalProps {
  unionSeq: number;
  unionRegistersQuery: UnionRegistersQuery;
  addedUnionRows: VoterManagementTableColumns[];
  open: boolean;
  onClose: (open: false) => void;
  onSubmit: (rows: UnionRegistersTableProps[]) => void;
}

const VoterAddModal = ({
  unionSeq,
  unionRegistersQuery,
  addedUnionRows,
  open,
  onClose,
  onSubmit,
}: VoterAddModalProps) => {
  const [showToast, setShowToast] = useState(false);

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [unionRegisterSeq, selectedUnionRegisterSeq] = useState(0);

  const { data, isLoading, isError, error } = unionRegistersQuery;

  const rows: UnionRegistersTableProps[] = useMemo(() => {
    return (
      data?.data
        ?.filter((data) => {
          const isExist = addedUnionRows.some(
            (originRow) => originRow.unionRegisterSeq === data.unionRegisterSeq,
          );
          // console.log(data.unionRegisterNo, isExist);
          // console.log(
          //   isExist,
          //   data.unionRegisterSeq,
          //   data.unionRegisterNo,
          //   data.name,
          //   addedUnionRows,
          // );
          return !isExist;
        })
        .map((data) => {
          const {
            unionRegisterSeq,
            unionRegisterNo,
            positionDescription,
            agent,
            shareType,
            name,
            gender,
            birth,
            mainPhone,
            localAddresses,
          } = data;

          return {
            id: unionRegisterSeq,
            unionRegisterNo,
            positionDescription,
            agent: agent ? 'O' : 'X',
            shareType,
            name: name?.name,
            gender,
            birth,
            phoneNo: mainPhone?.phoneNo,
            localAddresses: localAddresses[0].zoneRoad,
          };
        }) || []
    );
  }, [data?.data, addedUnionRows]);

  // const [filteredRows, setFilteredRows] = useState(data.result.content);

  const handleOnRowSelect = <Model,>(ids: Model) => {
    setSelectedIds(ids as number[]);
  };

  // const handleOnFilterChange = (props: { union: string[] } | undefined) => {
  //   const filter = props!.union;

  //   if (filter.length === 1) {
  //     const isSelected = filter[0] === 'selected';

  //     const selectedIDs = new Set(selectedIds);
  //     const filteredRows = rows.filter((row) => {
  //       if (isSelected && selectedIDs.has(row.id)) {
  //         return true;
  //       }
  //       if (!isSelected && !selectedIDs.has(row.id)) return true;
  //     });

  //     return setFilteredRows(filteredRows);
  //   }

  //   setFilteredRows(rows);
  // };

  const handleSubmit = () => {
    if (!selectedIds.length) {
      return setShowToast(true);
    }

    const selectedIDs = new Set(selectedIds);
    const filteredRows = rows.filter((row) => {
      if (selectedIDs.has(row.id)) {
        return true;
      }
      return false;
    });

    onSubmit(filteredRows);
  };

  const handleOnNameClick = (id: string | number) => {
    setShowUnionInfoModal(true);
    selectedUnionRegisterSeq(Number(id));
  };

  const { columns } = useCreateGridColumns({
    handlers: {
      onLinkClick: handleOnNameClick,
    },
    columns: UNION_REGISTER_TABLE_COLUMNS,
  });

  if (isError) throw error;

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog>
          <DialogTitle>선거인 추가하기</DialogTitle>
          <DialogContent>
            <Stack gap={1} marginBottom={3}>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                <span>
                  <strong>추가 된 조합원 정보만 불러오는 기능</strong> 입니다.
                  다시 한번 확인해 주세요.
                </span>
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                선거인을 불러오시려면 조합원 명부 관리에서 추가하시려는 선거인을
                조합원 명부에 등록해 주세요
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                빨간색으로 표시 항목은 누락 된 정보가 있는 조합원 입니다. 다시
                한번 확인해 주세요.
              </Typography>
              <Typography
                level="body-md"
                color="danger"
                startDecorator={<InfoIcon />}
              >
                이름, 성별, 연락처, 생년월일 정보가 누락 또는 오입력 된 경우
                전자투표의 수신 또는 열람이 불가합니다. 조합원 명부 관리
                페이지의 조합원 정보에서 수정해 주세요.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                전자투표인 경우 대리인을 선임하지 않은 법인의 경우 본인인증이
                불가하여 전자투표 진행이 어려울 수 있습니다.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                대리인이 등록된 조합원의 경우 대리인유무에 ‘○’ 가 표시됩니다.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                기존 선거인 명부에 있는 연번과 중복이 되는 조합원은 파란색으로
                표시됩니다. 중복된 조합원 중 하나를 삭제하시고 다시
                진행해주세요.
              </Typography>
            </Stack>

            <Stack gap={2}>
              <Stack flexDirection="row" gap={1}>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  전체{' '}
                  <Typography color="primary">
                    {commaizeNumber(rows.length)}
                  </Typography>
                </Typography>

                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  조회 목록{' '}
                  <Typography color="primary">
                    {commaizeNumber(rows.length)}
                  </Typography>
                </Typography>
              </Stack>
              <Stack height={442}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  loading={isLoading}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={handleOnRowSelect}
                  keepNonExistentRowsSelected
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth type="submit" onClick={handleSubmit}>
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        color="danger"
        startDecorator={<CircleOutlinedIcon />}
        endDecorator={<SnackbarClose />}
        open={showToast}
        onClose={() => setShowToast(false)}
      >
        선택된 조합원이 없습니다.
      </Snackbar>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </>
  );
};

export default VoterAddModal;

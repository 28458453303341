import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | 'none' | 'lax' | 'strict';
  partitioned?: boolean;
}

export interface CookieGetOptions {
  doNotParse?: boolean;
  doNotUpdate?: boolean;
}

/** Key, Value를 받아 Cookie 생성 후 저장
 * @param {string} name
 * @param {string} value
 */
export const setCookie = (
  name: string,
  value: string,
  options?: CookieSetOptions,
) => {
  const cookiesOptions = { path: '/', ...options };

  return cookies.set(name, value, cookiesOptions);
};

/** Key를 받아 Value를 반환
 * @param {string} name
 * @returns {string}
 */
export const getCookie = (name: string, option?: CookieGetOptions) => {
  return cookies.get(name, option);
};

/** Key를 받아 쿠키를 삭제
 * @param {string} name
 */
export const removeCookie = (name: string, options?: CookieSetOptions) => {
  const cookiesOptions = { path: '/', ...options };

  return cookies.remove(name, cookiesOptions);
};

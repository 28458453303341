import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DatePicker,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Modal,
  ModalDialog,
  ModalOverflow,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { ForwardedRef, forwardRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import {
  ApplyTaxRequest,
  useTaxInvoiceRequestMutation,
} from 'apis/charges/apis';
import useFeedback from 'hooks/useFeedback';
import { formatDateTime } from 'utils/format';

export interface ApplyTaxModalProps<TFieldValues> {
  unionSeq: number;
  depositChargeSeq: number;
  defaultValues?: TFieldValues;
  open: boolean;
  onClose: () => void;
}

const schema = yup.object({
  email: yup
    .string()
    .required('이메일을 입력하세요')
    .email('유효한 이메일 형식을 입력하세요'),
  managerName: yup
    .string()
    .required('담당자를 입력하세요')
    .max(10, '10글자까지만 입력이 가능합니다.'),
  managerPhoneNo: yup
    .string()
    .required('담당자 전화번호를 입력하세요')
    .max(11)
    .min(1, '담당자를 입력하세요'),
  depositAt: yup.string().required('입금일자를 입력하세요'),
  requestAt: yup.string().required('발행일자를 입력하세요'),
});

const ApplyTaxModal = (
  props: ApplyTaxModalProps<{ chargeAmount: number }>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { unionSeq, depositChargeSeq, open, onClose } = props;

  const { snackbar } = useFeedback();

  const { mutate: applyTaxInvoiceMutation, isPending: isSubmitLoading } =
    useTaxInvoiceRequestMutation();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Omit<ApplyTaxRequest, 'unionSeq' | 'depositChargeSeq'>>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      managerName: '',
      managerPhoneNo: '',
      depositAt: '',
      requestAt: '',
    },
  });

  const enhancedSubmit = handleSubmit(() => {
    const formData = getValues();

    applyTaxInvoiceMutation(
      { unionSeq, depositChargeSeq, ...formData },
      {
        onSuccess: () => {
          snackbar('세금계산서 발행 신청이 완료되었습니다.', {
            color: 'success',
          });

          onClose();
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  });

  return (
    <>
      <Modal open={open} onClose={onClose} ref={ref}>
        <ModalOverflow>
          <ModalDialog
            sx={{
              width: '600px',
            }}
          >
            <DialogTitle>세금 계산서 발행 정보 </DialogTitle>
            <DialogContent>
              <Stack gap={2}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="세금계산서 발급 이메일"
                      placeholder="이메일 입력"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="managerName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="담당자"
                      placeholder="10자 이내로 입력해 주세요"
                      fullWidth
                      error={!!errors.managerName}
                      helperText={
                        errors.managerName ? errors.managerName.message : ''
                      }
                      validateOptions={{
                        maxLength: 10,
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="managerPhoneNo"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="담당자 전화번호"
                      placeholder="담당자 전화번호를 입력하세요."
                      fullWidth
                      error={!!errors.managerPhoneNo}
                      helperText={
                        errors.managerPhoneNo
                          ? errors.managerPhoneNo.message
                          : ''
                      }
                      validateOptions={{
                        maxLength: 11,
                        regex: /^[0-9]*$/,
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="depositAt"
                  render={({ field, fieldState }) => (
                    <FormControl error={fieldState.invalid}>
                      <DatePicker
                        {...field}
                        color="neutral"
                        variant="outlined"
                        label="입금일자"
                        onChange={(value) =>
                          field.onChange(
                            formatDateTime(
                              value ?? '',
                              "yyyy-MM-dd'T'HH:mm:ss",
                            ),
                          )
                        }
                      />
                      <FormHelperText>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="requestAt"
                  render={({ field, fieldState }) => (
                    <FormControl error={fieldState.invalid}>
                      <DatePicker
                        {...field}
                        variant="outlined"
                        color="neutral"
                        label="세금계산서 발행 요청일자"
                        onChange={(value) =>
                          field.onChange(
                            formatDateTime(
                              value ?? '',
                              "yyyy-MM-dd'T'HH:mm:ss",
                            ),
                          )
                        }
                      />
                      <FormHelperText>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                size="md"
                variant="solid"
                color="primary"
                onClick={enhancedSubmit}
                loading={isSubmitLoading}
              >
                발행신청
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="neutral"
                size="md"
                disabled={isSubmitLoading}
                onClick={onClose}
              >
                취소
              </Button>
            </DialogActions>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
};
export default forwardRef(ApplyTaxModal);

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
  Snackbar,
  SnackbarClose,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useState } from 'react';

import { commaizeNumber } from 'utils/format';

interface VoterAddConfirmModalProps {
  open: boolean;
  price: number;
  onClose: (open: false) => void;
  onConfirm: () => void;
}

const VoterAddConfirmModal = ({
  open,
  price,
  onClose,
  onConfirm,
}: VoterAddConfirmModalProps) => {
  const [showToast, setShowToast] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = () => {
    if (!isChecked) {
      return setShowToast(true);
    }

    onConfirm();
    onClose(false);
  };

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <DialogTitle>선거인 추가</DialogTitle>
          <DialogContent>
            <Stack marginBottom={2}>
              <Typography level="title-md">주의 사항</Typography>
              <Callout color="danger" variant="soft">
                <Stack gap={1} marginBottom={3}>
                  <Typography
                    level="body-md"
                    color="danger"
                    startDecorator={<InfoIcon />}
                  >
                    선택하신 조합원이 선거인으로 추가됩니다.
                  </Typography>
                  <Typography
                    level="body-md"
                    color="danger"
                    startDecorator={<InfoIcon />}
                  >
                    <span>
                      원활하고 안전한 투표를 위하여{' '}
                      <strong>
                        전자투표 마감 72시간 전까지만 발송이 가능합니다.
                      </strong>
                    </span>
                  </Typography>
                  <Typography level="body-md" startDecorator={<InfoIcon />}>
                    투표 정보와 투표 내용은 개설 당시 입력하신 내용과 동일하게
                    전달됩니다.
                  </Typography>
                  <Typography level="body-md" startDecorator={<InfoIcon />}>
                    전자투표 발송은 전자투표 현황에서 별도로 진행하셔야 합니다.
                  </Typography>
                  <Typography level="body-md" startDecorator={<InfoIcon />}>
                    선거인 추가 시 비용은 계약 시 안내 된 금액으로 책정 됩니다.
                  </Typography>
                </Stack>
              </Callout>
            </Stack>
            <Stack marginBottom={2}>
              <Typography level="title-md">신청금액</Typography>
              <Input value={`${commaizeNumber(price)}원`} readOnly />
            </Stack>

            <Stack textAlign="center" marginBottom={2}>
              <Typography level="body-md">
                총회개설 내용 및 선거인 정보에 오류가 없는지 다시 한번 확인해
                주세요.
                <br />
                수정을 원하시면 이전으로 돌아가기를,
                <br />
                선택된 조합원을 선거인 명부에 추가 하시려면
                <br />
                동의 여부를 선택 후 추가하기를 선택해주세요.
                <br />
                선거인 추가 이후 취소 및 환불이 불가능합니다.
              </Typography>
            </Stack>

            <Stack alignItems="center">
              <Checkbox
                label="확인하였습니다."
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth type="submit" onClick={handleSubmit}>
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={handleClose}
            >
              이전
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        color="danger"
        startDecorator={<CircleOutlinedIcon />}
        endDecorator={<SnackbarClose />}
        open={showToast}
        onClose={() => setShowToast(false)}
      >
        주의사항을 확인하시고 내용에 동의해 주세요.
      </Snackbar>
    </>
  );
};

export default VoterAddConfirmModal;

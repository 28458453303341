/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  Link,
  DataGridProps,
  Modal,
  AlertDialog,
  Grid,
  ModalProps,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import {
  useDeleteRegisterGroupsMutation,
  useUnionRegisterGroupsQuery,
} from 'lim/address-group/apis';
import { ADDRESS_GROUP_TABLE_COLUMNS } from 'lim/address-group/fixtures';
import addressGroupCreatePage from 'pages/main/union-management/address-groups/create';
import addressGroupDetail from 'pages/main/union-management/address-groups/detail';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const defaultParams = {
  unionRegisterGroupName: '',
  unionRegisterName: '',
};

const AddressGroupsPage = () => {
  const { snackbar } = useFeedback();
  const { unionSeq } = useParams();
  const unionId = Number(unionSeq);

  const [alertContent, setAlertContent] = useState({
    open: false,
    messageId: '',
    type: '',
    message: '',
    detail: '',
  });

  const [params, setParams] = useState(defaultParams);
  const [alertDialog, setAlertDialog] = useState({
    show: false,
    message: '',
    detail: '',
  });

  const [rowSelectionModel, setRowSelectionModel] = useState<
    DataGridProps['rowSelectionModel']
  >([]);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setAlertOpen(false);
  };

  const { data, refetch, isLoading, isError, error } =
    useUnionRegisterGroupsQuery(unionId, params);
  const { mutate } = useDeleteRegisterGroupsMutation(unionId);

  const handleChangeRowSelectionModel = (
    model: DataGridProps['rowSelectionModel'],
  ) => {
    setRowSelectionModel(model);
  };

  const handleOnDelete = () => {
    const unionRegisterGroupSeqs = rowSelectionModel as number[];
    console.log('unionRegisterGroupSeqs', unionRegisterGroupSeqs);

    if (!unionRegisterGroupSeqs.length) {
      return setAlertDialog({
        show: true,
        message: '삭제할 그룹을 선택해주세요.',
        detail: '삭제할 그룹을 선택해주세요.',
      });
    }

    setAlertContent({
      messageId: '1',
      open: true,
      type: 'REMOVE',
      message: '그룹 삭제 확인',
      detail: `선택한 ${unionRegisterGroupSeqs.length}개의 그룹을 삭제하시겠습니까? \n 삭제 후에는 복구할 수 없습니다.`,
    });
    setAlertOpen(true);
  };

  const handleAlertAction = (type: string) => {
    setAlertOpen(false);
    setAlertContent({
      open: false,
      messageId: '',
      type: '',
      message: '',
      detail: '',
    });
    if (type === 'OK') {
      const unionRegisterGroupSeqs = rowSelectionModel as number[];
      mutate(
        { unionRegisterGroupSeqs },
        {
          onSuccess: () => {
            refetch();
            return snackbar('삭제가 완료되었습니다.', {
              color: 'success',
            });
          },
          onError: (error) => {
            snackbar(error?.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
    }
  };

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const {
          unionRegisterGroupSeq,
          name,
          unionRegisterCount,
          createdAt,
          modifiedAt,
          autoGenerated,
        } = row;
        return {
          id: unionRegisterGroupSeq,
          name,
          unionRegisterCount,
          createdAt,
          modifiedAt,
          autoGenerated,
        };
      }) || []
    );
  }, [data?.data]);

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <Search
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSubmit={setParams}
          onReset={() => setParams(defaultParams)}
          defaultValues={defaultParams}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="그룹명 입력"
                  label="그룹명"
                  name="unionRegisterGroupName"
                  fullWidth
                />
              </Search.Field>
            </Grid>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="이름 입력"
                  label="이름"
                  name="unionRegisterName"
                  fullWidth
                  slotProps={{
                    input: { maxLength: 30 },
                  }}
                />
              </Search.Field>
            </Grid>
          </Grid>
        </Search>
        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(data?.pagination?.totalDataCount || 0)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(data?.pagination?.totalElements || 0)}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Stack height={442}>
          <DataGrid
            rows={rows}
            // className={classes.checkbox}
            columns={ADDRESS_GROUP_TABLE_COLUMNS}
            loading={isLoading}
            checkboxSelection
            disableRowSelectionOnClick
            isRowSelectable={(params) => !params.row?.autoGenerated}
            pagination
            // getRowClassName={(params) =>
            //   params.row.autoGenerated && `autoGenerated`
            // }
            onRowSelectionModelChange={handleChangeRowSelectionModel}
          />
        </Stack>

        <Stack direction="row" alignSelf="end" gap={2}>
          <Button color="danger" variant="outlined" onClick={handleOnDelete}>
            삭제
          </Button>
          <Button component={Link} href="create">
            그룹 추가
          </Button>
        </Stack>
      </Stack>

      <Modal open={alertDialog.show}>
        <AlertDialog
          message={alertDialog.message}
          detail={alertDialog.detail}
          onSubmit={() => setAlertDialog({ ...alertDialog, show: false })}
        />
      </Modal>
      <Modal open={alertOpen} onClose={handleClose}>
        <AlertDialog
          type="OKCancel"
          message={alertContent.message}
          detail={alertContent.detail}
          onSubmit={handleAlertAction}
        />
      </Modal>
    </Stack>
  );
};

const addressGroupsPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/address-groups',
  children: [
    { path: '', index: true, element: <AddressGroupsPage /> },
    addressGroupDetail,
    addressGroupCreatePage,
  ],
  handle: {
    getTitle: () => '그룹 관리',
    getMenuCode: () => 'M0411',
  },
};

export default addressGroupsPage;

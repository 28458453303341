import { useTheme } from '@mui/joy';
import {
  PieCenterLabel,
  PieChart,
  PieChartProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { PropsWithChildren } from 'react';

import EmptyText from 'components/pages/meet-management/history/detail/tabs/VoteHomeTab/Charts/EmptyData';

import PercentLabel from './PercentLabel';
import RateLabel from './RateLabel';

interface PieChartWrapperProps {
  title: string;
  series: PieChartProps['series'];
}

const PieChartWrapper = ({
  title,
  series,
  children,
}: PropsWithChildren<PieChartWrapperProps>) => {
  const theme = useTheme();

  const chartContainerStyle = {
    background: theme.palette.background.surface,
    padding: '12px 20px',
    boxSizing: 'borderBox',
    width: '338px',
    height: '216px',
  };

  return (
    <Stack gap={1} sx={chartContainerStyle}>
      <Typography level="body-md">{title}</Typography>
      {series[0].data.length > 0 ? (
        <PieChart series={series} width={298} height={160}>
          {children}
        </PieChart>
      ) : (
        <EmptyText />
      )}
    </Stack>
  );
};

const PercentLabelWrapper = ({ label }: { label: string }) => {
  return (
    <PieCenterLabel>
      <PercentLabel text={label} />
    </PieCenterLabel>
  );
};

const RateLabelWrapper = ({ label }: { label: string }) => {
  return (
    <PieCenterLabel>
      <RateLabel text={label} />
    </PieCenterLabel>
  );
};

PieChartWrapper.PercentLabel = PercentLabelWrapper;
PieChartWrapper.RateLabel = RateLabelWrapper;

export default PieChartWrapper;

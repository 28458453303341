export const commaizeNumber = (value: string | number | undefined) => {
  if (!value) {
    return value;
  }

  if (typeof value === 'string') {
    return Number(value).toLocaleString();
  }

  return value.toLocaleString();
};

import JoyCircularProgress from '@mui/joy/CircularProgress';
import type { CircularProgressProps as JoyCircularProgressProps } from '@mui/joy/CircularProgress';
import { ForwardedRef, forwardRef } from 'react';

import Modal from 'design-system/components/feedback/Modal';
import Box from 'design-system/components/layout/Box';

export interface CircularProgressModalProps extends JoyCircularProgressProps {
  open: boolean;
}

const CircularProgressModal = (
  props: CircularProgressModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { open, ...other } = props;

  return (
    <Modal open={open}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <JoyCircularProgress {...other} ref={ref} />
      </Box>
    </Modal>
  );
};

export default forwardRef<HTMLDivElement, CircularProgressModalProps>(
  CircularProgressModal,
);

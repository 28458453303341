import {
  Button,
  CheckOption,
  Checkbox,
  CheckboxGroup,
  DateTimePicker,
  FormControl,
  FormLabel,
  PanelCloseButton,
  PanelHeader,
  // PanelCloseButton,
  // PanelHeader,
  Select,
  Stack,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CounselUpdateParams,
  useCounselQuery,
  useCounselUpdateMutation,
} from 'apis/counsel/apis';
// import { useUnionRegisterQuery } from 'apis/union/register/api';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterAgentDescriptions from 'components/pages/common/UnionRegisterAgentDescriptions';
import UnionRegisterDescriptions from 'components/pages/common/UnionRegisterDescriptions';
import BeforeCounselPannel from 'components/pages/counsels/BeforeCounselPannel';
import SidePanel from 'components/SidePanel';
// import useLayoutContext from 'hooks/useLayoutContext';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { formatDateTime } from 'utils/format';

const CounselModifyPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const unionSeq = Number(params.unionSeq);
  const counselSeq = Number(params.counselSeq);

  const { getGroupCode } = useCommonCodes();
  const { confirmDialog } = useFeedback();

  const {
    counselMethodCodeGroup,
    tendencyCodeGroup,
    counselTopicCodeGroup,
    votingWrittenCodeGroup,
    attendExpectedCodeGroup,
  } = useMemo(
    () => ({
      counselMethodCodeGroup: getGroupCode('COUNSEL_METHOD_TYPE'),
      tendencyCodeGroup: getGroupCode('TENDENCY_TYPE'),
      counselTopicCodeGroup: getGroupCode('COUNSEL_TOPIC_TYPE'),
      votingWrittenCodeGroup: getGroupCode('VOTING_WRITTEN_TYPE'),
      attendExpectedCodeGroup: getGroupCode('ATTEND_TYPE'),
    }),
    [getGroupCode],
  );

  const {
    data: unionRegisterInfoReturnData,
    isError,
    error,
  } = useCounselQuery({
    unionSeq,
    counselSeq,
  });

  const unionRegisterInfo = unionRegisterInfoReturnData?.data?.unionRegister;
  const unionRegisterAgentInfo = unionRegisterInfo?.agent;

  const { mutate: updateCounsel } = useCounselUpdateMutation();

  const [open, setOpen] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<CounselUpdateParams>({
    defaultValues: {
      unionSeq,
      unionRegisterSeq: unionRegisterInfo?.unionRegisterSeq,
      counselSeq: counselSeq,
      method: unionRegisterInfoReturnData?.data?.method,
      memo: unionRegisterInfoReturnData?.data?.memo,
      counselAt: unionRegisterInfoReturnData?.data?.counselAt,
      tendency: unionRegisterInfoReturnData?.data?.tendency,
      serviceType: 'OMS',
      topics: unionRegisterInfoReturnData?.data?.topics,
      votingWrittenType: unionRegisterInfoReturnData?.data?.votingWrittenType,
      meetAttendType: unionRegisterInfoReturnData?.data?.meetAttendType,
    },
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleBackToParentPage = useCallback(() => {
    if (isDirty) {
      confirmDialog('작성된 내용이 사라질 수 있습니다.', {
        onConfirm: () => navigate('..', { relative: 'path' }),
      });
      return;
    }

    navigate('..', { relative: 'path' });
  }, [confirmDialog, navigate, isDirty]);

  const enhancedHandleSubmit = handleSubmit((formData) => {
    updateCounsel(formData, {
      onSuccess: () => {
        navigate('..');
      },
    });
  });

  if (isError) throw error;

  return (
    <>
      <SidePanel open={open} onClose={handleClose}>
        <PanelHeader>이전 상담 내역</PanelHeader>
        <BeforeCounselPannel
          unionSeq={unionSeq}
          unionRegisterNo={unionRegisterInfo?.unionRegisterSeq || 0}
        />
        <PanelCloseButton />
      </SidePanel>

      <Stack gap={2}>
        <Stack component="form" onSubmit={enhancedHandleSubmit} gap={2}>
          <Stack gap={5}>
            <Stack gap={3}>
              <UnionRegisterDescriptions data={unionRegisterInfo} />
              <UnionRegisterAgentDescriptions data={unionRegisterAgentInfo} />
            </Stack>

            <Stack gap={1}>
              <Typography
                level="h3"
                fontSize="lg"
                fontWeight="xl"
                lineHeight="xl"
              >
                상담 등록
              </Typography>

              <Stack gap={3}>
                <Stack flexDirection="row" gap={2}>
                  <Stack>
                    <FormControl>
                      <FormLabel>상담 방식</FormLabel>
                      <Controller
                        control={control}
                        name="method"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {counselMethodCodeGroup?.items &&
                              counselMethodCodeGroup.items.map(
                                (counselMethodCode) => (
                                  <CheckOption
                                    key={`method-code-${counselMethodCode.code}`}
                                    value={counselMethodCode.code}
                                  >
                                    {counselMethodCode.name}
                                  </CheckOption>
                                ),
                              )}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack>
                    <Controller
                      control={control}
                      name="counselAt"
                      render={({ field }) => (
                        <DateTimePicker
                          // size="md"
                          // color="neutral"
                          // label="상담 일시"
                          // value={new Date(field.value)}
                          // onChange={(newValue) => field.onChange(newValue)}
                          // sx={{
                          //   width: '416px',
                          // }}
                          {...field}
                          size="md"
                          color="neutral"
                          label="상담 일시"
                          disableFuture
                          value={field.value ? new Date(field.value) : null}
                          onChange={(newValue) =>
                            field.onChange(
                              formatDateTime(
                                newValue ?? '',
                                "yyyy-MM-dd'T'HH:mm:ss",
                              ),
                            )
                          }
                        />
                      )}
                    />
                  </Stack>
                </Stack>

                <Stack flexDirection="row" gap={2}>
                  <Stack>
                    <FormControl>
                      <FormLabel>우호도</FormLabel>
                      <Controller
                        control={control}
                        name="tendency"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {tendencyCodeGroup?.items &&
                              tendencyCodeGroup.items.map((tendencyCode) => (
                                <CheckOption
                                  key={`tendency-code-${tendencyCode.code}`}
                                  value={tendencyCode.code}
                                >
                                  {tendencyCode.name}
                                </CheckOption>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack>
                    <FormControl>
                      <FormLabel>투표/서면</FormLabel>

                      <Controller
                        control={control}
                        name="votingWrittenType"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {votingWrittenCodeGroup?.items &&
                              votingWrittenCodeGroup.items.map(
                                (votingWrittenCode) => (
                                  <CheckOption
                                    key={`voting-written-code-${votingWrittenCode.code}`}
                                    value={votingWrittenCode.code}
                                  >
                                    {votingWrittenCode.name}
                                  </CheckOption>
                                ),
                              )}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack>
                    <FormControl>
                      <FormLabel>참석 유형</FormLabel>

                      <Controller
                        control={control}
                        name="meetAttendType"
                        render={({ field }) => (
                          <Select
                            variant="outlined"
                            size="md"
                            color="neutral"
                            value={field.value}
                            onChange={(_, value) => field.onChange(value)}
                            sx={{
                              width: '200px',
                            }}
                          >
                            {attendExpectedCodeGroup?.items &&
                              attendExpectedCodeGroup.items.map(
                                (votingWrittenCode) => (
                                  <CheckOption
                                    key={`attend-expected-code-${votingWrittenCode.code}`}
                                    value={votingWrittenCode.code}
                                  >
                                    {votingWrittenCode.name}
                                  </CheckOption>
                                ),
                              )}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack>
                  <Controller
                    control={control}
                    name="topics"
                    render={({ field }) => (
                      <CheckboxGroup
                        label="상담 주제"
                        orientation="horizontal"
                        value={field.value}
                        onChange={(values) => field.onChange(values)}
                      >
                        {counselTopicCodeGroup?.items &&
                          counselTopicCodeGroup.items.map(
                            (votingWrittenCode) => (
                              <Checkbox
                                key={`attend-expected-code-${votingWrittenCode.code}`}
                                value={votingWrittenCode.code}
                                label={votingWrittenCode.name}
                              />
                            ),
                          )}
                      </CheckboxGroup>
                    )}
                  />
                </Stack>

                <Stack>
                  <FormControl>
                    <FormLabel>상담 내용</FormLabel>
                    <Controller
                      control={control}
                      name="memo"
                      render={({ field }) => (
                        <Textarea
                          variant="outlined"
                          color="neutral"
                          size="md"
                          placeholder="내용 입력"
                          required
                          sx={{
                            height: '442px',
                          }}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Stack flexDirection="row" justifyContent="flex-end">
            <Stack flexDirection="row" gap={1}>
              <Button size="lg" variant="outlined" onClick={handleOpen}>
                이전 상담 내역
              </Button>
              <Button
                size="lg"
                variant="outlined"
                color="neutral"
                onClick={handleBackToParentPage}
              >
                취소
              </Button>

              <Button size="lg" type="submit">
                저장
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const counselModifyPage: CustomRouteObject = {
  path: ':counselSeq/modify',
  element: <CounselModifyPage />,
};

export default counselModifyPage;

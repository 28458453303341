import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DataGrid,
  DataGridProps,
  DateTimePicker,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CreateMessageBody, useMessageRegistersQuery } from 'apis/message/apis';
import { Caller } from 'apis/types/message';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import MessageForm, {
  MessageFormValues,
} from 'components/pages/messages/MessageForm';
import MessagePreviewModal from 'components/pages/messages/modal/MessagePreviewModal';
import useFeedback from 'hooks/useFeedback';
import useStepperDialog from 'hooks/useStepperDialog';

const schema = yup.object({
  outgoingPhoneNo: yup.string().required('발신번호를 선택해 주세요.'),
  sendType: yup.string().required('발송방식을 선택해 주세요.'),
  scheduledAt: yup.string().when('sendType', {
    is: 'SCHEDULE',
    then: (schema) => schema.required('예약발송일시를 선택해 주세요.'),
  }),
  recipients: yup
    .array()
    .required()
    .min(1, '선택된 수신자가 없습니다. 수신자를 선택해 주세요.'),
  message: yup
    .string()
    .when('files', {
      is: (value: File[]) => value.length === 0,
      then: (schema) =>
        schema.required('발송할 문자 내용을 입력 또는 이미지를 첨부해 주세요.'),
    })
    .default(''),
  files: yup.array(),
  title: yup.string().default(''),
});

type MessageFormSchema = yup.InferType<typeof schema>;
export interface MessageAgreementStepProps {
  // rows: MessageRegister[];
  callers: Caller[];
  columns: DataGridProps['columns'];
  onSubmit?: (data: MessageFormSchema) => void;
  unionSeq: number;
}

const MessageAgreementStep = ({
  // rows,
  callers,
  columns = [],
  onSubmit,
  unionSeq,
}: MessageAgreementStepProps) => {
  const { getGroupCode } = useCommonCodes();
  const { snackbar } = useFeedback();
  const { values, updateValues, stepNext, PreviousButton } = useStepperDialog<
    MessageFormSchema & { uniqueIds: string[] }
  >();

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<
    DataGridProps['rowSelectionModel']
  >(values?.uniqueIds?.map((id) => id) ?? []);

  const { data: consentMeesageRegistersReturnData, isPending } =
    useMessageRegistersQuery({
      unionSeq,
      consentStatus: '',
    });

  // const rows = ConsentMeesageParticipantList?.data | [];
  // const rows = [];
  const rows = useMemo(
    () => consentMeesageRegistersReturnData?.data || [],
    [consentMeesageRegistersReturnData],
  );

  const sendTypeCodeGroup = useMemo(
    () => getGroupCode('SMS_SEND_TYPE'),
    [getGroupCode],
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit: validateForm,
  } = useForm<MessageFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      outgoingPhoneNo: callers?.[0]?.outgoingPhoneNo || '',
    },
    values,
  });

  const title = watch('title');
  const message = watch('message');
  const files = watch('files') ?? [];
  const isReservation = watch('sendType') === 'SCHEDULE';

  const handleChangeRowSelectionModel = (
    model: DataGridProps['rowSelectionModel'],
  ) => {
    if (!Array.isArray(model)) {
      return;
    }

    setRowSelectionModel(model);
    setValue(
      'recipients',
      rows
        .filter(({ uniqueId }) => model.includes(uniqueId))
        .map(({ unionRegisterSeq, name, phoneNo }) => ({
          unionRegisterSeq,
          name: name?.name,
          phoneNo,
        })),
    );
  };

  const handleChangeMessageForm = (data: MessageFormValues) => {
    Object.entries(data).map(([key, value]) =>
      setValue(key as FieldPath<CreateMessageBody>, value),
    );
  };

  const handleSubmit = validateForm(
    (data) => {
      updateValues({ ...data, uniqueIds: rowSelectionModel });
      onSubmit?.(data);
      stepNext();
    },
    (error) => {
      const message = Object.values(error)[0].message;
      snackbar(message, { color: 'danger' });
    },
  );

  return (
    <>
      <Grid
        component="form"
        container
        rowGap={2.5}
        columnGap={5}
        onSubmit={handleSubmit}
      >
        <Grid xs={12}>
          <Typography color="neutral">
            동의서 접수를 위한 수신인을 선택해 주세요.
            <br />
          </Typography>
        </Grid>

        <Grid xs={7.5}>
          <Stack
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Stack direction="row" gap={2}>
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>발신번호 선택</FormLabel>

                <Controller
                  control={control}
                  name="outgoingPhoneNo"
                  render={({ field: { onChange, ...other } }) => (
                    <Select
                      placeholder={
                        callers.length > 0 ? '발신번호 선택' : '발신번호 없음'
                      }
                      {...other}
                      disabled={callers.length === 0}
                      onChange={(_, value) => onChange(value)}
                    >
                      {callers.map(
                        ({ smsCallerSeq, outgoingPhoneNo, name }) => (
                          <Option
                            key={`caller_${smsCallerSeq}`}
                            value={outgoingPhoneNo}
                          >
                            {outgoingPhoneNo} {name}
                          </Option>
                        ),
                      )}
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl>
                <FormLabel>발송 시간 설정</FormLabel>

                <Stack flexDirection="row" gap={2}>
                  <Controller
                    control={control}
                    name="sendType"
                    render={({ field }) => (
                      <RadioGroup orientation="horizontal" {...field}>
                        {sendTypeCodeGroup?.items.map(({ code, name }) => (
                          <Radio
                            key={`message_method_${code}_radio`}
                            label={name}
                            value={code}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />

                  <Controller
                    control={control}
                    name="scheduledAt"
                    disabled={!isReservation}
                    render={({ field }) => (
                      <DateTimePicker {...field} color="neutral" disablePast />
                    )}
                  />
                </Stack>
              </FormControl>
            </Stack>

            <Stack height={562}>
              <DataGrid
                rows={rows}
                columns={columns}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={handleChangeRowSelectionModel}
                getRowId={(row) => row.uniqueId}
                loading={isPending}
                checkboxSelection
                disableRowSelectionOnClick
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid xs={4}>
          <Stack width={380}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>문자 내용 작성</FormLabel>

              <MessageForm
                value={{ title, message, files }}
                onChange={handleChangeMessageForm}
              />
            </FormControl>
          </Stack>
        </Grid>

        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => setIsPreviewModalOpen(true)}
            >
              미리보기
            </Button>

            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <PreviousButton />
              <Button type="submit">다음</Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      {isPreviewModalOpen && (
        <MessagePreviewModal
          open={isPreviewModalOpen}
          onClose={setIsPreviewModalOpen}
          data={{ title, message, files }}
        />
      )}
    </>
  );
};

export default MessageAgreementStep;

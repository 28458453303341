import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import { ApiError, ApiResponseData } from 'types/api';

interface UnionSeq {
  unionSeq: number;
}
export interface UnionRegisterStatusQueryReturnData {
  status: string;
}

export const useRegisterStatusQuery = (
  params: UnionSeq,
): UseQueryResult<
  ApiResponseData<UnionRegisterStatusQueryReturnData>,
  ApiError
> => {
  const { unionSeq } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/registers/file-status`],
  });
};

export interface UnionRegisterStatusUpdateMutationParams {
  unionSeq: number;
  status: string;
}

export const useUpdateRegisterStatusMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  UnionRegisterStatusUpdateMutationParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, status } = params;
      const { data } = await axiosInstance.patch(
        `/unions/${unionSeq}/registers/file-status`,
        {
          status,
        },
      );
      return data;
    },
  });

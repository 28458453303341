import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';

import { ElectronicVote } from 'apis/meet/apis';
import { formatDateTime } from 'utils/format';

interface MessagePreviewModalProps {
  info: Omit<ElectronicVote, 'electronicVoteSeq' | 'documentNo'> & {
    meetName: string;
    unionName: string;
  };
  open: boolean;
  onClose: (show: false) => void;
}

const MessagePreviewModal = ({
  info,
  open,
  onClose,
}: MessagePreviewModalProps) => {
  const {
    unionName,
    meetName,
    senderName,
    description,
    contactNo,
    voteEndAt,
    voteStartAt,
  } = info;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>발송 메세지 미리보기</DialogTitle>
        <DialogContent>
          <Stack
            padding="24px 16px"
            borderRadius="6px"
            border={(theme) => `1px solid ${theme.palette.neutral[300]}`}
          >
            <Stack
              width="368px"
              height="588px"
              padding={2}
              borderRadius="6px"
              bgcolor={(theme) => theme.palette.neutral[100]}
            >
              <Typography>
                {unionName}(으)로부터 발송한 전자투표가 도착했습니다.
              </Typography>
              <Typography>&quot;조합원&quot;님</Typography>
              <Typography>아래 전자투표에 참여 요청드립니다.</Typography>
              <Typography>· 총회명: {meetName}</Typography>
              <Typography>· 발송단체명: {senderName}</Typography>
              <Typography>· 수신자: 조합원</Typography>
              <Typography sx={{ lineBreak: 'anywhere' }}>
                · 투표설명: {description}
              </Typography>
              <Typography>· 문의전화번호: {contactNo}</Typography>
              <Typography>
                · 투표기간: {formatDateTime(voteStartAt, 'yyyy-MM-dd')} ~{' '}
                {formatDateTime(voteEndAt, 'yyyy-MM-dd')}
              </Typography>
              <Typography sx={{ lineBreak: 'anywhere' }}>
                · 내용 확인하고 투표하기: [전자투표 링크가 표시됩니다.]
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>확인</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default MessagePreviewModal;

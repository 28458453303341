import {
  AspectRatio,
  Button,
  Card,
  DateRangePicker,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';
import {
  MessageSentsParams,
  useDeleteMessageSentMutation,
  useMessageSentsQuery,
} from 'apis/message/apis';
import { MessageSent } from 'apis/types/message';
import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import { formatDateTime } from 'utils/format';

const DEFAULT_PAGE_SIZE = 3;

const DEFAULT_SEARCH_PARAMS = {
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  keyword: '',
  searchFrom: '',
  searchTo: '',
};
const DEFAULT_SEARCH_VALUES = {
  keyword: '',
  searchFromTo: '',
};

interface SearchParamsValues {
  keyword: string;
  searchFromTo: string;
}

export interface MessageSentBoxModalProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'onSelect'> {
  unionSeq: number;
  onSelect?: (message: MessageSent) => void;
  onClose: (value: boolean) => void;
}

const MessageSentBoxModal = (props: MessageSentBoxModalProps) => {
  const { unionSeq, onSelect, onClose, open, ...rest } = props;

  const [searchParams, setSearchParams] = useState<MessageSentsParams>({
    unionSeq,
    ...DEFAULT_SEARCH_PARAMS,
  });

  const [imageCaches, setImageCaches] = useState<Record<string, string[]>>({});

  const { data: sentBoxes, refetch: refetchMessageSentBox } =
    useMessageSentsQuery(searchParams);

  const { mutateAsync: downloadFile } = useFileDownloadMutation();
  const { mutate: deleteSentMessage } = useDeleteMessageSentMutation();

  const { snackbar, confirmDialog } = useFeedback();

  const rows = useMemo(() => sentBoxes?.data ?? [], [sentBoxes?.data]);
  const pageCount = useMemo(
    () =>
      Math.ceil((sentBoxes?.pagination.totalElements || 1) / DEFAULT_PAGE_SIZE),
    [sentBoxes?.pagination],
  );

  const handleSearchParams = (values: SearchParamsValues) => {
    const {
      searchFromTo: [searchFrom, searchTo],
      ...otherValues
    } = values;

    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...otherValues,
      searchFrom,
      searchTo,
    }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleChangePagination = (
    _: ChangeEvent<unknown>,
    page: number | null,
  ) => {
    if (!page) {
      return;
    }

    setSearchParams({ ...searchParams, page: page - 1 });
  };

  const downloadCurrentPageImages = useCallback(() => {
    sentBoxes?.data?.forEach(async (sent) => {
      if (!sent.filePaths || sent.smsId in imageCaches) {
        return;
      }

      const urls = await Promise.all(
        sent.filePaths.map(async (path) => {
          const blobFile = await downloadFile({ filePath: path });
          return URL.createObjectURL(blobFile);
        }),
      );

      setImageCaches({ ...imageCaches, [sent.smsId]: urls });
    });
  }, [sentBoxes, imageCaches, downloadFile]);

  const handleRemove = (smsId: string) => () => {
    confirmDialog('선택하신 문자 내역을 삭제하시겠습니까?', {
      message: '보낸 문자함 삭제',
      onConfirm: () => {
        deleteSentMessage(
          {
            unionSeq,
            smsId,
          },
          {
            onSuccess: () => {
              refetchMessageSentBox();

              snackbar('해당 문자함을 삭제하였습니다.', { color: 'success' });
            },
            onError: (error) => {
              snackbar(error.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  };

  const handleSelect = (smsId: string) => () => {
    confirmDialog('선택하신 문자 내역으로 입력하시겠습니까?', {
      message: '보낸 문자함 입력',
      onConfirm: () => {
        const selectedSentBox = sentBoxes?.data?.find(
          ({ smsId: originSmsId }) => originSmsId === smsId,
        );

        if (!selectedSentBox) {
          return;
        }

        onSelect?.(selectedSentBox);
        onClose(false);
      },
    });
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose(false);
  };

  useEffect(() => {
    downloadCurrentPageImages();
  }, [downloadCurrentPageImages]);

  return (
    <Modal {...rest} open={open} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog minWidth={980}>
          <DialogTitle>보낸 문자함</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack gap={3}>
              <Stack gap={1.25}>
                <Typography level="body-md">
                  최근 6개월간 발송한 문자를 확인 할 수 있습니다. 재전송 하실
                  문자를 선택하면 선택한 이미지와 내용이 자동 입력됩니다.
                  <br />
                  문자 발송에 실패한 내역은 발송일시가 표시되지 않습니다.
                </Typography>

                <Search
                  defaultValues={DEFAULT_SEARCH_VALUES}
                  onSubmit={handleSearchParams}
                  onReset={handleSearchReset}
                >
                  <Grid container gap={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={4} maxWidth={200}>
                      <Search.Field>
                        <TextField
                          label="키워드"
                          name="keyword"
                          placeholder="모두 입력"
                          fullWidth
                        />
                      </Search.Field>
                    </Grid>

                    <Grid xs={12} maxWidth={416}>
                      <Search.Field>
                        <DateRangePicker
                          label="발송일시"
                          name="searchFromTo"
                          sx={{ field: { xs: 12, maxWidth: 200 } }}
                        />
                      </Search.Field>
                    </Grid>
                  </Grid>
                </Search>
              </Stack>

              <Stack gap={2}>
                {rows.length === 0 ? (
                  <Stack
                    height={480}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography textAlign="center">
                      보낸 문자 내역이 없습니다. 문자발송을 하시면 보낸문자의
                      이미지와 내용이 표시됩니다.
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" gap={1.25}>
                    {rows.map(({ smsId, sentAt, title, message }) => (
                      <Stack key={`sent_box_${smsId}`} gap={1.25}>
                        <Card
                          variant="outlined"
                          sx={{
                            width: 306,
                            height: 440,
                            overflowY: 'auto',
                          }}
                        >
                          <Stack direction="column" gap={2}>
                            <Typography
                              fontSize="md"
                              fontWeight="lg"
                              lineHeight="md"
                            >
                              발송일시{' '}
                              {formatDateTime(sentAt, 'yyyy-MM-dd HH:mm:ss')}
                            </Typography>

                            <Stack direction="column" gap={1.25}>
                              {imageCaches[smsId]?.map((src, index) => (
                                <AspectRatio
                                  key={`sent_box_${smsId}_image_${index}`}
                                  ratio="1.4/1"
                                  objectFit="fill"
                                >
                                  <img
                                    src={src}
                                    alt={`${title}의 ${index + 1}번째 이미지`}
                                  />
                                </AspectRatio>
                              ))}
                            </Stack>

                            {(title || message) && (
                              <Card variant="soft" color="neutral">
                                <Stack gap={1.25}>
                                  {title && (
                                    <Typography level="title-md">
                                      {title}
                                    </Typography>
                                  )}

                                  {message && (
                                    <Typography level="body-md">
                                      {message}
                                    </Typography>
                                  )}
                                </Stack>
                              </Card>
                            )}
                          </Stack>
                        </Card>

                        <Stack direction="row" gap={1}>
                          <Button
                            variant="outlined"
                            color="neutral"
                            fullWidth
                            onClick={handleRemove(smsId)}
                          >
                            삭제하기
                          </Button>
                          <Button fullWidth onClick={handleSelect(smsId)}>
                            선택하기
                          </Button>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                )}

                {rows.length > 0 && (
                  <Stack alignItems="center">
                    <Pagination
                      variant="plain"
                      color="neutral"
                      size="md"
                      orientation="horizontal"
                      showFirstButton={false}
                      showLastButton={false}
                      hidePrevButton={false}
                      hideNextButton={false}
                      count={pageCount}
                      onChange={handleChangePagination}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
            >
              닫기
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default MessageSentBoxModal;

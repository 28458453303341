import JoyTabList from '@mui/joy/TabList';
import type { TabListProps as JoyTabListProps } from '@mui/joy/TabList';
import { ForwardedRef, forwardRef } from 'react';

export type TabListProps = JoyTabListProps;

const TabList = (props: TabListProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyTabList {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, TabListProps>(TabList);

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { PropsWithChildren } from 'react';

import { defaultQueryFn, handleToast } from 'apis/ReactQueryProvider/utils';

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  key: 'REACT_QUERY_STORAGE',
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: false,
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
    },
  },

  queryCache: new QueryCache({
    onSuccess: (_, query) => handleToast(query, 'success'),
    onError: (_, query) => handleToast(query, 'fail'),
  }),
  mutationCache: new MutationCache({
    onSuccess: (_data, _variables, _context, mutation) =>
      handleToast(mutation, 'success'),
    onError: (_data, _variables, _context, mutation) =>
      handleToast(mutation, 'fail'),
  }),
});

const ReactQueryProvider = ({
  queryClient,
  children,
}: PropsWithChildren<{ queryClient: QueryClient }>) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            const isPersist = !!query.meta?.persist,
              hasData = !!query.state.data;
            return isPersist && hasData;
          },
        },
      }}
    >
      {children}
    </PersistQueryClientProvider>
  );
};

export default ReactQueryProvider;

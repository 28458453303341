import { GridRowId } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFileUploadMutation } from 'apis/common/apis';
import { useMemberPasswordVerifyMutation } from 'apis/member/apis';
import {
  CreateCallerBody,
  useCallersQuery,
  useCreateCallerMutation,
  useDeleteCallerMutation,
} from 'apis/message/apis';
import { MESSAGE_TABS } from 'apis/message/constants';
import { MESSAGE_CALLER_COLUMNS } from 'apis/message/fixtures';
import MessageCallerAdditionModal, {
  CallerAdditionValues,
} from 'components/pages/messages/modal/MessageCallerAdditionModal';
import MessageCallerDeletionModal from 'components/pages/messages/modal/MessageCallerDeletionModal';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const MessageCallerPage = () => {
  const params = useParams();
  const { pageContext } = useLayoutContext();

  const { unionSeq } = params;
  const { unionBasename, memberInfo } = pageContext || {};

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const [isAdditionOpen, setIsAdditionOpen] = useState<boolean>(false);
  const [isCancellationOpen, setIsCancellationOpen] = useState<boolean>(false);

  const {
    data: callers,
    isPending,
    isError,
    error,
    refetch: refetchCallers,
  } = useCallersQuery({ unionSeq: Number(unionSeq) });

  const { mutateAsync: uploadFiles, isPending: isFileUploading } =
    useFileUploadMutation();
  const { mutate: verifyPassword } = useMemberPasswordVerifyMutation();

  const { mutate: createCaller, isPending: isCreateCallerLoading } =
    useCreateCallerMutation();
  const { mutate: deleteCaller } = useDeleteCallerMutation();

  const { snackbar } = useFeedback();

  const rows = useMemo(() => callers?.data || [], [callers]);
  const isCallerAdditionSubmitting = useMemo(
    () => isFileUploading || isCreateCallerLoading,
    [isFileUploading, isCreateCallerLoading],
  );
  const { selectedCount, isRowSelected } = useMemo(
    () => ({
      selectedCount: rowSelectionModel.length,
      isRowSelected: !!(
        Array.isArray(rowSelectionModel) && rowSelectionModel.length > 0
      ),
    }),
    [rowSelectionModel],
  );

  const handleUploadFiles = async (
    files: { key: string; value: File | undefined }[],
  ) => {
    let fileSeqs = null;
    const dataTransfer = new DataTransfer();

    files.forEach(({ value }) => value && dataTransfer.items.add(value));

    await uploadFiles(
      { divisionCode: 'CALLING_NUMBER', files: dataTransfer.files },
      {
        onSuccess: ({ data }) => {
          fileSeqs = data?.reduce(
            (obj, { fileSeq }, index) => ({
              ...obj,
              [files[index].key]: fileSeq,
            }),
            {},
          );
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );

    return fileSeqs;
  };

  const handleSubmitCallerAddition = async (data: CallerAdditionValues) => {
    const { outgoingPhoneNo, name, phoneNo } = data;
    const files = [
      {
        key: 'communicationFileSeq',
        value: data.communicationFile,
      },
      {
        key: 'attorneyFileSeq',
        value: data.attorneyFile,
      },
      {
        key: 'businessRegistrationFileSeq',
        value: data.businessRegistrationFile,
      },
      {
        key: 'employmentCertificateFileSeq',
        value: data.employmentCertificateFile,
      },
      {
        key: 'identityFileSeq',
        value: data.identityFile,
      },
    ];

    const fileSeqs = await handleUploadFiles(files);
    if (!fileSeqs) {
      return;
    }

    createCaller(
      {
        ...(fileSeqs as CreateCallerBody),
        unionSeq: Number(unionSeq),
        outgoingPhoneNo,
        phoneNo,
        name,
      },
      {
        onSuccess: () => {
          refetchCallers();

          snackbar('발신번호가 등록되었습니다.', {
            color: 'success',
          });
          setIsAdditionOpen(false);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleSubmitCallerDeletion = () => {
    deleteCaller(
      {
        unionSeq: Number(unionSeq),
        smsCallerSeqs: rowSelectionModel as number[],
      },
      {
        onSuccess: () => {
          refetchCallers();

          snackbar('발신번호가 삭제되었습니다.', {
            color: 'success',
          });
          setIsCancellationOpen(false);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleSubmitMemberPassword = (password: string) => {
    verifyPassword(
      { memberSeq: memberInfo?.memberSeq ?? 0, password },
      {
        onSuccess: () => {
          handleSubmitCallerDeletion();
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const { columns } = useCreateGridColumns({
    columns: MESSAGE_CALLER_COLUMNS,
  });

  if (isError) {
    throw error;
  }

  return (
    <>
      <Tabs
        color="neutral"
        selectedTabVariant="plain"
        selectedTabColor="primary"
        tabIndicatorInset
        defaultValue={2}
        value={2}
      >
        <TabList disableUnderline>
          {MESSAGE_TABS.map(({ label, path }, index) => (
            <Tab
              key={`tab_${index}`}
              component={Link}
              value={index}
              href={`${unionBasename}${path}`}
            >
              {label}
            </Tab>
          ))}
        </TabList>

        <TabPanel value={2}>
          <Stack gap={2}>
            <Stack gap={1.75}>
              <Stack direction="row" gap={1}>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  전체{' '}
                  <Typography color="primary">
                    {commaizeNumber(callers?.data?.length)}
                  </Typography>
                </Typography>

                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  선택{' '}
                  <Typography color="primary">
                    {commaizeNumber(selectedCount)}
                  </Typography>
                </Typography>
              </Stack>

              <Stack height={442}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  loading={isPending}
                  getRowId={(row) => row.smsCallerSeq}
                  rowSelectionModel={rowSelectionModel}
                  onRowSelectionModelChange={setRowSelectionModel}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                color="danger"
                disabled={!isRowSelected}
                onClick={() => setIsCancellationOpen(true)}
              >
                선택삭제
              </Button>
              <Button onClick={() => setIsAdditionOpen(true)}>
                발신번호 추가
              </Button>
            </Stack>
          </Stack>
        </TabPanel>
      </Tabs>

      {isAdditionOpen && (
        <MessageCallerAdditionModal
          open={isAdditionOpen}
          isSubmitLoading={isCallerAdditionSubmitting}
          onClose={setIsAdditionOpen}
          onSubmit={handleSubmitCallerAddition}
        />
      )}

      {isCancellationOpen && (
        <MessageCallerDeletionModal
          data={{ count: selectedCount }}
          open={isCancellationOpen}
          onClose={setIsCancellationOpen}
          onSubmit={handleSubmitMemberPassword}
        />
      )}
    </>
  );
};

const messageCallerPage: CustomRouteObject = {
  path: 'callers',
  element: <MessageCallerPage />,
  handle: {
    getTitle: () => '발신번호 관리',
  },
};

export default messageCallerPage;

import JoyListItemDecorator from '@mui/joy/ListItemDecorator';
import type { ListItemDecoratorProps as JoyListItemDecoratorProps } from '@mui/joy/ListItemDecorator';
import { ForwardedRef, forwardRef } from 'react';

export type ListItemDecoratorProps = JoyListItemDecoratorProps;

const ListItemDecorator = (
  props: ListItemDecoratorProps,
  ref: ForwardedRef<HTMLSpanElement>,
) => {
  return (
    <JoyListItemDecorator {...props} ref={ref} sx={{ textAlign: 'center' }} />
  );
};

export default forwardRef<HTMLSpanElement, ListItemDecoratorProps>(
  ListItemDecorator,
);

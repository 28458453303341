import { Stack } from '@wooriga/design-system';

import { MeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';

import Descriptions from './Descriptions';

interface MeetsInfoDescriptionProps {
  meetsDetailQuery: MeetsDetailQuery;
}

const MeetsInfoDescription = ({
  meetsDetailQuery,
}: MeetsInfoDescriptionProps) => {
  return (
    <Stack gap={1.75} marginBottom="30px">
      <Descriptions meetsDetailQuery={meetsDetailQuery} />
    </Stack>
  );
};

export default MeetsInfoDescription;

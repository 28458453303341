import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowId } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  Grid,
  Link,
  Option,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  useExternalSystemCheckMutation,
  useFileUploadMutation,
} from 'apis/common/apis';
import {
  CreatePostDocumentBody,
  CreatePostSendBody,
  useCreatePostBalanceMutation,
  useCreatePostDocumentMutation,
  useCreatePostSendMutation,
  useDeletePostDocumentMutation,
} from 'apis/post/apis';
import {
  POST_REGISTER_DEFAULT_VALUE,
  POST_STAPLE_OPTIONS,
  POST_TABS,
} from 'apis/post/constants';
import {
  POST_REGISTER_COLUMNS,
  PostRegisterColumnProps,
} from 'apis/post/fixtures';
import { FileInfo } from 'apis/types/common';
import { PostRegister } from 'apis/types/post';
import { UnionRegisterInfo } from 'apis/types/union';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import PostBalanceModal from 'components/pages/posts/modal/PostBalanceModal';
import PostDocumentPreviewModal from 'components/pages/posts/modal/PostDocumentPreviewModal';
import PostDocumentSelectionModal from 'components/pages/posts/modal/PostDocumentSelectionModal';
import PostReceiverAdditionModal, {
  PostReceiverAdditionValues,
} from 'components/pages/posts/modal/PostReceiverAdditionModal';
import PostSenderForm from 'components/pages/posts/PostSenderForm';
import PostBalanceStep from 'components/pages/posts/stepper/PostBalanceStep';
import PostDocumentPreviewStep from 'components/pages/posts/stepper/PostDocumentPreviewStep';
import Search from 'components/Search';
import StepperDialog from 'components/StepperDialog';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { ModalState } from 'hyuk/types/common';
import { useUnionRegisterGroupsQuery } from 'lim/address-group/apis';
import {
  UnionRegisterGroupRegistersParams,
  useUnionRegisterGroupRegistersQuery,
} from 'lim/address-group/detail/apis';
import postRecordPage from 'pages/main/union-management/posts/records';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const FORM_DEFAULT_VALUES = {
  name: '',
  phoneNo: '',
  address: '',
  detailAddress: '',
  zipNo: '',
  sendQuantity: 0,
  fileSeq: 0,
  postType: '',
  colorType: '',
  flexType: '',
  isStapler: POST_STAPLE_OPTIONS[0].value,
  recipients: [],
};
const DEFAULT_SEARCH_PARAMS = {
  name: '',
  unionRegisterNo: '',
  phoneNo: '',
  shareType: '',
  localAddress: '',
  postAddress: '',
  positionDescription: '',
};
const DEFAULT_GROUP_SEARCH_PARAMS = {
  unionRegisterGroupName: '',
  unionRegisterName: '',
};

const DEFAULT_MODAL_STATE = {
  unionInfo: false,
  stepper: false,
  balance: false,
  documentPreview: false,
  documentSelection: false,
  recipientAddition: false,
};

const schema = yup.object({
  name: yup.string().required('발송인 이름을 입력해 주세요'),
  phoneNo: yup.string().required('발송인 연락처를 입력해 주세요'),
  address: yup.string().required('발송인 주소를 검색해 주세요'),
  detailAddress: yup.string().optional().default(''),
  zipNo: yup.string().required(),
  sendQuantity: yup.number().required(),
  fileSeq: yup.number().required(),
  postType: yup.string().required('발송방식을 선택해 주세요'),
  colorType: yup.string().required('출력색상을 선택해 주세요'),
  flexType: yup.string().required('출력형태을 선택해 주세요'),
  isStapler: yup.boolean().required('스테이플러 여부를 선택해 주세요'),
  recipients: yup.array().required().min(1, ''),
});

export type PostRecipientRow = UnionRegisterInfo & { id: number };

type PostModalState = ModalState<typeof DEFAULT_MODAL_STATE>;
type PostModalName = keyof PostModalState;

const PostPage = () => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [searchParams, setSearchParams] =
    useState<UnionRegisterGroupRegistersParams>(DEFAULT_SEARCH_PARAMS);

  const [rows, setRows] = useState<PostRecipientRow[]>([]);
  const [files, setFiles] = useState<(File | FileInfo)[]>([]);
  const [selectedUnionSeq, setSelectedUnionSeq] = useState(0);
  const [selectedGroupSeq, setSelectedGroupSeq] = useState('');
  const [latestUploadFile, setLatestUploadFile] = useState<FileInfo | null>(
    null,
  );

  const [isNewFileUpdate, setIsNewFileUpdate] = useState(false);
  const [isPostPreviewChecked, setIsPostPreviewChecked] = useState(false);

  const [modals, setModals] = useState<PostModalState>(DEFAULT_MODAL_STATE);

  const params = useParams();
  const { pageContext } = useLayoutContext();

  const { snackbar, alertDialog } = useFeedback();

  const { getGroupCode } = useCommonCodes();

  const { postTypeCodeGroup, colorTypeCodeGroup, flexTypeCodeGroup } = useMemo(
    () => ({
      postTypeCodeGroup: getGroupCode('POST_SEND_DIVIDE'),
      colorTypeCodeGroup: getGroupCode('POST_COLOR_DIVIDE'),
      flexTypeCodeGroup: getGroupCode('POST_FLEX_DIVIDE'),
    }),
    [getGroupCode],
  );

  const { data: groups } = useUnionRegisterGroupsQuery(
    Number(params.unionSeq),
    DEFAULT_GROUP_SEARCH_PARAMS,
  );
  const {
    data: senders,
    isPending,
    isError,
    error,
  } = useUnionRegisterGroupRegistersQuery(
    Number(params.unionSeq),
    Number(selectedGroupSeq),
    searchParams,
  );

  const { mutateAsync: uploadFiles, isPending: isFileUploading } =
    useFileUploadMutation();
  const { mutateAsync: checkPostSystem } = useExternalSystemCheckMutation();

  const { mutate: sendPost, isPending: isPostSending } =
    useCreatePostSendMutation();
  const { mutate: createPostDocument } = useCreatePostDocumentMutation();
  const { mutate: deletePostDocument } = useDeletePostDocumentMutation();
  const { mutate: calculatePostBalance } = useCreatePostBalanceMutation();

  const { control, watch, setValue, handleSubmit, reset } =
    useForm<CreatePostSendBody>({
      resolver: yupResolver(schema),
      defaultValues: FORM_DEFAULT_VALUES,
    });

  const formValues = watch();

  const isRowSelected = useMemo(
    () => Array.isArray(rowSelectionModel) && rowSelectionModel.length > 0,
    [rowSelectionModel],
  );
  const isFileSeqCached = useMemo(
    () => (formValues.fileSeq && !isNewFileUpdate) || isPostPreviewChecked,
    [formValues.fileSeq, isNewFileUpdate, isPostPreviewChecked],
  );

  const resetPageState = () => {
    setFiles([]);
    setRowSelectionModel([]);
    setLatestUploadFile(null);
    reset({
      ...FORM_DEFAULT_VALUES,
      postType: postTypeCodeGroup?.items[0].code ?? '',
      flexType: flexTypeCodeGroup?.items[0].code ?? '',
      colorType: colorTypeCodeGroup?.items[0].code ?? '',
    });
  };
  const spreadRecipients = (rows: UnionRegisterInfo[]) =>
    rows.map(
      ({ unionRegisterSeq, name, mainPhone, subPhones, postAddress }) => ({
        ...postAddress,
        unionRegisterSeq,
        name: name?.name,
        phoneNo:
          mainPhone?.phoneNo ??
          (Array.isArray(subPhones) && subPhones.length > 0
            ? subPhones[0].phoneNo
            : ''),
      }),
    );

  const openModal = (modalName: PostModalName) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };
  const closeModal = (modalName: PostModalName) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  const handleSearchParams = (values: UnionRegisterGroupRegistersParams) => {
    setSearchParams({ ...searchParams, ...values });
  };

  const handleSearchReset = () => {
    setSearchParams({
      ...searchParams,
      ...DEFAULT_SEARCH_PARAMS,
    });
  };

  const handleProcessRowUpdate = (
    updateRow: PostRegister & { id: number; detailAddressInput?: string },
  ) => {
    let newRow = updateRow;
    const isAddressChange = 'detailAddressInput' in updateRow;

    if (isAddressChange) {
      const changeAddress = newRow['detailAddressInput'];
      delete newRow['detailAddressInput'];

      newRow = {
        ...newRow,
        postAddress: {
          ...newRow.postAddress,
          detailAddress: changeAddress ?? '',
        },
      };
    }

    const newRows = rows.map((row, index) =>
      newRow.id === index + 1 ? newRow : row,
    );
    const selectedUnionRegisters = newRows.filter(({ id }) =>
      rowSelectionModel.includes(id),
    );

    setValue('recipients', spreadRecipients(selectedUnionRegisters));
    setRows(newRows);

    return newRow;
  };

  const handleChangeRowSelection = (model: GridRowId[]) => {
    const selectedUnionRegisters = rows.filter((_, index) =>
      model.includes(index + 1),
    );

    setRowSelectionModel(model);
    setValue('recipients', spreadRecipients(selectedUnionRegisters));
  };

  const handleChangeRegisterAddress: PostRegisterColumnProps['onClickAddress'] =
    useCallback(
      (result, id) => {
        const { userSelectedType, jibunAddress, roadAddress, zonecode } =
          result;

        const address = userSelectedType === 'R' ? roadAddress : jibunAddress;
        const newRows = rows.map((row, index) =>
          id === index + 1
            ? {
                ...row,
                postAddress: { ...row.postAddress, address, zipNo: zonecode },
              }
            : row,
        );
        const selectedUnionRegisters = newRows.filter(({ id }) =>
          rowSelectionModel.includes(id),
        );

        setValue('recipients', spreadRecipients(selectedUnionRegisters));
        setRows(newRows);
      },
      [rows, setValue, rowSelectionModel],
    );

  const handleChangeFiles = <T extends File | FileInfo>(files: T[]) => {
    setIsNewFileUpdate(true);
    setIsPostPreviewChecked(false);
    setFiles(files);
  };

  const handleUploadFiles = async () => {
    const fileList = new DataTransfer();

    files.forEach((file) => {
      if (file instanceof File) {
        fileList.items.add(file);
      }
    });

    return await uploadFiles(
      { divisionCode: 'POST', files: fileList.files },
      {
        onSuccess: ({ data }) => {
          if (!data) {
            return;
          }

          setLatestUploadFile(data[0]);
          setValue('fileSeq', data[0].fileSeq);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleCheckPostSystem = async () => {
    await checkPostSystem(
      { externalSystemType: 'POST_SEND' },
      {
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
          return false;
        },
      },
    );

    return true;
  };

  const handleOpenUnionInfoModal = useCallback(
    (seq: number | undefined) => {
      if (!seq) {
        alertDialog('조합원이 아니거나 조합원 정보가 없습니다.', {
          message: '조합원 정보',
        });
        return;
      }

      setSelectedUnionSeq(seq);
      openModal('unionInfo');
    },
    [alertDialog],
  );

  const handleOpenDocumentPreviewModal = () => {
    if (!isFileSeqCached) {
      handleUploadFiles();
    }

    openModal('documentPreview');
    setIsPostPreviewChecked(true);
    setIsNewFileUpdate(false);
  };

  const handleOpenBalanceModal = async () => {
    const isSystemAvailable = await handleCheckPostSystem();

    if (!isSystemAvailable) {
      return;
    }

    calculatePostBalance(
      {
        unionSeq: Number(params.unionSeq),
        ...formValues,
      },
      {
        onSuccess: () => {
          openModal('balance');
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleOpenPostStepper = handleSubmit(async () => {
    if (isFileSeqCached) {
      handleOpenBalanceModal();
      return;
    }

    await handleUploadFiles();

    openModal('stepper');
    setIsPostPreviewChecked(true);
    setIsNewFileUpdate(false);
  });

  const handleCreatePostDocument = ({
    fileSeq,
    formName,
  }: CreatePostDocumentBody) => {
    const request = {
      unionSeq: Number(params.unionSeq),
      fileSeq,
      formName,
    };

    createPostDocument(request, {
      onSuccess: () => {
        snackbar('서식 등록에 성공하였습니다.', {
          color: 'success',
        });
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleDeletePostDocument = (postFormSeqs: number[]) => {
    const request = {
      unionSeq: Number(params.unionSeq),
      postFormSeqs,
    };

    deletePostDocument(request, {
      onSuccess: () => {
        snackbar('서식 삭제에 성공하였습니다.', {
          color: 'success',
        });
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleSubmitReceiverAddition = (
    receiver: PostReceiverAdditionValues,
  ) => {
    const { phone, name, address, detailAddress, zipNo } = receiver;

    setRows([
      ...rows,
      {
        ...POST_REGISTER_DEFAULT_VALUE,
        mainPhone: {
          phoneNo: phone,
          isContactAllowed: true,
        },
        name: {
          name,
          nameClass: '',
        },
        postAddress: {
          address,
          detailAddress,
          zipNo,
        },
        unionRegisterSeq: 0,
        id: rows.length + 1,
      },
    ]);
  };

  const handleSubmitPostDocumentSelection = (upcomingFiles: File[]) => {
    const isFileNameDuplicate = Array.from(upcomingFiles).some(({ name }) =>
      files.some((file) => file.name === name),
    );

    if (isFileNameDuplicate) {
      snackbar('파일 이름이 중복되어 첨부하실 수 없습니다.', {
        color: 'danger',
      });
      return;
    }

    setIsNewFileUpdate(true);
    setIsPostPreviewChecked(false);
    setFiles((prevFiles) => [...prevFiles, ...upcomingFiles]);
  };

  const handleSubmitPostSend = () => {
    const request = {
      unionSeq: Number(params.unionSeq),
      ...formValues,
    };

    sendPost(request, {
      onSuccess: () => {
        snackbar('우편발송 요청이 완료되었습니다.', {
          color: 'success',
        });

        resetPageState();
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleSubmitPostStepper = (data: CreatePostSendBody) => {
    const request = {
      unionSeq: Number(params.unionSeq),
      ...data,
    };

    sendPost(request, {
      onSuccess: () => {
        snackbar('우편발송 요청이 완료되었습니다.', {
          color: 'success',
        });

        resetPageState();
        closeModal('stepper');
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const { columns } = useCreateGridColumns({
    columns: POST_REGISTER_COLUMNS,
    handlers: {
      onClickUnionMember: handleOpenUnionInfoModal,
      onClickAddress: handleChangeRegisterAddress,
    },
  });

  if (isError || !Number(params.unionSeq)) {
    throw error;
  }

  useEffect(() => {
    setRows(
      (senders?.data ?? []).map((sender, index) => ({
        ...sender,
        id: index + 1,
      })),
    );
  }, [senders]);

  useEffect(() => {
    if (groups)
      setSelectedGroupSeq(String(groups?.data?.[0].unionRegisterGroupSeq));
  }, [groups]);

  useEffect(() => {
    reset({
      ...FORM_DEFAULT_VALUES,
      postType: postTypeCodeGroup?.items[0].code ?? '',
      flexType: flexTypeCodeGroup?.items[0].code ?? '',
      colorType: colorTypeCodeGroup?.items[0].code ?? '',
    });
  }, [reset, postTypeCodeGroup, flexTypeCodeGroup, colorTypeCodeGroup]);

  return (
    <>
      <Tabs
        color="neutral"
        selectedTabVariant="plain"
        selectedTabColor="primary"
        tabIndicatorInset
        defaultValue={0}
        value={0}
      >
        <TabList disableUnderline>
          {POST_TABS.map(({ label, path }, index) => (
            <Tab
              key={`tab_${path}`}
              component={Link}
              value={index}
              href={`${pageContext?.unionBasename}${path}`}
            >
              {label}
            </Tab>
          ))}
        </TabList>

        <TabPanel value={0}>
          <Stack gap={6}>
            <Stack gap={2}>
              <Stack gap={1.75}>
                <Stack direction="row" alignItems="center" flex={1} gap={1}>
                  <Select
                    value={selectedGroupSeq}
                    onChange={(_, value) =>
                      setSelectedGroupSeq(value as string)
                    }
                    sx={{ width: '432px' }}
                  >
                    {groups?.data?.map(({ unionRegisterGroupSeq, name }) => (
                      <Option
                        key={`group_option_${unionRegisterGroupSeq}`}
                        value={String(unionRegisterGroupSeq)}
                      >
                        {name}
                      </Option>
                    ))}
                  </Select>

                  <Button
                    variant="soft"
                    onClick={() => openModal('recipientAddition')}
                  >
                    수신자 개별 등록
                  </Button>
                </Stack>

                <Search
                  defaultValues={DEFAULT_SEARCH_PARAMS}
                  onSubmit={handleSearchParams}
                  onReset={handleSearchReset}
                >
                  <Grid container gap={2}>
                    <Grid xs={12} maxWidth={200}>
                      <Search.Field>
                        <TextField
                          label="연번"
                          name="unionRegisterNo"
                          placeholder='숫자 또는 "-"입력'
                          validateOptions={{
                            maxLength: 11,
                            regex: /^(?!.*--)[0-9-]*$/,
                          }}
                          fullWidth
                        />
                      </Search.Field>
                    </Grid>

                    <Grid xs={12} maxWidth={200}>
                      <Search.Field>
                        <TextField
                          label="조합원 이름"
                          name="name"
                          placeholder="조합원 이름 입력"
                          slotProps={{
                            input: { maxLength: 30 },
                          }}
                          fullWidth
                        />
                      </Search.Field>
                    </Grid>
                  </Grid>
                </Search>

                <Stack flexDirection="row" gap={1}>
                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    전체{' '}
                    <Typography color="primary">
                      {commaizeNumber(rows.length)}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>

              <Stack height={442}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  loading={isPending}
                  rowSelectionModel={rowSelectionModel}
                  onRowSelectionModelChange={handleChangeRowSelection}
                  processRowUpdate={handleProcessRowUpdate}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Stack>
            </Stack>

            <Stack gap={2}>
              <PostSenderForm
                files={files}
                control={control}
                setValue={setValue}
                postTypeGroup={postTypeCodeGroup?.items}
                flexTypeGroup={flexTypeCodeGroup?.items}
                colorTypeGroup={colorTypeCodeGroup?.items}
                onChangeFiles={handleChangeFiles}
              />

              <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button
                  variant="outlined"
                  color="neutral"
                  disabled={files.length === 0}
                  onClick={handleOpenDocumentPreviewModal}
                  loading={isFileUploading}
                >
                  미리보기
                </Button>
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={() => openModal('documentSelection')}
                >
                  문서서식
                </Button>
                <Button
                  disabled={!isRowSelected || files.length === 0}
                  onClick={handleOpenPostStepper}
                >
                  우편발송
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </TabPanel>
      </Tabs>

      {modals.unionInfo && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq: Number(params.unionSeq),
            unionRegisterSeq: selectedUnionSeq,
          }}
          open={modals.unionInfo}
          onClose={() => {
            setSelectedUnionSeq(0);
            closeModal('unionInfo');
          }}
        />
      )}

      {modals.recipientAddition && (
        <PostReceiverAdditionModal
          open={modals.recipientAddition}
          onClose={() => closeModal('recipientAddition')}
          onSubmit={handleSubmitReceiverAddition}
        />
      )}

      {modals.documentPreview && latestUploadFile && (
        <PostDocumentPreviewModal
          fileInfo={latestUploadFile}
          open={modals.documentPreview}
          loading={isFileUploading}
          onClose={() => closeModal('documentPreview')}
          onSubmit={handleCreatePostDocument}
        />
      )}

      {modals.documentSelection && (
        <PostDocumentSelectionModal
          unionSeq={Number(params.unionSeq)}
          open={modals.documentSelection}
          onClose={() => closeModal('documentSelection')}
          onDelete={handleDeletePostDocument}
          onSubmit={handleSubmitPostDocumentSelection}
        />
      )}

      {modals.balance && (
        <PostBalanceModal
          unionSeq={Number(params.unionSeq)}
          formValues={formValues}
          open={modals.balance}
          onClose={() => closeModal('balance')}
          onSubmit={handleSubmitPostSend}
        />
      )}

      {modals.stepper && (
        <StepperDialog
          title="우편 발송"
          defaultLastButtonText="우편 발송"
          defaultValues={formValues}
          open={modals.stepper}
          onClose={() => closeModal('stepper')}
        >
          <StepperDialog.Step name="미리보기">
            <PostDocumentPreviewStep unionSeq={Number(params.unionSeq)} />
          </StepperDialog.Step>
          <StepperDialog.Step name="비용 조회">
            <PostBalanceStep
              unionSeq={Number(params.unionSeq)}
              isSendPostPending={isPostSending}
              onSubmit={handleSubmitPostStepper}
            />
          </StepperDialog.Step>
        </StepperDialog>
      )}
    </>
  );
};

const postsPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/posts',
  children: [{ index: true, element: <PostPage /> }, postRecordPage],
  handle: {
    getTitle: () => '우편 발송',
    getMenuCode: () => 'M0405',
  },
};

export default postsPage;

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { MeetInfo } from 'apis/types/meet';
import { ApiResponseData } from 'types/api';

export type MeetsQuery = UseQueryResult<
  ApiResponseData<MeetInfo[]>,
  AxiosError
>;

export interface MeetsParams {
  meetName: string;
  moverName: string;
  meetStatus: string;
  openType: string;
  meetMethod: string;
  meetType: string;
}

export const useMeetsQuery = (
  unionSeq: number,
  params: MeetsParams,
): MeetsQuery =>
  useQuery({
    queryKey: [`/meets?unionSeq=${unionSeq}`, params],
    // initialData: generateInitRealData(meetsData),
  });

import { UnionRegisterAgentInfo } from 'apis/types/union';

export const MESSAGE_TABS = [
  { label: '문자발송', path: '/union-management/messages' },
  { label: '내역 관리', path: '/union-management/messages/records' },
  { label: '발신번호 관리', path: '/union-management/messages/callers' },
];

export const MESSAGE_REGISTER_DEFAULT_VALUE = {
  uniqueId: '',
  unionRegisterSeq: 0,
  unionRegisterNo: '',
  unionRegisterNoMain: 0,
  unionRegisterNoSub: 0,
  name: {
    name: '',
    nameClass: '',
  },
  phoneNo: '',
  position: '',
  positionDescription: '',
  phoneMemo: '',
  consentStatus: '',
  agent: {} as UnionRegisterAgentInfo,
};

import { SxProps } from '@mui/system';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';

import { useLogoutMutation } from 'apis/auth/apis';
import axiosInstance from 'apis/axiosInstance';
import { CustomUIMatch } from 'types/route';
import { removeCookie } from 'utils/cookie';

const useLayout = () => {
  const matches = useMatches() as CustomUIMatch<unknown>[];
  const navigate = useNavigate();

  const { mutate } = useLogoutMutation();

  const defaultHeaderProps = useMemo(() => {
    const main = matches[1];
    const sub = matches[2];
    return {
      title: main?.handle?.getTitle && main?.handle.getTitle(),
      subTitle: sub?.handle?.getTitle && sub?.handle.getTitle(),
      helpTooltip: sub?.handle?.getHelpTootip?.(),
    };
  }, [matches]);

  const [layoutContainerStyles, setLayoutContainerStyles] = useState<
    SxProps | undefined
  >(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [subTitle, setSubTitle] = useState<string | undefined>(undefined);
  const [helpTooltip, setHelpTooltip] = useState<
    { title?: ReactNode; contents?: ReactNode } | undefined
  >(undefined);

  const handleLogout = useCallback(() => {
    mutate(null, {
      onSuccess: () => {
        removeCookie('accessToken', {
          secure: true,
          sameSite: 'strict',
        });
        removeCookie('memberSeq', {
          secure: true,
          sameSite: 'strict',
        });
        axiosInstance.defaults.headers['authorization'] = null;
        navigate('/login', { replace: true });
      },
    });
  }, [mutate, navigate]);

  const returnValues = useMemo(
    () => ({
      title: title || defaultHeaderProps.title,
      setTitle,
      subTitle: subTitle || defaultHeaderProps.subTitle,
      setSubTitle,
      helpTooltip: helpTooltip || defaultHeaderProps.helpTooltip,
      setHelpTooltip,
      layoutContainerStyles,
      setLayoutContainerStyles,
      logout: handleLogout,
    }),
    [
      defaultHeaderProps.helpTooltip,
      defaultHeaderProps.subTitle,
      defaultHeaderProps.title,
      handleLogout,
      helpTooltip,
      layoutContainerStyles,
      subTitle,
      title,
    ],
  );

  return returnValues;
};

export default useLayout;

import JoyModalClose from '@mui/joy/ModalClose';
import type { ModalCloseProps as JoyModalCloseProps } from '@mui/joy/ModalClose';
import { ForwardedRef, forwardRef } from 'react';

export type ModalCloseProps = JoyModalCloseProps;

const ModalClose = (
  props: ModalCloseProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  return <JoyModalClose {...props} ref={ref} />;
};

export default forwardRef<HTMLButtonElement, ModalCloseProps>(ModalClose);

import {
  keepPreviousData,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { UnionMemberInfo } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

export interface UnionMembersParams {
  unionRegisterName: string;
  unionRegisterNo: string;
  phoneNo: string;
  nickname: string;
  level: string;
}

export type UnionMembersResponse = UnionMemberInfo[];

export const useUnionMembersQuery = (
  unionSeq: number,
  params?: UnionMembersParams,
): UseQueryResult<ApiResponseData<UnionMembersResponse>, ApiError> =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/members`, params],
    placeholderData: keepPreviousData,
    // initialData: generateInitRealData(unionMembersData),
  });

export type UnionMemberResponse = UnionMemberInfo;

export type UnionMemberQuery = UseQueryResult<
  ApiResponseData<UnionMemberResponse>,
  ApiError
>;

export const useUnionMemberQuery = (
  unionSeq: number,
  unionMemberSeq: number,
  isMasking?: boolean,
): UnionMemberQuery =>
  useQuery({
    queryKey: [
      `/unions/${unionSeq}/members/${unionMemberSeq}?isMasking=${isMasking}`,
    ],

    placeholderData: keepPreviousData,
  });

export interface PasswordVerifyBody {
  password: string;
}

export type PasswordVerifyMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PasswordVerifyBody
>;

export const usePasswordVerifyMutation = (
  memberSeq: number,
): PasswordVerifyMutation =>
  useMutation({
    mutationFn: async (formData: PasswordVerifyBody) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/members/${memberSeq}/password/verify`,
        formData,
      );

      return data;
    },
  });

export interface LevelUpdateBody {
  levelCode: string;
}

export type LevelUpdateMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  LevelUpdateBody
>;

export const useLevelUpdateMutation = (
  unionSeq: number,
  unionMemberSeq: number,
): LevelUpdateMutation =>
  useMutation({
    mutationFn: async (formData: LevelUpdateBody) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/unions/${unionSeq}/members/${unionMemberSeq}/level`,
        formData,
      );

      return data;
    },
  });

export interface ApprovalBody {
  isApproval: boolean;
}

export type ApprovalMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  ApprovalBody
>;

export const useApprovalMutation = (
  unionSeq: number,
  unionMemberSeq: number,
): ApprovalMutation =>
  useMutation({
    mutationFn: async (formData: ApprovalBody) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/unions/${unionSeq}/members/${unionMemberSeq}/approval`,
        formData,
      );

      return data;
    },
  });

import JoyButton from '@mui/joy/Button';
import type {
  ExtendButton,
  ButtonTypeMap as JoyButtonTypeMap,
} from '@mui/joy/Button';
import type { OverrideProps } from '@mui/types';
import {
  forwardRef,
  type ElementType,
  type ForwardRefExoticComponent,
} from 'react';

export type ButtonProps<
  D extends ElementType = JoyButtonTypeMap['defaultComponent'],
  P = { component?: ElementType },
> = OverrideProps<JoyButtonTypeMap<P, D>, D>;

interface ButtonComponent<
  D extends ElementType = JoyButtonTypeMap['defaultComponent'],
> extends ForwardRefExoticComponent<ButtonProps<D>> {
  propTypes?: never;
  muiName?: string;
}

const Button = forwardRef((props, ref) => {
  return <JoyButton {...props} ref={ref} />;
}) as ButtonComponent;

Button.displayName = 'Button';
Button.muiName = 'Button';

export default Button as ExtendButton<JoyButtonTypeMap>;

import JoyTabPanel from '@mui/joy/TabPanel';
import type { TabPanelProps as JoyTabPanelProps } from '@mui/joy/TabPanel';
import { ForwardedRef, forwardRef } from 'react';

export type TabPanelProps = JoyTabPanelProps;

const TabPanel = (props: TabPanelProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyTabPanel {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, TabPanelProps>(TabPanel);

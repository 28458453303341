import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  Chip,
  ChipProps,
  CircularProgress,
  DatePicker,
  FormControl,
  FormLabel,
  Link,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { startOfDay } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { UnionRegisterLocalAddressRight } from 'apis/types/union';
import {
  UnionRegisterLocalAddressRightsUpdateParams,
  useUnionRegisterLocalAddressRightsQuery,
  useUnionRegisterLocalAddressRightsUpdateMutation,
} from 'apis/union/register/local-address-rights/api';
import CommonCode from 'components/CommonCode';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { REGISTERS_MODIFY_TABS } from 'pages/main/union-management/registers/modify/constants';
import { CustomRouteObject } from 'types/route';
import { formatDateDistanceHangul, formatDateTime } from 'utils/format';

export type UnionRegisterFormValues = Omit<
  UnionRegisterLocalAddressRightsUpdateParams,
  'unionSeq' | 'unionRegisterSeq'
>;

const RegisterModifyAuthsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const unionSeq = Number(params.unionSeq);
  const unionRegisterSeq = Number(params.unionRegisterSeq);

  const { pageContext } = useLayoutContext();
  const { unionBasename } = pageContext || {};

  const { getGroupCode } = useCommonCodes();

  const {
    landUtilizationCodeGroup,
    landCategoryCodeGroup,
    buildingStructureCodeGroup,
    buildingUsageCodeGroup,
  } = useMemo(
    () => ({
      landUtilizationCodeGroup: getGroupCode('LAND_UTILIZATION'),
      landCategoryCodeGroup: getGroupCode('LAND_CATEGORY'),
      buildingStructureCodeGroup: getGroupCode('BUILDING_STRUCTURE'),
      buildingUsageCodeGroup: getGroupCode('BUILDING_USAGE'),
    }),
    [getGroupCode],
  );

  const { data: unionRegisterLocalAddressRightsReturnData, isPending } =
    useUnionRegisterLocalAddressRightsQuery({ unionSeq, unionRegisterSeq });

  const { mutate: updateRights } =
    useUnionRegisterLocalAddressRightsUpdateMutation();

  const { snackbar, confirmDialog } = useFeedback();
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit: validateForm,
  } = useForm<UnionRegisterFormValues>();

  const unionRegisterLocalAddressRights = useMemo(
    () => unionRegisterLocalAddressRightsReturnData?.data || [],
    [unionRegisterLocalAddressRightsReturnData?.data],
  );

  const handleChangeAcquistionDate = useCallback(
    ({
      name,
      value,
      index,
    }: {
      name: 'landOwnership' | 'buildingOwnership';
      value: string | number | undefined;
      index: number;
    }) => {
      if (!value || typeof value === 'number') {
        return;
      }

      const acquiredAt =
        formatDateTime(startOfDay(value), "yyyy-MM-dd'T'HH:mm:ss") || '';
      const acquisitionPeriod = formatDateDistanceHangul(
        value,
        startOfDay(new Date()),
      );

      setValue(
        `rights.${index}.${name}.acquiredAt`,
        !acquiredAt || typeof acquiredAt === 'number' ? '' : acquiredAt,
      );
      setValue(`rights.${index}.${name}.acquisitionPeriod`, acquisitionPeriod);
    },
    [setValue],
  );

  const handleSubmit = validateForm(({ rights }) => {
    confirmDialog('해당 내용을 저장하시겠습니까?', {
      onConfirm: () => {
        updateRights(
          {
            unionSeq,
            unionRegisterSeq,
            rights,
          },
          {
            onSuccess: () => {
              navigate('..', { relative: 'path' });
            },
            onError: (error) => {
              snackbar(error.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  });

  const renderBuildingForm = useCallback(
    (
      {
        localAddressSeq,
        dong,
        jibun,
        registrationType: registrationTypeProp,
      }: UnionRegisterLocalAddressRight,
      index: number,
    ) => {
      const isPermitted =
        watch(`rights.${index}.buildingOwnership.isPermitted`) ?? true;
      const registrationType =
        registrationTypeProp === 'LAND_BUILDING'
          ? 'BUILDING'
          : registrationTypeProp;

      return (
        <AccordionGroup
          key={`rights-${localAddressSeq}-${index}`}
          variant="outlined"
          size="lg"
          sx={{ borderRadius: 'md' }}
        >
          <Accordion>
            <AccordionSummary indicator={<ExpandMoreRoundedIcon />}>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Typography
                  level="h3"
                  fontSize="lg"
                  fontWeight="xl"
                  lineHeight="xl"
                  py={1}
                >
                  {dong} {jibun}
                </Typography>

                <Chip
                  color={
                    registrationType === 'BUILDING'
                      ? ('blue' as ChipProps['color'])
                      : 'neutral'
                  }
                  variant="outlined"
                >
                  <CommonCode
                    groupCode="REGISTRATION_TYPE"
                    code={registrationType}
                  />
                </Chip>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              <Stack flexDirection="row" flexWrap="wrap" gap={2}>
                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.isPermitted`}
                    defaultValue={true}
                    render={({ field }) => (
                      <RadioGroup
                        label="허가유무"
                        orientation="horizontal"
                        color="neutral"
                        value={String(field.value)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          field.onChange(event.target.value === 'true')
                        }
                      >
                        <Radio label="허가" value="true" />
                        <Radio label="무허가" value="false" />
                      </RadioGroup>
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.unPermittedBuildingNo`}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="무허가 건물번호"
                        error={fieldState.invalid}
                        disabled={!!isPermitted}
                      />
                    )}
                  />
                </Stack>
                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.buildingUsage`}
                    render={({ field, fieldState }) => (
                      <FormControl error={fieldState.invalid}>
                        <FormLabel>용도</FormLabel>
                        <Select
                          placeholder="용도를 선택하세요"
                          disabled={!isPermitted}
                          value={field.value ?? ''}
                          onChange={(_, value) => field.onChange(value)}
                        >
                          {buildingUsageCodeGroup?.items.map((code) => (
                            <Option key={code.code} value={code.code}>
                              {code.name}
                            </Option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.buildingStructure`}
                    render={({ field, fieldState }) => (
                      <FormControl error={fieldState.invalid}>
                        <FormLabel>구조</FormLabel>
                        <Select
                          placeholder="구조를 선택하세요"
                          disabled={!isPermitted}
                          value={field.value ?? ''}
                          onChange={(_, value) => field.onChange(value)}
                        >
                          {buildingStructureCodeGroup?.items.map((code) => (
                            <Option key={code.code} value={code.code}>
                              {code.name}
                            </Option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.floor`}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="층별"
                        error={fieldState.invalid}
                        disabled={!isPermitted}
                      />
                    )}
                  />
                </Stack>
                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.buildingName`}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="건물명"
                        placeholder="건물명을 입력하세요"
                        error={fieldState.invalid}
                        disabled={!isPermitted}
                        validateOptions={{
                          maxLength: 50,
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.acquiredAt`}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        {...field}
                        color="neutral"
                        label="취득일자"
                        disableFuture
                        disabled={!isPermitted}
                        onChange={(value) =>
                          handleChangeAcquistionDate({
                            index,
                            value: formatDateTime(
                              value ?? '',
                              "yyyy-MM-dd'T'HH:mm:ss",
                            ),
                            name: 'buildingOwnership',
                          })
                        }
                        slotProps={{
                          textField: { error: fieldState.invalid },
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.acquisitionPeriod`}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        label="취득기간"
                        readOnly
                        value={field.value}
                        disabled={!isPermitted}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.useApprovedAt`}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        {...field}
                        color="neutral"
                        label="사용승인일"
                        disabled={!isPermitted}
                        onChange={(value) =>
                          field.onChange(
                            formatDateTime(
                              value ?? '',
                              "yyyy-MM-dd'T'HH:mm:ss",
                            ),
                          )
                        }
                        slotProps={{
                          textField: { error: fieldState.invalid },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.totalArea`}
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label="전체면적(㎡)"
                        disabled={!isPermitted}
                        value={field.value || ''}
                        onChange={(event) =>
                          field.onChange(event.target.valueAsNumber)
                        }
                        validateOptions={{
                          regex: /^\d+(\.\d{1,3})?$/,
                        }}
                        slotProps={{
                          input: { step: 0.1, min: 0 },
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.ownedArea`}
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label="소유면적(㎡)"
                        disabled={!isPermitted}
                        value={field.value || ''}
                        onChange={(event) =>
                          field.onChange(event.target.valueAsNumber)
                        }
                        validateOptions={{
                          regex: /^\d+(\.\d{1,3})?$/,
                        }}
                        slotProps={{ input: { step: 0.1, min: 0 } }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.portion`}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="소유지분"
                        error={fieldState.invalid}
                        disabled={!isPermitted}
                        validateOptions={{
                          regex: /^[0-9/]*$/,
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.buildingOwnership.associatedJibun`}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="관련지번"
                        error={fieldState.invalid}
                        disabled={!isPermitted}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      );
    },
    [
      watch,
      control,
      buildingUsageCodeGroup?.items,
      buildingStructureCodeGroup?.items,
      handleChangeAcquistionDate,
    ],
  );

  const renderLandForm = useCallback(
    (
      { localAddressSeq, dong, jibun }: UnionRegisterLocalAddressRight,
      index: number,
    ) => {
      return (
        <AccordionGroup
          key={`rights-${localAddressSeq}`}
          variant="outlined"
          size="lg"
          sx={{ borderRadius: 'md' }}
        >
          <Accordion>
            <AccordionSummary indicator={<ExpandMoreRoundedIcon />}>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Typography
                  level="h3"
                  fontSize="lg"
                  fontWeight="xl"
                  lineHeight="xl"
                  py={1}
                >
                  {dong} {jibun}
                </Typography>

                <Chip variant="outlined" color="warning">
                  <CommonCode groupCode="REGISTRATION_TYPE" code="LAND" />
                </Chip>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>
              <Stack flexDirection="row" flexWrap="wrap" gap={2}>
                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.landCategory`}
                    render={({ field, fieldState }) => (
                      <FormControl error={fieldState.invalid}>
                        <FormLabel>지목</FormLabel>
                        <Select
                          placeholder="지목을 선택하세요"
                          value={field.value ?? ''}
                          onChange={(_, value) => field.onChange(value)}
                        >
                          {landCategoryCodeGroup?.items.map((code) => (
                            <Option key={code.code} value={code.code}>
                              {code.name}
                            </Option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.utilization`}
                    render={({ field, fieldState }) => (
                      <FormControl error={fieldState.invalid}>
                        <FormLabel>이용상황</FormLabel>
                        <Select
                          placeholder="지목을 선택하세요"
                          value={field.value ?? ''}
                          onChange={(_, value) => field.onChange(value)}
                        >
                          {landUtilizationCodeGroup?.items.map((code) => (
                            <Option key={code.code} value={code.code}>
                              {code.name}
                            </Option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Stack>

                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.acquiredAt`}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        {...field}
                        color="neutral"
                        label="취득일자"
                        disableFuture
                        onChange={(value) =>
                          handleChangeAcquistionDate({
                            index,
                            value: formatDateTime(
                              value ?? '',
                              "yyyy-MM-dd'T'HH:mm:ss",
                            ),
                            name: 'landOwnership',
                          })
                        }
                        slotProps={{
                          textField: { error: fieldState.invalid },
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.acquisitionPeriod`}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField label="취득기간" readOnly {...field} />
                    )}
                  />
                </Stack>

                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.totalArea`}
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label="전체면적(㎡)"
                        value={field.value || ''}
                        onChange={(event) =>
                          field.onChange(event.target.valueAsNumber)
                        }
                        validateOptions={{
                          regex: /^\d+(\.\d{1,3})?$/,
                        }}
                        slotProps={{ input: { step: 0.1, min: 0 } }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.incorporatedArea`}
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label="편입면적(㎡)"
                        value={field.value || ''}
                        onChange={(event) =>
                          field.onChange(event.target.valueAsNumber)
                        }
                        validateOptions={{
                          regex: /^\d+(\.\d{1,3})?$/,
                        }}
                        slotProps={{ input: { step: 0.1, min: 0 } }}
                      />
                    )}
                  />
                </Stack>

                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.ownedArea`}
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label="소유면적(㎡)"
                        value={field.value || ''}
                        onChange={(event) =>
                          field.onChange(event.target.valueAsNumber)
                        }
                        validateOptions={{
                          regex: /^\d+(\.\d{1,3})?$/,
                        }}
                        slotProps={{ input: { step: 0.1, min: 0 } }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.portion`}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="소유지분"
                        error={fieldState.invalid}
                        validateOptions={{
                          regex: /^[0-9/]*$/,
                        }}
                      />
                    )}
                  />
                </Stack>

                <Stack gap={2} maxWidth={240} flex={1}>
                  <Controller
                    control={control}
                    name={`rights.${index}.landOwnership.hasSurfaceRight`}
                    defaultValue={false}
                    render={({ field }) => (
                      <RadioGroup
                        label="지상권 여부"
                        orientation="horizontal"
                        color="neutral"
                        value={String(field.value)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          field.onChange(event.target.value === 'true')
                        }
                      >
                        <Radio label="해당없음" value="false" />
                        <Radio label="해당" value="true" />
                      </RadioGroup>
                    )}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      );
    },
    [
      control,
      handleChangeAcquistionDate,
      landCategoryCodeGroup?.items,
      landUtilizationCodeGroup?.items,
    ],
  );

  const renderItem = useCallback(
    (data: UnionRegisterLocalAddressRight, index: number) => {
      const { registrationType } = data;
      const forms = [];

      switch (registrationType) {
        case 'AGGREGATE_BUILDING':
        case 'BUILDING':
          forms.push(renderBuildingForm(data, index));
          break;

        case 'LAND_BUILDING':
          forms.push(renderLandForm(data, index));
          forms.push(renderBuildingForm(data, index));
          break;
        case 'LAND':
          forms.push(renderLandForm(data, index));
          break;
      }

      return forms;
    },
    [renderBuildingForm, renderLandForm],
  );

  useEffect(() => {
    reset({
      rights: (unionRegisterLocalAddressRightsReturnData?.data || []).map(
        ({ localAddressSeq, landOwnership, buildingOwnership }) => ({
          localAddressSeq,
          landOwnership: landOwnership ?? {},
          buildingOwnership: buildingOwnership ?? {},
        }),
      ),
    });
  }, [reset, unionRegisterLocalAddressRightsReturnData?.data]);

  if (isPending) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Tabs
        color="neutral"
        selectedTabVariant="plain"
        selectedTabColor="primary"
        tabIndicatorInset
        defaultValue={1}
      >
        <TabList disableUnderline>
          {REGISTERS_MODIFY_TABS.map(({ label, path }, index) => (
            <Tab
              key={`tab_${path}`}
              component={Link}
              value={index}
              href={`${unionBasename}/union-management/registers/${unionRegisterSeq}/${path}`}
            >
              {label}
            </Tab>
          ))}
        </TabList>

        <TabPanel value={1}>
          <Stack component="form" gap={2}>
            <Stack gap={3}>
              {unionRegisterLocalAddressRights.map(renderItem)}
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Button
                size="lg"
                variant="outlined"
                color="neutral"
                component={Link}
                href="../.."
              >
                목록
              </Button>

              <Button size="lg" onClick={handleSubmit}>
                저장
              </Button>
            </Stack>
          </Stack>
        </TabPanel>
      </Tabs>
    </>
  );
};

const registerModifyAuthsPage: CustomRouteObject = {
  path: 'auths',
  element: <RegisterModifyAuthsPage />,
};

export default registerModifyAuthsPage;

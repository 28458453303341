import { useTheme } from '@mui/joy';
import { LineChart, Stack, Typography } from '@wooriga/design-system';
import { useMemo } from 'react';

import { MeetMethods } from 'apis/types/meet';
import { ElectronicVoteTimeSeriesQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { formatDateTime } from 'utils/format';

interface ElectronicLineChartProps {
  meetMethod: MeetMethods;
  electronicVoteTimeSeriesQuery?: ElectronicVoteTimeSeriesQuery;
}

const ElectronicLineChart = ({
  meetMethod,
  electronicVoteTimeSeriesQuery,
}: ElectronicLineChartProps) => {
  const theme = useTheme();

  const commonStyle = {
    background: theme.palette.background.surface,
    padding: '12px 20px',
  };

  const accumulativeVotedCharts = useMemo(() => {
    if (!electronicVoteTimeSeriesQuery?.data)
      return {
        xAxis: [],
        series: [],
      };

    const { accumulatedVoted: accVotedData } =
      electronicVoteTimeSeriesQuery.data.data;

    const accXAxis =
      accVotedData?.map((data) =>
        formatDateTime(new Date(data.date), 'MM.dd'),
      ) || [];
    const accSeries = accVotedData?.map((data) => data.value) || [];
    const accumulativeVoted = {
      xAxis: [{ scaleType: 'point' as const, data: accXAxis }],
      series: [{ data: accSeries }],
    };

    return accumulativeVoted;
  }, [electronicVoteTimeSeriesQuery?.data?.data]);

  const votedCharts = useMemo(() => {
    if (!electronicVoteTimeSeriesQuery?.data)
      return {
        xAxis: [],
        series: [],
      };

    const { voted: votedData } = electronicVoteTimeSeriesQuery.data.data;

    const xAxis =
      votedData?.map((data) => formatDateTime(new Date(data.date), 'MM.dd')) ||
      [];
    const series = votedData?.map((data) => data.value) || [];
    const voted = {
      xAxis: [{ scaleType: 'point' as const, data: xAxis }],
      series: [{ data: series }],
    };

    return voted;
  }, [electronicVoteTimeSeriesQuery?.data?.data]);

  const withdrawalChart = useMemo(() => {
    if (!electronicVoteTimeSeriesQuery?.data)
      return {
        xAxis: [],
        series: [],
      };

    const { voteWithdrawal } = electronicVoteTimeSeriesQuery.data.data;

    const xAxis =
      voteWithdrawal?.map((data) =>
        formatDateTime(new Date(data.date), 'MM.dd'),
      ) || [];
    const series = voteWithdrawal?.map((data) => data.value) || [];
    const voted = {
      xAxis: [{ scaleType: 'point' as const, data: xAxis }],
      series: [{ data: series }],
    };

    return voted;
  }, [electronicVoteTimeSeriesQuery?.data?.data]);

  if (!meetMethod.includes('ELECTRONIC')) return <></>;

  return (
    <Stack flexWrap="wrap" flexDirection="row" gap={1.75}>
      <Stack gap={1} sx={commonStyle} width="100%">
        <Typography level="body-md">날짜별 누적 전자 투표수</Typography>
        <LineChart
          series={accumulativeVotedCharts.series}
          xAxis={accumulativeVotedCharts.xAxis}
          height={400}
        />
      </Stack>
      <Stack gap={1} sx={commonStyle} width="100%">
        <Typography level="body-md">날짜별 전자 투표수</Typography>
        <LineChart
          series={votedCharts.series}
          xAxis={votedCharts.xAxis}
          height={400}
        />
      </Stack>
      <Stack gap={1} sx={commonStyle} width="100%">
        <Typography level="body-md">날짜별 전자 철회수</Typography>
        <LineChart
          series={withdrawalChart.series}
          xAxis={withdrawalChart.xAxis}
          height={400}
        />
      </Stack>
    </Stack>
  );
};

export default ElectronicLineChart;

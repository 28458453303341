import {
  Stack,
  Typography,
  DataGrid,
  Button,
  useGridUtils,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DetailModal from 'components/pages/meet-management/history/detail/ParticipantHistories/DetailModal';
import SearchFilter from 'components/pages/meet-management/history/detail/ParticipantHistories/SearchFilter';
import {
  ActionType,
  useMeetsParticipantHistoriesQuery,
  useMeetsParticipantHistoryDetailQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import { PARTICIPANT_HISTORIES_TABLE_COLUMNS } from 'lim/generalMeetingHistoryDetail/fixtures';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const translateActionType = (type: ActionType) => {
  switch (type) {
    case 'ADD':
      return '추가';
    case 'DELETE':
      return '삭제';
    case 'EDIT':
      return '수정';
  }
};

const defaultParams = {
  unionRegisterNo: '',
  name: '',
  modifiedBy: '',
  actionType: '',
};

const ParticipantHistoriesPage = () => {
  const { meetSeq } = useParams();
  const navigate = useNavigate();
  const meetId = Number(meetSeq);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedID, setSelectedID] = useState<number>(0);
  const [params, setParams] = useState(defaultParams);

  const { data, isLoading, isError, error } = useMeetsParticipantHistoriesQuery(
    meetId,
    params,
  );

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'participant-history-list',
    activeSaveSnapshot: true,
  });

  const meetsParticipantHistoryDetailQuery =
    useMeetsParticipantHistoryDetailQuery(meetId, selectedID);

  const handleDetailButtonClick = (id: number | string) => {
    setSelectedID(Number(id));
    setShowDetailModal(true);
  };

  const rows = useMemo(
    () =>
      data?.data?.map((row) => {
        const {
          meetParticipantHistorySeq,
          participant,
          modifiedAt,
          modifiedBy,
          actionType,
        } = row;
        const {
          unionRegisterNo,
          position,
          agent,
          shareType,
          name,
          gender,
          birth,
        } = participant;
        return {
          id: meetParticipantHistorySeq,
          unionRegisterNo,
          position,
          agent: agent ? 'O' : 'X',
          shareType,
          name: name?.name,
          gender,
          birth,
          participant,
          modifiedAt,
          modifiedBy,
          actionType: translateActionType(actionType),
        };
      }) || [],
    [data?.data],
  );

  const totalElements = data?.pagination?.totalElements || 0;
  const totalDataCount = data?.pagination?.totalDataCount || 0;

  const handleGoList = () => {
    navigate('..', {
      state: {
        currentTab: 'VOTER',
      },
    });
  };

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <SearchFilter onSearchSubmit={setParams} />
      </Stack>

      <Stack gap={2}>
        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(totalDataCount)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(totalElements)}
            </Typography>
          </Typography>
        </Stack>

        <Stack height={442}>
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={PARTICIPANT_HISTORIES_TABLE_COLUMNS(
              handleDetailButtonClick,
            )}
            loading={isLoading}
            disableRowSelectionOnClick
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Button variant="outlined" color="neutral" onClick={handleGoList}>
            목록
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => exportExcel()}
          >
            내역 다운
          </Button>
        </Stack>
      </Stack>

      <DetailModal
        meetsParticipantHistoryDetailQuery={meetsParticipantHistoryDetailQuery}
        open={showDetailModal}
        onClose={setShowDetailModal}
      />
    </Stack>
  );
};

const participantHistoriesPage: CustomRouteObject = {
  path: 'participant-histories',
  element: <ParticipantHistoriesPage />,
  handle: {
    getTitle: () => '선거인 변동 이력',
  },
};

export default participantHistoriesPage;

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useState } from 'react';

import useFeedback from 'hooks/useFeedback';

interface PasswordConfirmModalProps {
  open: boolean;
  onClose: (open: false) => void;
  onConfirm: (password: string) => void;
}

const PasswordConfirmModal = ({
  open,
  onClose,
  onConfirm,
}: PasswordConfirmModalProps) => {
  const { snackbar } = useFeedback();

  const [password, setPassword] = useState('');

  const handleOnConfirm = () => {
    if (!password) {
      snackbar('비밀번호를 입력해 주세요.', { color: 'danger' });
      return;
    }

    onConfirm(password);
    onClose(false);
  };

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog minWidth={640}>
        <DialogTitle sx={{ alignSelf: 'center' }}>
          가입자의 상세정보를 보시겠습니까?
        </DialogTitle>
        <DialogContent sx={{ marginBottom: '35px' }}>
          <Stack gap={1.75}>
            <Typography level="title-md" alignSelf="center">
              계속하려면 <strong>비밀번호</strong>를 입력해 주세요
            </Typography>
            <Input
              type="password"
              value={password}
              placeholder="비밀번호를 입력하세요."
              onChange={(e) => setPassword(e.target.value)}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button fullWidth onClick={handleOnConfirm}>
            확인
          </Button>

          <Button
            fullWidth
            color="neutral"
            variant="outlined"
            onClick={() => onClose(false)}
          >
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PasswordConfirmModal;

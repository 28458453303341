import JoyListItem from '@mui/joy/ListItem';
import type { ListItemProps as JoyListItemProps } from '@mui/joy/ListItem';
import { ForwardedRef, forwardRef } from 'react';

export type ListItemProps = JoyListItemProps;

const ListItem = (props: ListItemProps, ref: ForwardedRef<HTMLLIElement>) => {
  return <JoyListItem {...props} ref={ref} />;
};

export default forwardRef<HTMLLIElement, ListItemProps>(ListItem);

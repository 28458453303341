import { ColorPaletteProp } from '@mui/joy';
import type { TimeClockProps as XTimeClockProps } from '@mui/x-date-pickers-pro';
import { TimeClock as XTimeClock } from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef } from 'react';

export interface TimeClockProps extends XTimeClockProps<Date> {
  color?: ColorPaletteProp;
}

const TimeClock = (
  props: TimeClockProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { ...other } = props;

  return <XTimeClock ref={ref} {...other} />;
};

export default forwardRef<HTMLDivElement, TimeClockProps>(TimeClock);

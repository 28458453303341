import { createContext } from 'react';

import { PanelProps } from 'design-system/components/surfaces/panels/Panel';

export type PanelContextValue = PanelProps & {
  setOpen?: (value: boolean) => void;
};

const PanelContext = createContext<PanelContextValue | undefined>(undefined);

export default PanelContext;

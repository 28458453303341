import JoyRadio from '@mui/joy/Radio';
import type { RadioProps as JoyRadioProps } from '@mui/joy/Radio';

import useRadioGroup from 'design-system/components/inputs/radio/useRadioGroup';

export type RadioProps = JoyRadioProps;

const Radio = ({ ...other }: RadioProps) => {
  const { name, variant, color, size, disabled, value, onChange, radioProps } =
    useRadioGroup() || {};

  return (
    <JoyRadio
      name={name}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      onChange={onChange}
      checked={value === other.value}
      {...radioProps}
      {...other}
    />
  );
};

export default Radio;

import { createElement, ReactNode } from 'react';
import { StateCreator } from 'zustand';

import ApplyTaxModal from 'components/pages/charges/components/ApplyTaxModal';
import ChargeModal from 'components/pages/charges/components/ChargeModal';

export interface OpenChargeModalProps {
  unionSeq: number;
  unionName: string;
  onSubmit?: (props: { seq: number; isTaxBillApplied: boolean }) => void;
}
export interface OpenTaxBillModalProps {
  unionSeq: number;
  depositChargeSeq: number;
}

export type ChargeModalSlice = {
  isChargeModalOpen: boolean;
  chargeModal: ReactNode | null;
  openChargeModal: (props: OpenChargeModalProps) => void;
  openTaxBillModal: (props: OpenTaxBillModalProps) => void;
  closeChargeModal: () => void;
};

// 임시적으로 처리, 추후 layout 관련 store로 관리 필요
export const createChargeModalSlice: StateCreator<
  ChargeModalSlice,
  [],
  [],
  ChargeModalSlice
> = (set, get) => ({
  isChargeModalOpen: false,
  chargeModal: null,

  openChargeModal: ({ unionSeq, unionName, onSubmit }) =>
    set(() => {
      const chargeModal = createElement(ChargeModal, {
        unionSeq,
        unionName,
        open: true,
        onClose: () => get().closeChargeModal(),
        onSubmit,
      });

      return { isChargeModalOpen: true, chargeModal };
    }),
  openTaxBillModal: ({ unionSeq, depositChargeSeq }) =>
    set(() => {
      const taxBillModal = createElement(ApplyTaxModal, {
        open: true,
        onClose: () => get().closeChargeModal(),
        unionSeq,
        depositChargeSeq,
      });

      return { isChargeModalOpen: true, chargeModal: taxBillModal };
    }),

  closeChargeModal: () =>
    set(() => ({
      isChargeModalOpen: false,
      chargeModal: null,
    })),
});

import { UnionRegisterInfo } from 'apis/types/union';

export const isMissed = (row: UnionRegisterInfo) => {
  const { birth, agent } = row;
  const name = row.name?.name;

  if (
    row?.gender &&
    birth &&
    (row?.mainPhone || row?.subPhones?.length) &&
    name
  ) {
    return false;
  }

  if (agent?.gender && agent.birth && agent.phoneNo && agent.name?.name) {
    return false;
  }

  return true;
};

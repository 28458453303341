import JoySwitch from '@mui/joy/Switch';
import type { SwitchProps as JoySwitchProps } from '@mui/joy/Switch';
import { ForwardedRef, forwardRef } from 'react';

export type SwitchProps = JoySwitchProps;

const Switch = (props: SwitchProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoySwitch {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, SwitchProps>(Switch);

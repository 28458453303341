import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { useForm } from 'react-hook-form';

import useFeedback from 'hooks/useFeedback';
import { PromotionUserBody } from 'lim/meetPromotion/apis';

interface PromotionRegisterModalProps {
  open: boolean;

  onClose: (show: false) => void;
  onSubmit: (formData: PromotionUserBody) => void;
}

const PromotionRegisterModal = ({
  open,

  onClose,
  onSubmit,
}: PromotionRegisterModalProps) => {
  const { snackbar } = useFeedback();

  const { register, handleSubmit: validateSubmit } = useForm({
    defaultValues: {
      name: '',
      phoneNo: '',
      memo: '',
    },
  });

  const handleOnSubmit = validateSubmit((data) => {
    if (data.name && data.phoneNo) {
      return onSubmit(data);
    }

    snackbar('입력되지 않은 값이 있습니다.', { color: 'danger' });
  });

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog sx={{ width: '500px' }}>
        <DialogTitle>홍보 팀장 정보 등록</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Stack gap={1}>
              <TextField
                {...register('name')}
                label="이름 *"
                placeholder="이름을 입력하세요"
                validateOptions={{
                  maxLength: 10,
                }}
              />
            </Stack>
            <Stack gap={1}>
              <TextField
                {...register('phoneNo')}
                label="연락처 *"
                placeholder="연락처를 입력하세요."
                defaultValue=""
                validateOptions={{ maxLength: 11, regex: /^[0-9\s]*$/ }}
              />
            </Stack>
            <Stack gap={1}>
              <TextField
                {...register('memo')}
                label="메모"
                placeholder="메모를 입력하세요."
                validateOptions={{ maxLength: 11, regex: /^[0-9\s]*$/ }}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnSubmit}>확인</Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => onClose(false)}
          >
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PromotionRegisterModal;

import { useTheme } from '@mui/joy';
import { Button, Chip, Stack, Typography } from '@wooriga/design-system';

import { MeetMethods } from 'apis/types/meet';

interface AgendaProps {
  src: string;
  meetMethod: MeetMethods;
  chip: string;
  title: string;
  content: string;
  onClick: () => void;
}

const Agenda = ({
  src,
  meetMethod,
  chip,
  title,
  content,
  onClick,
}: AgendaProps) => {
  const theme = useTheme();

  const includeElectronic = meetMethod.includes('ELECTRONIC');

  return (
    <Stack
      width={267}
      padding={2}
      gap={2}
      border={`1px ${theme.palette.neutral[300]} solid`}
      borderRadius={5}
      sx={{ backgroundColor: theme.palette.neutral[50] }}
    >
      {includeElectronic && <img src={src} alt="agenda_image" />}

      <Stack height="100%" gap={1}>
        <Chip>{chip}</Chip>
        <Stack gap={2}>
          <Typography level="title-lg">{title}</Typography>
          <Typography level="body-md">{content}</Typography>
        </Stack>
      </Stack>

      <Button fullWidth variant="soft" onClick={onClick}>
        선택
      </Button>
    </Stack>
  );
};

export default Agenda;

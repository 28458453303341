import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  FileDragDrop,
  FileList,
  FormControl,
  FormHelperText,
  Input,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormSetValue,
} from 'react-hook-form';

import { CommonCode } from 'apis/common/apis';
import { CreatePostSendBody } from 'apis/post/apis';
import { POST_STAPLE_OPTIONS } from 'apis/post/constants';
import { FileInfo } from 'apis/types/common';
import AddressModalTrigger, {
  AddressModalTriggerProps,
} from 'components/pages/posts/AddressModalTrigger';
import useFeedback from 'hooks/useFeedback';

export interface PostSenderFormValues {
  address: string;
  detailAddress: string;
  zipNo: string;
  name: string;
  phoneNo: string;
  postType: string;
  colorType: string;
  flexType: string;
  isStapler: boolean;
}

export interface PostSenderFormProps {
  files: (File | FileInfo)[];
  control: Control<CreatePostSendBody>;
  setValue: UseFormSetValue<CreatePostSendBody>;
  postTypeGroup: CommonCode[] | undefined;
  flexTypeGroup: CommonCode[] | undefined;
  colorTypeGroup: CommonCode[] | undefined;
  onChangeValues?: (values: PostSenderFormValues) => void;
  onChangeFiles?: (files: (File | FileInfo)[]) => void;
}

const PostSenderForm = (props: PostSenderFormProps) => {
  const {
    control,
    setValue,
    files = [],
    postTypeGroup = [],
    flexTypeGroup = [],
    colorTypeGroup = [],
    onChangeFiles,
  } = props;

  const { snackbar } = useFeedback();

  const handleChangeAddress: AddressModalTriggerProps['onComplete'] = (
    result,
  ) => {
    const { userSelectedType, roadAddress, jibunAddress, zonecode } = result;
    const option = { shouldDirty: true, shouldValidate: true };

    setValue('zipNo', zonecode, option);
    setValue(
      'address',
      userSelectedType === 'R' ? roadAddress : jibunAddress,
      option,
    );
  };

  const handleChangeBooleanValue =
    (field: ControllerRenderProps<CreatePostSendBody>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      field.onChange(value === 'true');
    };

  const handleSelectFiles = (upcomingFiles: FileList | null) => {
    if (!upcomingFiles) {
      return;
    }
    const isFileNameDuplicate = Array.from(upcomingFiles).some(({ name }) =>
      files.some((file) => file.name === name),
    );

    if (isFileNameDuplicate) {
      snackbar('파일 이름이 중복되어 첨부하실 수 없습니다.', {
        color: 'danger',
      });
      return;
    }

    onChangeFiles?.([...files, ...Array.from(upcomingFiles)]);
  };

  const handleSelectFileDelete = (file: File | FileInfo) => {
    const filteredFiles = files.filter((value) => value.name !== file.name);

    onChangeFiles?.(filteredFiles);
  };

  return (
    <>
      <Stack gap={1}>
        <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
          우편발송 정보 입력
        </Typography>

        <Stack direction="row" flexWrap="wrap" gap={3}>
          <Stack flex="1 1 40%" gap={2}>
            <Stack gap={1}>
              <Stack direction="row" alignItems="flex-end" gap={1}>
                <Controller
                  control={control}
                  name="address"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      variant="soft"
                      label="발송자 주소"
                      placeholder="주소"
                      startDecorator={<SearchIcon />}
                      error={!!fieldState.invalid}
                      fullWidth
                      readOnly
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="zipNo"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="soft"
                      placeholder="우편번호"
                      sx={{ width: '120px' }}
                      error={!!fieldState.invalid}
                      readOnly
                    />
                  )}
                />

                <AddressModalTrigger onComplete={handleChangeAddress}>
                  <Button
                    variant="outlined"
                    color="neutral"
                    sx={{ width: '80px' }}
                  >
                    검색
                  </Button>
                </AddressModalTrigger>
              </Stack>

              <Controller
                control={control}
                name="detailAddress"
                render={({ field, formState }) => (
                  <FormControl
                    error={
                      !!formState.errors?.address || !!formState.errors?.zipNo
                    }
                  >
                    <Input placeholder="상세주소 입력" {...field} />
                    <FormHelperText color="red">
                      {formState.errors?.address?.message ??
                        formState.errors?.zipNo?.message ??
                        ''}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Stack>

            <Stack direction="row" gap={1}>
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="발송자 명"
                    placeholder="발송자 명 입력"
                    fullWidth
                    validateOptions={{ maxLength: 30 }}
                    error={!!fieldState.invalid}
                    helperText={fieldState.error?.message ?? ''}
                  />
                )}
              />

              <Controller
                control={control}
                name="phoneNo"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="발송자 연락처"
                    placeholder="숫자만 입력"
                    fullWidth
                    validateOptions={{ maxLength: 11, regex: /^[0-9\s]*$/ }}
                    error={!!fieldState.invalid}
                    helperText={fieldState.error?.message ?? ''}
                  />
                )}
              />
            </Stack>

            <Stack gap={1}>
              <Controller
                control={control}
                name="postType"
                render={({ field }) => (
                  <RadioGroup
                    orientation="horizontal"
                    label="발송방식"
                    {...field}
                  >
                    {postTypeGroup?.map(({ code, name }) => (
                      <Radio
                        key={`post_method_${code}_radio`}
                        label={name}
                        value={code}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Stack>

            <Stack direction="row" gap={8}>
              <Controller
                control={control}
                name="colorType"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    orientation="horizontal"
                    label="출력색상"
                  >
                    {colorTypeGroup?.map(({ code, name }) => (
                      <Radio
                        key={`post_color_${code}_radio`}
                        label={name}
                        value={code}
                      />
                    ))}
                  </RadioGroup>
                )}
              />

              <Controller
                control={control}
                name="flexType"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    orientation="horizontal"
                    label="출력형태"
                  >
                    {flexTypeGroup?.map(({ code, name }) => (
                      <Radio
                        key={`post_flex_${code}_radio`}
                        label={name}
                        value={code}
                      />
                    ))}
                  </RadioGroup>
                )}
              />

              <Controller
                control={control}
                name="isStapler"
                render={({ field }) => (
                  <RadioGroup
                    orientation="horizontal"
                    label="스테이플러"
                    value={String(field.value)}
                    onChange={handleChangeBooleanValue(field)}
                  >
                    {POST_STAPLE_OPTIONS.map(({ label, value }) => (
                      <Radio
                        key={`post_flex_${value}_radio`}
                        label={label}
                        value={String(value)}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Stack>
          </Stack>

          <Stack flex="1 1 40%" gap={2}>
            <Stack gap={0.75}>
              <Typography level="body-sm" textColor="common.black">
                첨부파일 올리기
              </Typography>

              <FileDragDrop
                variant="outlined"
                color="neutral"
                accept="image/png, application/pdf"
                fullWidth
                onSelectFiles={handleSelectFiles}
              />
            </Stack>

            <Stack gap={0.75}>
              <Typography level="body-sm" textColor="common.black">
                첨부파일 목록{' '}
                <Typography color="primary">{files.length}</Typography>
              </Typography>

              <FileList
                variant="outlined"
                color="neutral"
                value={files}
                labelGetter={(value) => value.name}
                onDelete={handleSelectFileDelete}
                fullWidth
                sx={{ height: '160px', overflowY: 'auto' }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PostSenderForm;

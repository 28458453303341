import JoyDialogContent from '@mui/joy/DialogContent';
import type { DialogContentProps as JoyDialogContentProps } from '@mui/joy/DialogContent';
import { ForwardedRef, forwardRef } from 'react';

export type DialogContentProps = JoyDialogContentProps;

const DialogContent = (
  props: DialogContentProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyDialogContent {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, DialogContentProps>(DialogContent);

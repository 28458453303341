import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  CreateMessageBody,
  useCallersQuery,
  useCreateMessageMutation,
} from 'apis/message/apis';
import { MESSAGE_ELECTOR_COLUMNS } from 'apis/message/fixtures';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import MessageBalanceStep from 'components/pages/messages/steps/MessageBalanceStep';
import MessageElectorStep from 'components/pages/messages/steps/MessageElectorStep';
import StepperDialog from 'components/StepperDialog';
import useFeedback from 'hooks/useFeedback';
import {
  MeetsParticipantsParams,
  useMeetsParticipantsQuery,
  VoterMeetsParticipantsParams,
} from 'lim/generalMeetingHistoryDetail/apis';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const voterDefaultParams: VoterMeetsParticipantsParams = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
};

const electronicDefaultParams: MeetsParticipantsParams<'ELECTRONIC'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
  isElectronicVoteResent: '',
  tendency: '',
  receivedAtFrom: '',
  receivedAtTo: '',
  openedAtTo: '',
  openedAtFrom: '',
  votedAtFrom: '',
  votedAtTo: '',
};

const onsiteDefaultParams: MeetsParticipantsParams<'ONSITE'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  tendency: '',
  attendExpectedType: '',
  writtenSubmissionStatus: '',
  writtenSubmissionAtTo: '',
  writtenSubmissionAtFrom: '',
  writtenSubmissionType: '',
};

const DEFAULT_VALUES = {
  scheduledAt: '',
  sendType: 'IMMEDIATE',
  recipients: [],
  files: [],
  title: '',
  message: '',
};

const DEFAULT_SELECTED_UNION_STATE = {
  open: false,
  seq: 0,
};

export interface MessageModalProps {
  meetSeq: number;
  meetMethod: string;
  open: boolean;
  onClose: (show: false) => void;
}

const MessageModal = ({
  meetSeq,
  meetMethod,
  open,
  onClose,
}: MessageModalProps) => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const currentParams =
    meetMethod === 'ELECTRONIC'
      ? electronicDefaultParams
      : meetMethod === 'ONSITE'
      ? onsiteDefaultParams
      : voterDefaultParams;

  const [meetsParticipantsParams, setMeetsParticipantsParams] = useState<
    | MeetsParticipantsParams<'ELECTRONIC' | 'ONSITE'>
    | VoterMeetsParticipantsParams
  >(currentParams);
  const [selectedUnionState, setSelectedUnionState] = useState(
    DEFAULT_SELECTED_UNION_STATE,
  );

  const { data: participants } = useMeetsParticipantsQuery<
    'ELECTRONIC' | 'ONSITE'
  >(meetSeq, meetsParticipantsParams);
  const { data: callersReturnData } = useCallersQuery({
    unionSeq,
  });

  const { mutate: sendMessage } = useCreateMessageMutation();

  const { snackbar, alertDialog } = useFeedback();

  const handleOpenUnionInfoModal = useCallback(
    (seq: number | undefined) => {
      if (!seq) {
        alertDialog('조합원이 아니거나 조합원 정보가 없습니다.', {
          message: '조합원 정보',
        });
        return;
      }

      setSelectedUnionState({ open: true, seq });
    },
    [alertDialog],
  );

  const handleSubmitSendMessage = (data: CreateMessageBody) => {
    sendMessage(
      { ...data, unionSeq },
      {
        onSuccess: () => {
          snackbar('문자 발송이 완료되었습니다.', { color: 'success' });
          onClose(false);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const callers = useMemo(
    () => callersReturnData?.data?.filter(({ status }) => status === 'C') ?? [],
    [callersReturnData?.data],
  );
  const rows = participants?.data || [];

  const { columns } = useCreateGridColumns({
    columns: MESSAGE_ELECTOR_COLUMNS,
    handlers: {
      isElectronic: meetMethod === 'ELECTRONIC',
      onClickUnionMember: handleOpenUnionInfoModal,
    },
  });

  return (
    <>
      <StepperDialog
        title="문자 발송"
        defaultLastButtonText="문자 발송"
        defaultValues={DEFAULT_VALUES}
        open={open}
        onClose={() => {
          onClose(false);
        }}
      >
        <StepperDialog.Step name="조합원 선택">
          <MessageElectorStep
            rows={rows}
            columns={columns}
            callers={callers}
            isElectronic={meetMethod === 'ELECTRONIC'}
            onSearch={setMeetsParticipantsParams}
          />
        </StepperDialog.Step>

        <StepperDialog.Step name="비용 조회">
          <MessageBalanceStep
            unionSeq={unionSeq}
            onSubmit={handleSubmitSendMessage}
          />
        </StepperDialog.Step>
      </StepperDialog>

      {selectedUnionState.open && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq,
            unionRegisterSeq: selectedUnionState.seq,
          }}
          open={selectedUnionState.open}
          onClose={() => setSelectedUnionState(DEFAULT_SELECTED_UNION_STATE)}
        />
      )}
    </>
  );
};

export default MessageModal;

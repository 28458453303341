import { CircularProgressModal } from '@wooriga/design-system';
import { Fragment } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from 'stores';

const FeedbackProvider = () => {
  const { snackbars, feedbackDialogs, loading } = useBoundStore(
    useShallow(({ snackbars, feedbackDialogs, loading }) => ({
      snackbars,
      feedbackDialogs,
      loading,
    })),
  );

  return (
    <>
      {snackbars.size > 0 &&
        [...snackbars.entries()].map(([id, element]) => (
          <Fragment key={id}>{element}</Fragment>
        ))}

      {feedbackDialogs.size > 0 &&
        [...feedbackDialogs.entries()].map(([id, element]) => (
          <Fragment key={id}>{element}</Fragment>
        ))}

      <CircularProgressModal open={loading} />
    </>
  );
};

export default FeedbackProvider;

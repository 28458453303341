import {
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import Search from 'components/Search';
import { MeetsParticipantHistoriesParams } from 'lim/generalMeetingHistoryDetail/apis';

const defaultParams = {
  unionRegisterNo: '',
  name: '',
  modifiedBy: '',
  actionType: '',
};

const SearchFilter = ({
  onSearchSubmit,
}: {
  onSearchSubmit: (params: MeetsParticipantHistoriesParams) => void;
}) => {
  const { getGroupCode } = useCommonCodes();
  const statusGroup = useMemo(
    () => getGroupCode('MEET_PARTICIPANT_ACTION_TYPE'),
    [getGroupCode],
  );

  const [selectParams, setSelectParams] = useState<
    Pick<MeetsParticipantHistoriesParams, 'actionType'>
  >({
    actionType: '',
  });

  const handleOnSearch = (params: MeetsParticipantHistoriesParams) => {
    onSearchSubmit({
      ...params,
      ...selectParams,
    });
  };

  const handleOnSelect = (key: 'actionType', value: string) => {
    setSelectParams({
      ...selectParams,
      [key]: value,
    });
  };

  const handleOnReset = () => {
    setSelectParams({
      actionType: '',
    });
    onSearchSubmit(defaultParams);
  };

  return (
    <Search
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      defaultValues={defaultParams}
      onSubmit={handleOnSearch}
      onReset={handleOnReset}
    >
      <Grid container gap={2}>
        <Stack flexDirection="row" flexWrap="wrap" gap={2}>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                label="연번"
                name="unionRegisterNo"
                placeholder='숫자 또는 "-"입력'
                validateOptions={{
                  maxLength: 11,
                  regex: /^(?!.*--)[0-9-]*$/,
                }}
                fullWidth
              />
            </Search.Field>
          </Grid>

          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="작업자를 입력하세요"
                label="작업자"
                name="modifiedBy"
                fullWidth
              />
            </Search.Field>
          </Grid>

          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="이름을 입력하세요"
                label="이름"
                name="name"
                fullWidth
                slotProps={{
                  input: { maxLength: 30 },
                }}
              />
            </Search.Field>
          </Grid>

          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>기능</FormLabel>
                <Select
                  fullWidth
                  name="actionType"
                  value={selectParams.actionType}
                  onChange={(_, value) =>
                    handleOnSelect('actionType', value as unknown as string)
                  }
                >
                  <Option value="">전체</Option>
                  {statusGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>
        </Stack>
      </Grid>
    </Search>
  );
};

export default SearchFilter;

import {
  UseMutateFunction,
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import 'lim/generalMeetingHistoryDetail/json';
import { RealAddress } from 'apis/types/common';
import { MeetInfo, MeetMethods, MeetStatus } from 'apis/types/meet';
import { UnionRegisterInfo } from 'apis/types/union';
import {
  CalculateCostResponse,
  VoteForm,
  VoteType as MeetOpenVoteType,
} from 'lim/meetOpen/apis';
import {
  DateParamsCommonKeyword,
  PromotionUserResponse,
  UseStatus,
} from 'lim/type';
import {
  ElectronicVoteStatusResponse,
  MeetParticipantResponse,
  OnsiteVoteStatusResponse,
} from 'lim/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

export interface ElectronicVoteSendStatusCount {
  received: number;
  sendWait: number;
  sendFail: number;
  sendDone: number;
  unSent: number;
  opened: number;
  unOpened: number;
  voted: number;
  unVoted: number;
}

export type ElectronicVoteSendStatusCountQuery = UseQueryResult<
  ApiResponseData<ElectronicVoteSendStatusCount>,
  AxiosError
>;

export const useElectronicVoteSendStatusCountQuery = (
  meetSeq: number,
): ElectronicVoteSendStatusCountQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/electronic-vote-send-status-count`],
    // initialData: generateInitRealData(electronicVoteSendStatusCountData),
  });

export interface TendencyCount {
  upper: number;
  middle: number;
  lower: number;
  undetermined: number;
  nothing: number;
}

export interface ElectronicVoteTendencyCount {
  voted: TendencyCount;
  opened: TendencyCount;
}

export type ElectronicVoteTendencyCountQuery = UseQueryResult<
  ApiResponseData<ElectronicVoteTendencyCount>,
  AxiosError
>;

export const useElectronicVoteTendencyCountQuery = (
  meetSeq: number,
): ElectronicVoteTendencyCountQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/electronic-vote-tendency-count`],
    // initialData: generateInitRealData(electronicVoteTendencyCountData),
  });

export interface TimeSeriesData {
  date: string;
  value: number;
}

export interface ElectronicVoteTimeSeries {
  accumulatedVoted?: TimeSeriesData[];
  voted: TimeSeriesData[];
  voteWithdrawal?: TimeSeriesData[];
}

export type ElectronicVoteTimeSeriesQuery = UseQueryResult<
  ApiResponseData<ElectronicVoteTimeSeries>,
  AxiosError
>;

export const useElectronicVoteTimeSeriesQuery = (
  meetSeq: number,
): ElectronicVoteTimeSeriesQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/electronic-vote-time-series`],
    // initialData: generateInitRealData(electronicVoteTimeSeriesData),
  });

export type MeetsDetailQuery = UseQueryResult<
  ApiResponseData<MeetInfo>,
  AxiosError
>;

export const useMeetsDetailQuery = (meetSeq: number): MeetsDetailQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}`],
    // initialData: generateInitRealData(meetsDetailData(meetSeq)),
  });

export interface Candidate {
  no: number;
  name: string;
  description: string;
  attachFileSeq: number;
}

export interface Agenda {
  agendaSeq: number;
  name: string;
  description: string;
  voteForm: VoteForm;
  voteType: MeetOpenVoteType;
  order: number;
  attachFileSeq: number;
  selectCount: number;
  candidates: Candidate[];
}

export type MeetsAgendasQuery = UseQueryResult<
  ApiResponseData<Agenda[]>,
  AxiosError
>;

export const useMeetsAgendasQuery = (meetSeq: number): MeetsAgendasQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/agendas`],
    // initialData: generateInitRealData(meetsAgendasData),
  });

export interface Name {
  name: string;
  nameClass: string;
}

export interface Agent {
  unionRegisterAgentSeq: number;
  name: Name;
  birth: string;
  phoneNo: string;
  gender: string;
}

export type ShareType = 'MANY' | 'REPRESENT' | 'SHARE' | 'SINGLE';

export interface Participant {
  meetParticipantSeq: number;
  unionRegisterSeq: number;
  unionRegisterNo: string;
  name: Name;
  birth: string;
  phoneNo: string;
  gender: string;
  realAddress: RealAddress;
  tendency: string;
  position: string;
  shareType: ShareType;
  agent: Agent;
  electronicVoteStatus: ElectronicVoteStatusResponse;
  onsiteVoteStatus: OnsiteVoteStatusResponse;
  promotionUser?: PromotionUserResponse;
}

export type MeetsParticipantsQuery = UseQueryResult<
  ApiResponseData<MeetParticipantResponse[]>,
  AxiosError
>;

type CommonMeetsParticipantsParamsKeys = 'unionRegisterNo' | 'phoneNo' | 'name';

export type CommonMeetsParticipantsParams = {
  [key in CommonMeetsParticipantsParamsKeys]: string;
};

type ElectronicMeetsParticipantsDateParamsKeys = DateParamsCommonKeyword<
  'received' | 'opened' | 'voted'
>;

type OnsiteMeetsParticipantsDateParamsKeys =
  DateParamsCommonKeyword<'writtenSubmission'>;

type ElectronicMeetsParticipantsSelectParamsKeys =
  | 'electronicVoteProgressStatus'
  | 'isElectronicVoteResent'
  | 'tendency';

type ElectronicMeetsParticipantsParams = CommonMeetsParticipantsParams & {
  [key in
    | ElectronicMeetsParticipantsSelectParamsKeys
    | ElectronicMeetsParticipantsDateParamsKeys]: string;
};

type OnsiteMeetsParticipantsParamsKeys =
  | 'writtenSubmissionType'
  | 'writtenSubmissionStatus'
  | 'attendExpectedType'
  | 'tendency';

type OnsiteMeetsParticipantsParams = CommonMeetsParticipantsParams & {
  [key in
    | OnsiteMeetsParticipantsParamsKeys
    | OnsiteMeetsParticipantsDateParamsKeys]: string;
};

type VoterMeetsParticipantsKeys =
  | CommonMeetsParticipantsParamsKeys
  | 'electronicVoteProgressStatus';

export type VoterMeetsParticipantsParams = {
  [key in VoterMeetsParticipantsKeys]: string;
};

export type MeetsParticipantsParams<MeetMethod extends MeetMethods> =
  MeetMethod extends 'ELECTRONIC'
    ? ElectronicMeetsParticipantsParams
    : OnsiteMeetsParticipantsParams;

export const useMeetsParticipantsQuery = <
  MeetMethod extends MeetMethods = 'ELECTRONIC',
>(
  meetSeq: number,
  params?: MeetsParticipantsParams<MeetMethod> | VoterMeetsParticipantsParams,
): MeetsParticipantsQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/participants`, params],
    // initialData: generateInitRealData(participantsData),
    placeholderData: keepPreviousData,
  });

export interface VoteResultCount {
  yesCount: number;
  noCount: number;
  abstentionCount: number;
  totalCount: number;
}

export interface VoteResultCandidate extends Omit<Candidate, 'attachFileSeq'> {
  isPassed: boolean;
  electronicResult: VoteResultCount;
  writtenSubmissionResult: VoteResultCount;
  onsiteResult: VoteResultCount;
}

export interface VoteResult {
  agendaSeq: number;
  name: string;
  description: string;
  voteForm: VoteForm;
  voteType: MeetOpenVoteType;
  order: number;
  selectCount: number;
  candidates: VoteResultCandidate[];
}

export type MeetsVoteResultQuery = UseQueryResult<
  ApiResponseData<VoteResult[]>,
  AxiosError
>;
export type MeetJointBookResultQuery = UseQueryResult<
  ApiResponseData<{
    privateFileSeq?: number;
    publicFileSeq?: number;
    status: string;
  }>,
  AxiosError
>;

export const useMeetsVoteResultQuery = (
  meetSeq: number,
): MeetsVoteResultQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/vote-result`],
    // initialData: generateInitRealData(voteResultData),
  });

export const useElectronicVoteResultQuery = (
  meetSeq: number,
): MeetsVoteResultQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/electronic-vote-result`],
  });

export interface MeetCandidateInfo {
  agendaSeq: number;
  candidateNo: number;
}

export interface CompleteMeetBody {
  approvalList: MeetCandidateInfo[];
  rejectionList: MeetCandidateInfo[];
}

export type CompleteMeetMutation = UseMutationResult<
  ApiResponseData,
  AxiosError,
  CompleteMeetBody
>;

export const useCompleteMeetMutation = (
  meetSeq: number,
): CompleteMeetMutation =>
  useMutation({
    mutationFn: async (formData: CompleteMeetBody) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/meets/${meetSeq}/complete`,
        formData,
      );
      return data;
    },
  });

export interface ParticipantsBody {
  unionRegisterSeqs: number[];
}

export type MeetsParticipantsMutation = UseMutationResult<
  ApiResponseData,
  AxiosError,
  ParticipantsBody
>;

export const useMeetsJointBookQuery = (
  meetSeq: number,
): MeetJointBookResultQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/joint-book`],
  });

export interface JointBookRequest {
  isNotificationAllowed: boolean;
  memberSeq: number;
  name: string;
  phoneNo: string;
  meetSeq: number;
}

export const useRegisterJointBookMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  JointBookRequest
  // RegisterConsentRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      const { meetSeq, ...body } = request;
      console.log(meetSeq, body);
      const { data } = await axiosInstance.post(
        `/meets/${meetSeq}/joint-book`,
        body,
      );
      return data;
    },
  });

export const useMeetsParticipantsMutation = (
  meetSeq: number,
): MeetsParticipantsMutation =>
  useMutation({
    mutationFn: async (formData: ParticipantsBody) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/meets/${meetSeq}/participants`,
        formData,
      );
      return data;
    },
  });

export interface WrittenSubmissionCount {
  direct: number;
  registered: number;
  manager: number;
  expected: number;
  refusal: number;
  undefined: number;
  none: number;
}

export type MeetsWrittenSubmissionCountQuery = UseQueryResult<
  ApiResponseData<WrittenSubmissionCount>,
  AxiosError
>;

export const useMeetsWrittenSubmissionCountQuery = (
  meetSeq: number,
): MeetsWrittenSubmissionCountQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/written-submission-count`],
    // initialData: generateInitRealData(writtenSubmissionCountData),
  });

export interface AttendExpectedCount {
  mySelf: number;
  agent: number;
  nonAppearance: number;
  undefined: number;
  none: number;
}

export type MeetsAttendExpectedCountQuery = UseQueryResult<
  ApiResponseData<AttendExpectedCount>,
  AxiosError
>;

export const useMeetsAttendExpectedCountQuery = (
  meetSeq: number,
): MeetsAttendExpectedCountQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/attend-expected-count`],
    // initialData: generateInitRealData(attendExpectedCountData),
  });

export interface WrittenSubmissionTimeSeries {
  accumulatedWrittenSubmission: TimeSeriesData[];
  writtenSubmission: TimeSeriesData[];
  writtenWithdrawal: TimeSeriesData[];
}

export type MeetsWrittenSubmissionTimeSeriesQuery = UseQueryResult<
  ApiResponseData<WrittenSubmissionTimeSeries>,
  AxiosError
>;

export const useMeetsWrittenSubmissionTimeSeriesQuery = (
  meetSeq: number,
): MeetsWrittenSubmissionTimeSeriesQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/written-submission-time-series`],
    // initialData: generateInitRealData(writtenSubmissionTimeSeriesData),
  });

export interface AttendanceType {
  onsite: number;
  writtenSubmission: number;
  electronic: number;
}

export interface OnsiteAttendanceType {
  mySelf: number;
  agent: number;
}

export interface VoteType {
  onsite: number;
  writtenSubmission: number;
  electronic: number;
}

export interface RealtimeStatusCount {
  attendanceType: AttendanceType;
  onsiteAttendanceType: OnsiteAttendanceType;
  voteType: VoteType;
}

export type MeetsRealtimeStatusCountQuery = UseQueryResult<
  ApiResponseData<RealtimeStatusCount>,
  AxiosError
>;

export const useMeetsRealtimeStatusCountQuery = (
  meetSeq: number,
): MeetsRealtimeStatusCountQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/realtime-status-count`],
    // initialData: generateInitRealData(realtimeStatusCountData),
  });

export interface PromotionUserDetail {
  promotionUserSeq: number;
  id: string;
  name: string;
  phoneNo: string;
  memo: string;
  position: string;
  userStatus: UseStatus;
  createdAt: string;
  assignedParticipantCount: number;
}

export type MeetsPromotionUserDetailQuery = UseQueryResult<
  ApiResponseData<PromotionUserDetail>,
  AxiosError
>;

export const useMeetsPromotionUserDetailQuery = (
  meetSeq: number,
  promotionUserSeq: number,
): MeetsPromotionUserDetailQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/promotion-users/${promotionUserSeq}`],
    enabled: !!promotionUserSeq,
    // initialData: generateInitRealData(promotionUserData),
  });

export interface VoteResultBody {
  decision: Decision;
  agendas: MutateAgenda[];
}

export interface Decision {
  writtenSubmissionCount: number;
  onsiteCount: number;
}

export interface MutateAgenda {
  agendaSeq: number;
  order: number;
  candidates: MutateCandidate[];
}

export interface MutateCandidate {
  no: number;
  electronicResult?: MutateVoteResult;
  writtenSubmissionResult: MutateVoteResult;
  onsiteResult: MutateVoteResult;
}

export interface MutateVoteResult {
  yesCount: number;
  noCount: number;
  abstentionCount: number;
  totalCount: number;
}

export type MeetsVoteResultMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  VoteResultBody
>;

export const useMeetsVoteResultMutation = (
  meetSeq: number,
): MeetsVoteResultMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/meets/${meetSeq}/vote-result`,
        formData,
      );
      return data;
    },
  });

export interface VoteResultSummary {
  onsiteVoteCount: number;
  writtenSubmissionCount: number;
  electronicVoteCount: number;
  totalParticipantCount: number;
  totalVoteCount: number;
}

export type MeetsVoteResultSummaryQuery = UseQueryResult<
  ApiResponseData<VoteResultSummary>,
  AxiosError
>;

export const useMeetsVoteResultSummaryQuery = (
  meetSeq: number,
): MeetsVoteResultSummaryQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/vote-result/summary`],
    // initialData: generateInitRealData(voteResultSummaryData),
  });

export interface PasswordVerifyBody {
  password: string;
}

export type MeetsPasswordVerifyMutation = UseMutationResult<
  ApiResponseData,
  AxiosError,
  PasswordVerifyBody
>;

export type MeetsPasswordVerifyMutateFunction = UseMutateFunction<
  ApiResponseData,
  AxiosError,
  PasswordVerifyBody
>;

export const useMeetsPasswordVerifyMutation = (
  meetSeq: number,
): MeetsPasswordVerifyMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/meets/${meetSeq}/password/verify`,
        formData,
      );

      return data;
    },
  });

export interface PasswordPatchBody {
  previousPassword: string;
  password: string;
}

export type MeetsPasswordPatchMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PasswordPatchBody
>;

export const useMeetsPasswordPatchMutation = (
  meetSeq: number,
): MeetsPasswordPatchMutation =>
  useMutation({
    mutationFn: async (formData: PasswordPatchBody) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/meets/${meetSeq}/password`,
        formData,
      );
      return data;
    },
  });

export interface StatusPatchBody {
  status: MeetStatus;
}

export type MeetsStatusPatchMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  StatusPatchBody
>;

export const useMeetsStatusPatchMutation = (
  meetSeq: number,
): MeetsStatusPatchMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/meets/${meetSeq}/status`,
        formData,
      );

      return data;
    },
  });

export type OpenType = 'DELEGATE' | 'GENERAL' | 'BOARD';

export type UnionRegistersQuery = UseQueryResult<
  ApiResponseData<UnionRegisterInfo[]>,
  AxiosError
>;

export type UnionRegistersParams = Partial<{
  unionRegisterNo: string;
  unionRegisterNoMain: number;
  name: string;
  phoneNo: string;
  shareType: string[];
  position: string[];
  localAddress: string;
  realAddress: string;
  ownerType: string;
  hasAgent: boolean;
}>;

export const useUnionRegistersQuery = (
  unionSeq: number,
  params?: UnionRegistersParams,
): UnionRegistersQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/registers`, params],
    // initialData: generateInitRealData(unionRegistersData),
  });

export type ParticipantHistoriesPromotionUser = PromotionUserResponse & {
  createdAt: string;
};

export type ActionType = 'ADD' | 'EDIT' | 'DELETE';
export interface ParticipantHistory {
  meetParticipantHistorySeq: number;
  actionType: ActionType;
  modifiedAt: string;
  modifiedBy: string;
  reason: string;
  participant: MeetParticipantResponse;
  previousParticipant: MeetParticipantResponse;
}

export type MeetsParticipantHistoriesQuery = UseQueryResult<
  ApiResponseData<ParticipantHistory[]>,
  AxiosError
>;

export interface MeetsParticipantHistoriesParams {
  unionRegisterNo: string;
  name: string;
  modifiedBy: string;
  actionType: string;
}

export const useMeetsParticipantHistoriesQuery = (
  meetSeq: number,
  params: MeetsParticipantHistoriesParams,
): MeetsParticipantHistoriesQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/participant-histories`, params],
    // initialData: generateInitRealData(participantHistoriesData),
  });

export type MeetsParticipantHistoryDetailQuery = UseQueryResult<
  ApiResponseData<ParticipantHistory>,
  AxiosError
>;

export const useMeetsParticipantHistoryDetailQuery = (
  meetSeq: number,
  meetParticipantHistorySeq: number,
): MeetsParticipantHistoryDetailQuery =>
  useQuery({
    queryKey: [
      `/meets/${meetSeq}/participant-histories/${meetParticipantHistorySeq}`,
    ],
    // initialData: generateInitRealData(participantHistoryDetailData),
  });

export interface ElectronicVoteHistory {
  progressStatus: string;
  voteStatus: string;
  receivedAt: string;
  openedAt: string;
  votedAt: string;
  sentAt: string;
  resendCount: number;
}

export type ElectronicVoteHistoriesQuery = UseQueryResult<
  ApiResponseData<ElectronicVoteHistory[]>,
  AxiosError
>;

export const useElectronicVoteHistoriesQuery = (
  meetSeq: number,
  meetParticipantSeq: number,
): ElectronicVoteHistoriesQuery =>
  useQuery({
    queryKey: [
      `/meets/${meetSeq}/participants/${meetParticipantSeq}/electronic-vote-histories`,
    ],
    // initialData: electronicVoteHistoriesData,
  });

export interface ElectronicVoteCostCalculateBody {
  meetParticipantSeqs: number[];
}

export type ElectronicVoteCostCalculateMutation = UseMutationResult<
  ApiResponseData<CalculateCostResponse>,
  ApiError,
  ElectronicVoteCostCalculateBody
>;

export const useElectronicVoteCostCalculateMutate = (
  meetSeq: number,
): ElectronicVoteCostCalculateMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<
        ApiResponseData<CalculateCostResponse>
      >(`/meets/${meetSeq}/electronic-vote/cost/calculate`, formData);

      return data;
    },
  });

interface PostResendBody {
  meetParticipantSeqs: number[];
}

export type PostResendMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PostResendBody
>;

export const useElectronicVoteResendMutate = (
  meetSeq: number,
): PostResendMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/meets/${meetSeq}/electronic-vote/resend`,
        formData,
      );

      return data;
    },
  });

export type MeetParticipantQuery = UseQueryResult<
  ApiResponseData<MeetParticipantResponse>,
  AxiosError
>;

export const useMeetParticipantDetailQuery = (
  meetSeq: number,
  meetParticipantSeq: number,
): MeetParticipantQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/participants/${meetParticipantSeq}`],
    // initialData: meetParticipantData,
  });

export interface UpdateMeetBody {
  onsiteVote: {
    meetAt?: Date;
    submissionEndAt?: Date;
    jibunAddress?: string;
    roadAddress?: string;
    detailAddress?: string;
  };
  electronicVote: {
    contactNo?: string;
  };
}

export type UpdateMeetMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  UpdateMeetBody
>;

export const useUpdateMeetMutation = (meetSeq: number): UpdateMeetMutation =>
  useMutation({
    mutationFn: async (formData: UpdateMeetBody) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/meets/${meetSeq}`,
        formData,
      );

      return data;
    },
  });

export interface MeetsOnsiteVoteCountResponse {
  writtenSubmission: number;
  onsiteVote: number;
}

export type MeetsOnsiteVoteCount = UseQueryResult<
  ApiResponseData<MeetsOnsiteVoteCountResponse>,
  AxiosError
>;

export const useMeetsOnsiteVoteCountQuery = (
  meetSeq: number,
): MeetsOnsiteVoteCount =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/onsite-vote/vote-count`],
  });

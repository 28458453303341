import { MeetMethods } from 'apis/types/meet';
import {
  VoteResult,
  VoteResultCandidate,
} from 'lim/generalMeetingHistoryDetail/apis';
import { Agenda, VoteForm, VoteType } from 'lim/meetOpen/apis';

import { generateMeetMethodName } from '../../utils';

interface GenerateRowDataProps {
  meetMethod: MeetMethods;
  meet:
    | 'total'
    | 'electronicResult'
    | 'onsiteResult'
    | 'writtenSubmissionResult';
  voteResult: VoteResult;
  candidate: VoteResultCandidate;
  totalVoteCount: number;
}

export const calculateVoteRate = (yesCount: number, totalCount: number) => {
  const originRate = (yesCount / totalCount) * 100;
  const voteRate = Math.round(originRate * 100) / 100;

  if (isNaN(originRate)) return `0%`;

  return `${voteRate}%`;
};

export const translateVoteType = (
  voteForm: VoteForm,
  voteType: VoteType,
  selectCount: number = 0,
) => {
  if (isChoiceVote(voteForm)) {
    const type = voteType === 'MULTI' ? `-${selectCount}개 선택` : '-1건 선택';

    return `선택투표${type}`;
  }
  const type = voteType === 'MULTI' ? '-후보 여러명(개)' : '';

  return `찬반투표${type}`;
};

export const isChoiceVote = (voteForm: VoteForm) => {
  if (voteForm === 'SELECT') return true;
  return false;
};

export const calculateCandidateVoteCount = (
  meetMethod: MeetMethods,
  candidate: VoteResultCandidate,
) => {
  const {
    electronicResult: {
      yesCount: electronicYesCount,
      noCount: electronicNoCount,
      totalCount: electronicTotalCount,
      abstentionCount: electronicAbstentionCount,
    },
    onsiteResult: {
      yesCount: onsiteYesCount,
      noCount: onsiteNoCount,
      totalCount: onsiteTotalCount,
      abstentionCount: onsiteAbstentionCount,
    },
    writtenSubmissionResult: {
      yesCount: writtenSubmissionYesCount,
      noCount: writtenSubmissionNoCount,
      totalCount: writtenSubmissionTotalCount,
      abstentionCount: writtenSubmissionAbstentionCount,
    },
  } = candidate;

  const onsiteYesCountTotal = onsiteYesCount + writtenSubmissionYesCount;
  const onsiteNoCountTotal = onsiteNoCount + writtenSubmissionNoCount;
  const onsiteTotalCountTotal = onsiteTotalCount + writtenSubmissionTotalCount;
  const onsiteAbstentionCountTotal =
    onsiteAbstentionCount + writtenSubmissionAbstentionCount;

  if (meetMethod === 'ELECTRONIC') {
    return {
      yesCount: electronicYesCount,
      noCount: electronicNoCount,
      totalCount: electronicTotalCount,
      abstentionCount: electronicAbstentionCount,
    };
  }

  if (meetMethod === 'ONSITE') {
    return {
      yesCount: onsiteYesCountTotal,
      noCount: onsiteNoCountTotal,
      totalCount: onsiteTotalCountTotal,
      abstentionCount: onsiteAbstentionCountTotal,
    };
  }

  return {
    yesCount: electronicYesCount + onsiteYesCountTotal,
    noCount: electronicNoCount + onsiteNoCountTotal,
    totalCount: electronicTotalCount + onsiteTotalCountTotal,
    abstentionCount: electronicAbstentionCount + onsiteAbstentionCountTotal,
  };
};

export const generateRowData = ({
  meetMethod,
  meet,
  voteResult,
  candidate,
  totalVoteCount,
}: GenerateRowDataProps) => {
  const method = meet === 'total' ? '총합' : generateMeetMethodName(meet);
  const { agendaSeq, order, name: agendaName } = voteResult;
  const { no, name: candidateName, isPassed } = candidate;

  const countObject =
    meet === 'total'
      ? calculateCandidateVoteCount(meetMethod, candidate)
      : candidate[meet];

  const { yesCount, noCount, abstentionCount } = countObject;
  // const totalCount =
  //   voteResult.voteForm === 'SELECT' ? totalVoteCount : countObject.totalCount;

  return {
    id: `${agendaSeq}-${no}-${meet}`,
    order: `제 ${order}호`,
    agenda: agendaName,
    method,
    no: `후보 ${no}`,
    candidate: candidateName,
    yesCount,
    noCount,
    totalCount: totalVoteCount,
    abstentionCount,
    voteRate: calculateVoteRate(yesCount, totalVoteCount),
    isPassed,
    isChoiceVote: isChoiceVote(voteResult.voteForm),
  };
};

export const calculateTotalVoteCount = (
  votedCount: number,
  data?: Agenda[],
) => {
  if (!data) return NaN;
  const selectCountData: number[] = [];
  const againstCountData: number[] = [];
  data?.forEach(({ voteForm, selectCount, candidates }) => {
    if (voteForm === 'AGAINST') {
      againstCountData.push(candidates.length);
    }
    if (voteForm === 'SELECT') {
      selectCountData.push(selectCount);
    }
  });

  const multiSelectTotalCount = selectCountData?.reduce(
    (acc, cur) => (acc += cur),
  );
  const againstTotalCount = againstCountData?.reduce(
    (acc, cur) => (acc += cur),
  );

  const totalCount =
    (Number(multiSelectTotalCount) + Number(againstTotalCount)) * votedCount;
  return totalCount;
};

import { styled } from '@mui/joy';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { SVGTextElementAttributes } from 'react';

import {
  Typography,
  TypographyProps,
} from 'design-system/components/dataDisplay';

export interface PieCenterLabelProps extends TypographyProps {}

const PieCenterLabel = (props: PieCenterLabelProps) => {
  const { level = 'h4', children, ...other } = props;
  const { width, height, left, top } = useDrawingArea();

  return (
    <StyledText
      component="text"
      x={left + width / 2}
      y={top + height / 2}
      level={level}
      {...other}
    >
      {children}
    </StyledText>
  );
};

const StyledText = styled(Typography)<
  PieCenterLabelProps & {
    x: SVGTextElementAttributes<SVGTextElement>['x'];
    y: SVGTextElementAttributes<SVGTextElement>['y'];
  }
>(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
}));

export default PieCenterLabel;

import { Stack } from '@wooriga/design-system';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import {
  MeetsAttendExpectedCountQuery,
  MeetsWrittenSubmissionCountQuery,
} from 'lim/generalMeetingHistoryDetail/apis';

interface OnsiteDescriptionsProps {
  meetsWrittenSubmissionCountQuery?: MeetsWrittenSubmissionCountQuery;
  meetsAttendExpectedCountQuery?: MeetsAttendExpectedCountQuery;
}

const OnsiteDescriptions = ({
  meetsWrittenSubmissionCountQuery,
  meetsAttendExpectedCountQuery,
}: OnsiteDescriptionsProps) => {
  if (!meetsWrittenSubmissionCountQuery?.data) return;
  if (!meetsAttendExpectedCountQuery?.data) return;

  const {
    direct,
    registered,
    manager,
    expected,
    refusal,
    undefined: submitUndefined,
    none,
  } = meetsWrittenSubmissionCountQuery.data.data;

  const {
    mySelf,
    agent,
    nonAppearance,
    undefined: expectedUndefined,
    none: expectedNone,
  } = meetsAttendExpectedCountQuery.data.data;

  const submitJSON = generateDescriptionsJSON(
    ['제출(직접)', '제출(등기)', '제출(담당자)'],
    [direct, registered, manager],
  );

  const unSubmitJSON = generateDescriptionsJSON(
    ['예정', '거부', '미정', '미응답'],
    [expected, refusal, submitUndefined, none],
  );

  const expectedJSON = generateDescriptionsJSON(
    ['본인', '대리인', '불참', '미정', '미확인'],
    [mySelf, agent, nonAppearance, expectedUndefined, expectedNone],
  );

  return (
    <Stack gap={1.75} marginBottom="30px">
      <DescriptionsRenderer columns={5} json={submitJSON} />
      <DescriptionsRenderer columns={5} json={unSubmitJSON} />
      <DescriptionsRenderer columns={5} json={expectedJSON} />
    </Stack>
  );
};

export default OnsiteDescriptions;

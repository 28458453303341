import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Stack, Box, Typography, Button, Link } from '@wooriga/design-system';
import { useParams } from 'react-router-dom';

import { CustomRouteObject } from 'types/route';

const MainPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  return (
    <Box maxWidth={480} mt={7}>
      <Box
        position="absolute"
        bottom={0}
        right={0}
        width={620}
        height={588}
        sx={{ backgroundImage: 'url(/images/logos/logo_main_003.svg)' }}
      />

      <Box
        sx={{
          marginBottom: '80px',
        }}
      >
        <img src="/images/logos/logo_main_002.svg" alt="메인로고" />
      </Box>

      <Typography
        component="h1"
        fontSize={44}
        fontWeight="xl"
        textColor="neutral.600"
        mb={1.25}
      >
        환영합니다
      </Typography>

      <Typography
        component="p"
        fontSize="lg"
        fontWeight="md"
        lineHeight="lg"
        textColor="neutral.600"
        whiteSpace="break-spaces"
      >
        조합 운영의 효율성을 높이는 <br />
        조합 운영·관리 시스템, WOORIGA OMS 입니다
      </Typography>

      <Stack flexDirection="row" gap={2} mt={5}>
        <Button
          variant="soft"
          color="primary"
          size="lg"
          component={Link}
          href={`https://www.wooriga.kr/unions/${unionSeq}`}
        >
          조합 홈페이지 이동
        </Button>
        <Button
          variant="soft"
          color="primary"
          size="lg"
          component={Link}
          href="https://vms.wooriga.kr"
        >
          총회 관리 시스템 이동
        </Button>
      </Stack>
    </Box>
  );
};

const mainPage: CustomRouteObject = {
  path: '/unions/:unionSeq',
  element: <MainPage />,
  handle: {
    getTitle: () => '홈',
    getIcon: () => <HomeOutlinedIcon />,
  },
};

export default mainPage;

import JoyCardActions from '@mui/joy/CardActions';
import type { CardActionsProps as JoyCardActionsProps } from '@mui/joy/CardActions';
import { ForwardedRef, forwardRef } from 'react';

export type CardActionsProps = JoyCardActionsProps;

const CardActions = (
  props: CardActionsProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyCardActions {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, CardActionsProps>(CardActions);

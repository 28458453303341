import { styled } from '@mui/joy';
import { ChangeEvent, ForwardedRef, forwardRef, useCallback } from 'react';

import Checkbox, {
  CheckboxProps,
} from 'design-system/components/inputs/checkbox/Checkbox';

export interface JoyCheckboxProps
  extends Omit<CheckboxProps, 'onChange' | 'color' | 'size' | 'sx' | 'value'> {
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const JoyCheckbox = (
  props: JoyCheckboxProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const { onChange, checked, ...other } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event, event.target.checked);
    },
    [onChange],
  );

  return (
    <StyledCheckbox
      {...other}
      variant={checked ? 'solid' : 'outlined'}
      checked={checked}
      onChange={handleChange}
      ref={ref}
    />
  );
};

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (propName) =>
    propName !== 'touchRippleRef' && propName !== 'inputProps',
})``;

JoyCheckbox.displayName = 'JoyCheckbox';

export default forwardRef<HTMLInputElement, JoyCheckboxProps>(JoyCheckbox);

import { useTheme } from '@mui/joy';
import {
  legendClasses,
  BarChart as XBarChart,
  axisClasses,
  chartsGridClasses,
  markElementClasses,
  lineHighlightElementClasses,
} from '@mui/x-charts';
import type { BarChartProps as XBarChartProps } from '@mui/x-charts/BarChart';
import { ForwardedRef, forwardRef } from 'react';

export interface BarChartProps extends XBarChartProps {}

const BarChart = (props: BarChartProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    sx,
    children,
    series,
    width = 300,
    height = 200,
    grid = { horizontal: true, vertical: false },
    leftAxis = null,
    rightAxis,
    bottomAxis,
    topAxis,
    ...other
  } = props;

  const theme = useTheme();

  return (
    <XBarChart
      sx={{
        [`& .${legendClasses.root}`]: {
          [`& .${legendClasses.mark}`]: {
            rx: 7,
          },
        },
        [`& .${chartsGridClasses.root} .${chartsGridClasses.line}`]: {
          stroke: theme.palette.neutral[200],
        },
        [`& .${axisClasses.root}`]: {
          [`& .${axisClasses.line}`]: {
            stroke: theme.palette.neutral[400],
          },
          [`& .${axisClasses.tick}`]: {
            stroke: theme.palette.neutral[400],
          },
        },
        [`& .${markElementClasses.root}`]: {
          scale: '0.9',

          [`&.${markElementClasses.highlighted}`]: {
            fill: `${theme.palette.primary[600]}20`,
            stroke: 'transparent',
            scale: '3',
          },
        },
        [`& .${lineHighlightElementClasses.root}`]: {
          fill: theme.palette.primary[600],
          stroke: theme.palette.common.white,
          strokeWidth: 2,
        },
        ...sx,
      }}
      ref={ref}
      series={series}
      width={width}
      height={height}
      leftAxis={leftAxis}
      rightAxis={rightAxis}
      topAxis={topAxis}
      bottomAxis={bottomAxis}
      grid={grid}
      {...other}
    >
      {children}
    </XBarChart>
  );
};

export default forwardRef<HTMLDivElement, BarChartProps>(BarChart);

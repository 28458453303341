import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  // Typography,
} from '@wooriga/design-system';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ResultGrid from 'components/pages/meet-management/history/detail/common/ResultGrid';
import useFeedback from 'hooks/useFeedback';
import { useElectronicVoteResultQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { MEETS_AGENDAS_ElECTRONIC_TABLE_COLUMNS } from 'lim/generalMeetingHistoryDetail/fixtures';

interface ElectronicResultModalProps {
  voterCount: number;
  registeredCount: number;
  open: boolean;

  onClose: (open: false) => void;
  onSubmit: () => void;
}

const ElectronicResultModal = ({
  voterCount,
  registeredCount,
  open,

  onClose,
  onSubmit,
}: ElectronicResultModalProps) => {
  const { meetSeq } = useParams();
  const { confirmDialog } = useFeedback();
  const meetId = Number(meetSeq);

  const { data } = useElectronicVoteResultQuery(meetId);

  const handleClose = () => {
    confirmDialog('개표 결과 입력을 취소하시겠습니까?', {
      message: '입력한 내용은 저장되지 않습니다.',
      onConfirm: () => {
        onClose(false);
      },
    });
  };

  const rows = useMemo(() => {
    return (
      data?.data?.flatMap((voteResult) => {
        const { agendaSeq, order, name, selectCount, description, candidates } =
          voteResult;

        const agenda = name + description;

        return candidates?.map((candidate) => {
          const { no, name, isPassed, electronicResult } = candidate;
          const { yesCount, noCount, totalCount, abstentionCount } =
            electronicResult;

          return {
            id: `${agendaSeq}-${no}-electronicResult`,
            // methodIndex: 0,
            order: `제 ${order}호`,
            agenda,
            method: '전자투표',
            no: `후보 ${no}`,
            candidate: name,
            yesCount,
            noCount,
            totalCount,
            abstentionCount,
            voteRate: selectCount ? (yesCount / selectCount) * 100 : 0,
            isPassed,
          };
        });
      }) || []
    );
  }, [data?.data]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog>
        <DialogTitle>전자투표 결과</DialogTitle>
        <DialogContent>
          <Stack gap={3} width={1000}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>총선거인: {voterCount}</Typography>

              <Stack alignSelf="end">
                <Typography level="body-md">
                  전자투표 수: {registeredCount}
                </Typography>
              </Stack>
            </Stack>

            <Stack>
              <ResultGrid
                columns={MEETS_AGENDAS_ElECTRONIC_TABLE_COLUMNS}
                rows={rows}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button fullWidth type="submit" onClick={() => onSubmit()}>
            다음
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="neutral"
            onClick={handleClose}
          >
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ElectronicResultModal;

// 임시적으로 Hook 생성, 추후 useLayout로 관리 필요

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from 'stores';
import { OpenChargeModalProps } from 'stores/charge';

const useChargeModal = () => {
  const queryClient = useQueryClient();
  const { chargeModal, openChargeModal, openTaxBillModal, closeChargeModal } =
    useBoundStore(
      useShallow(
        ({
          isChargeModalOpen,
          chargeModal,
          openChargeModal,
          openTaxBillModal,
          closeChargeModal,
        }) => ({
          isChargeModalOpen,
          chargeModal,
          openChargeModal,
          openTaxBillModal,
          closeChargeModal,
        }),
      ),
    );

  const handleOpenChargeModal = useCallback(
    ({ unionSeq, unionName, onSubmit }: OpenChargeModalProps) => {
      openChargeModal({
        unionSeq,
        unionName,
        onSubmit: ({ seq, isTaxBillApplied }) => {
          onSubmit?.({ seq, isTaxBillApplied });

          if (isTaxBillApplied) {
            openTaxBillModal({ unionSeq, depositChargeSeq: seq });
          }

          queryClient.invalidateQueries({
            queryKey: [`/unions/${unionSeq}/service-info`],
          });
        },
      });
    },
    [queryClient, openChargeModal, openTaxBillModal],
  );

  return {
    chargeModal,
    openChargeModal: handleOpenChargeModal,
    openTaxBillModal,
    closeChargeModal,
  };
};

export default useChargeModal;

import JoyDivider from '@mui/joy/Divider';
import type { DividerProps as JoyDividerProps } from '@mui/joy/Divider';
import { ForwardedRef, forwardRef } from 'react';

export type DividerProps = JoyDividerProps;

const Divider = (props: DividerProps, ref: ForwardedRef<HTMLHRElement>) => {
  return <JoyDivider {...props} ref={ref} />;
};

export default forwardRef<HTMLHRElement, DividerProps>(Divider);

import JoyStepButton from '@mui/joy/StepButton';
import type { StepButtonTypeMap as JoyStepButtonTypeMap } from '@mui/joy/StepButton';
import type { OverrideProps, OverridableComponent } from '@mui/types';
import {
  type ElementType,
  forwardRef,
  type ForwardRefExoticComponent,
} from 'react';

export type StepButtonProps<
  D extends ElementType = JoyStepButtonTypeMap['defaultComponent'],
  P = { component?: ElementType },
> = OverrideProps<JoyStepButtonTypeMap<P, D>, D>;

interface StepButtonComponent<
  D extends ElementType = JoyStepButtonTypeMap['defaultComponent'],
> extends ForwardRefExoticComponent<StepButtonProps<D>> {
  propTypes?: never;
}

const StepButton = forwardRef((props, ref) => {
  return <JoyStepButton {...props} ref={ref} />;
}) as StepButtonComponent;

StepButton.displayName = 'StepButton';

export default StepButton as OverridableComponent<JoyStepButtonTypeMap>;

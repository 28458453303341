import CheckIcon from '@mui/icons-material/Check';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import Option, { optionClasses } from '@mui/joy/Option';
import type { OptionProps as JoyOptionProps } from '@mui/joy/Option';
import { ForwardedRef, forwardRef } from 'react';

import ListItemDecorator from 'design-system/components/dataDisplay/list/ListItemDecorator';

export type CheckOptionProps = JoyOptionProps;

const CheckOption = (
  { children, sx, ...rest }: CheckOptionProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  return (
    <Option
      {...rest}
      ref={ref}
      sx={{
        [`&:not(.${optionClasses.selected}) .${listItemDecoratorClasses.root}`]:
          {
            opacity: 0,
          },
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
    >
      <ListItemDecorator>
        <CheckIcon />
      </ListItemDecorator>

      {children}
    </Option>
  );
};

export default forwardRef<HTMLLIElement, CheckOptionProps>(CheckOption);

import { Typography } from '@wooriga/design-system';

const EmptyText = () => {
  return (
    <Typography alignSelf="center" alignContent="center" height="100%">
      표시할 데이터가 없습니다.
    </Typography>
  );
};

export default EmptyText;

import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/joy';
import { Callout, PieCenterLabel, Stack } from '@wooriga/design-system';
import { useMemo } from 'react';

import { MeetMethods, MeetStatus } from 'apis/types/meet';
import JointBookStatusContent from 'components/pages/meet-management/history/detail/RealTimeAttendance/JointBookStatusContent';
import PieChartWrapper from 'components/PieChartWrapper';
import { percentage } from 'components/PieChartWrapper/PercentLabel';
import {
  MeetJointBookResultQuery,
  MeetsDetailQuery,
  MeetsRealtimeStatusCountQuery,
} from 'lim/generalMeetingHistoryDetail/apis';

import MeetStatusContent from './MeetStatusContent';

export interface RealTimeAttendanceProps {
  meetMethod: MeetMethods;
  meetStatus: MeetStatus;
  meetsRealtimeStatusCountQuery?: MeetsRealtimeStatusCountQuery;
  meetsDetailQuery: MeetsDetailQuery;
  meetsJointBookQuery?: MeetJointBookResultQuery;
  meetSeq: number;
}

const RealTimeAttendance = ({
  meetMethod,
  meetStatus,
  meetsDetailQuery,
  meetsRealtimeStatusCountQuery, // meetsJointBookQuery,
  meetSeq,
}: RealTimeAttendanceProps) => {
  const theme = useTheme();

  const countData = meetsRealtimeStatusCountQuery?.data;
  const totalCount = useMemo(
    () => meetsDetailQuery.data?.data.participantCount || 0,
    [meetsDetailQuery.data],
  );

  const onsiteRateSeries = useMemo(() => {
    if (!countData) return [];
    const { mySelf, agent } = countData.data.onsiteAttendanceType;

    const voted = mySelf + agent;
    const unvoted = totalCount - voted;

    if (!voted) return [{ data: [] }];

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'voted',
            label: `참석완료 ${voted}`,
            color: theme.palette.primary[400],
            value: voted,
          },
          {
            id: 'unvoted',
            color: theme.palette.primary[100],
            value: unvoted,
          },
        ],
      },
    ];
  }, [countData?.data]);

  const onsiteAttendanceTypeSeries = useMemo(() => {
    if (!countData) return [];

    const { mySelf, agent } = countData.data.onsiteAttendanceType;

    const total = mySelf + agent;
    if (!total) return [{ data: [] }];

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'mySelf',
            label: `본인 ${mySelf}`,
            color: theme.palette.primary[400],

            value: mySelf,
          },

          {
            id: 'agent',
            label: `대리인 ${agent}`,
            color: theme.palette.primary[300],
            value: agent,
          },
        ],
      },
    ];
  }, [countData?.data]);

  const attendanceTypeSeries = useMemo(() => {
    if (!countData) return [{ data: [] }];
    const { onsite, writtenSubmission, electronic } =
      countData.data.attendanceType;

    const total = onsite + writtenSubmission + electronic;
    if (!total) return [{ data: [] }];

    const series = [
      {
        data: [
          {
            id: 'onsite',
            label: `직접 참석 ${onsite}`,
            color: theme.palette.primary[400],

            value: onsite,
          },

          {
            id: 'writtenSubmission',
            label: `서면 참석 ${writtenSubmission}`,
            color: theme.palette.primary[300],

            value: writtenSubmission,
          },
        ],
      },
    ];

    if (meetMethod === 'ONSITE_ELECTRONIC') {
      series[0].data.push({
        id: 'electronic',
        label: `전자 참석 ${electronic}`,
        color: theme.palette.primary[200],
        value: electronic,
      });
    }

    return series;
  }, [countData?.data]);

  const onsiteRateLabel = useMemo(() => {
    if (!countData) return { percentage: '', rate: '' };

    const { mySelf, agent } = countData.data.onsiteAttendanceType;

    const voted = mySelf + agent;

    return {
      percentage: `${percentage(voted, totalCount)}%`,
      rate: `${voted}명 / ${totalCount}명`,
    };
  }, [countData?.data]);

  const onsiteAttendanceTypeLabel = useMemo(() => {
    if (!countData) return '';

    const { mySelf, agent } = countData.data.onsiteAttendanceType;

    const total = mySelf + agent;

    return `${total}명`;
  }, [countData?.data]);

  return (
    <Stack gap={1.75}>
      <Callout variant="outlined" startDecorator={<InfoIcon />}>
        <MeetStatusContent meetStatus={meetStatus} />
        <JointBookStatusContent meetStatus={meetStatus} meetSeq={meetSeq} />
      </Callout>

      {/* ---------------------- Pie ---------------------- */}

      <Stack flexWrap="wrap" flexDirection="row" gap={1.75}>
        <PieChartWrapper series={onsiteRateSeries} title="총회 참석률">
          <PieChartWrapper.PercentLabel label={onsiteRateLabel.percentage} />
          <PieChartWrapper.RateLabel label={onsiteRateLabel.rate} />
        </PieChartWrapper>

        <PieChartWrapper series={attendanceTypeSeries} title="총회 출석 방법" />

        <PieChartWrapper
          series={onsiteAttendanceTypeSeries}
          title="총회 참석 유형"
        >
          <PieCenterLabel>{onsiteAttendanceTypeLabel}</PieCenterLabel>
        </PieChartWrapper>
      </Stack>
    </Stack>
  );
};

export default RealTimeAttendance;

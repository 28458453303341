import JoyModal from '@mui/joy/Modal';
import type { ModalProps as JoyModalProps } from '@mui/joy/Modal';
import { ForwardedRef, forwardRef } from 'react';

export type ModalProps = JoyModalProps;

const Modal = (props: ModalProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyModal {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, ModalProps>(Modal);

import { yupResolver } from '@hookform/resolvers/yup';
import { AspectRatio, Button, Stack, TextField } from '@wooriga/design-system';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useLoginMutation } from 'apis/auth/apis';
import axiosInstance from 'apis/axiosInstance';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { setCookie } from 'utils/cookie';

const schema = yup.object().shape({
  id: yup.string().required('아이디를 입력해주세요.'),
  password: yup.string().required('비밀번호를 입력해주세요.'),
});

interface SignInFormValues {
  id: string;
  password: string;
}

const LoginPage = () => {
  const { mutate } = useLoginMutation();
  const navigate = useNavigate();
  const { alertDialog } = useFeedback();

  const { control, handleSubmit } = useForm<SignInFormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const enhancedSubmit = handleSubmit((data) => {
    mutate(data, {
      onSuccess: (response) => {
        const { accessToken, member } = response.data || {};
        const { memberSeq } = member || {};

        if (accessToken && memberSeq) {
          setCookie('accessToken', accessToken, {
            secure: true,
            sameSite: 'strict',
          });
          setCookie('memberSeq', memberSeq.toString(), {
            secure: true,
            sameSite: 'strict',
          });
          axiosInstance.defaults.headers[
            'authorization'
          ] = `Bearer ${accessToken}`;

          navigate('/', { replace: true });
        }
      },
      onError: ({ response }) => {
        alertDialog(response?.data.message);
      },
    });
  });

  return (
    <>
      <Stack
        flex="1 1 100%"
        width="100%"
        maxWidth="sm"
        justifyContent="center"
        gap={8.75}
      >
        <Stack overflow="hidden" gap={3}>
          <AspectRatio variant="plain" objectFit="contain" maxHeight={48}>
            <img src="/images/logos/logo_main_001.svg" alt="우리가 메인 로고" />
          </AspectRatio>
          <AspectRatio variant="plain" objectFit="contain" maxHeight={18}>
            <img
              src="/images/login_service_description.svg"
              alt="성공하는 도시정비사업을 위한 조합 총회 운영·관리 시스템 OMS"
            />
          </AspectRatio>
        </Stack>

        <Stack component="form" gap={12.5} onSubmit={enhancedSubmit}>
          <Stack gap={2}>
            <Controller
              control={control}
              name="id"
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  autoComplete="username"
                  label="아이디"
                  placeholder="아이디 입력"
                  size="lg"
                  error={invalid}
                  helperText={error?.message || ' '}
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  type="password"
                  autoComplete="password"
                  label="비밀번호"
                  placeholder="비밀번호 입력"
                  size="lg"
                  error={invalid}
                  helperText={error?.message || ' '}
                  {...field}
                />
              )}
            />
          </Stack>

          <Button type="submit" size="lg">
            로그인
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

const loginPage: CustomRouteObject = {
  path: '/login',
  element: <LoginPage />,
  handle: {
    getTitle: () => '로그인',
  },
};

export default loginPage;

import { ReactElement } from 'react';

import { DataProps } from 'components/DescriptionsRenderer/type';

export const generateDescriptionsJSON = (
  labels: (string | ReactElement)[],
  contents: (string | number | ReactElement | undefined)[],
  spans?: number[],
): DataProps[] =>
  labels.map((label, i) => ({
    label,
    content: contents[i],
    span: spans?.[i] || 1,
  }));

import { styled } from '@mui/joy';
import { ForwardedRef, forwardRef, ForwardRefExoticComponent } from 'react';

import Box, { BoxProps } from 'design-system/components/layout/Box';

export type NavigationBarHeaderSlot = 'root';

export const navigationBarHeaderClasses = {
  root: 'JoyNavigationBarHeader-root',
};

export interface NavigationBarHeaderProps extends BoxProps {}

export type NavigationBarHeaderOwnerState = NavigationBarHeaderProps;

interface NavigationBarFooterComponent
  extends ForwardRefExoticComponent<NavigationBarHeaderProps> {
  propTypes?: never;
  muiName?: string;
}

const NavigationBarHeader = forwardRef(
  (props: NavigationBarHeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...other } = props;

    return (
      <JoyNavigationBarHeaderRoot
        {...other}
        className={navigationBarHeaderClasses.root}
        ref={ref}
      >
        {children}
      </JoyNavigationBarHeaderRoot>
    );
  },
) as NavigationBarFooterComponent;

const JoyNavigationBarHeaderRoot = styled(Box, {
  name: 'JoyNavigationBarHeader',
  slot: 'root',
})`
  display: flex;
  flex: 0 0 auto;
  padding: 16px 48px 16px 12px;
`;

NavigationBarHeader.displayName = 'NavigationBarHeader';
NavigationBarHeader.muiName = 'NavigationBarHeader';

export default NavigationBarHeader;

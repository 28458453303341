import JoyAspectRatio from '@mui/joy/AspectRatio';
import type { AspectRatioProps as JoyAspectRatioProps } from '@mui/joy/AspectRatio';
import { ForwardedRef, forwardRef } from 'react';

export type AspectRatioProps = JoyAspectRatioProps;

const AspectRatio = (
  props: AspectRatioProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyAspectRatio {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, AspectRatioProps>(AspectRatio);

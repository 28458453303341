/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTheme } from '@mui/joy';
import {
  AlertDialog,
  Modal,
  PieCenterLabel,
  Stack,
} from '@wooriga/design-system';
import { useEffect, useMemo, useState } from 'react';

import { MeetMethods, MeetStatus } from 'apis/types/meet';
import ElectronicDateInfo from 'components/pages/meet-management/history/detail/common/DateInfos/ElectronicDateInfo';
import OnsiteDateInfo from 'components/pages/meet-management/history/detail/common/DateInfos/OnsiteDateInfo';
import { DataGridValues } from 'components/pages/meet-management/history/detail/common/ResultGrid';
import { generateRowData } from 'components/pages/meet-management/history/detail/common/ResultGrid/utils';
import ElectronicResultModal from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ElectronicResultModal';
import MeetCompleteModal from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/MeetCompleteModal';
import ResultContent, {
  ResultContentProps,
} from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ResultContent';
import ReVoteModal from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ReVoteModal';
import TallyResultEnterModal from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/TallyResultEnterModal';
import { combineResults } from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/utils';
import VoteResultEnterModal from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/VoteResultEnterModal';
import { MeetMethodProps } from 'components/pages/meet-management/history/detail/type';
import { MeetMethodResultKeys } from 'components/pages/meet-management/history/detail/utils';
import PieChartWrapper from 'components/PieChartWrapper';
import { percentage } from 'components/PieChartWrapper/PercentLabel';
import useFeedback from 'hooks/useFeedback';
import {
  CompleteMeetBody,
  ElectronicVoteSendStatusCountQuery,
  MeetCandidateInfo,
  MeetsAgendasQuery,
  MeetsDetailQuery,
  MeetsOnsiteVoteCount,
  MeetsPasswordVerifyMutateFunction,
  MeetsRealtimeStatusCountQuery,
  MeetsVoteResultQuery,
  MeetsVoteResultSummaryQuery,
  VoteResultBody,
} from 'lim/generalMeetingHistoryDetail/apis';
import {
  MeetsVoteResultTableColumnsProps,
  ResultState,
} from 'lim/generalMeetingHistoryDetail/fixtures';

import ConfirmModal from './ConfirmModal';

type MeetResultKeys = MeetMethodResultKeys | 'total';

interface Onsite {
  meetsRealtimeStatusCountQuery: MeetsRealtimeStatusCountQuery;
  meetsVoteResultSummaryQuery: MeetsVoteResultSummaryQuery;
  meetsAgendasQuery: MeetsAgendasQuery;
  meetsOnsiteVoteCount: MeetsOnsiteVoteCount;

  onEnterResult?: (formData: VoteResultBody) => void;
  onMeetReset?: () => void;
  passwordVerifyMutate?: MeetsPasswordVerifyMutateFunction;
}

interface Electronic {
  electronicVoteSendStatusCountQuery: ElectronicVoteSendStatusCountQuery;
}

export type TallyResultProps<MeetMethod extends MeetMethods> = MeetMethodProps<
  MeetMethod,
  Electronic,
  Onsite
> & {
  meetMethod: MeetMethod;
  // ------------------------ 공통 ------------------------
  meetsVoteResultQuery: MeetsVoteResultQuery;
  meetsDetailQuery: MeetsDetailQuery;
  onMeetComplete: (formData: CompleteMeetBody) => void;
};

const TallyResultTab = <MeetMethod extends MeetMethods>({
  meetMethod,
  // ------------------------ 공통 ------------------------
  meetsAgendasQuery,
  meetsVoteResultSummaryQuery,
  meetsVoteResultQuery,
  meetsDetailQuery,
  // ------------------------ 전자 ------------------------
  electronicVoteSendStatusCountQuery,
  // ------------------------ 서면 ------------------------
  meetsRealtimeStatusCountQuery,
  meetsOnsiteVoteCount,

  // event
  onMeetComplete,
  onMeetReset,
  onEnterResult,
  passwordVerifyMutate,
}: TallyResultProps<MeetMethod>) => {
  const isElectronic = meetMethod === 'ELECTRONIC';
  const isOnsite =
    meetMethod === 'ONSITE' && meetsVoteResultSummaryQuery && meetsAgendasQuery;
  const isOverall =
    meetMethod === 'ONSITE_ELECTRONIC' &&
    meetsVoteResultSummaryQuery &&
    meetsAgendasQuery;

  const theme = useTheme();

  const { data } = meetsVoteResultQuery;
  const { snackbar } = useFeedback();

  const realtimeStatusCountData = meetsRealtimeStatusCountQuery?.data;
  const summaryData = meetsVoteResultSummaryQuery?.data;

  const [selectState, setSelectState] = useState<ResultState>();

  const [formData, setFormData] = useState<VoteResultBody>();

  const [alertDialog, setAlertDialog] = useState({
    show: false,
    message: '',
    detail: '',
  });

  const [showMeetCompleteModal, setShowMeetCompleteModal] = useState(false);
  const [showReVoteModal, setShowReVoteModal] = useState(false);
  const [showTallyResultEnterModal, setShowTallyResultEnterModal] =
    useState(false);
  const [showOnsiteResultEnterModal, setShowOnsiteResultEnterModal] =
    useState(false);
  const [
    showWrittenSubmitResultEnterModal,
    setShowWrittenSubmitResultEnterModal,
  ] = useState(false);
  const [showElectronicResultModal, setShowElectronicResultModal] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [writtenSubmitDataGridValue, setWrittenSubmitDataGridValue] =
    useState<DataGridValues>({});

  const totalCount = useMemo(
    () => meetsDetailQuery.data?.data.participantCount || 0,
    [meetsDetailQuery.data],
  );

  const electronicSeries = useMemo(() => {
    if (!electronicVoteSendStatusCountQuery?.data) return [{ data: [] }];
    const { data } = electronicVoteSendStatusCountQuery;
    const { voted } = data.data;

    if (!voted) return [{ data: [] }];
    const unvoted = totalCount - voted;

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'voted',
            label: `투표 완료 ${voted}`,
            //@ts-ignore
            color: theme.palette.blue[400],
            value: voted,
          },
          {
            id: 'unvoted',
            color: theme.palette.primary[100],
            value: unvoted,
          },
        ],
      },
    ];
  }, [electronicVoteSendStatusCountQuery?.data]);

  const onsiteRateSeries = useMemo(() => {
    if (!realtimeStatusCountData) return [{ data: [] }];

    const { mySelf, agent } = realtimeStatusCountData.data.onsiteAttendanceType;

    const voted = mySelf + agent;
    const unvoted = totalCount - voted;

    if (!voted) return [{ data: [] }];

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'voted',
            label: `참석완료 ${voted}`,
            color: theme.palette.primary[400],
            value: voted,
          },
          {
            id: 'unvoted',
            value: unvoted,
            color: theme.palette.primary[100],
          },
        ],
      },
    ];
  }, [realtimeStatusCountData?.data]);

  const voteRateSeries = useMemo(() => {
    if (!realtimeStatusCountData) return [{ data: [] }];

    const { onsite, writtenSubmission, electronic } =
      realtimeStatusCountData.data.voteType;

    const total = onsite + writtenSubmission + electronic;
    const unvoted = totalCount - total;

    if (!total) return [{ data: [] }];

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'electronic',
            label: `전자 투표 ${electronic}`,
            value: electronic,
            //@ts-ignore
            color: theme.palette.blue[400],
          },
          {
            id: 'writtenSubmission',
            label: `서면 제출 ${writtenSubmission}`,
            value: writtenSubmission,
            color: theme.palette.success[400],
          },
          {
            id: 'onsite',
            label: `현장 투표 ${onsite}`,
            value: onsite,
            color: theme.palette.primary[300],
          },
          {
            id: 'unvoted',
            value: unvoted,
            color: theme.palette.primary[100],
          },
        ],
      },
    ];
  }, [realtimeStatusCountData?.data]);

  const onsiteAttendanceTypeSeries = useMemo(() => {
    if (!realtimeStatusCountData) return [{ data: [] }];

    const { mySelf, agent } = realtimeStatusCountData.data.onsiteAttendanceType;

    const total = mySelf + agent;
    if (!total) return [{ data: [] }];

    return [
      {
        data: [
          {
            id: 'mySelf',
            label: `본인 ${mySelf}`,
            color: theme.palette.primary[400],
            value: mySelf,
          },

          {
            id: 'agent',
            label: `대리인 ${agent}`,
            color: theme.palette.primary[300],
            value: agent,
          },
        ],
      },
    ];
  }, [realtimeStatusCountData?.data]);

  const attendanceTypeSeries = useMemo(() => {
    if (!realtimeStatusCountData) return [{ data: [] }];

    const { onsite, writtenSubmission, electronic } =
      realtimeStatusCountData.data.attendanceType;

    const total = onsite + writtenSubmission + electronic;
    if (!total) return [{ data: [] }];

    const series = [
      {
        data: [
          {
            id: 'onsite',
            label: `직접 참석 ${onsite}`,
            color: theme.palette.primary[400],
            value: onsite,
          },

          {
            id: 'writtenSubmission',
            label: `서면 참석 ${writtenSubmission}`,
            color: theme.palette.primary[300],

            value: writtenSubmission,
          },
        ],
      },
    ];

    if (meetMethod === 'ONSITE_ELECTRONIC') {
      series[0].data.push({
        id: 'electronic',
        label: `전자 참석 ${electronic}`,
        color: theme.palette.primary[200],
        value: electronic,
      });
    }

    return series;
  }, [realtimeStatusCountData?.data]);

  const onsiteRateLabel = useMemo(() => {
    if (!realtimeStatusCountData) return { percentage: '', rate: '' };

    const { mySelf, agent } = realtimeStatusCountData.data.onsiteAttendanceType;

    const voted = mySelf + agent;

    return {
      percentage: `${percentage(voted, totalCount)}%`,
      rate: `${voted}명 / ${totalCount}명`,
    };
  }, [realtimeStatusCountData?.data]);

  const onsiteAttendanceTypeLabel = useMemo(() => {
    if (!realtimeStatusCountData) return '';

    const { mySelf, agent } = realtimeStatusCountData.data.onsiteAttendanceType;

    const total = mySelf + agent;

    return `${total}명`;
  }, [realtimeStatusCountData?.data]);

  const electronicVotingRateLabel = useMemo(() => {
    if (!electronicVoteSendStatusCountQuery?.data)
      return { percentage: '', rate: '' };
    const countData = electronicVoteSendStatusCountQuery.data.data;
    const { voted } = countData;

    return {
      percentage: `${percentage(voted, totalCount)}%`,
      rate: `${voted}명 / ${totalCount}명`,
    };
  }, [electronicVoteSendStatusCountQuery?.data?.data]);

  const voteRateLabel = useMemo(() => {
    if (!realtimeStatusCountData) return { percentage: '', rate: '' };

    const { onsite, writtenSubmission, electronic } =
      realtimeStatusCountData.data.voteType;

    const voted = onsite + writtenSubmission + electronic;

    return {
      percentage: `${percentage(voted, totalCount)}%`,
      rate: `${voted}명 / ${totalCount}명`,
    };
  }, [realtimeStatusCountData?.data]);

  // console.log(summaryData?.data);

  const rows: MeetsVoteResultTableColumnsProps[] = useMemo(() => {
    const meetMethods: MeetResultKeys[] = isElectronic
      ? ['total', 'electronicResult']
      : isOnsite
      ? ['total', 'onsiteResult', 'writtenSubmissionResult']
      : [
          'total',
          'electronicResult',
          'onsiteResult',
          'writtenSubmissionResult',
        ];

    return (
      meetMethods?.flatMap((meet) => {
        return (
          data?.data?.flatMap((voteResult) => {
            return voteResult.candidates.map((candidate) => {
              return generateRowData({
                meet,
                meetMethod,
                voteResult,
                candidate,
                totalVoteCount: summaryData?.data?.totalVoteCount || 0,
              });
            });
          }) || []
        );
      }) || []
    );
  }, [data]);

  const handleOnResultSelect: ResultContentProps['onRowResultSelect'] = (
    id,
    value,
  ) => {
    const newSelectState = selectState?.map((row) => {
      if (row.id === id) {
        return {
          id,
          value,
        };
      }
      return row;
    });

    setSelectState(newSelectState);
  };

  const handleShowMeetCompleteModal = (meetStatus: MeetStatus) => {
    const shouldEnterResult =
      meetStatus === 'PROCESS' && (isOnsite || isOverall);
    if (shouldEnterResult) {
      return setShowTallyResultEnterModal(true);
    }

    const isNotComplete = selectState?.some((row) => row.value === null);

    if (isNotComplete)
      return setAlertDialog({
        show: true,
        message: '의결 결과 선택해 주세요.',
        detail: '의결 결과 선택해 주세요.',
      });

    setShowMeetCompleteModal(true);
  };

  const handleMeetComplete = (password: string) => {
    const approvalList: MeetCandidateInfo[] = [];
    const rejectionList: MeetCandidateInfo[] = [];

    selectState?.forEach((state) => {
      const { id, value } = state;
      const [agendaSeq, no] = id.split('-');

      const isApproved = value;
      const listInfo = {
        agendaSeq: Number(agendaSeq),
        candidateNo: Number(no),
      };

      if (isApproved) {
        approvalList.push(listInfo);
      } else {
        rejectionList.push(listInfo);
      }
    });

    passwordVerifyMutate?.(
      { password },
      {
        onSuccess: () => {
          onMeetComplete({
            approvalList,
            rejectionList,
          });
        },
        onError: () => {
          snackbar('비밀번호가 틀렸습니다.', { color: 'danger' });
        },
      },
    );
  };

  const handleEnterTallyResult = (password: string) => {
    passwordVerifyMutate?.(
      { password },
      {
        onSuccess: () => {
          setShowTallyResultEnterModal(false);
          if (isOverall) {
            return setShowElectronicResultModal(true);
          }
          setShowWrittenSubmitResultEnterModal(true);
        },
        onError: () => {
          snackbar('비밀번호가 틀렸습니다.', { color: 'danger' });
        },
      },
    );

    // 패스워드가 틀릴경운 error message
  };

  const handleReVoteResult = (password: string) => {
    passwordVerifyMutate?.(
      { password },
      {
        onSuccess: () => {
          onMeetReset?.();
        },
        onError: () => {
          snackbar('비밀번호가 틀렸습니다.', { color: 'danger' });
        },
      },
    );

    // 패스워드가 틀릴경운 error message
  };

  const handleElectronicResultSubmit = () => {
    setShowOnsiteResultEnterModal(true);
  };

  const handleWrittenSubmitResultEnterSubmit = (
    dataGridValue: DataGridValues,
  ) => {
    setShowOnsiteResultEnterModal(true);

    setWrittenSubmitDataGridValue(dataGridValue);
  };

  const handleOnsiteResultEnterSubmit = (dataGridValue: DataGridValues) => {
    if (summaryData?.data) {
      const decision = {
        onsiteCount: summaryData.data.onsiteVoteCount,
        writtenSubmissionCount: summaryData.data.writtenSubmissionCount,
      };

      const combinedResult = combineResults(
        writtenSubmitDataGridValue,
        dataGridValue,
      );

      const agendas = combinedResult.map((result) => {
        const find = data?.data?.find(
          (data) => data.agendaSeq === result.agendaSeq,
        );
        return {
          ...result,
          order: find?.order || 1,
        };
      });

      const formData = {
        decision,
        agendas,
      };

      setFormData(formData);
      setShowConfirmModal(true);
    }
  };

  const handleStatusReset = () => {
    setShowReVoteModal(true);
  };

  const handleOnConfirm = () => {
    setShowTallyResultEnterModal(false);
    setShowOnsiteResultEnterModal(false);
    setShowWrittenSubmitResultEnterModal(false);

    setShowConfirmModal(false);
    if (formData) onEnterResult?.(formData);
  };

  useEffect(() => {
    const selectState = rows
      .filter((row) => row.id.includes('total'))
      .map((row) => ({
        id: row.id,
        value: row.isPassed || null,
      }));

    setSelectState(selectState);
  }, [rows]);

  const commonStyle = {
    background: theme.palette.background.surface,
    padding: '12px 20px',
    boxSizing: 'borderBox',
  };

  return (
    <Stack gap={1.75}>
      <Stack gap={1.75} sx={commonStyle}>
        {(isElectronic || isOverall) && (
          <ElectronicDateInfo meetsDetailQuery={meetsDetailQuery} />
        )}

        {isOnsite && <OnsiteDateInfo meetsDetailQuery={meetsDetailQuery} />}
      </Stack>

      {/* ---------------------- Pie ---------------------- */}

      <Stack flexWrap="wrap" flexDirection="row" gap={1.75}>
        {isOnsite && (
          <>
            <PieChartWrapper title="총회 참석률" series={onsiteRateSeries}>
              <PieChartWrapper.PercentLabel
                label={onsiteRateLabel.percentage}
              />
              <PieChartWrapper.RateLabel label={onsiteRateLabel.rate} />
            </PieChartWrapper>

            <PieChartWrapper
              title="총회 출석 방법"
              series={attendanceTypeSeries}
            />

            <PieChartWrapper
              title="총회 참석 유형"
              series={onsiteAttendanceTypeSeries}
            >
              <PieCenterLabel>{onsiteAttendanceTypeLabel}</PieCenterLabel>
            </PieChartWrapper>
          </>
        )}

        {(isElectronic || isOverall) && (
          <PieChartWrapper title="전자투표율" series={electronicSeries}>
            <PieChartWrapper.PercentLabel
              label={electronicVotingRateLabel.percentage}
            />
            <PieChartWrapper.RateLabel label={electronicVotingRateLabel.rate} />
          </PieChartWrapper>
        )}

        {isOverall && (
          <PieChartWrapper title="투표 참여율" series={voteRateSeries}>
            <PieChartWrapper.PercentLabel label={voteRateLabel.percentage} />
            <PieChartWrapper.RateLabel label={voteRateLabel.rate} />
          </PieChartWrapper>
        )}
      </Stack>

      <ResultContent
        commonStyle={commonStyle}
        meetMethod={meetMethod}
        meetsDetailQuery={meetsDetailQuery}
        rows={rows}
        resultState={selectState}
        onSubmit={handleShowMeetCompleteModal}
        onReset={handleStatusReset}
        onRowResultSelect={handleOnResultSelect}
      />

      <Modal open={alertDialog.show}>
        <AlertDialog
          message={alertDialog.message}
          detail={alertDialog.detail}
          onSubmit={() => setAlertDialog({ ...alertDialog, show: false })}
        />
      </Modal>

      <ReVoteModal
        open={showReVoteModal}
        onClose={setShowReVoteModal}
        onSubmit={handleReVoteResult}
      />

      <MeetCompleteModal
        open={showMeetCompleteModal}
        onClose={setShowMeetCompleteModal}
        onSubmit={handleMeetComplete}
      />

      <TallyResultEnterModal
        open={showTallyResultEnterModal}
        onClose={setShowTallyResultEnterModal}
        onSubmit={handleEnterTallyResult}
      />

      {isOverall && (
        <ElectronicResultModal
          voterCount={totalCount}
          registeredCount={summaryData?.data.electronicVoteCount || 0}
          open={showElectronicResultModal}
          onClose={setShowElectronicResultModal}
          onSubmit={handleElectronicResultSubmit}
        />
      )}
      {(isOnsite || isOverall) && (
        <>
          <VoteResultEnterModal
            type="writtenSubmissionResult"
            meetsAgendasQuery={meetsAgendasQuery}
            voterCount={totalCount}
            registeredCount={
              meetsOnsiteVoteCount?.data?.data.writtenSubmission || 0
            }
            open={showWrittenSubmitResultEnterModal}
            onClose={setShowWrittenSubmitResultEnterModal}
            onSubmit={handleWrittenSubmitResultEnterSubmit}
          />

          <VoteResultEnterModal
            type="onsiteResult"
            meetsAgendasQuery={meetsAgendasQuery}
            voterCount={totalCount}
            registeredCount={meetsOnsiteVoteCount?.data?.data.onsiteVote || 0}
            open={showOnsiteResultEnterModal}
            onClose={setShowOnsiteResultEnterModal}
            onSubmit={handleOnsiteResultEnterSubmit}
          />
        </>
      )}

      <ConfirmModal
        open={showConfirmModal}
        onClose={setShowConfirmModal}
        onConfirm={handleOnConfirm}
      />
    </Stack>
  );
};

export default TallyResultTab;

import JoyFormControl from '@mui/joy/FormControl';
import type { FormControlProps as JoyFormControlProps } from '@mui/joy/FormControl';
import { ForwardedRef, forwardRef } from 'react';

export type FormControlProps = JoyFormControlProps;

const FormControl = (
  props: FormControlProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyFormControl {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, FormControlProps>(FormControl);

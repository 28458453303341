import JoyMenuButton from '@mui/joy/MenuButton';
import type { MenuButtonTypeMap as JoyMenuButtonTypeMap } from '@mui/joy/MenuButton';
import type { OverrideProps, OverridableComponent } from '@mui/types';
import {
  type ElementType,
  forwardRef,
  type ForwardRefExoticComponent,
} from 'react';

export type MenuButtonProps<
  D extends ElementType = JoyMenuButtonTypeMap['defaultComponent'],
  P = { component?: ElementType },
> = OverrideProps<JoyMenuButtonTypeMap<P, D>, D>;

interface MenuButtonComponent<
  D extends ElementType = JoyMenuButtonTypeMap['defaultComponent'],
> extends ForwardRefExoticComponent<MenuButtonProps<D>> {
  propTypes?: never;
}

const MenuButton = forwardRef((props, ref) => {
  return <JoyMenuButton {...props} ref={ref} />;
}) as MenuButtonComponent;

MenuButton.displayName = 'MenuButton';

export default MenuButton as OverridableComponent<JoyMenuButtonTypeMap>;

import {
  Button,
  DialogContent,
  DialogTitle,
  Drawer,
  DrawerProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { CreateDocumentIssuanceBody } from 'apis/document-issuance/apis';
import {
  VIEW_ISSUANCE_SETTING_DEFAULT_VALUES,
  VIEW_ISSUANCE_SETTING_ITEMS,
} from 'apis/document-issuance/constants';
import ViewIssuanceSettingCard from 'components/pages/document-issuances/ViewIssuanceSettingCard';
import useFeedback from 'hooks/useFeedback';

export interface ViewIssuanceSettingFormValues
  extends Omit<ViewIssuanceSettingValues, 'requestDocumentCodes'> {
  requestDocumentCodes: { value: string }[];
}

export type ViewIssuanceSettingValues = Omit<
  CreateDocumentIssuanceBody,
  'totalRequestCount' | 'addressCount'
>;

export interface ViewIssuanceSettingDrawerProps
  extends Omit<DrawerProps, 'onClose' | 'children' | 'component' | 'onSubmit'> {
  onClose?: () => void;
  onSubmit?: (data: ViewIssuanceSettingValues) => void;
}

const ViewIssuanceSettingDrawer = (props: ViewIssuanceSettingDrawerProps) => {
  const { open, onClose, onSubmit, ...rest } = props;

  const { alertDialog } = useFeedback();
  const methods = useForm<ViewIssuanceSettingFormValues>({
    defaultValues: VIEW_ISSUANCE_SETTING_DEFAULT_VALUES,
  });

  const isCardSelected =
    (methods.watch('requestDocumentCodes') ?? []).length > 0;

  const handleClose: DrawerProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    onClose?.();
  };

  const handleSubmit = methods.handleSubmit((data) => {
    const changedBooleanValues = Object.entries(data)
      .filter(([key]) => /^has.*/.test(key))
      .reduce(
        (obj, [key, value]) => {
          return { ...obj, [key]: value === 'true' };
        },
        {} as Record<string, boolean>,
      );

    const matchedRequestDataModel: ViewIssuanceSettingValues = {
      ...data,
      ...changedBooleanValues,
      requestDocumentCodes: (data.requestDocumentCodes ?? []).map(
        ({ value }) => value,
      ),
    };

    const isGgSettingAvailable =
      !matchedRequestDataModel['requestDocumentCodes'].includes('GG') ||
      (matchedRequestDataModel['requestDocumentCodes'].includes('GG') &&
        (changedBooleanValues['hasGgNormal'] ||
          changedBooleanValues['hasGgPyoje'] ||
          changedBooleanValues['hasGgTotalPyoje']));

    if (!isGgSettingAvailable) {
      alertDialog(
        '건축물대장의 종류를 선택해주세요. (일반/전유부, 표제부, 총괄표제)',
        {
          message: '종류 선택',
        },
      );
      return;
    }

    onSubmit?.(matchedRequestDataModel);
    onClose?.();
  });

  useEffect(() => {
    if (!open) {
      methods.reset();
    }
  }, [open, methods]);

  return (
    <FormProvider {...methods}>
      <Drawer
        {...rest}
        component="form"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
      >
        <DialogTitle sx={{ px: 1 }}>
          <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
            자료별 발급/열람 조건 설정
          </Typography>
        </DialogTitle>

        {open && (
          <DialogContent sx={{ p: 2.5 }}>
            <Stack gap={1}>
              {VIEW_ISSUANCE_SETTING_ITEMS.map((props) => (
                <ViewIssuanceSettingCard
                  key={`card_${props.name}`}
                  {...props}
                />
              ))}
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              gap={1}
              sx={{ py: 2 }}
            >
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => onClose?.()}
              >
                취소
              </Button>
              <Button disabled={!isCardSelected} onClick={handleSubmit}>
                발급신청
              </Button>
            </Stack>
          </DialogContent>
        )}
      </Drawer>
    </FormProvider>
  );
};

export default ViewIssuanceSettingDrawer;

import styled from '@mui/joy/styles/styled';
import { ForwardedRef, forwardRef } from 'react';

import PaginationItem, {
  PaginationItemProps,
} from 'design-system/components/navigation/pagination/PaginationItem';
import usePagination, {
  UsePaginationProps,
} from 'design-system/components/navigation/pagination/usePagination';

export type PaginationProps = UsePaginationProps & {
  activeItemVariant?: PaginationItemProps['variant'];
  activeItemColor?: PaginationItemProps['color'];
  variant?: PaginationItemProps['variant'];
  size?: PaginationItemProps['size'];
  color?: PaginationItemProps['color'];
  orientation?: 'horizontal' | 'vertical';
};

const Pagination = forwardRef(
  (
    {
      activeItemVariant = 'soft',
      activeItemColor = 'primary',
      variant,
      size,
      color,
      orientation,
      disabled,
      count,
      page,
      defaultPage,
      boundaryCount,
      siblingCount,
      showFirstButton,
      showLastButton,
      hidePrevButton,
      hideNextButton,
      onChange,
      ...rest
    }: PaginationProps,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    const { items } = usePagination({
      showFirstButton,
      showLastButton,
      hidePrevButton,
      hideNextButton,
      count,
      page,
      defaultPage,
      boundaryCount,
      siblingCount,
      disabled,
      onChange,
    });

    return (
      <PaginationRoot aria-label="pagination navigation" {...rest} ref={ref}>
        <PaginationUl orientation={orientation}>
          {items.map((item) => (
            <li key={`pagination-item-${item.type}-${item.page}`}>
              <PaginationItem
                size={size}
                variant={item.selected ? activeItemVariant : variant}
                color={item.selected ? activeItemColor : color}
                {...item}
              />
            </li>
          ))}
        </PaginationUl>
      </PaginationRoot>
    );
  },
);

const PaginationRoot = styled('nav', {
  name: 'JoyPagination',
  slot: 'root',
})<PaginationProps>`
  margin: 0;
`;

const PaginationUl = styled('ul', {
  name: 'JoyPaination',
  slot: 'ul',
  shouldForwardProp: (propName) => propName !== 'orientation',
})<{ orientation: PaginationProps['orientation'] }>`
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'row' : 'column'};
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

Pagination.displayName = 'Pagination';

export default Pagination;

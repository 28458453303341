import JoyList from '@mui/joy/List';
import type { ListProps as JoyListProps } from '@mui/joy/List';
import { ForwardedRef, forwardRef, ForwardRefExoticComponent } from 'react';

export type ListProps = JoyListProps;

interface ListComponent extends ForwardRefExoticComponent<ListProps> {
  propTypes?: never;
  muiName?: string;
}

const List = forwardRef(
  (props: ListProps, ref: ForwardedRef<HTMLUListElement>) => {
    return <JoyList {...props} ref={ref} />;
  },
) as ListComponent;

List.muiName = 'List';
List.displayName = 'List';

export default List;

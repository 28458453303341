const HAS_CHARACTER_REGEX = /\D/g;
const COMPANY_REGISTRATION_REGEX = /^(\d{3})(\d{2})(\d{5})$/;

export const formatCompanyRegistrationNumber = (
  companyNumber: string | undefined,
) => {
  if (
    !companyNumber ||
    companyNumber.length === 10 ||
    HAS_CHARACTER_REGEX.test(companyNumber)
  ) {
    return companyNumber;
  }

  return companyNumber.replace(COMPANY_REGISTRATION_REGEX, '$1-$2-$3');
};

import { useTheme } from '@mui/joy';
import {
  Stack,
  Typography,
  TabList,
  Tabs,
  Tab,
  Chip,
  TabPanel,
  Button,
} from '@wooriga/design-system';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import VoteModal from 'components/pages/common/MeetsInfoDescription/VoteModal';
import CertificationTab from 'components/pages/meet-management/certification/tabs/CertificationTab';
import { generateMeetStatus } from 'components/pages/meet-management/history/detail/utils';
import {
  useMeetsAgendasQuery,
  useMeetsDetailQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import { CustomRouteObject } from 'types/route';

const CertificationDetailPage = () => {
  const { unionSeq, meetSeq } = useParams();
  const unionId = Number(unionSeq);
  const meetId = Number(meetSeq);
  const theme = useTheme();

  const { data: meetDetailData } = useMeetsDetailQuery(meetId);
  const { data, isError, error } = useMeetsDetailQuery(meetId);
  const { data: agendaData } = useMeetsAgendasQuery(meetId);

  const [showVoteModal, setShowVoteModal] = useState(false);

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Typography level="title-lg" marginBottom="15px">
        증명서 발급 상세
      </Typography>

      <Tabs
        defaultValue={0}
        selectedTabColor="primary"
        selectedTabVariant="plain"
        tabIndicatorInset
        sx={{
          marginBottom: '30px',
          background: theme.palette.background.body,
        }}
      >
        <TabList>
          <Tab value={0}>유통 증명서</Tab>
          <Tab value={1}>투표내역 증명서</Tab>
          <Tab value={2}>전자문서 증명서</Tab>
        </TabList>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          padding="16px"
          height={68}
        >
          <Stack
            padding="16px"
            flexDirection="row"
            gap={1.75}
            alignItems="center"
          >
            <Typography level="title-md">
              {meetDetailData?.data.name}
            </Typography>
            <Chip color="primary">
              {generateMeetStatus(data?.data?.meetStatus || 'BEFORE')}
            </Chip>
          </Stack>

          <Stack justifyContent="flex-end" flexDirection="row" gap={1.75}>
            {meetDetailData?.data?.electronicVote && (
              <Button onClick={() => setShowVoteModal(true)}>투표 보기</Button>
            )}
          </Stack>
        </Stack>

        <TabPanel value={0}>
          <CertificationTab
            unionSeq={unionId}
            meetSeq={meetId}
            type="DIST_CERT"
          />
        </TabPanel>
        <TabPanel value={1}>
          <CertificationTab
            unionSeq={unionId}
            meetSeq={meetId}
            type="VOTE_CERT"
          />
        </TabPanel>
        <TabPanel value={2}>
          <CertificationTab
            unionSeq={unionId}
            meetSeq={meetId}
            type="ELEC_CERT"
          />
        </TabPanel>
      </Tabs>

      {agendaData?.data && meetDetailData?.data.electronicVote && (
        <VoteModal
          voteName={meetDetailData.data.name}
          voteDescription={meetDetailData.data.electronicVote.description}
          agendas={agendaData.data}
          open={showVoteModal}
          onClose={setShowVoteModal}
        />
      )}
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <CertificationDetailPage />,
  handle: {
    getTitle: () => '증명서 발급 상세',
  },
};

const certificationDetail = {
  path: ':meetSeq',
  children: [route],
  handle: {
    getTitle: () => '증명서 발급 상세',
  },
};

export default certificationDetail;

import { Box, Chip, Stack, Typography } from '@wooriga/design-system';

import { MeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { formatDateTime } from 'utils/format';

interface ElectronicDateInfoProps {
  meetsDetailQuery: MeetsDetailQuery;
}

const ElectronicDateInfo = ({ meetsDetailQuery }: ElectronicDateInfoProps) => {
  const { data } = meetsDetailQuery;
  if (!data?.data.electronicVote) return;

  const { voteStartAt, voteEndAt } = data.data.electronicVote;

  return (
    <>
      <Stack flexDirection="row" gap="40px">
        <Stack gap="10px">
          <Chip>전자투표 시작일시</Chip>
          <Stack>
            <Typography level="body-md">
              {formatDateTime(voteStartAt, 'yyyy-MM-dd (EEE)')}
            </Typography>
            <Typography level="body-md">
              {formatDateTime(voteStartAt, 'HH시 mm분')}
            </Typography>
          </Stack>
        </Stack>
        <Box alignContent="center">{'>'}</Box>
        <Stack gap="10px">
          <Chip>전자투표 종료일시</Chip>
          <Stack>
            <Typography level="body-md">
              {formatDateTime(voteEndAt, 'yyyy-MM-dd (EEE)')}
            </Typography>
            <Typography level="body-md">
              {formatDateTime(voteEndAt, 'HH시 mm분')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ElectronicDateInfo;

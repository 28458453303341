import JoyListItemButton from '@mui/joy/ListItemButton';
import type {
  ExtendListItemButton,
  ListItemButtonTypeMap as JoyListItemButtonTypeMap,
} from '@mui/joy/ListItemButton';
import type { OverrideProps } from '@mui/types';
import { forwardRef } from 'react';
import type { ElementType, ForwardRefExoticComponent } from 'react';

export type ListItemButtonProps<
  D extends ElementType = JoyListItemButtonTypeMap['defaultComponent'],
  P = { component?: ElementType },
> = OverrideProps<JoyListItemButtonTypeMap<P, D>, D>;

interface ListItemButtonComponent<
  D extends ElementType = JoyListItemButtonTypeMap['defaultComponent'],
> extends ForwardRefExoticComponent<ListItemButtonProps<D>> {
  propTypes?: never;
  muiName?: string;
}

const ListItemButton = forwardRef((props, ref) => {
  return <JoyListItemButton {...props} {...ref} />;
}) as ListItemButtonComponent;

ListItemButton.muiName = 'ListItemButton';
ListItemButton.displayName = 'ListItemButton';

export default ListItemButton as ExtendListItemButton<JoyListItemButtonTypeMap>;

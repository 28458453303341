import JoyMenuList from '@mui/joy/MenuList';
import type { MenuListProps as JoyMenuListProps } from '@mui/joy/MenuList';
import { ForwardedRef, forwardRef } from 'react';

export type MenuListProps = JoyMenuListProps;

const MenuList = (
  props: MenuListProps,
  ref: ForwardedRef<HTMLUListElement>,
) => {
  return <JoyMenuList {...props} ref={ref} />;
};

export default forwardRef<HTMLUListElement, MenuListProps>(MenuList);

import JoySelect from '@mui/joy/Select';
import type { SelectProps as JoySelectProps } from '@mui/joy/Select';
import { ForwardedRef, forwardRef } from 'react';

export interface SelectProps<
  OptionValue extends object | string,
  Multiple extends boolean,
> extends JoySelectProps<OptionValue, Multiple> {
  fullWidth?: boolean;
}

const SelectInner = <
  OptionValue extends object | string,
  Multiple extends boolean,
>(
  props: SelectProps<OptionValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const { sx, fullWidth, ...other } = props;

  return (
    <JoySelect<OptionValue, Multiple>
      {...other}
      sx={{ ...sx, ...(fullWidth && { width: '100%' }) }}
      ref={ref}
    />
  );
};

const Select = forwardRef(SelectInner) as <
  OptionValue extends object | string,
  Multiple extends boolean,
>(
  props: SelectProps<OptionValue, Multiple> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof SelectInner>;

export default Select;

import { GridColDef, Link } from '@wooriga/design-system';

import { RealAddress } from 'apis/types/common';
import { UnionRegisterAgentInfo, UnionRegisterInfoAll } from 'apis/types/union';
import { formatBirth, formatDate, formatTelephone } from 'utils/format';
import { formatCompanyRegistrationNumber } from 'utils/format/company';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

export const UNION_REGISTERS_GRID_COLUMNS = ({
  onClickAgentName,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickAgentName: (unionRegisterAgentSeq: number) => void;
}>) =>
  [
    {
      field: 'managementNo',
      headerName: '관리번호',
      width: 78,
      minWidth: 78,
    },
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      width: 78,
      minWidth: 78,
      hideable: false,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 78,
      minWidth: 78,
      groupable: true,
      groupingValueGetter: (value) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', value)?.name || '',
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', value)?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      type: 'boolean',
      width: 78,
      minWidth: 78,
    },
    {
      field: 'hasMultipleRight',
      headerName: '다물권',
      type: 'boolean',
      width: 78,
      minWidth: 78,
      groupable: true,
    },
    {
      field: 'hasVotingRight',
      headerName: '의결권',
      type: 'boolean',
      width: 78,
      minWidth: 78,
      groupable: true,
    },
    {
      field: 'salesCount',
      headerName: '분양권 개수',
      type: 'number',
      width: 64,
      minWidth: 64,
      groupable: true,
      groupingValueGetter: (value) => (value > 0 && value) || '',
      valueGetter: (value) => (value > 0 && value) || '',
    },
    {
      field: 'position',
      headerName: '직책',
      width: 50,
      minWidth: 50,
      groupable: true,
      groupingValueGetter: (value) =>
        getCommonCode('UNION_REGISTER_POSITION', value)?.name || '',
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_POSITION', value)?.name || '',
    },
    {
      field: 'positionDescription',
      headerName: '직책상세',
      width: 78,
      minWidth: 78,
      groupable: true,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 90,
      minWidth: 90,
      valueGetter: (_, row) =>
        row?.unionRegisterSeq &&
        (row?.name
          ? [row?.name?.name, row?.name?.nameClass].join('')
          : '이름없음'),
      renderCell: (params) => {
        const { unionRegisterSeq } = params.row;
        const { value } = params;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            href={`${unionRegisterSeq}/modify`}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'agent',
      headerName: '대리인',
      width: 80,
      minWidth: 80,
      valueGetter: (params: UnionRegisterAgentInfo, row) =>
        row?.agent?.unionRegisterAgentSeq &&
        [params?.name?.name, params?.name?.nameClass].join(''),
      renderCell: (params) => {
        const { agent } = params.row;
        const { unionRegisterAgentSeq } = agent || {};

        return (
          agent &&
          unionRegisterAgentSeq && (
            <Link
              display="inline"
              width="100%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              onClick={() => onClickAgentName(unionRegisterAgentSeq)}
            >
              {params?.value}
            </Link>
          )
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      width: 80,
      minWidth: 80,
      groupable: true,
      groupingValueGetter: (value) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', value)?.name || '',
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', value)?.name || '',
    },
    {
      field: 'birth',
      headerName: '생년월일',
      width: 130,
      minWidth: 130,
      valueGetter: (_, row) =>
        formatBirth(row?.birth) ??
        (formatCompanyRegistrationNumber(row?.companyNo) || ''),
    },
    {
      field: 'gender',
      headerName: '성별',
      width: 50,
      minWidth: 50,
      groupable: true,
      groupingValueGetter: (value) =>
        value === 'M' ? '남자' : value === 'F' ? '여자' : '',
      valueGetter: (value: string) =>
        value === 'M' ? '남자' : value === 'F' ? '여자' : '',
    },
    {
      field: 'mainPhone',
      headerName: '조합원 연락처',
      width: 130,
      minWidth: 130,
      valueGetter: (_, row) =>
        (row?.mainPhone?.phoneNo && formatTelephone(row?.mainPhone?.phoneNo)) ||
        '',
    },
    {
      field: 'subPhone',
      headerName: '기타 연락처',
      width: 130,
      minWidth: 130,
      valueGetter: (_, row) =>
        (row?.subPhones?.[0]?.phoneNo &&
          formatTelephone(row.subPhones[0].phoneNo)) ||
        '',
    },
    {
      field: 'realAddress',
      headerName: '실제 거주지',
      width: 230,
      valueGetter: (params?: RealAddress) =>
        (params && [params?.address, params?.detailAddress].join(' ')) || '',
    },
    {
      field: 'postAddress',
      headerName: '우편물 수령지',
      width: 230,
      valueGetter: (_, row) =>
        [row?.postAddress?.address, row?.postAddress?.detailAddress].join(' '),
    },
    {
      field: 'etcAddress',
      headerName: '기타 거주지',
      width: 230,
    },
    {
      field: 'localAddressRegistrationType',
      headerName: '등기구분',
      width: 78,
      minWidth: 78,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, registrationType }) => ({
          key: `local-address-${localAddressSeq}-registration-type`,
          value: getCommonCode('REGISTRATION_TYPE', registrationType)?.name,
        })),
    },
    {
      field: 'localAddresses',
      headerName: '권리 소재지',
      width: 240,
      multiline: true,
      valueGetter: (params: UnionRegisterInfoAll['localAddresses']) =>
        params?.map(
          ({
            localAddressSeq,
            dong,
            jibun,
            apartmentDong,
            apartmentFloor,
            apartmentHosu,
            building,
          }) => ({
            key: `local-address-${localAddressSeq}`,
            value: [
              dong,
              jibun,
              apartmentDong,
              apartmentFloor,
              apartmentHosu,
              building,
            ].join(' '),
          }),
        ),
    },
    {
      field: 'landOwnershipCategory',
      headerName: '지목',
      type: 'boolean',
      width: 78,
      minWidth: 78,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-category`,
          value: getCommonCode('LAND_CATEGORY', landOwnership?.landCategory)
            ?.name,
        })),
    },
    {
      field: 'landOwnershipPortion',
      headerName: '소유지분',
      width: 78,
      minWidth: 78,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-portion`,
          value: landOwnership?.portion,
        })),
    },
    {
      field: 'landOwnershipTotalArea',
      headerName: '전체면적 ㎡',
      width: 100,
      type: 'number',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-total-area`,
          value: landOwnership?.totalArea,
        })),
    },
    {
      field: 'landOwnershipOwnedArea',
      headerName: '소유면적 ㎡',
      width: 100,
      type: 'number',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-owned-area`,
          value: landOwnership?.ownedArea,
        })),
    },
    {
      field: 'landOwnershipIncorporatedArea',
      headerName: '편입면적 ㎡',
      width: 100,
      type: 'number',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-incorporated-area`,
          value: landOwnership?.incorporatedArea,
        })),
    },
    {
      field: 'landOwnershipUtilization',
      headerName: '이용상황',
      width: 100,
      type: 'boolean',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-utilization`,
          value: getCommonCode('LAND_UTILIZATION', landOwnership?.utilization)
            ?.name,
        })),
    },
    {
      field: 'landOwnershipAcquiredAt',
      headerName: '취득일자',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-acquired-at`,
          value:
            landOwnership?.acquiredAt && formatDate(landOwnership.acquiredAt),
        })),
    },
    {
      field: 'landOwnershipAcquisitionPeriod',
      headerName: '취득기간',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-acquisition-period`,
          value: landOwnership?.acquisitionPeriod,
        })),
    },
    {
      field: 'landOwnershipHasSurfaceRight',
      headerName: '지상권 여부',
      width: 100,
      type: 'boolean',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, landOwnership }) => ({
          key: `local-address-${localAddressSeq}-land-ownership-has-surface-right`,
          value: landOwnership?.hasSurfaceRight,
        })),
    },
    {
      field: 'buildingOwnershipIsPermitted',
      headerName: '허가유무',
      width: 100,
      type: 'boolean',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-is-permitted`,
          value: buildingOwnership?.isPermitted,
        })),
    },
    {
      field: 'buildingOwnershipBuildingName',
      headerName: '건물명',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-building-name`,
          value: buildingOwnership?.buildingName,
        })),
    },
    {
      field: 'buildingOwnershipBuildingUsage',
      headerName: '용도',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-building-usage`,
          value: getCommonCode(
            'BUILDING_USAGE',
            buildingOwnership?.buildingUsage,
          )?.name,
        })),
    },
    {
      field: 'buildingOwnershipBuildingStructure',
      headerName: '구조',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-building-structure`,
          value: getCommonCode(
            'BUILDING_STRUCTURE',
            buildingOwnership?.buildingStructure,
          )?.name,
        })),
    },
    {
      field: 'buildingOwnershipFloor',
      headerName: '층별 지하/지상',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-floor`,
          value: buildingOwnership?.floor,
        })),
    },
    {
      field: 'buildingOwnershipUseApprovedAt',
      headerName: '사용승인일',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-use-approved-at`,

          value:
            buildingOwnership?.useApprovedAt &&
            formatDate(buildingOwnership.useApprovedAt),
        })),
    },
    {
      field: 'buildingOwnershipPortion',
      headerName: '소유지분',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-portion`,
          value: buildingOwnership?.portion,
        })),
    },
    {
      field: 'buildingOwnershipTotalArea',
      headerName: '전체면적㎡',
      width: 100,
      type: 'number',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-total-area`,
          value: buildingOwnership?.totalArea,
        })),
    },
    {
      field: 'buildingOwnershipOwnedArea',
      headerName: '소유면적㎡',
      width: 100,
      type: 'number',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-owned-area`,
          value: buildingOwnership?.ownedArea,
        })),
    },
    {
      field: 'buildingOwnershipAcquiredAt',
      headerName: '취득일자',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-acquired-at`,
          value:
            buildingOwnership?.acquiredAt &&
            formatDate(buildingOwnership.acquiredAt),
        })),
    },
    {
      field: 'buildingOwnershipAcquisitionPeriod',
      headerName: '취득기간',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-acquisition-period`,
          value: buildingOwnership?.acquisitionPeriod,
        })),
    },
    {
      field: 'buildingOwnershipUnPermittedBuildingNo',
      headerName: '무허가 건물번호',
      width: 124,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-unpermitted-building-no`,
          value: buildingOwnership?.unPermittedBuildingNo,
        })),
    },
    {
      field: 'buildingOwnershipAssociatedJibun',
      headerName: '관련지번',
      width: 100,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, buildingOwnership }) => ({
          key: `local-address-${localAddressSeq}-building-ownership-associated-jibun`,
          value: buildingOwnership?.associatedJibun,
        })),
    },
    {
      field: 'memo',
      headerName: '비고',
      width: 200,
    },
  ] as GridColDef<UnionRegisterInfoAll>[];

import {
  Descriptions,
  DescriptionsItem,
  Stack,
  Typography,
} from '@wooriga/design-system';

import { UnionRegisterInfo } from 'apis/types/union';
import CommonCode from 'components/CommonCode';
import { formatBirth, formatTelephone } from 'utils/format';
import { formatCompanyRegistrationNumber } from 'utils/format/company';

export interface UnionRegisterDescriptionsProps {
  data?: UnionRegisterInfo;
}

const UnionRegisterDescriptions = (props: UnionRegisterDescriptionsProps) => {
  const { data } = props;

  const unionRegisterInfo = data;

  const mainRealAddress = unionRegisterInfo?.realAddress;

  const postAddress = unionRegisterInfo?.postAddress;

  const mainLocalAddress = unionRegisterInfo?.localAddresses[0];

  return (
    <Stack gap={1}>
      <Typography level="h3" fontSize="lg" fontWeight="xl" lineHeight="xl">
        조합원 정보
      </Typography>

      <Descriptions variant="soft" color="primary" size="lg" columns={4}>
        <DescriptionsItem label="연번" span={1}>
          {unionRegisterInfo?.unionRegisterNo}
        </DescriptionsItem>
        <DescriptionsItem label="이름" span={1}>
          {unionRegisterInfo?.name?.name ||
            '' + (unionRegisterInfo?.name?.nameClass || '')}
        </DescriptionsItem>
        {unionRegisterInfo?.ownerType &&
        unionRegisterInfo?.ownerType === 'COMPANY' ? (
          <DescriptionsItem label="성별" span={1}></DescriptionsItem>
        ) : (
          <DescriptionsItem label="성별" span={1}>
            {unionRegisterInfo?.gender === 'M'
              ? '남자'
              : unionRegisterInfo?.gender === 'F'
              ? '여자'
              : '미지정'}
          </DescriptionsItem>
        )}
        <DescriptionsItem label="구분" span={1}>
          {unionRegisterInfo?.ownerType && (
            <CommonCode
              groupCode="UNION_REGISTER_USER_DIVIDE"
              code={unionRegisterInfo?.ownerType}
            />
          )}
        </DescriptionsItem>
        <DescriptionsItem label="소유 구분" span={1}>
          {unionRegisterInfo?.shareType && (
            <CommonCode
              groupCode="UNION_REGISTER_SHARE_TYPE"
              code={unionRegisterInfo?.shareType}
            />
          )}
        </DescriptionsItem>
        {unionRegisterInfo?.ownerType &&
        unionRegisterInfo?.ownerType === 'COMPANY' ? (
          <DescriptionsItem label="사업자 번호" span={1}>
            {formatCompanyRegistrationNumber(unionRegisterInfo?.companyNo)}
          </DescriptionsItem>
        ) : (
          <DescriptionsItem label="생년월일" span={1}>
            {formatBirth(unionRegisterInfo?.birth)}
          </DescriptionsItem>
        )}

        <DescriptionsItem label="연락처" span={1}>
          {formatTelephone(unionRegisterInfo?.mainPhone?.phoneNo)}
          {/* {unionRegisterInfo?.mainPhone?.phoneNo} */}
        </DescriptionsItem>
        <DescriptionsItem label="직책" span={1}>
          {unionRegisterInfo?.positionDescription}
        </DescriptionsItem>
        <DescriptionsItem label="소재지" span={2}>
          {mainLocalAddress &&
            `${mainLocalAddress.city || ''} ${mainLocalAddress.gu || ''} ${
              mainLocalAddress.jibun || ''
            } ${mainLocalAddress.building || ''} ${
              mainLocalAddress.apartmentDong || ''
            } ${mainLocalAddress.apartmentHosu || ''}`}
        </DescriptionsItem>

        <DescriptionsItem label="거주지" span={2}>
          {mainRealAddress &&
            `${mainRealAddress.city || ''} ${mainRealAddress.gu || ''} ${
              mainRealAddress.dong || ''
            } ${mainRealAddress.address || ''} ${
              mainRealAddress.detailAddress || ''
            }`}
        </DescriptionsItem>
        <DescriptionsItem label="우편물 수령지" span={2}>
          {postAddress && `${postAddress.address || ''}`}
        </DescriptionsItem>
        <DescriptionsItem label="비고" span={2}>
          {unionRegisterInfo?.memo}
        </DescriptionsItem>
      </Descriptions>
    </Stack>
  );
};

export default UnionRegisterDescriptions;

import JoyFormLabel from '@mui/joy/FormLabel';
import type { FormLabelProps as JoyFormLabelProps } from '@mui/joy/FormLabel';
import { ForwardedRef, forwardRef } from 'react';

export type FormLabelProps = JoyFormLabelProps;

const FormLabel = (
  props: FormLabelProps,
  ref: ForwardedRef<HTMLLabelElement>,
) => {
  return <JoyFormLabel {...props} ref={ref} />;
};

export default forwardRef<HTMLLabelElement, FormLabelProps>(FormLabel);

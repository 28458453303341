import { Stack } from '@wooriga/design-system';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { ElectronicVoteSendStatusCountQuery } from 'lim/generalMeetingHistoryDetail/apis';

interface ElectronicDescriptionsProps {
  electronicVoteSendStatusCountQuery?: ElectronicVoteSendStatusCountQuery;
}

const ElectronicDescriptions = ({
  electronicVoteSendStatusCountQuery,
}: ElectronicDescriptionsProps) => {
  if (!electronicVoteSendStatusCountQuery) return;

  const getVoteSendJSON = () => {
    if (!electronicVoteSendStatusCountQuery?.data)
      return generateDescriptionsJSON(
        ['수신완료', '전송대기', '전송실패', '전송완료', '미발송'],
        [],
      );

    const { received, sendWait, sendFail, sendDone, unSent } =
      electronicVoteSendStatusCountQuery.data.data;

    return generateDescriptionsJSON(
      ['수신완료', '전송대기', '전송실패', '전송완료', '미발송'],
      [received, sendWait, sendFail, sendDone, unSent],
    );
  };

  const getVoteViewJSON = () => {
    if (!electronicVoteSendStatusCountQuery?.data)
      return generateDescriptionsJSON(
        ['열람', '미열람', '투표완료', '미완료'],
        [],
      );

    const { opened, unOpened, voted, unVoted } =
      electronicVoteSendStatusCountQuery.data.data;

    return generateDescriptionsJSON(
      ['열람', '미열람', '투표완료', '미완료'],
      [opened, unOpened, voted, unVoted],
    );
  };

  return (
    <Stack gap={1.75} marginBottom="30px">
      <DescriptionsRenderer columns={5} json={getVoteSendJSON()} />
      <DescriptionsRenderer columns={5} json={getVoteViewJSON()} />
    </Stack>
  );
};

export default ElectronicDescriptions;

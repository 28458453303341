import JoyAutocomplete from '@mui/joy/Autocomplete';
import type { AutocompleteProps as JoyAutocompleteProps } from '@mui/joy/Autocomplete';

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends JoyAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  return <JoyAutocomplete {...props} />;
};

export default Autocomplete;

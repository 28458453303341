import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useState } from 'react';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { translateMeetMethod } from 'components/pages/meet-management/history/detail/tabs/VoteHomeTab/utils';
import { UpdateMeetBody } from 'lim/generalMeetingHistoryDetail/apis';

import ElectronicDescriptions from './ElectronicDescriptions';
import OnsiteDescriptions from './OnsiteDescriptions';
import { EditModalProps } from './type';

const defaultFormData = {
  onsiteVote: {
    meetAt: new Date(),
    submissionEndAt: new Date(),
    jibunAddress: '',
    roadAddress: '',
    detailAddress: '',
  },
  electronicVote: {
    contactNo: '',
  },
};
const EditModal = ({ data, open, onClose, onConfirm }: EditModalProps) => {
  const { meetMethod, participantCount, agendaCount } = data;

  const [formData, setFormData] = useState<UpdateMeetBody>(defaultFormData);

  const commonJSON = generateDescriptionsJSON(
    ['총회 방식', '총 선거인', '안건 수'],
    [
      translateMeetMethod(meetMethod),
      participantCount.toString(),
      agendaCount.toString(),
      '',
    ],
  );

  useEffect(() => {
    let electronicVote: UpdateMeetBody['electronicVote'] = {};
    let onsiteVote: UpdateMeetBody['onsiteVote'] = {};

    if (data.electronicVote) {
      const { contactNo } = data.electronicVote;

      electronicVote = {
        contactNo,
      };
    }

    if (data.onsiteVote) {
      const { jibunAddress, roadAddress, detailAddress } = data.onsiteVote;

      onsiteVote = {
        meetAt: new Date(),
        submissionEndAt: new Date(),
        jibunAddress,
        roadAddress,
        detailAddress,
      };
    }

    setFormData({
      onsiteVote,
      electronicVote,
    });
  }, [open, data]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog minWidth={640} sx={{ gap: '30px' }}>
        <DialogTitle sx={{ alignSelf: 'center' }}>총회 정보 수정</DialogTitle>
        <DialogContent>
          <Stack gap={3}>
            <Typography level="title-lg">일반 정보</Typography>
            <DescriptionsRenderer columns={1} json={commonJSON} />

            {data.electronicVote && (
              <ElectronicDescriptions
                electronicVote={data.electronicVote}
                onChange={(values) =>
                  setFormData({ ...formData, electronicVote: values })
                }
              />
            )}
            {data.onsiteVote && (
              <OnsiteDescriptions
                onsiteVote={data.onsiteVote}
                onChange={(values) =>
                  setFormData({ ...formData, onsiteVote: values })
                }
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={() => onConfirm(formData)}>
            확인
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            fullWidth
            onClick={() => onClose(false)}
          >
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default EditModal;

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {
  AppBar,
  // Avatar,
  Box,
  Button,
  Chip,
  CollapseListItem,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  NavigationBar,
  NavigationBarContent,
  NavigationBarFooter,
  NavigationBarHeader,
  NavigationBarToggleButton,
  Option,
  Select,
  Stack,
  Typography,
} from '@wooriga/design-system';
import * as _ from 'lodash-es';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  matchPath,
  Outlet,
  useLoaderData,
  useLocation,
  useMatches,
  useNavigate,
  useRevalidator,
} from 'react-router-dom';

import { MemberInfo, MemberUnionInfo } from 'apis/types/member';
import { useUnionServiceInfoQuery } from 'apis/union/serviceInfo/apis';
import CommonCode from 'components/CommonCode';
import HelpTooltip from 'components/HelpTooltip';
import useChargeModal from 'hooks/useChargeModal';
import { LayoutContextValue } from 'hooks/useLayoutContext/types';
import useLayout from 'layouts/useLayout';
import { AvailableMenu } from 'lim/auth/apis';
import type { CustomRouteObject, CustomUIMatch } from 'types/route';
import { commaizeNumber } from 'utils/format';

const WIDTH = 300,
  MIN_WIDTH = 56;

const PATTERN = /\/unions\/([^/]+)(\/.*)?/;

const replaceUnionSeq = (url: string, unionSeq: number): string => {
  const match = url.match(PATTERN);

  if (match) {
    return match?.[2]
      ? `/unions/${unionSeq}${match[2]}`
      : `/unions/${unionSeq}`;
  }

  return url;
};

const extractIdFromUrl = (url: string): string | null => {
  const match = url.match(PATTERN);
  return match ? match[1] : null;
};

export interface DefaultLayoutProps {
  routes: CustomRouteObject[];
}

const MainLayout = ({ routes }: DefaultLayoutProps) => {
  const matches = useMatches() as CustomUIMatch<unknown>[];
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const location = useLocation();
  const {
    title,
    setTitle,
    subTitle,
    setSubTitle,
    helpTooltip,
    setHelpTooltip,
    layoutContainerStyles,
    setLayoutContainerStyles,
    logout,
  } = useLayout();
  const { chargeModal, openChargeModal } = useChargeModal();

  const {
    memberInfo,
    memberUnions,
    memberUnionInfo,
    availableMenus = [],
  } = useLoaderData() as {
    memberInfo: MemberInfo;
    memberUnions: MemberUnionInfo[];
    memberUnionInfo: MemberUnionInfo;
    availableMenus: AvailableMenu[];
  };

  const [open, setOpen] = useState<boolean>(true);
  const [sidePanel, setSidePanel] = useState<ReactNode | undefined>(undefined);

  const { data: unionServiceInfoResultData } = useUnionServiceInfoQuery({
    unionSeq: memberUnionInfo!.unionSeq,
  });
  const unionServiceInfo = unionServiceInfoResultData?.data;

  const unionBasename = useMemo(() => {
    const id = extractIdFromUrl(location.pathname);
    return id ? `/unions/${id}` : '/unions';
  }, [location.pathname]);

  const handleToggle = useCallback((_: SyntheticEvent, open: boolean) => {
    setOpen(open);
  }, []);

  const handleChangeUnion = useCallback(
    (unionSeq: number) => {
      navigate(`/unions/${unionSeq}`);
      revalidator.revalidate();
    },
    [location.pathname, navigate, revalidator],
  );

  const pageContext = useMemo(
    () => ({
      memberInfo,
      memberUnionInfo,
      unionServiceInfo,
      title,
      setTitle,
      subTitle,
      setSubTitle,
      helpTooltip,
      setHelpTooltip,
      unionBasename,
      logout,
      changeUnion: handleChangeUnion,
    }),
    [
      handleChangeUnion,
      helpTooltip,
      logout,
      memberInfo,
      memberUnionInfo,
      setHelpTooltip,
      setSubTitle,
      setTitle,
      subTitle,
      title,
      unionBasename,
      unionServiceInfo,
    ],
  );

  return (
    <Stack flexDirection="row" minHeight="100vh">
      <NavigationBar
        open={open}
        onChange={handleToggle}
        width={WIDTH}
        minWidth={MIN_WIDTH}
        permanent
      >
        <NavigationBarHeader>
          {/* <Avatar size="sm" /> */}
          {memberUnions && (
            <Select<MemberUnionInfo, false>
              variant="plain"
              fullWidth
              size="sm"
              value={memberUnionInfo}
              onChange={(_, value) =>
                value && handleChangeUnion(value.unionSeq)
              }
            >
              {memberUnions.map((union) => (
                <Option key={`unions-${union.unionSeq}`} value={union}>
                  {union.name}
                </Option>
              ))}
            </Select>
          )}
        </NavigationBarHeader>

        <NavigationBarContent>
          <List>
            {routes.map(({ path, handle, children }, index) => {
              const { getTitle, getIcon, getHidden, getMenuCode } =
                handle || {};
              const title = (getTitle && getTitle()) || path;
              const icon = getIcon && getIcon();
              const hidden = getHidden && getHidden();
              const selected = !!path && !!matchPath(path, location.pathname);
              const unionSeq = memberUnionInfo.unionSeq;

              const menuCode = getMenuCode?.();
              const matchedMenu = availableMenus.find(
                (menu) => menu.code === menuCode,
              );

              if (hidden || (menuCode && !matchedMenu)) return undefined;

              if (children) {
                const key = `collapse-list-item-${index}`;
                const findMatch = matches.find((match) =>
                  _.find(children, (child) => {
                    const childPath =
                      child?.path && replaceUnionSeq(child.path, unionSeq);
                    const result =
                      childPath && matchPath(childPath, match.pathname);
                    return result;
                  }),
                );
                const childrenSelected = !!findMatch;

                return (
                  <CollapseListItem key={key} in={childrenSelected}>
                    <ListItemButton selected={childrenSelected}>
                      <ListItemDecorator>{icon}</ListItemDecorator>
                      <ListItemContent>{title}</ListItemContent>
                    </ListItemButton>

                    <List>
                      {children.map((child) => {
                        const childKey = `${key}-child-list-item-${child.path}`;
                        const href =
                          child.path && replaceUnionSeq(child.path, unionSeq);
                        const childSelected =
                          !!href && findMatch?.pathname === href;

                        const childHidden =
                          child.handle?.getHidden && child.handle?.getHidden();
                        const childMenuCode = child.handle?.getMenuCode?.();
                        const matchedSubMenu = matchedMenu?.subMenus.find(
                          (menu) => menu.code === childMenuCode,
                        );

                        if (childHidden || (childMenuCode && !matchedSubMenu))
                          return undefined;

                        return (
                          <ListItem key={childKey}>
                            <ListItemButton
                              selected={childSelected}
                              component={Link}
                              // href={childSelected ? undefined : href}
                              href={href}
                            >
                              <ListItemDecorator />
                              <ListItemContent>
                                {(child.handle?.getTitle &&
                                  child.handle.getTitle()) ||
                                  ''}
                              </ListItemContent>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </CollapseListItem>
                );
              }

              const href = path && replaceUnionSeq(path, unionSeq);

              return (
                <ListItem key={`list-item-${index}-${path}`}>
                  <ListItemButton
                    selected={selected}
                    component={Link}
                    // href={selected ? undefined : href}
                    href={href}
                  >
                    <ListItemDecorator>{icon}</ListItemDecorator>
                    <ListItemContent>{title}</ListItemContent>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </NavigationBarContent>

        <Divider />

        <NavigationBarFooter flexDirection="row">
          <Stack flex={1} justifyContent="center">
            <Typography
              fontWeight="lg"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              <Typography>{memberInfo?.nickname}</Typography>
              {` `}님
            </Typography>
          </Stack>

          <Button
            size="sm"
            startDecorator={<LogoutRoundedIcon />}
            onClick={logout}
            sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            로그아웃
          </Button>
        </NavigationBarFooter>

        <NavigationBarToggleButton />
      </NavigationBar>

      <Stack flex={1} flexDirection="column" overflow="hidden">
        <AppBar
          alignItems="center"
          sx={{
            width: `calc(100% - ${open ? WIDTH : MIN_WIDTH}px)`,
            marginLeft: open ? WIDTH : MIN_WIDTH,
            paddingX: 6.5,
          }}
        >
          <Stack flexDirection="row" alignItems="center" flex={1}>
            <Typography
              level="h1"
              fontSize="xl"
              fontWeight="xl"
              lineHeight="lg"
              textColor="primary"
              mr={2}
            >
              {title}
            </Typography>

            <Typography
              level="h2"
              fontSize="md"
              fontWeight="lg"
              lineHeight="md"
              textColor="tertiary"
            >
              {subTitle}
            </Typography>

            {helpTooltip && (
              <HelpTooltip
                title={helpTooltip.title}
                contents={helpTooltip.contents}
              />
            )}
          </Stack>

          {unionServiceInfo && (
            <Box display="flex" flexBasis="auto" gap={5}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Chip color="primary" variant="outlined">
                  <CommonCode
                    groupCode="UNION_SERVICE_DIVIDE"
                    code={unionServiceInfo.serviceDivisionCode}
                  />
                </Chip>
                <Typography level="title-sm" textColor="secondary">
                  {`잔액 ${commaizeNumber(
                    unionServiceInfo.depositBalance +
                      unionServiceInfo.pointBalance,
                  )}원`}
                </Typography>
                <Button
                  variant="soft"
                  onClick={() =>
                    openChargeModal({
                      unionSeq: memberUnionInfo?.unionSeq || 0,
                      unionName: memberUnionInfo.name,
                    })
                  }
                >
                  충전
                </Button>
              </Box>
            </Box>
          )}
        </AppBar>

        {chargeModal && chargeModal}

        <Stack
          sx={{
            flexDirection: 'row',
            pt: 8,
            minHeight: '100vh',
            flex: '1',
          }}
        >
          <Box
            flex={1}
            px={6.5}
            py={4}
            maxWidth="xl"
            width="100%"
            sx={{
              ...layoutContainerStyles,
            }}
          >
            <Outlet
              context={
                {
                  setSidePanel,
                  setLayoutContainerStyles,
                  pageContext,
                } satisfies LayoutContextValue
              }
            />
          </Box>

          {sidePanel && (
            <Box ml="auto" position="relative">
              {sidePanel}
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MainLayout;

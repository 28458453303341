import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Typography,
} from '@wooriga/design-system';

import CommonCode from 'components/CommonCode';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { useMeetsPromotionUserDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';

interface PromotionUserInfoModalProps {
  meetSeq: number;
  promotionUserSeq: number;
  open: boolean;
  onClose: (open: false) => void;
}

const PromotionUserInfoModal = ({
  meetSeq,
  promotionUserSeq,
  open,
  onClose,
}: PromotionUserInfoModalProps) => {
  const { data } = useMeetsPromotionUserDetailQuery(meetSeq, promotionUserSeq);

  if (!data) return <></>;

  const {
    userStatus,
    phoneNo,
    id,
    position,
    name,
    assignedParticipantCount,
    memo,
  } = data.data;

  const JSON = generateDescriptionsJSON(
    ['계정 상태', '연락처', '아이디', '직무', '이름', '담당선거인수', '메모'],
    [
      <CommonCode
        key="PROMOTION_USER_STATUS"
        groupCode="PROMOTION_USER_STATUS"
        code={userStatus}
      />,
      phoneNo,
      id,
      <CommonCode
        key="PROMOTION_USER_POSITION"
        groupCode="PROMOTION_USER_POSITION"
        code={position}
      />,
      name,
      assignedParticipantCount,
      memo,
    ],
  );

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog minWidth={640}>
        <DialogTitle>담당자 정보</DialogTitle>
        <DialogContent>
          {' '}
          <Typography level="title-md" marginTop={4}>
            일반 정보
          </Typography>
          <DescriptionsRenderer columns={2} json={JSON} />
        </DialogContent>

        <DialogActions>
          <Button fullWidth onClick={() => onClose(false)}>
            확인
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PromotionUserInfoModal;

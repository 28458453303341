import { PropsWithChildren, ReactNode } from 'react';

export type DescriptionsItemSlot = 'root' | 'label' | 'content';

export type DescriptionsItemProps = PropsWithChildren<{
  label?: ReactNode;
  span?: number;
}>;

export type DescriptionsItemOwnerState = DescriptionsItemProps;

const DescriptionsItem = ({ children }: DescriptionsItemProps) => {
  return children;
};

export default DescriptionsItem;

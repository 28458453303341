export const translateMeetMethod = (meetMethod: string) => {
  switch (meetMethod) {
    case 'ONSITE':
      return '현장 투표';
    case 'ELECTRONIC':
      return '전자투표';
    default:
      return '전자·현장 투표';
  }
};

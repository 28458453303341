import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/joy';
import { ForwardedRef, forwardRef, useCallback } from 'react';

import IconButton, {
  IconButtonProps,
} from 'design-system/components/inputs/IconButton';
import usePanel from 'design-system/components/surfaces/panels/usePanel';

export type PanelCloseButtonSlot = 'root';

export const PanelCloseButtonClasses = {
  root: 'JoyPanelCloseButton-root',
};

export interface PanelCloseButtonProps extends IconButtonProps {}

export type PanelCloseButtonOwnerState = PanelCloseButtonProps;

const PanelCloseButton = (
  props: PanelCloseButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const { variant = 'plain', size = 'sm', children, ...other } = props;

  const panelContext = usePanel();

  const handleClick = useCallback(() => {
    if (!panelContext) return;
    panelContext.setOpen?.(false);
    panelContext.onClose?.();
  }, [panelContext]);

  return (
    <PanelCloseButtonRoot
      variant={variant || panelContext?.variant}
      size={size}
      onClick={handleClick}
      ref={ref}
      {...other}
    >
      {children ? children : <CloseIcon />}
    </PanelCloseButtonRoot>
  );
};

const PanelCloseButtonRoot = styled(IconButton, {
  name: 'JoyPanelCloseButton',
  slot: 'root',
})`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
`;

export default forwardRef<HTMLButtonElement, PanelCloseButtonProps>(
  PanelCloseButton,
);

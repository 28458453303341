import CloseIcon from '@mui/icons-material/Close';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useContext,
  MouseEvent,
} from 'react';

import CloseSnackbarContext from 'design-system/components/feedback/snackbar/CloseSnackbarContext';
import { Button, ButtonProps } from 'design-system/components/inputs';

export type SnackbarCloseProps = ButtonProps;

const SnackbarClose = (
  { onClick, children, ...other }: SnackbarCloseProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const closeSnackbarContext = useContext(CloseSnackbarContext);

  const enhancedClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      closeSnackbarContext?.onClose?.(event, 'escapeKeyDown');
      onClick?.(event);
    },
    [closeSnackbarContext, onClick],
  );

  return (
    <Button
      ref={ref}
      onClick={enhancedClick}
      color={closeSnackbarContext?.color}
      variant={
        closeSnackbarContext?.variant === 'solid'
          ? closeSnackbarContext?.variant
          : 'plain'
      }
      size={closeSnackbarContext?.size}
      {...other}
    >
      {children ? children : <CloseIcon />}
    </Button>
  );
};

export default forwardRef<HTMLButtonElement, SnackbarCloseProps>(SnackbarClose);

import { GridPaginationModel } from '@mui/x-data-grid-premium';
import {
  Box,
  Button,
  DataGrid,
  Link,
  Pagination,
  Stack,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { MeetMethods, MeetStatus } from 'apis/types/meet';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import SendingHistoryModal from 'components/pages/meet-management/history/detail/tabs/VoteManagementTab/SendingHistoryModal';
import { MeetMethodProps } from 'components/pages/meet-management/history/detail/type';
import { generateMeetMethodKey } from 'components/pages/meet-management/history/detail/utils';
import useLayoutContext from 'hooks/useLayoutContext';
import {
  ElectronicVoteSendStatusCountQuery,
  MeetsAttendExpectedCountQuery,
  MeetsParticipantsParams,
  MeetsParticipantsQuery,
  MeetsWrittenSubmissionCountQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import {
  ELECTRONIC_VOTE_MANAGEMENT_COLUMNS,
  ONSITE_VOTE_MANAGEMENT_COLUMNS,
} from 'lim/generalMeetingHistoryDetail/fixtures';
import { commaizeNumber } from 'utils/format';

import ElectronicDescriptions from './Descriptions/ElectronicDescriptions';
import OnsiteDescriptions from './Descriptions/OnsiteDescriptions';
import MessageModal from './MessageModal';
import PostModal from './PostModal';
import PromotionUserInfoModal from './PromotionUserInfoModal';
import ElectronicFilter from './searchFilters/ElectronicFilter';
import OnsiteFilter from './searchFilters/OnsiteFilter';
import { SendingHistoryModalProps } from './SendingHistoryModal/type';

interface Onsite {
  meetsWrittenSubmissionCountQuery: MeetsWrittenSubmissionCountQuery;
  meetsAttendExpectedCountQuery: MeetsAttendExpectedCountQuery;
}

interface Electronic {
  electronicVoteSendStatusCountQuery: ElectronicVoteSendStatusCountQuery;
}

export type VoteManagementTabProps<MeetMethod extends MeetMethods> = Omit<
  SendingHistoryModalProps,
  'open' | 'onClose' | 'onSuccess' | 'meetParticipantSeq'
> &
  MeetMethodProps<MeetMethod, Electronic, Onsite> & {
    meetSeq: number;
    meetMethod: MeetMethod;
    meetStatus: MeetStatus;

    // ------------------------ 공통 ------------------------
    meetsParticipantsQuery: MeetsParticipantsQuery;
    onSearch: (params: MeetsParticipantsParams<MeetMethod>) => void;
  };

const VoteManagementTab = <MeetMethod extends MeetMethods>({
  meetSeq,
  meetMethod,
  meetStatus,
  // ------------------------ 공통 ------------------------
  meetsParticipantsQuery, //

  // ------------------------ 전자 ------------------------
  electronicVoteSendStatusCountQuery, //
  // ------------------------ 서면 ------------------------
  meetsWrittenSubmissionCountQuery,
  meetsAttendExpectedCountQuery,
  onSearch,
}: VoteManagementTabProps<MeetMethod>) => {
  const { pageContext } = useLayoutContext();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'meet-vote',
    activeSaveSnapshot: true,
  });

  const isElectronic = meetMethod === 'ELECTRONIC';
  const { data, refetch, isError, error, isLoading } = meetsParticipantsQuery;

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);
  const [showSentCountModal, setShowSentCountModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);

  const [showPromotionUserInfoModal, setShowPromotionUserInfoModal] =
    useState(false);

  const [selectedUnionRegisterSeq, setSelectedUnionRegisterSeq] = useState(0);
  const [selectedParticipantId, setSelectedParticipantId] = useState<
    number | null
  >(null);
  const [promotionUserSeq, setPromotionUserSeq] = useState<number | null>(null);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const handleOnNameClick = (id: string | number) => {
    const unionId = Number(id);
    setShowUnionInfoModal(true);

    setSelectedUnionRegisterSeq(unionId);
  };

  const handleOnCountClick = (id: string | number) => {
    setShowSentCountModal(true);
    setSelectedParticipantId(Number(id));
  };

  const handleOnPromotionUserClick = (id: number) => {
    setShowPromotionUserInfoModal(true);
    setPromotionUserSeq(Number(id));
  };

  const rows = useMemo(() => {
    return (
      data?.data?.map((participant) => {
        const {
          meetParticipantSeq,
          unionRegisterSeq,
          unionRegisterNo,
          agent,
          name,
          birth,
          phoneNo,
          tendency,

          promotionUser,
        } = participant;

        const meetMethodData = generateMeetMethodKey(meetMethod);

        return {
          id: meetParticipantSeq,
          unionRegisterSeq,
          unionRegisterNo,
          agent: !!agent,
          name: name?.name,
          birth,
          phoneNo,
          tendency,
          ...participant[meetMethodData],
          promotionUserSeq: promotionUser?.promotionUserSeq,
          promotionUser: promotionUser?.name,
        };
      }) || []
    );
  }, [meetsParticipantsQuery]);

  const totalElements = data?.pagination?.totalElements || 0;
  const totalDataCount = data?.pagination?.totalDataCount || 0;

  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  if (isError) throw error;

  return (
    <>
      {isElectronic ? (
        <ElectronicDescriptions
          electronicVoteSendStatusCountQuery={
            electronicVoteSendStatusCountQuery
          }
        />
      ) : (
        <OnsiteDescriptions
          meetsWrittenSubmissionCountQuery={meetsWrittenSubmissionCountQuery}
          meetsAttendExpectedCountQuery={meetsAttendExpectedCountQuery}
        />
      )}

      <Stack gap={1.75}>
        <Typography level="title-lg">선거인 목록</Typography>

        <Stack gap={1.75}>
          {isElectronic ? (
            <ElectronicFilter onSearchSubmit={onSearch} />
          ) : (
            <OnsiteFilter onSearchSubmit={onSearch} />
          )}
        </Stack>

        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount || 0)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements || 0)}
              </Typography>
            </Typography>
          </Stack>

          <Box>
            <DataGrid
              apiRef={datagridApiRef}
              rows={rows}
              loading={isLoading}
              columns={
                isElectronic
                  ? ELECTRONIC_VOTE_MANAGEMENT_COLUMNS(
                      handleOnNameClick,
                      handleOnCountClick,
                      handleOnPromotionUserClick,
                    )
                  : ONSITE_VOTE_MANAGEMENT_COLUMNS(
                      handleOnNameClick,
                      handleOnPromotionUserClick,
                    )
              }
              disableRowSelectionOnClick
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            />
          </Box>

          <Stack alignItems="center">
            <Pagination
              color="neutral"
              variant="plain"
              size="md"
              orientation="horizontal"
              showFirstButton
              showLastButton
              hidePrevButton={false}
              hideNextButton={false}
              count={pageTotalCount}
              onChange={(_, page) => {
                page &&
                  setPaginationModel({
                    ...paginationModel,
                    page: page - 1,
                  });
              }}
            />
          </Stack>

          <Stack direction="row" alignSelf="end" gap={2}>
            <Button variant="outlined" component={Link} href="..">
              목록
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                exportExcel();
              }}
            >
              내역 다운
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowMessageModal(true)}
            >
              문자 발송
            </Button>
            <Button variant="outlined" onClick={() => setShowPostModal(true)}>
              우편 발송
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq: Number(pageContext?.memberUnionInfo?.unionSeq),
            unionRegisterSeq: selectedUnionRegisterSeq,
          }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}

      {selectedParticipantId && (
        <SendingHistoryModal
          meetSeq={meetSeq}
          meetStatus={meetStatus}
          meetParticipantSeq={selectedParticipantId}
          meetMethod={meetMethod}
          open={showSentCountModal}
          onClose={setShowSentCountModal}
          onSuccess={refetch}
        />
      )}

      {showMessageModal && (
        <MessageModal
          meetSeq={meetSeq}
          meetMethod={meetMethod}
          open={showMessageModal}
          onClose={setShowMessageModal}
        />
      )}

      {showPostModal && (
        <PostModal
          unionSeq={pageContext?.memberUnionInfo?.unionSeq || 0}
          meetSeq={meetSeq}
          meetMethod={meetMethod}
          open={showPostModal}
          onClose={setShowPostModal}
        />
      )}

      {promotionUserSeq && (
        <PromotionUserInfoModal
          meetSeq={meetSeq}
          promotionUserSeq={promotionUserSeq}
          open={showPromotionUserInfoModal}
          onClose={setShowPromotionUserInfoModal}
        />
      )}
    </>
  );
};

export default VoteManagementTab;

import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  AlertDialog,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFileUploadMutation } from 'apis/common/apis';
import { UnionRegistersTableProps } from 'apis/meet/fixtures';
import { MeetMethods } from 'apis/types/meet';
import Agenda from 'components/pages/meet-management/open/Agenda';
import VoteItem from 'components/pages/meet-management/open/agendas/VoteItem';
import ElectronicRadio from 'components/pages/meet-management/open/meetMethodRadios/ElectronicRadio';
import OnsiteRadio from 'components/pages/meet-management/open/meetMethodRadios/OnsiteRadio';
import OpenConfirmModal from 'components/pages/meet-management/open/modals/OpenConfirmModal';
import RegisterListModal from 'components/pages/meet-management/open/modals/RegisterListModal';
import VotePreviewModal from 'components/pages/meet-management/open/modals/VotePreviewModal';
import { isMissed } from 'components/pages/meet-management/open/utils';
import useFeedback from 'hooks/useFeedback';
import { useUnionRegistersQuery } from 'lim/generalMeetingHistoryDetail/apis';
import {
  CreateMeetBody,
  ElectronicVoteRequest,
  OnsiteVoteRequest,
  useCalculateCostMutation,
  VoteForm,
  VoteType,
} from 'lim/meetOpen/apis';
import { OpenType } from 'lim/type';
import { CustomRouteObject } from 'types/route';

import { validRequiredField } from './utils';

const ACCEPT_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];

type MeetInfoProps = Omit<
  CreateMeetBody,
  'electronicVote' | 'onsiteVote' | 'agendas'
>;

const generateDefaultAgenda = (
  voteForm: VoteForm,
  voteType: VoteType,
  order: number,
  candidates: CreateMeetBody['agendas'][number]['candidates'],
) => {
  return {
    name: '',
    description: '',
    voteForm,
    voteType,
    order,
    // attachFileSeq: NaN,
    selectCount: 0,
    candidates: candidates,
  };
};

const AgendaPage = () => {
  const noticeFileRef = useRef<HTMLDivElement | null>(null);

  const { unionSeq } = useParams();
  const unionId = Number(unionSeq);
  const location = useLocation();
  const navigate = useNavigate();

  const { snackbar, alertDialog: alert, confirmDialog } = useFeedback();

  const { mutate } = useCalculateCostMutation();
  const { mutate: uploadFile } = useFileUploadMutation();
  const { data } = useUnionRegistersQuery(unionId);

  const [alertDialog, setAlertDialog] = useState({
    show: false,
    message: '',
    detail: '',
  });

  const [showRegisterListModal, setShowRegisterListModal] = useState(false);
  const [showVotePreviewModal, setShowVotePreviewModal] = useState(false);
  const [openConfirmModalInfo, setOpenConfirmModalInfo] = useState<{
    show: boolean;
    formData: CreateMeetBody | null;
    cost: number;
  }>({
    show: false,
    formData: null,
    cost: 0,
  });

  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isPasswordLengthError, setIsPasswordLengthError] = useState(false);
  const [fakeIds, setFakeIds] = useState<number[]>([]);

  const [meetInfo, setMeetInfo] = useState<MeetInfoProps>({
    unionSeq: unionId,
    unionRegisterSeqs: [0],
    name: '',
    moverName: '',
    openType: '',
    meetType: 'REGULAR',
    meetMethod: 'ELECTRONIC',
    password: '',
    noticeFileSeq: 0,
  });

  const [electronicVoteInfo, setElectronicVoteInfo] =
    useState<ElectronicVoteRequest>({
      signatureType: '',
      senderName: '',
      contactNo: '',
      description: '',
      voteStartAt: '',
      voteEndAt: '',
    });
  const [onsiteVoteInfo, setOnsiteVoteInfo] = useState<OnsiteVoteRequest>({
    meetAt: '',
    submissionStartAt: '',
    submissionEndAt: '',
    jibunAddress: '',
    roadAddress: '',
    detailAddress: '',
    attendanceRate: 10,
  });
  const [agendas, setAgendas] = useState<CreateMeetBody['agendas']>([]);

  const [formData, setFormData] = useState<CreateMeetBody>();

  const [fileName, setFileName] = useState(
    'PDF, JPG, JPEG, PNG 파일 1개만 첨부',
  );

  const handleInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setMeetInfo({
      ...meetInfo,
      [name]: value,
    });
  };

  const handleRadioChange = ({ target }: { target: EventTarget }) => {
    const meetMethod = (target as HTMLInputElement).value as MeetMethods;

    setMeetInfo({
      ...meetInfo,
      meetMethod,
    });
  };

  const handlePasswordLengthCheck = () => {
    if (meetInfo.password.length < 4) {
      setIsPasswordLengthError(true);
    } else {
      setIsPasswordLengthError(false);
    }
  };

  const handlePasswordCheck = () => {
    if (
      meetInfo.password &&
      confirmPassword &&
      confirmPassword !== meetInfo.password
    )
      return setIsPasswordError(true);

    setIsPasswordError(false);
  };

  const handleOnAgendaChange = (
    formData: CreateMeetBody['agendas'][number],
  ) => {
    const copiedAgenda = [...agendas];
    copiedAgenda[formData.order - 1] = formData;

    setAgendas(copiedAgenda);
  };

  const handleChangeFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { files: originalFiles } = event.currentTarget;
    const files = Array.from(originalFiles ?? []);

    const isAcceptFiles = files.every((file) =>
      ACCEPT_FILE_TYPES.includes(file.type),
    );

    if (!isAcceptFiles) {
      return;
    }

    setFileName(originalFiles?.[0].name || '');

    if (originalFiles)
      uploadFile(
        { divisionCode: 'MEET', files: originalFiles },
        {
          onSuccess: ({ data }) => {
            if (data) {
              setFileName(originalFiles?.[0].name || '');

              const newValue = {
                ...meetInfo,
                noticeFileSeq: data[0].fileSeq,
              };

              setMeetInfo(newValue);
            }
          },
          onError: (error) => {
            snackbar(error?.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
  };

  const handleClickOpenFileFinder = () => {
    if (noticeFileRef.current) {
      noticeFileRef.current.click();
    }
  };

  const handleAddAgenda =
    (candidateCount: number, voteType: VoteType, voteForm: VoteForm) => () => {
      const order = agendas.length;
      const ids = [...fakeIds];
      ids.splice(order, 0, Math.random());

      setFakeIds(ids);
      setAgendas((agenda) => [
        ...agenda,
        generateDefaultAgenda(
          voteForm,
          voteType,
          order + 1,
          Array(candidateCount),
        ),
      ]);
    };

  const handleDeleteAgenda = (index: number) => () => {
    confirmDialog('작성된 내용이 삭제됩니다', {
      onConfirm: () => {
        const copiedAgendas = [...agendas];
        const ids = [...fakeIds];

        copiedAgendas.splice(index, 1);
        const newAgendas = copiedAgendas.map((agenda, i) => ({
          ...agenda,
          order: i + 1,
        }));
        ids.splice(index, 1);

        setFakeIds(ids);
        setAgendas(newAgendas);
      },
    });
  };

  const handlePreview = () => {
    // const hasElectronic = meetInfo.meetMethod.includes('ELECTRONIC');
    // const hasOnsite = meetInfo.meetMethod.includes('ONSITE');

    // const onsiteVote = hasOnsite ? onsiteVoteInfo : undefined;
    // const electronicVote = hasElectronic ? electronicVoteInfo : undefined;
    const formData = {
      ...meetInfo,
      onsiteVote: onsiteVoteInfo,
      electronicVote: electronicVoteInfo,
      agendas,
    };

    if (formData.noticeFileSeq === 0) {
      return alert('총회 공고문 등록해 주세요');
    }

    if (isPasswordLengthError) {
      return alert('비밀번호는 4자 이상 입력하세요.');
    }
    if (isPasswordError) {
      alert('비밀번호를 확인해 주세요');
      return false;
    }

    if (!validRequiredField(formData, setAlertDialog)) return;

    setShowVotePreviewModal(true);
    setFormData(formData);
  };

  const handleOnSubmit = () => {
    setShowVotePreviewModal(false);
    if (formData) {
      mutate(
        {
          unionSeq: unionId,
          meetMethod: meetInfo.meetMethod,
          unionRegisterSeqs: meetInfo.unionRegisterSeqs,
        },
        {
          onSuccess: ({ data }) => {
            setOpenConfirmModalInfo({
              show: true,
              formData,
              cost: data.totalCost,
            });
          },
          onError: (error) => {
            snackbar(error.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    const state = location.state as { ids?: number[]; openType: OpenType };

    if (state?.ids && state?.openType) {
      return setMeetInfo({
        ...meetInfo,
        unionRegisterSeqs: state.ids,
        openType: state.openType,
      });
    }

    navigate(`/unions/${unionId}/meet-management/open`);
  }, []);

  const rows: (UnionRegistersTableProps & { isMissed: boolean })[] =
    useMemo(() => {
      const state = location.state as { ids?: number[]; openType: OpenType };

      return (
        data?.data
          ?.filter((data) => state.ids?.includes(data.unionRegisterSeq))
          .map((data) => {
            const {
              unionRegisterSeq,
              unionRegisterNo,
              positionDescription,
              agent,
              shareType,
              name,
              gender,
              birth,
              mainPhone,
              // localAddresses,
            } = data;

            return {
              id: unionRegisterSeq,
              unionRegisterNo,
              positionDescription,
              agent: agent ? 'O' : '',
              shareType,
              name: name?.name,
              gender: gender || '',
              birth,
              phoneNo: mainPhone?.phoneNo,
              isMissed: isMissed(data),
              // localAddresses: localAddresses[0].zoneRoad,
            };
          }) || []
      );
    }, [data?.data, location.state]);

  return (
    <Stack gap={2}>
      <Stack maxWidth={800} marginBottom={3}>
        <Stepper>
          <Step
            indicator={
              <StepIndicator variant="solid" color="primary">
                <CheckIcon />
              </StepIndicator>
            }
          >
            선거인 명부 확정
          </Step>
          <Step
            indicator={
              <StepIndicator variant="solid" color="primary">
                2
              </StepIndicator>
            }
          >
            안건 입력
          </Step>
          <Step
            indicator={
              <StepIndicator variant="soft" color="primary">
                3
              </StepIndicator>
            }
          >
            총회 개설
          </Step>
        </Stepper>
      </Stack>
      <Stack gap={10}>
        <Stack gap={2}>
          <Typography level="title-lg">총회 정보 입력</Typography>
          <Stack gap={2}>
            <Stack gap={1}>
              <Typography level="body-md">총회 종류*</Typography>

              <RadioGroup
                name="meetType"
                orientation="horizontal"
                value={meetInfo.meetType}
                onChange={handleInfoChange}
              >
                <Radio label="정기총회" value="REGULAR" />
                <Radio label="임시총회" value="SPECIAL" />
              </RadioGroup>
            </Stack>

            <Grid container gap={2} width={832}>
              <Grid xs={5}>
                <FormControl>
                  <FormLabel>총회 명*</FormLabel>
                  <Input
                    name="name"
                    placeholder="총회 명 입력"
                    onChange={handleInfoChange}
                    value={meetInfo.name}
                    validateOptions={{
                      maxLength: 30,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={5}>
                <FormControl>
                  <FormLabel>소집/발의자 명*</FormLabel>
                  <Input
                    name="moverName"
                    placeholder="소집/발의자 명 입력"
                    onChange={handleInfoChange}
                    value={meetInfo.moverName}
                    validateOptions={{
                      maxLength: 10,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={5}>
                <FormControl error={isPasswordLengthError}>
                  <FormLabel>비밀번호*</FormLabel>
                  <Input
                    name="password"
                    type="password"
                    placeholder="총회 비밀번호 입력"
                    onChange={handleInfoChange}
                    onBlur={handlePasswordLengthCheck}
                    value={meetInfo.password}
                    validateOptions={{
                      maxLength: 16,
                    }}
                  />
                  {isPasswordLengthError && (
                    <FormHelperText>
                      <InfoIcon />
                      비밀번호는 4자 이상 입력하세요.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid xs={5}>
                <FormControl error={isPasswordError}>
                  <FormLabel>비밀번호 재입력*</FormLabel>
                  <Input
                    name="confirmPassword"
                    type="password"
                    placeholder="총회 비밀번호 재입력"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={handlePasswordCheck}
                    validateOptions={{
                      maxLength: 16,
                    }}
                  />
                  {isPasswordError && (
                    <FormHelperText>
                      <InfoIcon />
                      비밀번호가 일치하지 않습니다.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid xs={10.3}>
                <>
                  <Input
                    type="file"
                    name="noticeFile"
                    sx={{ display: 'none' }}
                    slotProps={{
                      input: {
                        ref: (element) => (noticeFileRef.current = element),
                      },
                    }}
                    onChange={handleChangeFileInput}
                  />
                  <TextField
                    name="noticeFile"
                    label="총회 공고문 등록*"
                    placeholder={fileName}
                    required
                    readOnly
                    endDecorator={
                      <Button
                        variant="outlined"
                        color="neutral"
                        onClick={handleClickOpenFileFinder}
                      >
                        파일첨부
                      </Button>
                    }
                  />
                </>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography level="title-lg">투표 정보 입력</Typography>
          <Stack gap={3}>
            <Stack gap={1}>
              <Typography level="body-md">총회 방식*</Typography>

              <RadioGroup
                orientation="horizontal"
                name={meetInfo.meetMethod}
                value={meetInfo.meetMethod}
                onChange={handleRadioChange}
              >
                <Radio label="전자투표" value="ELECTRONIC" />
                <Radio label="현장 투표" value="ONSITE" />
                <Radio label="전자‧현장 투표" value="ONSITE_ELECTRONIC" />
              </RadioGroup>
            </Stack>
            <ElectronicRadio
              meetName={meetInfo.name}
              meetMethod={meetInfo.meetMethod}
              meetAt={onsiteVoteInfo.meetAt}
              onChange={setElectronicVoteInfo}
            />
            <OnsiteRadio
              meetMethod={meetInfo.meetMethod}
              voteEndAt={electronicVoteInfo.voteEndAt}
              onChange={setOnsiteVoteInfo}
            />
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Typography level="title-lg">투표 안건 생성</Typography>

          <AccordionGroup variant="outlined" color="neutral">
            {agendas.length > 0 &&
              agendas.map((agenda, i) => {
                const { candidates, voteType, voteForm } = agenda;
                return (
                  <Accordion key={i} defaultExpanded>
                    <AccordionSummary>
                      <Typography level="title-md">
                        제 {i + 1}호 안건
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <VoteItem
                        key={fakeIds[i]}
                        index={i}
                        meetMethod={meetInfo.meetMethod}
                        voteType={voteType}
                        voteForm={voteForm}
                        count={candidates.length}
                        onChange={handleOnAgendaChange}
                        onDelete={handleDeleteAgenda(i)}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </AccordionGroup>

          <Stack gap={2} flexWrap="wrap" flexDirection="row">
            <Agenda
              meetMethod={meetInfo.meetMethod}
              chip="후보 1건"
              title="찬반투표"
              content="후보가 오직 1건이며, 이에 대한 찬성과 반대를 투표 할 때 선택해주세요."
              src="/images/meet-open/agenda_option_1.png"
              onClick={handleAddAgenda(1, 'MONO', 'AGAINST')}
            />
            <Agenda
              meetMethod={meetInfo.meetMethod}
              chip="후보 2건 이상"
              title="찬반투표"
              content="후보가 여러 건이며, 각 후보 별로 찬성과 반대를 투표 할 때 선택해주세요."
              src="/images/meet-open/agenda_option_n.png"
              onClick={handleAddAgenda(2, 'MULTI', 'AGAINST')}
            />
            <Agenda
              meetMethod={meetInfo.meetMethod}
              chip="여러 후보 중 1건 선택"
              title="선택투표"
              content="안건의 후보가 여러 건이며, 후보들 중 1건 만을 선택할 때 선택해주세요."
              src="/images/meet-open/agenda_select_1.png"
              onClick={handleAddAgenda(2, 'MONO', 'SELECT')}
            />
            <Agenda
              meetMethod={meetInfo.meetMethod}
              chip="여러 후보 중 2건 이상 선택"
              title="선택투표"
              content="안건의 후보가 여러 건이며, 후보들 중 2건이상 선택할 때 선택해주세요."
              src="/images/meet-open/agenda_select_n.png"
              onClick={handleAddAgenda(2, 'MULTI', 'SELECT')}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={1} direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => setShowRegisterListModal(true)}
        >
          선거인 명부 보기
        </Button>
        <Button onClick={handlePreview}>총회 개설</Button>
      </Stack>

      {openConfirmModalInfo.formData && (
        <OpenConfirmModal
          formData={openConfirmModalInfo.formData}
          count={meetInfo.unionRegisterSeqs.length}
          cost={openConfirmModalInfo.cost}
          open={openConfirmModalInfo.show}
          onClose={() =>
            setOpenConfirmModalInfo({
              ...openConfirmModalInfo,
              show: false,
            })
          }
        />
      )}

      <RegisterListModal
        rows={rows}
        open={showRegisterListModal}
        onClose={setShowRegisterListModal}
      />

      <VotePreviewModal
        open={showVotePreviewModal}
        agendas={agendas}
        onClose={setShowVotePreviewModal}
        onConfirm={handleOnSubmit}
      />

      <Modal open={alertDialog.show}>
        <AlertDialog
          message={alertDialog.message}
          detail={alertDialog.detail}
          onSubmit={() => setAlertDialog({ ...alertDialog, show: false })}
        />
      </Modal>
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <AgendaPage />,
  handle: {
    getTitle: () => '총회 개설',
  },
};

const agenda = {
  path: 'agenda',
  children: [route],
  handle: {
    getTitle: () => '총회 개설',
  },
};

export default agenda;

import { GridColDef } from '@wooriga/design-system';

import { CommonCode } from 'apis/common/apis';

export interface BaseHandlers {
  getCommonCode: (groupCode: string, code: string) => CommonCode | undefined;
}

export type GridColumnsHandlers<H = object> = BaseHandlers & H;

export interface CreateGridColumnsProps<H> {
  handlers: GridColumnsHandlers<H>;
  columns: (handlers: GridColumnsHandlers<H>) => GridColDef[];
}

const createGridColumns = <H>(props: CreateGridColumnsProps<H>) => {
  const { handlers, columns } = props;

  return { columns: columns(handlers), handlers };
};

export default createGridColumns;

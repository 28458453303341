import JoyCircularProgress from '@mui/joy/CircularProgress';
import type { CircularProgressProps as JoyCircularProgressProps } from '@mui/joy/CircularProgress';
import { ForwardedRef, forwardRef } from 'react';

export type CircularProgressProps = JoyCircularProgressProps;

const CircularProgress = (
  props: CircularProgressProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyCircularProgress {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, CircularProgressProps>(
  CircularProgress,
);

/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  Link,
  FormControl,
  FormLabel,
  Select,
  Option,
  useGridUtils,
  Grid,
  // DateRangePicker,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import MeetsInfoDescription from 'components/pages/common/MeetsInfoDescription';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { useMeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import {
  CertificatesParticipantsParams,
  CertificationType,
  useCertificatesCostCalculateMutation,
  useCertificatesMutation,
  useCertificatesParticipantsQuery,
  useCertificatesSummaryQuery,
} from 'lim/meetCertification/apis';
import { CERTIFICATION_DETAIL_TABLE_COLUMNS } from 'lim/meetCertification/fixtures';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

import RequestModal from '../modals/RequestModal';

interface CertificationTabProps {
  unionSeq: number;
  meetSeq: number;
  type: CertificationType;
}

const defaultParams: CertificatesParticipantsParams = {
  unionRegisterNo: '',
  name: '',
  certificateIssuanceStatus: '',
  searchFrom: '',
  searchTo: '',
};

const CertificationTab = ({
  unionSeq,
  meetSeq,
  type,
}: CertificationTabProps) => {
  const { pageContext } = useLayoutContext();
  const { getGroupCode } = useCommonCodes();
  const codeGroup = useMemo(
    () => getGroupCode('MEET_CERT_ISSUABLE_STATUS'),
    [getGroupCode],
  );

  const { snackbar } = useFeedback();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: `certification-${type}`,
    activeSaveSnapshot: true,
  });

  const [params, setParams] = useState(defaultParams);
  const [certificateIssuanceStatus, setCertificateIssuanceStatus] =
    useState('');

  const meetsDetailQuery = useMeetsDetailQuery(meetSeq);

  const { data, isLoading, isError, error, refetch } =
    useCertificatesParticipantsQuery(meetSeq, type, params);

  const { data: summaryData, refetch: summaryRefetch } =
    useCertificatesSummaryQuery(meetSeq);
  const { mutate: costMutate } = useCertificatesCostCalculateMutation(meetSeq);
  const { mutate } = useCertificatesMutation(meetSeq);

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const [selectedUnionID, setSelectedUnionID] = useState(0);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [totalCost, setTotalCost] = useState(0);

  const handleOnSelect = (value: string) => {
    setCertificateIssuanceStatus(value);
  };

  const handleOnSearch = (params: CertificatesParticipantsParams) => {
    let newValue = {} as CertificatesParticipantsParams;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    setParams({
      ...newValue,
      certificateIssuanceStatus,
    });
  };

  const handleOnNameClick = (id: number) => {
    setSelectedUnionID(id);
    setShowUnionInfoModal(true);
  };

  const handleOnRowSelect = <Model,>(ids: Model) => {
    setSelectedIds(ids as number[]);
  };

  const handleRequest = () => {
    if (!selectedIds.length) {
      snackbar('발급 신청 할 조합원을 선택해주세요.', { color: 'danger' });
      return;
    }

    const unableUnion = rows
      .filter((row) => row.certificateIssuanceStatus !== 'ABLE')
      .some((row) => selectedIds.includes(row.id));

    if (unableUnion) {
      snackbar('신청 불가능한 조합원이 있습니다.', { color: 'danger' });
      return;
    }

    setShowRequestModal(true);
    costMutate(
      { certificateType: type, meetParticipantSeqs: selectedIds },
      {
        onSuccess: (data) => {
          setTotalCost(data.data.totalCost);
        },
        // 비용 계산 실패시
        onError: (e) => {
          snackbar(e.response?.data.message ?? e.message, { color: 'danger' });
        },
      },
    );
  };

  const handleOnSubmit = () => {
    const formData = {
      certificateType: type,
      meetParticipantSeqs: selectedIds,
    };
    mutate(formData, {
      onSuccess: () => {
        snackbar('발급 신청이 완료되었습니다.', { color: 'success' });
        setSelectedIds([]);
        summaryRefetch();
        refetch();
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, { color: 'danger' });
      },
    });
  };

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const { certificateIssuanceStatus } = row;
        const {
          meetParticipantSeq,
          unionRegisterNo,
          agent,
          name,
          electronicVoteStatus,
          unionRegisterSeq,
        } = row.meetParticipant;
        const { receivedAt, openedAt, votedAt } = electronicVoteStatus;
        return {
          id: meetParticipantSeq,
          unionRegisterSeq: unionRegisterSeq,
          unionRegisterNo,
          agent: agent ? 'O' : '',
          name: name?.name,
          receivedAt,
          openedAt,
          votedAt,
          electronicVoteStatus: electronicVoteStatus.voteStatus,
          certificateIssuanceStatus,
        };
      }) || []
    );
  }, [data?.data]);
  const { columns } = useCreateGridColumns({
    columns: CERTIFICATION_DETAIL_TABLE_COLUMNS,
    handlers: {
      onNameClick: handleOnNameClick,
    },
  });

  const certificateType =
    type === 'DIST_CERT'
      ? 'distributionCertificate'
      : type === 'VOTE_CERT'
      ? 'voteCertificate'
      : 'electronicCertificate';

  const getJson = () => {
    if (!summaryData?.data)
      return generateDescriptionsJSON(
        ['신청 가능', '신청중', '발급완료', '다운로드'],
        [],
      );

    const { ableCount, requestCount, completeCount, downloadCount } =
      summaryData.data[certificateType];

    return generateDescriptionsJSON(
      ['신청 가능', '신청중', '발급완료', '다운로드'],
      [ableCount, requestCount, completeCount, downloadCount],
    );
  };

  if (isError) throw error;

  const certificationType =
    type === 'DIST_CERT'
      ? '유통 증명서'
      : type === 'VOTE_CERT'
      ? '투표내역 증명서'
      : '전자문서 증명서';

  return (
    <Stack gap={3}>
      <MeetsInfoDescription meetsDetailQuery={meetsDetailQuery} />

      <Stack gap={1.75}>
        <Typography level="title-lg">발급 현황</Typography>
        <DescriptionsRenderer json={getJson()} columns={2} />
      </Stack>

      <Search
        //@ts-ignore
        defaultValues={defaultParams}
        //@ts-ignore
        onSubmit={handleOnSearch}
        onReset={() => {
          setParams(defaultParams);
          setCertificateIssuanceStatus('');
        }}
      >
        <Grid container gap={2}>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                label="연번"
                name="unionRegisterNo"
                placeholder='숫자 또는 "-"입력'
                validateOptions={{
                  maxLength: 11,
                  regex: /^(?!.*--)[0-9-]*$/,
                }}
                fullWidth
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="이름을 입력하세요."
                label="이름"
                name="name"
                fullWidth
                slotProps={{
                  input: { maxLength: 30 },
                }}
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>발급상태</FormLabel>
                <Select
                  sx={{ width: '100%' }}
                  placeholder="발급대상을 선택하세요."
                  name="certificateIssuanceStatus"
                  value={certificateIssuanceStatus}
                  onChange={(_, value) =>
                    handleOnSelect(value as unknown as string)
                  }
                >
                  <Option value="">전체</Option>

                  {codeGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>

          {/* <Search.Field>
            <DateRangePicker
              label="기간조회"
              name="search"
              sx={{ field: { xs: 12, maxWidth: 200 } }}
            />
          </Search.Field> */}
        </Grid>
      </Search>

      <Stack gap={2}>
        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(data?.pagination?.totalDataCount || 0)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(data?.pagination?.totalElements || 0)}
            </Typography>
          </Typography>
        </Stack>

        <Stack height={442}>
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={columns}
            loading={isLoading}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleOnRowSelect}
          />
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Button component={Link} href=".." variant="outlined" color="neutral">
          목록
        </Button>

        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              exportExcel();
            }}
          >
            내역 다운
          </Button>

          <Button onClick={handleRequest}>발급신청</Button>
        </Stack>
      </Stack>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectedUnionID }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}

      <RequestModal
        requestor={pageContext?.memberUnionInfo?.name || ''}
        certificationType={certificationType}
        count={selectedIds.length}
        cost={totalCost}
        open={showRequestModal}
        onClose={setShowRequestModal}
        onSubmit={handleOnSubmit}
      />
    </Stack>
  );
};

export default CertificationTab;

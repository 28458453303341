import { createContext, SyntheticEvent } from 'react';

export type NavigationBarContextValue = {
  open: boolean;
  toggle: (event: SyntheticEvent) => void;
  permanent: boolean;
};

/**
 * @ignore - internal component.
 */
const NavigationBarContext = createContext<
  NavigationBarContextValue | undefined
>(undefined);

export default NavigationBarContext;

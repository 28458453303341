import { createContext } from 'react';

import { RadioGroupProps } from 'design-system/components/inputs/radio/RadioGroup';

export type RadioGroupContextValue = RadioGroupProps;

/**
 * @ignore - internal component.
 */
const Context = createContext<RadioGroupContextValue | undefined>(undefined);

export default Context;

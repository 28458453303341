import { RealAddress } from 'apis/types/common';
import { Tendency } from 'apis/types/counsel';
import { UnionRegisterAgentInfo } from 'apis/types/union';

export const POST_TABS = [
  { label: '우편발송', path: '/union-management/posts' },
  { label: '내역 관리', path: '/union-management/posts/records' },
];

export const POST_STAPLE_OPTIONS = [
  { label: '아니요', value: false },
  { label: '예', value: true },
];

export const POST_REGISTER_DEFAULT_VALUE = {
  unionRegisterSeq: 0,
  unionRegisterNo: '',
  unionRegisterNoMain: 0,
  unionRegisterNoSub: 0,
  managementNo: 0,
  name: {
    name: '',
    nameClass: '',
  },
  birth: '',
  companyNo: '',
  phoneNo: '',
  memo: '',
  gender: '',
  position: '',
  positionDescription: '',
  shareType: '',
  ownerType: '',
  isRepresentative: false,
  hasMultipleRight: false,
  hasVotingRight: false,
  hasSalesRight: false,
  hasOnePlusOne: false,
  localAddresses: [],
  realAddress: {} as RealAddress,
  agent: {} as UnionRegisterAgentInfo,
  phones: [],
  tendency: '' as Tendency,
  createdAt: '',
  modifiedAt: '',
  modifiedBy: '',
  receivePhoneNo: '',
  etcPhone: '',
  salesCount: 0,
  etcAddress: '',
};

import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  SyntheticEvent,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface FieldProps {
  children: ReactElement;
}

const SelectField = ({ children }: FieldProps) => {
  const { control } = useFormContext();

  return Children.map(children, (child) => {
    if (
      isValidElement<{
        name: string;
      }>(child)
    ) {
      const { name } = child.props;

      if (name)
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange, ...other } }) => {
              const fields = {
                ...other,
                onChange: (_: SyntheticEvent, value: unknown) =>
                  onChange(value),
                value: value ?? '',
              };

              return cloneElement(child, fields);
            }}
          />
        );
    }

    return child;
  });
};

export default SelectField;

import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from 'stores';

const useFeedback = () => {
  const {
    snackbar,
    clearSnackbars,
    alertDialog,
    confirmDialog,
    clearFeedbackDialogs,
    setLoading,
  } = useBoundStore(
    useShallow(
      ({
        snackbar,
        clearSnackbars,
        alertDialog,
        confirmDialog,
        clearFeedbackDialogs,
        setLoading,
      }) => ({
        snackbar,
        clearSnackbars,
        alertDialog,
        confirmDialog,
        clearFeedbackDialogs,
        setLoading,
      }),
    ),
  );

  const clearFeedbacks = useCallback(() => {
    clearSnackbars();
    clearFeedbackDialogs();
  }, [clearSnackbars, clearFeedbackDialogs]);

  return {
    snackbar,
    clearSnackbars,
    alertDialog,
    confirmDialog,
    clearFeedbackDialogs,
    clearFeedbacks,
    setLoading,
  };
};

export default useFeedback;

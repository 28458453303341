import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { ChargeModalSlice, createChargeModalSlice } from 'stores/charge';
import { createFeedbackSlice, FeedbackSlice } from 'stores/feedback';
import { createPageCacheSlice, PageCacheSlice } from 'stores/page';

export type GlobalSlice = PageCacheSlice & FeedbackSlice & ChargeModalSlice;

export const useBoundStore = create<GlobalSlice>()(
  persist(
    (...a) => ({
      ...createPageCacheSlice(...a),
      ...createFeedbackSlice(...a),
      ...createChargeModalSlice(...a),
    }),
    {
      name: 'wooriga-page',
      partialize: (state) => ({ pages: state.pageCaches }),
    },
  ),
);

import {
  GridExcelExportOptions,
  gridFilteredSortedRowIdsSelector,
  GridInitialState,
  GridPaginationModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import * as _ from 'lodash-es';
import { useCallback, useLayoutEffect, useState } from 'react';

// import { DataGridProps } from 'design-system/components/dataGrid/DataGrid';

export interface UseGridUtilsProps {
  key: string;
  activeSaveSnapshot?: boolean;
  // autosizeOptions?: DataGridProps['autosizeOptions'];
}

const useGridUtils = (props: UseGridUtilsProps) => {
  const {
    key,
    activeSaveSnapshot,
    // autosizeOptions
  } = props;

  const localStorageKey = `data-grid-state-${key}`;

  const datagridApiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialState>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const exportExcel = useCallback(
    (options?: GridExcelExportOptions) => {
      datagridApiRef.current.exportDataAsExcel(options);
    },
    [datagridApiRef],
  );

  const getExcelData = useCallback(<V = unknown>() => {
    if (!datagridApiRef.current) return { headers: [], rows: [] };

    // Select rows and columns
    const filteredSortedRowIds =
      gridFilteredSortedRowIdsSelector(datagridApiRef);
    const visibleColumns = datagridApiRef.current
      .getVisibleColumns()
      .filter((col) => col.field !== '__check__');
    const visibleColumnsField = visibleColumns.map((col) => col.field);
    const headers: string[] = visibleColumns.map((col) => col.headerName || '');
    const rows = [
      ...filteredSortedRowIds.map((id) => {
        return visibleColumnsField.map((field) => {
          const cell = datagridApiRef.current.getCellParams(id, field);
          const value =
            typeof cell.value === 'boolean'
              ? cell.value
                ? 'O'
                : ''
              : (cell.value as V);
          return _.isArray(value)
            ? value.map((v) =>
                typeof v.value === 'boolean' ? (v.value ? 'O' : '') : v.value,
              )
            : value;
        });
      }),
    ];

    return { headers, rows };
  }, [datagridApiRef]);

  // const autosizeColumns = useCallback(
  //   (options?: DataGridProps['autosizeOptions']) => {
  //     datagridApiRef.current.autosizeColumns({
  //       ...autosizeOptions,
  //       ...options,
  //     });
  //   },
  //   [autosizeOptions, datagridApiRef],
  // );

  const saveSnapshot = useCallback(() => {
    if (
      activeSaveSnapshot &&
      datagridApiRef?.current?.exportState &&
      localStorage
    ) {
      const currentState = datagridApiRef.current.exportState();
      localStorage.setItem(localStorageKey, JSON.stringify(currentState));
    }
  }, [datagridApiRef, localStorageKey, activeSaveSnapshot]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage.getItem(localStorageKey);
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    );

    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [localStorageKey, saveSnapshot]);

  return {
    datagridApiRef,
    initialState,
    // autosizeColumns,
    saveSnapshot,
    exportExcel,
    getExcelData,
    paginationModel,
    onPaginationModelChange: setPaginationModel,
  };
};

export default useGridUtils;

import { styled } from '@mui/joy';
import useControlled from '@mui/utils/useControlled';
import { ForwardedRef, forwardRef, useMemo } from 'react';

import PanelContext from 'design-system/components/surfaces/panels/PanelContext';
import Sheet, { SheetProps } from 'design-system/components/surfaces/Sheet';

export type PanelSlot = 'root';

export const panelClasses = {
  root: 'JoyPanel-root',
};

export interface PanelProps extends SheetProps {
  open?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
}

export type PanelOwnerState = PanelProps;

const Panel = (props: PanelProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { open: openProp, defaultOpen, ...other } = props;

  const [open, setOpen] = useControlled({
    controlled: openProp,
    default: defaultOpen,
    name: 'Panel',
    state: 'open',
  });

  const contextValue = useMemo(
    () => ({ open, setOpen, ...other }),
    [open, other, setOpen],
  );

  return (
    <PanelContext.Provider value={contextValue}>
      {open && <PanelRoot {...other} ref={ref} />}
    </PanelContext.Provider>
  );
};

const PanelRoot = styled(Sheet, {
  name: 'JoyPanel',
  slot: 'root',
})`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 1rem;
`;

export default forwardRef<HTMLDivElement, PanelProps>(Panel);

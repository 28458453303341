import { useMutation } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { RegistrationAddress } from 'apis/types/common';
import { ApiError, ApiResponseData } from 'types/api';

export interface FindRegistrationAddressesParams {
  address: string;
}

export type FindRegistrationAddressesReturnData = RegistrationAddress[];

export const useFindRegistrationAddressesMutation = () =>
  useMutation<
    ApiResponseData<FindRegistrationAddressesReturnData>,
    ApiError,
    FindRegistrationAddressesParams
  >({
    mutationFn: async (body) => {
      const { data } = await axiosInstance.post(
        '/find/registration-addresses',
        body,
      );

      return data;
    },
  });

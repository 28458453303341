import JoySkeleton from '@mui/joy/Skeleton';
import type { SkeletonProps as JoySkeletonProps } from '@mui/joy/Skeleton';
import { ForwardedRef, forwardRef } from 'react';

export type SkeletonProps = JoySkeletonProps;

const Skeleton = (props: SkeletonProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoySkeleton {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, SkeletonProps>(Skeleton);

import JoyBadge from '@mui/joy/Badge';
import type { BadgeProps as JoyBadgeProps } from '@mui/joy/Badge';
import { ForwardedRef, forwardRef } from 'react';

export type BadgeProps = JoyBadgeProps;

const Badge = (props: BadgeProps, ref: ForwardedRef<HTMLSpanElement>) => {
  return <JoyBadge {...props} ref={ref} />;
};

export default forwardRef<HTMLSpanElement, BadgeProps>(Badge);

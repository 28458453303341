import JoyAccordionGroup from '@mui/joy/AccordionGroup';
import type { AccordionGroupProps as JoyAccordionGroupProps } from '@mui/joy/AccordionGroup';
import {
  Children,
  ForwardedRef,
  cloneElement,
  forwardRef,
  isValidElement,
} from 'react';

import { AccordionProps } from 'design-system/components/surfaces/accordion/Accordion';

export type AccordionGroupProps = JoyAccordionGroupProps;

const AccordionGroup = (
  props: AccordionGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { color, variant, children } = props;

  return (
    <JoyAccordionGroup {...props} ref={ref}>
      {Children.map(children, (child) => {
        if (isValidElement<AccordionProps>(child) && variant === 'solid') {
          return cloneElement(child, {
            color,
            variant,
          });
        }

        return child;
      })}
    </JoyAccordionGroup>
  );
};

export default forwardRef<HTMLDivElement, AccordionGroupProps>(AccordionGroup);

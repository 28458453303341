import { ColorPaletteProp, styled, VariantProp } from '@mui/joy';
import { ForwardedRef, forwardRef, PropsWithChildren, ReactNode } from 'react';

export type CalloutSlot =
  | 'root'
  | 'container'
  | 'title'
  | 'content'
  | 'start-decorator'
  | 'end-decorator';
export type CalloutOwnerState = CalloutProps;

export interface CalloutProps extends PropsWithChildren {
  variant?: VariantProp;
  color?: ColorPaletteProp;
  size?: 'sm' | 'md' | 'lg';
  startDecorator?: ReactNode;
  endDecorator?: ReactNode;
}

const Callout = (props: CalloutProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    variant,
    color,
    size,
    startDecorator,
    endDecorator,
    children,
    ...other
  } = props;

  const ownerState = { variant, color, size };

  return (
    <CalloutRoot {...other} ownerState={ownerState} ref={ref}>
      {startDecorator && (
        <CalloutStartDecorator ownerState={ownerState}>
          {startDecorator}
        </CalloutStartDecorator>
      )}

      <CalloutContainer>{children}</CalloutContainer>

      {endDecorator && (
        <CalloutEndDecorator ownerState={ownerState}>
          {endDecorator}
        </CalloutEndDecorator>
      )}
    </CalloutRoot>
  );
};

const CalloutRoot = styled('div', {
  name: 'JoyCallout',
  slot: 'root',
  overridesResolver: (props, styles) => styles.root,
})<{ ownerState: CalloutOwnerState }>(
  ({
    theme,
    ownerState: { variant = 'outlined', size = 'md', color = 'neutral' },
  }) => ({
    '--Callout-radius': theme.vars.radius.sm,
    '--Callout-decoratorChildRadius':
      'max((var(--Callout-radius) - var(--variant-borderWidth, 0px)) - var(--Callout-padding), min(var(--Callout-padding) + var(--variant-borderWidth, 0px), var(--Callout-radius) / 2))',
    '--Button-minHeight': 'var(--Callout-decoratorChildHeight)',
    '--IconButton-size': 'var(--Callout-decoratorChildHeight)',
    '--Button-radius': 'var(--Callout-decoratorChildRadius)',
    '--IconButton-radius': 'var(--Callout-decoratorChildRadius)',
    '--Icon-color': 'currentColor',
    '--Callout-titleColor': 'currentColor',
    ...(size === 'sm' && {
      '--Callout-padding': '0.5rem',
      '--Callout-decoratorChildHeight': '1.5rem',
      '--Icon-fontSize': theme.vars.fontSize.xl,
      gap: '0.5rem',
    }),
    ...(size === 'md' && {
      '--Callout-padding': '0.75rem',
      '--Callout-decoratorChildHeight': '2rem',
      '--Icon-fontSize': theme.vars.fontSize.xl,
      gap: '0.625rem',
    }),
    ...(size === 'lg' && {
      '--Callout-padding': '1rem',
      '--Callout-decoratorChildHeight': '2.375rem',
      '--Icon-fontSize': theme.vars.fontSize.xl2,
      gap: '0.875rem',
    }),
    ...(variant === 'solid' && {
      '--Callout-contentColor': theme.palette.neutral.lightChannel,
    }),
    backgroundColor: theme.vars.palette.background.surface,
    display: 'flex',
    position: 'relative',
    padding: `var(--Callout-padding)`,
    borderRadius: 'var(--Callout-radius)',
    ...theme.variants[variant][color],
  }),
);

const CalloutContainer = styled('div', {
  name: 'JoyCallout',
  slot: 'container',
})`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CalloutStartDecorator = styled('span', {
  name: 'JoyCallout',
  slot: 'start-decorator',
  overridesResolver: (props, styles) => styles.startDecorator,
})<{ ownerState: CalloutOwnerState }>({
  display: 'inherit',
  flex: 'none',
});

const CalloutEndDecorator = styled('span', {
  name: 'JoyCallout',
  slot: 'end-decorator',
  overridesResolver: (props, styles) => styles.endDecorator,
})<{ ownerState: CalloutOwnerState }>({
  display: 'inherit',
  flex: 'none',
  marginLeft: 'auto',
});

export default forwardRef<HTMLDivElement, CalloutProps>(Callout);

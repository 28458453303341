import { styled } from '@mui/joy';
import { ForwardedRef, forwardRef } from 'react';

import Stack, { StackProps } from 'design-system/components/layout/Stack';

export type PanelActionsSlot = 'root';

export const PanelActionsClasses = {
  root: 'JoyPanelActions-root',
};

export interface PanelActionsProps extends StackProps {}

export type PanelActionsOwnerState = PanelActionsProps;

const PanelActions = (
  props: PanelActionsProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { ...other } = props;

  return <PanelActionsRoot flexDirection="row" gap={1} {...other} ref={ref} />;
};

const PanelActionsRoot = styled(Stack, {
  name: 'JoyPanelActions',
  slot: 'root',
})``;

export default forwardRef<HTMLDivElement, PanelActionsProps>(PanelActions);

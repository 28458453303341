import { Button, CircularProgress, Stack } from '@wooriga/design-system';
import { useCallback, useEffect, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';
import { useCreatePostDocumentMutation } from 'apis/post/apis';
import PostDocumentNameModal from 'components/pages/posts/modal/PostDocumentNameModal';
import useFeedback from 'hooks/useFeedback';
import usePrevious from 'hooks/usePrevious';
import useStepperDialog from 'hooks/useStepperDialog';

export interface PostDocumentPreviewStepProps {
  unionSeq: number;
}

const PostDocumentPreviewStep = (props: PostDocumentPreviewStepProps) => {
  const { unionSeq } = props;

  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [src, setSrc] = useState('');

  const { values, PreviousButton, NextButton } = useStepperDialog();

  const { mutate: downloadFile, isPending } = useFileDownloadMutation();
  const { mutate: createPostDocument, isPending: isSubmitLoading } =
    useCreatePostDocumentMutation();

  const previousFileSeq = usePrevious(values.fileSeq);
  const { snackbar } = useFeedback();

  const getPostDocumentUrl = useCallback(() => {
    if (!values.fileSeq || previousFileSeq === values.fileSeq) {
      return;
    }

    downloadFile(
      { fileSeq: values.fileSeq },
      {
        onSuccess: (data) => {
          if (!data) {
            return;
          }

          const url = URL.createObjectURL(
            new Blob([data], { type: 'application/pdf' }),
          );
          setSrc(url);
        },
      },
    );
  }, [values.fileSeq, previousFileSeq, downloadFile]);

  const handleDocumentNameSubmit = (formName: string) => {
    const request = {
      unionSeq,
      fileSeq: values.fileSeq,
      formName,
    };

    createPostDocument(request, {
      onSuccess: () => {
        snackbar('서식 등록에 성공하였습니다.', {
          color: 'success',
        });
        setIsNameModalOpen(false);
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  useEffect(() => {
    getPostDocumentUrl();
  }, [getPostDocumentUrl]);

  return (
    <>
      <Stack minWidth={1080} gap={2}>
        <Stack
          width="100%"
          minHeight={580}
          justifyContent="center"
          alignItems="center"
        >
          {isPending ? (
            <CircularProgress />
          ) : (
            <iframe
              title="우편발송 미리보기"
              src={src}
              style={{ width: '100%', height: '100%', flex: 1 }}
            />
          )}
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setIsNameModalOpen(true)}
          >
            서식저장
          </Button>

          <Stack direction="row" gap={1}>
            <PreviousButton />
            <NextButton />
          </Stack>
        </Stack>
      </Stack>

      {isNameModalOpen && (
        <PostDocumentNameModal
          open={isNameModalOpen}
          isSubmitLoading={isSubmitLoading}
          onClose={setIsNameModalOpen}
          onSubmit={handleDocumentNameSubmit}
        />
      )}
    </>
  );
};

export default PostDocumentPreviewStep;

// LOCATION_NUMBER : 지역 전화번호
// MOBILE_NUMBER : 휴대 전화번호
// VOIP_NUMBER : 인터넷 전화번호(070)
// CORPORATE_NUMBER : 기업대표 및 서비스 전화번호(15XX)
// NATION_WIDE_NUMBER : 전국대표 전화번호(050X)

export const LOCATION_NUMBER_REGEX = /^(0\d{1,2})(\d{3,4})(\d{4})$/;
export const MOBILE_NUMBER_REGEX = /^(01[016789])(\d{3,4})(\d{4})$/;
export const VOIP_NUMBER_REGEX = /^(070)(\d{3,4})(\d{4})$/;
export const CORPORATE_NUMBER_REGEX = /^(15|16|18)(\d{2})(\d{4})(\d{4})$/;
export const NATION_WIDE_NUMBER_REGEX = /^(050)(\d{3,4})(\d{4})$/;

export const formatTelephone = (telephone: string | undefined) => {
  if (!telephone) {
    return telephone;
  }

  const isLocationNumber = LOCATION_NUMBER_REGEX.test(telephone);
  const isMobileNumber = MOBILE_NUMBER_REGEX.test(telephone);
  const isVoipNumber = VOIP_NUMBER_REGEX.test(telephone);
  const isCorporateNumber = CORPORATE_NUMBER_REGEX.test(telephone);
  const isNationWideNumber = NATION_WIDE_NUMBER_REGEX.test(telephone);

  if (isLocationNumber) {
    return telephone.replace(LOCATION_NUMBER_REGEX, '$1-$2-$3');
  }

  if (isMobileNumber) {
    return telephone.replace(MOBILE_NUMBER_REGEX, '$1-$2-$3');
  }

  if (isVoipNumber) {
    return telephone.replace(VOIP_NUMBER_REGEX, '$1-$2-$3');
  }

  if (isCorporateNumber) {
    return telephone.replace(CORPORATE_NUMBER_REGEX, '$1$2-$3-$4');
  }

  if (isNationWideNumber) {
    return telephone.replace(NATION_WIDE_NUMBER_REGEX, '$1-$2-$3');
  }

  return telephone;
};

import {
  Button,
  DataGrid,
  // DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import CommonCode from 'components/CommonCode';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import ResendModal from 'components/pages/meet-management/history/detail/tabs/VoteManagementTab/ResendModal';
import useFeedback from 'hooks/useFeedback';
import {
  useElectronicVoteCostCalculateMutate,
  useElectronicVoteHistoriesQuery,
  useElectronicVoteResendMutate,
  useMeetParticipantDetailQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import { SENDING_HISTORY_COLUMNS } from 'lim/generalMeetingHistoryDetail/fixtures';
import { formatBirth, formatDateTime, formatTelephone } from 'utils/format';

import { SendingHistoryModalProps } from './type';

const SendingHistoryModal = ({
  meetSeq,
  meetStatus,
  meetParticipantSeq,

  open,
  onClose,
  onSuccess,
}: SendingHistoryModalProps) => {
  const { snackbar } = useFeedback();
  const { data } = useMeetParticipantDetailQuery(meetSeq, meetParticipantSeq);
  const { mutate: mutate } = useElectronicVoteCostCalculateMutate(meetSeq);
  const { mutate: resendMutate, isPending: isResendPending } =
    useElectronicVoteResendMutate(meetSeq);

  const { data: voteHistory } = useElectronicVoteHistoriesQuery(
    meetSeq,
    meetParticipantSeq,
  );

  const [showResendModal, setShowResendModal] = useState(false);
  const [cost, setCost] = useState(0);

  const handleShowResendModal = () => {
    mutate(
      {
        meetParticipantSeqs: [meetParticipantSeq],
      },
      {
        onSuccess: ({ data }) => {
          setShowResendModal(true);
          setCost(data.totalCost);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
    setShowResendModal(true);
  };

  const handleResend = () => {
    if (isResendPending) {
      return;
    }
    resendMutate(
      {
        meetParticipantSeqs: [meetParticipantSeq],
      },
      {
        onSuccess: () => {
          setShowResendModal(false);
          onClose(false);
          onSuccess();
          snackbar(
            '발송이 완료되었습니다. 발송은 상황에 따라 5분 정도 소요될 수 있습니다.',
            {
              color: 'success',
            },
          );
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const rows = useMemo(
    () =>
      voteHistory?.data?.map((history, i) => ({
        id: i,
        ...history,
      })) || [],
    [voteHistory?.data],
  );

  if (!data?.data) return;

  const { unionRegisterNo, name, birth, phoneNo, agent, electronicVoteStatus } =
    data.data;

  const { progressStatus, sentAt, receivedAt, openedAt, votedAt } =
    electronicVoteStatus;

  const unionJSON = generateDescriptionsJSON(
    ['연번', '조합원 이름', '생년월일', '연락처'],
    [unionRegisterNo, name?.name, formatBirth(birth), formatTelephone(phoneNo)],
  );

  const agentJSON = generateDescriptionsJSON(
    ['대리인 이름', '생년월일', '연락처'],
    [
      agent?.name?.name,
      formatBirth(agent?.birth),
      formatTelephone(agent?.phoneNo),
    ],
  );

  const sendingHistoryJSON = generateDescriptionsJSON(
    ['진행상태', '발송일시', '수신일시', '열람일시', '투표일시'],
    [
      <CommonCode
        key={progressStatus}
        groupCode="MEET_PARTICIPANT_VOTE_STATUS"
        code={progressStatus}
      />,
      formatDateTime(sentAt, 'yyyy-MM-dd HH:mm'),
      formatDateTime(receivedAt, 'yyyy-MM-dd HH:mm'),
      formatDateTime(openedAt, 'yyyy-MM-dd HH:mm'),
      formatDateTime(votedAt, 'yyyy-MM-dd HH:mm'),
    ],
  );

  const isProcessBlock =
    meetStatus === 'PROCESS' &&
    (progressStatus === 'OPEN_FAIL' || progressStatus === 'FAIL');

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog minWidth={640}>
          <DialogTitle>전자투표 발송 내역</DialogTitle>
          <DialogContent>
            <Typography level="title-md" marginTop={4}>
              조합원 정보
            </Typography>
            <DescriptionsRenderer columns={1} json={unionJSON} />

            <Typography level="title-md" marginTop={4}>
              대리인 정보
            </Typography>
            <DescriptionsRenderer columns={1} json={agentJSON} />

            <Typography level="title-md" marginTop={4}>
              전자투표 신규발송
            </Typography>
            <DescriptionsRenderer columns={1} json={sendingHistoryJSON} />

            <Typography level="title-md" marginTop={4}>
              전자투표 발송 내역
            </Typography>
            <DataGrid
              rows={rows}
              columns={SENDING_HISTORY_COLUMNS}
              // loading={isLoading}
              rowHeight={38}
              disableRowSelectionOnClick
            />
          </DialogContent>

          <DialogActions>
            {(isProcessBlock ||
              (meetStatus !== 'PROCESS' && progressStatus === 'NONE')) && (
              <Button fullWidth onClick={handleShowResendModal}>
                재발송
              </Button>
            )}

            <Button
              color="neutral"
              variant="outlined"
              fullWidth
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <ResendModal
        open={showResendModal}
        cost={cost}
        onClose={setShowResendModal}
        onSubmit={handleResend}
        isResendPending={isResendPending}
      />
    </>
  );
};

export default SendingHistoryModal;

import JoyCardCover from '@mui/joy/CardCover';
import type { CardCoverProps as JoyCardCoverProps } from '@mui/joy/CardCover';
import { ForwardedRef, forwardRef } from 'react';

export type CardCoverProps = JoyCardCoverProps;

const CardCover = (
  props: CardCoverProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyCardCover {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, CardCoverProps>(CardCover);

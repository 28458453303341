import JoyStepper from '@mui/joy/Stepper';
import type { StepperProps as JoyStepperProps } from '@mui/joy/Stepper';
import { ForwardedRef, forwardRef } from 'react';

export type StepperProps = JoyStepperProps;

const Stepper = (props: StepperProps, ref: ForwardedRef<HTMLOListElement>) => {
  return <JoyStepper {...props} ref={ref} />;
};

export default forwardRef<HTMLOListElement, StepperProps>(Stepper);

import { styled } from '@mui/joy';
import { ForwardedRef, forwardRef, ForwardRefExoticComponent } from 'react';

import Stack, { StackProps } from 'design-system/components/layout/Stack';

export type NavigationBarFooterSlot = 'root';

export const NavigationBarFooterClasses = {
  root: 'JoyNavigationBarFooter-root',
};

export interface NavigationBarFooterProps extends StackProps {}

interface NavigationBarFooterComponent
  extends ForwardRefExoticComponent<NavigationBarFooterProps> {
  propTypes?: never;
  muiName?: string;
}

const NavigationBarFooter = forwardRef(
  (props: NavigationBarFooterProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...other } = props;

    return (
      <JoyNavigationBarFooterRoot
        ref={ref}
        className={NavigationBarFooterClasses.root}
        {...other}
      >
        {children}
      </JoyNavigationBarFooterRoot>
    );
  },
) as NavigationBarFooterComponent;

const JoyNavigationBarFooterRoot = styled(Stack, {
  name: 'JoyNavigationBarFooter',
  slot: 'root',
})<NavigationBarFooterProps>`
  display: flex;
  flex: 0 0 auto;
  padding: 16px 12px;
`;

NavigationBarFooter.displayName = 'NavigationBarFooter';
NavigationBarFooter.muiName = 'NavigationBarFooter';

export default NavigationBarFooter;

import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Callout,
  Checkbox,
  Descriptions,
  DescriptionsItem,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  CreateMessageBody,
  useCreateMessageBalanceMutation,
} from 'apis/message/apis';
import CommonCode from 'components/CommonCode';
import useFeedback from 'hooks/useFeedback';
import useStepperDialog from 'hooks/useStepperDialog';
import { commaizeNumber, formatDateTime, formatTelephone } from 'utils/format';

export interface MessageBalanceStepProps {
  unionSeq: number;
  onSubmit?: (data: CreateMessageBody) => void;
  isSendMessagePending?: boolean;
}

const MessageBalanceStep = (props: MessageBalanceStepProps) => {
  const { unionSeq, onSubmit, isSendMessagePending } = props;

  const [isAgree, setIsAgree] = useState(false);

  const { snackbar } = useFeedback();
  const { values, PreviousButton, NextButton } =
    useStepperDialog<CreateMessageBody>();
  const { data: balance, mutate: calculateMessageBalance } =
    useCreateMessageBalanceMutation();

  const { outgoingPhoneNo, sendType, scheduledAt, recipients } = values;

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAgree(event.currentTarget.checked);
  };

  const handleSubmit = () => {
    onSubmit?.(values as CreateMessageBody);
  };

  useEffect(() => {
    const request = { ...values, unionSeq };

    calculateMessageBalance(request, {
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  }, [unionSeq, values, snackbar, calculateMessageBalance]);

  return (
    <Stack gap={4} maxWidth={520}>
      <Callout variant="outlined" color="warning" startDecorator={<InfoIcon />}>
        조합 업무와 관련 없는 영리목적의 광고성 메세지를 보내지 않도록 유의해
        주세요. 문자 발송을 원하시면 “문자발송”을 선택해 주세요.
      </Callout>

      <Stack direction="column" alignItems="center" minHeight={260} gap={2.5}>
        <Box width="100%">
          <Descriptions columns={1}>
            <DescriptionsItem label="발신번호">
              {formatTelephone(outgoingPhoneNo)}
            </DescriptionsItem>
            <DescriptionsItem label="문자유형">
              <CommonCode groupCode="SMS_SEND_TYPE" code={sendType ?? ''} />
            </DescriptionsItem>
            {sendType === 'SCHEDULE' && (
              <DescriptionsItem label="발송일시">
                {scheduledAt &&
                  formatDateTime(scheduledAt, 'yyyy-MM-dd HH:mm:ss')}
              </DescriptionsItem>
            )}
            <DescriptionsItem label="수신자 수">
              {commaizeNumber((recipients ?? []).length || 0)} 명
            </DescriptionsItem>
            <DescriptionsItem label="비용">
              {commaizeNumber((balance?.data ?? {}).totalCost || 0)}원
            </DescriptionsItem>
          </Descriptions>
        </Box>

        <Typography level="body-md" color="neutral" textAlign="center">
          문자 내용 및 수신자 정보에 오류가 없는지 다시 한번 확인해주세요.
          <br />
          문자상의 모든 내용은 작성자에게 책임이 있으며,
          <br />
          발송이 완료된 문자는 취소 및 환불이 불가능합니다.
        </Typography>

        <Checkbox
          variant="outlined"
          color="primary"
          label="위 사항을 모두 확인하였습니다."
          checked={isAgree}
          disabled={isSendMessagePending}
          onChange={handleChecked}
        />
      </Stack>

      <Stack direction="row" justifyContent="flex-end" gap={1}>
        <PreviousButton disabled={isSendMessagePending} />
        <NextButton
          disabled={!isAgree}
          onClick={handleSubmit}
          loading={isSendMessagePending}
        />
      </Stack>
    </Stack>
  );
};

export default MessageBalanceStep;

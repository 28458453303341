import { StateCreator } from 'zustand';

export type PageCacheSlice = {
  pageCaches: Map<string, string>;
  getPageCache: (url: string) => string;
  updatePageCache: (url: string, data: Record<string, unknown>) => void;
  deletePageCache: (url: string) => void;
};

export const createPageCacheSlice: StateCreator<
  PageCacheSlice,
  [],
  [],
  PageCacheSlice
> = (set, get) => ({
  pageCaches: new Map(),

  getPageCache: (url) => JSON.parse(get().pageCaches.get(url) ?? ''),

  updatePageCache: (url, data) =>
    set((state) => {
      const previousCaches = [...get().pageCaches];
      const cloneCaches = new Map(previousCaches);

      cloneCaches.set(url, JSON.stringify(data));

      return { ...state, pageCahces: cloneCaches };
    }),
  deletePageCache: (url) =>
    set((state) => {
      const previousCaches = [...get().pageCaches];
      const cloneCaches = new Map(previousCaches);

      cloneCaches.delete(url);

      return { ...state, pageCahces: cloneCaches };
    }),
});

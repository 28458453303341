import { ColorPaletteProp, VariantProp } from '@mui/joy';
import { createContext } from 'react';

import { SnackbarProps } from 'design-system/components/feedback/snackbar/Snackbar';

interface CloseSnackbarContextValues {
  onClose: SnackbarProps['onClose'];
  variant?: VariantProp;
  color?: ColorPaletteProp;
  size?: SnackbarProps['size'];
}

const CloseSnackbarContext = createContext<
  CloseSnackbarContextValues | undefined
>(undefined);

export default CloseSnackbarContext;

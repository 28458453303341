import { ForwardedRef, forwardRef } from 'react';
import {
  Link as RouteDomLink,
  LinkProps as RouteDomLinkProps,
  useInRouterContext,
} from 'react-router-dom';

export interface ILinkBehaviorProps extends Omit<RouteDomLinkProps, 'to'> {
  href: RouteDomLinkProps['to'] & HTMLAnchorElement['href'];
}

const LinkBehavior = (
  props: ILinkBehaviorProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) => {
  const isInRouter = useInRouterContext();

  const { href, ...other } = props;

  if (!isInRouter) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} />;
  }

  return (
    <RouteDomLink ref={ref} to={href as RouteDomLinkProps['to']} {...other} />
  );
};

export default forwardRef<HTMLAnchorElement, ILinkBehaviorProps>(LinkBehavior);

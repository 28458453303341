import type {
  BaseSingleInputFieldProps,
  FieldSection,
  TimeValidationError,
  UseTimeFieldProps,
} from '@mui/x-date-pickers-pro';
import {
  useClearableField,
  unstable_useTimeField as useTimeField,
} from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef } from 'react';

import JoyField, {
  JoyFieldProps,
} from 'design-system/components/pickers/JoyField';

export interface TimeFieldProps
  extends UseTimeFieldProps<Date, false>,
    BaseSingleInputFieldProps<
      Date | null,
      Date,
      FieldSection,
      false,
      TimeValidationError
    > {
  variant?: JoyFieldProps['variant'];
  color?: JoyFieldProps['color'];
  size?: JoyFieldProps['size'];
  helperText?: JoyFieldProps['helperText'];
  fullWidth?: JoyFieldProps['fullWidth'];
}

const TimeField = (
  props: TimeFieldProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { slots, slotProps, ...textFieldProps } = props;

  const fieldResponse = useTimeField<Date, false, typeof textFieldProps>({
    ...textFieldProps,
    enableAccessibleFieldDOMStructure: false,
  });

  /* If you don't need a clear button, you can skip the use of this hook */
  const processedFieldProps = useClearableField({
    ...fieldResponse,
    slots,
    slotProps,
  });

  return <JoyField ref={ref} {...processedFieldProps} />;
};

export default forwardRef<HTMLDivElement, TimeFieldProps>(TimeField);

import {
  // DefinedUseQueryResult,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
// import { generateInitRealData } from 'lim/_api';
import { PostAddress, RealAddress } from 'apis/types/common';
import { PromotionUserResponse } from 'lim/type';
import {
  MeetParticipantResponse,
  OnsiteVoteStatusResponse,
} from 'lim/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

import { Agent } from '../apis';

// import { participantDetailData } from './json';

export interface Name {
  name: string;
  nameClass: string;
}

export interface ElectronicVoteStatus {
  sendStatus: string;
  voteStatus: string;
  receivedAt: string;
  openedAt: string;
  votedAt: string;
  resendCount: number;
}

export interface ParticipantDetail {
  meetParticipantSeq: number;
  unionRegisterSeq: number;
  unionRegisterNo: string;
  name: Name;
  birth: string;
  phoneNo: string;
  gender: string;
  realAddress: RealAddress;
  tendency: string;
  position: string;
  shareType: string;
  agent: Agent;
  electronicVoteStatus: ElectronicVoteStatus;
  onsiteVoteStatus: OnsiteVoteStatusResponse;
  promotionUser: PromotionUserResponse;
}

export type MeetsParticipantsDetailQuery = UseQueryResult<
  ApiResponseData<MeetParticipantResponse>,
  AxiosError
>;

export const useMeetsParticipantsDetailQuery = (
  meetSeq: number,
  meetParticipantSeq: number,
): MeetsParticipantsDetailQuery =>
  useQuery({
    queryKey: [`/meets/${meetSeq}/participants/${meetParticipantSeq}`],
    // initialData: generateInitRealData(participantDetailData),
  });

export interface ParticipantDetailEditBody {
  name: string;
  nameClass: string;
  birth?: string;
  companyNo?: string;
  ownerType?: string;
  gender?: string;
  phoneNo: string;
  agent?: {
    name: string;
    nameClass: string;
    birth: string;
    phoneNo: string;
    gender: string;
  };
  postAddress: PostAddress;
  realAddress: RealAddress;
}

export type MeetsParticipantsDetailEditMutation = UseMutationResult<
  ApiResponseData<ParticipantDetail>,
  ApiError,
  ParticipantDetailEditBody
>;

export const useMeetsParticipantsDetailEditMutation = (
  meetSeq: number,
  meetParticipantSeq: number,
): MeetsParticipantsDetailEditMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.put<
        ApiResponseData<ParticipantDetail>
      >(`/meets/${meetSeq}/participants/${meetParticipantSeq}`, formData);

      return data;
    },
  });

export interface ParticipantDetailDeleteMutationBody {
  meetPassword: string;
  deleteReason: string;
}

export type MeetsParticipantsDetailDeleteMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  ParticipantDetailDeleteMutationBody
>;

export const useMeetsParticipantsDetailDeleteMutation = (
  meetSeq: number,
  meetParticipantSeq: number,
): MeetsParticipantsDetailDeleteMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.delete<ApiResponseData>(
        `/meets/${meetSeq}/participants/${meetParticipantSeq}`,
        { data: formData },
      );

      return data;
    },
  });

import { ColorPaletteProp } from '@mui/joy';
import type { DigitalClockProps as XDigitalClockProps } from '@mui/x-date-pickers-pro';
import { DigitalClock as XDigitalClock } from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef } from 'react';

export interface DigitalClockProps extends XDigitalClockProps<Date> {
  color?: ColorPaletteProp;
}

const DigitalClock = (
  props: DigitalClockProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { ...other } = props;

  return <XDigitalClock ref={ref} {...other} />;
};

export default forwardRef<HTMLDivElement, DigitalClockProps>(DigitalClock);

import { useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { PaginationState } from 'hooks/useQueryControls/types';
import {
  convertObject2SearchParams,
  convertSearchParams2Object,
} from 'hooks/useQueryControls/utils';

export interface UseQueryControlsProps<
  SearchParamsType extends FieldValues,
  PaginationParamsType extends PaginationState,
> {
  defaultSearchParams: SearchParamsType;
  initSearchParams: SearchParamsType;
  defaultPaginationState?: PaginationParamsType;
  initPaginationState?: PaginationParamsType;
}

const useQueryControls = <
  SearchParamsType extends FieldValues,
  PaginationParamsType extends PaginationState,
>(
  props: UseQueryControlsProps<SearchParamsType, PaginationParamsType>,
) => {
  const {
    defaultSearchParams,
    initSearchParams,
    defaultPaginationState = { pageSize: 10, page: 0 } as PaginationParamsType,
    initPaginationState = { pageSize: 10, page: 0 } as PaginationParamsType,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchState, setSearchState] =
    useState<SearchParamsType>(defaultSearchParams);
  const [paginationState, setPaginationState] = useState<PaginationParamsType>(
    defaultPaginationState,
  );

  const handleChangeSearchState = useCallback(
    (newSearchState: SearchParamsType) => {
      setSearchState(newSearchState);
      setPaginationState(initPaginationState);

      setSearchParams(
        convertObject2SearchParams({
          ...newSearchState,
          ...initPaginationState,
        }),
      );
    },
    [initPaginationState, setSearchParams],
  );

  const handleChangePaginationState = useCallback(
    (newPaginationState: PaginationParamsType) => {
      setPaginationState(newPaginationState);

      const newPaginationSearchParams = convertObject2SearchParams({
        ...searchState,
        ...newPaginationState,
      });
      setSearchParams(newPaginationSearchParams);
    },
    [searchState, setSearchParams],
  );

  const handleInitState = useCallback(() => {
    setSearchState(initSearchParams);
    setPaginationState(initPaginationState);

    setSearchParams(
      convertObject2SearchParams({
        ...initSearchParams,
        ...initPaginationState,
      }),
    );
  }, [initPaginationState, initSearchParams, setSearchParams]);

  useEffect(() => {
    const { pageSize, page, ...other } =
      convertSearchParams2Object(searchParams);

    const newSearchState = {
      ...defaultSearchParams,
      ...other,
    } as SearchParamsType;
    setSearchState(newSearchState);

    const newPaginationState = {
      pageSize: Number(pageSize) || defaultPaginationState.pageSize,
      page: Number(page) || defaultPaginationState.page,
    } as PaginationParamsType;

    setPaginationState(newPaginationState);
  }, [searchParams]);

  return {
    searchState,
    setSearchState: handleChangeSearchState,
    paginationState,
    setPaginationState: handleChangePaginationState,
    initState: handleInitState,
  };
};

export default useQueryControls;

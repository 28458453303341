import { ReactNode, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

import { LayoutContextValue } from 'hooks/useLayoutContext/types';

const ERROR_MESSAGE_UNSUPPORTED_ELEMENT =
  '현재 Layout에서 지원되지 않는 요소입니다.';

const useLayoutContext = () => {
  const { setSidePanel, ...other } = useOutletContext<LayoutContextValue>();

  const unmountSidePanel = useCallback(() => {
    if (!setSidePanel) throw Error(ERROR_MESSAGE_UNSUPPORTED_ELEMENT);

    setSidePanel(undefined);
  }, [setSidePanel]);

  const mountSidePanel = useCallback(
    (element: ReactNode) => {
      if (!setSidePanel) throw Error(ERROR_MESSAGE_UNSUPPORTED_ELEMENT);

      setSidePanel(element);
    },
    [setSidePanel],
  );

  return { ...other, mountSidePanel, unmountSidePanel };
};

export default useLayoutContext;

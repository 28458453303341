import { createContext } from 'react';

import { CheckboxGroupProps } from 'design-system/components/inputs/checkbox/CheckboxGroup';

export type CheckboxGroupContextValue = CheckboxGroupProps;

/**
 * @ignore - internal component.
 */
const Context = createContext<CheckboxGroupContextValue | undefined>(undefined);

export default Context;

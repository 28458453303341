import JoyAccordionSummary from '@mui/joy/AccordionSummary';
import type { AccordionSummaryProps as JoyAccordionSummaryProps } from '@mui/joy/AccordionSummary';
import { ForwardedRef, forwardRef } from 'react';

export type AccordionSummaryProps = JoyAccordionSummaryProps;

const AccordionSummary = (
  props: AccordionSummaryProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyAccordionSummary {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, AccordionSummaryProps>(
  AccordionSummary,
);

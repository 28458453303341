import JoyTooltip from '@mui/joy/Tooltip';
import type { TooltipProps as JoyTooltipProps } from '@mui/joy/Tooltip';
import { ForwardedRef, forwardRef } from 'react';

export type TooltipProps = JoyTooltipProps;

const Tooltip = (props: TooltipProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyTooltip {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, TooltipProps>(Tooltip);

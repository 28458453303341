import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import {
  AlertDailogResultType,
  AlertDailogType,
  AlertDialog,
  Box,
  Button,
  Callout,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
  ModalProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useFindRegistrationAddressesMutation } from 'apis/common/registration-addresses/apis';
import { REGISTRATION_GRID_COLUMNS } from 'apis/common/registration-addresses/fixtures';
import { RegistrationAddress } from 'apis/types/common';
import AddressModalTrigger, {
  AddressModalTriggerHandles,
} from 'components/pages/posts/AddressModalTrigger';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

export interface LocalAddressConfirmModalProps
  extends Omit<ModalProps, 'onSubmit' | 'onClose' | 'children'> {
  onSubmit?: (data: {
    city: string;
    gu: string;
    dong: string;
    jibun: string;
    zoneRoad: string;
    building: string;
    apartmentDong: string;
    apartmentFloor: string;
    apartmentHosu: string;
    checkStatus: 'Y';
    registrationAddresses: RegistrationAddress[];
  }) => void;
  onClose: (value: boolean) => void;
}

const LocalAddressConfirmModal = (props: LocalAddressConfirmModalProps) => {
  const { onSubmit, onClose, ...rest } = props;

  const triggerRef = useRef<AddressModalTriggerHandles>(null);
  const { mutate, isPending } = useFindRegistrationAddressesMutation();

  const [alertInfo, setAlertInfo] = useState<
    | {
        message: ReactNode;
        detail: ReactNode;
        type: AlertDailogType;
        onSubmit: (result: AlertDailogResultType) => void;
      }
    | undefined
  >(undefined);

  const { columns } = useCreateGridColumns({
    columns: REGISTRATION_GRID_COLUMNS,
  });
  const [rows, setRows] = useState<RegistrationAddress[]>([]);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const {
    handleSubmit: formSubmit,
    control,
    setValue,
    getValues,
    reset,
  } = useForm({});

  const handleClose = useCallback(() => {
    onClose(false);
    reset();
    setRows([]);
    setRowSelectionModel([]);
  }, [onClose, reset]);

  const handleSubmit = useCallback(() => {
    const values = getValues();

    const buildings = [];
    const lands = [];
    values.registrationAddresses.forEach((addr: RegistrationAddress) =>
      addr.registrationType === 'LAND'
        ? lands.push(addr)
        : buildings.push(addr),
    );

    if (buildings.length > 1 || lands.length > 1) {
      return setAlertInfo({
        onSubmit: () => setAlertInfo(undefined),
        type: 'OK',
        message: '알림',
        detail: '토지나 건물(집합건물)은 1건씩 선택 가능합니다.',
      });
    }

    onSubmit?.({
      ...values.address,
      building: values.building,
      detailAddress: values.detailAddress,
      checkStatus: 'Y',
      registrationAddresses: values.registrationAddresses,
    });
    handleClose();
  }, [getValues, handleClose, onSubmit]);

  const handleFetchRegistrationAddresses = formSubmit((data) => {
    const { address, building, detailAddress } = data;

    if (!address) {
      return setAlertInfo({
        onSubmit: () => setAlertInfo(undefined),
        type: 'OK',
        message: '알림',
        detail: '먼저 주소를 검색해주세요.',
      });
    }

    setValue('registrationAddresses', []);

    mutate(
      {
        address: [address.address, building, detailAddress].join(' ').trim(),
      },
      {
        onSuccess: (data) => setRows(data.data || []),
        onError: (data) => console.log(data),
      },
    );
  });

  const handleRowSelectionModelChange = useCallback(
    (newRowSelectionModel: GridRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);

      const rowIds = newRowSelectionModel as string[];
      const selectedRows = rows.filter((row) =>
        rowIds.includes(row.registrationNo),
      );
      setValue('registrationAddresses', selectedRows);
    },
    [rows, setValue],
  );

  return (
    <>
      <Modal onClose={handleClose} {...rest}>
        <ModalDialog sx={{ width: '100%' }} maxWidth="md">
          <DialogTitle>소재지 주소 검토</DialogTitle>

          <DialogContent>
            <Stack gap={2}>
              <Callout
                startDecorator={
                  <Typography
                    startDecorator={<ErrorIcon />}
                    textColor="neutral.500"
                  />
                }
              >
                <Typography>{`주소 검색 후 "등기 정보 가져오기" 버튼을 눌러 소재지의 등기 정보를 가져오세요.`}</Typography>
              </Callout>

              <Stack>
                <Typography
                  fontSize="sm"
                  fontWeight="md"
                  lineHeight="sm"
                  mb={0.75}
                >
                  주소
                </Typography>

                <Stack gap={1}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field: addressField }) => (
                      <>
                        <Stack flexDirection="row" gap={1}>
                          <Input
                            sx={{ flex: 1 }}
                            placeholder="주소"
                            startDecorator={<SearchIcon />}
                            readOnly
                            name={addressField.name}
                            value={addressField.value?.address || ''}
                            onClick={() => triggerRef.current?.openModal()}
                          />

                          <AddressModalTrigger
                            ref={triggerRef}
                            onComplete={(address) => {
                              addressField.onChange({
                                city: address.sido,
                                gu: address.sigungu,
                                dong: address.bname,
                                jibun: address.jibunAddress
                                  .split(' ')
                                  .findLast(
                                    (part) =>
                                      part.match(/\d+-\d+/) ||
                                      part.match(/\d+/),
                                  ),
                                zoneRoad: address.roadname,
                                address: address.address,
                                zipNo: address.zonecode,
                              });

                              setValue('building', address.buildingName);
                              setValue('detailAddress', '');
                            }}
                          >
                            <Button variant="outlined" color="neutral">
                              주소 검색
                            </Button>
                          </AddressModalTrigger>
                        </Stack>
                      </>
                    )}
                  />

                  <Stack flexDirection="row" gap={1}>
                    <Controller
                      name="building"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Input
                          sx={{ flex: 1 }}
                          placeholder="공동주택(건물명) 입력"
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="detailAddress"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <Input
                          sx={{ flex: 1 }}
                          placeholder="상세주소 입력"
                          {...field}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-end' }}
                onClick={handleFetchRegistrationAddresses}
              >
                등기 정보 가져오기
              </Button>

              <Box>
                <Typography
                  fontSize="sm"
                  fontWeight="md"
                  lineHeight="sm"
                  mb={0.75}
                >
                  검색결과
                </Typography>

                <Stack height={280}>
                  <DataGrid
                    loading={isPending}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={handleRowSelectionModelChange}
                    rowSelectionModel={rowSelectionModel}
                    getRowId={(row) => row.registrationNo}
                    rows={rows}
                    columns={columns}
                  />
                </Stack>
              </Box>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleSubmit}>완료</Button>
            <Button onClick={handleClose} variant="outlined" color="neutral">
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal open={!!alertInfo}>
        <AlertDialog
          onSubmit={alertInfo?.onSubmit}
          type={alertInfo?.type}
          message={alertInfo?.message}
          detail={alertInfo?.detail}
        />
      </Modal>
    </>
  );
};

export default LocalAddressConfirmModal;

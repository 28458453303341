import { styled } from '@mui/joy';
import { ForwardedRef, forwardRef } from 'react';

import Box, { BoxProps } from 'design-system/components/layout/Box';

export type PanelContentSlot = 'root';

export const PanelContentClasses = {
  root: 'JoyPanelContent-root',
};

export interface PanelContentProps extends BoxProps {}

export type PanelContentOwnerState = PanelContentProps;

const PanelContent = (
  props: PanelContentProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { ...other } = props;

  return <PanelContentRoot {...other} ref={ref} />;
};

const PanelContentRoot = styled(Box, {
  name: 'JoyPanelContent',
  slot: 'root',
})`
  flex: 1 1;
`;

export default forwardRef<HTMLDivElement, PanelContentProps>(PanelContent);

import { useMemo } from 'react';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';

export interface CommonCodeProps {
  groupCode?: string;
  code?: string;
}

const CommonCode = (props: CommonCodeProps) => {
  const { groupCode, code } = props;
  const { getCode } = useCommonCodes();

  const codeObj = useMemo(
    () => (groupCode && code ? getCode(groupCode, code) : undefined),
    [code, getCode, groupCode],
  );
  return codeObj?.name || code;
};

export default CommonCode;

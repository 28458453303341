import { ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColumnMenuItemProps,
  GridPinnedColumnPosition,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-premium';
import * as _ from 'lodash-es';
import { useCallback, MouseEvent, Fragment } from 'react';

export interface JoyGridColumnMenuPinningItemProps
  extends GridColumnMenuItemProps {}

const JoyGridColumnMenuPinningItem = (
  props: JoyGridColumnMenuPinningItemProps,
) => {
  const { onClick, colDef } = props;

  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const theme = useTheme();

  const pinAllColumn = useCallback(
    (side: GridPinnedColumnPosition) => (event: MouseEvent<HTMLElement>) => {
      const prevPinnedColumns = apiRef.current.getPinnedColumns();

      const addPinnedColumns = _.dropRightWhile(
        apiRef.current.getAllColumns(),
        (column) => colDef.field !== column.field,
      )
        .filter(
          (column) => apiRef.current.isColumnPinned(column.field) !== side,
        )
        .map((column) => column.field);

      const otherSide =
        side === GridPinnedColumnPosition.RIGHT
          ? GridPinnedColumnPosition.LEFT
          : GridPinnedColumnPosition.RIGHT;

      const newPinnedColumns = {
        [side]: [...(prevPinnedColumns[side] || []), ...addPinnedColumns],
        [otherSide]: prevPinnedColumns[otherSide] || [],
      };

      apiRef.current.setPinnedColumns(newPinnedColumns);
      onClick(event);
    },
    [apiRef, colDef.field, onClick],
  );

  const pinColumn = useCallback(
    (side: GridPinnedColumnPosition) => (event: MouseEvent<HTMLElement>) => {
      apiRef.current.pinColumn(colDef.field, side);
      onClick(event);
    },
    [apiRef, colDef.field, onClick],
  );

  const unpinColumn = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      apiRef.current.unpinColumn(colDef.field);
      onClick(event);
    },
    [apiRef, colDef.field, onClick],
  );

  const unpinAllColumn = useCallback(
    (side: GridPinnedColumnPosition) => (event: MouseEvent<HTMLElement>) => {
      const prevPinnedColumns = apiRef.current.getPinnedColumns();

      const otherSide =
        side === GridPinnedColumnPosition.RIGHT
          ? GridPinnedColumnPosition.LEFT
          : GridPinnedColumnPosition.RIGHT;

      const newPinnedColumns = {
        [side]: [
          ...(prevPinnedColumns[side]?.filter(
            (column) => column === GRID_CHECKBOX_SELECTION_COL_DEF.field,
          ) || []),
        ],
        [otherSide]:
          prevPinnedColumns[otherSide]?.filter(
            (column) => column === GRID_CHECKBOX_SELECTION_COL_DEF.field,
          ) || [],
      };

      apiRef.current.setPinnedColumns(newPinnedColumns);
      onClick(event);
    },
    [apiRef, onClick],
  );

  const pinToLeftMenuItem = (
    <MenuItem onClick={pinColumn(GridPinnedColumnPosition.LEFT)}>
      <ListItemIcon>
        <rootProps.slots.columnMenuPinLeftIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{apiRef.current.getLocaleText('pinToLeft')}</ListItemText>
    </MenuItem>
  );

  const pinToRightMenuItem = (
    <MenuItem onClick={pinColumn(GridPinnedColumnPosition.RIGHT)}>
      <ListItemIcon>
        <rootProps.slots.columnMenuPinRightIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{apiRef.current.getLocaleText('pinToRight')}</ListItemText>
    </MenuItem>
  );

  const pinAllToLeftMenuItem = (
    <MenuItem onClick={pinAllColumn(GridPinnedColumnPosition.LEFT)}>
      <ListItemIcon>
        <rootProps.slots.columnMenuPinLeftIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        {apiRef.current.getLocaleText('pinAllToLeft')}
      </ListItemText>
    </MenuItem>
  );

  const pinAllToRightMenuItem = (
    <MenuItem onClick={pinAllColumn(GridPinnedColumnPosition.RIGHT)}>
      <ListItemIcon>
        <rootProps.slots.columnMenuPinRightIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        {apiRef.current.getLocaleText('pinAllToRight')}
      </ListItemText>
    </MenuItem>
  );

  if (!colDef) {
    return null;
  }

  const side = apiRef.current.isColumnPinned(colDef.field);

  if (side) {
    return (
      <Fragment>
        {theme.direction === 'rtl' ? (
          <MenuItem onClick={unpinAllColumn(GridPinnedColumnPosition.RIGHT)}>
            <ListItemIcon />
            <ListItemText>
              {apiRef.current.getLocaleText('unpinAllToRight')}
            </ListItemText>
          </MenuItem>
        ) : (
          <MenuItem onClick={unpinAllColumn(GridPinnedColumnPosition.LEFT)}>
            <ListItemIcon />
            <ListItemText>
              {apiRef.current.getLocaleText('unpinAllToLeft')}
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={unpinColumn}>
          <ListItemIcon />
          <ListItemText>{apiRef.current.getLocaleText('unpin')}</ListItemText>
        </MenuItem>
      </Fragment>
    );
  }

  if (theme.direction === 'rtl') {
    return (
      <Fragment>
        {pinAllToRightMenuItem}
        {pinToRightMenuItem}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {pinAllToLeftMenuItem}
      {pinToLeftMenuItem}
    </Fragment>
  );
};

export default JoyGridColumnMenuPinningItem;

import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import FileView from 'components/pages/common/MeetsInfoDescription/VoteModal/FileView';
import { VoteModalProps } from 'components/pages/common/MeetsInfoDescription/VoteModal/type';

const VoteModal = ({
  voteName,
  voteDescription,
  agendas,
  open,
  onClose,
}: VoteModalProps) => {
  const JSON = generateDescriptionsJSON(
    ['총회명', '투표 설명'],
    [voteName, voteDescription],
  );

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog minWidth={640} sx={{ gap: '30px' }}>
        <DialogTitle sx={{ alignSelf: 'center' }}>전자 투표 내용</DialogTitle>
        <DialogContent>
          <Stack gap={3.5} sx={{ wordBreak: 'break-all' }}>
            <DescriptionsRenderer columns={1} json={JSON} />

            {agendas.map((agenda, i) => {
              const { name, description, attachFileSeq, candidates } = agenda;

              const agendaJSON = generateDescriptionsJSON(
                [
                  '안건 명',

                  <span
                    key="안건 설명"
                    style={{
                      wordBreak: 'break-word',
                      whiteSpace: 'nowrap',
                      marginRight: '5px',
                    }}
                  >
                    안건 설명
                  </span>,
                  '첨부파일',
                ],
                [
                  name,
                  description,
                  attachFileSeq ? (
                    <FileView
                      key="첨부파일"
                      type="link"
                      fileSeq={attachFileSeq}
                    />
                  ) : (
                    ''
                  ),
                ],
              );

              return (
                <Stack key={agenda.name} gap={1.75}>
                  <Typography level="title-lg">제 {i + 1}호 안건</Typography>
                  <DescriptionsRenderer columns={1} json={agendaJSON} />
                  {candidates.map((candidate, candidateIndex) => {
                    return (
                      <Stack key={candidate.name} gap="30px">
                        <Stack
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Stack
                            flexDirection="row"
                            gap={1.75}
                            minHeight="200px"
                          >
                            <Chip
                              sx={{ height: 'fit-content' }}
                              color="primary"
                            >
                              {candidateIndex + 1}번
                            </Chip>
                            <Typography level="title-md">
                              {candidate.name}
                            </Typography>
                            <Typography level="body-md">
                              {candidate.description}
                            </Typography>
                          </Stack>
                          {!isNaN(candidate.attachFileSeq) && (
                            <FileView
                              key="첨부파일"
                              type="img"
                              width={200}
                              height={200}
                              fileSeq={candidate.attachFileSeq}
                            />
                          )}
                        </Stack>
                        <Divider />
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={() => onClose(false)}>
            닫기
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default VoteModal;

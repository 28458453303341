import { GridRowId } from '@mui/x-data-grid-premium';
import {
  Box,
  Button,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';
import { usePostDocumentsQuery } from 'apis/post/apis';
import { POST_DOCUMENT_COLUMNS } from 'apis/post/fixtures';

const MAX_SELECT = 6;

export interface PostDocumentSelectionModalProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'onSubmit'> {
  unionSeq: number;
  onSubmit?: (values: File[]) => void;
  onDelete?: (values: number[]) => void;
  onClose: (value: boolean) => void;
}

const PostDocumentSelectionModal = (props: PostDocumentSelectionModalProps) => {
  const { unionSeq, onDelete, onSubmit, onClose, ...rest } = props;

  const [selectedFileSeqs, setSelectedFileSeqs] = useState<GridRowId[]>([]);

  const { data, isPending } = usePostDocumentsQuery({
    unionSeq: Number(unionSeq),
  });

  const { mutateAsync: downloadFile, isPending: isDownloadFile } =
    useFileDownloadMutation();

  const documents = useMemo(() => data?.data ?? [], [data?.data]);
  const isRowSelected = useMemo(
    () => selectedFileSeqs.length > 0,
    [selectedFileSeqs],
  );
  const isUniversalDocumentSelected = useMemo(
    () =>
      selectedFileSeqs.length > 0 &&
      data?.data?.some(
        ({ file, postFormSeq }) =>
          selectedFileSeqs.includes(file.fileSeq) && !postFormSeq,
      ),
    [data?.data, selectedFileSeqs],
  );
  const selectedDocuments = useMemo(
    () =>
      documents.filter(
        ({ file }) =>
          Array.isArray(selectedFileSeqs) &&
          selectedFileSeqs.includes(file.fileSeq),
      ),
    [documents, selectedFileSeqs],
  );

  const handleRowSelectionModelChange = (model: GridRowId[]) => {
    if (model.length > MAX_SELECT) return;

    setSelectedFileSeqs(model);
  };

  const handleDelete = () => {
    const postFormSeqs = selectedDocuments.map(
      ({ postFormSeq }) => postFormSeq,
    );

    onDelete?.(postFormSeqs);
  };

  const handleSubmit = async () => {
    const mutations = selectedDocuments.map(({ file }) =>
      downloadFile({ fileSeq: file.fileSeq }),
    );

    const responses = await Promise.all(mutations);
    const files = await responses.flatMap((blob, index) =>
      blob ? [new File([blob], selectedDocuments[index].file.originName)] : [],
    );

    onSubmit?.(files as File[]);
    onClose(false);
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') return;

    onClose(false);
  };

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog>
          <DialogTitle>문서서식</DialogTitle>

          <DialogContent sx={{ pb: 4 }}>
            <Stack gap={1.25}>
              <Typography level="body-md" color="neutral">
                우편 발송할 서식은 최대 {MAX_SELECT}개까지 선택 가능합니다.
              </Typography>

              <Box sx={{ width: 600, height: 440 }}>
                <DataGrid
                  rows={documents}
                  columns={POST_DOCUMENT_COLUMNS}
                  loading={isPending}
                  rowSelectionModel={selectedFileSeqs}
                  onRowSelectionModelChange={handleRowSelectionModelChange}
                  getRowId={(row) => row.file.fileSeq}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Stack width="100%" direction="row" justifyContent="space-between">
              <Button
                variant="outlined"
                color="danger"
                disabled={!isRowSelected || isUniversalDocumentSelected}
                onClick={handleDelete}
              >
                선택삭제
              </Button>

              <Stack direction="row" gap={1}>
                <Button
                  variant="outlined"
                  color="neutral"
                  disabled={isDownloadFile}
                  onClick={() => onClose(false)}
                >
                  취소
                </Button>
                <Button
                  disabled={!isRowSelected || isDownloadFile}
                  onClick={handleSubmit}
                  loading={isDownloadFile}
                >
                  서식선택
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default PostDocumentSelectionModal;

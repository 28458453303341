import JoyListDivider from '@mui/joy/ListDivider';
import type { ListDividerProps as JoyListDividerProps } from '@mui/joy/ListDivider';
import { ForwardedRef, forwardRef } from 'react';

export type ListDividerProps = JoyListDividerProps;

const ListDivider = (
  props: ListDividerProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  return <JoyListDivider {...props} ref={ref} />;
};

export default forwardRef<HTMLLIElement, ListDividerProps>(ListDivider);

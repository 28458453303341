import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { ManagerList } from 'kwon/union-info/types';
import { ApiError, ApiResponseData } from 'types/api';

export interface UnionManagerListItem {
  unionManagerSeq: number;
  status: string;
  data: ManagerList[];
}
export interface UnionSeq {
  unionSeq: number;
}

export type UnionManagerReturnData = UnionManagerListItem[];

export interface UnionInfoValues {
  name: string;
  phoneNo: string;
  email: string;
  faxNo: string;
  managerName: string;
  managerPhoneNo?: string;
  address: Address | string;
}

export interface Address {
  address: string;
  detailAddress: string;
  zipNo: string;
}

export interface UnionInfoList extends UnionInfoValues {
  unionOfficeSeq: number;
}

// export interface UnionInfoDetail {
//   data: UnionInfoValues;
// }
export interface UnionInfoDetail {
  data: UnionInfoValues;
}

// export interface OfficeInfo {
//   data: UnionInfoList[];
// }
export type OfficeInfo = UnionInfoList;

export interface CreateUnionInfo extends Omit<UnionInfoValues, 'address'> {
  address: string;
  detailAddress: string;
  zipNo: string;
  unionSeq: number;
}

export interface ModifyUnionInfo extends CreateUnionInfo {}
export type DeleteUnionManagerRequest = {
  unionSeq: number;
  unionManagerSeq: number;
};

export interface RegisterManagerRequest {
  unionSeq?: number;
  name: string;
  phoneNo: string;
  email?: string;
}

export const useUnionManagerListQuery = (
  params: UnionSeq,
): UseQueryResult<ApiResponseData<UnionManagerReturnData>, ApiError> => {
  const { unionSeq } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/managers`],
  });
};

export const useOfficeInfoQuery = (
  params: UnionSeq,
): UseQueryResult<ApiResponseData<OfficeInfo>, ApiError> => {
  const { unionSeq } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/office`],
  });
};

export const useOfficeDetailInfoQuery = (
  unionSeq: number,
): UseQueryResult<ApiResponseData<UnionInfoValues>, ApiError> => {
  return useQuery({
    queryKey: [`/unions/${unionSeq}/offices`],
  });
};

export const useCreateUnionInfoMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  CreateUnionInfo
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/office`,
        body,
      );
      return data;
    },
  });

export const useModifyUnionInfoMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ModifyUnionInfo
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.put(
        `/unions/${unionSeq}/office`,
        body,
      );
      return data;
    },
  });

export const useDeleteUnionManagerMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  DeleteUnionManagerRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, unionManagerSeq } = request;
      const { data } = await axiosInstance.delete(
        `/unions/${unionSeq}/managers/${unionManagerSeq}`,
      );
      return data;
    },
  });
export const useRegisterManagerMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  RegisterManagerRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/managers`,
        body,
      );
      return data;
    },
  });

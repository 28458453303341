import { format, FormatDateOptions } from 'date-fns';
import { ko } from 'date-fns/locale/ko';
import * as _ from 'lodash-es';

export const formatDateTime = <T extends Date>(
  date: string | number | undefined | T,
  formatStr = "yyyy-MM-dd'T'HH:mm:ss",
  options?: Omit<FormatDateOptions, 'locale'>,
) => {
  if (!date) {
    return date;
  }

  return format(date, formatStr, { ...options, locale: ko });
};

export const maxDateTime = <T extends Date>(
  value1: string | number | undefined | null | T,
  value2: string | number | undefined | null | T,
) => {
  const validateValue1 = _.isNull(value1) || _.isUndefined(value1);
  const validateValue2 = _.isNull(value2) || _.isUndefined(value2);

  if (validateValue1 && validateValue2) {
    return undefined;
  }

  if (validateValue1) {
    return value2;
  }

  if (validateValue2) {
    return value1;
  }

  const date1 = new Date(value1);
  const date2 = new Date(value2);

  return date1 > date2 ? value1 : value2;
};

export const minDateTime = <T extends Date>(
  value1: string | number | undefined | null | T,
  value2: string | number | undefined | null | T,
) => {
  const validateValue1 = _.isNull(value1) || _.isUndefined(value1);
  const validateValue2 = _.isNull(value2) || _.isUndefined(value2);

  if (validateValue1 && validateValue2) {
    return undefined;
  }

  if (validateValue1) {
    return value2;
  }

  if (validateValue2) {
    return value1;
  }

  const date1 = new Date(value1);
  const date2 = new Date(value2);

  return date1 < date2 ? value1 : value2;
};

import JoyCardContent from '@mui/joy/CardContent';
import type { CardContentProps as JoyCardContentProps } from '@mui/joy/CardContent';
import { ForwardedRef, forwardRef } from 'react';

export type CardContentProps = JoyCardContentProps;

const CardContent = (
  props: CardContentProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyCardContent {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, CardContentProps>(CardContent);

import { useContext } from 'react';

import RadioGroupContext, {
  RadioGroupContextValue,
} from 'design-system/components/inputs/radio/RadioGroupContext';

export interface RadioGroupState extends RadioGroupContextValue {}

const useRadioGroup = (): RadioGroupState | undefined => {
  return useContext(RadioGroupContext);
};

export default useRadioGroup;

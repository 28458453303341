import { UseMutationResult, useMutation } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { MeetMethods } from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

export interface ElectronicVoteRequest {
  signatureType: string;
  senderName: string;
  contactNo: string;
  description: string;
  voteStartAt: string;
  voteEndAt: string;
}

export interface OnsiteVoteRequest {
  meetAt: string;
  submissionStartAt: string;
  submissionEndAt: string;
  jibunAddress: string;
  roadAddress: string;
  detailAddress: string;
  attendanceRate: number;
}

export interface Candidate {
  no: number;
  name: string;
  description: string;
  attachFileSeq?: number;
}

export type VoteType = 'MONO' | 'MULTI';
export type VoteForm = 'AGAINST' | 'SELECT';

export interface Agenda {
  // agendaSeq: number;
  name: string;
  description: string;
  voteForm: VoteForm;
  voteType: VoteType;
  order: number;
  attachFileSeq?: number;
  selectCount: number;
  candidates: Candidate[];
}

export interface CreateMeetBody {
  unionSeq: number;
  unionRegisterSeqs: number[];
  name: string;
  moverName: string;
  openType: string;
  meetType: string;
  meetMethod: MeetMethods;
  password: string;
  noticeFileSeq: number;
  electronicVote?: ElectronicVoteRequest;
  onsiteVote?: OnsiteVoteRequest;
  agendas: Agenda[];
}

export type CreateMeetMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreateMeetBody
>;

export const useCreateMeetMutation = (): CreateMeetMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        '/meets',
        formData,
      );
      return data;
    },
  });

export interface CalculateCostBody {
  unionSeq: number;
  meetMethod: string;
  unionRegisterSeqs: number[];
}

export interface CalculateCostResponse {
  totalCost: number;
}

export type CalculateCostMutation = UseMutationResult<
  ApiResponseData<CalculateCostResponse>,
  ApiError,
  CalculateCostBody
>;

export const useCalculateCostMutation = (): CalculateCostMutation =>
  useMutation({
    mutationFn: async (formData: CalculateCostBody) => {
      const { data } = await axiosInstance.post<
        ApiResponseData<CalculateCostResponse>
      >('/meets/cost/calculate', formData);

      return data;
    },
  });

import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import {
  Button,
  DataGrid,
  DateRangePicker,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ConsentsParams, useConsentsQuery } from 'apis/agreements/apis';
import { AGREEMENT_TABLE_COLUMNS } from 'apis/agreements/fixture';
import {
  CreateMessageBody,
  useCallersQuery,
  useCreateMessageMutation,
} from 'apis/message/apis';
import { MESSAGE_AGREEMENT_COLUMNS } from 'apis/message/fixtures';
import AgreeModal from 'components/pages/agreements/agreeModal';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import MessageAgreementStep from 'components/pages/messages/steps/MessageAgreementStep';
import MessageBalanceStep from 'components/pages/messages/steps/MessageBalanceStep';
import Search from 'components/Search';
import StepperDialog from 'components/StepperDialog';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';
import { expandData, exportExcel } from 'utils/grid/excel';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

export interface AgreementFormValues {
  unionSeq: number;
  name?: string;
  unionRegisterNo?: string;
  phoneNo?: string;
  localAddress?: string;
  searchFromTo?: string[];
  page?: number;
  pageSize?: number;
}

const DEFAULT_SEARCH_PARAMS = {
  name: '',
  unionRegisterNo: '',
  phoneNo: '',
  localAddress: '',
  searchFromTo: [undefined, undefined],
  searchFrom: '',
  searchTo: '',
  shareType: '',
};

const DEFAULT_MESSAGE_VALUES = {
  outgoingPhoneNo: '',
  scheduledAt: '',
  sendType: 'IMMEDIATE',
  recipients: [],
  files: [],
  title: '',
  message: '',
  uniqueIds: [],
};

const AgreementPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const { pageContext } = useLayoutContext();
  const { memberUnionInfo } = pageContext || {};

  const [isOpen, setIsOpen] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchParams, setSearchParams] = useState<ConsentsParams>({
    unionSeq: memberUnionInfo?.unionSeq || 0,
    ...paginationModel,
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [openAgree, setOpenAgree] = useState(false);
  const [defaultRowSelectionModel, setDefaultRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState<boolean>(false);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  const handleClickName = useCallback((unionRegisterSeq: number) => {
    setSelectUnionRegisterSeq(unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const {
    data: consentsRegisterReturnData,
    isPending,
    isError,
    error,
    refetch,
  } = useConsentsQuery(searchParams);

  const { mutate: sendMessage, isPending: isSendMessagePending } =
    useCreateMessageMutation();

  const { data: callersReturnData } = useCallersQuery({
    unionSeq,
  });

  const { snackbar } = useFeedback();
  const { datagridApiRef, getExcelData } = useGridUtils({
    key: 'union-agreement',
  });

  const callers = useMemo(
    () => callersReturnData?.data?.filter(({ status }) => status === 'C') || [],
    [callersReturnData],
  );

  const handleExportExcel = useCallback(async () => {
    const { headers, rows } = getExcelData();
    const expandRows = expandData(rows);

    try {
      await exportExcel(headers, expandRows as string[][], {
        fileName: '동의_현황.xlsx',
        worksheetName: '동의 현황 내역',
      });
    } catch (error) {
      snackbar('다운로드에 실패하였습니다.', { color: 'danger' });
    }
  }, [getExcelData, snackbar]);

  const handleSearchParams = (values: Partial<AgreementFormValues>) => {
    if (!values) {
      return;
    }

    const { searchFromTo, ...other } = values;

    const body: ConsentsParams = {
      unionSeq,
      ...other,
    };

    if (searchFromTo) {
      body.searchFrom = values.searchFromTo?.[0];
      body.searchTo = values.searchFromTo?.[1];
    }

    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...body }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const openHandler = () => {
    setOpenAgree(true);
  };

  const handleSubmitSendMessage = (data: CreateMessageBody) => {
    sendMessage(
      { ...data, unionSeq },
      {
        onSuccess: () => {
          snackbar('문자 발송이 완료되었습니다.', { color: 'success' });
          setIsOpen(false);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleChangeDefaultSelection = useCallback(
    (rowInfo: GridRowSelectionModel) => {
      setDefaultRowSelectionModel(rowInfo);
    },
    [],
  );

  const handleChangePagination = (
    _: ChangeEvent<unknown>,
    page: number | null,
  ) => {
    if (!page) return;

    setPaginationModel({ ...paginationModel, page: page - 1 });
  };

  const { columns: messageAgreementColumns } = useCreateGridColumns({
    columns: MESSAGE_AGREEMENT_COLUMNS,
  });

  const { columns } = useCreateGridColumns({
    handlers: { onClickName: handleClickName },
    columns: AGREEMENT_TABLE_COLUMNS,
  });

  const rows = useMemo(
    () => consentsRegisterReturnData?.data || [],
    [consentsRegisterReturnData],
  );

  const totalElements =
    consentsRegisterReturnData?.pagination?.totalElements || 0;
  const totalDataCount =
    consentsRegisterReturnData?.pagination?.totalDataCount || 0;

  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  if (isError) throw error;

  return (
    <>
      <Stack gap={4}>
        <Stack gap={1.75}>
          <Search
            defaultValues={DEFAULT_SEARCH_PARAMS}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12}>
                <Grid container gap={2}>
                  <Grid xs={12} maxWidth={200}>
                    <Search.Field>
                      <TextField
                        label="이름"
                        name="name"
                        placeholder="이름 입력"
                        fullWidth
                        slotProps={{
                          input: { maxLength: 30 },
                        }}
                      />
                    </Search.Field>
                  </Grid>

                  <Grid xs={12} maxWidth={200}>
                    <Search.Field>
                      <TextField
                        label="연번"
                        name="unionRegisterNo"
                        placeholder='숫자 또는 "-"입력'
                        validateOptions={{
                          maxLength: 11,
                          regex: /^(?!.*--)[0-9-]*$/,
                        }}
                        fullWidth
                      />
                    </Search.Field>
                  </Grid>
                  <Grid xs={12} maxWidth={200}>
                    <Search.Field>
                      <TextField
                        label="연락처"
                        name="phoneNo"
                        placeholder="숫자만 입력"
                        slotProps={{
                          input: { maxLength: 11 },
                        }}
                        validateOptions={{
                          regex: /^(?!.*--)[0-9]*$/,
                        }}
                        fullWidth
                      />
                    </Search.Field>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container gap={2}>
                  <Grid xs={12} maxWidth={416}>
                    <Search.Field>
                      <TextField
                        label="소재지"
                        name="localAddress"
                        placeholder="주소 검색"
                        fullWidth
                      />
                    </Search.Field>
                  </Grid>

                  <Grid xs={12} maxWidth={416}>
                    <Search.Field>
                      <DateRangePicker
                        label="기간 조회"
                        name="searchFromTo"
                        sx={{ field: { xs: 12, maxWidth: 200 } }}
                      />
                    </Search.Field>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container gap={2}></Grid>
              </Grid>
            </Grid>
          </Search>
        </Stack>
        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>
          </Stack>
          <Stack gap={2}>
            <Stack height={442}>
              <DataGrid
                apiRef={datagridApiRef}
                rows={rows}
                columns={columns}
                columnBufferPx={Infinity}
                getRowHeight={(params) =>
                  (params?.model?.unionRegister?.localAddresses?.length || 1) *
                    40 || 40
                }
                loading={isPending}
                onRowSelectionModelChange={handleChangeDefaultSelection}
                getRowId={(row) => row?.unionRegister?.unionRegisterSeq || 0}
                checkboxSelection
                disableRowSelectionOnClick
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            </Stack>
            <Stack alignItems="center">
              <Pagination
                color="neutral"
                variant="plain"
                size="md"
                orientation="horizontal"
                showFirstButton={false}
                showLastButton={false}
                hidePrevButton={false}
                hideNextButton={false}
                count={pageTotalCount}
                onChange={handleChangePagination}
              ></Pagination>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="outlined"
              size="md"
              color="neutral"
              onClick={handleExportExcel}
            >
              내역다운
            </Button>
            <Stack direction="row" justifyContent="space-between" gap={2}>
              <Button
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                문자발송
              </Button>
              <Button
                disabled={defaultRowSelectionModel.length === 0}
                onClick={openHandler}
              >
                동의현황 저장
              </Button>
            </Stack>
          </Stack>
        </Stack>
        {openAgree && (
          <AgreeModal
            open={openAgree}
            refetch={refetch}
            selectedRow={defaultRowSelectionModel}
            onClose={() => {
              setOpenAgree(false);
            }}
          />
        )}
      </Stack>

      {isOpen && (
        <StepperDialog
          title="문자 발송"
          defaultLastButtonText="문자 발송"
          defaultValues={{
            ...DEFAULT_MESSAGE_VALUES,
            outgoingPhoneNo: callers?.[0]?.outgoingPhoneNo || '',
          }}
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <StepperDialog.Step name="조합원 선택">
            <MessageAgreementStep
              columns={messageAgreementColumns}
              callers={callers}
              unionSeq={unionSeq}
            />
          </StepperDialog.Step>

          <StepperDialog.Step name="비용 조회">
            <MessageBalanceStep
              unionSeq={unionSeq}
              isSendMessagePending={isSendMessagePending}
              onSubmit={handleSubmitSendMessage}
            />
          </StepperDialog.Step>
        </StepperDialog>
      )}

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}
    </>
  );
};

const agreementPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/agreements',
  children: [{ path: '', index: true, element: <AgreementPage /> }],
  handle: {
    getTitle: () => '동의서 관리',
    getMenuCode: () => 'M0402',
  },
};

export default agreementPage;

import { default as JoySnackbar } from '@mui/joy/Snackbar';
import type { SnackbarProps as JoySnackbarProps } from '@mui/joy/Snackbar';
import { ForwardedRef, forwardRef } from 'react';

import CloseSnackbarContext from 'design-system/components/feedback/snackbar/CloseSnackbarContext';

export type SnackbarProps = JoySnackbarProps;

const Snackbar = (
  { onClose, children, color, variant, size, ...rest }: SnackbarProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <CloseSnackbarContext.Provider value={{ onClose, color, variant, size }}>
      <JoySnackbar
        ref={ref}
        color={color}
        variant={variant}
        size={size}
        onClose={onClose}
        {...rest}
      >
        {children}
      </JoySnackbar>
    </CloseSnackbarContext.Provider>
  );
};

export default forwardRef<HTMLDivElement, SnackbarProps>(Snackbar);

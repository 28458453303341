import HowToVoteOutlinedIcon from '@mui/icons-material/HowToVoteOutlined';

import certification from 'pages/main/meet-management/certification';
import history from 'pages/main/meet-management/history';
import open from 'pages/main/meet-management/open';
import promotion from 'pages/main/meet-management/promotion';
import { CustomRouteObject } from 'types/route';

const generalMeetingManagementPage: CustomRouteObject = {
  handle: {
    getTitle: () => '총회 관리',
    getIcon: () => <HowToVoteOutlinedIcon />,
    getMenuCode: () => 'M10',
  },
  children: [open, history, certification, promotion],
};

export default generalMeetingManagementPage;

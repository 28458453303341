import { styled } from '@mui/joy';
import { ForwardedRef, forwardRef } from 'react';

import Box, { BoxProps } from 'design-system/components/layout/Box';

export type AppBarSlot = 'root';

export interface AppBarProps extends BoxProps {
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
}

const AppBar = (props: AppBarProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    position = 'fixed',
    flexDirection = 'row',
    sx,
    children,
    ...other
  } = props;

  return (
    <JoyAppBarRoot
      {...other}
      flexDirection={flexDirection}
      sx={{ ...sx }}
      component="header"
      position={position}
      ref={ref}
    >
      {children}
    </JoyAppBarRoot>
  );
};

const JoyAppBarRoot = styled(Box, { name: 'JoyAppBar', slot: 'root' })`
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  background-color: ${({ theme }) =>
    theme.palette.mode === 'light' ? '#fff' : '#000'};
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.palette.mode === 'light'
        ? theme.palette.neutral[200]
        : theme.palette.neutral[600]};

  justify-items: center;
  min-height: 64px;

  transition-property: margin-left, width;
  transition-duration: 0.5s;

  ${{
    variants: [
      {
        props: { position: 'fixed' },
        style: {
          position: 'fixed',
          top: 0,
          left: 'auto',
          right: 0,
        },
      },
    ],
  }}
`;

export default forwardRef<HTMLDivElement, AppBarProps>(AppBar);

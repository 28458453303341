import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { Form } from 'apis/types/form';
import { ApiError, ApiResponseData } from 'types/api';

/**
 * GET
 */

// 문서 서식 목록 조회
export interface FormsParams {
  keyword: string;
}
export type FormsReturnData = Form[];
type UseFormsQueryResult = UseQueryResult<
  ApiResponseData<FormsReturnData>,
  ApiError
>;

export const useFormsQuery = (params: FormsParams): UseFormsQueryResult => {
  const { ...restParams } = params;

  return useQuery({
    queryKey: [`/forms`, restParams],
  });
};

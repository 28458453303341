import {
  DateRangePicker,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { MeetMethods } from 'apis/types/meet';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import Search from 'components/Search';
import { MeetsParticipantsParams } from 'lim/generalMeetingHistoryDetail/apis';

const defaultParams: MeetsParticipantsParams<'ELECTRONIC'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
  isElectronicVoteResent: '',
  tendency: '',
  receivedAtFrom: '',
  receivedAtTo: '',
  openedAtTo: '',
  openedAtFrom: '',
  votedAtFrom: '',
  votedAtTo: '',
};

const ElectronicFilter = <T extends MeetMethods = 'ELECTRONIC'>({
  onSearchSubmit,
}: {
  onSearchSubmit: (params: MeetsParticipantsParams<T>) => void;
}) => {
  const { getGroupCode } = useCommonCodes();

  const { statusGroup, tendencyGroup } = useMemo(
    () => ({
      statusGroup: getGroupCode('MEET_PARTICIPANT_VOTE_STATUS'),
      tendencyGroup: getGroupCode('TENDENCY_TYPE'),
    }),
    [getGroupCode],
  );

  const [selectParams, setSelectParams] = useState<
    Pick<
      MeetsParticipantsParams<'ELECTRONIC'>,
      | 'electronicVoteProgressStatus'
      | 'isElectronicVoteResent'
      // | 'voteStatus'
      | 'tendency'
      // | 'receivedAt'
    >
  >({
    electronicVoteProgressStatus: '',
    tendency: '',
    isElectronicVoteResent: '',
  });

  const handleOnSearch = (params: MeetsParticipantsParams<T>) => {
    let newValue = {} as MeetsParticipantsParams<T>;

    Object.entries(params).forEach(([key, value]) => {
      if (key.includes('At') && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    onSearchSubmit({
      ...newValue,
      ...selectParams,
    });
  };

  const handleOnReset = () => {
    onSearchSubmit(defaultParams as MeetsParticipantsParams<T>);
    setSelectParams({
      electronicVoteProgressStatus: '',
      isElectronicVoteResent: '',
      tendency: '',
    });
  };

  const handleOnSelect = (
    key: 'electronicVoteProgressStatus' | 'tendency' | 'isElectronicVoteResent',
    value: string | boolean,
  ) => {
    setSelectParams({
      ...selectParams,
      [key]: value,
    });
  };

  return (
    <Search
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      defaultValues={defaultParams}
      onSubmit={handleOnSearch}
      onReset={handleOnReset}
    >
      <Grid container gap={2}>
        <Stack flexDirection="row" flexWrap="wrap" gap={2}>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                label="연번"
                name="unionRegisterNo"
                placeholder='숫자 또는 "-"입력'
                validateOptions={{
                  maxLength: 11,
                  regex: /^(?!.*--)[0-9-]*$/,
                }}
                fullWidth
              />
            </Search.Field>
          </Grid>

          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="연락처를 입력하세요"
                label="연락처"
                name="phoneNo"
                fullWidth
                validateOptions={{
                  maxLength: 11,
                  regex: /^[0-9]*$/,
                }}
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="이름을 입력하세요"
                label="이름"
                name="name"
                fullWidth
                slotProps={{
                  input: { maxLength: 10 },
                }}
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>상태</FormLabel>
                <Select
                  fullWidth
                  name="electronicVoteProgressStatus"
                  value={selectParams.electronicVoteProgressStatus}
                  onChange={(_, value) =>
                    handleOnSelect(
                      'electronicVoteProgressStatus',
                      value as unknown as string,
                    )
                  }
                >
                  <Option value="">전체</Option>

                  {statusGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>발송여부</FormLabel>
                <Select
                  fullWidth
                  name="isElectronicVoteResent"
                  value={selectParams.isElectronicVoteResent}
                  onChange={(_, value) =>
                    handleOnSelect(
                      'isElectronicVoteResent',
                      value as unknown as boolean,
                    )
                  }
                >
                  <Option value="">전체</Option>
                  <Option value="true">O</Option>
                  <Option value="false">X</Option>
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>우호도</FormLabel>
                <Select
                  fullWidth
                  name="tendency"
                  value={selectParams.tendency}
                  onChange={(_, value) =>
                    handleOnSelect('tendency', value as unknown as string)
                  }
                >
                  <Option value="">전체</Option>
                  {tendencyGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={416}>
              <Search.Field>
                <DateRangePicker
                  label="수신일시"
                  name="receivedAt"
                  sx={{ field: { xs: 12, maxWidth: 200 } }}
                />
              </Search.Field>
            </Grid>
          </Grid>

          <Grid container gap={2}>
            <Grid xs={12} maxWidth={416}>
              <Search.Field>
                <DateRangePicker
                  label="열람일시"
                  name="openedAt"
                  sx={{ field: { xs: 12, maxWidth: 200 } }}
                />
              </Search.Field>
            </Grid>
          </Grid>

          <Grid container gap={2}>
            <Grid xs={12} maxWidth={416}>
              <Search.Field>
                <DateRangePicker
                  label="투표일시"
                  name="votedAt"
                  sx={{ field: { xs: 12, maxWidth: 200 } }}
                />
              </Search.Field>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Search>
  );
};

export default ElectronicFilter;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import useFeedback from 'hooks/useFeedback';
import { useMeetsPasswordVerifyMutation } from 'lim/generalMeetingHistoryDetail/apis';

import PasswordResetModal from './PasswordResetModal';

const schema = yup.object().shape({
  password: yup.string().required('비밀번호를 입력해주세요.'),
});

interface PasswordConfirmModalProps {
  open: boolean;
  meetSeq: number;
  onClose: (open: false) => void;
}

const PasswordConfirmModal = ({
  meetSeq,
  open,
  onClose,
}: PasswordConfirmModalProps) => {
  const { snackbar } = useFeedback();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { password: '' },
  });

  const [isShowPasswordChangeModal, setIsShowPasswordChangeModal] =
    useState(false);

  const { mutate } = useMeetsPasswordVerifyMutation(meetSeq);

  const handleConfirm = handleSubmit((data) => {
    mutate(
      { password: data.password },
      {
        onSuccess: () => {
          navigate(`${meetSeq}`);
        },
        onError: () => {
          snackbar('입력한 비밀번호가 일치하지 않습니다.', { color: 'danger' });
        },
      },
    );
  });

  const handleReset = useCallback(() => {
    reset();
    onClose(false);
  }, [onClose, reset]);

  return (
    <>
      <Modal open={open} onClose={handleReset}>
        <ModalDialog
          minWidth="sm"
          component="form"
          onSubmit={handleConfirm}
          onReset={handleReset}
        >
          <DialogTitle sx={{ alignSelf: 'center' }}>
            선택한 총회의 총회 내역 상세로 이동하시겠습니까?
          </DialogTitle>

          <DialogContent>
            <Stack gap={1.75}>
              <Typography level="title-md" alignSelf="center">
                이동하시려면 총회 개설시 입력한 비밀번호를 입력해주세요
              </Typography>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <TextField
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password?.message || ' '}
                    {...field}
                  />
                )}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button type="submit" fullWidth>
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                handleReset();
                setIsShowPasswordChangeModal(true);
              }}
            >
              비밀번호 변경
            </Button>
            <Button type="reset" fullWidth variant="outlined" color="neutral">
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <PasswordResetModal
        meetSeq={meetSeq}
        open={isShowPasswordChangeModal}
        onClose={setIsShowPasswordChangeModal}
      />
    </>
  );
};

export default PasswordConfirmModal;

import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { MemberInfo, MemberUnionInfo } from 'apis/types/member';
import { ApiError, ApiResponseData } from 'types/api';

export interface MemberInfoParams {
  memberSeq: MemberInfo['memberSeq'];
}

export type MemberInfoReturnData = MemberInfo;

export const memberInfoQuery = ({ memberSeq }: MemberInfoParams) => ({
  queryKey: [`/members/${memberSeq}`],
});

export const useMemberInfoQuery = (
  params: MemberInfoParams,
): UseQueryResult<ApiResponseData<MemberInfoReturnData>, ApiError> =>
  useQuery(memberInfoQuery(params));

export interface MemberUnionsParams {
  memberSeq: MemberInfo['memberSeq'];
}

export type MemberUnionsReturnData = MemberUnionInfo[];

export const memberUnionsQuery = ({ memberSeq }: MemberUnionsParams) => ({
  queryKey: [`/members/${memberSeq}/oms-unions`],
});

export const useMemberUnionsQuery = (
  params: MemberUnionsParams,
): UseQueryResult<ApiResponseData<MemberUnionsReturnData>, ApiError> =>
  useQuery(memberUnionsQuery(params));

export interface MemberPasswordVerifyParams {
  memberSeq: MemberInfo['memberSeq'];
  password: string;
}

export const useMemberPasswordVerifyMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  MemberPasswordVerifyParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { memberSeq, ...body } = request;

      const { data } = await axiosInstance.post(
        `/members/${memberSeq}/password/verify`,
        body,
      );

      return data;
    },
  });

import { entries } from 'lodash';
import * as qs from 'qs';

export const convertObject2QueryString = (params: object) => {
  return qs.stringify(params, { arrayFormat: 'comma' });
};

export const convertURLSearchParams2Object = (
  urlSearchParams: URLSearchParams,
) => {
  return qs.parse(urlSearchParams.toString());
};

export const convertObject2KeyValuePairs = (params: object) => {
  return entries(params);
};

export const parseNumberToLocaleString = (
  value: number,
  locales?: Intl.LocalesArgument,
  options?: Intl.NumberFormatOptions,
) => {
  return value.toLocaleString(locales || navigator.languages, options);
};

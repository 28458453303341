import { ForwardedRef, forwardRef } from 'react';
import { NumericFormat, type NumericFormatProps } from 'react-number-format';

export interface NumericFormatAdapterProps extends NumericFormatProps {}

const NumericFormatAdapter = (
  props: NumericFormatAdapterProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  return <NumericFormat {...props} {...ref} />;
};

export default forwardRef<HTMLInputElement, NumericFormatAdapterProps>(
  NumericFormatAdapter,
);

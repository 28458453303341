import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

export interface PostDocumentNameModalProps
  extends Omit<ModalProps, 'onSubmit' | 'onClose' | 'children'> {
  isSubmitLoading?: boolean;
  onSubmit?: (documentName: string) => void;
  onClose: (value: boolean) => void;
}

const PostDocumentNameModal = (props: PostDocumentNameModalProps) => {
  const { isSubmitLoading = false, onSubmit, onClose, ...rest } = props;

  const [name, setName] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const handleSubmit = () => {
    onSubmit?.(name);
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') return;

    onClose(false);
  };

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog minWidth={360}>
          <DialogTitle>해당 서식을 저장하시겠습니까?</DialogTitle>

          <DialogContent>
            <Stack direction="column" gap={2}>
              <Typography level="body-md">
                저장하시려면 서식명을 입력해주세요.
              </Typography>

              <TextField
                label="서식명"
                placeholder="서식명 입력"
                value={name}
                onChange={handleChange}
                required
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!name}
              loading={isSubmitLoading}
              onClick={handleSubmit}
            >
              완료
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
              disabled={isSubmitLoading}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default PostDocumentNameModal;

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from '@wooriga/design-system';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AuthListTab from 'components/pages/auth/tabs/AuthListTab';
import ManagementTab from 'components/pages/auth/tabs/ManagementTab';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import {
  useAuthGroupsQuery,
  useCreateAuthGroupMutation,
  useDeleteAuthGroupMutation,
  useMemberAuthGroupQuery,
} from 'lim/auth/apis';
import { CustomRouteObject } from 'types/route';

const AuthPage = () => {
  const { alertDialog, snackbar } = useFeedback();
  const { pageContext } = useLayoutContext();
  const navigate = useNavigate();

  const params = useParams();
  const { state } = useLocation();

  const { unionSeq } = params;

  const unionId = Number(unionSeq);
  const unionAuthGroupId = Number(state?.unionAuthGroupSeq);
  const [currentTab, setCurrentTab] = useState(0);

  const [tabInit, setTabInit] = useState(true);

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: '',
    content: <></>,
    onConfirm: () => {},
  });

  const memberAuthGroupsQuery = useMemberAuthGroupQuery(
    unionId,
    Number(pageContext?.memberUnionInfo?.unionMemberSeq),
  );

  const authGroupsQuery = useAuthGroupsQuery(unionId);
  const { mutate: createMutate } = useCreateAuthGroupMutation(unionId);
  const { mutate: deleteMutate } = useDeleteAuthGroupMutation(
    unionId,
    unionAuthGroupId,
  );

  const { data, refetch } = authGroupsQuery;

  const handleCreateGroup = () => {
    if (data?.data.length === 10) {
      return alertDialog('권한그룹은 최대 10개 까지 생성할 수 있습니다.', {
        color: 'danger',
      });
    }
    createMutate(
      { name: '', memo: '' },
      {
        onSuccess: ({ data }) => {
          setTabInit(false);
          setCurrentTab(0);
          refetch();
          navigate('', {
            state: {
              unionAuthGroupSeq: data.seq,
            },
          });
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleDeleteGroup = () => {
    setModalInfo({
      show: true,
      title: '권한그룹을 삭제 하시겠습니까?',
      content: (
        <>
          <Typography textColor="text.tertiary">
            해당 권한그룹의 권한자는 권한이 삭제되며,
          </Typography>
          <Typography>권한그룹은 삭제 후 복구 할 수 없습니다.</Typography>
        </>
      ),
      onConfirm: () => {
        deleteMutate(null, {
          onSuccess: () => {
            refetch();
            navigate('', {
              state: {
                unionAuthGroupSeq: data?.data[0].unionAuthGroupSeq,
              },
            });

            snackbar('그룹 삭제가 완료되었습니다.', {
              color: 'success',
            });

            setModalInfo({
              ...modalInfo,
              show: false,
            });
          },
          onError: (error) => {
            snackbar(error?.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        });
      },
    });
  };

  useEffect(() => {
    if (data?.data) {
      setTabInit(true);
    }
  }, [unionAuthGroupId]);

  useEffect(() => {
    if (isNaN(unionAuthGroupId)) {
      navigate('', {
        state: {
          unionAuthGroupSeq: data?.data[0].unionAuthGroupSeq,
        },
      });
    }
  }, [data?.data]);

  const isAutoGenerated = useMemo(
    () =>
      data?.data.find((data) => data.unionAuthGroupSeq === unionAuthGroupId)
        ?.isAutoGenerated,
    [data?.data, unionAuthGroupId],
  );

  return (
    <Stack
      height="100%"
      display="grid"
      gridTemplateColumns="400px calc(100% - 400px)"
      boxSizing="border-box"
      gap={3}
    >
      <Container>
        <Stack
          marginBottom={1}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography level="title-lg">권한 그룹</Typography>
          <Button variant="soft" onClick={handleCreateGroup}>
            권한 그룹 추가
          </Button>
        </Stack>

        <Stack
          height="100%"
          padding="6px 0px"
          bgcolor="#ffffff"
          borderRadius="6px"
        >
          <Stack>
            {data?.data.map((auth) => {
              const { unionAuthGroupSeq, isAutoGenerated, name } = auth;

              const isCurrent = unionAuthGroupId === unionAuthGroupSeq;
              return (
                <Button
                  key={unionAuthGroupSeq}
                  sx={{ justifyContent: 'space-between' }}
                  variant={isCurrent ? 'soft' : 'plain'}
                  color={isCurrent ? 'primary' : 'neutral'}
                  endDecorator={
                    !isAutoGenerated && (
                      <Button
                        variant="plain"
                        color="neutral"
                        sx={{ padding: '0', minHeight: 'auto' }}
                        onClick={handleDeleteGroup}
                      >
                        <CloseIcon />
                      </Button>
                    )
                  }
                  onClick={() =>
                    navigate('', {
                      state: {
                        unionAuthGroupSeq,
                      },
                    })
                  }
                >
                  {name}
                </Button>
              );
            })}
          </Stack>
        </Stack>
      </Container>
      <Container>
        {tabInit && (
          <Tabs
            defaultValue={currentTab}
            value={currentTab}
            onChange={(_, value) => {
              setCurrentTab(Number(value));
            }}
            selectedTabColor="primary"
            selectedTabVariant="plain"
            tabIndicatorInset
            sx={{
              marginBottom: '30px',
              background: (theme) => theme.palette.background.body,
            }}
          >
            <TabList>
              <Tab value={0}>권한자 관리</Tab>
              <Tab value={1}>권한 목록</Tab>
            </TabList>
            <TabPanel value={0}>
              <ManagementTab
                unionSeq={unionId}
                memberAuthGroupsQuery={memberAuthGroupsQuery}
                unionAuthGroupSeq={unionAuthGroupId}
                authGroupsQuery={authGroupsQuery}
              />
            </TabPanel>
            <TabPanel value={1}>
              <AuthListTab
                isAutoGenerated={isAutoGenerated}
                unionSeq={unionId}
                unionAuthGroupSeq={unionAuthGroupId}
              />
            </TabPanel>
          </Tabs>
        )}
      </Container>

      {/* ------------------------ Modal ------------------------ */}

      <Modal
        open={modalInfo.show}
        onClose={() =>
          setModalInfo({
            ...modalInfo,
            show: false,
          })
        }
      >
        <ModalDialog>
          <DialogTitle>{modalInfo.title}</DialogTitle>
          <DialogContent>{modalInfo.content}</DialogContent>
          <DialogActions>
            <Button onClick={modalInfo.onConfirm}>확인</Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() =>
                setModalInfo({
                  ...modalInfo,
                  show: false,
                })
              }
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Stack>
  );
};

const Container = ({ children }: { children: ReactNode }) => (
  <Stack
    height="100%"
    padding={2}
    bgcolor={(theme) => theme.palette.neutral[50]}
    border={(theme) => `1px solid ${theme.palette.neutral[300]}`}
    borderRadius="12px"
  >
    {children}
  </Stack>
);

const authPage: CustomRouteObject = {
  path: '/unions/:unionSeq/etc-management/auth',
  element: <AuthPage />,
  handle: {
    getTitle: () => '권한 관리',
    getMenuCode: () => 'M1101',
  },
};

export default authPage;

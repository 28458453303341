import {
  DataGrid,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import { useMeetsPromotionUsersQuery } from 'lim/meetPromotion/apis';
import {
  PROMOTION_INFO_HEAD_TABLE_COLUMNS,
  PROMOTION_INFO_MANAGER_TABLE_COLUMNS,
} from 'lim/meetPromotion/fixtures';

interface PromotionInfoModalProps {
  meetSeq: number;
  promotionType: string;
  open: boolean;
  onClose: (open: false) => void;
}

const PromotionInfoModal = ({
  meetSeq,
  promotionType,
  open,
  onClose,
}: PromotionInfoModalProps) => {
  const { data, isLoading, isError, error } = useMeetsPromotionUsersQuery(
    meetSeq,
    promotionType,
    {
      name: '',
      id: '',
      phoneNo: '',
      userStatus: 'USE',
      searchFrom: '',
      searchTo: '',
    },
  );

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const {
          promotionUserSeq,
          id,
          name,
          phoneNo,
          memo,
          userStatus,
          position,
          assignedParticipantCount,
        } = row;
        return {
          id: promotionUserSeq,
          accountID: id,
          userStatus,
          name,

          phoneNo,
          memo,
          position,
          assignedParticipantCount,
        };
      }) || []
    );
  }, [data]);

  if (isError) throw error;

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog sx={{ width: 860 }}>
        <DialogTitle>
          홍보 {promotionType === 'HEAD' ? '팀장' : '담당자'} 조회
        </DialogTitle>
        <DialogContent>
          <Stack height={442}>
            <DataGrid
              rows={rows}
              columns={
                promotionType === 'HEAD'
                  ? PROMOTION_INFO_HEAD_TABLE_COLUMNS
                  : PROMOTION_INFO_MANAGER_TABLE_COLUMNS
              }
              loading={isLoading}
              disableRowSelectionOnClick
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onClose(false)}>확인</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PromotionInfoModal;

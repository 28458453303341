import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import useFeedback from 'hooks/useFeedback';
import { commaizeNumber } from 'utils/format';

interface ResendModalProps {
  cost: number;
  open: boolean;
  onClose: (show: false) => void;
  onSubmit: () => void;
  isResendPending?: boolean;
}

const ResendModal = ({
  open,
  cost,

  onClose,
  onSubmit,
  isResendPending,
}: ResendModalProps) => {
  const { snackbar } = useFeedback();

  const [isChecked, setIsChecked] = useState(false);

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleSubmit = () => {
    if (!isChecked) {
      return snackbar('주의사항을 확인하시고 내용에 동의해 주세요.', {
        color: 'danger',
      });
    }

    onSubmit();
  };

  const costJSON = generateDescriptionsJSON(
    ['비용'],
    [`${commaizeNumber(cost)}원`],
  );

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
  };

  useEffect(() => {
    setIsChecked(false);
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <DialogTitle>주의사항</DialogTitle>

          <DialogContent>
            <Stack gap={3}>
              <Callout
                color="danger"
                variant="soft"
                startDecorator={<InfoIcon />}
              >
                <Typography color="danger">
                  전자투표 발송시 새로운 투표 주소가 전달되며, 전달받은 투표
                  주소를 통해 진행한 투표만 인정됩니다.
                </Typography>
                <Typography color="danger">
                  원활하고 안전한 투표를 위하여 전자투표 마감 72시간 전까지만
                  발송이 가능합니다.
                </Typography>
                <Typography>
                  투표 정보와 투표 내용은 개설 당시 입력하신 내용과 동일하게
                  전달됩니다.
                </Typography>
                <Typography>
                  발송시 유통증명서 및 전자문서 증명서는 전달받은 투표 주소를
                  통해 진행한 투표 이력만 보관됩니다.
                </Typography>
                <Typography>
                  투표내역증명서는 선거인에게 전달된 전자 투표에 대한 진행
                  이력이 기록됩니다.
                </Typography>
              </Callout>
              <DescriptionsRenderer columns={1} json={costJSON} />
              <Stack gap={1}>
                <Typography
                  textColor="text.tertiary"
                  textAlign="center"
                  fontSize="md"
                  fontWeight="md"
                  lineHeight="md"
                >
                  총회 정보 내용 및 선거인 정보에 오류가 없는지 다시 한번 <br />
                  작성하신 모든 내용은 작성자에게 책임이 있습니다. <br />
                  수정을 원하시면 &quot;이전&quot;으로 돌아가기를, <br />
                  수정된 선거인 명부로 전자투표 주소를 발송 하시려면 동의 여부를
                  선택 후 <br />
                  <strong>&quot;발송하기&quot;</strong>를 선택해 주세요. <br />
                  <strong>신청 이후 취소 및 환불</strong>이 불가능합니다
                </Typography>
              </Stack>
              <Stack alignItems="center" marginBottom={3}>
                <Checkbox
                  label="위 사항을 모두 확인하였습니다."
                  checked={isChecked}
                  onChange={handleChecked}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" gap={1}>
              <Button color="neutral" variant="outlined" onClick={handleClose}>
                취소
              </Button>
              <Button onClick={handleSubmit} loading={isResendPending}>
                발송하기
              </Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default ResendModal;

export const validateSearchParams = (value: unknown) => {
  if (
    value === null ||
    value === undefined ||
    value === 'undefiend' ||
    (typeof value === 'string' && value.trim() === '')
  )
    return false;

  return true;
};

export const convertSearchParams2Object = (searchParams: URLSearchParams) => {
  const searchParamsObject: { [key: string]: string } = {};

  for (const [key, value] of searchParams.entries()) {
    if (validateSearchParams(value)) searchParamsObject[key] = value;
  }

  return searchParamsObject;
};

export const convertObject2SearchParams = (object: object) => {
  const searchParamsObject: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(object)) {
    if (validateSearchParams(value)) searchParamsObject[key] = value;
  }

  return searchParamsObject;
};

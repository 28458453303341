import ModalDialog from '@mui/joy/ModalDialog';
import type { ModalDialogProps as JoyModalDialogProps } from '@mui/joy/ModalDialog';
import { ForwardedRef, ReactNode, forwardRef, useCallback } from 'react';

import Typography from 'design-system/components/dataDisplay/Typography';
import DialogActions from 'design-system/components/feedback/dialog/DialogActions';
import DialogTitle from 'design-system/components/feedback/dialog/DialogTitle';
import ModalOverflow from 'design-system/components/feedback/ModalOverflow';
import Button from 'design-system/components/inputs/Button';

export interface AlertDialogProps
  extends Omit<JoyModalDialogProps, 'onSubmit'> {
  message?: ReactNode;
  detail?: ReactNode;
  type?: AlertDailogType;
  onSubmit?: (result: AlertDailogResultType) => void;
}

export type AlertDailogResultType = 'OK' | 'Cancel';

export type AlertDailogType = 'OK' | 'OKCancel';

const AlertDialog = (
  { message, detail, onSubmit, type = 'OK', color, ...other }: AlertDialogProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const handleSubmit = useCallback(
    (result: AlertDailogResultType) => () => {
      onSubmit && onSubmit(result);
    },
    [onSubmit],
  );

  const renderButtons = useCallback(
    (currType: AlertDailogType) => {
      switch (currType) {
        case 'OK':
          return (
            <Button color={color} onClick={handleSubmit('OK')}>
              확인
            </Button>
          );
        case 'OKCancel':
          return (
            <>
              <Button color={color} onClick={handleSubmit('OK')}>
                확인
              </Button>
              <Button
                color="neutral"
                variant="outlined"
                onClick={handleSubmit('Cancel')}
              >
                취소
              </Button>
            </>
          );
      }
    },
    [color, handleSubmit],
  );

  return (
    <ModalOverflow>
      <ModalDialog {...other} ref={ref}>
        <DialogTitle>{message}</DialogTitle>
        <Typography overflow="auto" whiteSpace="pre">
          {detail}
        </Typography>
        <DialogActions orientation="horizontal-reverse">
          {renderButtons(type)}
        </DialogActions>
      </ModalDialog>
    </ModalOverflow>
  );
};

export default forwardRef<HTMLDivElement, AlertDialogProps>(AlertDialog);

import CheckIcon from '@mui/icons-material/Check';
import {
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from '@wooriga/design-system';
import {
  Children,
  cloneElement,
  createContext,
  Dispatch,
  isValidElement,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import StepperDialogStep, {
  StepperDialogStepProps,
} from 'components/StepperDialog/StepperDialogStep';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StepperDialogValues = Record<string, any>;
export interface StepperDialogContextType<T extends StepperDialogValues> {
  values: T;
  steps: string[];
  step: string;
  defaultLastButtonText?: string;
  closeDialog: () => void;
  updateValues: Dispatch<SetStateAction<T>>;
  setStep: Dispatch<SetStateAction<string>>;
}

type StepperDialogStepElement = ReactElement<StepperDialogStepProps>[];
export interface StepperDialogProps<T>
  extends Omit<ModalProps, 'onClose' | 'onSubmit' | 'children'> {
  children: StepperDialogStepElement;
  title: string;
  defaultValues?: T;
  defaultStep?: StepperDialogStepProps['name'];
  defaultLastButtonText?: string;
  onClose: (value: boolean) => void;
}

export const StepperDialogContext =
  createContext<StepperDialogContextType<StepperDialogValues> | null>(null);

const StepperDialog = <T extends StepperDialogValues>({
  title,
  defaultValues,
  defaultStep,
  defaultLastButtonText,
  children,
  open,
  onClose,
  ...rest
}: StepperDialogProps<T>) => {
  const steps = Children.toArray(children)
    .filter(isValidElement)
    .map((validChild) => (validChild.props as StepperDialogStepProps)?.name);

  const [values, updateValues] = useState(defaultValues || {});
  const [step, setStep] = useState(defaultStep ?? steps[0]);

  const stepIndex = useMemo(() => steps.indexOf(step), [steps, step]);

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose?.(false);
  };

  useEffect(() => {
    if (!open) {
      updateValues({} as T);
      setStep(steps[0]);
    }
  }, [open, steps]);

  return (
    <StepperDialogContext.Provider
      value={{
        values,
        updateValues,
        steps,
        step,
        setStep,
        defaultLastButtonText,
        closeDialog: () => onClose?.(false),
      }}
    >
      <Modal {...rest} open={open} onClose={handleClose}>
        <ModalOverflow>
          <ModalDialog maxWidth="lg">
            <DialogTitle>
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
              >
                <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                  {title}
                </Typography>

                <Stepper
                  size="md"
                  orientation="horizontal"
                  sx={{ flex: `0 1 calc(140px * ${steps.length})` }}
                >
                  {steps.map((name, index) => (
                    <Step
                      key={`step_${name}`}
                      indicator={
                        <StepIndicator
                          variant={stepIndex <= index ? 'soft' : 'solid'}
                          color={stepIndex < index ? 'neutral' : 'primary'}
                        >
                          {stepIndex <= index ? index + 1 : <CheckIcon />}
                        </StepIndicator>
                      }
                    >
                      {name}
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </DialogTitle>

            <DialogContent sx={{ py: 1 }}>
              {cloneElement(children[stepIndex])}
            </DialogContent>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </StepperDialogContext.Provider>
  );
};

StepperDialog.Step = StepperDialogStep;

export default StepperDialog;

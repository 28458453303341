import {
  listClasses,
  listDividerClasses,
  listItemButtonClasses,
  listItemClasses,
  styled,
} from '@mui/joy';
import useControlled from '@mui/utils/useControlled';
import {
  ForwardedRef,
  forwardRef,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';

import { collapseListItemClasses } from 'design-system/components/dataDisplay/list/CollapseListItem';
import Box from 'design-system/components/layout/Box';
import Drawer, {
  DrawerProps,
} from 'design-system/components/navigation/Drawer';
import { navigationBarContentClasses } from 'design-system/components/navigation/NavigationBarContent';
import NavigationBarContext from 'design-system/components/navigation/NavigationBarContext';
import { NavigationBarFooterClasses } from 'design-system/components/navigation/NavigationBarFooter';
import { navigationBarHeaderClasses } from 'design-system/components/navigation/NavigationBarHeader';

export type NavigationBarSlot = 'root' | 'docked';

export const navigationBarClasses = {
  root: 'JoyNavigationBar-root',
  docked: 'JoyNavigationBar-docked',
  hidden: 'JoyNavigationBar-hidden',
  permanent: 'JoyNavigationBar-permanent',
};

export interface NavigationBarProps
  extends Omit<DrawerProps, 'open' | 'onChange'> {
  permanent?: boolean;
  minWidth?: number;
  width?: number;
  open?: boolean;
  defaultOpen?: boolean;
  onChange?: (event: SyntheticEvent, open: boolean) => void;
}

export type NavigationBarOwnerState = NavigationBarProps;

const NavigationBar = (
  props: NavigationBarProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    permanent = false,
    children,
    defaultOpen = permanent ? true : false,
    open: openProp,
    onChange,
    width = 300,
    minWidth = 56,
    ...other
  } = props;

  const [open, setOpenState] = useControlled({
    controlled: openProp,
    default: defaultOpen,
    name: 'NavigationBar',
    state: 'open',
  });

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      setOpenState(!open);

      if (onChange) {
        onChange(event, !open);
      }
    },
    [onChange, open, setOpenState],
  );

  const contextValue = useMemo(
    () => ({ open, toggle: handleChange, permanent }),
    [handleChange, open, permanent],
  );

  const className = useMemo(() => {
    const classNames: string[] = [navigationBarClasses.root];

    !open && classNames.push(navigationBarClasses.hidden);
    permanent && classNames.push(navigationBarClasses.permanent);

    return classNames.join(' ');
  }, [open, permanent]);

  const drawer = (
    <NavigationBarContext.Provider value={contextValue}>
      <JoyNavigationBarRoot
        {...other}
        component={permanent ? 'nav' : Drawer}
        className={className}
        width={width}
        minWidth={minWidth}
        open={open}
        ref={ref}
      >
        {children}
      </JoyNavigationBarRoot>
    </NavigationBarContext.Provider>
  );

  if (permanent)
    return (
      <JoyNavigationBarDocked
        className={className}
        width={width}
        minWidth={minWidth}
      >
        {drawer}
      </JoyNavigationBarDocked>
    );

  return drawer;
};

const JoyNavigationBarDocked = styled('div', {
  name: 'JoyNavigationBar',
  slot: 'docked',
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'minWidth',
})<{ width: number; minWidth: number }>`
  position: relative;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.navigationBar};
  transition-property: width, flex;
  transition-duration: 0.5s;
  width: ${({ width }) => width}px;
  flex: 0 0 ${({ width }) => width}px;

  &.${navigationBarClasses.hidden} {
    width: ${({ minWidth }) => minWidth}px;
    flex: 0 0 ${({ minWidth }) => minWidth}px;
  }
`;

const JoyNavigationBarRoot = styled(Box, {
  name: 'JoyNavigationBar',
  slot: 'root',
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'minWidth',
})<NavigationBarProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;

  & .${navigationBarContentClasses.root} > .${listClasses.root} {
    overflow-x: hidden;

    & .${listItemClasses.root}, & .${collapseListItemClasses.root} {
      & .${listItemButtonClasses.root} {
        border-radius: 8px;
        line-height: 150%;
        white-space: pre;
      }
    }

    & > .${listItemClasses.root}, & > .${collapseListItemClasses.root} {
      &,
      & > .${listItemClasses.root} {
        & .${listItemButtonClasses.root} {
          &:not(.${listItemButtonClasses.disabled}) {
            font-weight: ${({ theme }) => theme.fontWeight.md};
            color: ${({ theme }) =>
              theme.palette.mode === 'light'
                ? theme.palette.neutral[500]
                : theme.palette.neutral[400]};

            &:active,
            :hover {
              color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.neutral[700]
                  : theme.palette.neutral[200]};
            }

            &.${listItemButtonClasses.selected} {
              background-color: transparent;
              font-weight: ${({ theme }) => theme.fontWeight.lg};
              color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.neutral[700]
                  : theme.palette.neutral[200]};
            }
          }
        }
      }

      & .${collapseListItemClasses.content} {
        &
          .${listClasses.root}
          .${listItemClasses.root}
          .${listItemButtonClasses.root} {
          &:not(.${listItemButtonClasses.disabled}) {
            color: ${({ theme }) =>
              theme.palette.mode === 'light'
                ? theme.palette.neutral[700]
                : theme.palette.neutral[200]};

            &:hover {
              background-color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary[50]
                  : theme.palette.primary[900]};
              color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary[700]
                  : theme.palette.primary[200]};
            }

            &:active {
              background-color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary[100]
                  : theme.palette.primary[800]};
              color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary[700]
                  : theme.palette.primary[200]};
            }

            &.${listItemButtonClasses.selected} {
              background-color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary[100]
                  : theme.palette.primary[800]};
              font-weight: ${({ theme }) => theme.fontWeight.lg};
              color: ${({ theme }) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary[700]
                  : theme.palette.primary[200]};
            }
          }
        }
      }
    }
  }

  &.${navigationBarClasses.permanent} {
    width: ${({ width }) => width}px;
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
    transition-property: width;
    transition-duration: 0.5s;

    & > .${navigationBarContentClasses.root} {
      transition-property: padding-left, padding-right;
      transition-duration: 0.5s;
    }

    &
      > .${NavigationBarFooterClasses.root},
      &
      > .${navigationBarHeaderClasses.root} {
      overflow-x: hidden;
      transition-property: opacity;
      transition-duration: 0.1s;
    }

    &.${navigationBarClasses.hidden} {
      width: ${({ minWidth }) => minWidth}px;

      & .${collapseListItemClasses.expanded} {
        grid-template-rows: 0fr;
      }

      & > .${navigationBarContentClasses.root} {
        padding-left: 6px;
        padding-right: 6px;
      }

      &
        > .${NavigationBarFooterClasses.root},
        &
        > .${navigationBarHeaderClasses.root} {
        opacity: 0;
      }
    }
  }

  & .${listDividerClasses.root} {
    transition-property: background-color;
    transition-duration: 0.5s;

    .${navigationBarClasses.hidden} & {
      background-color: transparent;
    }
  }
`;

export default forwardRef<HTMLDivElement, NavigationBarProps>(NavigationBar);

import { Children, cloneElement, isValidElement, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface FieldProps {
  children: ReactElement;
}

const Field = ({ children }: FieldProps) => {
  const { control } = useFormContext();

  return Children.map(children, (child) => {
    if (
      isValidElement<{
        name: string;
      }>(child)
    ) {
      const { name } = child.props;

      if (name)
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, ...other } }) => {
              const fields = {
                ...other,
                value: value ?? '',
              };

              return cloneElement(child, fields);
            }}
          />
        );
    }

    return child;
  });
};

export default Field;

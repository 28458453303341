import { KakaoPostcodeModal } from '@wooriga/design-system';
import {
  PropsWithChildren,
  cloneElement,
  isValidElement,
  Children,
  useState,
  ReactElement,
  MouseEvent,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { DaumPostcodeEmbedProps } from 'react-daum-postcode';

export interface AddressModalTriggerHandles {
  openModal: () => void;
}

export interface AddressModalTriggerProps extends PropsWithChildren {
  onComplete?: DaumPostcodeEmbedProps['onComplete'];
}

const AddressModalTrigger = (
  props: AddressModalTriggerProps,
  ref: ForwardedRef<AddressModalTriggerHandles>,
) => {
  const { children, onComplete } = props;

  const element = Children.only(children);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openModal: handleClick,
  }));

  return (
    <>
      {isValidElement(element)
        ? cloneElement(element as ReactElement, {
            onClick: (event: MouseEvent<HTMLElement>) => {
              element.props.onClick?.(event);
              handleClick();
            },
          })
        : children}

      <KakaoPostcodeModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        postcodeEmbedProps={{
          onComplete: (address) => {
            onComplete?.({
              ...address,
              jibunAddress: address.jibunAddress
                ? address.jibunAddress
                : address.autoJibunAddress,
            });
          },
        }}
      />
    </>
  );
};

export default forwardRef(AddressModalTrigger);

// Example

// 6자리, 제로 패딩(X)
// 950101 -> 95/01/01, 95.01.01, 95-01-01, 95년 01월 01일

// 6자리, 제로 패딩(O)
// 950101 -> 95/1/1, 95.1.1, 95-1-1, 95년 1월 1일

// 8자리, 제로 패딩(X)
// 19950101 -> 1995/01/01, 1995.01.01, 1995-01-01, 1995년 01월 01일

// 8자리, 제로 패딩(O)
// 19950101 -> 1995/1/1, 1995.1.1, 1995-1-1, 1995년 1월 1일

export type BirthDelimiter = '.' | '/' | '-' | 'hangul';
export type FormatBirthOptions = {
  delimiter?: BirthDelimiter;
  shouldHaveZeroPadding?: boolean;
};

const BIRTH_SIX_DIGIT_REGEX = /(\d{2})(\d{2})(\d{2})/g;
const BIRTH_EIGHT_DIGIT_REGEX = /(\d{4})(\d{2})(\d{2})/g;
const BIRTH_ZERO_PADDING_REGEX = /\b0(\d)\b/g;

const convertDelimiterBirth = (
  birth: string,
  delimiter: BirthDelimiter,
  regex: RegExp,
) => {
  if (delimiter === 'hangul') {
    return birth.replace(regex, `$1년$2월$3일`);
  }
  return birth.replace(regex, `$1${delimiter}$2${delimiter}$3`);
};

const removeZeroPadding = (birth: string, delimiter: BirthDelimiter) => {
  return birth
    .split(delimiter)
    .map((value, index) => {
      if (index === 0) {
        return value;
      }

      return value.replace(BIRTH_ZERO_PADDING_REGEX, '$1');
    })
    .join(delimiter);
};

export const formatBirth = (
  birth: string | undefined,
  options?: FormatBirthOptions,
) => {
  if (!birth) {
    return birth;
  }

  const delimiter = options?.delimiter ?? '-';
  const shouldHaveZeroPadding = options?.shouldHaveZeroPadding ?? false;

  const isSixDigit = birth.length === 6;
  const isEightDigit = birth.length === 8;

  let formattedBirth = birth;

  if (isSixDigit) {
    formattedBirth = convertDelimiterBirth(
      birth,
      delimiter,
      BIRTH_SIX_DIGIT_REGEX,
    );
  }

  if (isEightDigit) {
    formattedBirth = convertDelimiterBirth(
      birth,
      delimiter,
      BIRTH_EIGHT_DIGIT_REGEX,
    );
  }

  if (shouldHaveZeroPadding) {
    formattedBirth = removeZeroPadding(formattedBirth, delimiter);
  }

  return formattedBirth;
};

import { Chip, Stack, Typography } from '@wooriga/design-system';

import { Agenda } from 'lim/meetOpen/apis';

import ChoiceAgenda from './ChoiceAgenda';
import OptionAgenda from './OptionAgenda';

interface PreviewProps {
  agenda: Agenda;
}

const PreviewAgenda = ({ agenda }: PreviewProps) => {
  const {
    order,
    name,
    voteForm,
    voteType,
    candidates,
    description,
    selectCount,
  } = agenda;

  const type = voteForm === 'AGAINST' ? '찬반투표' : '선택투표';

  const generateContent = () => {
    if (type === '찬반투표') {
      if (voteType === 'MONO') {
        return '후보가 오직 1건이며, 이에 대한 찬성과 반대를 투표 할 때 선택해주세요.';
      }
      return '후보가 여러 건이며, 각 후보 별로 찬성과 반대를 투표 할 때 선택해주세요.';
    }

    if (voteType === 'MONO') {
      return '안건의 후보가 여러 건이며, 후보들 중 1건 만을 선택할 때 선택해주세요.';
    }
    return '안건의 후보가 여러 건이며, 후보들 중 2건이상 선택할 때 선택해주세요.';
  };

  return (
    <Stack
      width="400px"
      padding={2}
      gap={2}
      border={(theme) => `1px ${theme.palette.neutral[300]} solid`}
      borderRadius={5}
      bgcolor={(theme) => theme.palette.neutral[50]}
    >
      <Stack height="100%" gap={2}>
        <Stack flexDirection="row" gap={1}>
          <Chip>후보 {candidates.length}명</Chip>
          <Typography level="title-lg">{type}</Typography>
        </Stack>
        <Stack>
          <Typography level="body-md">{generateContent()}</Typography>
        </Stack>
      </Stack>

      {type === '선택투표' ? (
        <ChoiceAgenda
          selectCount={selectCount || 1}
          agendaNo={order}
          agendaDes={description}
          agendaName={name}
          candidates={candidates}
        />
      ) : (
        <OptionAgenda
          agendaNo={order}
          agendaDes={description}
          agendaName={name}
          candidates={candidates}
        />
      )}
    </Stack>
  );
};

export default PreviewAgenda;

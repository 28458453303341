import { useState } from 'react';

import { CreatePostSendBody, useCreatePostSendMutation } from 'apis/post/apis';
import { POST_STAPLE_OPTIONS } from 'apis/post/constants';
import PostBalanceStep from 'components/pages/posts/stepper/PostBalanceStep';
import PostDocumentPreviewStep from 'components/pages/posts/stepper/PostDocumentPreviewStep';
import PostElectorStep from 'components/pages/posts/stepper/PostElectorStep';
import PostSenderStep from 'components/pages/posts/stepper/PostSenderStep';
import StepperDialog from 'components/StepperDialog';
import useFeedback from 'hooks/useFeedback';
import {
  MeetsParticipantsParams,
  useMeetsParticipantsQuery,
  VoterMeetsParticipantsParams,
} from 'lim/generalMeetingHistoryDetail/apis';

const voterDefaultParams: VoterMeetsParticipantsParams = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
};

const electronicDefaultParams: MeetsParticipantsParams<'ELECTRONIC'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
  isElectronicVoteResent: '',
  tendency: '',
  receivedAtFrom: '',
  receivedAtTo: '',
  openedAtTo: '',
  openedAtFrom: '',
  votedAtFrom: '',
  votedAtTo: '',
};

const onsiteDefaultParams: MeetsParticipantsParams<'ONSITE'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  tendency: '',
  attendExpectedType: '',
  writtenSubmissionStatus: '',
  writtenSubmissionAtTo: '',
  writtenSubmissionAtFrom: '',
  writtenSubmissionType: '',
};
export interface PostModalProps {
  unionSeq: number;
  meetSeq: number;
  meetMethod: string;
  open: boolean;
  onClose: (show: false) => void;
}

const DEFAULT_VALUES = {
  name: '',
  phoneNo: '',
  address: '',
  detailAddress: '',
  zipNo: '',
  sendQuantity: 0,
  fileSeq: 0,
  postType: '',
  colorType: '',
  flexType: '',
  isStapler: POST_STAPLE_OPTIONS[0].value,
  recipients: [],
  files: [],
};

const PostModal = ({
  unionSeq,
  meetSeq,
  meetMethod,
  open,
  onClose,
}: PostModalProps) => {
  const currentParams =
    meetMethod === 'ELECTRONIC'
      ? electronicDefaultParams
      : meetMethod === 'ONSITE'
      ? onsiteDefaultParams
      : voterDefaultParams;

  const [params, setParams] = useState<
    | MeetsParticipantsParams<'ELECTRONIC' | 'ONSITE'>
    | VoterMeetsParticipantsParams
  >(currentParams);

  const { data: participants } = useMeetsParticipantsQuery<
    'ELECTRONIC' | 'ONSITE'
  >(meetSeq, params);
  const { mutate: sendPost, isPending: isSendPostPending } =
    useCreatePostSendMutation();

  const { snackbar } = useFeedback();

  const handleSubmit = (data: CreatePostSendBody) => {
    if (isSendPostPending) {
      return;
    }
    const request = { ...data, unionSeq };

    sendPost(request, {
      onSuccess: () => {
        snackbar('우편발송 요청이 완료되었습니다.', {
          color: 'success',
        });
        onClose(false);
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const rows = participants?.data || [];

  return (
    <StepperDialog
      title="우편 발송"
      defaultLastButtonText="우편 발송"
      defaultValues={DEFAULT_VALUES}
      open={open}
      onClose={() => {
        onClose(false);
      }}
    >
      <StepperDialog.Step name="수신자 선택">
        <PostElectorStep
          unionSeq={unionSeq}
          rows={rows}
          isElectronic={meetMethod === 'ELECTRONIC'}
          onSearch={setParams}
        />
      </StepperDialog.Step>
      <StepperDialog.Step name="내용 입력">
        <PostSenderStep unionSeq={unionSeq} />
      </StepperDialog.Step>
      <StepperDialog.Step name="미리보기">
        <PostDocumentPreviewStep unionSeq={unionSeq} />
      </StepperDialog.Step>
      <StepperDialog.Step name="비용 조회">
        <PostBalanceStep
          unionSeq={unionSeq}
          onSubmit={handleSubmit}
          isSendPostPending={isSendPostPending}
        />
      </StepperDialog.Step>
    </StepperDialog>
  );
};

export default PostModal;

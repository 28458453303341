import JoyAvatar from '@mui/joy/Avatar';
import type { AvatarProps as JoyAvatarProps } from '@mui/joy/Avatar';
import { ForwardedRef, forwardRef } from 'react';

export type AvatarProps = JoyAvatarProps;

const Avatar = (props: AvatarProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyAvatar {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, AvatarProps>(Avatar);

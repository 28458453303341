import * as _ from 'lodash-es';

export async function findAsync<T = unknown>(
  arr: T[],
  asyncCallback: (value: T, index?: number, array?: T[]) => Promise<boolean>,
): Promise<T | undefined> {
  const promises = arr.map(asyncCallback);
  const results = await Promise.all(promises);
  const index = results.findIndex((result) => result);
  return arr[index];
}

export async function findIndexAsync<T = unknown>(
  arr: T[],
  asyncCallback: (value: T, index?: number, array?: T[]) => Promise<boolean>,
): Promise<number | undefined> {
  const promises = arr.map(asyncCallback);
  const results = await Promise.all(promises);
  const index = results.findIndex((result) => result);
  return index;
}

export const deepFind = (key: string, obj: object) => {
  let result = null;

  const search = (obj: object) => {
    if (!_.isObject(obj)) {
      return;
    }

    for (const k in obj) {
      if (_.has(obj, k)) {
        if (k === key) {
          result = _.get(obj, k);
          return;
        }

        if (_.isObject(obj[k])) {
          search(obj[k]);
        }
      }
    }
  };

  search(obj);
  return result;
};

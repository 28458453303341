import JoyStep from '@mui/joy/Step';
import type { StepProps as JoyStepProps } from '@mui/joy/Step';
import { ForwardedRef, forwardRef } from 'react';

export type StepProps = JoyStepProps & {
  error?: boolean;
  warning?: boolean;
};

const Step = (props: StepProps, ref: ForwardedRef<HTMLLIElement>) => {
  return <JoyStep {...props} ref={ref} />;
};

export default forwardRef<HTMLLIElement, StepProps>(Step);

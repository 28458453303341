/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  AlertDialog,
  Button,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useMemo, useState } from 'react';

import Search from 'components/Search';
import { useUnassignedMembersQuery } from 'lim/auth/apis';
import { AUTH_ADD_TABLE_COLUMNS, AuthTableProps } from 'lim/auth/fixtures';

interface AuthAddModalProps {
  selectedUnions: AuthTableProps[];
  unionSeq: number;
  open: boolean;
  onClose: (open: false) => void;
  onConfirm: (ids: AuthTableProps[]) => void;
}

const defaultParams = {
  name: '',
  phoneNo: '',
};

const AuthAddModal = ({
  selectedUnions,
  unionSeq,
  open,
  onClose,
  onConfirm,
}: AuthAddModalProps) => {
  const [selectedRows, setSelectedRows] = useState<AuthTableProps[]>([]);
  const [alertDialog, setAlertDialog] = useState({
    show: false,
    message: '',
    detail: '',
  });

  const [params, setParams] = useState(defaultParams);

  const { data, isError, error, isLoading } = useUnassignedMembersQuery(
    unionSeq,
    params,
  );

  const handleOnRowSelect = <Model,>(ids: Model) => {
    const rowIds = ids as number[];
    const selectedRows = rows.filter((row) => rowIds.includes(row.id));
    setSelectedRows(selectedRows);
  };

  const handleOnConfirm = () => {
    if (!setSelectedRows.length)
      return setAlertDialog({
        show: true,
        message: '추가할 조합원을 선택해주세요.',
        detail: '추가할 조합원을 선택해주세요.',
      });
    onClose(false);
    onConfirm(selectedRows);
  };

  const rows = useMemo(
    () =>
      data?.data
        ?.filter(
          (row) =>
            !selectedUnions.some(
              (selectedRow) => selectedRow.id === row.unionMemberSeq,
            ),
        )
        .map((row) => {
          const {
            unionMemberSeq,
            member,
            level,
            nickname,
            unionRegister,
            isApproved,
          } = row;

          const validData = () => {
            if (unionRegister) {
              const {
                managementNo,
                unionRegisterNo,
                name,
                birth,
                mainPhone,
                localAddresses,
              } = unionRegister;

              return {
                managementNo,
                unionRegisterNo,
                name: name?.name,
                birth,
                phoneNo: mainPhone?.phoneNo,
                localAddresses: localAddresses?.[0]?.zoneRoad,
              };
            }

            return {
              managementNo: '',
              unionRegisterNo: '',
              name: member?.name,
              birth: member?.birth,
              phoneNo: member?.phoneNo,
              nickname: member?.nickname,
              localAddresses: '',
            };
          };

          return {
            id: unionMemberSeq,
            level,
            nickname,
            isApproved,
            ...validData(),
          };
        }) || [],
    [data?.data, selectedUnions.length],
  );

  useEffect(() => {
    setParams(defaultParams);
    setSelectedRows([]);
  }, [open]);

  if (isError) throw error;

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog minWidth="900px">
          <DialogTitle>권한자 추가</DialogTitle>

          <DialogContent>
            <Typography
              textColor="text.tertiary"
              level="body-md"
              marginBottom={2}
            >
              권한 그룹에 추가할 가입자를 검색해주세요.
            </Typography>
            <Stack gap={2}>
              <Search
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                defaultValues={defaultParams}
                onSubmit={setParams}
              >
                <Stack flexDirection="row" flexWrap="wrap" gap={2}>
                  <Search.Field>
                    <TextField
                      placeholder="연락처 입력"
                      label="연락처"
                      name="phoneNo"
                      validateOptions={{
                        regex: /^[0-9\s]*$/,
                        maxLength: 11,
                      }}
                      fullWidth
                    />
                  </Search.Field>

                  <Search.Field>
                    <TextField
                      placeholder="이름을 입력"
                      label="이름"
                      name="name"
                      fullWidth
                      validateOptions={{
                        maxLength: 30,
                      }}
                    />
                  </Search.Field>
                </Stack>
              </Search>
              <Stack height={442} gap={2}>
                <DataGrid
                  rows={rows}
                  columns={AUTH_ADD_TABLE_COLUMNS}
                  loading={isLoading}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={handleOnRowSelect}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleOnConfirm}>추가</Button>
            <Button
              color="neutral"
              variant="outlined"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal open={alertDialog.show}>
        <AlertDialog
          message={alertDialog.message}
          detail={alertDialog.detail}
          onSubmit={() => setAlertDialog({ ...alertDialog, show: false })}
        />
      </Modal>
    </>
  );
};

export default AuthAddModal;

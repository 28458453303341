import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import useFeedback from 'hooks/useFeedback';
import { useMeetsPasswordPatchMutation } from 'lim/generalMeetingHistoryDetail/apis';

export interface ResetPasswordInfo {
  previousPassword: string;
  password: string;
  confirmPassword: string;
}

interface PasswordResetModalProps {
  meetSeq: number;
  open: boolean;

  onClose: (open: false) => void;
}

const PasswordResetModal = ({
  meetSeq,
  open,

  onClose,
}: PasswordResetModalProps) => {
  const { snackbar, alertDialog } = useFeedback();
  const [resetPasswordInfo, setResetPasswordInfo] = useState<ResetPasswordInfo>(
    {
      previousPassword: '',
      password: '',
      confirmPassword: '',
    },
  );

  const { mutate: patchMutate } = useMeetsPasswordPatchMutation(meetSeq);

  const { previousPassword, password, confirmPassword } = resetPasswordInfo;

  const handleOnChange =
    (key: keyof ResetPasswordInfo) => (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = {
        ...resetPasswordInfo,
        [key]: e.target.value,
      };
      setResetPasswordInfo(newValue);
    };

  const handleOnResetPasswordConfirm = () => {
    const { password, confirmPassword, previousPassword } = resetPasswordInfo;
    const isEmpty = Object.values(resetPasswordInfo).some(
      (resetPassword) => !resetPassword,
    );

    if (isEmpty) return alertDialog('비밀번호를 입력해주세요.');

    if (
      password.length < 4 ||
      confirmPassword.length < 4 ||
      previousPassword.length < 4
    ) {
      return alertDialog('비밀번호는 4자이상 입력하세요.');
    }

    if (password !== confirmPassword)
      return alertDialog('새 비밀번호가 일치하지 않습니다.');

    patchMutate(
      { previousPassword, password },
      {
        onSuccess: () => {
          onClose(false);
          snackbar('비밀번호 변경이 완료되었습니다.', { color: 'success' });
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  useEffect(() => {
    setResetPasswordInfo({
      password: '',
      confirmPassword: '',
      previousPassword: '',
    });
  }, [open]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog minWidth={640}>
        <DialogTitle sx={{ alignSelf: 'center' }}>비밀번호 변경</DialogTitle>
        <DialogContent sx={{ marginBottom: '35px' }}>
          <Stack gap={1.75}>
            <TextField
              label="현재 비밀번호"
              type="password"
              value={previousPassword}
              onChange={handleOnChange('previousPassword')}
              slotProps={{
                input: { minLength: 4 },
              }}
            />
            <TextField
              label="새 비밀번호"
              type="password"
              value={password}
              onChange={handleOnChange('password')}
              slotProps={{
                input: { minLength: 4, maxLength: 10 },
              }}
            />
            <TextField
              label="새 비밀번호 재입력"
              type="password"
              value={confirmPassword}
              onChange={handleOnChange('confirmPassword')}
              slotProps={{
                input: { minLength: 4 },
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button fullWidth onClick={handleOnResetPasswordConfirm}>
            확인
          </Button>

          <Button
            fullWidth
            variant="outlined"
            color="neutral"
            onClick={() => onClose(false)}
          >
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PasswordResetModal;

import { QueryFunctionContext } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { ReactQueryMeta } from 'apis/ReactQueryProvider/types';

// import { QueryMeta } from './type';

export const defaultQueryFn = async ({ queryKey }: QueryFunctionContext) => {
  if (queryKey[1] && typeof queryKey[1] !== 'object')
    throw Error('query second key must be object');
  const key = queryKey[0] as string;
  const params = Object.entries(queryKey[1] || {})
    .filter((param) => param[1])
    .reduce((acc: { [key: string]: unknown }, cur) => {
      acc[cur[0]] = cur[1];
      return acc;
    }, {});

  try {
    if (key.indexOf('/_mock') === 0) {
      const url = key.replace('/_mock', '');
      const { data } = await axiosInstance.get(url, {
        baseURL: '/',
        params,
      });

      // console.log('is mock');
      // console.log('url : ', url);

      return data;
    } else {
      const url = `/${key}`.replace('//', '/');
      const { data } = await axiosInstance.get(url, {
        params,
      });

      // console.log('is not mock');
      // console.log('url : ', url);

      return data;
    }
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }
  }
};

export const toast = (message: string) => {
  window.alert(message);
};

export const handleToast = (
  { meta }: ReactQueryMeta,
  type: 'success' | 'fail',
) => {
  const toastMessage = meta?.toast?.[type];

  if (toastMessage) toast(toastMessage);
};

import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

import counselsPage from 'pages/main/counsel-management/counsels';
import counselCreatePage from 'pages/main/counsel-management/create';
import { CustomRouteObject } from 'types/route';

const counselManagementPage: CustomRouteObject = {
  handle: {
    getTitle: () => '상담 관리',
    getIcon: () => <TextsmsOutlinedIcon />,
    getMenuCode: () => 'M09',
  },
  children: [counselCreatePage, counselsPage],
};

export default counselManagementPage;

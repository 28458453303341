import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  AspectRatio,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useState } from 'react';

import FileView from 'components/pages/common/MeetsInfoDescription/VoteModal/FileView';
import { CreateMeetBody } from 'lim/meetOpen/apis';

const lists = [
  { label: '찬성', value: 1 },
  { label: '반대', value: 2 },
  { label: '기권', value: 3 },
];

interface OptionAgendaProps {
  agendaName: string;
  agendaNo: number;
  agendaDes: string;
  candidates: CreateMeetBody['agendas'][number]['candidates'];
}

const OptionAgenda = ({
  agendaNo,
  agendaName,
  agendaDes,
  candidates,
}: OptionAgendaProps) => {
  const [checkedLists, setCheckedLists] = useState<Record<string, number>>({});

  const handleCheck = (name: string, value: number) => {
    setCheckedLists({
      ...checkedLists,
      [name]: value,
    });
  };

  return (
    <Stack gap={3}>
      <Stack>
        <Stack
          position="relative"
          borderRadius="8px"
          padding="24px 24px 16px 24px"
          bgcolor={(theme) => theme.palette.background.body}
          boxShadow={(theme) => `0px 0px 5px ${theme.palette.neutral[300]}`}
          gap={3}
        >
          <Chip
            sx={{
              color: '#ffffff',
              backgroundColor: '#215DBA',
              position: 'absolute',
              padding: '4px 16px',
              top: '0',
              left: '0',
              right: '0',
              margin: 'auto',
              transform: 'translateY(-50%)',
            }}
          >
            {agendaNo}호 안건
          </Chip>
          <Stack alignItems="center">
            <Typography sx={{ wordBreak: 'break-word' }} level="title-lg">
              {agendaName}
            </Typography>

            <AccordionGroup>
              <Accordion>
                <AccordionSummary sx={{ alignSelf: 'center', width: 130 }}>
                  안건 보기
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ lineBreak: 'anywhere' }}>
                    {agendaDes}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </Stack>
        </Stack>
      </Stack>
      {candidates.map((candidate) => {
        const { no, name, description, attachFileSeq } = candidate;

        return (
          <Stack
            key={no}
            justifyContent="space-between"
            borderRadius="md"
            padding="14px"
            bgcolor={(theme) => theme.palette.background.body}
            boxShadow={(theme) => `0px 0px 5px ${theme.palette.neutral[300]}`}
            gap={3}
          >
            <Stack alignItems="center" gap={1}>
              <AspectRatio
                variant="plain"
                objectFit="contain"
                sx={{ width: 166 }}
                ratio="1/1"
              >
                {attachFileSeq ? (
                  <FileView type="img" fileSeq={attachFileSeq} />
                ) : (
                  <img
                    src="/images/meet-open/candidate.svg"
                    alt="candidate_image"
                  />
                )}
              </AspectRatio>

              <Stack>
                <Typography
                  sx={{ wordBreak: 'break-word' }}
                  level="title-lg"
                  textAlign="center"
                >
                  {no}번. {name}
                </Typography>

                <AccordionGroup>
                  <Accordion>
                    <AccordionSummary sx={{ alignSelf: 'center', width: 150 }}>
                      상세설명 보기
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ wordBreak: 'break-word' }}>
                        {description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </AccordionGroup>
              </Stack>
            </Stack>
            <Stack flexDirection="row" justifyContent="center">
              {lists.map((list, i) => {
                const radius =
                  i === 0
                    ? '6px 0px 0px 6px'
                    : i === 2
                    ? '0px 6px 6px 0px'
                    : '0';

                const isLast = i !== lists.length - 1;
                const isChecked = checkedLists[name] === list.value;

                return (
                  <Stack
                    width="100%"
                    // height="300px"
                    key={list.label}
                    border={(theme) =>
                      `1px solid ${theme.palette.neutral[300]}`
                    }
                    borderRadius={radius}
                    borderRight={(theme) =>
                      !isLast ? `1px solid ${theme.palette.neutral[300]}` : `0`
                    }
                  >
                    <Stack
                      borderRadius={radius}
                      width="100%"
                      alignItems="center"
                      padding="6px 10px"
                      bgcolor={(theme) => theme.palette.neutral[50]}
                    >
                      <Typography>{list.label}</Typography>
                    </Stack>
                    <Divider />
                    <Button
                      sx={{ padding: '20px' }}
                      variant="plain"
                      color="danger"
                      value={list.value}
                      onClick={() => handleCheck(name, list.value)}
                    >
                      <img
                        width={48}
                        src={
                          isChecked
                            ? '/images/meet-open/vote_checked.svg'
                            : '/images/meet-open/vote.svg'
                        }
                        alt="vote_image"
                      />
                    </Button>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default OptionAgenda;

import JoyAvatarGroup from '@mui/joy/AvatarGroup';
import type { AvatarGroupProps as JoyAvatarGroupProps } from '@mui/joy/AvatarGroup';
import { ForwardedRef, forwardRef } from 'react';

export type AvatarGroupProps = JoyAvatarGroupProps;

const AvatarGroup = (
  props: AvatarGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyAvatarGroup {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, AvatarGroupProps>(AvatarGroup);

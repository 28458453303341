import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

const INITIAL_VALUES = {
  count: 0,
};

export interface MessageCallerDeletionModalProps
  extends Omit<ModalProps, 'onSubmit' | 'onClose' | 'children'> {
  data?: { count: number };
  onSubmit?: (value: string) => void;
  onClose: (value: boolean) => void;
}

const MessageCallerDeletionModal = (props: MessageCallerDeletionModalProps) => {
  const { data = INITIAL_VALUES, onSubmit, onClose, ...rest } = props;
  const { count } = data;

  const [password, setPassword] = useState<string>('');

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleSubmit = () => {
    onSubmit?.(password);
  };

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog>
          <DialogTitle>발신번호를 삭제하시겠습니까?</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack direction="column" gap={2}>
              <Typography level="body-md">
                선택하신 항목 총 {count}개 발신번호를 삭제하시겠습니까? <br />
                삭제하시려면 비밀번호를 입력해주세요.
              </Typography>

              <TextField
                type="password"
                label="비밀번호"
                placeholder="비밀번호 입력"
                value={password}
                onChange={handleChange}
                required
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="danger"
              disabled={!password}
              onClick={handleSubmit}
            >
              삭제
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default MessageCallerDeletionModal;

import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import useFeedback from 'hooks/useFeedback';

interface MeetCompleteModalProps {
  open: boolean;
  onClose: (open: false) => void;
  onSubmit: (password: string) => void;
}

const MeetCompleteModal = ({
  open,

  onClose,
  onSubmit,
}: MeetCompleteModalProps) => {
  const { alertDialog } = useFeedback();
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [password, setPassword] = useState('');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleOnSubmit = () => {
    if (!isChecked) {
      return alertDialog(
        '총회 종료 안내사항을 확인하시고 체크박스를 선택해 주세요.',
      );
    }

    if (!password) {
      return alertDialog('비밀번호를 입력해주세요.');
    }

    onClose(false);

    onSubmit(password);
  };

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
    setPassword('');
  };

  useEffect(() => {
    setPassword('');
    setIsChecked(false);
    setIsDisabled(false);
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <Typography
            alignSelf="center"
            color="danger"
            level="h2"
            startDecorator={<InfoIcon />}
          >
            총회 종료 주의사항
          </Typography>
          <DialogContent>
            <Stack gap={3}>
              <Callout color="danger" variant="soft">
                <Typography level="title-lg">
                  <strong>총회가 종료되면 더 이상 수정이 불가합니다.</strong>
                </Typography>
                <Typography level="body-md">
                  선거 관리 시스템(VMS)과 조합운영관리시스템(OMS)에서 해당
                  총회에 대한 모든 정보 입력과 수정이 불가능해지며, 시점 확인이
                  이루어진 모든 전자투표 기록과 개표 결과는 공인전자문서센터에
                  안전하게 보관됩니다.
                  <br />
                  <br />
                  한번 보관된 문서는 안전한 법적 효력을 위하여 수정될 수
                  없습니다. 수정이 필요하시면 재입력 버튼을 눌러
                  선거관리시스템(VMS)에서 추가 작업을 완료한 뒤 개표 결과를
                  입력해 주세요.
                </Typography>
              </Callout>

              <Checkbox
                color="primary"
                label="확인하였습니다."
                onChange={(e) => setIsChecked(e.target.checked)}
              />

              <Input
                type="password"
                name="password"
                placeholder="총회 비밀번호를 입력하세요."
                onChange={handleOnChange}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isDisabled}
              fullWidth
              type="submit"
              onClick={handleOnSubmit}
            >
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={handleClose}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default MeetCompleteModal;

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { MeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { formatDateTime } from 'utils/format';

import { translateMeetMethod } from '../../../meet-management/history/detail/tabs/VoteHomeTab/utils';

interface DescriptionsProps {
  meetsDetailQuery: MeetsDetailQuery;
}

const Descriptions = ({ meetsDetailQuery }: DescriptionsProps) => {
  const { data } = meetsDetailQuery;

  if (!data?.data) return;

  const {
    meetMethod,
    participantCount,
    agendaCount,
    electronicVote,
    onsiteVote,
  } = data.data;

  if (meetMethod === 'ONSITE') {
    if (!onsiteVote) return <></>;
    const {
      submissionStartAt,
      submissionEndAt,
      meetAt,
      roadAddress,
      contactNo,
    } = onsiteVote;
    const onsiteJSON = generateDescriptionsJSON(
      [
        '총회 방식',
        '총 선거인',
        '안건 수',

        '서면 제출 시작',
        '서면 제출 마감',
        '총회일',

        '조합 전화번호',
        '총회 장소 ',
      ],
      [
        translateMeetMethod(meetMethod),
        participantCount,
        agendaCount,
        formatDateTime(submissionStartAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        formatDateTime(submissionEndAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        formatDateTime(meetAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        contactNo,
        roadAddress,
      ],
    );

    return <DescriptionsRenderer columns={3} json={onsiteJSON} />;
  }

  if (meetMethod === 'ELECTRONIC') {
    if (!electronicVote) return <></>;
    const { contactNo, voteStartAt, voteEndAt, description, senderName } =
      electronicVote;
    const electronicJSON = generateDescriptionsJSON(
      [
        '총회 방식',
        '총 선거인',
        '안건 수',
        '문의 전화번호',
        '전자투표 시작',
        '전자투표 종료',
        '발송 단체명',
        '전자투표 내용 ',
      ],
      [
        translateMeetMethod(meetMethod),
        participantCount,
        agendaCount,
        contactNo,
        formatDateTime(voteStartAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        formatDateTime(voteEndAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        senderName,
        description,
      ],
    );

    return <DescriptionsRenderer columns={3} json={electronicJSON} />;
  }

  if (meetMethod === 'ONSITE_ELECTRONIC') {
    if (!electronicVote || !onsiteVote) return <></>;
    const { contactNo, voteStartAt, voteEndAt, description, senderName } =
      electronicVote;
    const {
      submissionStartAt,
      submissionEndAt,
      meetAt,
      roadAddress,
      contactNo: unionContactNo,
    } = onsiteVote;
    const overAllJSON = generateDescriptionsJSON(
      [
        '총회 방식',
        '총 선거인',
        '안건 수',
        '조합 전화번호',
        '문의 전화번호',

        '전자투표 시작',
        '전자투표 종료',
        '서면 제출 시작',
        '서면 제출 마감',
        '총회일',

        '발송 단체명',
        '총회 장소 ',
        '전자투표 내용 ',
      ],
      [
        translateMeetMethod(meetMethod),
        participantCount.toString(),
        agendaCount.toString(),
        unionContactNo,
        contactNo,

        formatDateTime(voteStartAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        formatDateTime(voteEndAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        formatDateTime(submissionStartAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        formatDateTime(submissionEndAt, 'yyyy-MM-dd (EEE) HH시 mm분'),
        formatDateTime(meetAt, 'yyyy-MM-dd (EEE) HH시 mm분'),

        senderName,
        roadAddress,
        description,
      ],
    );

    return <DescriptionsRenderer columns={3} json={overAllJSON} />;
  }
};

export default Descriptions;

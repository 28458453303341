import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import useFeedback from 'hooks/useFeedback';
import { MeetsPromotionUserDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { PromotionUserUpdateBody } from 'lim/meetPromotion/apis';

interface PromotionHeadEditModalProps {
  open: boolean;
  promotionUserDetailQuery: MeetsPromotionUserDetailQuery;
  onClose: (show: false) => void;
  onSubmit: (formData: PromotionUserUpdateBody) => void;
}

const PromotionHeadEditModal = ({
  open,
  promotionUserDetailQuery,
  onClose,
  onSubmit,
}: PromotionHeadEditModalProps) => {
  const { data, isError, error } = promotionUserDetailQuery;

  const { confirmDialog } = useFeedback();
  const { getGroupCode } = useCommonCodes();

  const { control, handleSubmit: validateSubmit, reset } = useForm();

  const codeGroup = useMemo(
    () => getGroupCode('PROMOTION_USER_STATUS'),
    [getGroupCode],
  );

  const handleSubmit = validateSubmit((data) => {
    confirmDialog('수정하시겠습니까?', {
      onConfirm: () => {
        onSubmit({
          userStatus: data.userStatus,
          memo: data.memo,
        });
      },
    });
  });

  useEffect(() => {
    reset({
      id: data?.data.id ?? '',
      name: data?.data.name ?? '',
      memo: data?.data.memo ?? '',
      userStatus: data?.data.userStatus ?? '',
    });
  }, [data?.data, reset]);

  if (isError) throw error;

  return (
    <Modal
      component="form"
      open={open}
      onClose={() => onClose(false)}
      onSubmit={handleSubmit}
    >
      <ModalDialog sx={{ width: '500px' }}>
        <DialogTitle>홍보 팀장 정보 수정</DialogTitle>
        <DialogContent>
          해당 홍보 팀장의 정보를 수정 하시겠습니까?
        </DialogContent>

        <Stack gap={2}>
          <Controller
            control={control}
            name="id"
            disabled
            render={({ field }) => <TextField label="아이디" {...field} />}
          />

          <Controller
            control={control}
            name="name"
            disabled
            render={({ field }) => <TextField label="이름" {...field} />}
          />

          <Controller
            control={control}
            name="memo"
            render={({ field }) => (
              <TextField label="메모" placeholder="메모 입력" {...field} />
            )}
          />

          <Controller
            control={control}
            name="userStatus"
            render={({ field }) => (
              <RadioGroup label="계정상태" orientation="horizontal" {...field}>
                {codeGroup?.items.map(({ code, name }) => (
                  <Radio
                    key={`user_status_${code}_radio`}
                    label={name}
                    value={code}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </Stack>

        <DialogActions>
          <Button onClick={handleSubmit}>확인</Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => onClose(false)}
          >
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PromotionHeadEditModal;

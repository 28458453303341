// import { GridColDef } from '@mui/x-data-grid-pro';
import {
  Descriptions,
  DescriptionsItem,
  Stack,
  Typography,
  DataGrid,
  Button,
  Pagination,
  Grid,
  Link,
  useGridUtils,
  DateRangePicker,
  GridColDef,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { TransactionListParams, useChargeListQuery } from 'apis/charges/apis';
import CommonCode from 'components/CommonCode';
import ConfirmChargeModal from 'components/pages/charges/components/ConfirmChargeModal';
import Search from 'components/Search';
import useChargeModal from 'hooks/useChargeModal';
import useLayoutContext from 'hooks/useLayoutContext';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber, formatDateTime } from 'utils/format';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

export interface ChargeSearchFormValues {
  searchFromTo?: string[];
  unionSeq: number;
  page?: number;
  pageSize?: number;
}

export interface ChargeListRow {
  transactionSeq: number;
  serviceType: string;
  content: string;
  totalChargeAmount: number;
  totalUsageAmount: number;
  totalRefundAmount: number;
  totalBalance: number;
  requestedAt?: string;
  completedAt?: string;
}

export const CHARGE_COLUMN = ({
  onClickConfirmCharge,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickConfirmCharge: (transactionSeq: number) => void;
}>) =>
  [
    {
      field: 'serviceType',
      headerName: '서비스 구분',
      valueGetter: (_, row) =>
        getCommonCode('UNION_CHARGE_USE_DIVIDE', row?.serviceType)?.name,
    },
    {
      field: 'content',
      headerName: '충전 및 사용내역',
      renderCell: (params) => {
        if (
          params?.row?.serviceType === 'COMPLETE' ||
          params?.row?.serviceType === 'REQUEST'
        ) {
          return (
            <Link
              display="inline"
              width="100%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              onClick={() => {
                onClickConfirmCharge(params?.row?.transactionSeq);
              }}
            >
              {params?.formattedValue}
            </Link>
          );
        } else {
          return params?.formattedValue;
        }
      },
    },

    {
      field: 'requestedAt',
      headerName: '신청일시',
      valueFormatter: (params) => {
        return params ? formatDateTime(params, 'yyyy-MM-dd HH:mm:ss') : '';
      },
    },
    {
      field: 'completedAt',
      headerName: '완료일시',
      valueFormatter: (params) => {
        return params ? formatDateTime(params, 'yyyy-MM-dd HH:mm:ss') : '';
      },
    },
    {
      field: 'creditType',
      headerName: '사용 및 환불 구분',
      renderCell: (params) => {
        return params?.formattedValue;
      },
    },
    {
      field: 'totalChargeAmount',
      headerName: '충전액',
      renderCell: (params) => {
        return params?.value ? `${commaizeNumber(params?.value)} 원 ` : '-';
      },
    },
    {
      field: 'totalUsageAmount',
      headerName: '사용액',
      renderCell: (params) => {
        return params?.value ? `${commaizeNumber(params?.value)} 원 ` : '-';
      },
    },
    {
      field: 'totalRefundAmount',
      headerName: '환불액',
      renderCell: (params) => {
        return params?.value ? `${commaizeNumber(params?.value)} 원 ` : '-';
      },
    },
    {
      field: 'totalBalance',
      headerName: '잔액',
      renderCell: (params) => {
        return `${commaizeNumber(params?.value)} 원 `;
      },
    },
  ] as GridColDef[];

// UNION_SERVICE_DIVIDE

const ChargePage = () => {
  const { pageContext } = useLayoutContext();
  const { memberUnionInfo, unionServiceInfo } = pageContext || {};

  const DEFAULT_SEARCH_PARAMS = {
    unionSeq: memberUnionInfo?.unionSeq || 0,
    searchFormTo: ['', ''],
    searchFrom: '',
    searchTo: '',
  };
  const { openChargeModal, openTaxBillModal } = useChargeModal();

  const onClickConfirmCharge = (transactionSeq: number) => {
    setConfirmChargeModalOpen(true);
    setSelectedTransactionSeq(transactionSeq);
  };

  const { columns } = useCreateGridColumns({
    handlers: { onClickConfirmCharge: onClickConfirmCharge },
    columns: CHARGE_COLUMN,
  });

  const useCountInfo = [
    {
      label: '조합등급',
      children: (
        <CommonCode
          groupCode="UNION_SERVICE_DIVIDE"
          code={unionServiceInfo?.serviceDivisionCode || ''}
        />
      ),
    },
    {
      label: '서비스 기간',
      children: `${formatDateTime(
        unionServiceInfo?.serviceStartAt,
        'yyyy-MM-dd',
      )} ~ ${formatDateTime(unionServiceInfo?.serviceEndAt, 'yyyy-MM-dd')}`,
    },
    {
      label: '예치금 잔액',
      children: commaizeNumber(unionServiceInfo?.depositBalance || 0) + '원',
    },
    {
      label: '포인트 잔액',
      children: commaizeNumber(unionServiceInfo?.pointBalance || 0) + '원',
    },
  ];

  // depositSeq

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [confirmChargeModalOpen, setConfirmChargeModalOpen] = useState(false);

  const [selectedTransactionSeq, setSelectedTransactionSeq] =
    useState<number>(0);

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'charge-list',
    activeSaveSnapshot: true,
  });

  const [searchParams, setSearchParams] = useState<TransactionListParams>({
    ...paginationModel,
    ...DEFAULT_SEARCH_PARAMS,
  });

  const {
    data: chargeListReturnData,
    isLoading,
    isPending,
    isError,
    error,
    refetch: refetchChargeList,
  } = useChargeListQuery(searchParams);

  const handleSearchParams = (
    values: Partial<ChargeSearchFormValues> | undefined,
  ) => {
    if (!values) {
      return;
    }
    const { searchFromTo, ...other } = values;
    const body: TransactionListParams = {
      unionSeq: memberUnionInfo?.unionSeq || 0,
      ...other,
    };

    if (searchFromTo) {
      body.searchFrom = values.searchFromTo?.[0];
      body.searchTo = values.searchFromTo?.[1];
    }

    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...body }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleOpenChargeModal = () => {
    openChargeModal({
      unionSeq: memberUnionInfo?.unionSeq || 0,
      unionName: memberUnionInfo?.name ?? '',
      onSubmit: ({ seq }) => {
        if (!seq) {
          return;
        }

        refetchChargeList();
        setSelectedTransactionSeq(seq);
      },
    });
  };

  const rows = useMemo(
    () => chargeListReturnData?.data || [],
    [chargeListReturnData],
  );

  const totalElements = chargeListReturnData?.pagination?.totalElements;
  const totalDataCount = chargeListReturnData?.pagination?.totalDataCount;
  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  if (isError) {
    throw error;
  }
  return (
    <Stack gap={6}>
      <Stack gap={2}>
        <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
          충전 및 사용 관리
        </Typography>

        <Descriptions variant="soft" color="primary" size="lg" columns={4}>
          {useCountInfo.map(({ label, children }) => (
            <DescriptionsItem key={label} label={label}>
              {children}
            </DescriptionsItem>
          ))}
        </Descriptions>
      </Stack>

      <Stack>
        <Stack gap={2}>
          <Search
            defaultValues={DEFAULT_SEARCH_PARAMS}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid xs={12}>
              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <DateRangePicker
                    label="기간 조회"
                    name="searchFromTo"
                    sx={{ field: { xs: 12, maxWidth: 200 } }}
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>

          <Stack gap={2} pt={3}>
            <Stack flexDirection="row" gap={1}>
              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                전체{' '}
                <Typography color="primary">
                  {commaizeNumber(totalDataCount)}
                </Typography>
              </Typography>

              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                조회 목록{' '}
                <Typography color="primary">
                  {commaizeNumber(totalElements)}
                </Typography>
              </Typography>
            </Stack>
            <Stack sx={{ height: 442, width: '100%' }}>
              <DataGrid
                apiRef={datagridApiRef}
                columns={columns}
                rows={rows}
                loading={isLoading || isPending}
                disableRowSelectionOnClick
                getRowId={(row) => {
                  return row.no;
                }}
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            </Stack>
            <Stack alignItems="center">
              <Pagination
                color="neutral"
                variant="plain"
                size="md"
                orientation="horizontal"
                showFirstButton={false}
                showLastButton={false}
                hidePrevButton={false}
                hideNextButton={false}
                count={pageTotalCount}
                onChange={(_, page) => {
                  page &&
                    setPaginationModel({
                      ...paginationModel,
                      page: page - 1,
                    });
                }}
              ></Pagination>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Button
              color="neutral"
              variant="outlined"
              onClick={() => {
                exportExcel();
              }}
            >
              내역 다운
            </Button>
            <Button color="primary" onClick={handleOpenChargeModal}>
              충전 신청
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <ConfirmChargeModal
        confirmChargeModalOpen={confirmChargeModalOpen}
        transactionSeq={selectedTransactionSeq}
        onClose={() => setConfirmChargeModalOpen(false)}
        refetchChargeList={refetchChargeList}
        setApplyTaxModalOpen={() => {
          openTaxBillModal({
            unionSeq: memberUnionInfo?.unionSeq || 0,
            depositChargeSeq: selectedTransactionSeq,
          });
        }}
      />
    </Stack>
  );
};

const chargesPage: CustomRouteObject = {
  path: '/unions/:unionSeq/etc-management/charges',
  element: <ChargePage />,
  handle: {
    getTitle: () => '충전 및 사용 관리',
    getMenuCode: () => 'M1103',
  },
};

export default chargesPage;

import JoySheet from '@mui/joy/Sheet';
import type { SheetProps as JoySheetProps } from '@mui/joy/Sheet';
import { ForwardedRef, forwardRef } from 'react';

export type SheetProps = JoySheetProps;

const Sheet = (props: SheetProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoySheet {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, SheetProps>(Sheet);

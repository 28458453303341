import { Button, ButtonProps } from '@wooriga/design-system';
import { createElement, useCallback, useContext, useMemo } from 'react';

import {
  StepperDialogContext,
  StepperDialogValues,
} from 'components/StepperDialog';

const useStepperDialog = <T extends StepperDialogValues>() => {
  const contextValues = useContext(StepperDialogContext);

  if (contextValues === null) {
    throw new Error('StepperDialogContext를 찾을 수 없습니다.');
  }

  const {
    values,
    updateValues,
    steps,
    step,
    setStep,
    defaultLastButtonText,
    closeDialog,
  } = contextValues;

  const stepIndex = useMemo(() => steps.indexOf(step), [steps, step]);

  const stepTo = useCallback(
    (step: string) => {
      setStep(step);
    },
    [setStep],
  );

  const stepNext = useCallback(() => {
    if (stepIndex + 1 >= steps.length) {
      closeDialog();
      return;
    }

    setStep(steps[stepIndex + 1]);
  }, [stepIndex, steps, setStep, closeDialog]);

  const stepPrev = useCallback(() => {
    if (stepIndex - 1 < 0) {
      closeDialog();
      return;
    }

    setStep(steps[stepIndex - 1]);
  }, [stepIndex, steps, setStep, closeDialog]);

  const PreviousButton = useCallback(
    (props: ButtonProps) =>
      createElement(
        Button,
        {
          ...props,
          variant: props.variant ?? 'outlined',
          color: props.color ?? 'neutral',
          onClick: (event) => {
            props.onClick?.(event);
            stepPrev();
          },
        },
        stepIndex === 0 ? '취소' : '이전',
      ),
    [stepIndex, stepPrev],
  );

  const NextButton = useCallback(
    (props: ButtonProps) =>
      createElement(
        Button,
        {
          ...props,
          variant: props.variant ?? 'solid',
          color: props.color ?? 'primary',
          onClick: (event) => {
            props.onClick?.(event);
            stepNext();
          },
        },
        stepIndex === steps.length - 1
          ? defaultLastButtonText ?? '확인'
          : '다음',
      ),
    [steps, stepIndex, defaultLastButtonText, stepNext],
  );

  return {
    values: values as T,
    updateValues,
    stepTo,
    stepNext,
    stepPrev,
    PreviousButton,
    NextButton,
  };
};

export default useStepperDialog;

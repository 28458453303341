import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { styled } from '@mui/joy';
import type { ListItemProps as JoyListItemProps } from '@mui/joy/ListItem';
import { unstable_isMuiElement as isMuiElement } from '@mui/utils';
import {
  Children,
  cloneElement,
  ForwardedRef,
  forwardRef,
  isValidElement,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { ListProps } from 'design-system/components/dataDisplay/list/List';
import ListItem from 'design-system/components/dataDisplay/list/ListItem';
import { ListItemButtonProps } from 'design-system/components/dataDisplay/list/ListItemButton';
import NavigationBarContext from 'design-system/components/navigation/NavigationBarContext';

export type CollapseListItemSlot = 'root' | 'content';

export const collapseListItemClasses = {
  root: 'JoyCollapseListItem-root',
  content: 'JoyCollapseListItem-content',
  expanded: 'JoyCollapseListItem-expanded',
};

export interface CollapseListItemProps extends JoyListItemProps {
  in?: boolean;
  expandLessIcon?: ReactNode;
  expandMoreIcon?: ReactNode;
}

const CollapseListItem = (
  props: CollapseListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const {
    expandLessIcon = <ExpandLessRoundedIcon />,
    expandMoreIcon = <ExpandMoreRoundedIcon />,
    in: inProp,
    children: childrenProp,
    ...other
  } = props;

  const [open, setOpen] = useState(inProp);

  const navigationBarContext = useContext(NavigationBarContext);

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (navigationBarContext && !navigationBarContext.open) {
        return navigationBarContext.toggle(event);
      }

      setOpen((state) => !state);
    },
    [navigationBarContext],
  );

  const render = useMemo(() => {
    let list: ReactNode;
    const children: ReactNode[] = [];

    Children.forEach(childrenProp, (child, index) => {
      if (
        isValidElement<ListItemButtonProps>(child) &&
        isMuiElement(child, ['ListItemButton'])
      ) {
        return children.push(
          cloneElement(
            child,
            {
              key: index,
              onClick: handleClick,
            },
            child.props.children,
            open ? expandLessIcon : expandMoreIcon,
          ),
        );
      }

      if (isValidElement<ListProps>(child) && isMuiElement(child, ['List'])) {
        return (list = child);
      }

      return children.push(child);
    });

    return { list, children };
  }, [childrenProp, expandLessIcon, expandMoreIcon, handleClick, open]);

  useLayoutEffect(() => {
    setOpen(inProp);
  }, [inProp]);

  return (
    <CollapseListItemRoot className={collapseListItemClasses.root}>
      <ListItem {...other} ref={ref}>
        {render?.children}
      </ListItem>

      <CollapseListItemContent
        className={
          open
            ? `${collapseListItemClasses.content} ${collapseListItemClasses.expanded}`
            : collapseListItemClasses.content
        }
      >
        <CollapseListItemContentInner>
          {render?.list}
        </CollapseListItemContentInner>
      </CollapseListItemContent>
    </CollapseListItemRoot>
  );
};

const CollapseListItemRoot = styled('div', {
  name: 'JoyCollapseListItem',
  slot: 'root',
})``;

const CollapseListItemContent = styled('div', {
  name: 'JoyCollapseListItem',
  slot: 'content',
})`
  display: grid;
  grid-template-rows: 0fr;
  transition-property: grid-template-rows;
  transition-duration: 0.25s;

  &.${collapseListItemClasses.expanded} {
    grid-template-rows: 1fr;
  }
`;

const CollapseListItemContentInner = styled('div')`
  overflow-y: hidden;
`;

export default forwardRef<HTMLLIElement, CollapseListItemProps>(
  CollapseListItem,
);

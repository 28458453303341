import JoyMenuItem from '@mui/joy/MenuItem';
import type { MenuItemProps as JoyMenuItemProps } from '@mui/joy/MenuItem';
import { ForwardedRef, forwardRef } from 'react';

export type MenuItemProps = JoyMenuItemProps;

const MenuItem = (props: MenuItemProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyMenuItem {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, MenuItemProps>(MenuItem);

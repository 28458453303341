import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { UnionMemberAvailableMenu } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

export interface MemberAvailableMenusParams {
  unionSeq: number;
  unionMemberSeq: number;
}

export type MemberAvailableMenusReturnData = UnionMemberAvailableMenu[];

export const memberAvailableMenusQuery = ({
  unionSeq,
  unionMemberSeq,
}: MemberAvailableMenusParams) => ({
  queryKey: [`/unions/${unionSeq}/members/${unionMemberSeq}/available-menus`],
});

export const useMemberAvailableMenusQuery = (
  params: MemberAvailableMenusParams,
): UseQueryResult<ApiResponseData<MemberAvailableMenusReturnData>, ApiError> =>
  useQuery(memberAvailableMenusQuery(params));

import { useContext } from 'react';

import CheckboxGroupContext, {
  CheckboxGroupContextValue,
} from 'design-system/components/inputs/checkbox/CheckboxGroupContext';

export interface CheckboxGroupState extends CheckboxGroupContextValue {}

const useCheckboxGroup = (): CheckboxGroupState | undefined => {
  return useContext(CheckboxGroupContext);
};

export default useCheckboxGroup;

import {
  Stack,
  Typography,
  Button,
  Option,
  Select,
  Link,
} from '@wooriga/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useLevelUpdateMutation,
  usePasswordVerifyMutation,
  useUnionMemberQuery,
} from 'apis/union/member/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import PasswordConfirmModal from 'components/pages/members/PasswordConfirmModal';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { CustomRouteObject } from 'types/route';
import { formatBirth, formatDate, formatTelephone } from 'utils/format';

const LEVELGROUPS = {
  OWNER: [
    {
      code: 'EXECUTIVE',
      name: '운영진',
    },
    {
      code: 'OWNER',
      name: '소유자',
    },
    {
      code: 'DELETE',
      name: '권한없음',
    },
  ],
  SUBSRIBER: [
    {
      code: 'SUBSCRIBER',
      name: '가입자',
    },
    {
      code: 'EXECUTIVE',
      name: '운영진',
    },
    {
      code: 'DELETE',
      name: '권한없음',
    },
  ],
  UNAPPROVER: [
    {
      code: 'UNAPPROVER',
      name: '미승인자',
    },
  ],
  EXECUTEIVE: [
    {
      code: 'EXECUTIVE',
      name: '운영진',
    },
    {
      code: 'DELETE',
      name: '권한없음',
    },
  ],
};

const MemberDetailPage = () => {
  const { pageContext } = useLayoutContext();
  const { snackbar } = useFeedback();
  const maskingText =
    pageContext?.memberInfo?.email || pageContext?.memberInfo?.name;
  const memberSeq = Number(pageContext?.memberInfo?.memberSeq);
  const { unionSeq, unionMemberSeq } = useParams();
  const unionId = Number(unionSeq);
  const unionMemberId = Number(unionMemberSeq);

  const [showModal, setShowModal] = useState(false);

  const [isMasking, setIsMasking] = useState(true);

  const { data, refetch, isSuccess, isError, error } = useUnionMemberQuery(
    unionId,
    unionMemberId,
    isMasking,
  );

  const { mutate } = usePasswordVerifyMutation(memberSeq);
  const { mutate: levelUpdateMutate } = useLevelUpdateMutation(
    unionId,
    unionMemberId,
  );

  const { getGroupCode } = useCommonCodes();

  const codeGroup = useMemo(() => getGroupCode('USER_LEVEL'), [getGroupCode]);

  const [levelCode, setLevelCode] = useState<string>('DELETE');
  const executiveCodes = LEVELGROUPS.EXECUTEIVE.map((item) => item.code);
  const ownerCodes = LEVELGROUPS.OWNER.map((item) => item.code);
  const subscriberCodes = LEVELGROUPS.SUBSRIBER.map((item) => item.code);
  const unapproverCodes = LEVELGROUPS.UNAPPROVER.map((item) => item.code);

  const handleMaksing = () => {
    setIsMasking(true);
    snackbar('회원 정보 보기가 완료되었습니다.', { color: 'success' });
    return;
  };

  const handleConfirm = (password: string) => {
    mutate(
      { password },
      {
        onSuccess: () => {
          setIsMasking(false);
          snackbar('인증되었습니다', { color: 'success' });
          return;
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
          return;
        },
      },
    );
  };

  const handleLevelUpdate = () => {
    if (!levelCode) return;

    setLevelCode(levelCode);
    levelUpdateMutate(
      { levelCode: levelCode },
      {
        onSuccess: () => {
          refetch();
          snackbar('가입자 등급이 변경되었습니다.', { color: 'success' });
        },
        onError: (error) => {
          setLevelCode(levelCode);
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  useEffect(() => {
    const currentValue = codeGroup?.items.find(
      (item) => item.code === data?.data?.level,
    );

    if (currentValue) {
      setLevelCode(currentValue.code);
    }
  }, [codeGroup?.items, codeGroup?.items.length, data?.data?.level]);

  if (isError) throw error;
  if (!isSuccess) return <></>;

  const validData = () => {
    const { unionRegister, member, joinedAt } = data?.data || {};
    const { name, birth, gender, phoneNo } = member || {};

    if (unionRegister) {
      const { localAddresses } = unionRegister;
      const {
        city,
        gu,
        dong,
        jibun,
        building,
        apartmentDong,
        apartmentFloor,
        apartmentHosu,
      } = localAddresses?.[0] || {};

      return {
        name,
        birth: birth || '',
        phoneNo: phoneNo,
        gender: gender === 'F' ? '여성' : gender === 'M' ? '남성' : '',
        localAddresses: [
          city,
          gu,
          dong,
          jibun,
          building,
          apartmentDong,
          apartmentFloor,
          apartmentHosu,
        ].join(' '),
        joinedAt,
      };
    }

    return {
      name,
      birth,
      phoneNo,
      gender: gender === 'F' ? '여성' : gender === 'M' ? '남성' : '',
      localAddresses: '',
      joinedAt,
    };
  };

  const { level, nickname } = data?.data || {};

  const { name, phoneNo, birth, gender, localAddresses, joinedAt } =
    validData();
  const descriptions = generateDescriptionsJSON(
    [
      '가입자 이름/닉네임',
      '가입자 등급',
      '연락처',
      '생년월일',
      '성별',
      '소재지',
      '조합 가입일',
    ],
    [
      `${name} / ${nickname}`,
      <Select
        multiple={false}
        key={level}
        value={levelCode}
        placeholder={levelCode}
        size="sm"
        disabled={memberSeq === data?.data?.member.memberSeq}
        onChange={(_, value) => value && setLevelCode(value)}
      >
        {codeGroup?.items.map((item) => {
          if (
            (level === 'OWNER' && !ownerCodes.includes(item.code)) ||
            (level === 'SUBSCRIBER' && !subscriberCodes.includes(item.code)) ||
            (level === 'UNAPPROVER' && !unapproverCodes.includes(item.code)) ||
            (level === 'EXECUTIVE' && !executiveCodes.includes(item.code))
          ) {
            return null;
          }

          return (
            <Option key={item.code} value={item.code}>
              {item.name}
            </Option>
          );
        })}
      </Select>,
      formatTelephone(phoneNo),
      formatBirth(birth),
      gender,
      localAddresses,
      formatDate(joinedAt),
    ],
  );

  return (
    <Stack gap={2}>
      {isMasking === true ? null : (
        <Stack
          sx={{
            position: 'absolute',
            top: '20vh',
            left: '55vw',
            transform: 'translate(-50%, -50%) rotate(-19.86deg)',
            fontSize: '3rem',
            opacity: 0.15,
          }}
        >
          {maskingText}
        </Stack>
      )}
      <Stack gap={1.75}>
        <Typography level="title-lg">가입자 정보</Typography>
        <DescriptionsRenderer columns={2} json={descriptions} />
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignSelf="end" gap={1}>
          <Button
            size="lg"
            component={Link}
            variant="outlined"
            color="neutral"
            href=".."
          >
            목록
          </Button>
        </Stack>
        <Stack direction="row" gap={1}>
          {isMasking === true ? (
            <Button
              size="lg"
              variant="outlined"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              가입자 정보 보기
            </Button>
          ) : (
            <Button
              size="lg"
              variant="outlined"
              color="primary"
              onClick={handleMaksing}
            >
              확인
            </Button>
          )}

          <Button
            size="lg"
            onClick={handleLevelUpdate}
            disabled={level === levelCode}
          >
            저장
          </Button>
        </Stack>
      </Stack>

      {showModal && (
        <PasswordConfirmModal
          open={showModal}
          onClose={setShowModal}
          onConfirm={handleConfirm}
        />
      )}
    </Stack>
  );
};

const memberDetailPage: CustomRouteObject = {
  path: ':unionMemberSeq',
  element: <MemberDetailPage />,
  handle: {
    getTitle: () => '가입자 상세 정보',
  },
};

export default memberDetailPage;

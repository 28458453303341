import { DaumPostcodeEmbed, DaumPostcodeEmbedProps } from 'react-daum-postcode';

import ModalDialog, {
  ModalDialogProps,
} from 'design-system/components/feedback/dialog/ModalDialog';
import Modal, { ModalProps } from 'design-system/components/feedback/Modal';

export interface KakaoPostcodeModalProps extends Omit<ModalProps, 'children'> {
  postcodeEmbedProps?: DaumPostcodeEmbedProps;
  dialogProps?: ModalDialogProps;
  width?: number;
  height?: number;
}

const KakaoPostcodeModal = ({
  postcodeEmbedProps,
  dialogProps,
  open,
  onClose,
  width = 500,
  height = 500,
  ...other
}: KakaoPostcodeModalProps) => {
  const id = open ? 'daum-postcode-popover' : undefined;

  const { sx, ...dialogPropsOther } = dialogProps || {};

  return (
    <Modal id={id} open={open} onClose={onClose} {...other}>
      <ModalDialog
        {...dialogPropsOther}
        sx={{ width, height, ...sx, padding: 0, overflow: 'hidden' }}
      >
        <DaumPostcodeEmbed
          style={{ width, height }}
          {...postcodeEmbedProps}
          onClose={(event) => onClose && onClose(event, 'escapeKeyDown')}
        />
      </ModalDialog>
    </Modal>
  );
};

export default KakaoPostcodeModal;

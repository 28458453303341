import JoyListSubheader from '@mui/joy/ListSubheader';
import type { ListSubheaderProps as JoyListSubheaderProps } from '@mui/joy/ListSubheader';
import { ForwardedRef, forwardRef } from 'react';

export type ListSubheaderProps = JoyListSubheaderProps;

const ListSubheader = (
  props: ListSubheaderProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyListSubheader {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, ListSubheaderProps>(ListSubheader);

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  // Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import ResultGrid, {
  DataGridValues,
} from 'components/pages/meet-management/history/detail/common/ResultGrid';
import {
  calculateTotalVoteCount,
  isChoiceVote,
} from 'components/pages/meet-management/history/detail/common/ResultGrid/utils';
import useFeedback from 'hooks/useFeedback';
import { OnInputChange } from 'lim/_fixtures/type';
import {
  MeetsAgendasQuery,
  MutateAgenda,
} from 'lim/generalMeetingHistoryDetail/apis';
import {
  MEETS_AGENDAS_TABLE_COLUMNS,
  MeetsAgendasTableColumnsProps,
} from 'lim/generalMeetingHistoryDetail/fixtures';

type FormDataProps = Omit<MutateAgenda, 'candidates'> & {
  candidates: Partial<MutateAgenda['candidates'][number]>[];
};

interface VoteResultEnterModalProps {
  type: 'onsiteResult' | 'writtenSubmissionResult';
  meetsAgendasQuery: MeetsAgendasQuery;
  voterCount: number;
  registeredCount: number;
  open: boolean;

  onClose: (open: false) => void;
  onSubmit: (dataGridValues: DataGridValues) => void;
}

const VoteResultEnterModal = ({
  type,
  meetsAgendasQuery,
  voterCount,
  registeredCount,
  open,

  onClose,
  onSubmit,
}: VoteResultEnterModalProps) => {
  const meetName = type === 'onsiteResult' ? '현장 투표' : '서면 제출';
  const { data } = meetsAgendasQuery;

  const { confirmDialog, alertDialog } = useFeedback();

  const [showDataGrid, setShowDataGrid] = useState(false);
  const [count, setCount] = useState(0);
  const [dataGridValues, setDataGridValues] = useState<DataGridValues>({});
  const [formData, setFormData] = useState<FormDataProps[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const rows: MeetsAgendasTableColumnsProps[] = useMemo(() => {
    return (
      data?.data.flatMap((voteResult) => {
        const { agendaSeq, order, name: agendaName, candidates } = voteResult;

        return candidates.map((candidate) => {
          const { no, name } = candidate;

          return {
            id: `${agendaSeq}-${no}`,
            order: `제 ${order}호`,
            agenda: agendaName,
            no,
            candidate: name,
            yesCount: 0,
            noCount: 0,
            abstentionCount: 0,
            totalCount: 0,
            isChoiceVote: isChoiceVote(voteResult.voteForm),
          };
        });
      }) || []
    );
  }, [data]);

  const validSelectVoteCount = (
    id: string | number,
    newValue: DataGridValues,
  ) => {
    const agendaSeq = id.toString().split('-')[0];
    const agenda = data?.data.find((d) => d.agendaSeq === Number(agendaSeq));
    if (agenda?.voteForm === 'AGAINST') return true;

    const selectCount = agenda?.selectCount || 1;

    const currentAgendaIds = Object.keys(newValue).filter(
      (key) => key.indexOf(`${agendaSeq}-`) === 0,
    );

    const totalCount = currentAgendaIds
      .map((id) => newValue[id].yesCount)
      .reduce((acc, cur) => (acc += cur));

    if (count * selectCount < totalCount) return false;
    return true;
  };

  const validDataGridValueCount = (
    id: string | number,
    newValue: DataGridValues,
  ): boolean => {
    const total = Object.values(newValue[id]).reduce((acc, cur) => {
      return (acc = Number(acc) + Number(cur));
    });

    if (Number(total) > count) {
      alertDialog(
        `투표 안건의 찬/선, 반대, 기/무의 입력값은 최종 ${meetName} 수보다 클 수 없습니다.`,
      );

      return false;
    }

    if (!validSelectVoteCount(id, newValue)) {
      alertDialog(
        `선택투표 안건별 후보 각각의 찬성/선택 값의 합은 최종 ${meetName} 수보다 클 수 없습니다.`,
      );

      return false;
    }

    return true;
  };

  const isFullField = (newValue: DataGridValues) => {
    const calculatedTotalVoteCount = calculateTotalVoteCount(count, data?.data);
    const totalValuesCount = Object.values(newValue).reduce(
      (acc, cur): number => {
        const valuesTotalCount = Object.values(cur).reduce(
          (childAcc, childCur) => {
            return (childAcc = Number(childAcc) + Number(childCur));
          },
        );
        return (acc = Number(acc) + Number(valuesTotalCount));
      },
      0,
    );

    if (totalValuesCount === calculatedTotalVoteCount) return true;

    return false;
  };

  const handleCount = (e: ChangeEvent<HTMLInputElement>) => {
    setCount(Number(e.target.value));
  };

  const handleDataGridValueChange: OnInputChange = (id, e) => {
    const { name, value } = e.target;

    const newValue = {
      ...dataGridValues,
      [id]: {
        ...dataGridValues[id],
        [name]: Number(value),
      },
    };

    if (!validDataGridValueCount(id, newValue)) return;

    setDataGridValues(newValue);

    if (isFullField(newValue)) {
      return setIsDisabled(false);
    }

    if (!isDisabled) {
      setIsDisabled(true);
    }
  };

  const handleAdjust = () => {
    if (registeredCount < count) {
      setShowDataGrid(false);
      return alertDialog(
        `최종 ${meetName} 수는 총회관리 시스템에 등록된 ${meetName} 수인 ${registeredCount} 보다 클수 없습니다.`,
      );
    }

    if (count === 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    setDataGridValues({});
    setShowDataGrid(true);

    let dataGridValues: DataGridValues = {};

    if (formData.length) {
      formData.forEach((agenda) => {
        agenda.candidates.forEach((candidate) => {
          dataGridValues = {
            ...dataGridValues,
            [`${agenda.agendaSeq}-${candidate.no}`]: {
              abstentionCount: 0,
              noCount: 0,
              yesCount: 0,
            },
          };
        });
      });

      setDataGridValues(dataGridValues);
    }
  };

  const handleOnSubmit = () => {
    onSubmit(dataGridValues);
  };

  const handleClose = () => {
    confirmDialog('개표 결과 입력을 취소하시겠습니까?', {
      message: '입력한 내용은 저장되지 않습니다.',
      onConfirm: () => {
        onClose(false);
      },
    });
  };

  useEffect(() => {
    // if (!formData.length) {
    const formData = data?.data.map((data) => {
      const { agendaSeq, order, candidates } = data;
      const formDataCandidates = candidates.map((candidate) => {
        const { no } = candidate;

        const defaultValue = {
          yesCount: 0,
          noCount: 0,
          abstentionCount: 0,
          totalCount: 0,
        };

        return {
          no,
          writtenSubmissionResult: defaultValue,
        };
      });

      return {
        agendaSeq,
        order,
        candidates: formDataCandidates,
      };
    });

    if (formData) setFormData(formData);
    setIsDisabled(true);
    setShowDataGrid(false);
    // }
  }, [data?.data, open]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog>
        <DialogTitle>{meetName} 집계 결과 입력</DialogTitle>
        <DialogContent>
          <Stack gap={3} width={1000}>
            <Stack>
              <ul>
                {meetName === '서면 제출' ? (
                  <li>
                    입력한 서면결의서 결과는 현장 투표 집계 결과와 합산되어 집계
                    결과를 확인할 수 있습니다.
                  </li>
                ) : (
                  <li>
                    입력한 현장 투표 집계 결과는 서면결의서 결과와 합산되어 집계
                    결과를 확인할 수 있습니다.
                  </li>
                )}

                <li>
                  찬성/선택, 반대, 기권/무효에 입력한 투표수의 합계가 자동으로
                  합산되어 표시됩니다.
                </li>
                <li> 입력하지 않은 값은 ‘0’으로 저장됩니다.</li>
              </ul>
            </Stack>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>총 선거인: {voterCount}</Typography>

              <Stack alignSelf="end" gap={1}>
                <Typography level="body-md">
                  총회관리 시스템에 등록된 {meetName} 수: {registeredCount}
                </Typography>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Typography>최종 {meetName} 수:</Typography>
                  <Input
                    // type="number"
                    validateOptions={{
                      regex: /^[0-9]*$/,
                    }}
                    name="count"
                    onChange={handleCount}
                  />
                  <Button onClick={handleAdjust}>적용</Button>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              {showDataGrid && (
                <ResultGrid
                  rows={rows}
                  defaultGroupingExpansionDepth={1}
                  columns={MEETS_AGENDAS_TABLE_COLUMNS(
                    count,
                    dataGridValues,
                    handleDataGridValueChange,
                  )}
                />
              )}
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isDisabled}
            fullWidth
            type="submit"
            onClick={handleOnSubmit}
          >
            다음
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="neutral"
            onClick={handleClose}
          >
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default VoteResultEnterModal;

import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import AddressModalTrigger, {
  AddressModalTriggerProps,
} from 'components/pages/posts/AddressModalTrigger';

const DEFAULT_FORM_VALUES = {
  name: '',
  phone: '',
  address: '',
  detailAddress: '',
  zipNo: '',
};

const schema = yup.object({
  name: yup.string().required('이름을 입력해 주세요'),
  phone: yup.string().required('연락처를 입력해 주세요'),
  address: yup.string().required('주소를 검색해 주세요'),
  detailAddress: yup.string().optional().default(''),
  zipNo: yup.string().required('우편번호를 검색해 주세요'),
});

export type PostReceiverAdditionValues = yup.InferType<typeof schema>;
export interface PostReceiverAdditionModalProps
  extends Omit<ModalProps, 'children' | 'onSubmit' | 'component'> {
  onSubmit?: (values: PostReceiverAdditionValues) => void;
  onClose: (value: boolean) => void;
}

const PostReceiverAdditionModal = (props: PostReceiverAdditionModalProps) => {
  const { onSubmit, onClose, ...rest } = props;

  const {
    control,
    handleSubmit: validateSubmit,
    setValue,
    formState: { errors },
  } = useForm<PostReceiverAdditionValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleChangeAddress: AddressModalTriggerProps['onComplete'] = (
    result,
  ) => {
    const { userSelectedType, roadAddress, jibunAddress, zonecode } = result;

    setValue('address', userSelectedType === 'R' ? roadAddress : jibunAddress);
    setValue('zipNo', zonecode);
  };

  const handleSubmit = (data: PostReceiverAdditionValues) => {
    onSubmit?.(data);
    onClose(false);
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') return;

    onClose(false);
  };

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog component="form" onSubmit={validateSubmit(handleSubmit)}>
          <DialogTitle>수신자 개별 등록</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack gap={2}>
              <Stack gap={1}>
                <Stack direction="row" alignItems="flex-end" gap={1}>
                  <Controller
                    control={control}
                    name="address"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        variant="soft"
                        label="수신자 주소"
                        placeholder="주소"
                        startDecorator={<SearchIcon />}
                        fullWidth
                        readOnly
                        error={fieldState.invalid}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="zipNo"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        variant="soft"
                        placeholder="우편번호"
                        sx={{ width: '120px' }}
                        readOnly
                        error={fieldState.invalid}
                      />
                    )}
                  />

                  <AddressModalTrigger onComplete={handleChangeAddress}>
                    <Button sx={{ width: '80px' }}>검색</Button>
                  </AddressModalTrigger>
                </Stack>

                <Controller
                  control={control}
                  name="detailAddress"
                  render={({ field }) => (
                    <FormControl error={!!errors.address || !!errors.zipNo}>
                      <Input {...field} placeholder="상세주소 입력" />
                      <FormHelperText>
                        {errors.address?.message ?? errors.zipNo?.message ?? ''}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Stack>

              <Stack direction="row" gap={1}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="수신자 명"
                      placeholder="수신자 명 입력"
                      fullWidth
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      validateOptions={{
                        maxLength: 10,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="phone"
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="수신자 연락처"
                      placeholder="숫자만 입력"
                      fullWidth
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      validateOptions={{ maxLength: 11, regex: /^[0-9/]*$/ }}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button type="submit">등록</Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default PostReceiverAdditionModal;

import {
  Box,
  Button,
  DatePicker,
  FormControl,
  FormLabel,
  Link,
  Stack,
  TextField,
  Textarea,
  Typography,
  Grid,
  FileList,
  Snackbar,
  SnackbarClose,
} from '@wooriga/design-system';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useArchiveDeleteMutation,
  useArchiveDetailQuery,
} from 'apis/archives/apis';
import CommonCode from 'components/CommonCode';
import useLayoutContext from 'hooks/useLayoutContext';
import { ArchiveUpdate } from 'kwon/archives/types';
import { CustomRouteObject } from 'types/route';

export interface Files {
  fileSeq: number;
  originName: string;
}

const ArchvicesDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { pageContext } = useLayoutContext();
  const memberUnionInfo = pageContext?.memberUnionInfo;

  const {
    data: archiveData,
    isError,
    error,
    isLoading,
  } = useArchiveDetailQuery({
    unionSeq: memberUnionInfo?.unionSeq || 0,
    archiveSeq: Number(id),
  });

  const [currentFileList, setCurrentFileList] = useState<Files[]>([]);
  const [toastInfo, setToastInfo] = useState({
    success: true,
    show: false,
    content: '',
  });

  const { mutate: deleteArchive } = useArchiveDeleteMutation();

  const handleClickDeleteArchive = () => {
    deleteArchive(
      {
        unionSeq: memberUnionInfo?.unionSeq || 0,
        archiveSeq: Number(id),
      },
      {
        onSuccess: () => {
          navigate('..');
        },
        onError: () => {
          setToastInfo({
            success: false,
            show: true,
            content: '등록 실패했습니다.',
          });
        },
      },
    );
  };

  const methods = useForm<ArchiveUpdate>({
    defaultValues: {
      title: '',
      content: '',
      category: '',
      isPublic: false,
      fileSeqs: [],
    },
  });

  useEffect(() => {
    if (archiveData?.data) {
      methods.reset({
        title: archiveData?.data?.title,
        content: archiveData?.data?.content,
        category: archiveData?.data?.category,
        isPublic: archiveData?.data?.isPublic,
        fileSeqs: archiveData?.data?.files,
      });
      setCurrentFileList(archiveData?.data?.files);
    }
  }, [archiveData, methods]);

  if (isError) throw error;

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <FormProvider {...methods}>
      <Stack gap={2} component="form">
        <Stack gap={1.75}>
          <Typography level="title-lg">자료실 상세</Typography>
          <Typography level="title-lg">자료 정보</Typography>

          <Stack gap={2}>
            <Grid container xs={12} gap={2}>
              <Grid xs={3}>
                <FormControl>
                  <FormLabel>구분</FormLabel>
                  <CommonCode
                    key={archiveData?.data?.category}
                    groupCode="ARCHIVE_CATEGORY"
                    code={String(archiveData?.data?.category)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={3}>
                <DatePicker
                  variant="outlined"
                  color="neutral"
                  size="md"
                  label="작성일"
                  readOnly
                  value={
                    archiveData?.data?.createdAt &&
                    new Date(archiveData?.data?.createdAt)
                  }
                />
              </Grid>
              <Grid xs={3}>
                <DatePicker
                  variant="outlined"
                  color="neutral"
                  label="수정일"
                  size="md"
                  readOnly
                  value={
                    archiveData?.data?.createdAt &&
                    new Date(archiveData?.data?.createdAt)
                  }
                />
              </Grid>
              <Grid xs={2}>
                <Box minWidth={200}>
                  <FormControl>
                    <FormLabel>공개여부</FormLabel>
                    <Typography>
                      {archiveData?.data?.isPublic ? '공개' : '비공개'}
                    </Typography>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Stack>
              <TextField
                label="제목"
                fullWidth
                placeholder="제목 입력"
                {...methods.register('title', {
                  required: true,
                })}
                readOnly
              />
            </Stack>
            <Stack>
              <Typography>내용</Typography>
              <Textarea
                placeholder="내용 입력"
                sx={{
                  height: '422px',
                }}
                {...methods.register('content', {
                  required: true,
                })}
                readOnly
              />
            </Stack>

            {archiveData?.data && archiveData?.data?.fileCount > 0 ? (
              <Stack>
                <Typography>
                  첨부파일 목록{' '}
                  <Typography color="primary">
                    {currentFileList?.length}
                  </Typography>
                </Typography>
                <Box>
                  <FileList<Files>
                    {...methods.register('fileSeqs')}
                    variant="outlined"
                    color="neutral"
                    value={currentFileList}
                    labelGetter={(value) => value.originName}
                    fullWidth
                    readOnly={true}
                    onChange={(file) => {
                      if (file) {
                        const seqs = file?.map((item) => item.fileSeq);
                        methods.setValue('fileSeqs', seqs);
                        setCurrentFileList(file);
                      }
                    }}
                  />
                </Box>
              </Stack>
            ) : null}
          </Stack>
          <Stack direction="row" alignSelf="end" gap={1}>
            <Button
              component={Link}
              variant="outlined"
              color="neutral"
              size="md"
              href=".."
            >
              목록
            </Button>
            <Button
              variant="outlined"
              color="danger"
              size="md"
              onClick={handleClickDeleteArchive}
            >
              삭제
            </Button>
            <Button
              size="md"
              color="primary"
              component={Link}
              href={`/unions/${
                memberUnionInfo?.unionSeq || 0
              }/union-management/archives/update/${id}`}
            >
              수정
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Snackbar
        color="danger"
        open={toastInfo.show}
        endDecorator={<SnackbarClose />}
        onClose={() =>
          setToastInfo({
            ...toastInfo,
            show: false,
          })
        }
      >
        {toastInfo.content}
      </Snackbar>
    </FormProvider>
  );
};

const archvicesDetailPage: CustomRouteObject = {
  path: 'detail/:id',
  element: <ArchvicesDetailPage />,
  handle: {
    getTitle: () => '자료실 상세',
  },
};
export default archvicesDetailPage;

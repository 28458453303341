import useControlled from '@mui/utils/useControlled';
import { Box, Panel, type PanelProps } from '@wooriga/design-system';
import { useCallback, useEffect } from 'react';

import useLayoutContext from 'hooks/useLayoutContext';

export interface SidePanelProps extends PanelProps {
  open?: boolean;
  width?: number;
}

const SidePanel = (props: SidePanelProps) => {
  const {
    width = 600,
    sx,
    children,
    open: openProp,
    defaultOpen,
    onClose,
  } = props;

  const { mountSidePanel, unmountSidePanel } = useLayoutContext();

  const [open, setOpen] = useControlled({
    controlled: openProp,
    default: defaultOpen,
    name: 'SidePanel',
    state: 'open',
  });

  const handleClose = useCallback(() => {
    setOpen(false);

    onClose?.();
  }, [onClose, setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    open ? handleOpen() : handleClose();
  }, [open]);

  useEffect(() => {
    mountSidePanel(
      <Box
        position="relative"
        width={open ? width : 0}
        sx={{ transitionProperty: 'width', transitionDuration: '0.5s' }}
      >
        <Panel
          open
          onClose={handleClose}
          variant="outlined"
          {...props}
          sx={{
            position: 'fixed',
            width: width,
            height: '100%',
            boxShadow: 'xl',
            transitionProperty: 'right',
            transitionDuration: '0.5s',
            right: open ? 0 : -width,
            ...sx,
          }}
        >
          {children}
        </Panel>
      </Box>,
    );
  }, [children, handleClose, mountSidePanel, open, props, sx, width]);

  useEffect(() => {
    return () => {
      unmountSidePanel();
    };
  }, []);

  return undefined;
};

export default SidePanel;

import { ViewIssuanceSettingCardProps } from 'components/pages/document-issuances/ViewIssuanceSettingCard';

export const DOCUMENT_ISSUANCE_TABS = [
  { label: '자료발급', path: '/union-management/document-issuances' },
  { label: '내역 관리', path: '/union-management/document-issuances/records' },
];

export const REGISTER_TYPES = [
  { code: 'TD', name: '토지' },
  { code: 'GD', name: '건물' },
  { code: 'JD', name: '집합' },
];

export const VIEW_ISSUANCE_SETTING_ITEMS: Omit<
  ViewIssuanceSettingCardProps<string | number>,
  'index'
>[] = [
  {
    name: 'REGISTER',
    label: '등기부등본',
    items: [
      {
        type: 'radio',
        name: 'dIssuanceType',
        label: '발급/열람',
        defaultValue: 'B',
        options: [
          {
            label: '발급',
            value: 'A',
          },
          {
            label: '열람',
            value: 'B',
          },
        ],
      },
      {
        type: 'radio',
        name: 'hasDmm',
        label: '매매목록',
        defaultValue: 'false',
        options: [
          {
            label: '포함',
            value: 'true',
          },
          {
            label: '미포함',
            value: 'false',
          },
        ],
      },
      {
        type: 'radio',
        name: 'hasDgj',
        label: '공공담보/전세목록',
        defaultValue: 'false',
        options: [
          {
            label: '포함',
            value: 'true',
          },
          {
            label: '미포함',
            value: 'false',
          },
        ],
      },
      {
        type: 'radio',
        name: 'hasDmalso',
        label: '말소사항',
        defaultValue: 'false',
        options: [
          {
            label: '포함',
            value: 'true',
          },
          {
            label: '미포함',
            value: 'false',
          },
        ],
      },
      {
        type: 'radio',
        name: 'dRegistrationState',
        label: '기록 상태',
        defaultValue: 'A',
        options: [
          {
            label: '현행',
            value: 'A',
          },
          {
            label: '폐쇄',
            value: 'B',
          },
          {
            label: '현행+폐쇄',
            value: 'C',
          },
        ],
      },
    ],
  },
  {
    name: 'GG',
    label: '건축물대장',
    items: [
      {
        type: 'radio',
        name: 'ggIssuanceType',
        label: '발급/열람',
        defaultValue: 'B',
        options: [
          {
            label: '발급',
            value: 'A',
          },
          {
            label: '열람',
            value: 'B',
          },
        ],
      },
      {
        type: 'radio',
        label: '일반/전유부',
        name: 'hasGgNormal',
        defaultValue: 'true',
        options: [
          {
            label: '포함',
            value: 'true',
          },
          {
            label: '미포함',
            value: 'false',
          },
        ],
      },
      {
        type: 'radio',
        label: '표제부',
        name: 'hasGgPyoje',
        defaultValue: 'false',
        options: [
          {
            label: '포함',
            value: 'true',
          },
          {
            label: '미포함',
            value: 'false',
          },
        ],
      },
      {
        type: 'radio',
        label: '총괄표제',
        name: 'hasGgTotalPyoje',
        defaultValue: 'false',
        options: [
          {
            label: '포함',
            value: 'true',
          },
          {
            label: '미포함',
            value: 'false',
          },
        ],
      },
    ],
  },
  {
    name: 'GP',
    label: '배치도',
    items: [
      {
        type: 'radio',
        name: 'gpIssuanceType',
        label: '발급/열람',
        defaultValue: 'A',
        options: [
          {
            label: '발급',
            value: 'A',
          },
        ],
      },
    ],
  },
  {
    name: 'TP',
    label: '토지(임야)대장',
    items: [
      {
        type: 'radio',
        name: 'ttIssuanceType',
        label: '발급/열람',
        defaultValue: 'B',
        options: [
          {
            label: '발급',
            value: 'A',
          },
          {
            label: '열람',
            value: 'B',
          },
        ],
      },
      {
        type: 'radio',
        name: 'hasTtHistory',
        label: '연혁인쇄',
        defaultValue: 'true',
        options: [
          {
            label: '유',
            value: 'true',
          },
          {
            label: '무',
            value: 'false',
          },
        ],
      },
    ],
  },
  {
    name: 'TT',
    label: '지적도/임야도',
    items: [
      {
        type: 'radio',
        name: 'tpIssuanceType',
        label: '발급/열람',
        defaultValue: 'A',
        options: [
          {
            label: '발급',
            value: 'A',
          },
        ],
      },
    ],
  },
  {
    name: 'TU',
    label: '토지이용계획',
    items: [
      {
        type: 'radio',
        name: 'tuIssuanceType',
        label: '발급/열람',
        defaultValue: 'B',
        options: [
          {
            label: '발급',
            value: 'A',
          },
          {
            label: '열람',
            value: 'B',
          },
        ],
      },
    ],
  },
  {
    name: 'TJ',
    label: '대지권등록부',
    items: [
      {
        type: 'radio',
        name: 'tjIssuanceType',
        label: '발급/열람',
        defaultValue: 'B',
        options: [
          {
            label: '발급',
            value: 'A',
          },
          {
            label: '열람',
            value: 'B',
          },
        ],
      },
    ],
  },
];

export const VIEW_ISSUANCE_SETTING_DEFAULT_VALUES =
  VIEW_ISSUANCE_SETTING_ITEMS.reduce((obj, { items }) => {
    const values = items.reduce(
      (valObj, { defaultValue, name }) => ({ ...valObj, [name]: defaultValue }),
      {},
    );

    return { ...obj, ...values };
  }, {});

import JoyDialogActions from '@mui/joy/DialogActions';
import type { DialogActionsProps as JoyDialogActionsProps } from '@mui/joy/DialogActions';
import { ForwardedRef, forwardRef } from 'react';

export type DialogActionsProps = JoyDialogActionsProps;

const DialogActions = (
  props: DialogActionsProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyDialogActions {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, DialogActionsProps>(DialogActions);

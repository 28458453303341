import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid-premium';
import { ForwardedRef, forwardRef } from 'react';

import JoyGridColumnMenuPinningItem from 'design-system/components/dataGrid/JoyGridColumnMenuPinningItem';

export interface JoyGridColumnMenuProps extends GridColumnMenuProps {}

const JoyGridColumnMenu = (
  props: JoyGridColumnMenuProps,
  ref: ForwardedRef<HTMLUListElement>,
) => {
  const {
    hideMenu,
    colDef,
    slots: slotsProp,
    slotProps: slotPropsProp,
    ...other
  } = props;

  const slots = {
    columnMenuPinningItem: JoyGridColumnMenuPinningItem,
    ...slotsProp,
  };
  const slotProps = { ...slotPropsProp };

  return (
    <GridColumnMenu
      hideMenu={hideMenu}
      colDef={colDef}
      ref={ref}
      slots={slots}
      slotProps={slotProps}
      {...other}
    />
  );
};

JoyGridColumnMenu.displayName = 'JoyGridColumnMenu';

export default forwardRef<HTMLUListElement, JoyGridColumnMenuProps>(
  JoyGridColumnMenu,
);

import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import { styled } from '@mui/joy';
import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { IconButton, IconButtonProps } from 'design-system/components/inputs';
import NavigationBarContext from 'design-system/components/navigation/NavigationBarContext';

export type NavigationBarToggleButtonSlot = 'root';

export const navigationBarToggleButtonClasses = {
  root: 'JoyNavigationBarToggleButton-root',
  hidden: 'JoyNavigationBarToggleButton-hidden',
  permanent: 'JoyNavigationBarToggleButton-permanent',
};

export interface NavigationBarToggleButtonProps extends IconButtonProps {}

export type NavigationBarToggleButtonOwnerState =
  NavigationBarToggleButtonProps;

interface NavigationBarToggleButtonComponent
  extends ForwardRefExoticComponent<NavigationBarToggleButtonProps> {
  propTypes?: never;
  muiName?: string;
}

const NavigationBarToggleButton = forwardRef(
  (
    props: NavigationBarToggleButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { onClick, ...other } = props;

    const navigationBarContext = useContext(NavigationBarContext);

    const handleClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        navigationBarContext?.toggle(event);

        if (onClick) {
          onClick(event);
        }
      },
      [navigationBarContext, onClick],
    );

    const className = useMemo(() => {
      const classNames: string[] = [navigationBarToggleButtonClasses.root];

      !navigationBarContext?.open &&
        classNames.push(navigationBarToggleButtonClasses.hidden);
      navigationBarContext?.permanent &&
        classNames.push(navigationBarToggleButtonClasses.permanent);

      return classNames.join(' ');
    }, [navigationBarContext]);

    return (
      <JoyNavigationBarToggleButtonRoot
        variant="outlined"
        size="sm"
        className={className}
        ref={ref}
        onClick={handleClick}
        {...other}
      >
        {navigationBarContext?.open ? (
          <KeyboardDoubleArrowLeftRoundedIcon />
        ) : (
          <KeyboardDoubleArrowRightRoundedIcon />
        )}
      </JoyNavigationBarToggleButtonRoot>
    );
  },
) as NavigationBarToggleButtonComponent;

const JoyNavigationBarToggleButtonRoot = styled(IconButton, {
  name: 'JoyNavigationBarToggleButton',
  slot: 'root',
})<NavigationBarToggleButtonProps>`
  position: absolute;
  top: 16px;
  right: -1px;
  background-color: ${({ theme }) => theme.palette.background.body};

  &:not(.${navigationBarToggleButtonClasses.hidden}) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.${navigationBarToggleButtonClasses.hidden} {
    right: -32px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

NavigationBarToggleButton.displayName = 'NavigationBarToggleButton';
NavigationBarToggleButton.muiName = 'NavigationBarToggleButton';

export default NavigationBarToggleButton;

import { GridColDef, Link } from '@wooriga/design-system';

import { PostRecipient } from 'apis/types/post';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

export interface PostDetailRecordColumnProps {
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}

export const POST_DETAIL_RECORD_COLUMNS = ({
  onClickUnionMember,
}: GridColumnsHandlers<PostDetailRecordColumnProps>) =>
  [
    // {
    //   field: 'no',
    //   headerName: 'No',
    //   valueGetter: (_, row, __, apiRef) =>
    //     apiRef.current.getAllRowIds().indexOf(row?.postRecipientSeq) + 1 || '',
    // },
    {
      field: 'name',
      headerName: '조합원 이름',
      renderCell: ({ value, row }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickUnionMember(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'agent',
      headerName: '대리인',
      type: 'boolean',
    },
    {
      field: 'address',
      headerName: '우편물 수령지',
      flex: 1,
      valueGetter: (value: PostRecipient['address']) =>
        [value?.address, value?.detailAddress].join(' '),
    },
    {
      field: 'zipNo',
      headerName: '우편번호',
      valueGetter: (_, row) => row?.address?.zipNo,
    },
    {
      field: 'registerNo',
      headerName: '등기번호',
      renderCell: ({ value }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${value}&displayHeader=Y`}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'deliveryStatus',
      headerName: '배달상태',
    },
  ] as GridColDef<PostRecipient>[];

import { useTheme } from '@mui/joy';
import {
  Card,
  Checkbox,
  CheckboxGroup,
  List,
  ListDivider,
  ListItem,
  Radio,
  RadioGroup,
  Sheet,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, Fragment } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { REGISTER_TYPES } from 'apis/document-issuance/constants';
import { ViewIssuanceSettingFormValues } from 'components/pages/document-issuances/ViewIssuanceSettingDrawer';

export type ViewIssuanceSettingItem<T> = {
  type: 'radio' | 'checkbox';
  label: string;
  name: string;
  options: {
    label: string;
    value: T;
  }[];
  defaultValue?: T;
};

export interface ViewIssuanceSettingCardProps<T> {
  name: string;
  label: string;
  items: ViewIssuanceSettingItem<T>[];
}

const ViewIssuanceSettingCard = <T extends string | number>(
  props: ViewIssuanceSettingCardProps<T>,
) => {
  const { name, label, items } = props;

  const theme = useTheme();
  const { control } = useFormContext<ViewIssuanceSettingFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requestDocumentCodes',
  });

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.currentTarget;
    const index = fields.findIndex(
      ({ value: targetValue }) => targetValue === value,
    );

    if (!checked) {
      remove(index);
      return;
    }

    append({
      ...fields,
      value,
    });
  };

  return (
    <Card>
      <Sheet
        variant="soft"
        color="primary"
        sx={{
          padding: '10px 16px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: theme.radius.md,
        }}
      >
        {
          // 등기부등본 파트의 특수 패턴으로 별도의 분기 처리, 추후 논의 필요
          name === 'REGISTER' ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Typography>등기부등본</Typography>

              <Stack direction="row" gap={2}>
                {REGISTER_TYPES.map(({ code, name }) => (
                  <Checkbox
                    key={`register_checkbox_${code}`}
                    variant={
                      fields.some(
                        ({ value: currentValue }) => currentValue === code,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    color="primary"
                    label={name}
                    value={code}
                    onChange={handleCheckbox}
                  />
                ))}
              </Stack>
            </Stack>
          ) : (
            <Checkbox
              variant={
                fields.some(({ value: currentValue }) => currentValue === name)
                  ? 'solid'
                  : 'outlined'
              }
              color="primary"
              label={label}
              value={name}
              onChange={handleCheckbox}
            />
          )
        }
      </Sheet>

      <List>
        {items.map(({ type, label: title, options, name }, index) => (
          <Fragment key={`item_${name}_${title}`}>
            <ListItem>
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>{title}</Typography>

                {type === 'radio' && (
                  <Controller
                    control={control}
                    name={name as keyof ViewIssuanceSettingFormValues}
                    render={({ field }) => (
                      <RadioGroup size="md" orientation="horizontal" {...field}>
                        {options.map(({ label, value }) => (
                          <Radio
                            key={`${title}_radio_${value}`}
                            label={label}
                            value={value}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                )}

                {type === 'checkbox' && (
                  <Stack direction="row" gap={1.25}>
                    <Controller
                      control={control}
                      name={name as keyof ViewIssuanceSettingFormValues}
                      render={({ field }) => (
                        <CheckboxGroup
                          orientation="horizontal"
                          value={field.value as unknown as string[]}
                          onChange={(value) => field.onChange(value)}
                        >
                          {options.map(({ label, value }) => (
                            <Checkbox
                              key={`${title}_checkbox_${value}`}
                              value={value}
                              label={label}
                            />
                          ))}
                        </CheckboxGroup>
                      )}
                    />
                  </Stack>
                )}
              </Stack>
            </ListItem>

            {index !== items.length - 1 && <ListDivider inset="gutter" />}
          </Fragment>
        ))}
      </List>
    </Card>
  );
};

export default ViewIssuanceSettingCard;

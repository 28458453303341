import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import { ColorPaletteProp, styled, useTheme, VariantProp } from '@mui/joy';
import useControlled from '@mui/utils/useControlled';
import * as _ from 'lodash-es';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import AspectRatio from 'design-system/components/dataDisplay/AspectRatio';
import IconButton from 'design-system/components/inputs/IconButton';
import Box from 'design-system/components/layout/Box';
import Sheet, { SheetProps } from 'design-system/components/surfaces/Sheet';

export type FileThumbnailListSlot = 'root' | 'label';

export interface FileThumbnailListProps<T extends object = object>
  extends Omit<SheetProps, 'defaultValue' | 'value' | 'onChange' | 'onBlur'> {
  variant?: VariantProp;
  color?: ColorPaletteProp;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  fullWidth?: boolean;

  defaultValue?: T[];
  value?: T[];
  keyGetter?: (value: T) => string;
  labelGetter?: (value: T) => string;
  thumnailGetter?: (value: T) => string | undefined;
  onChange?: (value: T[]) => void;
  onDelete?: (value: T) => void;
}

export type FileThumbnailListOwnerState = FileThumbnailListProps;

const FileThumbnailListInner = <T extends object = object>(
  props: FileThumbnailListProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    variant,
    color,
    size,
    disabled,
    fullWidth,

    defaultValue,
    value: valueProp,
    onChange,
    onDelete,
    keyGetter,
    labelGetter,
    thumnailGetter,

    ...other
  } = props;

  const theme = useTheme();

  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: 'FileThumbnailList',
    state: 'value',
  });

  const ownerState = useMemo(
    () => ({
      disabled,
      variant,
      color,
      size,
      fullWidth,
    }),
    [color, disabled, fullWidth, size, variant],
  );

  const handleDelete = useCallback(
    (deleteValue: T) => {
      const newValue = _.without(value, deleteValue);
      setValue(newValue);
      onDelete?.(deleteValue);
      onChange?.(newValue);
    },
    [onChange, onDelete, setValue, value],
  );

  const items = useMemo(() => {
    return value.map((item, index) => {
      const key = keyGetter ? keyGetter(item) : index;
      const label = labelGetter && labelGetter(item);
      const img = thumnailGetter && thumnailGetter(item);

      return (
        <FileThumbnailListItem key={key} ownerState={ownerState}>
          <AspectRatio variant="plain">
            {img ? (
              <img src={img} alt={label} />
            ) : (
              <div>
                <ImageIcon
                  sx={{ fontSize: '2rem', color: theme.palette.neutral[500] }}
                />
              </div>
            )}
          </AspectRatio>

          <FileThumbnailListItemHover ownerState={ownerState}>
            <IconButton onClick={() => handleDelete(item)}>
              <CloseIcon />
            </IconButton>
          </FileThumbnailListItemHover>
        </FileThumbnailListItem>
      );
    });
  }, [
    handleDelete,
    keyGetter,
    labelGetter,
    ownerState,
    theme.palette.neutral,
    thumnailGetter,
    value,
  ]);

  return (
    value.length > 0 && (
      <FileThumbnailListRoot ref={ref} ownerState={ownerState} {...other}>
        {items}
      </FileThumbnailListRoot>
    )
  );
};

const FileThumbnailList = forwardRef(FileThumbnailListInner) as <
  T extends object = object,
>(
  props: FileThumbnailListProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof FileThumbnailListInner>;

const FileThumbnailListRoot = styled(Sheet, {
  name: 'JoyFileThumbnailList',
  slot: 'root',
})<{ ownerState: FileThumbnailListOwnerState }>(
  ({
    theme,
    ownerState: {
      fullWidth,
      variant = 'outlined',
      size = 'sm',
      color = 'neutral',
      disabled,
    },
  }) => ({
    display: 'flex',
    width: 348,
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    gap: 16,

    borderRadius: theme.radius[size],
    ...theme.variants[variant][color],

    ...(fullWidth && { width: '100%' }),

    ...(disabled && {
      color: theme.palette.neutral[400],
    }),
  }),
);

const FileThumbnailListItem = styled(Box, {
  name: 'JoyFileThumbnailList',
  slot: 'item',
})<{ ownerState: FileThumbnailListOwnerState }>(
  ({
    theme,
    ownerState: {
      fullWidth,
      variant = 'outlined',
      size = 'sm',
      color = 'neutral',
      disabled,
    },
  }) => ({
    width: 94,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius[size],
    ...theme.variants[variant][color],
    ...(fullWidth && { width: '100%' }),
    ...(disabled && {
      color: theme.palette.neutral[400],
    }),
    '&:hover': {
      '& .MuiBox-root': {
        display: 'block',
      },
    },
  }),
);

const FileThumbnailListItemHover = styled(Box, {
  name: 'JoyFileThumbnailListItemHover',
  slot: 'root',
})<{ ownerState: FileThumbnailListOwnerState }>(() => ({
  position: 'absolute',
  display: 'none',
  top: 'calc(50% - 18px)',
  left: 'calc(50% - 18px)',
}));

export default FileThumbnailList;

import { Button, GridColDef, Link, Stack } from '@wooriga/design-system';

import { UnionMemberInfo } from 'apis/types/union';
import { formatBirth, formatTelephone } from 'utils/format';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

// export interface MemberSearchParams {
//   sendNumber: string;
//   nickname: string;
//   phone: string;
//   rank: string;
//   unionNumber: string;
//   pages?: number;
//   pageSize?: number;
// }

// export interface Table {
//   id: number;
//   managementNo: string;
//   unionRegisterNo: string;
//   name: string;
//   level: string;
//   nickname: string;
//   birth: string;
//   phoneNo: string;
//   localAddresses: string;
//   isApproved: string;
// }

export const MEMBER_TABLE_COLUMNS = ({
  getCommonCode,
  onApprove,
}: GridColumnsHandlers<{
  onApprove: (id: number, isApproval: boolean) => void;
}>): GridColDef<UnionMemberInfo>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    width: 78,
    minWidth: 78,
    hideable: false,
    valueGetter: (_, row) => row?.unionRegister?.unionRegisterNo || '',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'name',
    headerName: '가입자 이름',
    width: 90,
    minWidth: 90,
    valueGetter: (_, row) => row?.member?.name || '',
  },
  {
    field: 'level',
    headerName: '가입자 등급',
    width: 78,
    minWidth: 78,
    valueGetter: (params) => getCommonCode('USER_LEVEL', params)?.name || '',
  },
  {
    field: 'nickname',
    headerName: '닉네임',
    width: 130,
    minWidth: 130,
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          component={Link}
          underline="always"
          href={`${params.id}`}
        >
          {params.value}
        </Link>
      );
    },
  },
  {
    field: 'birth',
    headerName: '생년월일',
    width: 130,
    minWidth: 130,
    valueGetter: (_, row) =>
      row?.member?.birth ? formatBirth(row.member.birth) : '',
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 130,
    minWidth: 130,
    valueGetter: (_, row) =>
      row?.member?.phoneNo ? formatTelephone(row.member.phoneNo) : '',
  },
  {
    field: 'localAddresses',
    headerName: '소재지',
    width: 230,
    valueGetter: (_, row) => {
      const { dong, jibun } = row?.unionRegister?.localAddresses?.[0] || {};
      return [dong, jibun].join(' ') || '';
    },
  },
  {
    field: 'isApproved',
    headerName: '승인여부',
    width: 150,
    minWidth: 150,
    renderCell: (params) =>
      params.value === true ? (
        '승인완료'
      ) : (
        <Stack flexDirection="row" gap={1}>
          <Button
            color="neutral"
            variant="outlined"
            onClick={() => onApprove(Number(params.id), true)}
          >
            승인
          </Button>
          <Button
            color="neutral"
            variant="outlined"
            onClick={() => onApprove(Number(params.id), false)}
          >
            거부
          </Button>
        </Stack>
      ),
  },
];

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import useFeedback from 'hooks/useFeedback';
import { ParticipantDetailDeleteMutationBody } from 'lim/generalMeetingHistoryDetail/voterEdit/apis';

interface FormData {
  meetPassword: string;
  deleteReason: string;
}

interface VoterDeleteModalProps {
  open: boolean;
  onClose: (open: false) => void;
  onSubmit: (formData: ParticipantDetailDeleteMutationBody) => void;
}

const VoterDeleteModal = ({
  open,
  onClose,
  onSubmit,
}: VoterDeleteModalProps) => {
  const { snackbar } = useFeedback();

  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    meetPassword: '',
    deleteReason: '',
  });
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnSubmit = () => {
    if (!isChecked) {
      snackbar('주의사항을 확인하시고 내용에 동의해 주세요.', {
        color: 'danger',
      });
      return;
    }

    if (!formData.meetPassword || formData.meetPassword.length < 4) {
      snackbar('비밀번호는 4자이상 입력하세요.', {
        color: 'danger',
      });
      return;
    }

    if (!formData.deleteReason) {
      snackbar('삭제 사유를 입력해 주세요.', {
        color: 'danger',
      });
      return;
    }

    onSubmit(formData);
    onClose(false);
  };

  useEffect(() => {
    setIsChecked(false);
    setFormData({
      meetPassword: '',
      deleteReason: '',
    });
  }, [open]);

  const handleClose = () => {
    onClose(false);
    setIsChecked(false);
    setFormData({
      meetPassword: '',
      deleteReason: '',
    });
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog>
          <DialogTitle>해당 선거인을 삭제하시겠습니까?</DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <Stack>
                <Typography level="body-md">
                  해당 선거인의 정보를 삭제하면 복구할 수 없습니다.
                </Typography>
                <Typography level="body-md">
                  이 정보를 삭제하려면 아래에 비밀번호와 삭제 사유를 입력해
                  주세요.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <TextField
                  label="총회 비밀번호"
                  type="password"
                  name="meetPassword"
                  placeholder="총회 비밀번호 입력"
                  onChange={handleOnChange}
                />
                <TextField
                  label="삭제 사유"
                  name="deleteReason"
                  placeholder="삭제 사유 입력"
                  onChange={handleOnChange}
                />
                <Stack alignItems="center">
                  <Checkbox
                    variant="outlined"
                    color="primary"
                    label="위 사항을 모두 확인하였습니다."
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth onClick={handleOnSubmit}>
              확인
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={handleClose}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default VoterDeleteModal;

import {
  Button,
  DialogActions,
  DialogContent,
  Modal,
  ModalDialog,
  Typography,
} from '@wooriga/design-system';

interface GroupMoveModalProps {
  open: boolean;
  onClose: (open: false) => void;
  onConfirm: () => void;
}

const ConfirmModal = ({ open, onClose, onConfirm }: GroupMoveModalProps) => {
  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog>
        <DialogContent>
          <Typography level="body-md">
            개표 결과 입력을 완료하시겠습니까?
          </Typography>
          <Typography level="body-md">
            입력한 내용이 집계되어 표시됩니다.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={onConfirm}>확인</Button>
          <Button variant="outlined" onClick={() => onClose(false)}>
            닫기
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmModal;

import JoyListItemContent from '@mui/joy/ListItemContent';
import type { ListItemContentProps as JoyListItemContentProps } from '@mui/joy/ListItemContent';
import { ForwardedRef, forwardRef } from 'react';

export type ListItemContentProps = JoyListItemContentProps;

const ListItemContent = (
  props: ListItemContentProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyListItemContent {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, ListItemContentProps>(
  ListItemContent,
);

import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Chip,
  PanelContent,
  Stack,
  Typography,
} from '@wooriga/design-system';

import { useCounselsQuery } from 'apis/counsel/apis';
import CommonCode from 'components/CommonCode';
import { formatDateTime } from 'utils/format';

interface BeforeCounselPannelProps {
  unionSeq: number;
  unionRegisterNo: number;
}
const BeforeCounselPannel = ({
  unionSeq,
  unionRegisterNo,
}: BeforeCounselPannelProps) => {
  const {
    data: beforeCounselListData,
    error,
    isError,
  } = useCounselsQuery({
    unionSeq,
    unionRegisterSeq: unionRegisterNo.toString(),
  });
  if (isError) throw error;
  return (
    <PanelContent overflow="scroll">
      <Typography fontSize="md" fontWeight="lg" lineHeight="md" mb={1}>
        전체
        <Typography
          color="primary"
          fontSize="md"
          fontWeight="lg"
          lineHeight="md"
          ml={0.5}
        >
          {beforeCounselListData?.data?.length}
        </Typography>
      </Typography>

      <Stack gap={2}>
        <AccordionGroup variant="outlined" color="neutral">
          {beforeCounselListData?.data?.map((counsel, index: number) => (
            <Accordion key={index}>
              <AccordionSummary>
                <Stack flexDirection="row" gap={1}>
                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    상담사
                  </Typography>
                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    {counsel?.counselor?.name}
                  </Typography>
                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    <CommonCode
                      groupCode="COUNSEL_METHOD_TYPE"
                      code={counsel.method}
                    />
                  </Typography>
                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    {formatDateTime(counsel.counselAt, 'yyyy-MM-dd HH:mm')}
                  </Typography>
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Stack gap={2}>
                  <Stack>
                    <Stack flexDirection="row" gap={1}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        상담 주제
                      </Typography>
                      <Typography>
                        {counsel.topics.map((item, index) => {
                          const isLastItem =
                            index === counsel.topics.length - 1;

                          return (
                            <>
                              <CommonCode
                                groupCode="COUNSEL_TOPIC_TYPE"
                                code={item}
                                key={item}
                              />
                              {!isLastItem && ', '}
                            </>
                          );
                        })}
                      </Typography>
                    </Stack>

                    <Box>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        상담 내용
                      </Typography>
                      <Typography>{counsel.memo}</Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row" marginRight="auto" gap={3}>
                    <Stack direction="row" gap={1}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        시스템
                      </Typography>
                      <Chip variant="solid" color="primary" size="sm">
                        {counsel.serviceType}
                      </Chip>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        우호도
                      </Typography>
                      <Chip variant="outlined" color="warning" size="sm">
                        {/* {counsel.tendency} */}
                        <CommonCode
                          groupCode="TENDENCY_TYPE"
                          code={counsel.tendency}
                        />
                      </Chip>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        투표/서면
                      </Typography>
                      <Chip variant="soft" color="success" size="sm">
                        {counsel.votingWrittenType === 'COMPLETE'
                          ? '완료'
                          : '미완료'}
                      </Chip>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        총회
                      </Typography>
                      <Chip variant="outlined" color="primary" size="sm">
                        {counsel.meetAttendType === 'Agent' ? '대리인' : '본인'}
                      </Chip>
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionGroup>
      </Stack>
    </PanelContent>
  );
};

export default BeforeCounselPannel;

import JoyTab from '@mui/joy/Tab';
import type { TabTypeMap as JoyTabTypeMap } from '@mui/joy/Tab';
import { OverridableComponent, OverrideProps } from '@mui/types';
import {
  ElementType,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
} from 'react';

import useTabs from 'design-system/components/navigation/tabs/useTabs';

export type TabProps<
  D extends ElementType = JoyTabTypeMap['defaultComponent'],
  P = { component?: ElementType },
> = OverrideProps<JoyTabTypeMap<P, D>, D>;

interface TabComponent<
  D extends ElementType = JoyTabTypeMap['defaultComponent'],
> extends ForwardRefExoticComponent<TabProps<D>> {
  propTypes?: never;
}

const Tab = forwardRef(
  (
    { color, variant, value, ...rest }: TabProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const {
      value: tabValue,
      selectedTabColor,
      selectedTabVariant,
      tabIndicatorInset,
    } = useTabs() || {};

    return (
      <JoyTab
        color={tabValue === value ? selectedTabColor : color}
        variant={tabValue === value ? selectedTabVariant : variant}
        indicatorInset={tabIndicatorInset}
        {...rest}
        ref={ref}
      />
    );
  },
) as TabComponent;

Tab.displayName = 'Tab';

export default Tab as OverridableComponent<JoyTabTypeMap>;

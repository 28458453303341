import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

import mainRouter from 'routes/mainRouter';
import publicRouter from 'routes/publicRouter';

export const routes = (queryClient: QueryClient): RouteObject[] => [
  mainRouter(queryClient) as RouteObject,
  publicRouter(queryClient) as RouteObject,
];

const rootRouter = (queryClient: QueryClient) =>
  createBrowserRouter(routes(queryClient), { basename: '/' });

export default rootRouter;

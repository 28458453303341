import { CreateMeetBody } from 'lim/meetOpen/apis';

type OnAlert = (alertInfo: {
  show: boolean;
  message: string;
  detail: string;
}) => void;

export const validRequiredField = (
  formData: CreateMeetBody,
  onAlert: OnAlert,
) => {
  const {
    name,
    moverName,
    password,
    meetMethod,
    electronicVote,
    onsiteVote,
    agendas,
  } = formData;

  const hasElectronic = meetMethod.includes('ELECTRONIC');
  const hasOnsite = meetMethod.includes('ONSITE');

  // 총회 정보
  if (!name || !moverName || !password) {
    onAlert({
      show: true,
      message: '필수 값을 입력해주세요',
      detail: '총회 정보의 필수 값을 입력해주세요.',
    });
    return false;
  }

  // 전자 투표
  if (hasElectronic && electronicVote) {
    const hasEmptyField = Object.values(electronicVote).some((value) => !value);

    if (hasEmptyField) {
      onAlert({
        show: true,
        message: '필수 값을 입력해주세요',
        detail: '전자투표 정보의 필수 값을 입력해주세요.',
      });

      return false;
    }
  }

  // 현장 투표
  if (hasOnsite && onsiteVote) {
    const {
      meetAt,
      submissionStartAt,
      submissionEndAt,
      jibunAddress,
      roadAddress,
    } = onsiteVote;

    if (
      !meetAt ||
      !submissionEndAt ||
      !submissionStartAt ||
      !jibunAddress ||
      !roadAddress
    ) {
      onAlert({
        show: true,
        message: '필수 값을 입력해주세요',
        detail: '현장 투표 정보의 필수 값을 입력해주세요.',
      });
      return false;
    }
  }

  const hasEmptyField = agendas.some((agenda) => !agenda.name);
  if (hasEmptyField) {
    onAlert({
      show: true,
      message: '필수 값을 입력해주세요',
      detail: '안건의 필수 값을 입력해주세요.',
    });
    return false;
  }

  // 후보
  return agendas.some((agenda) => {
    const hasEmptyField = agenda.candidates.some(
      (candidate) => !candidate.name,
    );

    if (hasEmptyField) {
      onAlert({
        show: true,
        message: '필수 값을 입력해주세요',
        detail: '후보의 필수 값을 입력해주세요.',
      });
    }
    return !hasEmptyField;
  });
};

import {
  AlertDailogResultType,
  AlertDailogType,
  AlertDialog,
  Button,
  Chip,
  Descriptions,
  DescriptionsItem,
  Link,
  Modal,
  Stack,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { ReactNode, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCounselDeleteMutation, useCounselQuery } from 'apis/counsel/apis';
import CommonCode from 'components/CommonCode';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterAgentDescriptions from 'components/pages/common/UnionRegisterAgentDescriptions';
import UnionRegisterDescriptions from 'components/pages/common/UnionRegisterDescriptions';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { CustomRouteObject } from 'types/route';
import { formatDateTime } from 'utils/format';

const CounselDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { pageContext } = useLayoutContext();

  const { memberInfo } = pageContext || {};
  const unionSeq = Number(params.unionSeq);
  const counselSeq = Number(params.counselSeq);

  const { getCode } = useCommonCodes();
  const { snackbar } = useFeedback();
  const [alertInfo, setAlertInfo] = useState<
    | {
        message: ReactNode;
        detail: ReactNode;
        type: AlertDailogType;
        onSubmit: (result: AlertDailogResultType) => void;
      }
    | undefined
  >(undefined);

  const {
    data: counselReturnData,
    isError,
    error,
  } = useCounselQuery({
    unionSeq,
    counselSeq,
  });
  const { mutate: deleteCounsel } = useCounselDeleteMutation();

  const unionCounselorInfo = counselReturnData?.data?.counselor;

  const unionRegisterInfo = counselReturnData?.data?.unionRegister;
  const unionRegisterAgentInfo = unionRegisterInfo?.agent;

  const handleDeleteCounsel = () => {
    setAlertInfo({
      onSubmit: (result) => {
        if (result === 'OK') {
          deleteCounsel(
            { unionSeq, counselSeq },
            {
              onSuccess: () => {
                navigate('..', { relative: 'path' });
              },
              onError: (error) => {
                snackbar(error.response?.data.message ?? error.message, {
                  color: 'danger',
                });
              },
            },
          );
        }

        setAlertInfo(undefined);
      },
      type: 'OKCancel',
      message: '',
      detail: '해당 상담 내역을 삭제하시겠습니까?',
    });
  };

  if (isError) throw error;

  const counselInfoDescriptions = useMemo(() => {
    const counselInfo = counselReturnData?.data;

    if (!counselInfo) return <></>;

    return (
      <Stack gap={1}>
        <Typography fontSize="lg" fontWeight="xl">
          상담 내용
        </Typography>

        <Stack gap={3.5}>
          <Descriptions
            variant="outlined"
            color="neutral"
            size="lg"
            columns={3}
          >
            <DescriptionsItem label="상담 방식">
              <CommonCode
                groupCode="COUNSEL_METHOD_TYPE"
                code={counselInfo.method}
              />
            </DescriptionsItem>
            <DescriptionsItem label="상담 일시">
              {formatDateTime(counselInfo.counselAt, 'yyyy-MM-dd HH:mm')}
            </DescriptionsItem>
            <DescriptionsItem label="상담사">
              {counselInfo.counselor?.name}
            </DescriptionsItem>
            <DescriptionsItem label="상담 주제">
              {counselInfo.topics
                ?.map((topic) => getCode('COUNSEL_TOPIC_TYPE', topic)?.name)
                .join(', ')}
            </DescriptionsItem>
            <DescriptionsItem label="상담 리뷰">
              <Stack flexDirection="row" gap={1}>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  우호도{' '}
                  <Chip color="primary" variant="outlined">
                    <CommonCode
                      groupCode="TENDENCY_TYPE"
                      code={counselInfo?.tendency}
                    />
                  </Chip>
                </Typography>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  투표/서면{' '}
                  <Chip color="primary" variant="outlined">
                    <CommonCode
                      groupCode="VOTING_WRITTEN_TYPE"
                      code={counselInfo?.votingWrittenType}
                    />
                  </Chip>
                </Typography>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  총회{' '}
                  <Chip color="primary" variant="outlined">
                    <CommonCode
                      groupCode="ATTEND_TYPE"
                      code={counselInfo?.meetAttendType}
                    />
                  </Chip>
                </Typography>
              </Stack>
            </DescriptionsItem>
            <DescriptionsItem label="등록 시스템">
              <CommonCode
                groupCode="COUNSEL_SERVICE_TYPE"
                code={counselInfo?.serviceType}
              />
            </DescriptionsItem>
          </Descriptions>

          <Textarea
            variant="outlined"
            color="neutral"
            size="md"
            minRows={20}
            readOnly
            value={counselReturnData?.data?.memo || ''}
          />
        </Stack>
      </Stack>
    );
  }, [counselReturnData?.data]);

  return (
    <>
      <Stack gap={2}>
        <Typography fontSize="lg" fontWeight="xl">
          상담내역 상세
        </Typography>

        <UnionRegisterDescriptions data={unionRegisterInfo} />
        <UnionRegisterAgentDescriptions data={unionRegisterAgentInfo} />
        {counselInfoDescriptions}

        <Stack flexDirection="row" justifyContent="space-between">
          <Button
            size="lg"
            variant="outlined"
            color="neutral"
            component={Link}
            href=".."
          >
            목록
          </Button>

          <Stack flexDirection="row" gap={1}>
            <Button
              size="lg"
              variant="outlined"
              color="neutral"
              component={Link}
              href="modify"
              disabled={memberInfo?.memberSeq !== unionCounselorInfo?.memberSeq}
            >
              수정
            </Button>
            <Button
              size="lg"
              variant="outlined"
              color="danger"
              disabled={memberInfo?.memberSeq !== unionCounselorInfo?.memberSeq}
              onClick={handleDeleteCounsel}
            >
              삭제
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Modal open={!!alertInfo}>
        <AlertDialog
          onSubmit={alertInfo?.onSubmit}
          type={alertInfo?.type}
          message={alertInfo?.message}
          detail={alertInfo?.detail}
        />
      </Modal>
    </>
  );
};

const counselDetailPage: CustomRouteObject = {
  path: ':counselSeq',
  element: <CounselDetailPage />,
  handle: {
    getTitle: () => '상담 상세',
  },
};

export default counselDetailPage;

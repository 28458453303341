import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import authPage from 'pages/main/setting/auth';
import chargesPage from 'pages/main/setting/charges';
import unionInfoPage from 'pages/main/setting/union-infos';
import { CustomRouteObject } from 'types/route';

const settingPage: CustomRouteObject = {
  handle: {
    getTitle: () => '설정',
    getIcon: () => <SettingsOutlinedIcon />,
    getMenuCode: () => 'M11',
  },
  children: [authPage, unionInfoPage, chargesPage],
};

export default settingPage;

import type { TabProps as JoyTabProps } from '@mui/joy/Tab';
import JoyTabs from '@mui/joy/Tabs';
import type { TabsProps as JoyTabsProps } from '@mui/joy/Tabs';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

import TabsContext from 'design-system/components/navigation/tabs/TabsContext';

export type TabsProps = JoyTabsProps & {
  selectedTabVariant: JoyTabsProps['variant'];
  selectedTabColor: JoyTabsProps['color'];
  tabIndicatorInset?: JoyTabProps['indicatorInset'];
};

const Tabs = (
  {
    selectedTabColor,
    selectedTabVariant,
    tabIndicatorInset,
    children,
    onChange,
    defaultValue,
    ...rest
  }: TabsProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [value, setValue] = useState<TabsProps['value']>(defaultValue);
  const contextValue = useMemo(
    () => ({
      selectedTabColor,
      selectedTabVariant,
      tabIndicatorInset,
      value,
      ...rest,
    }),
    [rest, selectedTabColor, selectedTabVariant, tabIndicatorInset, value],
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent | null, value: number | string | null) => {
      onChange && onChange(event, value);
      setValue(value);
    },
    [onChange],
  );

  return (
    <JoyTabs {...rest} value={value} onChange={handleChange} ref={ref}>
      <TabsContext.Provider value={contextValue}>
        {children}
      </TabsContext.Provider>
    </JoyTabs>
  );
};

export default forwardRef<HTMLDivElement, TabsProps>(Tabs);

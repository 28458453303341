import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Callout,
  Checkbox,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  CreateMessageBody,
  useCreateMessageBalanceMutation,
} from 'apis/message/apis';
import CommonCode from 'components/CommonCode';
import useFeedback from 'hooks/useFeedback';
import { commaizeNumber, formatDateTime, formatTelephone } from 'utils/format';

export interface MessageBalanceModalProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'onSubmit'> {
  unionSeq: number;
  data: CreateMessageBody;
  isMutationLoading?: boolean;
  onSubmit?: (data: CreateMessageBody) => void;
  onClose: (value: boolean) => void;
}

const MessageBalanceModal = (props: MessageBalanceModalProps) => {
  const {
    unionSeq,
    data,
    isMutationLoading = false,
    onSubmit,
    onClose,
    ...rest
  } = props;

  const [checked, setChecked] = useState<boolean>(false);

  const { snackbar } = useFeedback();
  const { data: balance, mutate: calculateMessageBalance } =
    useCreateMessageBalanceMutation();

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.currentTarget.checked);
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose(false);
  };

  const handleSubmit = () => {
    if (!data) {
      return;
    }

    onSubmit?.(data);
  };

  useEffect(() => {
    const request = { ...data, unionSeq };

    calculateMessageBalance(request, {
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unionSeq, snackbar, calculateMessageBalance]);

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog maxWidth="sm">
          <DialogTitle>문자발송</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack direction="column" gap={2.5}>
              <Callout
                variant="outlined"
                color="warning"
                startDecorator={<InfoIcon />}
              >
                조합 업무와 관련 없는 영리목적의 광고성 메세지를 보내지 않도록
                유의해 주세요. 문자 발송을 원하시면 “문자발송”을 선택해 주세요.
              </Callout>

              <Stack direction="column" alignItems="center" gap={2.5}>
                <Box width="100%">
                  <Descriptions columns={1}>
                    <DescriptionsItem label="발신번호">
                      {formatTelephone(data?.outgoingPhoneNo)}
                    </DescriptionsItem>
                    <DescriptionsItem label="문자유형">
                      <CommonCode
                        groupCode="SMS_SEND_TYPE"
                        code={data?.sendType ?? ''}
                      />
                    </DescriptionsItem>
                    {data?.sendType === 'SCHEDULE' && (
                      <DescriptionsItem label="발송일시">
                        {data?.scheduledAt &&
                          formatDateTime(
                            data?.scheduledAt,
                            'yyyy-MM-dd HH:mm:ss',
                          )}
                      </DescriptionsItem>
                    )}
                    <DescriptionsItem label="수신자 수">
                      {commaizeNumber((data?.recipients ?? []).length || 0)} 명
                    </DescriptionsItem>
                    <DescriptionsItem label="비용">
                      {commaizeNumber(balance?.data?.totalCost || 0)}원
                    </DescriptionsItem>
                  </Descriptions>
                </Box>

                <Typography level="body-md" color="neutral" textAlign="center">
                  문자 내용 및 수신자 정보에 오류가 없는지 다시 한번
                  확인해주세요.
                  <br />
                  문자상의 모든 내용은 작성자에게 책임이 있으며,
                  <br />
                  발송이 완료된 문자는 취소 및 환불이 불가능합니다.
                </Typography>

                <Checkbox
                  variant="outlined"
                  color="primary"
                  label="위 사항을 모두 확인하였습니다."
                  checked={checked}
                  onChange={handleChecked}
                  disabled={isMutationLoading}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!checked || isMutationLoading}
              onClick={handleSubmit}
              loading={isMutationLoading}
            >
              문자발송
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              disabled={isMutationLoading}
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default MessageBalanceModal;

import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

import membersPage from 'pages/main/community/members';
import { CustomRouteObject } from 'types/route';

const communityPage: CustomRouteObject = {
  handle: {
    getTitle: () => '커뮤니티',
    getIcon: () => <AssignmentIndOutlinedIcon />,
    getMenuCode: () => 'M01',
  },
  children: [membersPage],
};

export default communityPage;

import {
  DataGrid,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';
import { FormsParams, useFormsQuery } from 'apis/form/apis';
import { FORMS_COLUMNS } from 'apis/form/fixtures';
import { FileInfo } from 'apis/types/common';
import { Form } from 'apis/types/form';
import FormPreviewModal, {
  FormPreviewModalProps,
} from 'components/pages/forms/FormPreviewModal';
import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const DEFAULT_SEARCH_PARAMS = {
  keyword: '',
};

const FormsPage = () => {
  const [searchParams, setSearchParams] = useState<FormsParams>({
    ...DEFAULT_SEARCH_PARAMS,
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [previewData, setPreviewData] = useState<
    FormPreviewModalProps['data'] | undefined
  >(undefined);

  const {
    data: records,
    isPending,
    isError,
    error,
  } = useFormsQuery(searchParams);

  const { mutate: downloadFile, isPending: isPreviewPending } =
    useFileDownloadMutation();

  const { snackbar } = useFeedback();

  const handleSearchParams = (values: Partial<FormsParams>) => {
    if (!values) {
      return;
    }

    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...values }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPreviewData(undefined);
  };

  const handleClickDownload = useCallback(
    (_: Form, selectedFileInfo: FileInfo) => {
      const { fileSeq, originName } = selectedFileInfo;

      downloadFile(
        { fileSeq },
        {
          onSuccess: (data) => {
            const url = URL.createObjectURL(data);

            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = originName;

            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            URL.revokeObjectURL(url);
          },
          onError: (error) => {
            snackbar(error.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
    },
    [downloadFile, snackbar],
  );

  const handleClickPreview = useCallback(
    (formInfo: Form, selectedFileInfo: FileInfo) => {
      setIsModalOpen(true);

      const { fileSeq } = selectedFileInfo;

      downloadFile(
        { fileSeq },
        {
          onSuccess: (data) => {
            setPreviewData({ title: formInfo.title, blob: data });
          },
          onError: (error) => {
            snackbar(error.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
    },
    [downloadFile, snackbar],
  );

  const rows = useMemo(() => records?.data || [], [records]);
  const { columns } = useCreateGridColumns({
    columns: FORMS_COLUMNS,
    handlers: {
      onClickPreview: handleClickPreview,
      onClickDownload: handleClickDownload,
    },
  });

  if (isError) {
    throw error;
  }

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1.75}>
          <Search
            defaultValues={DEFAULT_SEARCH_PARAMS}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <TextField
                    label="키워드"
                    name="keyword"
                    placeholder="모두 입력"
                    fullWidth
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>

          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(records?.pagination.totalDataCount || 0)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(records?.pagination.totalElements || 0)}
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Stack height={442}>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={isPending}
              getRowId={(row) => row.formSeq}
            />
          </Stack>
        </Stack>
      </Stack>

      {isModalOpen && (
        <FormPreviewModal
          data={previewData}
          loading={isPreviewPending}
          open={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

const formsPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/forms',
  element: <FormsPage />,
  handle: {
    getTitle: () => '문서 서식',
    getMenuCode: () => 'M0409',
  },
};

export default formsPage;

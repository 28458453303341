import { createContext } from 'react';

import { TabsProps } from 'design-system/components/navigation/tabs/Tabs';

export type TabsContextValue = TabsProps;

/**
 * @ignore - internal component.
 */
const Context = createContext<TabsContextValue | undefined>(undefined);

export default Context;

import JoyDropdown from '@mui/joy/Dropdown';
import type { DropdownProps as JoyDropdownProps } from '@mui/joy/Dropdown';
import { ForwardedRef, forwardRef } from 'react';

export type DropdownProps = JoyDropdownProps;

const Dropdown = (props: DropdownProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyDropdown {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, DropdownProps>(Dropdown);

import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import { ApiError, ApiResponseData } from 'types/api';

export type AuthType =
  | 'SUPER_ADMIN'
  | 'UNION_MANAGER'
  | 'UNION_OPERATOR'
  | 'CUSTOM';

export interface AuthGroup {
  unionAuthGroupSeq: number;
  name: string;
  memo: string;
  memberCount: number;
  isAutoGenerated: boolean;
  type?: AuthType;
}

export type AuthGroupsQuery = UseQueryResult<
  ApiResponseData<AuthGroup[]>,
  AxiosError
>;

export const useAuthGroupsQuery = (unionSeq: number): AuthGroupsQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/auth-groups`],
    // initialData: generateInitRealData(authGroupsData),
  });

export type MemberAuthGroupsQuery = UseQueryResult<
  ApiResponseData<AuthGroup>,
  AxiosError
>;

export const useMemberAuthGroupQuery = (
  unionSeq: number,
  unionMemberSeq: number,
): MemberAuthGroupsQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/members/${unionMemberSeq}/auth-group`],
    queryFn: async ({ queryKey }) => {
      if (isNaN(unionMemberSeq)) {
        return {
          data: {
            undefined,
          },
        };
      }
      try {
        const url = `/${queryKey[0]}`.replace('//', '/');
        const { data } = await axiosInstance.get(url);
        return data;
      } catch (err) {
        if (err instanceof Error) {
          throw err;
        }
      }
    },
  });

export type UnassignedMembersQuery = UseQueryResult<
  ApiResponseData<UnionMemberResponse[]>,
  AxiosError
>;

interface UnassignedMembersParams {
  name: string;
  phoneNo: string;
}

export const useUnassignedMembersQuery = (
  unionSeq: number,
  params: UnassignedMembersParams,
): UnassignedMembersQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/auth-groups/unassigned-members`, params],
    // initialData: generateInitRealData(unassignedUnionMembersData),
  });

export type AssignedMembersQuery = UseQueryResult<
  ApiResponseData<UnionMemberResponse[]>,
  AxiosError
>;

export const useAssignedMembersQuery = (
  unionSeq: number,
  unionAuthGroupSeq: number,
): AssignedMembersQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/auth-groups/${unionAuthGroupSeq}/members`],
    // initialData: generateInitRealData(unionMembersData),
    enabled: !isNaN(unionSeq) && !isNaN(unionAuthGroupSeq),
  });

export type DeleteAuthGroupMutation = UseMutationResult<
  ApiResponseData,
  ApiError
>;

export const useDeleteAuthGroupMutation = (
  unionSeq: number,
  unionAuthGroupSeq: number,
): DeleteAuthGroupMutation =>
  useMutation({
    mutationFn: async () => {
      const { data } = await axiosInstance.delete<ApiResponseData>(
        `/unions/${unionSeq}/auth-groups/${unionAuthGroupSeq}`,
      );

      return data;
    },
  });

export interface UpdateAuthGroupBody {
  name: string;
  memo: string;
}

export type UpdateAuthGroupMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  UpdateAuthGroupBody
>;

export const useUpdateAuthGroupMutation = (
  unionSeq: number,
  unionAuthGroupSeq: number,
): UpdateAuthGroupMutation =>
  useMutation({
    mutationFn: async (formData: UpdateAuthGroupBody) => {
      const { data } = await axiosInstance.put<ApiResponseData>(
        `/unions/${unionSeq}/auth-groups/${unionAuthGroupSeq}`,
        formData,
      );

      return data;
    },
  });

export interface UpdateAuthGroupMembersBody {
  unionMemberSeqs: number[];
}

export type UpdateAuthGroupMembersMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  UpdateAuthGroupMembersBody
>;

export const useUpdateAuthGroupMembersMutation = (
  unionSeq: number,
  unionAuthGroupSeq: number,
): UpdateAuthGroupMembersMutation =>
  useMutation({
    mutationFn: async (formData: UpdateAuthGroupMembersBody) => {
      const { data } = await axiosInstance.put<ApiResponseData>(
        `/unions/${unionSeq}/auth-groups/${unionAuthGroupSeq}/members`,
        formData,
      );

      return data;
    },
  });

export interface CreateAuthGroupBody {
  name: string;
  memo: string;
}

export type CreateAuthGroupMutation = UseMutationResult<
  ApiResponseData<{ seq: number }>,
  ApiError,
  CreateAuthGroupBody
>;

export const useCreateAuthGroupMutation = (
  unionSeq: number,
): CreateAuthGroupMutation =>
  useMutation({
    mutationFn: async (formData: CreateAuthGroupBody) => {
      const { data } = await axiosInstance.post<
        ApiResponseData<{ seq: number }>
      >(`/unions/${unionSeq}/auth-groups`, formData);

      return data;
    },
  });

export interface SuperAdminDelegateBody {
  delegatorUnionMemberSeq: number;
  delegateeUnionMemberSeq: number;
}

export type SuperAdminDelegateMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  SuperAdminDelegateBody
>;

export const useSuperAdminDelegateMutation = (
  unionSeq: number,
): SuperAdminDelegateMutation =>
  useMutation({
    mutationFn: async (formData: SuperAdminDelegateBody) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/unions/${unionSeq}/super-admin/delegate`,
        formData,
      );

      return data;
    },
  });

export interface AuthGroupMenusResponse {
  menuSeqs: number[];
}

export type AuthGroupMenusQuery = UseQueryResult<
  ApiResponseData<AuthGroupMenusResponse>,
  AxiosError
>;

export const useAuthGroupMenusQuery = (
  unionSeq: number,
  unionAuthGroupSeq: number,
): AuthGroupMenusQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/auth-groups/${unionAuthGroupSeq}/menus`],
    // initialData: generateInitRealData(authGroupMenusData),
  });

export interface SubMenu {
  menuSeq: number;
  code: string;
  name: string;
  sortOrder: number;
}

export interface AvailableMenu {
  menuSeq: number;
  code: string;
  name: string;
  description: string;
  sortOrder: number;
  subMenus: SubMenu[];
}

export type AvailableMenusQuery = UseQueryResult<
  ApiResponseData<AvailableMenu[]>,
  AxiosError
>;

export const useAvailableMenusQuery = (unionSeq: number): AvailableMenusQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/auth-groups/available-menus`],
    // initialData: generateInitRealData(availableMenusData),
  });

export interface UpdateAuthGroupMenusBody {
  menuSeqs: number[];
}

export type UpdateAuthGroupMenusMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  UpdateAuthGroupMenusBody
>;

export const useUpdateAuthGroupMenusMutation = (
  unionSeq: number,
  unionAuthGroupSeq: number,
): UpdateAuthGroupMenusMutation =>
  useMutation({
    mutationFn: async (formData: UpdateAuthGroupMenusBody) => {
      const { data } = await axiosInstance.put<ApiResponseData>(
        `/unions/${unionSeq}/auth-groups/${unionAuthGroupSeq}/menus`,
        formData,
      );

      return data;
    },
  });

import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
  Typography,
  ModalProps,
  FormControl,
  FormLabel,
  Input,
  KakaoPostcodeModal,
} from '@wooriga/design-system';
import { ForwardedRef, forwardRef, useState } from 'react';
import { Address as KakaoAddress } from 'react-daum-postcode';
import { useForm } from 'react-hook-form';

import { CreateUnionInfo } from 'kwon/union-info/apis';

export interface AddUnionInfoModalProps {
  open: boolean;
  onClose: (value: boolean) => void;
  onSubmit: (data: CreateUnionInfo) => void;
  unionSeq: number;
}

const AddUnionInfoModal = (
  props: AddUnionInfoModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { open, onClose, unionSeq, onSubmit } = props;

  const [isOpenKakao, setIsOpenKakao] = useState(false);

  const {
    register,
    handleSubmit: validateSubmit,
    reset,
    setValue,
  } = useForm<CreateUnionInfo>();
  const handleComplete = (address: KakaoAddress) => {
    setValue('address', address.address || '');
    setValue('zipNo', address.zonecode || '');
  };
  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    reset();
    onClose(false);
  };
  const handleSubmit = (data: CreateUnionInfo) => {
    data.unionSeq = unionSeq;
    onSubmit?.(data);
    reset();
    onClose(false);
  };

  return (
    <Modal
      component="form"
      onSubmit={validateSubmit(handleSubmit)}
      onClose={handleClose}
      ref={ref}
      open={open}
    >
      <Box>
        <ModalDialog
          sx={{
            width: '400px',
          }}
        >
          <DialogTitle>조합 사무실 등록</DialogTitle>
          <Typography level="body-md">
            조합 사무실을 신규로 등록하시겠습니까?
          </Typography>
          <DialogContent>
            <Stack gap={2}>
              <TextField
                label="사무실 명"
                placeholder="사무실 명 입력"
                fullWidth
                {...register('name')}
              />
              <Stack>
                <FormControl>
                  <Stack
                    gap={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box width="80%">
                      <FormLabel>사무실 주소</FormLabel>
                      <Input
                        fullWidth
                        startDecorator={<SearchIcon />}
                        placeholder="사무실 주소 입력"
                        disabled
                        {...register('address')}
                      />
                    </Box>
                    <Box display="flex" alignItems="flex-end">
                      <Button
                        color="neutral"
                        size="md"
                        variant="outlined"
                        onClick={() => setIsOpenKakao(true)}
                      >
                        검색
                      </Button>
                    </Box>
                  </Stack>
                </FormControl>

                <KakaoPostcodeModal
                  open={isOpenKakao}
                  postcodeEmbedProps={{ onComplete: handleComplete }}
                  onClose={() => {
                    setIsOpenKakao(false);
                  }}
                />
                <TextField
                  placeholder="상세 주소 입력"
                  fullWidth
                  {...register('detailAddress')}
                />
              </Stack>
              <TextField
                label="사무실 연락처"
                placeholder="사무실 연락처 입력"
                fullWidth
                {...register('phoneNo')}
              />
              <TextField
                label="사무실 이메일"
                placeholder="사무실 이메일 입력"
                fullWidth
                {...register('email')}
              />
              <TextField
                label="사무실 팩스"
                placeholder="사무실 팩스 입력"
                fullWidth
                {...register('faxNo')}
              />
              <TextField
                label="담당자"
                {...register('managerName')}
                fullWidth
              />
              <TextField
                label="담당자 연락처"
                {...register('managerPhoneNo')}
                fullWidth
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button size="md" variant="solid" color="primary" type="submit">
              등록
            </Button>
            <Button
              size="md"
              variant="outlined"
              color="neutral"
              onClick={() => {
                reset();
                onClose(false);
              }}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Box>
    </Modal>
  );
};
export default forwardRef(AddUnionInfoModal);

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  IconButton,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@wooriga/design-system';
import { ReactNode, useState } from 'react';

export interface HelpTooltipProps
  extends Omit<TooltipProps, 'title' | 'content' | 'children'> {
  title?: ReactNode;
  contents?: ReactNode;
}

const HelpTooltip = (props: HelpTooltipProps) => {
  const { title, contents, ...other } = props;

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      variant="outlined"
      color="neutral"
      size="md"
      open={open}
      placement="right-end"
      disableFocusListener
      disableHoverListener
      arrow
      title={
        <Stack minWidth={100} maxWidth={480} gap={1} p={2}>
          <Stack flexDirection="row" alignItems="center" height={32}>
            <Typography
              textColor="neutral.700"
              fontSize="sm"
              fontWeight="lg"
              lineHeight="sm"
              flex={1}
            >
              {title}
            </Typography>

            <IconButton size="sm" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <Typography
            textColor="neutral.500"
            fontSize="sm"
            fontWeight="md"
            lineHeight="sm"
          >
            {contents}
          </Typography>
        </Stack>
      }
      {...other}
    >
      <IconButton color="neutral" size="sm" onClick={handleOpen}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default HelpTooltip;

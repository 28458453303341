import JoyTypography from '@mui/joy/Typography';
import type { TypographyProps as JoyTypographyProps } from '@mui/joy/Typography';
import { ForwardedRef, forwardRef } from 'react';

export type TypographyProps = JoyTypographyProps;

const Typography = (
  props: TypographyProps,
  ref: ForwardedRef<HTMLSpanElement>,
) => {
  return <JoyTypography {...props} ref={ref} />;
};

export default forwardRef<HTMLSpanElement, TypographyProps>(Typography);

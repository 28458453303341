import {
  AlertDialog,
  Button,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import AuthAddModal from 'components/pages/auth/modals/AuthAddModal';
import MemberInfoModal from 'components/pages/auth/modals/MemberInfoModal';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import useLayout from 'layouts/useLayout';
import {
  AuthGroupsQuery,
  AuthType,
  MemberAuthGroupsQuery,
  useAssignedMembersQuery,
  useSuperAdminDelegateMutation,
  useUpdateAuthGroupMembersMutation,
  useUpdateAuthGroupMutation,
} from 'lim/auth/apis';
import { AUTH_TABLE_COLUMNS, AuthTableProps } from 'lim/auth/fixtures';

interface ManagementTabProps {
  unionSeq: number;
  unionAuthGroupSeq: number;
  memberAuthGroupsQuery: MemberAuthGroupsQuery;
  authGroupsQuery: AuthGroupsQuery;
}

const isPossibleModification = (myAuthType: AuthType, authType: AuthType) => {
  if (authType === 'UNION_MANAGER') {
    if (myAuthType === 'SUPER_ADMIN') return true;
    return false;
  }

  return true;
};
const isPossibleEntrust = (myAuthType: AuthType, authType: AuthType) => {
  if (authType === 'UNION_MANAGER') {
    if (myAuthType === 'SUPER_ADMIN') return true;
  }
  return false;
};

const ManagementTab = ({
  unionSeq,
  unionAuthGroupSeq,
  authGroupsQuery,
  memberAuthGroupsQuery,
}: ManagementTabProps) => {
  const { snackbar } = useFeedback();
  const { pageContext } = useLayoutContext();
  const { logout } = useLayout();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showMemberInfoModal, setShowMemberInfoModal] = useState(false);
  const [alertDialog, setAlertDialog] = useState({
    show: false,
    message: '',
    detail: '',
  });
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: '',
    content: <></>,
    onConfirm: () => {},
  });

  const [selectedMember, setSelectedMember] = useState<number>();
  const [formData, setFormData] = useState({
    name: '',
    memo: '',
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isPossible, setIsPossible] = useState(false);
  const [isEntrustPossible, setIsEntrustPossible] = useState(false);

  const { data, refetch } = authGroupsQuery;
  const { data: authData } = memberAuthGroupsQuery;

  const {
    data: rowData,
    refetch: rowDataRefetch,
    isLoading,
  } = useAssignedMembersQuery(unionSeq, unionAuthGroupSeq);

  const { mutate: infoUpdateMutate } = useUpdateAuthGroupMutation(
    unionSeq,
    unionAuthGroupSeq,
  );
  const { mutate: updateMutate } = useUpdateAuthGroupMembersMutation(
    unionSeq,
    unionAuthGroupSeq,
  );
  const { mutate: delegateMutate } = useSuperAdminDelegateMutation(unionSeq);

  const [rows, setRows] = useState<AuthTableProps[]>([]);

  const handleUpdateGroup = () => {
    const rowIds = rows.map((row) => row.id);

    setModalInfo({
      show: true,
      title: '변경사항을 저장 하시겠습니까?',
      content: (
        <>
          <Typography textColor="text.tertiary">
            권한이 변경되면 권한이 부여된 권한자들이 접근할 수 있는
          </Typography>
          <Typography>메뉴와 권한이 변경됩니다.</Typography>
        </>
      ),
      onConfirm: () => {
        updateMutate(
          { unionMemberSeqs: rowIds },
          {
            onSuccess: () => {
              rowDataRefetch();
              snackbar('변경사항 저장이 완료되었습니다.', {
                color: 'success',
              });
              setModalInfo({
                ...modalInfo,
                show: false,
              });
            },
            onError: (error) => {
              snackbar(error?.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  };

  const handleAddRows = (rows: AuthTableProps[]) => {
    setRows((originRows) => [...originRows, ...rows]);
  };

  const handleDeleteRow = (rowId: number) => {
    const newRows = rows.filter((row) => row.id !== rowId);
    setRows(newRows);
    setModalInfo({
      ...modalInfo,
      show: false,
    });
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInfoUpdate = () => {
    infoUpdateMutate(formData, {
      onSuccess: () => {
        refetch();
        snackbar('그룹 정보 변경이 완료되었습니다.', {
          color: 'success',
        });
      },
      onError: (error) => {
        snackbar(error?.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const handleDelegate = (nickname: string) => {
    setModalInfo({
      show: true,
      title: `${nickname}님에게 조합 최고관리자 권한을 위임하시겠습니까?`,
      content: (
        <>
          <Typography textColor="text.tertiary">
            조합 최고관리자의 권한이 삭제된고 권한이 위임됩니다.
          </Typography>
          <Typography textColor="text.tertiary">
            기존 최고관리자, 위임자, 시스템관리자에게 SMS가 발송됩니다.
          </Typography>
        </>
      ),
      onConfirm: () => {
        if (selectedMember && pageContext?.memberUnionInfo?.unionMemberSeq) {
          delegateMutate(
            {
              delegatorUnionMemberSeq:
                pageContext?.memberUnionInfo.unionMemberSeq,
              delegateeUnionMemberSeq: selectedMember,
            },
            {
              onSuccess: () => {
                rowDataRefetch();
                snackbar('권한이 정상적으로 저장되었습니다.', {
                  color: 'success',
                });
                setShowMemberInfoModal(false);
                setModalInfo({
                  ...modalInfo,
                  show: false,
                });
                logout();
              },
              onError: (error) => {
                snackbar(error?.response?.data.message ?? error.message, {
                  color: 'danger',
                });
                setModalInfo({
                  ...modalInfo,
                  show: false,
                });
              },
            },
          );
        }
      },
    });
  };

  const handleMemberInfoModal = (memberSeq: number) => {
    setShowMemberInfoModal(true);
    setSelectedMember(memberSeq);
  };

  useEffect(() => {
    const currentAuthData = data?.data.find(
      (data) => data.unionAuthGroupSeq === unionAuthGroupSeq,
    );

    if (currentAuthData) {
      setFormData({
        name: currentAuthData.name || '',
        memo: currentAuthData.memo || '',
      });

      if (authData?.data.type && currentAuthData?.type) {
        const isPossible = isPossibleModification(
          authData.data.type,
          currentAuthData.type,
        );
        const isEtnrust = isPossibleEntrust(
          authData.data.type,
          currentAuthData.type,
        );

        setIsPossible(!isPossible);
        setIsEntrustPossible(isEtnrust);
      }

      return;
    }

    setFormData({
      name: '',
      memo: '',
    });
  }, [data?.data, unionAuthGroupSeq]);

  useEffect(() => {
    if (!rowData?.data) {
      return setRows([]);
    }
    const rows = rowData.data.map((row) => {
      const {
        unionMemberSeq,
        level,
        nickname,
        unionRegister,
        member,
        isApproved,
      } = row;

      const validData = () => {
        if (unionRegister) {
          const {
            managementNo,
            // unionRegisterNo,
            name,
            birth,
            mainPhone,
            localAddresses,
          } = unionRegister;

          return {
            managementNo,
            // unionRegisterNo,
            name: name?.name,
            birth,
            phoneNo: mainPhone?.phoneNo,
            localAddresses: localAddresses?.[0]?.zoneRoad,
          };
        }

        const { name, birth, phoneNo, nickname } = member;

        return {
          managementNo: '',
          unionRegisterNo: '',
          name,
          birth,
          phoneNo,
          nickname,
          localAddresses: '',
        };
      };
      return {
        id: unionMemberSeq,
        level,
        nickname,
        isApproved,
        ...validData(),
      };
    });

    setRows(rows);
  }, [rowData?.data]);

  useEffect(() => {
    if (data?.data) {
      const isAutoGenerated = data.data.find(
        (data) =>
          data.isAutoGenerated && data.unionAuthGroupSeq === unionAuthGroupSeq,
      );
      if (isAutoGenerated) setIsDisabled(true);
      else setIsDisabled(false);
    }
  }, [data?.data, unionAuthGroupSeq]);

  return (
    <>
      <Stack gap={2} marginBottom={8}>
        <Typography level="title-lg">권한 그룹 정보</Typography>
        <Stack gap={2}>
          <FormControl>
            <FormLabel>권한 그룹명*</FormLabel>
            <Input
              disabled={isDisabled}
              name="name"
              value={formData.name}
              onChange={handleOnChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>메모</FormLabel>
            <Input
              disabled={isDisabled}
              name="memo"
              value={formData.memo}
              onChange={handleOnChange}
            />
          </FormControl>
          <Stack alignItems="end">
            <Button disabled={isDisabled} onClick={handleInfoUpdate}>
              저장
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={1} marginBottom={2}>
        <Typography level="title-lg">권한자 목록</Typography>
        <Stack height={442}>
          <DataGrid
            rows={rows}
            columns={AUTH_TABLE_COLUMNS(handleMemberInfoModal, handleDeleteRow)}
            loading={isLoading}
            // rowHeight={38}
            disableRowSelectionOnClick
          />
        </Stack>
      </Stack>
      <Stack flexDirection="row" gap={1} justifyContent="end">
        <Button
          variant="outlined"
          disabled={isPossible}
          onClick={() => setShowAddModal(true)}
        >
          추가
        </Button>
        <Button disabled={isPossible} onClick={handleUpdateGroup}>
          저장
        </Button>
      </Stack>

      {/* ------------------------ Modal ------------------------ */}
      <AuthAddModal
        selectedUnions={rows}
        unionSeq={unionSeq}
        open={showAddModal}
        onClose={setShowAddModal}
        onConfirm={handleAddRows}
      />

      {selectedMember && (
        <MemberInfoModal
          unionSeq={unionSeq}
          unionMemberSeq={selectedMember}
          open={showMemberInfoModal}
          onClose={setShowMemberInfoModal}
          onConfirm={handleDelegate}
          isEntrustPossilbe={isEntrustPossible}
        />
      )}

      <Modal
        open={modalInfo.show}
        onClose={() =>
          setModalInfo({
            ...modalInfo,
            show: false,
          })
        }
      >
        <ModalDialog>
          <DialogTitle>{modalInfo.title}</DialogTitle>
          <DialogContent>{modalInfo.content}</DialogContent>
          <DialogActions>
            <Button onClick={modalInfo.onConfirm}>확인</Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() =>
                setModalInfo({
                  ...modalInfo,
                  show: false,
                })
              }
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal open={alertDialog.show}>
        <AlertDialog
          message={alertDialog.message}
          detail={alertDialog.detail}
          onSubmit={() => setAlertDialog({ ...alertDialog, show: false })}
        />
      </Modal>
    </>
  );
};

export default ManagementTab;

/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useTheme } from '@mui/joy';
import { Stack, Typography } from '@wooriga/design-system';
import { useMemo } from 'react';

import { MeetMethods } from 'apis/types/meet';
import PieChartWrapper from 'components/PieChartWrapper';
import { percentage } from 'components/PieChartWrapper/PercentLabel';
import {
  ElectronicVoteSendStatusCountQuery,
  ElectronicVoteTendencyCountQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import { Entries } from 'lim/type';

interface Electronic {
  electronicVoteSendStatusCountQuery?: ElectronicVoteSendStatusCountQuery;
  electronicVoteTendencyCountQuery?: ElectronicVoteTendencyCountQuery;
}

type ElectronicPieChartProps = Electronic & {
  totalCount: number;

  meetMethod: MeetMethods;
};
const translateLabel = (label: string) => {
  switch (label) {
    case 'upper':
      return '긍정';
    case 'middle':
      return '중립';
    case 'lower':
      return '부정';
    case 'undetermined':
      return '미정';
    case 'nothing':
      return '미상담';
    default:
      return '';
  }
};

const ElectronicPieChart = ({
  totalCount,
  meetMethod,
  electronicVoteSendStatusCountQuery,
  electronicVoteTendencyCountQuery,
}: ElectronicPieChartProps) => {
  1;
  const theme = useTheme();

  const chartColors = [
    theme.palette.success[400],
    theme.palette.warning[400],
    theme.palette.danger[400],
    theme.palette.neutral[400],
    theme.palette.neutral[200],
  ];

  const electronicViewRate = useMemo(() => {
    if (!electronicVoteSendStatusCountQuery?.data) return [{ data: [] }];
    const { opened, voted } = electronicVoteSendStatusCountQuery.data.data;

    const openedCount = opened + voted;
    if (!openedCount) return [{ data: [] }];

    const unopened = totalCount - openedCount;

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'opened',
            label: `열람완료 ${openedCount}`,
            // @ts-ignore
            color: theme.palette.blue[400],
            value: openedCount,
          },
          {
            id: 'unopened',
            color: theme.palette.primary[100],
            value: unopened,
          },
        ],
      },
    ];
  }, [electronicVoteSendStatusCountQuery?.data?.data]);

  const electronicVotingRateSeries = useMemo(() => {
    if (!electronicVoteSendStatusCountQuery?.data) return [{ data: [] }];
    const { voted } = electronicVoteSendStatusCountQuery.data.data;

    if (!voted) return [{ data: [] }];

    const unvoted = totalCount - voted;

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'voted',
            label: `투표완료 ${voted}`,
            // @ts-ignore
            color: theme.palette.blue[400],
            value: voted,
          },
          {
            id: 'unvoted',
            color: theme.palette.primary[100],
            value: unvoted,
          },
        ],
      },
    ];
  }, [electronicVoteSendStatusCountQuery?.data?.data]);

  const electronicViewerFriendliness = useMemo(() => {
    if (!electronicVoteTendencyCountQuery?.data) return [{ data: [] }];
    const { opened } = electronicVoteTendencyCountQuery.data.data;

    const total = Object.values(opened).reduce((acc, cur) => {
      return (acc = Number(acc) + Number(cur));
    });

    if (!total) return [{ data: [] }];

    const openedData = (Object.entries(opened) as Entries<typeof opened>).map(
      ([key, value], index) => {
        return {
          id: key,
          color: chartColors[index],
          label: `${translateLabel(key)} ${value}`,
          value,
        };
      },
    );

    return [
      {
        data: openedData,
      },
    ];
  }, [electronicVoteTendencyCountQuery?.data?.data]);

  const electronicVotedSeries = useMemo(() => {
    if (!electronicVoteTendencyCountQuery?.data) return [{ data: [] }];
    const { voted } = electronicVoteTendencyCountQuery.data.data;

    const total = Object.values(voted).reduce((acc, cur) => {
      return (acc = Number(acc) + Number(cur));
    });

    if (!total) return [{ data: [] }];

    const votedData = (Object.entries(voted) as Entries<typeof voted>).map(
      ([key, value], index) => {
        return {
          id: key,
          color: chartColors[index],
          label: `${translateLabel(key)} ${value}`,
          value,
        };
      },
    );

    return [
      {
        data: votedData,
      },
    ];
  }, [electronicVoteTendencyCountQuery?.data?.data]);

  const electronicViewRateLabel = useMemo(() => {
    if (!electronicVoteSendStatusCountQuery?.data)
      return { percentage: '', rate: '' };
    const { opened, voted } = electronicVoteSendStatusCountQuery.data.data;
    const openedCount = opened + voted;

    return {
      percentage: `${percentage(openedCount, totalCount)}%`,
      rate: `${openedCount}명 / ${totalCount}명`,
    };
  }, [electronicVoteSendStatusCountQuery?.data?.data]);

  const electronicVotingRateLabel = useMemo(() => {
    if (!electronicVoteSendStatusCountQuery?.data)
      return { percentage: '', rate: '' };
    const { voted } = electronicVoteSendStatusCountQuery.data.data;

    <tspan dy="0.75rem">
      {voted}명 / {totalCount}명
    </tspan>;

    return {
      percentage: `${percentage(voted, totalCount)}%`,
      rate: `${voted}명 / ${totalCount}명`,
    };
  }, [electronicVoteSendStatusCountQuery?.data?.data]);

  if (!meetMethod.includes('ELECTRONIC')) return <></>;

  return (
    <Stack>
      <Typography level="title-md">전자투표 현황</Typography>
      <Stack flexWrap="wrap" flexDirection="row" gap={2}>
        <PieChartWrapper series={electronicViewRate} title="열람율">
          <PieChartWrapper.PercentLabel
            label={electronicViewRateLabel.percentage}
          />
          <PieChartWrapper.RateLabel label={electronicViewRateLabel.rate} />
        </PieChartWrapper>

        <PieChartWrapper
          series={electronicViewerFriendliness}
          title="전자투표 열람자 우호도"
        />

        <PieChartWrapper series={electronicVotingRateSeries} title="전자투표율">
          <PieChartWrapper.PercentLabel
            label={electronicVotingRateLabel.percentage}
          />
          <PieChartWrapper.RateLabel label={electronicVotingRateLabel.rate} />
        </PieChartWrapper>

        <PieChartWrapper
          series={electronicVotedSeries}
          title="전자투표 투표자 우호도"
        />
      </Stack>
    </Stack>
  );
};

export default ElectronicPieChart;

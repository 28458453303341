import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import { queryClient } from 'apis/ReactQueryProvider';
// import { generateInitRealData } from 'lim/_api';
import { ApiError, ApiResponseData } from 'types/api';

// import { unionRegisterGroupsData } from './json';

export interface UnionRegisterGroupResponse {
  unionRegisterGroupSeq: number;
  name: string;
  memo: string;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
  unionRegisterCount: number;
  autoGenerated: boolean;
}

export type UnionRegisterGroupsQuery = UseQueryResult<
  ApiResponseData<UnionRegisterGroupResponse[]>,
  AxiosError
>;

interface UnionRegisterParams {
  unionRegisterGroupName: string;
  unionRegisterName: string;
}

export const useUnionRegisterGroupsQuery = (
  unionSeq: number,
  params?: UnionRegisterParams,
): UnionRegisterGroupsQuery =>
  useQuery({
    queryKey: [`/unions/${unionSeq}/register-groups`, params],
    // initialData: generateInitRealData(unionRegisterGroupsData),
  });

export interface DeleteRegisterGroupsBody {
  unionRegisterGroupSeqs: number[];
}

export type DeleteRegisterGroupsMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  DeleteRegisterGroupsBody
>;

export const useDeleteRegisterGroupsMutation = (
  unionSeq: number,
): DeleteRegisterGroupsMutation =>
  useMutation({
    mutationFn: async (formData: DeleteRegisterGroupsBody) => {
      const { data } = await axiosInstance.delete<ApiResponseData>(
        `/unions/${unionSeq}/register-groups`,
        { data: formData },
      );

      queryClient.invalidateQueries({
        queryKey: [`/unions/${unionSeq}/register-groups`],
      });

      return data;
    },
  });

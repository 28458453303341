import {
  UseQueryResult,
  useQuery,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import {
  DocumentIssuance,
  DocumentIssuanceBalance,
  DocumentIssuanceInfo,
  DocumentIssuanceRegister,
} from 'apis/types/document-issuance';
import { ApiError, ApiResponseData } from 'types/api';

/**
 * GET
 */

// 자료 발급 대상자(조합원) 목록 조회
export type DocumentIssuanceRegisterReturnData = DocumentIssuanceRegister[];
export interface DocumentIssuanceRegisterParams {
  unionSeq: number;
  unionRegisterNo: string;
  name: string;
  localAddress: string;
  unionRegisterGroupSeq: number;
}
type UseDocumentIssuanceRegisterQueryResult = UseQueryResult<
  ApiResponseData<DocumentIssuanceRegisterReturnData>,
  ApiError
>;

export const useDocumentIssuanceRegisterQuery = (
  params: DocumentIssuanceRegisterParams,
): UseDocumentIssuanceRegisterQueryResult => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/document-issuances/registers`, restParams],
    enabled: !!restParams.unionRegisterGroupSeq,
  });
};

// 자료발급 내역 목록 조회
export type DocumentIssuancesReturnData = DocumentIssuance[];
export interface DocumentIssuancesParams {
  unionSeq: number;
  requestedAtFrom: string;
  requestedAtTo: string;
  completedAtFrom: string;
  completedAtTo: string;
}
type UseDocumentIssuancesQueryResult = UseQueryResult<
  ApiResponseData<DocumentIssuancesReturnData>,
  ApiError
>;

export const useDocumentIssuancesQuery = (
  params: DocumentIssuancesParams,
): UseDocumentIssuancesQueryResult => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/document-issuances`, restParams],
  });
};

// 자료발급 내역 상세 조회
export type DocumentIssuanceReturnData = DocumentIssuance;
export interface DocumentIssuanceParams {
  unionSeq: number;
  documentIssuanceSeq: number;
}
type UseDataIssuanceQueryResult = UseQueryResult<
  ApiResponseData<DocumentIssuanceReturnData>,
  ApiError
>;

export const useDataIssuanceQuery = (
  params: DocumentIssuanceParams,
): UseDataIssuanceQueryResult => {
  const { unionSeq, documentIssuanceSeq } = params;

  return useQuery({
    queryKey: [`/unions/${unionSeq}/document-issuances/${documentIssuanceSeq}`],
  });
};

// 발급한 자료 목록 조회
export type DocumentIssuanceInfoReturnData = DocumentIssuanceInfo[];
export interface DocumentIssuanceInfoParams {
  unionSeq: number;
  documentIssuanceSeq: number;
  unionRegisterName: string;
  localAddress: string;
}
type UseDataIssuanceDetailRecordQueryResult = UseQueryResult<
  ApiResponseData<DocumentIssuanceInfoReturnData>,
  ApiError
>;

export const useDataIssuanceDetailRecordQuery = (
  params: DocumentIssuanceInfoParams,
): UseDataIssuanceDetailRecordQueryResult => {
  const { unionSeq, documentIssuanceSeq, ...restParams } = params;

  return useQuery({
    queryKey: [
      `/unions/${unionSeq}/document-issuances/${documentIssuanceSeq}/documents`,
      restParams,
    ],
  });
};

/**
 * POST
 */

// 자료 발급 신청
export type CreateDocumentIssuanceRequest = CreateDocumentIssuanceParams &
  CreateDocumentIssuanceBody;
export interface CreateDocumentIssuanceParams {
  unionSeq: number;
}
export interface CreateDocumentIssuanceBody {
  unionRegisters: {
    unionRegisterSeq: number;
    registrationAddressSeqs: number[];
  }[];
  requestDocumentCodes: string[];
  dIssuanceType: string;
  ggIssuanceType: string;
  gpIssuanceType: string;
  ttIssuanceType: string;
  tpIssuanceType: string;
  tuIssuanceType: string;
  tjIssuanceType: string;
  hasDmm: boolean;
  hasDgj: boolean;
  hasDmalso: boolean;
  hasTtHistory: boolean;
  hasGgNormal: boolean;
  hasGgPyoje: boolean;
  hasGgTotalPyoje: boolean;
  dRegistrationState: string;
  totalRequestCount: number;
  addressCount: number;
}
type UseCreateDocumentIssuanceMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreateDocumentIssuanceRequest
>;

export const useCreateDocumentIssuanceMutation =
  (): UseCreateDocumentIssuanceMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, ...body } = request;
        const { data } = await axiosInstance.post(
          `/unions/${unionSeq}/document-issuances`,
          body,
        );

        return data;
      },
    });

// 자료 발급 비용 조회
export type CreateDocumentIssuanceBalanceReturnData = {
  items: DocumentIssuanceBalance[];
};
export interface CreateDocumentIssuanceBalanceParams {
  unionSeq: number;
}
type UseCreateDocumentIssuanceBalanceMutationResult = UseMutationResult<
  ApiResponseData<CreateDocumentIssuanceBalanceReturnData>,
  ApiError,
  CreateDocumentIssuanceRequest
>;

export const useCreateDocumentIssuanceBalanceMutation =
  (): UseCreateDocumentIssuanceBalanceMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, ...body } = request;
        const { data } = await axiosInstance.post(
          `/unions/${unionSeq}/document-issuances/cost/calculate`,
          body,
        );

        return data;
      },
    });

// 자료 발급 자료 다운로드
export type CreateDocumentIssuanceDownloadRequest =
  CreateDocumentIssuanceDownloadParams & CreateDocumentIssuanceDownloadBody;
export interface CreateDocumentIssuanceDownloadBody {
  fileUrls: string[];
}
export interface CreateDocumentIssuanceDownloadParams {
  unionSeq: number;
  documentIssuanceSeq: number;
}
type UseCreateDocumentIssuanceDownloadMutationResult = UseMutationResult<
  Blob,
  ApiError,
  CreateDocumentIssuanceDownloadRequest
>;

export const useCreateDocumentIssuanceDownloadMutation =
  (): UseCreateDocumentIssuanceDownloadMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, documentIssuanceSeq, ...body } = request;
        const { data } = await axiosInstance.post(
          `/unions/${unionSeq}/document-issuances/${documentIssuanceSeq}/documents/download`,
          body,
          { responseType: 'blob' },
        );

        return data;
      },
    });

/**
 * DELETE
 */

import JoyTextarea from '@mui/joy/Textarea';
import type { TextareaProps as JoyTextareaProps } from '@mui/joy/Textarea';
import useControlled from '@mui/utils/useControlled';
import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
} from 'react';

import {
  validateInput,
  ValidationOptions,
} from 'design-system/components/inputs/utils';

export interface TextareaProps extends JoyTextareaProps {
  validateOptions?: ValidationOptions<string>;
}

const Textarea = (props: TextareaProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    validateOptions,
    onBlur,
    onChange,
    defaultValue,
    value: valueProp,
    ...other
  } = props;

  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: 'Input',
    state: 'open',
  });

  const handleValidate = useCallback(
    (currentValue: TextareaProps['value']) => {
      if (!validateOptions) return true;

      return validateInput(currentValue, validateOptions);
    },
    [validateOptions],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      const currentValue = event.target.value;

      if (handleValidate(currentValue)) {
        setValue(currentValue);

        onBlur?.(event);
      }
    },
    [handleValidate, onBlur, setValue],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const currentValue = event.target.value;

      if (handleValidate(currentValue)) {
        setValue(currentValue);
        onChange?.(event);
      }
    },
    [handleValidate, onChange, setValue],
  );

  return (
    <JoyTextarea
      {...other}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      ref={ref}
    />
  );
};

export default forwardRef<HTMLDivElement, TextareaProps>(Textarea);

import JoyDialogTitle from '@mui/joy/DialogTitle';
import type { DialogTitleProps as JoyDialogTitleProps } from '@mui/joy/DialogTitle';
import { ForwardedRef, forwardRef } from 'react';

export type DialogTitleProps = JoyDialogTitleProps;

const DialogTitle = (
  props: DialogTitleProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyDialogTitle {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, DialogTitleProps>(DialogTitle);

import { useMemo } from 'react';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import createGridColumns, {
  CreateGridColumnsProps,
  GridColumnsHandlers,
} from 'utils/grid/createGridColumns';

export interface UseCreateGridColumnsProps<H>
  extends Omit<CreateGridColumnsProps<H>, 'handlers'> {
  handlers?: H;
}

const useCreateGridColumns = <H>(props: UseCreateGridColumnsProps<H>) => {
  const { handlers: handlersProp, columns } = props;

  const { getCode } = useCommonCodes();

  const handlers: GridColumnsHandlers<H> = useMemo(
    () => ({
      getCommonCode: getCode,
      ...(handlersProp as H),
    }),
    [getCode, handlersProp],
  );

  const state = useMemo(() => {
    return createGridColumns<H>({ handlers, columns });
  }, [columns, handlers]);

  return state;
};

export default useCreateGridColumns;

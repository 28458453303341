import JoyCard from '@mui/joy/Card';
import type { CardProps as JoyCardProps } from '@mui/joy/Card';
import { ForwardedRef, forwardRef } from 'react';

export type CardProps = JoyCardProps;

const Card = (props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyCard {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, CardProps>(Card);

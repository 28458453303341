import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { LocalAddress } from 'apis/types/common';
import { UnionRegisterLocalAddressRight } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

export interface UnionRegisterLocalAddressRightsParams {
  // Path
  unionSeq: number;
  unionRegisterSeq: number;
}

export type UnionRegisterLocalAddressRightsReturnData =
  UnionRegisterLocalAddressRight[];

export const useUnionRegisterLocalAddressRightsQuery = ({
  unionSeq,
  unionRegisterSeq,
}: UnionRegisterLocalAddressRightsParams): UseQueryResult<
  ApiResponseData<UnionRegisterLocalAddressRightsReturnData>,
  ApiError
> =>
  useQuery({
    queryKey: [
      `/unions/${unionSeq}/registers/${unionRegisterSeq}/local-address-rights`,
    ],
  });

export interface UnionRegisterLocalAddressRightsUpdateParams {
  unionSeq: number;
  unionRegisterSeq: number;
  rights: {
    localAddressSeq: LocalAddress['localAddressSeq'];
    landOwnership?: UnionRegisterLocalAddressRight['landOwnership'];
    buildingOwnership?: UnionRegisterLocalAddressRight['buildingOwnership'];
  }[];
}

export const useUnionRegisterLocalAddressRightsUpdateMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    UnionRegisterLocalAddressRightsUpdateParams
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { unionSeq, unionRegisterSeq, rights } = params;

        const { data } = await axiosInstance.put(
          `/unions/${unionSeq}/registers/${unionRegisterSeq}/local-address-rights`,
          rights,
        );

        return data;
      },
    });

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GridPaginationModel } from '@mui/x-data-grid-premium';
import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  FormControl,
  FormLabel,
  Select,
  Option,
  useGridUtils,
  Box,
  Pagination,
  Grid,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import PasswordConfirmModal from 'components/pages/common/PasswordConfirmModal';
import ExtensionModal from 'components/pages/meet-management/certification/modals/ExtensionModal';
import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import {
  CertificateExtensionBody,
  CertificateMeetData,
  CertificateMeetsParams,
  useCertificateExtensionMutation,
  useCertificateMeetsQuery,
} from 'lim/meetCertification/apis';
import { CERTIFICATE_TABLE_COLUMNS } from 'lim/meetCertification/fixtures';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

import certificationDetail from './detail';

const defaultParams: CertificateMeetsParams = {
  meetMethod: '',
  meetName: '',
  searchFrom: '',
  searchTo: '',
};

const CertificationPage = () => {
  const { unionSeq } = useParams();
  const unionId = Number(unionSeq);

  const { snackbar } = useFeedback();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'certification-list',
    activeSaveSnapshot: true,
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showExtensionModal, setShowExtensionModal] = useState(false);

  const [selectedData, setSelectedData] = useState<CertificateMeetData>();

  const [selectedId, setSelectedId] = useState(0);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const [params, setParams] = useState(defaultParams);

  const [meetMethod, setMeetMethod] = useState('');

  const { data, isLoading, isError, error, refetch } = useCertificateMeetsQuery(
    unionId,
    params,
  );

  const { mutate } = useCertificateExtensionMutation(selectedId);

  const handleOnSelect = (value: string) => {
    setMeetMethod(value);
  };

  const handleOnSearch = (params: CertificateMeetsParams) => {
    let newValue = {} as CertificateMeetsParams;

    Object.entries(params).forEach(([key, value]) => {
      if (key.includes('search') && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    setParams({
      ...newValue,
      meetMethod,
    });
  };

  const handleConfirmModal = (id: number | string) => {
    setShowConfirmModal(true);
    setSelectedId(Number(id));
  };

  const handleOnExtensionClick = (id: string | number) => {
    setShowExtensionModal(true);
    setSelectedId(Number(id));

    const selectedData = data?.data.find((row) => {
      return row.meet.meetSeq === id;
    });

    if (selectedData) setSelectedData(selectedData);
  };

  const handleOnSubmit = (formData: CertificateExtensionBody) => {
    mutate(formData, {
      onSuccess: () => {
        snackbar('문서보간 기간 연장 신청이 완료되었습니다.', {
          color: 'success',
        });
        refetch();
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, {
          color: 'danger',
        });
      },
    });
  };

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const {
          meet: {
            meetSeq,
            name,
            meetMethod,
            onsiteVote: { meetAt },
            meetType,
            no,
          },
          documentStorageEndAt,
          documentStorageStatus,
          documentStorageExtensionStatus,
        } = row;

        return {
          id: meetSeq,
          no,
          name,
          meetMethod,
          meetAt,
          meetType,
          documentStorageEndAt,
          documentStorageStatus,
          documentStorageExtensionStatus,
        };
      }) || []
    );
  }, [data?.data]);
  const { columns } = useCreateGridColumns({
    columns: CERTIFICATE_TABLE_COLUMNS,
    handlers: {
      onNameClick: handleConfirmModal,
      onButtonClick: handleOnExtensionClick,
    },
  });

  const totalElements = data?.pagination?.totalElements || 0;
  const totalDataCount = data?.pagination?.totalDataCount || 0;

  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <Search
          //@ts-ignore
          onSubmit={handleOnSearch}
          //@ts-ignore
          defaultValues={defaultParams}
          onReset={() => {
            setParams(defaultParams);
            setMeetMethod('');
          }}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="총회명을 입력하세요."
                  label="총회명"
                  name="meetName"
                  fullWidth
                />
              </Search.Field>
            </Grid>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>총회방식</FormLabel>
                  <Select
                    sx={{ width: '100%' }}
                    placeholder="총회방식을 선택하세요."
                    name="meetMethod"
                    value={meetMethod}
                    onChange={(_, value) =>
                      handleOnSelect(value as unknown as string)
                    }
                  >
                    <Option value="">전체</Option>
                    <Option value="ELECTRONIC">전자투표</Option>
                    <Option value="ONSITE">현장 투표</Option>
                    <Option value="ONSITE_ELECTRONIC">전자‧현장 투표</Option>
                  </Select>
                </FormControl>
              </Search.Field>
            </Grid>

            {/* <Grid container gap={2}>
              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <DateRangePicker
                    label="기간조회"
                    name="search"
                    sx={{ field: { xs: 12, maxWidth: 200 } }}
                  />
                </Search.Field>
              </Grid>
            </Grid> */}
          </Grid>
        </Search>

        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(totalDataCount)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(totalElements)}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Box>
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableRowSelectionOnClick
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Box>
      </Stack>

      <Stack alignItems="center">
        <Pagination
          color="neutral"
          variant="plain"
          size="md"
          orientation="horizontal"
          showFirstButton
          showLastButton
          hidePrevButton={false}
          hideNextButton={false}
          count={pageTotalCount}
          onChange={(_, page) => {
            page &&
              setPaginationModel({
                ...paginationModel,
                page: page - 1,
              });
          }}
        />
      </Stack>

      <Stack direction="row" gap={1} alignSelf="end">
        <Button
          color="neutral"
          variant="outlined"
          onClick={() => {
            exportExcel();
          }}
        >
          내역 다운
        </Button>
      </Stack>

      <PasswordConfirmModal
        meetSeq={selectedId}
        open={showConfirmModal}
        onClose={setShowConfirmModal}
      />

      <ExtensionModal
        name={selectedData?.meet.name}
        endAt={selectedData?.documentStorageEndAt}
        open={showExtensionModal}
        onClose={setShowExtensionModal}
        onSubmit={handleOnSubmit}
      />
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <CertificationPage />,
  handle: {
    getTitle: () => '증명서 발급',
  },
};

const certification = {
  path: '/unions/:unionSeq/meet-management/certification',
  children: [route, certificationDetail],
  handle: {
    getTitle: () => '증명서 발급',
    getMenuCode: () => 'M1005',
  },
};

export default certification;

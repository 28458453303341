import useControlled from '@mui/utils/useControlled';
import { MouseEvent, useCallback } from 'react';
export type UsePaginationProps = {
  showFirstButton?: boolean;
  showLastButton?: boolean;
  hidePrevButton?: boolean;
  hideNextButton?: boolean;
  count?: number;
  page?: number;
  defaultPage?: number;
  boundaryCount?: number;
  siblingCount?: number;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<unknown>, page: number | null) => void;
};

export interface UsePaginationItem {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  type: PageType | 'page';
  page?: number | null;
  selected?: boolean;
  disabled?: boolean;
}

type PageType =
  | 'first'
  | 'previous'
  | 'next'
  | 'last'
  | 'start-ellipsis'
  | 'end-ellipsis'
  | string
  | number;

const usePagination = (props: UsePaginationProps) => {
  const {
    showFirstButton,
    showLastButton,
    hidePrevButton,
    hideNextButton,
    count = 1,
    page: pageProp,
    defaultPage = 1,
    boundaryCount = 1,
    siblingCount = 1,
    disabled,
    onChange,
  } = props;

  const [page, setPage] = useControlled({
    controlled: pageProp,
    default: defaultPage,
    name: 'Pagination',
    state: 'page',
  });

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, value: number | null) => {
      if (!pageProp && value) {
        setPage(value);
      }
      if (onChange) {
        onChange(event, value);
      }
    },
    [onChange, pageProp],
  );

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(
    Math.max(count - boundaryCount + 1, boundaryCount + 1),
    count,
  );

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - siblingCount * 2 - 1,
    ),
    // Greater than startPages
    boundaryCount + 2,
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2,
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1,
  );

  const itemList: PageType[] = [
    ...(showFirstButton ? ['first'] : []),
    ...(hidePrevButton ? [] : ['previous']),
    ...startPages,

    ...(siblingsStart > boundaryCount + 2
      ? ['start-ellipsis']
      : boundaryCount + 1 < count - boundaryCount
      ? [boundaryCount + 1]
      : []),

    ...range(siblingsStart, siblingsEnd),

    ...(siblingsEnd < count - boundaryCount - 1
      ? ['end-ellipsis']
      : count - boundaryCount > boundaryCount
      ? [count - boundaryCount]
      : []),

    ...endPages,
    ...(hideNextButton ? [] : ['next']),
    ...(showLastButton ? ['last'] : []),
  ];

  const buttonPage = (type: PageType) => {
    switch (type) {
      case 'first':
        return 1;
      case 'previous':
        return page - 1;
      case 'next':
        return page + 1;
      case 'last':
        return count;
      default:
        return null;
    }
  };

  const items: UsePaginationItem[] = itemList.map((item) => {
    return typeof item === 'number'
      ? {
          onClick: (event: MouseEvent<HTMLButtonElement>) => {
            handleClick(event, item);
          },
          type: 'page',
          page: item,
          selected: item === page,
          disabled,
          'aria-current': item === page ? 'true' : undefined,
        }
      : {
          onClick: (event: MouseEvent<HTMLButtonElement>) => {
            handleClick(event, buttonPage(item));
          },
          type: item,
          page: buttonPage(item),
          selected: false,
          disabled:
            disabled ||
            (item.indexOf('ellipsis') === -1 &&
              (item === 'next' || item === 'last' ? page >= count : page <= 1)),
        };
  });

  return { items };
};

export default usePagination;

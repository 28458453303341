import JoyCheckbox from '@mui/joy/Checkbox';
import type { CheckboxTypeMap as JoyCheckboxTypeMap } from '@mui/joy/Checkbox';
import type { OverrideProps } from '@mui/types';
import * as _ from 'lodash-es';
import {
  ChangeEvent,
  ElementType,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  useCallback,
} from 'react';

import useCheckboxGroup from 'design-system/components/inputs/checkbox/useCheckboxGroup';

export type CheckboxProps<
  D extends ElementType = JoyCheckboxTypeMap['defaultComponent'],
  P = { component?: ElementType },
> = OverrideProps<JoyCheckboxTypeMap<P, D>, D>;

interface CheckboxComponent<
  D extends ElementType = JoyCheckboxTypeMap['defaultComponent'],
> extends ForwardRefExoticComponent<CheckboxProps<D>> {
  propTypes?: never;
}

const Checkbox = forwardRef(
  (
    { onChange, ...other }: CheckboxProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const {
      name,
      variant,
      color,
      size,
      disabled,
      value,
      onChange: checkboxGroupHandleChange,
      checkboxProps,
      error,
    } = useCheckboxGroup() || {};

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        let newValues: CheckboxProps['value'][];

        if (event.target.checked) {
          newValues = value ? _.uniq([...value, newValue]) : [newValue];
        } else {
          newValues = _.without(value, newValue);
        }

        checkboxGroupHandleChange?.(newValues);
      },
      [checkboxGroupHandleChange, value],
    );

    return (
      <JoyCheckbox
        name={name}
        variant={variant}
        color={error ? 'danger' : color}
        size={size}
        disabled={disabled}
        onChange={onChange || handleChange}
        {...checkboxProps}
        {...other}
        ref={ref}
      />
    );
  },
) as CheckboxComponent;

Checkbox.displayName = 'Checkbox';

export default Checkbox;

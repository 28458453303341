import JoyModalOverflow from '@mui/joy/ModalOverflow';
import type { ModalOverflowProps as JoyModalOverflowProps } from '@mui/joy/ModalOverflow';
import { ForwardedRef, forwardRef } from 'react';

export type ModalOverflowProps = JoyModalOverflowProps;

const ModalOverflow = (
  props: ModalOverflowProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyModalOverflow {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, ModalOverflowProps>(ModalOverflow);

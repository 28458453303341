import { styled } from '@mui/joy';
import { ForwardedRef, forwardRef } from 'react';

import { Typography } from 'design-system/components/dataDisplay';
import { Box, BoxProps } from 'design-system/components/layout';

export type PanelHeaderSlot = 'root';

export const PanelHeaderClasses = {
  root: 'JoyPanelHeader-root',
};

export interface PanelHeaderProps extends BoxProps {}

export type PanelHeaderOwnerState = PanelHeaderProps;

const PanelHeader = (
  props: PanelHeaderProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { children, ...other } = props;

  return (
    <PanelHeaderRoot {...other} ref={ref}>
      <Typography level="h3" fontSize="lg" fontWeight="xl" lineHeight="xl">
        {children}
      </Typography>
    </PanelHeaderRoot>
  );
};

const PanelHeaderRoot = styled(Box, {
  name: 'JoyPanelHeader',
  slot: 'root',
})`
  padding-right: 2rem;
`;

export default forwardRef<HTMLDivElement, PanelHeaderProps>(PanelHeader);

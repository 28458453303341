import JoyBox from '@mui/joy/Box';
import type { BoxProps as JoyBoxProps } from '@mui/joy/Box';
import { ForwardedRef, forwardRef } from 'react';

export type BoxProps = JoyBoxProps;

const Box = (props: BoxProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyBox {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, BoxProps>(Box);

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GridPaginationModel } from '@mui/x-data-grid-premium';
import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Option,
  Button,
  Select,
  FormControl,
  FormLabel,
  useGridUtils,
  Grid,
  Box,
  Pagination,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MeetsParams, useMeetsQuery } from 'apis/meet/apis';
import { MEETS_HISTORY_TABLE_COLUMNS } from 'apis/meet/fixtures';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import PasswordConfirmModal from 'components/pages/common/PasswordConfirmModal';
import Search from 'components/Search';
import meetHistoryDetail from 'pages/main/meet-management/history/detail';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const defaultParams: MeetsParams = {
  meetName: '',
  moverName: '',
  meetStatus: '',
  openType: '',
  meetMethod: '',
  meetType: '',
};

const MeetHistoryPage = () => {
  const { getGroupCode } = useCommonCodes();

  const { statusGroup, openTypeGroup, methodGroup, meetTypeGroup } = useMemo(
    () => ({
      statusGroup: getGroupCode('MEET_STATUS'),
      openTypeGroup: getGroupCode('MEET_OPEN_TYPE'),
      methodGroup: getGroupCode('MEET_METHOD'),
      meetTypeGroup: getGroupCode('MEET_TYPE'),
    }),
    [getGroupCode],
  );

  const { unionSeq } = useParams();
  const unionId = Number(unionSeq);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const [selectedId, setSelectedId] = useState(0);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const [params, setParams] = useState(defaultParams);
  const [selectParams, setSelectParams] = useState<
    Pick<MeetsParams, 'meetMethod' | 'meetType' | 'meetStatus' | 'openType'>
  >({
    meetMethod: '',
    meetType: '',
    openType: '',
    meetStatus: '',
  });

  const { data, isLoading, isPending, isError, error } = useMeetsQuery(
    unionId,
    params,
  );

  const handleConfirmModal = (id: number | string) => {
    setIsShowConfirmModal(true);

    setSelectedId(Number(id));
  };

  const handleOnSelect = (
    key: 'meetMethod' | 'meetType' | 'meetStatus' | 'openType',
    value: string,
  ) => {
    setSelectParams({
      ...selectParams,
      [key]: value,
    });
  };

  const handleOnSearch = (params: MeetsParams) => {
    setParams({
      ...params,
      ...selectParams,
    });
  };

  const handleOnReset = () => {
    setParams(defaultParams);
    setSelectParams({
      meetMethod: '',
      meetType: '',
      openType: '',
      meetStatus: '',
    });
  };

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'meet-history',
  });

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const {
          no,
          meetSeq,
          name,
          meetAt,
          meetType,
          meetMethod,
          openType,

          agendaCount,
          moverName,
          createdAt,
          meetStatus,
        } = row;
        return {
          id: meetSeq,
          no,
          name,
          meetAt,
          meetType,
          meetMethod,
          openType,
          moverName,

          agendaCount,
          createdAt,
          meetStatus,
        };
      }) || []
    );
  }, [data]);

  const { columns } = useCreateGridColumns({
    columns: MEETS_HISTORY_TABLE_COLUMNS,
    handlers: {
      onLinkClick: handleConfirmModal,
    },
  });

  if (isError) throw error;

  const count = Math.ceil((data?.pagination?.totalDataCount || 0) / 10);

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1.75}>
          <Search
            //@ts-ignore
            onSubmit={handleOnSearch}
            //@ts-ignore
            defaultValues={defaultParams}
            onReset={handleOnReset}
          >
            <Grid container gap={2}>
              <Stack flexDirection="row" flexWrap="wrap" gap={2}>
                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      placeholder="총회명을 입력하세요"
                      label="총회명"
                      name="meetName"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      placeholder="소집/발의자를 입력하세요"
                      label="소집/발의자"
                      name="moverName"
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>상태</FormLabel>
                      <Select
                        id="general-meeting-history-status"
                        fullWidth
                        name="meetStatus"
                        value={selectParams.meetStatus}
                        onChange={(_, value) =>
                          handleOnSelect(
                            'meetStatus',
                            value as unknown as string,
                          )
                        }
                      >
                        <Option value="">전체</Option>
                        {statusGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>총회 방식</FormLabel>
                      <Select
                        id="general-meeting-history-method"
                        fullWidth
                        name="meetMethod"
                        // placeholder="Dog"
                        value={selectParams.meetMethod}
                        onChange={(_, value) =>
                          handleOnSelect(
                            'meetMethod',
                            value as unknown as string,
                          )
                        }
                      >
                        <Option value="">전체</Option>
                        {methodGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>회의 유형</FormLabel>
                      <Select
                        id="general-meeting-history-category"
                        fullWidth
                        // placeholder="Dog"
                        value={selectParams.openType}
                        name="openType"
                        onChange={(_, value) =>
                          handleOnSelect('openType', value as unknown as string)
                        }
                      >
                        <Option value="">전체</Option>
                        {openTypeGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>총회 종류</FormLabel>
                      <Select
                        id="general-meeting-history-type"
                        fullWidth
                        // placeholder="Dog"
                        value={selectParams.meetType}
                        name="meetType"
                        onChange={(_, value) =>
                          handleOnSelect('meetType', value as unknown as string)
                        }
                      >
                        <Option value="">전체</Option>
                        {meetTypeGroup?.items.map((item) => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </Search.Field>
                </Grid>
              </Stack>
            </Grid>
          </Search>

          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(data?.pagination?.totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(data?.pagination?.totalElements)}
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Box>
            <DataGrid
              apiRef={datagridApiRef}
              rows={rows}
              columns={columns}
              loading={isLoading || isPending}
              checkboxSelection
              disableRowSelectionOnClick
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            />
          </Box>
        </Stack>
        <Stack alignItems="center">
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton
            showLastButton
            hidePrevButton={false}
            hideNextButton={false}
            count={count}
            onChange={(_, page) => {
              page &&
                setPaginationModel({
                  ...paginationModel,
                  page: page - 1,
                });
            }}
          />
        </Stack>
        <Stack direction="row" alignSelf="end" gap={2}>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              exportExcel();
            }}
          >
            내역 다운
          </Button>
        </Stack>
      </Stack>

      <PasswordConfirmModal
        meetSeq={selectedId}
        open={isShowConfirmModal}
        onClose={setIsShowConfirmModal}
      />
    </>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <MeetHistoryPage />,
  handle: {
    getTitle: () => '총회 내역관리',
  },
};

const meetHistory = {
  path: '/unions/:unionSeq/meet-management/history',
  children: [route, meetHistoryDetail],
  handle: {
    getTitle: () => '총회 내역 관리',
    getMenuCode: () => 'M1004',
  },
};

export default meetHistory;

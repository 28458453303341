import JoyStack from '@mui/joy/Stack';
import type { StackProps as JoyStackProps } from '@mui/joy/Stack';
import { ForwardedRef, forwardRef } from 'react';

export type StackProps = JoyStackProps;

const Stack = (props: StackProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyStack {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, StackProps>(Stack);

import { Chip, GridColDef, Link } from '@wooriga/design-system';

import { MeetMethods, MeetStatus } from 'apis/types/meet';
import { OnLinkClick, TypeGuardCol } from 'lim/_fixtures/type';
import { formatBirth, formatDate, formatTelephone } from 'utils/format';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

type MeetTypes = 'REGULAR' | 'SPECIAL' | 'FOUNDING';
type OpenTypes = 'GENERAL' | 'DELEGATE' | 'BOARD' | 'ELECTION_COMMISSION';
interface MeetHistoryTableColumProps {
  no: number;
  name: string;
  meetAt: string;
  meetType: MeetTypes;
  meetMethod: MeetMethods;
  openType: OpenTypes;
  // moveName: string;
  moverName: string;
  agendaCount: number;
  createdAt: string;
  meetStatus: MeetStatus;
}

export const MEETS_HISTORY_TABLE_COLUMNS = ({
  onLinkClick,
  getCommonCode,
}: GridColumnsHandlers<{
  onLinkClick: OnLinkClick;
}>): TypeGuardCol<MeetHistoryTableColumProps>[] => [
  // { field: 'no', headerName: 'No', width: 78 },
  {
    field: 'name',
    headerName: '총회명',
    width: 200,
    renderCell: (params) => (
      <Link
        display="inline"
        width="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        onClick={() => onLinkClick(params.id)}
      >
        {params.value}
      </Link>
    ),
  },
  {
    field: 'meetAt',
    headerName: '총회일자',
    width: 78,
    valueGetter: (value) => formatDate(value),
  },
  {
    field: 'meetType',
    headerName: '총회종류',
    width: 78,
    valueGetter: (value) => getCommonCode('MEET_TYPE', value)?.name,
  },
  {
    field: 'meetMethod',
    headerName: '총회방식',
    width: 78,
    valueGetter: (value) => getCommonCode('MEET_METHOD', value)?.name,
  },
  {
    field: 'openType',
    headerName: '회의유형',
    width: 78,
    valueGetter: (value) => getCommonCode('MEET_OPEN_TYPE', value)?.name,
  },
  {
    field: 'moverName',
    headerName: '소집/발의자',
    width: 90,
  },
  {
    field: 'agendaCount',
    headerName: '안건수',
    type: 'number',
    width: 64,
  },
  {
    field: 'createdAt',
    headerName: '등록일시',
    width: 100,
    valueGetter: (value) => formatDate(value),
  },
  {
    field: 'meetStatus',
    headerName: '상태',
    width: 78,
    valueGetter: (value) => getCommonCode('MEET_STATUS', value)?.name,
    renderCell: (params) => {
      return <Chip color="primary">{params.value}</Chip>;
    },
  },
];

export interface UnionRegistersTableProps {
  id: number;
  unionRegisterNo: string; // 연번
  positionDescription: string; // 직책
  agent?: string; // 대리인여부
  shareType: string; // 소유구분
  name: string; // 이름
  gender: string; // 성별
  birth: string; // 생년월일
  phoneNo?: string; // 연락처
  // localAddresses: string; // 소재지
}

export const UNION_REGISTER_TABLE_COLUMNS = ({
  onLinkClick,
  getCommonCode,
}: GridColumnsHandlers<{ onLinkClick: OnLinkClick }>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      width: 78,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 78,
      groupable: true,
      groupingValueGetter: (value) =>
        (value && getCommonCode('UNION_REGISTER_SHARE_DIVIDE', value)?.name) ||
        '',
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_SHARE_DIVIDE', value)?.name || '',
    },

    {
      field: 'isRepresentative',
      headerName: '대표자',
      type: 'boolean',
      width: 78,
      groupingValueGetter: (value) => (value === true ? true : false),
      valueGetter: (value) => (value === true ? true : false),
      groupable: true,
    },

    {
      field: 'hasVotingRight',
      headerName: '의결권',
      type: 'boolean',
      width: 78,
      groupingValueGetter: (value) => (value === true ? true : false),
      valueGetter: (value) => (value === true ? true : false),
      groupable: true,
    },

    {
      field: 'positionDescription',
      headerName: '직책',
      width: 78,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 90,
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onLinkClick(params.id)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'agent',
      headerName: '대리인',
    },
    {
      field: 'birth',
      headerName: '생년월일',
      valueGetter: (_, value) => formatBirth(value.birth),
    },
    {
      field: 'gender',
      headerName: '성별',
      width: 50,
      valueGetter: (value: string) =>
        value === 'M' ? '남자' : value === 'F' ? '여자' : '',
    },
    {
      field: 'phoneNo',
      headerName: '조합원 연락처',
      minWidth: 120,
      valueGetter: (_, value) => formatTelephone(value.phoneNo),
    },
    // { field: 'localAddresses', headerName: '소재지', flex: 1 },
  ] as GridColDef<UnionRegistersTableProps>[];

import { GridPaginationModel } from '@mui/x-data-grid-premium';
import {
  Box,
  DataGrid,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { COUNSEL_CREATE_GRID_COLUMNS } from 'apis/union/register/fixtures/counsel';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import {
  UnionRegistersParams,
  useUnionRegistersQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import counselCreateInputPage from 'pages/main/counsel-management/create/input';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const CounselCreatePage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState(false);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  const handleClickName = useCallback((unionRegisterSeq: number) => {
    setSelectUnionRegisterSeq(unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const defaultSearchParams = {
    unionRegisterNo: '',
    name: '',
  };

  const [searchParams, setSearchParams] =
    useState<UnionRegistersParams>(defaultSearchParams);

  const {
    data: unionRegistersReturnData,
    isLoading,
    isPending,
    isError,
    error,
  } = useUnionRegistersQuery(unionSeq, searchParams);

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...defaultSearchParams,
    }));
  };

  const rows = useMemo(
    () => unionRegistersReturnData?.data || [],
    [unionRegistersReturnData],
  );

  const totalElements =
    unionRegistersReturnData?.pagination?.totalElements || 0;
  const totalDataCount =
    unionRegistersReturnData?.pagination?.totalDataCount || 0;
  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  if (isError) throw error;

  return (
    <>
      <Stack gap={2}>
        <Stack gap={1.75}>
          <Search
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={setSearchParams}
            defaultValues={defaultSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    label="연번"
                    name="unionRegisterNo"
                    placeholder='숫자 또는 "-"입력'
                    validateOptions={{
                      maxLength: 11,
                      regex: /^(?!.*--)[0-9-]*$/,
                    }}
                    fullWidth
                  />
                </Search.Field>
              </Grid>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    placeholder="조합원 이름 입력"
                    label="조합원 이름"
                    name="name"
                    slotProps={{
                      input: { maxLength: 30 },
                    }}
                    fullWidth
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>
        </Stack>

        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(totalDataCount)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(totalElements)}
            </Typography>
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Box>
            <DataGrid
              rows={rows}
              columns={COUNSEL_CREATE_GRID_COLUMNS(handleClickName)}
              getRowId={(row) => row.unionRegisterSeq}
              loading={isLoading || isPending}
              disableRowSelectionOnClick
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            />
          </Box>

          <Stack alignItems="center">
            <Pagination
              color="neutral"
              variant="plain"
              size="md"
              orientation="horizontal"
              showFirstButton={false}
              showLastButton={false}
              hidePrevButton={false}
              hideNextButton={false}
              count={pageTotalCount}
              onChange={(_, page) => {
                page &&
                  setPaginationModel({
                    ...paginationModel,
                    page: page - 1,
                  });
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}
    </>
  );
};

const counselCreatePage: CustomRouteObject = {
  path: '/unions/:unionSeq/counsel-management/create',
  children: [
    {
      path: '',
      index: true,
      element: <CounselCreatePage />,
    },
    counselCreateInputPage,
  ],
  handle: {
    getTitle: () => '상담 등록',
    getMenuCode: () => 'M0901',
  },
};

export default counselCreatePage;

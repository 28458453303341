import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Callout,
  Checkbox,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  CreatePostSendBody,
  useCreatePostBalanceMutation,
} from 'apis/post/apis';
import CommonCode from 'components/CommonCode';
import { commaizeNumber, formatTelephone } from 'utils/format';

const DEFAULT_VALUES = {
  name: '',
  address: '',
  zipNo: '',
  phoneNo: '',
  detailAddress: '',
  sendQuantity: 0,
  fileSeq: 0,
  postType: '',
  colorType: '',
  flexType: '',
  isStapler: false,
  recipients: [],
};

export interface PostBalanceModalProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'onSubmit'> {
  unionSeq: number;
  formValues?: CreatePostSendBody;
  onClose?: (value: boolean) => void;
  onSubmit?: () => void;
}

const PostBalanceModal = (props: PostBalanceModalProps) => {
  const {
    unionSeq,
    formValues = DEFAULT_VALUES,
    onClose,
    onSubmit,
    ...rest
  } = props;

  const {
    name,
    address,
    zipNo,
    phoneNo,
    postType,
    colorType,
    flexType,
    isStapler,
    recipients,
  } = formValues;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { data: balance, mutate: calculatePostBalance } =
    useCreatePostBalanceMutation();

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') return;

    onClose?.(false);
  };

  const handleSubmit = () => {
    onSubmit?.();
    onClose?.(false);
  };

  useEffect(() => {
    calculatePostBalance({ unionSeq, ...formValues });
  }, [unionSeq, formValues, calculatePostBalance]);

  return (
    <Modal onClose={handleClose} {...rest}>
      <ModalOverflow>
        <ModalDialog>
          <DialogTitle>우편발송</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack gap={3}>
              <Stack gap={2}>
                <Callout
                  variant="outlined"
                  color="warning"
                  startDecorator={<InfoIcon />}
                >
                  우편물 제작 및 발송은 발송 신청 이후 내용 수정, 취소 및 환불이
                  불가합니다.
                  <br />
                  주소가 정확하지 않은 경우 전체 발송이 취소됩니다. 정확한
                  주소를 확인 후 다시 발송하세요.
                  <br />
                  결제일을 기준으로 약 5일 이내 제작 및 발송하여 수신자에게
                  전달됩니다. (토요일, 일요일,
                  <br />
                  공휴일은 배달 소요일에서 제외됩니다)
                  <br />
                  우편봉투는 A4가 담기는 대봉투로 진행되며 최대 150장까지 동봉이
                  가능합니다.
                  <br />
                  모든 문서는 선택하신 출력 색상(컬러 or 흑백)으로 동일하게 출력
                  및 계산됩니다.
                </Callout>

                <Callout
                  variant="outlined"
                  color="danger"
                  startDecorator={<CancelIcon />}
                >
                  <Typography textColor="danger.700">
                    이용불가 우편물
                  </Typography>
                  우편법 제 1조의2(정의) 7호의 서신 성격의 인쇄물
                  <br />
                  우편법 제 17조에 따라 접수가 제한되는 우편물
                  <br />
                  그 밖에 사회적으로 물의를 일으킬 수 있다고 판단되는 내용이
                  포함된 우편물
                  <br />
                  받는 사람의 개인정보가 기재된 우편물 등
                  <br />
                  봉인 작업 불가 인쇄물 (자석, 스티커 등이 부착된 인쇄물,
                  스테이플러로 작업된 인쇄물)
                </Callout>
              </Stack>

              <Stack alignItems="center" gap={2}>
                <Box sx={{ width: '100%' }}>
                  <Descriptions
                    variant="outlined"
                    color="neutral"
                    size="md"
                    columns={1}
                  >
                    <DescriptionsItem label="발송자명">{name}</DescriptionsItem>
                    <DescriptionsItem label="발송자 주소">
                      {address}
                    </DescriptionsItem>
                    <DescriptionsItem label="발송자 우편번호">
                      {zipNo}
                    </DescriptionsItem>
                    <DescriptionsItem label="발송자 연락처">
                      {formatTelephone(phoneNo)}
                    </DescriptionsItem>
                    <DescriptionsItem label="발송방식">
                      <CommonCode
                        groupCode="POST_SEND_DIVIDE"
                        code={postType}
                      />
                    </DescriptionsItem>
                    <DescriptionsItem label="수신자 수">
                      {commaizeNumber(recipients.length)} 명
                    </DescriptionsItem>
                    <DescriptionsItem label="발송매수">
                      {commaizeNumber(balance?.data?.sentPageCount || 0)} 장
                    </DescriptionsItem>
                    <DescriptionsItem label="출력색상">
                      <CommonCode
                        groupCode="POST_COLOR_DIVIDE"
                        code={colorType}
                      />
                    </DescriptionsItem>
                    <DescriptionsItem label="출력형태">
                      <CommonCode
                        groupCode="POST_FLEX_DIVIDE"
                        code={flexType}
                      />
                    </DescriptionsItem>
                    <DescriptionsItem label="스테이플러">
                      {isStapler ? '예' : '아니요'}
                    </DescriptionsItem>
                    <DescriptionsItem label="비용">
                      제작비{' '}
                      {commaizeNumber(balance?.data?.productionCost || 0)} 원
                    </DescriptionsItem>
                    <DescriptionsItem>
                      발송비 {commaizeNumber(balance?.data?.shippingCost || 0)}{' '}
                      원
                    </DescriptionsItem>
                    <DescriptionsItem>
                      총액 {commaizeNumber(balance?.data?.totalCost || 0)} 원
                    </DescriptionsItem>
                  </Descriptions>
                </Box>

                <Typography color="neutral" textAlign="center">
                  우편물 내용 및 수신자 정보에 오류가 없는지 다시 한번
                  확인해주세요.
                  <br />
                  우편물상의 모든 내용은 작성자에게 책임이 있습니다.
                </Typography>

                <Checkbox
                  label="위 사항을 모두 확인하였습니다."
                  onChange={handleChecked}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button disabled={!isChecked} onClick={handleSubmit}>
              우편발송
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => onClose?.(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default PostBalanceModal;

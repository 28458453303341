import { Button, Chip, Link } from '@wooriga/design-system';

import { OnLinkClick, TypeGuardCol } from 'lim/_fixtures/type';
import { formatDate, formatDateTime } from 'utils/format';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

export type CertificateTableProps = {
  no: number;
  name: string;
  meetMethod: string;
  meetAt: string;
  meetType: string;
  documentStorageEndAt: string;
  documentStorageStatus: string;
  documentStorageExtensionStatus: string;
};

export interface CertificateTableColumnProps {
  onNameClick: OnLinkClick;
  onButtonClick: OnLinkClick;
}

export const CERTIFICATE_TABLE_COLUMNS = ({
  onNameClick,
  onButtonClick,
  getCommonCode,
}: GridColumnsHandlers<CertificateTableColumnProps>) =>
  [
    // { field: 'no', headerName: 'No' },
    {
      field: 'name',
      headerName: '총회명',
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.id)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'meetMethod',
      headerName: '총회방식',
      valueGetter: (value) => getCommonCode('MEET_METHOD', value)?.name,
    },
    {
      field: 'meetAt',
      headerName: '총회일자',
      valueGetter: (value) => formatDate(value),
    },
    {
      field: 'meetType',
      headerName: '총회종류',
      valueGetter: (value) => getCommonCode('MEET_TYPE', value)?.name,
    },
    {
      field: 'documentStorageEndAt',
      headerName: '문서보관 종료일',
      valueGetter: (value) => formatDate(value),
    },
    {
      field: 'documentStorageStatus',
      headerName: '문서보관 상태',
      valueGetter: (value) =>
        getCommonCode('DOCUMENT_STORAGE_STATUS', value)?.name,
    },
    {
      field: 'documentStorageExtensionStatus',
      headerName: '문서보관 연장',
      valueGetter: (value) =>
        getCommonCode('DOCUMENT_KEEP_EXTENSION_STATUS', value)?.name,
      renderCell: (params) => {
        if (params.value) return <Chip>{params.value}</Chip>;

        return <Button onClick={() => onButtonClick(params.id)}>신청</Button>;
      },
    },
  ] as TypeGuardCol<CertificateTableProps>[];

export type CertificationDetailTableProps = {
  unionRegisterSeq: number;
  unionRegisterNo: string;
  agent: string;
  name: string;
  receivedAt: string;
  openedAt: string;
  votedAt: string;
  electronicVoteStatus: string;
  certificateIssuanceStatus: string;
};

export interface CertificationDetailTableColumnProps {
  onNameClick: (id: number) => void;
}

export const CERTIFICATION_DETAIL_TABLE_COLUMNS = ({
  onNameClick,
  getCommonCode,
}: GridColumnsHandlers<CertificationDetailTableColumnProps>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'agent',
      headerName: '대리인',
      type: 'boolean',
    },
    {
      field: 'name',
      headerName: '이름',
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.row.unionRegisterSeq)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'receivedAt',
      headerName: '수신일시',
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'openedAt',
      headerName: '열람일시',
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'votedAt',
      headerName: '투표일시',
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'electronicVoteStatus',
      headerName: '전자투표 여부',
      valueGetter: (value) => getCommonCode('VOTING_STATUS_TYPE', value)?.name,
      renderCell: ({ value }) => {
        return <Chip>{value}</Chip>;
      },
    },
    {
      field: 'certificateIssuanceStatus',
      headerName: '발급상태',
      valueGetter: (value) =>
        getCommonCode('MEET_CERT_ISSUABLE_STATUS', value)?.name,
      renderCell: ({ value }) => {
        return <Chip>{value}</Chip>;
      },
    },
  ] as TypeGuardCol<CertificationDetailTableProps>[];

import JoyIconButton from '@mui/joy/IconButton';
import type {
  ExtendIconButton,
  IconButtonTypeMap as JoyIconButtonTypeMap,
} from '@mui/joy/IconButton';
import type { OverrideProps } from '@mui/types';
import {
  type ElementType,
  type ForwardRefExoticComponent,
  forwardRef,
} from 'react';

export type IconButtonProps<
  D extends ElementType = JoyIconButtonTypeMap['defaultComponent'],
  P = { component?: ElementType },
> = OverrideProps<JoyIconButtonTypeMap<P, D>, D>;

interface IconButtonComponent<
  D extends ElementType = JoyIconButtonTypeMap['defaultComponent'],
> extends ForwardRefExoticComponent<IconButtonProps<D>> {
  propTypes?: never;
  muiName?: string;
}

const IconButton = forwardRef((props, ref) => {
  return <JoyIconButton {...props} ref={ref} />;
}) as IconButtonComponent;

IconButton.displayName = 'IconButton';
IconButton.muiName = 'IconButton';

export default IconButton as ExtendIconButton<JoyIconButtonTypeMap>;

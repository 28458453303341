import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { enableMocking } from 'utils/mock/worker';

// eslint-disable-next-line no-restricted-imports
import App from './App';

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
});

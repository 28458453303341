import { QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import * as _ from 'lodash-es';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';

import { commonCodesQuery } from 'apis/common/apis';
import {
  memberInfoQuery,
  MemberInfoReturnData,
  memberUnionsQuery,
  MemberUnionsReturnData,
} from 'apis/member/apis';
import {
  memberAvailableMenusQuery,
  MemberAvailableMenusReturnData,
} from 'apis/union/member/available-menu/apis';
import MainLayout from 'layouts/MainLayout';
import mainPage from 'pages/main';
import communityPage from 'pages/main/community';
import counselManagementPage from 'pages/main/counsel-management';
import generalMeetingManagementPage from 'pages/main/meet-management';
import settingPage from 'pages/main/setting';
import unionManagementPage from 'pages/main/union-management';
import ErrorBoundary from 'routes/ErrorBoundary';
import { ApiErrorData, ApiResponseData } from 'types/api';
import { CustomRouteObject } from 'types/route';
import { getCookie, setCookie } from 'utils/cookie';

const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const memberSeq = await getCookie('memberSeq');

    if (!memberSeq) return redirect('/login');

    const unionSeq = Number(params.unionSeq);
    const mQuery = memberInfoQuery({ memberSeq });
    const ccQuery = commonCodesQuery();

    try {
      const { data: memberInfo }: ApiResponseData<MemberInfoReturnData> =
        await queryClient.fetchQuery(mQuery);

      if (!memberInfo) return redirect('/login');

      const muQuery = memberUnionsQuery({
        memberSeq: memberInfo.memberSeq,
      });
      const { data: memberUnions }: ApiResponseData<MemberUnionsReturnData> =
        await queryClient.fetchQuery(muQuery);
      await queryClient.fetchQuery(ccQuery);

      if (memberInfo && memberUnions) {
        if (memberUnions.length === 0) throw new Response('', { status: 404 });

        // 진입 후 새로고침이나 조합 이동(조합 식별 번호 있음)
        // 해당 조합의 권한이 있으면 진입
        // 없으면 Error Boundry 여기서 어떻게 해주어야 할지 애매함..

        if (!unionSeq) {
          const matchMemberUnionInfo = memberUnions[0];
          await setCookie('unionName', matchMemberUnionInfo.name);
          return redirect(`/unions/${matchMemberUnionInfo.unionSeq}`);
        }

        const matchMemberUnionIndex = _.findIndex(memberUnions, { unionSeq });
        const matchMemberUnionInfo = memberUnions[matchMemberUnionIndex];

        const muamQuery = memberAvailableMenusQuery({
          unionSeq,
          unionMemberSeq: matchMemberUnionInfo.unionMemberSeq,
        });

        const {
          data: availableMenus,
        }: ApiResponseData<MemberAvailableMenusReturnData> =
          await queryClient.fetchQuery(muamQuery);

        await setCookie('unionName', matchMemberUnionInfo.name);
        return {
          memberInfo,
          memberUnions,
          memberUnionInfo: matchMemberUnionInfo,
          availableMenus: availableMenus,
        };
      }
    } catch (error) {
      if (isAxiosError<ApiErrorData>(error) && error.response?.status === 401) {
        return redirect('/login');
      }

      throw error;
    }
  };

const children: CustomRouteObject[] = [
  mainPage,
  communityPage,
  unionManagementPage,
  counselManagementPage,
  generalMeetingManagementPage,
  settingPage,
];

const mainRouter = (queryClient: QueryClient): CustomRouteObject => ({
  path: '/',
  element: <MainLayout routes={children} />,
  errorElement: <ErrorBoundary />,
  loader: loader(queryClient),
  shouldRevalidate: ({ currentParams, nextParams }) =>
    currentParams.unionSeq !== nextParams.unionSeq,
  children,
});

export default mainRouter;

import JoyChip from '@mui/joy/Chip';
import type { ChipProps as JoyChipProps } from '@mui/joy/Chip';
import { ForwardedRef, forwardRef } from 'react';

export type ChipProps = JoyChipProps;

const Chip = (props: ChipProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyChip {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, ChipProps>(Chip);

import {
  Button,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
} from '@wooriga/design-system';
import { ForwardedRef, forwardRef } from 'react';

import {
  usePatchTransactionStatusMutation,
  useTransactionDetailQuery,
} from 'apis/charges/apis';
import CommonCode from 'components/CommonCode';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { commaizeNumber, formatDate } from 'utils/format';

export interface ConfirmChargeModalProps<TFieldValues> {
  defaultValues?: TFieldValues;
  confirmChargeModalOpen: boolean;
  transactionSeq: number;
  onClose: () => void;
  refetchChargeList: () => void;
  setApplyTaxModalOpen: () => void;
}

const ConfirmChargeModal = (
  props: ConfirmChargeModalProps<{ chargeAmount: number }>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    confirmChargeModalOpen,
    onClose,
    transactionSeq,
    refetchChargeList,
    setApplyTaxModalOpen,
  } = props;

  const { snackbar } = useFeedback();
  const { pageContext } = useLayoutContext();
  const { memberUnionInfo } = pageContext || {};

  const { data: transactionDetail } = useTransactionDetailQuery({
    depositChargeSeq: transactionSeq,
    unionSeq: memberUnionInfo?.unionSeq || 0,
  });
  const { mutate: patchChargeStatus, isPending: isChargeCancelling } =
    usePatchTransactionStatusMutation();

  const handleChangeChargeStatus = () => {
    patchChargeStatus(
      {
        unionSeq: memberUnionInfo?.unionSeq || 0,
        depositChargeSeq: transactionSeq,
        status: 'CANCEL',
      },
      {
        onSuccess: () => {
          snackbar('취소 되었습니다.', { color: 'success' });
          refetchChargeList();
          onClose();
        },
        onError: (err) => {
          snackbar(err.response?.data.message ?? err.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const {
    requestAmount,
    depositorName,
    depositorPhoneNo,
    requesterName,
    taxInvoiceStatus,
    taxInvoiceRequestAt,
  } = transactionDetail?.data || {};

  const chargeInfo = [
    { label: '충전신청액', children: `${commaizeNumber(requestAmount)}원` },
    { label: '비상연락처', children: depositorPhoneNo },
    { label: '입금자명', children: depositorName },
    { label: '신청인', children: requesterName },
    {
      label: '세금계산서 발행',
      children: (
        <CommonCode
          // 추가되면...변경 필요
          groupCode="TAX_INVOICE_STATUS"
          code={taxInvoiceStatus || ''}
        />
      ),
    },
    {
      label: '세금계산서 발행 요청일자',
      children: taxInvoiceRequestAt && formatDate(taxInvoiceRequestAt),
    },
  ];
  return (
    <Modal open={confirmChargeModalOpen} onClose={onClose} ref={ref}>
      <ModalOverflow>
        <ModalDialog
          sx={{
            width: '600px',
          }}
        >
          <DialogTitle>예치금 충전 신청 정보</DialogTitle>
          <DialogContent>
            <Descriptions
              color="neutral"
              variant="outlined"
              size="lg"
              columns={1}
            >
              {chargeInfo.map(({ label, children }) => (
                <DescriptionsItem key={label} label={label}>
                  {children}
                </DescriptionsItem>
              ))}
            </Descriptions>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              size="md"
              onClick={setApplyTaxModalOpen}
              disabled={taxInvoiceStatus !== 'NONE' || isChargeCancelling}
            >
              세금계산서 발행정보 입력
            </Button>
            <Button
              type="submit"
              size="md"
              variant="outlined"
              color="danger"
              onClick={handleChangeChargeStatus}
              loading={isChargeCancelling}
            >
              충전 신청 취소
            </Button>
            <Button
              size="md"
              variant="outlined"
              color="neutral"
              onClick={onClose}
              disabled={isChargeCancelling}
            >
              닫기
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};
export default forwardRef(ConfirmChargeModal);

import { Stack, Typography } from '@wooriga/design-system';
import { ReactElement } from 'react';

export interface UnionDataProps {
  label?: string;
  content?: string | ReactElement;
  span?: number;
}

interface UnionInfoCardProps {
  json: UnionDataProps[] | object;
  keysToRender?: string[];
}
const convertToUnionDataProps = (
  data: object,
  keysToRender?: string[],
): UnionDataProps[] => {
  const map: { [key: string]: string } = {
    address: '주소',
    detailAddress: '상세주소',
    zipNo: '우편번호',
    createdAt: '생성일',
    email: '이메일',
    faxNo: '팩스번호',
    managerPhoneNo: '담당자 전화번호',
    managerName: '담당자 이름',
    modifiedAt: '수정일',
    name: '사무실 이름',
    phoneNo: '전화번호',
  };

  return Object.entries(data)
    .map(([key, value]) => {
      if (keysToRender && !keysToRender.includes(key)) {
        return null;
      }
      const label = map[key] || key;
      if (typeof value === 'object' && !Array.isArray(value)) {
        return convertToUnionDataProps(value, keysToRender).map(
          (nestedItem) => ({
            ...nestedItem,
            label: `${nestedItem.label}`,
          }),
        );
      }
      return { label, content: String(value) };
    })
    .flat()
    .filter(Boolean) as UnionDataProps[];
};

const UnionInfoCard = ({ json, keysToRender }: UnionInfoCardProps) => {
  const convertedJson = convertToUnionDataProps(json || {}, keysToRender);
  return (
    <Stack gap={1.25}>
      {convertedJson.length > 0 ? (
        convertedJson
          .filter(({ label }) => label)
          .map(({ label, content }, index) => (
            <Stack display="flex" flexDirection="row" gap={1} key={index}>
              {label === '사무실 이름' ? (
                <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                  {content}
                </Typography>
              ) : (
                <>
                  <Typography minWidth={100}>{label}</Typography>
                  <Typography>{content}</Typography>
                </>
              )}
            </Stack>
          ))
      ) : (
        <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
          등록된 사무실이 정보가 없습니다.
        </Typography>
      )}
    </Stack>
  );
};

export default UnionInfoCard;

import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import useLayoutContext from 'hooks/useLayoutContext';
import { useRegisterStatusQuery } from 'kwon/registers/apis';
import registerCreatePage from 'pages/main/union-management/registers/create';
import registerModifyPage from 'pages/main/union-management/registers/modify';
import ConfirmStep from 'pages/main/union-management/registers/steps/ConfirmStep';
import NoneStep from 'pages/main/union-management/registers/steps/NoneStep';
import RegisteringStep from 'pages/main/union-management/registers/steps/RegisteringStep';
import { CustomRouteObject } from 'types/route';

export interface PostFormValues {
  name: string;
  phoneNo: string;
  address: string;
  detailAddress: string;
  zipNo: string;
  postType: string;
  colorType: string;
  flexType: string;
  isStapler: boolean;
  files: File[];
}

const RegistersPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);
  const { setLayoutContainerStyles } = useLayoutContext();

  const {
    data: unionRegisterStatus,
    isLoading,
    isPending,
  } = useRegisterStatusQuery({ unionSeq });

  const registerStatus = unionRegisterStatus?.data?.status;

  const renderStep = (status?: string) => {
    switch (status) {
      case 'NONE':
      case 'CANCEL':
        return <NoneStep unionSeq={unionSeq} />;
      // 확인 중
      case 'REGISTER':
      case 'REGISTERING':
      case 'REQUESTING':
        return <RegisteringStep unionSeq={unionSeq} />;
      // 확정완료
      case 'CONFIRM':
      case 'CONVEY':
        return <ConfirmStep unionSeq={unionSeq} />;
    }
  };

  useLayoutEffect(() => {
    setLayoutContainerStyles?.({ maxWidth: 'none' });

    return () => {
      setLayoutContainerStyles?.(undefined);
    };
  }, [setLayoutContainerStyles]);

  if (isLoading || isPending) return;

  return renderStep(registerStatus);
};

const registersPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/registers',
  children: [
    { index: true, element: <RegistersPage /> },
    registerCreatePage,
    registerModifyPage,
  ],
  handle: {
    getTitle: () => '조합원 명부 관리',
    getMenuCode: () => 'M0401',
  },
};

export default registersPage;

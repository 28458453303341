import {
  Descriptions,
  DescriptionsItem,
  Stack,
  Typography,
} from '@wooriga/design-system';

import { UnionRegisterAgentInfo } from 'apis/types/union';

export interface UnionRegisterAgentDescriptionsProps {
  data?: UnionRegisterAgentInfo;
}

const UnionRegisterAgentDescriptions = (
  props: UnionRegisterAgentDescriptionsProps,
) => {
  const { data } = props;

  const unionRegisterAgentInfo = data;

  return (
    <Stack gap={1}>
      <Typography level="h3" fontSize="lg" fontWeight="xl" lineHeight="xl">
        대리인 정보
      </Typography>
      <Descriptions variant="soft" color="primary" size="lg" columns={6}>
        <DescriptionsItem label="대리인 관계" span={1}>
          {unionRegisterAgentInfo?.relationDescription}
        </DescriptionsItem>
        <DescriptionsItem label="대리인 이름" span={1}>
          {unionRegisterAgentInfo?.name?.name}
          {unionRegisterAgentInfo?.name?.nameClass}
        </DescriptionsItem>
        <DescriptionsItem label="생년월일" span={1}>
          {unionRegisterAgentInfo?.birth}
        </DescriptionsItem>
        <DescriptionsItem label="연락처" span={1}>
          {unionRegisterAgentInfo?.phoneNo}
        </DescriptionsItem>
      </Descriptions>
    </Stack>
  );
};

export default UnionRegisterAgentDescriptions;

import JoyFormHelperText from '@mui/joy/FormHelperText';
import type { FormHelperTextProps as JoyFormHelperTextProps } from '@mui/joy/FormHelperText';
import { ForwardedRef, forwardRef } from 'react';

export type FormHelperTextProps = JoyFormHelperTextProps;

const FormHelperText = (
  props: FormHelperTextProps,
  ref: ForwardedRef<HTMLParagraphElement>,
) => {
  return <JoyFormHelperText {...props} ref={ref} />;
};

export default forwardRef<HTMLParagraphElement, FormHelperTextProps>(
  FormHelperText,
);

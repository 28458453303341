import { useTheme } from '@mui/joy';
import { Stack } from '@wooriga/design-system';

import { MeetMethods } from 'apis/types/meet';
import MeetsInfoDescription from 'components/pages/common/MeetsInfoDescription';
import ElectronicDateInfo from 'components/pages/meet-management/history/detail/common/DateInfos/ElectronicDateInfo';
import OnsiteDateInfo from 'components/pages/meet-management/history/detail/common/DateInfos/OnsiteDateInfo';
import { MeetMethodProps } from 'components/pages/meet-management/history/detail/type';
import {
  ElectronicVoteSendStatusCountQuery,
  ElectronicVoteTendencyCountQuery,
  ElectronicVoteTimeSeriesQuery,
  MeetsAgendasQuery,
  MeetsAttendExpectedCountQuery,
  MeetsDetailQuery,
  MeetsWrittenSubmissionCountQuery,
  MeetsWrittenSubmissionTimeSeriesQuery,
} from 'lim/generalMeetingHistoryDetail/apis';

import ElectronicLineChart from './Charts/ElectronicLineChart';
import ElectronicPieChart from './Charts/ElectronicPieChart';
import OnsiteLineChart from './Charts/OnsiteLineChart';
import OnsitePieChart from './Charts/OnsitePieChart';

// import LineChartRenderer from './Charts/LineChartRenderer';

interface Onsite {
  meetsWrittenSubmissionCountQuery: MeetsWrittenSubmissionCountQuery;
  meetsAttendExpectedCountQuery: MeetsAttendExpectedCountQuery;
  meetsWrittenSubmissionTimeSeriesQuery: MeetsWrittenSubmissionTimeSeriesQuery;
}

interface Electronic {
  electronicVoteSendStatusCountQuery: ElectronicVoteSendStatusCountQuery;
  electronicVoteTendencyCountQuery: ElectronicVoteTendencyCountQuery;
  electronicVoteTimeSeriesQuery: ElectronicVoteTimeSeriesQuery;
}

export type VoteHomeTabProps<MeetMethod extends MeetMethods> = MeetMethodProps<
  MeetMethod,
  Electronic,
  Onsite
> & {
  meetMethod: MeetMethod;
  // ------------------------ 공통 ------------------------
  meetsDetailQuery: MeetsDetailQuery;
  meetsAgendasQuery: MeetsAgendasQuery;
};

const VoteHomeTab = <MeetMethod extends MeetMethods>({
  meetMethod,
  // ------------------------ 공통 ------------------------
  meetsDetailQuery,

  // ------------------------ 서면 ------------------------
  meetsWrittenSubmissionCountQuery,
  meetsAttendExpectedCountQuery,
  meetsWrittenSubmissionTimeSeriesQuery,
  // ------------------------ 전자 ------------------------
  electronicVoteSendStatusCountQuery,
  electronicVoteTendencyCountQuery,
  electronicVoteTimeSeriesQuery,
}: VoteHomeTabProps<MeetMethod>) => {
  const theme = useTheme();

  const commonStyle = {
    background: theme.palette.background.surface,
    padding: '12px 20px',
  };

  const isElectronic = meetMethod === 'ELECTRONIC';
  const isOnsite = meetMethod === 'ONSITE';

  return (
    <>
      <Stack gap={3}>
        <MeetsInfoDescription meetsDetailQuery={meetsDetailQuery} />

        {isElectronic && (
          <Stack gap={1.75} sx={commonStyle}>
            <ElectronicDateInfo meetsDetailQuery={meetsDetailQuery} />
          </Stack>
        )}

        {isOnsite && (
          <Stack gap={1.75} sx={commonStyle}>
            <OnsiteDateInfo meetsDetailQuery={meetsDetailQuery} />
          </Stack>
        )}

        {/* ---------------------- Pie ---------------------- */}
        {meetsDetailQuery.data && (
          <ElectronicPieChart
            totalCount={meetsDetailQuery.data.data.participantCount}
            meetMethod={meetMethod}
            electronicVoteSendStatusCountQuery={
              electronicVoteSendStatusCountQuery
            }
            electronicVoteTendencyCountQuery={electronicVoteTendencyCountQuery}
          />
        )}

        {meetsDetailQuery.data && (
          <OnsitePieChart
            totalCount={meetsDetailQuery.data.data.participantCount}
            meetMethod={meetMethod}
            meetsWrittenSubmissionCountQuery={meetsWrittenSubmissionCountQuery}
            meetsAttendExpectedCountQuery={meetsAttendExpectedCountQuery}
          />
        )}

        {/* ---------------------- Line ---------------------- */}

        {/* <LineChartRenderer
          meetMethod={meetMethod}
          electronicVoteTimeSeriesQuery={electronicVoteTimeSeriesQuery}
          meetsWrittenSubmissionTimeSeriesQuery={
            meetsWrittenSubmissionTimeSeriesQuery
          }
        />
         */}
        <ElectronicLineChart
          meetMethod={meetMethod}
          electronicVoteTimeSeriesQuery={electronicVoteTimeSeriesQuery}
        />

        <OnsiteLineChart
          meetMethod={meetMethod}
          meetsWrittenSubmissionTimeSeriesQuery={
            meetsWrittenSubmissionTimeSeriesQuery
          }
        />
      </Stack>
    </>
  );
};

export default VoteHomeTab;
//

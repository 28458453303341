import {
  AlertDialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
} from '@wooriga/design-system';
import { useState } from 'react';

import { useUnionMemberQuery } from 'apis/union/member/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { formatDate } from 'utils/format';

interface MemberInfoModalProps {
  unionSeq: number;
  unionMemberSeq: number;

  open: boolean;
  onClose: (open: false) => void;
  onConfirm: (nickname: string) => void;

  isEntrustPossilbe?: boolean;
}

const MemberInfoModal = ({
  unionSeq,
  unionMemberSeq,
  open,
  onClose,
  onConfirm,
  isEntrustPossilbe = false,
}: MemberInfoModalProps) => {
  const [alertDialog, setAlertDialog] = useState({
    show: false,
    message: '',
    detail: '',
  });

  const { getCode } = useCommonCodes();
  const { data } = useUnionMemberQuery(unionSeq, unionMemberSeq, true);

  if (!data?.data) return;

  const validatedData = () => {
    const { level, member, unionRegister, joinedAt } = data?.data || {};

    const { name, nickname, gender, birth, phoneNo } = member || {};

    const formattedJoinedAt = formatDate(joinedAt);
    const formattedGender =
      gender === 'M' ? '남자' : gender === 'F' ? '여자' : '';

    if (unionRegister) {
      const { localAddresses } = unionRegister;
      const {
        city,
        gu,
        dong,
        jibun,
        building,
        apartmentDong,
        apartmentFloor,
        apartmentHosu,
      } = localAddresses?.[0] || {};

      return [
        name,
        level && getCode('USER_LEVEL', level)?.name,
        nickname,
        phoneNo,
        birth,
        formattedGender,
        [
          city,
          gu,
          dong,
          jibun,
          building,
          apartmentDong,
          apartmentFloor,
          apartmentHosu,
        ].join(' '),
        formattedJoinedAt,
      ];
    }

    return [
      name,
      level && getCode('USER_LEVEL', level)?.name,
      nickname,
      phoneNo,
      birth,
      formattedGender,
      '',
      formattedJoinedAt,
    ];
  };

  const JSON = generateDescriptionsJSON(
    [
      '이름',
      '가입자 등급',
      '닉네임',
      '연락처',
      '생년월일',
      '성별',
      '소재지',
      '조합 가입일',
    ],
    validatedData(),
  );
  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog minWidth="600px">
          <DialogTitle>가입자 정보</DialogTitle>

          <DialogContent>
            <DescriptionsRenderer json={JSON} columns={1} />
          </DialogContent>
          <DialogActions>
            <Button
              color="neutral"
              variant="outlined"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
            {data?.data?.nickname && (
              <Button
                disabled={!isEntrustPossilbe}
                onClick={() => onConfirm(data.data!.nickname)}
              >
                조합 최고관리자 권한위임
              </Button>
            )}
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal open={alertDialog.show}>
        <AlertDialog
          message={alertDialog.message}
          detail={alertDialog.detail}
          onSubmit={() => setAlertDialog({ ...alertDialog, show: false })}
        />
      </Modal>
    </>
  );
};

export default MemberInfoModal;

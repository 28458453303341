import { Stack } from '@wooriga/design-system';
import { Outlet } from 'react-router-dom';

const ContainerLayout = () => {
  return (
    <Stack flexWrap="wrap" width="100%" alignContent="center" minHeight="100vh">
      <Outlet />
    </Stack>
  );
};

export default ContainerLayout;

import { Box, Chip, Stack, Typography } from '@wooriga/design-system';

import { MeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { formatDateTime } from 'utils/format';

interface OnsiteDateInfoProps {
  meetsDetailQuery: MeetsDetailQuery;
}

const OnsiteDateInfo = ({ meetsDetailQuery }: OnsiteDateInfoProps) => {
  const { data } = meetsDetailQuery;

  if (!data?.data.onsiteVote) return;
  const { submissionStartAt, submissionEndAt, meetAt } = data.data.onsiteVote;

  return (
    <>
      <Stack flexDirection="row" gap="40px">
        <Stack gap="10px">
          <Chip>서면 제출 시작</Chip>
          <Stack>
            <Typography level="body-md">
              {formatDateTime(submissionStartAt, 'yyyy-MM-dd (EEE)')}
            </Typography>
            <Typography level="body-md">
              {formatDateTime(submissionStartAt, 'HH시 mm분')}
            </Typography>
          </Stack>
        </Stack>
        <Box alignContent="center">{'>'}</Box>
        <Stack gap="10px">
          <Chip>서면 제출 마감</Chip>
          <Stack>
            <Typography level="body-md">
              {formatDateTime(submissionEndAt, 'yyyy-MM-dd (EEE)')}
            </Typography>
            <Typography level="body-md">
              {formatDateTime(submissionEndAt, 'HH시 mm분')}
            </Typography>
          </Stack>
        </Stack>
        <Box alignContent="center">{'>'}</Box>
        <Stack gap="10px">
          <Chip>총회일</Chip>
          <Stack>
            <Typography level="body-md">
              {formatDateTime(meetAt, 'yyyy-MM-dd (EEE)')}
            </Typography>
            <Typography level="body-md">
              {formatDateTime(meetAt, 'HH시 mm분')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default OnsiteDateInfo;

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  DataGrid,
  Grid,
  IconButton,
  Link,
  Option,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useExternalSystemCheckMutation } from 'apis/common/apis';
import {
  CreateDocumentIssuanceBody,
  DocumentIssuanceRegisterParams,
  useCreateDocumentIssuanceBalanceMutation,
  useCreateDocumentIssuanceMutation,
  useDocumentIssuanceRegisterQuery,
} from 'apis/document-issuance/apis';
import { DOCUMENT_ISSUANCE_TABS } from 'apis/document-issuance/constants';
import {
  DOCUMENT_ISSUANCE_BOTTOM_COLUMNS,
  DOCUMENT_ISSUANCE_TOP_COLUMNS,
  DocumentIssuanceSelectedUnionRegisters,
} from 'apis/document-issuance/fixtures';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import DocumentIssuanceBalanceModal from 'components/pages/document-issuances/modal/DocumentIssuanceBalanceModal';
import ViewIssuanceSettingDrawer, {
  ViewIssuanceSettingValues,
} from 'components/pages/document-issuances/ViewIssuanceSettingDrawer';
import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import useTransferRows from 'hyuk/hooks/useTransferRows';
import { useUnionRegisterGroupsQuery } from 'lim/address-group/apis';
import documentIssuanceRecordPage from 'pages/main/union-management/document-issuances/records';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const DEFAULT_SELECTED_UNION_STATE = {
  open: false,
  seq: 0,
};
const DEFAULT_SEARCH_PARAMS = {
  unionRegisterNo: '',
  name: '',
  localAddress: '',
};

const DataIssuancePage = () => {
  const params = useParams();
  const { pageContext } = useLayoutContext();

  const { unionSeq } = params;
  const { unionBasename } = pageContext || {};

  const [searchParams, setSearchParams] =
    useState<DocumentIssuanceRegisterParams>({
      unionSeq: Number(unionSeq),
      unionRegisterGroupSeq: 0,
      ...DEFAULT_SEARCH_PARAMS,
    });

  const [selectedUnionState, setSelectedUnionState] = useState(
    DEFAULT_SELECTED_UNION_STATE,
  );

  const [form, setForm] = useState<CreateDocumentIssuanceBody | null>(null);
  const [selectedUnionRegisters, setSelectedUnionRegisters] =
    useState<DocumentIssuanceSelectedUnionRegisters>([]);

  const [isBalanceOpen, setIsBalanceOpen] = useState<boolean>(false);
  const [isSettingDrawerOpen, setIsSettingDrawerOpen] =
    useState<boolean>(false);

  const { data: groups } = useUnionRegisterGroupsQuery(Number(unionSeq), {
    unionRegisterGroupName: '',
    unionRegisterName: '',
  });

  const {
    data: documentIssuances,
    isPending,
    isError,
    error,
  } = useDocumentIssuanceRegisterQuery(searchParams);

  const { mutateAsync: checkMessageSystem } = useExternalSystemCheckMutation();

  const {
    data: balanceInfo,
    mutate: calculateDocumentIssuances,
    isPending: isBalanceLoading,
  } = useCreateDocumentIssuanceBalanceMutation();
  const {
    mutate: createDocumentIssuance,
    isPending: isDocumentIssuanceLoading,
  } = useCreateDocumentIssuanceMutation();

  const { snackbar, alertDialog } = useFeedback();
  const {
    oppositeRows,
    defaultRowSelectionModel,
    oppositeRowSelectionModel,
    isRowSelectable,
    onDefaultRowSelectionModelChange,
    onOppositeRowSelectionModelChange,
    onClickDefaultRowMovement,
    onClickOppositeRowMovement,
    reset: onRowSelectionModalReset,
  } = useTransferRows({
    path: 'unionRegister.localAddresses[0].localAddressSeq',
    defaultRows: documentIssuances?.data || [],
  });

  const resetPageState = () => {
    onRowSelectionModalReset();
    setSelectedUnionRegisters([]);
    setForm(null);
  };

  const handleSearchParams = (
    values: Partial<DocumentIssuanceRegisterParams>,
  ) => {
    if (!values) {
      return;
    }

    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...values }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleClickDefaultRowMovement = () => {
    onClickDefaultRowMovement();

    const newSelectedUnionRegisters = selectedUnionRegisters.filter(
      ({ localAddressSeq }) =>
        !oppositeRowSelectionModel.includes(localAddressSeq),
    );
    setSelectedUnionRegisters(newSelectedUnionRegisters);
  };

  const handleClickOppositeRowMovement = () => {
    onClickOppositeRowMovement();

    const newSelectedUnionRegisters = (documentIssuances?.data ?? [])
      .filter(({ unionRegister }) =>
        defaultRowSelectionModel.includes(
          unionRegister.localAddresses[0].localAddressSeq,
        ),
      )
      .map(({ unionRegister }) => {
        const { unionRegisterSeq, localAddresses } = unionRegister;

        const registrationAddressSeqs =
          localAddresses[0]?.registrationAddresses?.map(
            ({ registrationAddressSeq }) => registrationAddressSeq,
          ) ?? [];

        return {
          unionRegisterSeq,
          registrationAddressSeqs,
          localAddressSeq: localAddresses[0].localAddressSeq,
        };
      });

    setSelectedUnionRegisters((prevSelected) => [
      ...prevSelected,
      ...newSelectedUnionRegisters,
    ]);
  };

  const handleChangeCellCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, localAddressSeq: number) => {
      const { checked, value } = event.target;

      const newSelectedUnionRegisters = selectedUnionRegisters.map(
        (register) => {
          if (register.localAddressSeq !== localAddressSeq) {
            return register;
          }

          const registrationAddressSeqs = checked
            ? [...register.registrationAddressSeqs, Number(value)]
            : register.registrationAddressSeqs.filter(
                (seq) => seq !== Number(value),
              );

          return {
            ...register,
            registrationAddressSeqs,
          };
        },
      );

      setSelectedUnionRegisters(newSelectedUnionRegisters);
    },
    [selectedUnionRegisters],
  );

  const handleOpenUnionInfoModal = (seq: number) => {
    setSelectedUnionState({
      open: true,
      seq,
    });
  };

  const handleOpenSettingDrawer = () => {
    const totalRealEstateSelected = selectedUnionRegisters.reduce(
      (total, { registrationAddressSeqs }) =>
        (total += registrationAddressSeqs.length),
      0,
    );

    if (!totalRealEstateSelected) {
      alertDialog('최소 1개 이상의 부동산 구분 선택이 필요합니다.', {
        message: '선택 필요',
      });
      return;
    }

    setIsSettingDrawerOpen(true);
  };

  const handleCheckMessageSystem = async () => {
    await checkMessageSystem(
      { externalSystemType: 'DATA_ORDER' },
      {
        onError: () => {
          return false;
        },
      },
    );

    return true;
  };

  const handleSubmitDocumentIssuanceSetting = async (
    data: ViewIssuanceSettingValues,
  ) => {
    const isSystemAvailable = await handleCheckMessageSystem();

    if (!isSystemAvailable) {
      return;
    }

    const unionRegisters = selectedUnionRegisters.map(
      ({ unionRegisterSeq, registrationAddressSeqs }) => ({
        unionRegisterSeq,
        registrationAddressSeqs,
      }),
    );

    const form = {
      ...data,
      unionRegisters,
      totalRequestCount: 0,
      addressCount: 0,
    };

    calculateDocumentIssuances(
      { unionSeq: Number(unionSeq), ...form },
      {
        onSuccess: () => {
          setForm(form);
          setIsBalanceOpen(true);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleSubmitDocumentIssuance = (data: {
    totalAddress: number;
    totalCount: number;
  }) => {
    if (!form) {
      return;
    }

    createDocumentIssuance(
      {
        unionSeq: Number(unionSeq),
        ...form,
        addressCount: data.totalAddress,
        totalRequestCount: data.totalCount,
      },
      {
        onSuccess: () => {
          snackbar('자료 발급 요청이 완료되었습니다.', {
            color: 'success',
          });
          setIsBalanceOpen(false);
          resetPageState();
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const isRowSelected = useMemo(() => oppositeRows.length > 0, [oppositeRows]);

  const { columns: topColumns } = useCreateGridColumns({
    columns: DOCUMENT_ISSUANCE_TOP_COLUMNS,
    handlers: { onClickUnionMember: handleOpenUnionInfoModal },
  });

  const { columns: bottomColumns } = useCreateGridColumns({
    columns: DOCUMENT_ISSUANCE_BOTTOM_COLUMNS,
    handlers: {
      unionRegisters: selectedUnionRegisters,
      onClickUnionMember: handleOpenUnionInfoModal,
      onChangeCellCheckbox: handleChangeCellCheckbox,
    },
  });

  useEffect(() => {
    if (groups)
      setSearchParams((prevState) => ({
        ...prevState,
        unionRegisterGroupSeq: groups.data[0].unionRegisterGroupSeq,
      }));
  }, [groups]);

  if (isError) {
    throw error;
  }

  return (
    <>
      <Tabs
        color="neutral"
        selectedTabVariant="plain"
        selectedTabColor="primary"
        tabIndicatorInset
        defaultValue={0}
        value={0}
      >
        <TabList disableUnderline>
          {DOCUMENT_ISSUANCE_TABS.map(({ label, path }, index) => (
            <Tab
              key={`tab_${path}`}
              component={Link}
              value={index}
              href={`${unionBasename}${path}`}
            >
              {label}
            </Tab>
          ))}
        </TabList>

        <TabPanel value={0}>
          <Stack gap={2}>
            <Stack gap={1.75}>
              <Stack gap={1}>
                <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                  조합원 명부
                </Typography>

                <Select
                  // Object 타입으로 설정하기 힘든 구조로 추후 확인 필요
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={searchParams.unionRegisterGroupSeq}
                  onChange={(_, value) =>
                    // Object 타입으로 설정하기 힘든 구조로 추후 확인 필요
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSearchParams((prevState) => ({
                      ...prevState,
                      unionRegisterGroupSeq: value,
                    }))
                  }
                  sx={{ width: '432px' }}
                >
                  {groups?.data?.map(({ name, unionRegisterGroupSeq }) => (
                    <Option
                      key={`union_group_${unionRegisterGroupSeq}`}
                      value={unionRegisterGroupSeq}
                    >
                      {name}
                    </Option>
                  ))}
                </Select>
              </Stack>

              <Search
                defaultValues={DEFAULT_SEARCH_PARAMS}
                onSubmit={handleSearchParams}
                onReset={handleSearchReset}
              >
                <Grid container gap={2}>
                  <Grid xs={12} maxWidth={200}>
                    <Search.Field>
                      <TextField
                        label="연번"
                        name="unionRegisterNo"
                        placeholder='숫자 또는 "-"입력'
                        validateOptions={{
                          maxLength: 11,
                          regex: /^(?!.*--)[0-9-]*$/,
                        }}
                        fullWidth
                      />
                    </Search.Field>
                  </Grid>

                  <Grid xs={12} maxWidth={200}>
                    <Search.Field>
                      <TextField
                        label="조합원 이름"
                        name="name"
                        placeholder="조합원 이름 입력"
                        slotProps={{
                          input: { maxLength: 30 },
                        }}
                        fullWidth
                      />
                    </Search.Field>
                  </Grid>

                  <Grid xs={12} maxWidth={416}>
                    <Search.Field>
                      <TextField
                        label="소재지"
                        name="localAddress"
                        placeholder="주소 입력"
                        fullWidth
                      />
                    </Search.Field>
                  </Grid>
                </Grid>
              </Search>

              <Stack flexDirection="row" gap={1}>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  전체{' '}
                  <Typography color="primary">
                    {commaizeNumber(
                      documentIssuances?.pagination.totalDataCount || 0,
                    )}
                  </Typography>
                </Typography>

                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  조회 목록{' '}
                  <Typography color="primary">
                    {commaizeNumber(
                      documentIssuances?.pagination.totalElements || 0,
                    )}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={2}>
              <Stack height={442}>
                <DataGrid
                  rows={documentIssuances?.data || []}
                  columns={topColumns}
                  loading={isPending}
                  getRowId={(row) =>
                    row.unionRegister.localAddresses[0].localAddressSeq
                  }
                  rowSelectionModel={defaultRowSelectionModel}
                  onRowSelectionModelChange={onDefaultRowSelectionModelChange}
                  isRowSelectable={isRowSelectable}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Stack>

              <Stack direction="row" justifyContent="center" gap={1}>
                <IconButton
                  variant="outlined"
                  onClick={handleClickOppositeRowMovement}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
                <IconButton
                  variant="outlined"
                  onClick={handleClickDefaultRowMovement}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              </Stack>

              <Stack gap={1.75}>
                <Stack gap={1}>
                  <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                    선택 목록
                  </Typography>

                  <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                    전체{' '}
                    <Typography color="primary">
                      {commaizeNumber(oppositeRows.length)}
                    </Typography>
                  </Typography>
                </Stack>

                <Stack height={442}>
                  <DataGrid
                    rows={oppositeRows}
                    columns={bottomColumns}
                    getRowId={(row) =>
                      row.unionRegister.localAddresses[0].localAddressSeq
                    }
                    rowSelectionModel={oppositeRowSelectionModel}
                    onRowSelectionModelChange={
                      onOppositeRowSelectionModelChange
                    }
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent="flex-end">
                <Button
                  disabled={!isRowSelected}
                  onClick={handleOpenSettingDrawer}
                >
                  선택완료
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </TabPanel>
      </Tabs>

      {selectedUnionState.open && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq: Number(unionSeq),
            unionRegisterSeq: selectedUnionState.seq,
          }}
          open={selectedUnionState.open}
          onClose={() => setSelectedUnionState(DEFAULT_SELECTED_UNION_STATE)}
        />
      )}

      <ViewIssuanceSettingDrawer
        anchor="right"
        open={isSettingDrawerOpen}
        onClose={() => setIsSettingDrawerOpen(false)}
        onSubmit={handleSubmitDocumentIssuanceSetting}
      />

      {isBalanceOpen && (
        <DocumentIssuanceBalanceModal
          data={{
            items: balanceInfo?.data?.items ?? [],
            unionRegisters: selectedUnionRegisters ?? [],
          }}
          isLoading={isBalanceLoading}
          isSubmitLoading={isDocumentIssuanceLoading}
          open={isBalanceOpen}
          onClose={() => setIsBalanceOpen(false)}
          onSubmit={handleSubmitDocumentIssuance}
        />
      )}
    </>
  );
};

const documentIssuancesPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/document-issuances',
  children: [
    {
      index: true,
      element: <DataIssuancePage />,
    },
    documentIssuanceRecordPage,
  ],
  handle: {
    getTitle: () => '자료 발급',
    getMenuCode: () => 'M0407',
  },
};

export default documentIssuancesPage;

import { useTheme } from '@mui/joy';
import { LineChart, Stack, Typography } from '@wooriga/design-system';
import { useMemo } from 'react';

import { MeetMethods } from 'apis/types/meet';
import { MeetsWrittenSubmissionTimeSeriesQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { formatDateTime } from 'utils/format';

interface OnsiteLineChartProps {
  meetMethod: MeetMethods;
  meetsWrittenSubmissionTimeSeriesQuery?: MeetsWrittenSubmissionTimeSeriesQuery;
}

const OnsiteLineChart = ({
  meetMethod,
  meetsWrittenSubmissionTimeSeriesQuery,
}: OnsiteLineChartProps) => {
  const theme = useTheme();

  const timeSeriesData = meetsWrittenSubmissionTimeSeriesQuery?.data;

  const accumulativeSubmissionChart = useMemo(() => {
    if (!timeSeriesData)
      return {
        xAxis: [],
        series: [],
      };
    const { accumulatedWrittenSubmission } = timeSeriesData.data;

    const accXAxis =
      accumulatedWrittenSubmission?.map((data) =>
        formatDateTime(new Date(data.date), 'MM.dd'),
      ) || [];
    const accSeries =
      accumulatedWrittenSubmission?.map((data) => data.value) || [];
    const accumulativeSubmission = {
      xAxis: [{ scaleType: 'point' as const, data: accXAxis }],
      series: [{ data: accSeries }],
    };

    return accumulativeSubmission;
  }, [timeSeriesData?.data]);

  const submissionChart = useMemo(() => {
    if (!timeSeriesData)
      return {
        xAxis: [],
        series: [],
      };
    const { writtenSubmission } = timeSeriesData.data;

    const submissionXAxis =
      writtenSubmission?.map((data) =>
        formatDateTime(new Date(data.date), 'MM.dd'),
      ) || [];
    const SubmissionSeries = writtenSubmission?.map((data) => data.value) || [];
    const submission = {
      xAxis: [{ scaleType: 'point' as const, data: submissionXAxis }],
      series: [{ data: SubmissionSeries }],
    };

    return submission;
  }, [timeSeriesData?.data]);

  const withdrawalChart = useMemo(() => {
    if (!timeSeriesData)
      return {
        xAxis: [],
        series: [],
      };
    const { writtenWithdrawal } = timeSeriesData.data;

    const withdrawalXAxis =
      writtenWithdrawal?.map((data) =>
        formatDateTime(new Date(data.date), 'MM.dd'),
      ) || [];
    const withdrawalSeries = writtenWithdrawal?.map((data) => data.value) || [];
    const withdrawal = {
      xAxis: [{ scaleType: 'point' as const, data: withdrawalXAxis }],
      series: [{ data: withdrawalSeries }],
    };

    return withdrawal;
  }, [timeSeriesData?.data]);

  const commonStyle = {
    background: theme.palette.background.surface,
    padding: '12px 20px',
  };

  if (!meetMethod.includes('ONSITE')) return <></>;

  return (
    <Stack flexWrap="wrap" flexDirection="row" gap={1.75}>
      <Stack gap={1} sx={commonStyle} width="100%">
        <Typography level="body-md">날짜별 누적 서면 제출 수</Typography>
        <LineChart
          series={accumulativeSubmissionChart.series}
          xAxis={accumulativeSubmissionChart.xAxis}
          height={400}
        />
      </Stack>
      <Stack gap={1} sx={commonStyle} width="100%">
        <Typography level="body-md">날짜별 서면 제출수</Typography>
        <LineChart
          series={submissionChart.series}
          xAxis={submissionChart.xAxis}
          height={400}
        />
      </Stack>
      <Stack gap={1} sx={commonStyle} width="100%">
        <Typography level="body-md">날짜별 서면 철회수</Typography>
        <LineChart
          series={withdrawalChart.series}
          xAxis={withdrawalChart.xAxis}
          height={400}
        />
      </Stack>
    </Stack>
  );
};

export default OnsiteLineChart;

import { useMutation, UseMutationResult } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { Token } from 'apis/types/auth';
import { MemberInfo } from 'apis/types/member';
import { ApiError, ApiResponseData } from 'types/api';

export interface LoginParams {
  id: string;
  password: string;
}

export interface LoginReturnData {
  accessToken: Token;
  member: MemberInfo;
}

type UseLoginMutation = UseMutationResult<
  ApiResponseData<LoginReturnData>,
  ApiError,
  LoginParams
>;

export const useLoginMutation = (): UseLoginMutation =>
  useMutation({
    mutationFn: async (params) => {
      const { data } = await axiosInstance.post('/login', params);
      return data;
    },
    onSuccess: () => {},
  });

type UseLogoutMutation = UseMutationResult<ApiResponseData, ApiError, null>;

export const useLogoutMutation = (): UseLogoutMutation =>
  useMutation({
    mutationFn: async () => {
      const { data } = await axiosInstance.post('/logout');
      return data;
    },
  });

import JoyGrid from '@mui/joy/Grid';
import type { GridProps as JoyGridProps } from '@mui/joy/Grid';
import { ForwardedRef, forwardRef } from 'react';

export type GridProps = JoyGridProps;

const Grid = (props: GridProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyGrid {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, GridProps>(Grid);

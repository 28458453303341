import { Button, Input, Link, Option, Select } from '@wooriga/design-system';

import CommonCode from 'components/CommonCode';
import {
  OnInputChange,
  OnLinkClick,
  OnRowSelect,
  TypeGuardCol,
} from 'lim/_fixtures/type';
import {
  Participant,
  ShareType,
  VoteResultCandidate,
  VoteResultCount,
} from 'lim/generalMeetingHistoryDetail/apis';
import { PromotionUserResponse } from 'lim/type';
import {
  formatBirth,
  formatDate,
  formatDateTime,
  formatTelephone,
} from 'utils/format';

export type ElectronicVoteManagementColumns = Omit<
  Participant,
  'electronicVoteStatus'
> &
  Participant['electronicVoteStatus'] & {
    promotionUserSeq: PromotionUserResponse['promotionUserSeq'];
  };

export const ELECTRONIC_VOTE_MANAGEMENT_COLUMNS = (
  onNameClick: OnLinkClick,
  onCountClick: OnLinkClick,
  onPromotionUserClick: (id: number) => void,
): TypeGuardCol<ElectronicVoteManagementColumns>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
  },
  {
    field: 'name',
    headerName: '이름',
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onNameClick(params.row.unionRegisterSeq)}
        >
          {params.value}
        </Link>
      );
    },
  },
  {
    field: 'birth',
    headerName: '생년월일',
    valueGetter: (_, value) => formatBirth(value.birth),
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    valueGetter: (_, value) => formatTelephone(value.phoneNo),
  },
  {
    field: 'tendency',
    headerName: '우호도',
    renderCell: (params) => (
      <CommonCode groupCode="TENDENCY_TYPE" code={params.value} />
    ),
  },
  {
    field: 'progressStatus',
    headerName: '진행상태',
    renderCell: (params) => (
      <CommonCode
        groupCode="MEET_PARTICIPANT_VOTE_STATUS"
        code={params.value}
      />
    ),
  },
  {
    field: 'voteStatus',
    headerName: '전자투표 상태',
    renderCell: (params) => (
      <CommonCode groupCode="VOTING_STATUS_TYPE" code={params.value} />
    ),
  },
  {
    field: 'receivedAt',
    headerName: '수신일시',
    valueGetter: (_, value) =>
      formatDateTime(value.receivedAt, 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    field: 'openedAt',
    headerName: '열람일시',

    valueGetter: (_, value) =>
      formatDateTime(value.openedAt, 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    field: 'votedAt',
    headerName: '투표일시',
    width: 160,
    valueGetter: (_, value) =>
      formatDateTime(value.votedAt, 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    field: 'resendCount',
    headerName: '발송',
    width: 150,
    renderCell: (params) => {
      return (
        <Button variant="plain" onClick={() => onCountClick(params.id)}>
          {params.value}
        </Button>
      );
    },
  },
  {
    field: 'promotionUser',
    headerName: '담당자',
    width: 150,
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onPromotionUserClick(params.row.promotionUserSeq)}
        >
          {params.value}
        </Link>
      );
    },
  },
];

export type OnsiteVoteManagementColumns = Omit<
  Participant,
  'onsiteVoteStatus'
> &
  Participant['onsiteVoteStatus'] & {
    promotionUserSeq: PromotionUserResponse['promotionUserSeq'];
  };

export const ONSITE_VOTE_MANAGEMENT_COLUMNS = (
  onNameClick: OnLinkClick,
  onPromotionUserClick: (id: number) => void,
): TypeGuardCol<OnsiteVoteManagementColumns>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
  },
  {
    field: 'name',
    headerName: '이름',

    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onNameClick(params.row.unionRegisterSeq)}
        >
          {params.value}
        </Link>
      );
    },
  },
  {
    field: 'birth',
    headerName: '생년월일',
    valueGetter: (_, value) => formatBirth(value.birth),
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    valueGetter: (_, value) => formatTelephone(value.phoneNo),
  },
  {
    field: 'tendency',
    headerName: '우호도',

    renderCell: (params) => (
      <CommonCode groupCode="TENDENCY_TYPE" code={params.value} />
    ),
  },
  {
    field: 'writtenSubmissionType',
    headerName: '제출현황',

    renderCell: (params) => (
      <CommonCode groupCode="WRITTEN_SUBMIT_TYPE" code={params.value} />
    ),
  },
  {
    field: 'writtenSubmissionStatus',
    headerName: '서면투표 상태',
    renderCell: (params) => (
      <CommonCode groupCode="VOTING_WRITTEN_TYPE" code={params.value} />
    ),
  },
  {
    field: 'writtenSubmissionAt',
    headerName: '서면 제출일',
    valueGetter: (_, value) => formatDate(value.writtenSubmissionAt),
  },
  {
    field: 'writtenWithdrawalAt',
    headerName: '철회일',
    valueGetter: (_, value) => formatDate(value.writtenWithdrawalAt),
  },

  {
    field: 'attendExpectedType',
    headerName: '총회참석예정',

    renderCell: (params) => (
      <CommonCode groupCode="ATTEND_EXPECTED_TYPE" code={params.value} />
    ),
  },
  {
    field: 'promotionUser',
    headerName: '담당자',
    width: 150,
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onPromotionUserClick(params.row.promotionUserSeq)}
        >
          {params.value}
        </Link>
      );
    },
  },
];

interface SendingHistoryColumnProps {
  progressStatus: string;
  sentAt: string;
  receivedAt: string;
  openedAt: string;
  votedAt: string;
}

export const SENDING_HISTORY_COLUMNS: TypeGuardCol<SendingHistoryColumnProps>[] =
  [
    {
      field: 'progressStatus',
      headerName: '진행상태',
      renderCell: (params) => (
        <CommonCode
          groupCode="MEET_PARTICIPANT_VOTE_STATUS"
          code={params.value}
        />
      ),
    },
    {
      field: 'sentAt',
      headerName: '발송일시',
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'receivedAt',
      headerName: '수신일시',
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'openedAt',
      headerName: '열람일시',
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'votedAt',
      headerName: '투표일시',
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
  ];

export type VoterManagementTableColumns = {
  id: number;
  unionRegisterNo: string;
  unionRegisterSeq?: number;
  position: string;
  agent: boolean;
  shareType: ShareType;
  name: string;
  gender: string;
  birth: string;
  phoneNo: string;
  progressStatus: string;
  realAddress: string;
  postAddress: string;
  isDisabled: boolean;
};

export const VOTER_MANAGEMENT_TABLE_COLUMNS: TypeGuardCol<
  VoterManagementTableColumns & {
    edit: '';
  }
>[] = [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  }, //
  {
    field: 'shareType',
    headerName: '소유구분',
    renderCell: (params) => (
      <CommonCode groupCode="UNION_REGISTER_SHARE_TYPE" code={params.value} />
    ),
  },
  {
    field: 'position',
    headerName: '직책',
    renderCell: (params) => (
      <CommonCode groupCode="UNION_REGISTER_POSITION" code={params.value} />
    ),
  },
  { field: 'name', headerName: '이름' }, // name?.name
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
  },
  {
    field: 'birth',
    headerName: '생년월일',
    valueGetter: (value) => formatBirth(value),
  }, //
  {
    field: 'gender',
    headerName: '성별',
    valueGetter: (value: string) =>
      value === 'M' ? '남자' : value === 'F' ? '여자' : '',
  }, //

  {
    field: 'phoneNo',
    headerName: '연락처',
    valueGetter: (value) => formatTelephone(value),
  }, //
  {
    field: 'realAddress',
    headerName: '실제 거주지',
  },
  {
    field: 'postAddress',
    headerName: '우편물 수령지',
  },
  {
    field: 'progressStatus',
    headerName: '진행상태',
    renderCell: (params) => (
      <CommonCode
        groupCode="MEET_PARTICIPANT_VOTE_STATUS"
        code={params.value}
      />
    ),
  }, // sendStatus

  {
    field: 'edit',
    headerName: '수정',
    sortable: false,
    renderCell: (params) => {
      params.row.progressStatus === '';
      return (
        <Button
          disabled={params.row.isDisabled}
          component={Link}
          href={`${params.id}`}
        >
          수정
        </Button>
      );
    },
  },
];

type VoteResultProps = {
  agenda: React.ReactNode | string;
  order: string;
};

type CandidateProps = Pick<VoteResultCandidate, 'isPassed'> & {
  candidate: string;
  no: string;
};

export type MeetsVoteResultTableColumnsProps = VoteResultProps &
  CandidateProps &
  VoteResultCount & {
    id: string;
    voteRate?: string;
    method?: string;
    isChoiceVote?: boolean;
  };

export type ResultState = {
  id: string;
  value: boolean | null;
}[];

export const MEETS_VOTE_RESULT_TABLE_COLUMNS = (
  resultState: ResultState,
  onSelect: OnRowSelect,
): TypeGuardCol<MeetsVoteResultTableColumnsProps>[] => [
  {
    field: 'order',
    headerName: '의안',
  },
  { field: 'no', headerName: '기호' },
  {
    field: 'agenda',
    headerName: '안건',
    valueGetter: (value, row) => (row.method === '총합' ? value : ''),
  },
  {
    field: 'candidate',
    headerName: '후보명',
    valueGetter: (value, row) => (row.method === '총합' ? value : ''),
  },
  { field: 'method', headerName: '투표 종류' }, // name + description

  { field: 'yesCount', headerName: '찬성/선택' },
  {
    field: 'noCount',
    headerName: '반대',
    valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
  },
  {
    field: 'abstentionCount',
    headerName: '기권/무효',
    valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
  },
  {
    field: 'totalCount',
    headerName: '합계',
    valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
  },
  {
    field: 'voteRate',
    headerName: '득표율',
    valueGetter: (value, row) => (row.method === '총합' ? value : ''),
  },

  {
    field: 'isPassed',
    headerName: '의결결과',
    sortable: false,
    renderCell: (params) => {
      if (params.rowNode.type !== 'group') {
        if (params.row.method === '총합') {
          const state = resultState.find((state) => state.id === params.id);

          return (
            <Select
              defaultValue={params.value}
              value={state?.value}
              onChange={(_, value) => {
                onSelect(params.id, value);
              }}
            >
              <Option value={null}>선택</Option>
              <Option value={true}>가결</Option>
              <Option value={false}>부결</Option>
            </Select>
          );
        }
        return <></>;
      }
    },
  },
];

export const MEETS_VOTE_RESULT_COMPLETE_TABLE_COLUMNS =
  (): TypeGuardCol<MeetsVoteResultTableColumnsProps>[] => [
    {
      field: 'order',
      headerName: '의안',
    },
    { field: 'no', headerName: '기호' },
    {
      field: 'agenda',
      headerName: '안건',
      width: 150,
      valueGetter: (value, row) => (row.method === '총합' ? value : ''),
    },
    {
      field: 'candidate',
      headerName: '후보명',
      width: 150,
      valueGetter: (value, row) => (row.method === '총합' ? value : ''),
    },
    { field: 'method', headerName: '투표 종류' }, // name + description

    { field: 'yesCount', headerName: '찬성/선택' },
    {
      field: 'noCount',
      headerName: '반대',
      valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
    },
    {
      field: 'abstentionCount',
      headerName: '기권/무효',
      valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
    },
    {
      field: 'totalCount',
      headerName: '합계',
      valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
    },
    {
      field: 'voteRate',
      headerName: '득표율',
      valueGetter: (value, row) => (row.method === '총합' ? value : ''),
    },
    {
      field: 'isPassed',
      headerName: '의결결과',
      valueGetter: (value, row) => {
        if (row.method === '총합' ? value : '') {
          return value ? '가결' : '부결';
        }
        return '';
      },
    },
  ];

export type MeetsAgendasTableColumnsProps = {
  order: string;
  agenda: string;
  candidate: string;
  no: number;
  yesCount: number;
  noCount: number;
  abstentionCount: number;
  totalCount: number;
  isChoiceVote?: boolean;
};

export const MEETS_AGENDAS_ElECTRONIC_TABLE_COLUMNS: TypeGuardCol<MeetsAgendasTableColumnsProps>[] =
  [
    { field: 'order', headerName: '의안' },
    { field: 'agenda', headerName: '안건' }, // name + description
    { field: 'no', headerName: '기호' },
    { field: 'candidate', headerName: '후보명' },
    {
      field: 'yesCount',
      headerName: "'찬성/선택'",
    },
    {
      field: 'noCount',
      headerName: '반대',
      valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
    },
    {
      field: 'abstentionCount',
      headerName: "'기권/무효'",
      valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
    },
    {
      field: 'totalCount',
      headerName: '합계',
      valueGetter: (value, row) => (row.isChoiceVote ? '' : value),
    },
  ];

export const MEETS_AGENDAS_TABLE_COLUMNS = <
  Count,
  Values extends Record<string, Record<string, number>>,
>(
  count: Count,
  values: Values,
  onInputChange: OnInputChange,
): TypeGuardCol<MeetsAgendasTableColumnsProps>[] => [
  { field: 'order', headerName: '의안' },
  { field: 'agenda', headerName: '안건' }, // name + description
  { field: 'no', headerName: '기호', width: 100 },
  { field: 'candidate', headerName: '후보명' },
  {
    field: 'yesCount',
    headerName: "'찬성/선택'",
    width: 150,
    renderCell: (params) => {
      if (params.rowNode.type !== 'group') {
        const value = Number(values[params.id]?.[params.field]);

        return (
          <Input
            validateOptions={{
              regex: /^[0-9]*$/,
            }}
            name={params.field}
            defaultValue={0}
            value={value}
            onChange={(e) => onInputChange(params.id, e)}
          />
        );
      }
    },
  },
  {
    field: 'noCount',
    headerName: '반대',
    width: 100,
    renderCell: (params) => {
      if (params.row.isChoiceVote) return <></>;
      if (params.rowNode.type !== 'group') {
        const value = Number(values[params.id]?.[params.field]);

        return (
          <Input
            validateOptions={{
              regex: /^[0-9]*$/,
            }}
            name={params.field}
            defaultValue={0}
            value={value}
            onChange={(e) => onInputChange(params.id, e)}
          />
        );
      }
    },
  },
  {
    field: 'abstentionCount',
    headerName: "'기권/무효'",
    width: 150,
    renderCell: (params) => {
      if (params.row.isChoiceVote) return <></>;
      if (params.rowNode.type !== 'group') {
        const value = Number(values[params.id]?.[params.field]);

        return (
          <Input
            validateOptions={{
              regex: /^[0-9]*$/,
            }}
            name={params.field}
            defaultValue={0}
            value={value}
            onChange={(e) => onInputChange(params.id, e)}
          />
        );
      }
    },
  },
  {
    field: 'totalCount',
    headerName: '합계',
    width: 100,
    valueGetter: (_, row) => (row.isChoiceVote ? '' : count),
    renderCell: (params) => {
      if (params.rowNode.type === 'group') return <></>;
    },
  },
];

export type MeetsParticipantHistoriesTableColumnProps = {
  unionRegisterNo: string;
  position: string;
  agent: string;
  shareType: string;
  name: string;
  gender: string;
  birth: string;
  modifiedAt: string;
  modifiedBy: string;
  actionType: string;
};

export const PARTICIPANT_HISTORIES_TABLE_COLUMNS = (
  onLinkClick: OnLinkClick,
): TypeGuardCol<
  MeetsParticipantHistoriesTableColumnProps & { detail: string }
>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  { field: 'position', headerName: '직책' },
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
  },
  {
    field: 'shareType',
    headerName: '소유구분',
    renderCell: (params) => (
      <CommonCode groupCode="UNION_REGISTER_SHARE_TYPE" code={params.value} />
    ),
  },
  { field: 'name', headerName: '이름' },
  {
    field: 'gender',
    headerName: '성별',
    width: 50,
    valueGetter: (value: string) =>
      value === 'M' ? '남자' : value === 'F' ? '여자' : '',
  },
  {
    field: 'birth',
    headerName: '생년월일',
    valueGetter: (value) => formatBirth(value),
  },
  {
    field: 'modifiedAt',
    headerName: '변동일시',
    valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
  },
  { field: 'modifiedBy', headerName: '작업자' },
  {
    field: 'actionType',
    headerName: '기능',
    renderCell: (params) => (
      <CommonCode
        groupCode="MEET_PARTICIPANT_ACTION_TYPE"
        code={params.value}
      />
    ),
  },
  {
    field: 'detail',
    headerName: '내역',
    sortable: false,
    width: 160,
    minWidth: 160,
    renderCell: (params) => {
      return (
        <Button
          size="md"
          variant="outlined"
          color="primary"
          onClick={() => onLinkClick(params.id)}
        >
          상세보기
        </Button>
      );
    },
  },
];

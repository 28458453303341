import InfoIcon from '@mui/icons-material/Info';
import styled from '@mui/joy/styles/styled';
import {
  Stack,
  Typography,
  DataGrid,
  Callout,
  Link,
  Button,
  Select,
  Option,
  useGridUtils,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  UNION_REGISTER_TABLE_COLUMNS,
  UnionRegistersTableProps,
} from 'apis/meet/fixtures';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import {
  OpenType,
  useUnionRegistersQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import { commaizeNumber } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

import { isMissed } from '../utils';

interface MeetTypeTabProps {
  unionSeq: number;
  openType: OpenType;
}

const MeetTypeTab = ({ unionSeq, openType }: MeetTypeTabProps) => {
  const navigate = useNavigate();
  const { pageContext } = useLayoutContext();

  const { unionBasename } = pageContext || {};

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);

  const [clickedUnionId, setClickedUnionId] = useState(0);
  const [status, setStatus] = useState('all');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { data, isLoading, isError, error } = useUnionRegistersQuery(unionSeq);

  const { snackbar } = useFeedback();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'meet-open-tab',
    activeSaveSnapshot: false,
  });

  const handleOnNameClick = useCallback((id: string | number) => {
    setShowUnionInfoModal(true);
    setClickedUnionId(Number(id));
  }, []);

  const handleOnSelect = (value: string) => {
    setStatus(value);
  };

  const handleOnRowSelect = <Model,>(ids: Model) => {
    setSelectedIds(ids as number[]);
  };

  const handleOnSubmit = () => {
    if (!selectedIds.length) {
      return snackbar('선거인을 선택하세요', {
        color: 'danger',
      });
    }

    navigate('agenda', {
      state: {
        ids: selectedIds,
        openType,
      },
    });
  };

  const originRows: (UnionRegistersTableProps & { isMissed: boolean })[] =
    useMemo(() => {
      return (
        data?.data?.map((data) => {
          const {
            unionRegisterSeq,
            unionRegisterNo,
            positionDescription,
            agent,
            shareType,
            name,
            gender,
            birth,
            mainPhone,
            hasVotingRight,
            // localAddresses,
            isRepresentative,
          } = data;

          return {
            id: unionRegisterSeq,
            unionRegisterNo,
            positionDescription,
            agent: agent?.name?.name,
            shareType,
            name: name?.name,
            gender: gender || '',
            birth,
            phoneNo: mainPhone?.phoneNo,
            hasVotingRight,
            isRepresentative,
            isMissed: isMissed(data),
            // localAddresses: localAddresses[0].zoneRoad,
          };
        }) || []
      );
    }, [data?.data]);

  const rows: UnionRegistersTableProps[] = useMemo(() => {
    return originRows
      .filter((data) => {
        switch (openType) {
          case 'GENERAL':
            return true;
          case 'DELEGATE':
            return data.positionDescription === '대의원';

          case 'BOARD':
            return data.positionDescription === '이사';
        }
      })
      .filter((data) => {
        if (status === 'all') return true;
        if (status === 'SHARE') return data.shareType === status;

        if (status === 'isMissed') {
          return data.isMissed;
        }
        return true;
      });
  }, [openType, originRows, status]);

  const columnsHanlders = useMemo(
    () => ({
      onLinkClick: handleOnNameClick,
    }),
    [handleOnNameClick],
  );

  const { columns } = useCreateGridColumns({
    handlers: columnsHanlders,
    columns: UNION_REGISTER_TABLE_COLUMNS,
  });

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack marginBottom={3}>
        <Callout
          color="warning"
          variant="outlined"
          startDecorator={<InfoIcon />}
        >
          <Typography>
            빨간색으로 표시 항목은 누락된 정보가 있는 조합원입니다. 다시 한번
            확인해 주세요.
          </Typography>
          <Typography color="danger">
            이름, 성별, 연락처, 생년월일 정보가 누락 또는 오입력된 경우
            전자투표의 수신 또는 열람이 불가합니다.{' '}
            <Link href={`${unionBasename}/union-management/registers`}>
              조합원 명부 관리
            </Link>{' '}
            의 조합원정보에서 수정해 주세요.
          </Typography>
          <Typography>
            총회 개설 시 전자투표는 대리인에게 발송되며, 법인의 경우 대리인을
            선임하지 않으면 본인인증이 불가하여 전자투표 진행이 어려울 수
            있습니다.
          </Typography>
          <br />
          <Typography>
            총회 개설 전{' '}
            <Link href={`${unionBasename}/union-management/union-infos`}>
              조합 정보 관리
            </Link>{' '}
            에서 조합 사무실 정보를 등록해 주세요.
          </Typography>
          <Typography>
            조합 사무실 정보의{' '}
            <Typography component="span" color="danger">
              사무실 연락처
            </Typography>
            는 알림 문자 내{' '}
            <Typography component="span" color="danger">
              문의 전화번호
            </Typography>
            로 사용됩니다.
          </Typography>
        </Callout>
      </Stack>
      <Stack gap={1.75}>
        <Typography level="title-lg">선거인 명부 선택</Typography>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="end"
          gap={1}
        >
          <Stack direction="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(data?.pagination?.totalDataCount || 0)}
              </Typography>
            </Typography>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택 선거인{' '}
              <Typography color="primary">
                {commaizeNumber(selectedIds.length)}
              </Typography>
            </Typography>
          </Stack>

          <Select
            defaultValue="all"
            onChange={(_, value) => handleOnSelect(value as string)}
            startDecorator={<Typography level="body-md">상태 :</Typography>}
          >
            <Option value="all">전체</Option>
            <Option value="isMissed">정보누락</Option>
            <Option value="SHARE">공유자</Option>
          </Select>
        </Stack>
      </Stack>

      <Stack height={442} gap={2}>
        <StyledDataGrid
          apiRef={datagridApiRef}
          rows={rows}
          columns={columns}
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          getRowClassName={(params) => params.row.isMissed && `theme--isMissed`}
          onRowSelectionModelChange={handleOnRowSelect}
        />
      </Stack>

      <Stack flexDirection="row" alignSelf="end" gap={1}>
        <Button
          color="neutral"
          variant="outlined"
          onClick={() => exportExcel()}
        >
          내려받기
        </Button>
        <Button onClick={handleOnSubmit}>
          선거인 명부 확정 및 총회 정보 입력
        </Button>
      </Stack>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: clickedUnionId }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </Stack>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .theme--isMissed': {
    backgroundColor: theme.palette.danger[100],
    '&:hover': {
      backgroundColor: theme.palette.danger[200],
    },
  },
}));

export default MeetTypeTab;

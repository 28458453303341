import { FormControlOwnerState, styled, VariantProp } from '@mui/joy';
import useControlled from '@mui/utils/useControlled';
import {
  ChangeEvent,
  Children,
  cloneElement,
  ForwardedRef,
  forwardRef,
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';

import List from 'design-system/components/dataDisplay/list/List';
import ListItem from 'design-system/components/dataDisplay/list/ListItem';
import Typography from 'design-system/components/dataDisplay/Typography';
import Checkbox, {
  CheckboxProps,
} from 'design-system/components/inputs/checkbox/Checkbox';
import CheckboxGroupContext from 'design-system/components/inputs/checkbox/CheckboxGroupContext';
import { FormControlProps } from 'design-system/components/inputs/FormControl';
import { FormHelperTextProps } from 'design-system/components/inputs/FormHelperText';
import { FormLabelProps } from 'design-system/components/inputs/FormLabel';

export type CheckboxGroupSlot = 'root' | 'label' | 'container' | 'helperText';

export const checkboxGroupClasses = {
  root: 'JoyCheckboxGroup-root',
  disabled: 'JoyCheckboxGroup-disabled',
  error: 'JoyCheckboxGroup-error',
};

type CheckboxGroupValue = CheckboxProps['value'][] | undefined;

export interface CheckboxGroupProps
  extends Omit<FormControlProps, 'defaultValue' | 'onChange'> {
  name?: string;
  defaultValue?: CheckboxGroupValue;
  value?: CheckboxGroupValue;
  onChange?: (value: CheckboxGroupValue) => void;
  size?: 'lg' | 'md' | 'sm';
  fullWidth?: boolean;
  variant?: VariantProp;
  label?: ReactNode;
  helperText?: ReactNode;
  selectAll?: boolean;
  checkboxProps?: CheckboxProps;
}

export type CheckboxGroupOwnerState = CheckboxGroupProps;

const CheckboxGroup = (
  props: CheckboxGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    label,
    helperText,
    children,
    disabled,
    id,
    variant,
    color,
    size = 'md',
    fullWidth,
    error,
    orientation,
    selectAll,
    ...other
  } = props;

  const ownerState = { variant, color, size };

  const [value, setValue] = useControlled<CheckboxGroupValue>({
    controlled: valueProp,
    default: defaultValue,
    name: 'CheckboxGroup',
    state: 'value',
  });

  const className = useMemo(() => {
    const classNames: string[] = [checkboxGroupClasses.root];

    disabled && classNames.push(checkboxGroupClasses.disabled);
    error && classNames.push(checkboxGroupClasses.error);

    return classNames.join(' ');
  }, [disabled, error]);

  const childrenValues = useMemo(() => {
    const values: CheckboxGroupValue = [];

    Children.forEach(children, (child) => {
      isValidElement<CheckboxProps>(child) && values.push(child.props.value);
    });

    return values;
  }, [children]);

  const handleChange = useCallback(
    (values: CheckboxGroupValue) => {
      setValue(values);
      onChange?.(values);
    },
    [onChange, setValue],
  );

  const handleToggleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked ? childrenValues : [];
      setValue(newValue);
      onChange?.(newValue);
    },
    [childrenValues, onChange, setValue],
  );

  const contextValue = useMemo(
    () => ({
      label,
      helperText,
      children,
      disabled,
      variant,
      color,
      size,
      fullWidth,
      value,
      className,
      onChange: handleChange,
      error,
      ...other,
    }),
    [
      label,
      helperText,
      children,
      disabled,
      variant,
      color,
      size,
      fullWidth,
      value,
      className,
      handleChange,
      error,
      other,
    ],
  );

  return (
    <CheckboxGroupRoot className={className} ownerState={ownerState}>
      {label && (
        <CheckboxGroupFormLabel ownerState={ownerState}>
          {label}
        </CheckboxGroupFormLabel>
      )}

      <CheckboxGroupContainer
        id={id}
        role="group"
        aria-labelledby="sandwich-group"
        ref={ref}
        fullWidth={fullWidth}
      >
        <List size={size} orientation={orientation} sx={{ flexWrap: 'wrap' }}>
          <CheckboxGroupContext.Provider value={contextValue}>
            {selectAll && (
              <ListItem>
                <Checkbox
                  label="전체"
                  value="ALL"
                  indeterminate={
                    value &&
                    childrenValues.length !== value.length &&
                    value.length > 0
                  }
                  checked={childrenValues.length === value?.length}
                  onChange={handleToggleSelectAll}
                />
              </ListItem>
            )}

            {Children.map(children, (child) => {
              if (isValidElement<CheckboxProps>(child)) {
                return (
                  <ListItem>
                    {cloneElement(child, {
                      checked: value?.includes(child.props.value),
                    })}
                  </ListItem>
                );
              }

              return child;
            })}
          </CheckboxGroupContext.Provider>
        </List>
      </CheckboxGroupContainer>

      {helperText && (
        <CheckboxGroupFormHelperText ownerState={ownerState}>
          {helperText}
        </CheckboxGroupFormHelperText>
      )}
    </CheckboxGroupRoot>
  );
};

const CheckboxGroupRoot = styled('div', {
  name: 'JoyCheckboxGroup',
  slot: 'root',
})<{ ownerState: FormControlOwnerState }>(({ theme, ownerState }) => ({
  '--FormLabel-alignSelf':
    ownerState.orientation === 'horizontal' ? 'align-items' : 'flex-start',
  '--FormLabel-asteriskColor': theme.vars.palette.danger[500],
  ...(ownerState.size === 'sm' && {
    '--FormLabel-fontSize': theme.vars.fontSize.xs,
    '--FormLabel-lineHeight': theme.vars.lineHeight.xl,
    '--FormLabel-margin':
      ownerState.orientation === 'horizontal'
        ? '0 0.5rem 0 0'
        : '0 0 0.25rem 0',
    '--FormHelperText-fontSize': theme.vars.fontSize.xs,
    '--FormHelperText-lineHeight': theme.vars.lineHeight.xl,
  }),
  ...(ownerState.size === 'md' && {
    '--FormLabel-fontSize': theme.vars.fontSize.sm,
    '--FormLabel-lineHeight': theme.vars.lineHeight.sm,
    '--FormLabel-margin':
      ownerState.orientation === 'horizontal'
        ? '0 0.75rem 0 0'
        : '0 0 0.375rem 0',
    '--FormHelperText-fontSize': theme.vars.fontSize.sm,
    '--FormHelperText-lineHeight': theme.vars.lineHeight.sm,
  }),
  ...(ownerState.size === 'lg' && {
    '--FormLabel-fontSize': theme.vars.fontSize.md,
    '--FormLabel-lineHeight': theme.vars.lineHeight.md,
    '--FormLabel-margin':
      ownerState.orientation === 'horizontal' ? '0 1rem 0 0' : '0 0 0.5rem 0',
    '--FormHelperText-fontSize': theme.vars.fontSize.sm,
    '--FormHelperText-lineHeight': theme.vars.lineHeight.sm,
  }),
  ...(ownerState.color && {
    '--FormHelperText-color': theme.vars.palette[ownerState.color]?.[500],
  }),
  '--FormHelperText-margin': '0.375rem 0 0 0',
  [`&.${checkboxGroupClasses.error}`]: {
    '--FormHelperText-color': theme.vars.palette.danger[500],
  },
  [`&.${checkboxGroupClasses.disabled}`]: {
    '--FormLabel-color':
      theme.variants.plainDisabled?.[ownerState.color || 'neutral']?.color,
    '--FormHelperText-color':
      theme.variants.plainDisabled?.[ownerState.color || 'neutral']?.color,
  },
  display: 'flex',
  position: 'relative', // for keeping the control action area, for example Switch
  flexDirection: ownerState.orientation === 'horizontal' ? 'row' : 'column',
}));

const CheckboxGroupContainer = styled('div', {
  name: 'JoyCheckboxGroup',
  slot: 'container',
  shouldForwardProp: (propName) => propName !== 'fullWidth',
})<CheckboxGroupProps>`
  ${({ fullWidth }) => fullWidth && { width: '100%' }};
`;

const CheckboxGroupFormLabel = styled(Typography, {
  name: 'JoyCheckboxGroup',
  slot: 'label',
})<{ ownerState: FormLabelProps }>(({ theme }) => ({
  '--Icon-fontSize': 'calc(var(--FormLabel-lineHeight) * 1em)',
  WebkitTapHighlightColor: 'transparent',
  alignSelf: 'var(--FormLabel-alignSelf)', // to not fill the block space. It seems like a bug when clicking on empty space (within the label area), even though it is not.
  display: 'flex',
  gap: '2px',
  alignItems: 'center',
  flexWrap: 'wrap',
  userSelect: 'none',
  fontFamily: theme.vars.fontFamily.body,
  fontSize: `var(--FormLabel-fontSize, ${theme.vars.fontSize.sm})`,
  fontWeight: theme.vars.fontWeight.md,
  lineHeight: `var(--FormLabel-lineHeight, ${theme.vars.lineHeight.sm})`,
  color: `var(--FormLabel-color, ${theme.vars.palette.text.primary})`,
  margin: 'var(--FormLabel-margin, 0px)',
}));

const CheckboxGroupFormHelperText = styled(Typography, {
  name: 'JoyCheckboxGroup',
  slot: 'helperText',
})<{ ownerState: FormHelperTextProps }>(({ theme }) => ({
  '--Icon-fontSize': 'calc(var(--FormHelperText-lineHeight) * 1em)',
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  fontFamily: theme.vars.fontFamily.body,
  fontSize: `var(--FormHelperText-fontSize, ${theme.vars.fontSize.sm})`,
  lineHeight: `var(--FormHelperText-lineHeight, ${theme.vars.lineHeight.sm})`,
  color: `var(--FormHelperText-color, ${theme.vars.palette.text.tertiary})`,
  margin: 'var(--FormHelperText-margin, 0px)',
  [`.${checkboxGroupClasses.root} + &`]: {
    '--FormHelperText-margin': '0px', // remove the margin if the helper text is next to the form label.
  },
  [`.${checkboxGroupClasses.error} &`]: {
    '--Icon-color': 'currentColor',
  },
}));

export default forwardRef<HTMLDivElement, CheckboxGroupProps>(CheckboxGroup);

import { ColorPaletteProp } from '@mui/joy';
import type { MultiSectionDigitalClockProps as XMultiSectionDigitalClockProps } from '@mui/x-date-pickers-pro';
import { MultiSectionDigitalClock as XMultiSectionDigitalClock } from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef } from 'react';

export interface MultiSectionDigitalClockProps
  extends XMultiSectionDigitalClockProps<Date> {
  color?: ColorPaletteProp;
}

const MultiSectionDigitalClock = (
  props: MultiSectionDigitalClockProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { slots, ...other } = props;

  return (
    <XMultiSectionDigitalClock
      ref={ref}
      slots={{
        ...slots,
      }}
      {...other}
    />
  );
};

export default forwardRef<HTMLDivElement, MultiSectionDigitalClockProps>(
  MultiSectionDigitalClock,
);

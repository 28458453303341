import { Box, Button, Link, Stack, Typography } from '@wooriga/design-system';
import { isAxiosError } from 'axios';
import { useCallback } from 'react';
import {
  isRouteErrorResponse,
  Navigate,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

import axiosInstance from 'apis/axiosInstance';
import mainPage from 'pages/main';
import { ApiErrorData } from 'types/api';
import { removeCookie } from 'utils/cookie';

const ErrorPage = () => {
  const error: unknown = useRouteError();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    removeCookie('memberSeq', { secure: true, sameSite: 'strict' });
    removeCookie('accessToken', {
      secure: true,
      sameSite: 'strict',
    });
    axiosInstance.defaults.headers['authorization'] = '';
    navigate('/login', { replace: true });
  }, [navigate]);

  const renderErrorElement = useCallback(
    (err: unknown) => {
      let httpStatus, codeImage, symbolImage, title, subtitle, buttons;

      if (isRouteErrorResponse(err)) {
        switch (err.status) {
          case 401:
            codeImage = '/images/error-boundarys/code_401.svg';
            symbolImage = '/images/error-boundarys/symbol_401.svg';
            title = '조합의 권한을 찾을 수 없습니다';
            subtitle = '조합의 관리자에게 문의바랍니다';
            buttons = (
              <Button
                variant="solid"
                size="lg"
                fullWidth
                onClick={handleLogout}
              >
                확인
              </Button>
            );
            break;
          // case 403:
          //   codeImage = '/images/error-boundarys/code_403.svg';
          //   symbolImage = '/images/error-boundarys/symbol_403.svg';
          //   title = '해당 조합의 권한을 찾을 수 없습니다';
          //   subtitle = '해당 조합의 관리자에게 문의바랍니다';
          //   buttons = (
          //     <Button
          //       variant="solid"
          //       size="lg"
          //       fullWidth
          //       onClick={handleLogout}
          //     >
          //       확인
          //     </Button>
          //   );
          //   break;
          case 404:
            codeImage = '/images/error-boundarys/code_404.svg';
            symbolImage = '/images/error-boundarys/symbol_404.svg';
            title = '페이지를 찾을 수 없습니다';
            subtitle = '페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다';
            break;
        }
      } else if (isAxiosError<ApiErrorData>(err) && err.response) {
        const { status, data } = err.response;
        const { message, code } = data;
        httpStatus = status;

        switch (status) {
          case 401:
          case 403:
            switch (code) {
              case 'E00500':
              case 'E00505':
              case 'E00506':
                // codeImage = '/images/error-boundarys/code_401.svg';
                // symbolImage = '/images/error-boundarys/symbol_401.svg';
                // title = message;
                // subtitle = '재 로그인 후 이용해주세요.';
                // buttons = (
                //   <Button
                //     variant="solid"
                //     size="lg"
                //     fullWidth
                //     onClick={handleLogout}
                //   >
                //     확인
                //   </Button>
                // );

                return <Navigate to="/login" replace />;

              case 'E00501':
                codeImage = '/images/error-boundarys/code_403.svg';
                symbolImage = '/images/error-boundarys/symbol_403.svg';
                title = message;
                subtitle = '권한을 확인해주세요.';
                break;
              case 'E00503':
              case 'E00504':
                codeImage = '/images/error-boundarys/code_403.svg';
                symbolImage = '/images/error-boundarys/symbol_403.svg';
                title = message;
                subtitle = '조합에 문의하여 주시기 바랍니다.';
                buttons = (
                  <Button
                    variant="solid"
                    size="lg"
                    fullWidth
                    onClick={handleLogout}
                  >
                    확인
                  </Button>
                );
                break;
            }
            break;

          case 500:
          case 501:
          case 502:
          case 503:
            codeImage = '/images/error-boundarys/code_500.svg';
            symbolImage = '/images/error-boundarys/symbol_500.svg';
            title = '서버 오류가 발생하였습니다';
            subtitle =
              '서버를 찾을 수 없거나, 접속할 수 없는 상태입니다\n잠시후 이용해주세요';
            break;
        }
      }

      return (
        <>
          <Stack flex={1} alignItems="center" overflow="hidden">
            <Stack height="100%" justifyContent="center">
              <Box mb={7.5}>
                {codeImage && (
                  <img
                    src={codeImage}
                    alt={`httpStatus code : ${httpStatus}`}
                  />
                )}
              </Box>

              <Typography
                component="h1"
                fontSize={44}
                fontWeight="xl"
                textColor="neutral.600"
                mb={1.25}
              >
                {title || '알 수 없는 오류가 발생하였습니다'}
              </Typography>

              <Typography
                component="p"
                fontSize="lg"
                fontWeight="md"
                lineHeight="lg"
                textColor="neutral.400"
                whiteSpace="break-spaces"
              >
                {subtitle || '이 문제가 계속되면 관리자에게 문의해주세요'}
              </Typography>

              <Stack flexDirection="row" gap={2} mt={10}>
                {buttons || (
                  <>
                    <Button
                      variant="outlined"
                      size="lg"
                      fullWidth
                      onClick={() => navigate(-1)}
                    >
                      이전 페이지
                    </Button>
                    <Button
                      variant="solid"
                      size="lg"
                      fullWidth
                      component={Link}
                      href={mainPage.path}
                    >
                      메인 페이지
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack flex={1} justifyContent="flex-end">
            {symbolImage && <img src={symbolImage} alt={title} />}
          </Stack>
        </>
      );
    },
    [handleLogout, navigate],
  );

  return (
    <Stack width="100%" alignItems="center" height="100vh">
      <Stack flexDirection="row" maxWidth="lg" width="100%" height="100%">
        {renderErrorElement(error)}
      </Stack>
    </Stack>
  );
};

export default ErrorPage;

import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  PatternFormatAdapter,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import useFeedback from 'hooks/useFeedback';
import { CertificateExtensionBody } from 'lim/meetCertification/apis';
import { formatDateTime } from 'utils/format';

interface ExtensionModalProps {
  name?: string;
  endAt?: string;
  open: boolean;
  onClose: (show: false) => void;
  onSubmit: (formData: CertificateExtensionBody) => void;
}

const ExtensionModal = ({
  name,
  endAt,
  open,
  onClose,
  onSubmit,
}: ExtensionModalProps) => {
  const [phoneNo, setPhonNo] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const { snackbar } = useFeedback();

  // if (!name || !endAt) return;

  const JSON = generateDescriptionsJSON(
    ['총회명', '문서보관 종료일', '연장기간'],
    [name, formatDateTime(endAt, 'yyyy-MM-dd HH:mm:ss'), '5년'],
  );

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleSubmit = () => {
    if (!phoneNo) {
      snackbar('연락처를 입력해주세요.', { color: 'danger' });
      return;
    }

    if (!isChecked) {
      snackbar('주의사항을 확인하시고 내용에 동의해 주세요.', {
        color: 'danger',
      });
      return;
    }

    onSubmit({ phoneNo });
    handleClose();
  };

  const handleClose = () => {
    onClose(false);
    setPhonNo('');
    setIsChecked(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog>
        <DialogTitle>문서보관 기간 연장 신청</DialogTitle>

        <DialogContent sx={{ py: 1 }}>
          <Stack gap={3}>
            <Callout
              variant="outlined"
              color="neutral"
              startDecorator={<InfoIcon />}
            >
              문서 보관 연장 신청은 5년 단위로 연장됩니다.
              <br />
              연장까지 최소 3일의 소요 시간이 있으니 연락받을 연락처를 입력해
              주세요.
            </Callout>

            <Stack gap={1}>
              <Typography level="body-sm" textColor="text.primary">
                문서보관 신청 정보
              </Typography>
              <DescriptionsRenderer columns={1} json={JSON} />
            </Stack>

            <TextField
              label="연락처"
              value={phoneNo}
              slotProps={{
                input: {
                  component: PatternFormatAdapter,
                  allowEmptyFormatting: true,
                  format: '###-####-####',
                },
              }}
              onChange={(e) => setPhonNo(e.target.value)}
            />

            <Stack alignItems="center" gap={2}>
              <Typography
                level="body-md"
                textColor="text.tertiary"
                textAlign="center"
              >
                연장 신청 정보에 오류가 없는지 다시 한번 확인해 주세요.
                <br />
                신청 이후 <strong>취소 및 환불이 불가능</strong>합니다.
              </Typography>

              <Checkbox
                label="위 사항을 모두 확인하였습니다."
                checked={isChecked}
                onChange={handleChecked}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>확인</Button>
          <Button variant="outlined" color="neutral" onClick={handleClose}>
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ExtensionModal;

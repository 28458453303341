import * as _ from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { useCommonCodesQuery } from 'apis/common/apis';

export const useCommonCodes = () => {
  const { data } = useCommonCodesQuery();

  const getGroupCodes = useCallback(() => ({ data }), [data]);

  const getGroupCode = useCallback(
    (groupCode: string) => _.find(data?.data, { groupCode: groupCode }),
    [data?.data],
  );

  const getCode = useCallback(
    (groupCode: string, code: string) =>
      _.find(getGroupCode(groupCode)?.items, { code }),
    [getGroupCode],
  );

  return useMemo(
    () => ({ getGroupCodes, getGroupCode, getCode }),
    [getCode, getGroupCode, getGroupCodes],
  );
};

import JoyModalDialog from '@mui/joy/ModalDialog';
import type { ModalDialogProps as JoyModalDialogProps } from '@mui/joy/ModalDialog';
import { ForwardedRef, forwardRef } from 'react';

export type ModalDialogProps = JoyModalDialogProps;

const ModalDialog = (
  props: ModalDialogProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyModalDialog {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, ModalDialogProps>(ModalDialog);

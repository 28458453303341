import { GridRowId } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  DateTimePicker,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  useExternalSystemCheckMutation,
  useFileDownloadMutation,
} from 'apis/common/apis';
import {
  CreateMessageBody,
  MessageRegistersParams,
  useCallersQuery,
  useCreateMessageMutation,
  useMessageRegistersQuery,
} from 'apis/message/apis';
import {
  MESSAGE_REGISTER_DEFAULT_VALUE,
  MESSAGE_TABS,
} from 'apis/message/constants';
import { MESSAGE_REGISTER_COLUMNS } from 'apis/message/fixtures';
import { MessageRegister, MessageSent } from 'apis/types/message';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import MessageForm, {
  MessageFormValues,
} from 'components/pages/messages/MessageForm';
import MessageBalanceModal from 'components/pages/messages/modal/MessageBalanceModal';
import MessagePreviewModal from 'components/pages/messages/modal/MessagePreviewModal';
import MessageSentBoxModal from 'components/pages/messages/modal/MessageSentBoxModal';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { ModalState } from 'hyuk/types/common';
import { useUnionRegisterGroupsQuery } from 'lim/address-group/apis';
import messageCallerPage from 'pages/main/union-management/messages/caller';
import messageRecordPage from 'pages/main/union-management/messages/records';
import { CustomRouteObject } from 'types/route';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const FORM_DEFAULT_VALUES = {
  outgoingPhoneNo: '',
  scheduledAt: '',
  sendType: 'IMMEDIATE',
  recipients: [],
  files: [],
};
const DEFAULT_SEARCH_PARAMS = {
  unionRegisterGroupSeq: 0,
  consentStatus: '',
};

const DEFAULT_MODAL_STATE = {
  unionInfo: false,
  preview: false,
  balance: false,
  sentBox: false,
};

type MessageModalState = ModalState<typeof DEFAULT_MODAL_STATE>;
type MessageModalName = keyof MessageModalState;

const MessageMainPage = () => {
  const params = useParams();
  const { pageContext } = useLayoutContext();

  const unionSeq = Number(params.unionSeq);
  const { unionBasename } = pageContext || {};

  const [rows, setRows] = useState<MessageRegister[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [individualNumber, setIndividualNumber] = useState('');
  const [selectedUnionSeq, setSelectedUnionSeq] = useState(0);

  const [registerSearchParams, setRegisterSearchParams] =
    useState<MessageRegistersParams>({
      ...DEFAULT_SEARCH_PARAMS,
      unionSeq,
    });

  const [modals, setModals] = useState<MessageModalState>(DEFAULT_MODAL_STATE);

  const { getGroupCode } = useCommonCodes();

  const sendTypeCodeGroup = useMemo(
    () => getGroupCode('SMS_SEND_TYPE'),
    [getGroupCode],
  );

  const {
    data: groups,
    isError: isGroupsError,
    error: groupsError,
  } = useUnionRegisterGroupsQuery(unionSeq, {
    unionRegisterName: '',
    unionRegisterGroupName: '',
  });
  const {
    data: receivers,
    isPending,
    isError: isReceiversError,
    error: receiversError,
  } = useMessageRegistersQuery(registerSearchParams);
  const {
    data: callersReturnData,
    isError: isCallersError,
    error: callersError,
  } = useCallersQuery({ unionSeq });

  const { hasCallers, callers } = useMemo(() => {
    const callersResult =
      callersReturnData?.data?.filter(({ status }) => status === 'C') ?? [];
    return {
      hasCallers: callersResult.length > 0,
      callers: callersResult,
    };
  }, [callersReturnData?.data]);

  console.log(callers);
  console.log(hasCallers);

  const { mutateAsync: downloadFile } = useFileDownloadMutation();
  const { mutateAsync: checkMessageSystem } = useExternalSystemCheckMutation();

  const { mutate: sendMessage, isPending: isSendingMessage } =
    useCreateMessageMutation();

  const { snackbar, alertDialog } = useFeedback();

  const { control, setValue, watch, reset } = useForm<CreateMessageBody>({
    defaultValues: FORM_DEFAULT_VALUES,
  });

  const formValues = watch();

  const isRowSelected = useMemo(
    () => !!(rowSelectionModel.length > 0),
    [rowSelectionModel],
  );
  const isReservation = useMemo(
    () => formValues.sendType === 'SCHEDULE',
    [formValues.sendType],
  );
  const hasPreviewContent = useMemo(
    () =>
      (formValues.title?.length || 0) > 0 ||
      (formValues.message?.length || 0) > 0 ||
      (formValues.files ?? []).length > 0,
    [formValues],
  );

  const resetPageState = () => {
    setRowSelectionModel([]);
    reset({
      ...FORM_DEFAULT_VALUES,
      outgoingPhoneNo: callers?.[0]?.outgoingPhoneNo,
    });
  };

  const openModal = (modalName: MessageModalName) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };
  const closeModal = (modalName: MessageModalName) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  const handleChangeRowSelectionModel = (model: GridRowId[]) => {
    setRowSelectionModel(model);
    setValue(
      'recipients',
      rows
        .filter(({ uniqueId }) => model.includes(uniqueId))
        .map(({ unionRegisterSeq, name, phoneNo }) => ({
          unionRegisterSeq,
          name: name?.name,
          phoneNo,
        })),
    );
  };

  const handleChangeIndividualNumber = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setIndividualNumber(value.trimStart());
  };

  const handleChangeMessageForm = (data: MessageFormValues) => {
    Object.entries(data).map(([key, value]) =>
      setValue(key as FieldPath<CreateMessageBody>, value),
    );
  };

  const handleClickIndividualEnroll = () => {
    if (!individualNumber) return alertDialog('등록할 번호를 입력해주세요.');

    const targetPhones = individualNumber
      .replaceAll('\n', '|||')
      .replaceAll(', ', '|||')
      .replaceAll(',', '|||')
      .split('|||');

    const validPhones: string[] = [];
    const errorPhones: string[] = [];
    let firstErrorIndex = -1;

    targetPhones.forEach((phone, index) => {
      const cleaned = phone.replace(/\D/g, '');

      if (/^[0-9]{9,12}$/.test(cleaned)) {
        validPhones.push(cleaned);
      } else {
        if (phone.trim() !== '') {
          errorPhones.push(phone);
          if (firstErrorIndex === -1) firstErrorIndex = index;
        }
      }
    });
    if (errorPhones.length > 0) {
      const errorLog = errorPhones
        .map((erorrPhone) => `'${erorrPhone}'`)
        .join('\n');

      return alertDialog(
        `수신자의 연락처 형식이 올바르지 않습니다.\n확인 후 다시 등록해 주세요.\n\n${errorLog}`,
        { message: '알림' },
      );
    }

    const phones = validPhones.flatMap((phone) =>
      phone
        ? [
            {
              ...MESSAGE_REGISTER_DEFAULT_VALUE,
              phoneNo: phone,
            },
          ]
        : [],
    );

    setIndividualNumber('');
    setRows((oldRows) => [
      ...oldRows,
      ...phones.map((phone, index) => ({
        ...phone,
        uniqueId: `${oldRows.length + index + 1}`,
      })),
    ]);
  };

  const handleSentMessageSelection = async (message: MessageSent) => {
    const { title, message: messageContext, filePaths = [] } = message;

    const files = await Promise.all(
      filePaths.map(async (path) => {
        const [splitBySlash, splitByDot] = [path.split('/'), path.split('.')];

        const name = splitBySlash[splitBySlash.length - 1];
        const extension = splitByDot[splitByDot.length - 1];

        const blobFile = await downloadFile({ filePath: path });

        return new File([blobFile], name, {
          type: `image/${extension}`,
        });
      }),
    );

    setValue('title', title ?? '');
    setValue('message', messageContext ?? '');
    setValue('files', files);
  };

  const handleCheckMessageSystem = async () => {
    const request = { externalSystemType: 'SMS_SEND' };

    await checkMessageSystem(request, {
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
        return false;
      },
    });

    return true;
  };

  const handleOpenUnionInfoModal = useCallback(
    (seq: number | undefined) => {
      if (!seq) {
        alertDialog('조합원이 아니거나 조합원 정보가 없습니다.', {
          message: '조합원 정보',
        });
        return;
      }

      setSelectedUnionSeq(seq);
      openModal('unionInfo');
    },
    [alertDialog],
  );

  const handleOpenBalanceModal = async () => {
    const isSystemAvailable = await handleCheckMessageSystem();
    const isScheduleDateAvailable = !(
      formValues.sendType === 'SCHEDULE' && !formValues.scheduledAt
    );
    const isContentAvailable =
      formValues.files.length > 0 || (formValues.message ?? '').length > 0;

    if (!isSystemAvailable) {
      return;
    }

    if (!isScheduleDateAvailable) {
      snackbar('예약발송일시를 선택해 주세요.', { color: 'danger' });
      return;
    }

    if (!isContentAvailable) {
      snackbar('발송할 문자 내용을 입력 또는 이미지를 첨부해 주세요.', {
        color: 'danger',
      });
      return;
    }

    openModal('balance');
  };

  const handleSubmitSendMessage = () => {
    const request = { ...formValues, unionSeq };

    sendMessage(request, {
      onSuccess: () => {
        snackbar('문자 발송이 완료되었습니다.', {
          color: 'success',
        });

        closeModal('balance');
        resetPageState();
      },
      onError: (error) => {
        snackbar(error.response?.data.message ?? error.message, {
          color: 'danger',
        });
      },
    });
  };

  const { columns } = useCreateGridColumns({
    columns: MESSAGE_REGISTER_COLUMNS,
    handlers: {
      onClickUnionMember: handleOpenUnionInfoModal,
    },
  });

  if (isReceiversError || isCallersError || isGroupsError) {
    if (isReceiversError) throw receiversError;
    if (isCallersError) throw callersError;
    if (isGroupsError) throw groupsError;
  }

  useEffect(() => {
    setRows(receivers?.data ?? []);
  }, [receivers]);

  useEffect(() => {
    if (callers) {
      reset({
        ...FORM_DEFAULT_VALUES,
        outgoingPhoneNo: callers?.[0]?.outgoingPhoneNo || '',
      });
    }
  }, [callers, reset]);

  useEffect(() => {
    if (groups?.data && Array.isArray(groups.data)) {
      setRegisterSearchParams((prevParams) => ({
        ...prevParams,
        unionRegisterGroupSeq: groups.data
          ? groups.data[0].unionRegisterGroupSeq
          : 0,
      }));
    }
  }, [groups]);

  return (
    <>
      <Tabs
        color="neutral"
        selectedTabVariant="plain"
        selectedTabColor="primary"
        tabIndicatorInset
        defaultValue={0}
        value={0}
      >
        <TabList disableUnderline>
          {MESSAGE_TABS.map(({ label, path }, index) => (
            <Tab
              key={`tab_${path}`}
              component={Link}
              value={index}
              href={`${unionBasename}${path}`}
            >
              {label}
            </Tab>
          ))}
        </TabList>

        <TabPanel value={0}>
          <Grid container spacing={5} width="100%">
            <Grid xl>
              <Stack flexDirection="column" gap={2}>
                <Stack direction="column" gap={1}>
                  <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                    발신 설정
                  </Typography>

                  <Stack direction="row" gap={2}>
                    <FormControl sx={{ flex: 1 }}>
                      <FormLabel>발신번호 선택</FormLabel>

                      <Controller
                        control={control}
                        name="outgoingPhoneNo"
                        render={({ field: { onChange, ...other } }) => (
                          <Select
                            placeholder={
                              hasCallers ? '발신번호 선택' : '발신번호 없음'
                            }
                            {...other}
                            disabled={!hasCallers}
                            onChange={(_, value) => onChange(value)}
                          >
                            {callers.map(
                              ({ smsCallerSeq, outgoingPhoneNo, name }) => (
                                <Option
                                  key={`caller_${smsCallerSeq}`}
                                  value={outgoingPhoneNo}
                                >
                                  {outgoingPhoneNo} {name}
                                </Option>
                              ),
                            )}
                          </Select>
                        )}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>발송 시간 설정</FormLabel>

                      <Stack flexDirection="row" gap={2}>
                        <Controller
                          control={control}
                          name="sendType"
                          render={({ field }) => (
                            <RadioGroup orientation="horizontal" {...field}>
                              {sendTypeCodeGroup?.items.map(
                                ({ code, name }) => (
                                  <Radio
                                    key={`message_method_${code}_radio`}
                                    label={name}
                                    value={code}
                                  />
                                ),
                              )}
                            </RadioGroup>
                          )}
                        />

                        <Controller
                          control={control}
                          name="scheduledAt"
                          render={({ field }) => (
                            <DateTimePicker
                              {...field}
                              color="neutral"
                              disabled={!isReservation}
                              disablePast
                            />
                          )}
                        />
                      </Stack>
                    </FormControl>
                  </Stack>

                  <Typography
                    level="h3"
                    fontSize="lg"
                    fontWeight="xl"
                    lineHeight="xl"
                  >
                    수신자 설정
                  </Typography>

                  <Stack gap={1}>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>수신자 그룹 선택</FormLabel>
                      <Select
                        value={String(
                          registerSearchParams.unionRegisterGroupSeq,
                        )}
                        onChange={(_, value) =>
                          setRegisterSearchParams((prevParams) => ({
                            ...prevParams,
                            unionRegisterGroupSeq: Number(value),
                          }))
                        }
                      >
                        {groups?.data?.map(
                          ({ name, unionRegisterGroupSeq }) => (
                            <Option
                              key={`caller_${unionRegisterGroupSeq}`}
                              value={unionRegisterGroupSeq.toString()}
                            >
                              {name}
                            </Option>
                          ),
                        )}
                      </Select>
                    </FormControl>

                    <FormControl>
                      <FormLabel>수신자 개별 등록</FormLabel>
                      <Textarea
                        placeholder="등록할 번호 입력"
                        minRows={2}
                        maxRows={2}
                        value={individualNumber}
                        onChange={handleChangeIndividualNumber}
                        endDecorator={
                          <Button
                            variant="soft"
                            onClick={handleClickIndividualEnroll}
                            sx={{ ml: 'auto' }}
                          >
                            등록
                          </Button>
                        }
                      />
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack height={402}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={isPending}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={handleChangeRowSelectionModel}
                    getRowId={(row) => row.uniqueId}
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid xs="auto">
              <Stack width={380} gap={3}>
                <Stack gap={1}>
                  <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                    문자 내용 작성
                  </Typography>

                  <MessageForm
                    value={{
                      title: formValues.title,
                      message: formValues.message,
                      files: formValues.files,
                    }}
                    onChange={handleChangeMessageForm}
                    actions={
                      <Stack direction="row" alignSelf="flex-end">
                        <Button
                          variant="solid"
                          color="neutral"
                          onClick={() => openModal('sentBox')}
                        >
                          보낸 문자함
                        </Button>
                      </Stack>
                    }
                  />
                </Stack>

                <Stack direction="row" alignSelf="flex-end" gap={1}>
                  <Button
                    variant="outlined"
                    color="neutral"
                    disabled={!hasPreviewContent}
                    onClick={() => openModal('preview')}
                  >
                    미리보기
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isRowSelected}
                    onClick={handleOpenBalanceModal}
                  >
                    문자발송
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
      </Tabs>

      {modals.unionInfo && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq,
            unionRegisterSeq: selectedUnionSeq,
          }}
          open={modals.unionInfo}
          onClose={() => {
            setSelectedUnionSeq(0);
            closeModal('unionInfo');
          }}
        />
      )}

      {modals.preview && (
        <MessagePreviewModal
          open={modals.preview}
          onClose={() => closeModal('preview')}
          data={{
            title: formValues.title,
            message: formValues.message,
            files: formValues.files,
          }}
        />
      )}

      {modals.sentBox && (
        <MessageSentBoxModal
          unionSeq={unionSeq}
          open={modals.sentBox}
          onClose={() => closeModal('sentBox')}
          onSelect={handleSentMessageSelection}
        />
      )}

      {modals.balance && (
        <MessageBalanceModal
          unionSeq={unionSeq}
          data={formValues}
          open={modals.balance}
          onClose={() => closeModal('balance')}
          isMutationLoading={isSendingMessage}
          onSubmit={handleSubmitSendMessage}
        />
      )}
    </>
  );
};

const messagesPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/messages',
  children: [
    { index: true, element: <MessageMainPage /> },
    messageRecordPage,
    messageCallerPage,
  ],
  handle: {
    getTitle: () => '문자 발송',
    getMenuCode: () => 'M0403',
  },
};

export default messagesPage;

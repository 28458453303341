import JoyChipDelete from '@mui/joy/ChipDelete';
import type { ChipDeleteProps as JoyChipDeleteProps } from '@mui/joy/ChipDelete';
import { ForwardedRef, forwardRef } from 'react';

export type ChipDeleteProps = JoyChipDeleteProps;

const ChipDelete = (
  props: ChipDeleteProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  return <JoyChipDelete {...props} ref={ref} />;
};

export default forwardRef<HTMLButtonElement, ChipDeleteProps>(ChipDelete);

import { styled } from '@mui/joy';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import type { PickersActionBarProps as XPickersActionBarProps } from '@mui/x-date-pickers-pro';

import Button from 'design-system/components/inputs/Button';
import Stack from 'design-system/components/layout/Stack';

export interface JoyPickersActionBarProps extends XPickersActionBarProps {}

const JoyPickersActionBar = (props: JoyPickersActionBarProps) => {
  const { onAccept, onClear, onCancel, onSetToday, actions, className } = props;

  const localeText = useLocaleText();

  const actionItems = actions?.map((actionType) => {
    switch (actionType) {
      case 'clear':
        return (
          <Button
            variant="plain"
            onClick={() => {
              onClear();
            }}
            key={actionType}
          >
            {localeText.clearButtonLabel}
          </Button>
        );
      case 'cancel':
        return (
          <Button
            variant="plain"
            onClick={() => {
              onCancel();
            }}
            key={actionType}
          >
            {localeText.cancelButtonLabel}
          </Button>
        );
      case 'accept':
        return (
          <Button
            variant="plain"
            onClick={() => {
              onAccept();
            }}
            key={actionType}
          >
            {localeText.okButtonLabel}
          </Button>
        );
      case 'today':
        return (
          <Button
            variant="plain"
            onClick={() => {
              onSetToday();
            }}
            key={actionType}
          >
            {localeText.todayButtonLabel}
          </Button>
        );
    }
  });

  return (
    <JoyPickersActionBarRoot className={className}>
      {actionItems}
    </JoyPickersActionBarRoot>
  );
};

const JoyPickersActionBarRoot = styled(Stack, {
  name: 'JoyPickersActionBar',
  slot: 'root',
})`
  padding: 8px;
`;

export default JoyPickersActionBar;

import JoyStepIndicator from '@mui/joy/StepIndicator';
import type { StepIndicatorProps as JoyStepIndicatorProps } from '@mui/joy/StepIndicator';
import { ForwardedRef, forwardRef } from 'react';

export type StepIndicatorProps = JoyStepIndicatorProps;

const StepIndicator = (
  props: StepIndicatorProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyStepIndicator {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, StepIndicatorProps>(StepIndicator);

import JoyMenu from '@mui/joy/Menu';
import type { MenuProps as JoyMenuProps } from '@mui/joy/Menu';
import { ForwardedRef, forwardRef } from 'react';

export type MenuProps = JoyMenuProps;

const Menu = (props: MenuProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <JoyMenu {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, MenuProps>(Menu);

import { GridColDef } from '@mui/x-data-grid-pro';
import { Link } from '@wooriga/design-system';

import { formatTelephone, formatBirth, formatDate } from 'utils/format';
import { formatCompanyRegistrationNumber } from 'utils/format/company';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

export const AGREEMENT_TABLE_COLUMNS = ({
  onClickName,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (unionRegisterSeq: number) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      width: 78,
      valueGetter: (_, row) => row?.unionRegister?.unionRegisterNo || '',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 78,
      valueGetter: (_, row) =>
        getCommonCode(
          'UNION_REGISTER_SHARE_TYPE',
          row?.unionRegister?.shareType,
        )?.name || '',
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 90,
      valueGetter: (_, row) =>
        row?.unionRegister?.name
          ? [
              row?.unionRegister?.name?.name,
              row?.unionRegister?.name?.nameClass,
            ].join('')
          : '이름없음',
      renderCell: (params) => {
        const { value, row } = params;
        const { unionRegister } = row;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(unionRegister?.unionRegisterSeq)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'agent',
      headerName: '대리인',
      width: 80,
      valueGetter: (_, row) => {
        return [
          row?.unionRegister?.agent?.name?.name,
          row?.unionRegister?.agent?.name?.nameClass,
        ].join('');
      },
    },
    {
      field: 'consentStatus',
      headerName: '동의현황',
      width: 80,
      valueGetter: (value) =>
        getCommonCode('UNION_AGREEMENT_RESPONSE_TYPE', value)?.name || '',
    },

    {
      field: 'birth',
      headerName: '생년월일',
      width: 100,
      valueGetter: (_, row) => {
        const { birth, companyNo } = row?.unionRegister || {};

        const value =
          (birth && formatBirth(birth)) ||
          (companyNo && formatCompanyRegistrationNumber(companyNo)) ||
          '';

        return value;
      },
    },
    {
      field: 'mainPhone',
      headerName: '연락처',
      width: 110,
      valueGetter: (_, row) =>
        (row?.unionRegister?.mainPhone?.phoneNo &&
          formatTelephone(row.unionRegister.mainPhone?.phoneNo)) ||
        '',
    },
    {
      field: 'responseAt',
      headerName: '응답일',
      width: 100,
      valueGetter: (value) => (value && formatDate(value)) || '',
    },
    {
      field: 'localAddresses',
      headerName: '소재지',
      width: 240,
      multiline: true,
      valueGetter: (_, row) =>
        row?.unionRegister?.localAddresses?.map(
          ({ localAddressSeq, city, gu, dong, jibun }) => ({
            key: `local-address-${localAddressSeq}`,
            value: [city, gu, dong, jibun].join(' '),
          }),
        ),
    },
  ] as GridColDef[];

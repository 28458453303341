import {
  UseMutationResult,
  useQuery,
  UseQueryResult,
  useMutation,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import {
  ArchiveDetailReturnData,
  ArchiveListReturnData,
  ArchiveUpdate,
} from 'kwon/archives/types';
import { ApiError, ApiResponseData } from 'types/api';

export interface ArchiveParams {
  unionSeq: number;
  title: string;
  page: number;
  pageSize: number;
}
interface ArchiveDetailParams {
  unionSeq: number;
  archiveSeq: number;
}
export interface ArchiveDeleteParams {
  unionSeq: number;
  archiveSeq: number;
}
export interface ArchiveUpdateParams extends ArchiveUpdate {
  unionSeq: number;
  archiveSeq: number;
}
export interface ArchiveRegisterParams extends ArchiveUpdate {
  unionSeq: number;
}

export const useArchiveListQuery = (
  params: ArchiveParams,
): UseQueryResult<ApiResponseData<ArchiveListReturnData>, ApiError> => {
  const { unionSeq, ...rest } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/archives`, rest],
  });
};

export const useArchiveDetailQuery = (
  params: ArchiveDetailParams,
): UseQueryResult<ApiResponseData<ArchiveDetailReturnData, true>, ApiError> => {
  const { unionSeq, archiveSeq } = params;
  return useQuery({
    queryKey: [`/unions/${unionSeq}/archives/${archiveSeq}`],
  });
};

export const useArchiveRegisterMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  ArchiveRegisterParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;

      const { data } = await axiosInstance.post(
        `/unions/${unionSeq}/archives`,
        body,
      );

      return data;
    },
  });

export const useArchiveUpdateMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  ArchiveUpdateParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, archiveSeq, ...body } = request;

      const { data } = await axiosInstance.put(
        `/unions/${unionSeq}/archives/${archiveSeq}`,
        body,
      );

      return data;
    },
  });
export const useArchiveDeleteMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  ArchiveDeleteParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, archiveSeq } = request;
      const { data } = await axiosInstance.delete(
        `/unions/${unionSeq}/archives/${archiveSeq}`,
      );

      return data;
    },
  });

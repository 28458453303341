import type { DateCalendarProps as XDateCalendarProps } from '@mui/x-date-pickers-pro';
import { DateCalendar as XDateCalendar } from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef } from 'react';

export interface DateCalendarProps extends XDateCalendarProps<Date> {}

const DateCalendar = (
  props: DateCalendarProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { slots, ...other } = props;

  return (
    <XDateCalendar
      ref={ref}
      slots={{
        ...slots,
      }}
      {...other}
    />
  );
};

export default forwardRef<HTMLDivElement, DateCalendarProps>(DateCalendar);

import { MeetMethods, MeetStatus } from 'apis/types/meet';

export type MeetMethodResultKeys =
  | 'onsiteResult'
  | 'electronicResult'
  | 'writtenSubmissionResult';

export const generateMeetMethodKey = (key: MeetMethods | string) => {
  switch (key) {
    case 'ONSITE':
      return 'onsiteVoteStatus';
    case 'ELECTRONIC':
      return 'electronicVoteStatus';
    default:
      return 'onsiteVoteStatus';
  }
};

export const generateMeetStatus = (meetStatus: MeetStatus) => {
  switch (meetStatus) {
    case 'BEFORE':
      return '진행전';
    case 'PROCESS':
      return '진행중';
    case 'COUNTING':
      return '집계중';
    case 'COMPLETE':
      return '종료';
    default:
      return '취소';
  }
};

export const generateMeetMethodName = (
  meetMethodResultKey: MeetMethodResultKeys,
) => {
  switch (meetMethodResultKey) {
    case 'onsiteResult':
      return '서면 제출';
    case 'electronicResult':
      return '전자투표';
    case 'writtenSubmissionResult':
      return '현장 투표';

    default:
      return '';
  }
};

import { styled } from '@mui/joy';
import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  useContext,
} from 'react';

import DialogContent from 'design-system/components/feedback/dialog/DialogContent';
import Box, { BoxProps } from 'design-system/components/layout/Box';
import NavigationBarContext from 'design-system/components/navigation/NavigationBarContext';

export type NavigationBarContentSlot = 'root';

export const navigationBarContentClasses = {
  root: 'JoyNavigationBarContent-root',
};

export interface NavigationBarContentProps extends BoxProps {}

export type NavigationBarContentOwnerState = NavigationBarContentProps;

interface NavigationBarFooterComponent
  extends ForwardRefExoticComponent<NavigationBarContentProps> {
  propTypes?: never;
  muiName?: string;
}

const NavigationBarContent = forwardRef(
  (props: NavigationBarContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...other } = props;

    const navigationBarContext = useContext(NavigationBarContext);

    return (
      <JoyNavigationBarContentRoot
        {...other}
        className={navigationBarContentClasses.root}
        ref={ref}
        component={navigationBarContext?.permanent ? 'div' : DialogContent}
      >
        {children}
      </JoyNavigationBarContentRoot>
    );
  },
) as NavigationBarFooterComponent;

const JoyNavigationBarContentRoot = styled(Box, {
  name: 'JoyNavigationBarContent',
  slot: 'root',
})<NavigationBarContentProps>`
  display: flex;
  flex: 1 1 100%;
  padding-left: 12px;
  padding-right: 12px;
  overflow-y: auto;
`;

NavigationBarContent.displayName = 'NavigationBarContent';
NavigationBarContent.muiName = 'NavigationBarContent';

export default NavigationBarContent;

import { GridColDef } from '@mui/x-data-grid-pro';
import {
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
} from '@wooriga/design-system';

import { FileInfo } from 'apis/types/common';
import { Form } from 'apis/types/form';
import { formatDate } from 'utils/format';
import { GridColumnsHandlers } from 'utils/grid/createGridColumns';

export interface FormColumnProps {
  onClickPreview: (formInfo: Form, selectedFile: FileInfo) => void;
  onClickDownload: (formInfo: Form, selectedFile: FileInfo) => void;
}
export const FORMS_COLUMNS = ({
  getCommonCode,
  onClickPreview,
  onClickDownload,
}: GridColumnsHandlers<FormColumnProps>) =>
  [
    { field: 'title', headerName: '문서명', width: 240 },
    {
      field: 'formType',
      headerName: '문서구분',
      width: 78,
      valueGetter: (value) => getCommonCode('FORM_DIVIDE', value)?.name,
    },
    { field: 'summary', headerName: '문서요약', width: 280 },
    {
      field: 'createdAt',
      headerName: '등록일자',
      width: 100,
      valueGetter: (value) => value && formatDate(value),
    },
    {
      field: 'modifiedAt',
      headerName: '수정일자',
      width: 100,
      valueGetter: (value) => value && formatDate(value),
    },
    {
      field: 'action',
      headerName: '',
      display: 'flex',
      minWidth: 200,
      sortable: false,
      filterable: false,

      renderCell: ({ row, rowNode }) => {
        if (rowNode.type === 'group') return;

        return (
          <Dropdown>
            <Stack direction="row" alignItems="center" gap={1}>
              <Button
                variant="outlined"
                size="sm"
                color="neutral"
                onClick={() => onClickPreview(row, row.previewImage)}
              >
                미리보기
              </Button>
              <MenuButton variant="outlined" size="sm" color="primary">
                다운로드
              </MenuButton>
            </Stack>

            <Menu sx={{ minWidth: '200px' }}>
              {row.attachFiles.map((file) => (
                <MenuItem
                  key={`menu_item_${file.fileSeq}`}
                  onClick={() => onClickDownload(row, file)}
                >
                  {file.extension}
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        );
      },
    },
  ] as GridColDef<Form & { action: '' }>[];

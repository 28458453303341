import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  DialogTitle,
  DialogContent,
  DialogActions,
  Callout,
  List,
  ListItem,
  ModalOverflow,
} from '@wooriga/design-system';
import { ChangeEvent, ForwardedRef, forwardRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { useChargeDeposit } from 'apis/charges/apis';
import useFeedback from 'hooks/useFeedback';
import { commaizeNumber } from 'utils/format';

const MAX_CHARGE_AMOUNT = 50000000;

type ChargeModalFormValues = {
  requestAmount: number;
  depositorName: string;
  depositorPhoneNo: string;
};

const schema = yup.object({
  requestAmount: yup
    .number()
    .transform((value) => value || 0)
    .required('신청 금액을 입력해 주세요.')
    .min(1, '신청 금액을 입력해 주세요')
    .max(MAX_CHARGE_AMOUNT, '신청 금액은 50,000,000을 초과 할 수 없습니다'),
  depositorName: yup.string().required('입금자명을 입력해 주세요.'),
  depositorPhoneNo: yup.string().required('비상연락처를 입력해 주세요.'),
});

export interface ChargeModalProps {
  unionSeq: number;
  unionName: string;
  open: boolean;
  onClose?: () => void;
  onSubmit?: (props: { seq: number; isTaxBillApplied: boolean }) => void;
}

const ChargeModal = (
  props: ChargeModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { unionSeq, unionName, open, onClose, onSubmit } = props;

  const { confirmDialog, snackbar } = useFeedback();

  const [isTaxInvoice, setIsTaxInvoice] = useState(true);

  const { mutate: chargeDepositMutation, isPending: isSubmitLoading } =
    useChargeDeposit();

  const {
    control,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ChargeModalFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      requestAmount: 0,
      depositorName: unionName,
      depositorPhoneNo: '',
    },
  });

  const requestAmount = Number(watch('requestAmount')) || 0;
  const isAmountOverHundredThousand = Number(requestAmount) >= 100_000;

  const handleClose = () => {
    reset();
    onClose && onClose();
  };

  const handleChangeTaxInvoice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    setIsTaxInvoice(Boolean(value));
  };

  const handleOpenConfirm = handleSubmit(() => {
    confirmDialog('예치금 충전 신청을 등록 하시겠습니까?', {
      message: '알림',
      onConfirm: () => {
        const formData = getValues();

        chargeDepositMutation(
          { unionSeq, ...formData, requestAmount },
          {
            onSuccess: (res) => {
              snackbar('충전 신청이 완료되었습니다.', { color: 'success' });

              onSubmit?.({
                seq: res?.data?.seq || 0,
                isTaxBillApplied: isTaxInvoice,
              });

              if (!isTaxInvoice) {
                handleClose();
              }
            },
            onError: (error) => {
              snackbar(error.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  });

  return (
    <>
      <Modal open={open} onClose={onClose} ref={ref}>
        <ModalOverflow>
          <ModalDialog maxWidth="md">
            <DialogTitle>예치금 충전</DialogTitle>

            <Stack>
              <Callout variant="solid" size="md" color="primary">
                입금계좌 : 우리은행 1005-804-482687 (주)이제이엠컴퍼니
              </Callout>
            </Stack>
            <Stack>
              <Callout>
                <List component="ol" marker="disc" sx={{ pl: 2, py: 0 }}>
                  <ListItem>
                    <Typography>
                      입금 시{' '}
                      <Typography fontWeight="lg">
                        부가세10%가 추가된 부가세 포함 입금 총액
                      </Typography>
                      을 입금하셔야 합니다.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    입력하신 입금자명과 실제 입금자가 다를 경우 확인이 늦어질 수
                    있습니다.
                  </ListItem>
                  <ListItem>
                    입력하신 충전 신청액과 실제 입금액이 다를 경우 실제 입금액을
                    기준으로 충전됩니다.
                  </ListItem>
                  <ListItem>
                    10만원 이상 충전시 세금계산서가 자동 발행되니 발행정보를
                    입력해야 합니다.
                  </ListItem>
                </List>
              </Callout>
            </Stack>

            <DialogContent>
              <Stack gap={2}>
                <Controller
                  control={control}
                  name="requestAmount"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label="충전 신청 금액"
                      placeholder="숫자만 입력"
                      fullWidth
                      value={field.value || ''}
                      error={!!errors.requestAmount}
                      helperText={
                        errors.requestAmount ? errors.requestAmount.message : ''
                      }
                      validateOptions={{
                        regex: /^[0-9]*$/,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="depositorPhoneNo"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="비상연락처 입력"
                      placeholder="숫자만 입력"
                      fullWidth
                      error={!!errors.depositorPhoneNo}
                      helperText={
                        errors.depositorPhoneNo?.message ??
                        '입금 확인 및 입금 오류 시 연락받으실 비상연락처를 입력해주세요'
                      }
                      validateOptions={{
                        maxLength: 11,
                        regex: /^[0-9]*$/,
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="depositorName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="입금자명 입력"
                      placeholder="입금자명 입력"
                      fullWidth
                      error={!!errors.depositorName}
                      helperText={
                        errors.depositorName?.message ??
                        '조합명과 입금자명이 다를 경우 입금자 명을 입력해주세요'
                      }
                      validateOptions={{
                        maxLength: 30,
                      }}
                    />
                  )}
                />

                <RadioGroup
                  color="neutral"
                  orientation="horizontal"
                  label="세금계산서 발행 신청"
                  name="isTaxInvoiceRequest"
                  value={isAmountOverHundredThousand || isTaxInvoice}
                  onChange={handleChangeTaxInvoice}
                  disabled={isAmountOverHundredThousand}
                >
                  <Radio label="발행신청" value={true} />
                  <Radio label="미발행" value={false} />
                </RadioGroup>
              </Stack>

              <Stack gap={1} textAlign="right">
                <Typography>
                  예치금 충전액 : {commaizeNumber(requestAmount || 0)}원
                </Typography>
                <Typography color="primary">
                  부가세 포함 입금 총액 :{' '}
                  {commaizeNumber(Math.round((requestAmount || 0) * 1.1))}원
                </Typography>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                size="md"
                variant="solid"
                color="primary"
                onClick={handleOpenConfirm}
                loading={isSubmitLoading}
              >
                충전 신청
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="neutral"
                size="md"
                disabled={isSubmitLoading}
                onClick={handleClose}
              >
                취소
              </Button>
            </DialogActions>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
};

export default forwardRef(ChargeModal);

import { useContext } from 'react';

import PanelContext, {
  PanelContextValue,
} from 'design-system/components/surfaces/panels/PanelContext';

export type PanelState = PanelContextValue;

const usePanel = (): PanelState | undefined => {
  return useContext(PanelContext);
};

export default usePanel;

import { useContext } from 'react';

import TabsContext, {
  TabsContextValue,
} from 'design-system/components/navigation/tabs/TabsContext';

export interface UseTabsProps extends TabsContextValue {}

const useTabs = (): UseTabsProps | undefined => {
  return useContext(TabsContext);
};

export default useTabs;

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DesignSystemProvider } from '@wooriga/design-system';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import ReactQueryProvider, { queryClient } from 'apis/ReactQueryProvider';
import FeedbackProvider from 'components/Feedback/FeedbackProvider';
import rootRouter from 'routes/rootRouter';

export default function App() {
  return (
    <HelmetProvider>
      <CookiesProvider>
        <DesignSystemProvider mode="light" defaultColorScheme="light">
          <ReactQueryProvider queryClient={queryClient}>
            <RouterProvider router={rootRouter(queryClient)} />
            {/* <ReactQueryDevtools position="left" /> */}
          </ReactQueryProvider>
          <FeedbackProvider />
        </DesignSystemProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
}

import { GridRowId } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  Descriptions,
  DescriptionsItem,
  Grid,
  Stack,
  TextField,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useExternalSystemCheckMutation } from 'apis/common/apis';
import {
  MessageReceiversParams,
  useCreateMessageMutation,
  useMessageQuery,
  useMessageReceiversQuery,
  useMessageSentQuery,
} from 'apis/message/apis';
import { MESSAGE_RECEIVER_COLUMNS } from 'apis/message/fixtures';
import CommonCode from 'components/CommonCode';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import MessageBalanceModal from 'components/pages/messages/modal/MessageBalanceModal';
import MessagePreviewModal from 'components/pages/messages/modal/MessagePreviewModal';
import Search from 'components/Search';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber, formatDateTime, formatTelephone } from 'utils/format';
import useCreateGridColumns from 'utils/grid/useCreateGridColumns';

const DEFAULT_SEARCH_PARAMS = {
  phoneNo: '',
};

const DEFAULT_SELECTED_UNION_STATE = {
  open: false,
  seq: 0,
};

const MessageRecordDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { unionSeq, smsId } = params;

  const [searchParams, setSearchParams] = useState<MessageReceiversParams>({
    unionSeq: Number(unionSeq),
    smsId: smsId ?? '',
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const [selectedUnionState, setSelectedUnionState] = useState(
    DEFAULT_SELECTED_UNION_STATE,
  );

  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [isBalanceOpen, setIsBalanceOpen] = useState<boolean>(false);

  const { data: info } = useMessageQuery({
    unionSeq: Number(unionSeq),
    smsId: smsId ?? '',
  });
  const {
    data: receivers,
    isPending,
    isError,
    error,
  } = useMessageReceiversQuery(searchParams);

  const { data: preview } = useMessageSentQuery({
    unionSeq: Number(unionSeq),
    smsId: smsId ?? '',
  });

  const { mutateAsync: checkMessageSystem } = useExternalSystemCheckMutation();
  const { mutate: sendMessage, isPending: isSubmitLoading } =
    useCreateMessageMutation();

  const { snackbar, alertDialog } = useFeedback();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'message-records-detail',
    activeSaveSnapshot: false,
  });

  const isRowSelected = useMemo(
    () => !!(rowSelectionModel.length > 0),
    [rowSelectionModel],
  );

  const rows = useMemo(() => receivers?.data || [], [receivers]);
  const recipients = useMemo(
    () =>
      rows
        .filter(({ messageId }) => rowSelectionModel.includes(messageId))
        .map(({ unionRegisterSeq, name, phoneNo }) => ({
          unionRegisterSeq,
          phoneNo,
          name,
        })),
    [rows, rowSelectionModel],
  );
  const formData = useMemo(
    () => ({
      title: preview?.data?.title ?? '',
      message: preview?.data?.message ?? '',
      outgoingPhoneNo: info?.data?.outgoingPhoneNo ?? '',
      recipients,
      files: [],
      sendType: 'IMMEDIATE',
      scheduledAt: '',
    }),
    [preview?.data, info?.data?.outgoingPhoneNo, recipients],
  );

  const handleSearchParams = (values: Partial<MessageReceiversParams>) => {
    if (!values) {
      return;
    }

    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...values,
    }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleMoveParentPage = () => {
    navigate('..', { relative: 'path' });
  };

  const handleCheckMessageSystem = async () => {
    let isSystemAvailable = true;

    await checkMessageSystem(
      { externalSystemType: 'SMS_SEND' },
      {
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
          isSystemAvailable = false;
        },
      },
    );

    return { isSystemAvailable };
  };

  const handleOpenUnionInfoModal = (seq: number | undefined) => {
    if (!seq) {
      alertDialog('조합원이 아니거나 조합원 정보가 없습니다.');
      return;
    }

    setSelectedUnionState({
      open: true,
      seq,
    });
  };

  const handleOpenBalanceModal = async () => {
    const { isSystemAvailable } = await handleCheckMessageSystem();

    if (!isSystemAvailable) {
      return;
    }

    setIsBalanceOpen(true);
  };

  const handleSubmitSendMessage = () => {
    sendMessage(
      { ...formData, unionSeq: Number(unionSeq) },
      {
        onSuccess: () => {
          snackbar('문자 재발송이 완료되었습니다.', {
            color: 'success',
          });
          setIsBalanceOpen(false);
          setRowSelectionModel([]);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const { columns } = useCreateGridColumns({
    columns: MESSAGE_RECEIVER_COLUMNS,
    handlers: {
      onClickUnionMember: handleOpenUnionInfoModal,
    },
  });

  if (isError || !smsId) {
    throw error;
  }

  return (
    <>
      <Stack flex={3} gap={2}>
        <Stack gap={1}>
          <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
            상세 발송 내역
          </Typography>

          <Descriptions variant="soft" color="primary" size="md" columns={4}>
            <DescriptionsItem label="발신번호">
              {formatTelephone(info?.data?.outgoingPhoneNo)}
            </DescriptionsItem>
            <DescriptionsItem label="문자유형">
              <CommonCode
                groupCode="UNION_SMS_DIVIDE"
                code={info?.data?.smsType ?? ''}
              />
            </DescriptionsItem>
            <DescriptionsItem label="발송방식">
              <CommonCode
                groupCode="SMS_SEND_TYPE"
                code={info?.data?.sendType ?? ''}
              />
            </DescriptionsItem>
            <DescriptionsItem label="수신자 수">
              {commaizeNumber(info?.data?.recipientCount || 0)} 명
            </DescriptionsItem>
            <DescriptionsItem label="신청일시">
              {formatDateTime(
                info?.data?.requestedAt ?? new Date(),
                'yyyy-MM-dd HH:mm:ss',
              )}
            </DescriptionsItem>
            <DescriptionsItem label="성공">
              {commaizeNumber(info?.data?.successCount || 0)} 명
            </DescriptionsItem>
            <DescriptionsItem label="실패">
              {commaizeNumber(info?.data?.failCount || 0)} 명
            </DescriptionsItem>
          </Descriptions>
        </Stack>

        <Stack gap={1.75}>
          <Search
            defaultValues={DEFAULT_SEARCH_PARAMS}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    label="수신번호"
                    name="phoneNo"
                    placeholder="수신번호 입력"
                    fullWidth
                    validateOptions={{
                      maxLength: 11,
                      regex: /^[0-9]*$/,
                    }}
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>
        </Stack>

        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(receivers?.pagination.totalDataCount || 0)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(receivers?.pagination.totalElements || 0)}
              </Typography>
            </Typography>
          </Stack>

          <Stack height={442}>
            <DataGrid
              apiRef={datagridApiRef}
              rows={rows}
              columns={columns}
              loading={isPending}
              rowSelectionModel={rowSelectionModel}
              getRowId={(row) => row.messageId}
              isRowSelectable={({ row }) => row.sendResult === '실패'}
              onRowSelectionModelChange={setRowSelectionModel}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={1}>
              <Button
                variant="outlined"
                color="neutral"
                onClick={handleMoveParentPage}
              >
                목록
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => exportExcel()}
              >
                내역 다운
              </Button>
            </Stack>

            <Stack direction="row" gap={1}>
              <Button variant="outlined" onClick={() => setIsPreviewOpen(true)}>
                보낸 문자 내용 보기
              </Button>
              <Button
                disabled={!isRowSelected}
                onClick={handleOpenBalanceModal}
              >
                문자 재발송
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {selectedUnionState.open && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq: Number(unionSeq),
            unionRegisterSeq: selectedUnionState.seq,
          }}
          open={selectedUnionState.open}
          onClose={() => setSelectedUnionState(DEFAULT_SELECTED_UNION_STATE)}
        />
      )}

      {isPreviewOpen && (
        <MessagePreviewModal
          open={isPreviewOpen}
          onClose={setIsPreviewOpen}
          data={{
            title: preview?.data?.title,
            message: preview?.data?.message,
            files: preview?.data?.filePaths,
          }}
        />
      )}

      {isBalanceOpen && (
        <MessageBalanceModal
          unionSeq={Number(unionSeq)}
          data={formData}
          open={isBalanceOpen}
          isMutationLoading={isSubmitLoading}
          onClose={setIsBalanceOpen}
          onSubmit={handleSubmitSendMessage}
        />
      )}
    </>
  );
};

const messageRecordDetailPage: CustomRouteObject = {
  path: ':smsId',
  element: <MessageRecordDetailPage />,
};

export default messageRecordDetailPage;
